import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Popover from 'react-bootstrap/Popover';
import { Route, Routes, useNavigate, useParams } from "react-router";
import ViewPo from "./view-po";
import CreatePurchaseOrder from "./create-purchase-order";
import { PROJECT_MANAGEMENT, VENDOR_PAYMENT } from "src/constants/route-paths";
import idx from "idx";
import { numberFormat } from "src/util/currency-formatter";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deletePurchaseOrder, downloadProjectPO, getPOList } from "src/actions/project-management.action";
import { useSelector } from "react-redux";
import { getGlobalSettingTemplateTypes } from "src/actions/global-setting-template.action";
import DownloadDialog from "src/components/download-dialog";
import DeleteDialog from "../../../components/delete-dialog";
import { getProjectData } from "../utils/get-project";
import CustomTable from "src/components/table/table";
import PayInvoice from "../../vendor-payment/pay-invoice";
import { TemplateType } from "src/util/enums";

export default function PurchaseOrderList({ wSupplierPermission, ...props }) {

  const [columns, setColumns] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [pending, setPending] = useState([]);
  const [filter, setFilter] = useState("pending");
  const [poId, setPoId] = useState();
  const [poNo, setPoNo] = useState();
  const [poData, setPoData] = useState({});
  const [showPayInvoice, setShowInvoice] = useState(false);
  const [vendorId, setVedorId] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [downloadData, setDownloadData] = useState({
    open: false,
    id: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { projectId } = useParams();
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const projectDetail = useSelector(state => state.projectDetail) || {}
  const entitlements = useSelector(state => state.user.entitlements)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  useEffect(() => {
    _getPOList();

    if (filter === "Sub-Contractors") {
      subContractorColums();
    } else {
      suppilerColumns();
    }
    if (!idx(projectDetail, _ => _.project.res) || (idx(projectDetail, _ => _.project.projectId) != projectId))
      getProjectData({ projectId, dispatch })
  }, [projectId]);

  const subContractorColums = () => {
    const columns = [
      {
        title: "PO No",
        field: "id",
        render: (rowData) => (
          <Link to={`view/${rowData.u_id}`}>{rowData.id}</Link>
        ),
      },
      {
        title: "Total",
        field: "totalCost",
        render: (rowData) => `${numberFormat(rowData.totalCost)}`,
      },
      { title: "Vendor", field: "vendorName", render: (rowData) => rowData.vendorName },
      {
        title: "Vendor Type",
        field: "type",
        render: (rowData) =>
          rowData.type === "sub-contractor" ? "Contractor" : rowData.type,
      },
    ];

    setColumns(columns);
  };

  const suppilerColumns = () => {
    const columns = [
      {
        title: "PO No",
        field: "id",
        render: (rowData) => (
          <Link to={`view/${rowData.u_id}`}>{rowData.id}</Link>
        ),
      },
      {
        title: "Total",
        field: "totalCost",
        render: (rowData) => `${numberFormat(rowData.totalCost)}`,
      },
      { title: "Vendor", field: "vendorName", render: (rowData) => rowData.vendorName },
      { title: "Material Status", field: "materialstatus", render: (rowData) => rowData.materialstatus },
      { title: "Invoice Status", field: "invoicestatus", render: (rowData) => rowData.invoicestatus },
    ];

    setColumns(columns);
  };

  const _getPOList = () => {
    const POs = [];
    const suppliers = [];
    const completed = [];
    const pending = [];
    dispatch(getPOList({ projectId: projectId, vendorType: "Supplier" })).then((poList) => {
      Array.isArray(poList) &&
        poList.forEach((res) => {
          const vendorName = res.vendor && res.vendor.business_name;
          const type = res.vendor && res.vendor.type;
          const poObj = { id: res.id, vendorName, type, ...res };
          POs.push(poObj);
          suppliers.push(poObj);
          const status = idx(res, (_) => _.status) || "";
          if (
            status === "Completed" ||
            (res.invoicestatus == "Fully Invoiced" &&
              res.materialstatus == "Fully Received")
          ) {
            completed.push(poObj);
          } else {
            pending.push(poObj);
          }
        });

      setCompleted(completed);
      setPending(pending);
    });
  };

  const handlePayInvoiceModal = () => {
    setShowInvoice(!showPayInvoice);
  };

  const filterPo = (type) => {
    setFilter(type);

    if (type === "Sub-Contractors") {
      subContractorColums();
    } else {
      suppilerColumns();
    }
  };

  const handleMenu = (rowData) => {
    setPoId(rowData.u_id);
    setPoNo(rowData.id);
    setPoData(rowData);
    setVedorId(rowData.vendor && rowData.vendor.id);
  };

  const deleteHandler = async () => {
    deleteDialogState();
    await dispatch(deletePurchaseOrder(poId, "Purchase Order Deleted successfully"));
    _getPOList();
  };

  const deleteDialogState = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };
  const gettemplate = async () => {
    if (templateOptions.length == 0) {
      let _templateOptions = [];
      const templateData = await dispatch(getGlobalSettingTemplateTypes(TemplateType.PURCHASE_ORDER));
      for (let key in templateData) {
        _templateOptions.push({
          value: templateData[key].id,
          key: templateData[key].name,
        });
      }
      setTemplateOptions(_templateOptions);
    }
  };
  const handleDownloadDialog = (id = "", template = "") => {
    const newDownloadData = {};
    newDownloadData.open = !downloadData.open;
    newDownloadData.id = poId;
    newDownloadData.template = template;
    if (templateOptions.length == 0) {
      gettemplate();
    }
    setDownloadData(newDownloadData);
  };

  const downloadFile = async (fileFormat = "pdf", tempId) => {
    if (fileFormat === "pdf" || fileFormat === "ALL") {
      await dispatch(downloadProjectPO(downloadData.id, fileFormat, tempId)).catch(e => console.error(e));
    }
    if (fileFormat === "docx" || fileFormat === "ALL") {
      await dispatch(downloadProjectPO(downloadData.id, fileFormat, tempId)).catch(e => console.error(e));
    }
    handleDownloadDialog();
  };

  const viewProject = () => navigate(`/projects/view/${projectId}`)
  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  const viewPO = () => navigate(`view/${poId}`);
  const editPO = () => navigate(`edit/${poId}`);
  const createPO = () => navigate(`create-purchase-order`);
  const receiveStock = () => navigate(`/${PROJECT_MANAGEMENT}/receive-stock/${projectId}/${vendorId}/${poId}`);
  const returnStock = () => navigate(`/${PROJECT_MANAGEMENT}/return-stock/${projectId}/${vendorId}/${poId}`);
  const viewInvoices = () => navigate(`/${VENDOR_PAYMENT}?project=${projectId}&poId=${poId}`);

  return (
    <>
      <DownloadDialog
        {...downloadData}
        downloadFile={downloadFile}
        handleDownloadDialog={handleDownloadDialog}
        templateOptions={templateOptions}
        downloadModuleName="Purchase Order"
      />
      <DeleteDialog
        open={openDeleteDialog}
        id={poId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogState}
        moduleName="Purchase Order"
      />
      <Routes>
        <Route
          path={`view/:poId`}
          element={<ViewPo poData={poData} />}
        />
        <Route
          path={`create-purchase-order`}
          element={<CreatePurchaseOrder />}
        />
        <Route
          path={`edit/:poId`}
          element={<CreatePurchaseOrder />}
        />
        <Route
          path=""
          exact
          element={
            <>
              {showPayInvoice && (
                <PayInvoice
                  poNumber={poId}
                  poNo={poNo}
                  isSubContractor={false}
                  workType="project"
                  handleClose={handlePayInvoiceModal}
                  payInvoiceType={"purchaseOrder"}
                  handleDownloadDialog={handleDownloadDialog}
                />
              )}
              <div className="purchase-order-block">
                <div className="row">
                  <div className="w-auto">
                    <h2 className="content-heading text-uppercase">Purchase Order List</h2>
                    <div className="col-12 breadcrumb-block p-0">
                      <Breadcrumb>
                        <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Purchase Order List</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </div>
                  <div className="w-auto ms-auto mt-auto">
                    {wSupplierPermission && (<button
                      type="submit"
                      className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary"
                      onClick={createPO}
                    >
                      Create Purchase Order
                    </button>)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="inprogress"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab onClick={() => filterPo('pending')} eventKey="inprogress" title={`Active / Inprogress (${pending.length})`}>
                        <CustomTable
                          columns={columns}
                          data={pending}
                          isAction={true}
                          handleMenu={(data) => handleMenu(data)}
                          menu={(item) => (
                            <Popover id="popover-basic">
                              <Popover.Body>
                                <ul className="action-block-list">
                                  <li onClick={() => viewPO()}>
                                    View PO
                                  </li>
                                  {wSupplierPermission && <li onClick={() => editPO()} >
                                    Edit PO
                                  </li>}
                                  <li onClick={() => handleDownloadDialog()}>
                                    Download PO
                                  </li>
                                  {wSupplierPermission && <li
                                    className={!(entitlements.receiveStock) && 'disabled'}
                                    onClick={() => (entitlements.receiveStock) && receiveStock()}
                                  >
                                    Receive Stock
                                  </li>}
                                  <li onClick={viewInvoices}>View Invoices</li>
                                  {wSupplierPermission && <li onClick={deleteDialogState} >
                                    Delete
                                  </li>}
                                </ul>
                              </Popover.Body>
                            </Popover>
                          )}
                          fileName='Pending PO List'
                        />
                      </Tab>
                      <Tab onClick={() => filterPo('completed')} eventKey="completed" title={`Completed (${completed.length})`}>
                        <CustomTable
                          columns={columns}
                          data={completed}
                          isAction={true}
                          handleMenu={(data) => handleMenu(data)}
                          menu={(item) => (
                            <Popover id="popover-basic">
                              <Popover.Body>
                                <ul className="action-block-list">
                                  <li onClick={() => viewPO()}> View PO</li>
                                  <li onClick={() => handleDownloadDialog()}>Download PO</li>
                                  {wSupplierPermission && <li onClick={returnStock}>Return Stock</li>}
                                  {wSupplierPermission && <li onClick={handlePayInvoiceModal}>Receive Invoice</li>}
                                  <li onClick={viewInvoices}>View Invoices</li>
                                </ul>
                              </Popover.Body>
                            </Popover>
                          )}
                          fileName='Completed PO List'
                        />
                      </Tab>
                    </Tabs>

                  </div>
                </div>
              </div>
            </>
          }
        />
      </Routes>
    </>
  )

}
