import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { INVENTORY } from "./../../constants/route-paths";
import { getItem, getTransactionsList } from "./../../actions/inventory.action";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';

class POStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: "",
      transactions: [],
      poList: [],
      vehicleStock: {},
      wareHouseStock: {},
      item: {},
    };
  }

  async componentDidMount() {
    const itemId = this.props.params.itemId;
    this.setState({ itemId }, () => this.getTransactionsList());
    this.getTransactionsList();
    const item = await this.props.getItem(itemId);
    this.setState({ item });
  }

  getTransactionsList = async () => {
    let [vehicleStock, wareHouseStock] = await Promise.all([
      this.props.getTransactionsList("VEHICLE"),
      this.props.getTransactionsList("WAREHOUSE"),
    ]);

    vehicleStock = this.calculateStock(vehicleStock, "vehicle");
    wareHouseStock = this.calculateStock(wareHouseStock, "warehouse");
    this.setState({
      vehicleStock,
      wareHouseStock,
    });
  };

  calculateStock = (transactions, type) => {
    const { itemId } = this.state;
    let reservedStock = 0;
    let deStock = 0;

    const itemTransaction = transactions.filter(
      (transaction) => transaction.itemId === itemId
    );
    if (Array.isArray(itemTransaction)) {
      itemTransaction.forEach((transaction) => {
        if (type !== "vehicle") {
          if (transaction.transactionType === "RECEIVED") {
            if (
              transaction.poId === "NO_PO" ||
              transaction.poId === "INTERNAL STOCK"
            ) {
              deStock += transaction.quantity;
            } else {
              reservedStock += transaction.quantity;
            }
          } else if (type === "warehouse") {
            if (transaction.receivedFrom === "WAREHOUSE") {
              if (
                transaction.poId === "NO_PO" ||
                transaction.poId === "INTERNAL STOCK"
              ) {
                deStock -= transaction.quantity;
              } else {
                reservedStock -= transaction.quantity;
              }
            }
            if (transaction.location === "WAREHOUSE") {
              if (
                transaction.poId === "NO_PO" ||
                transaction.poId === "INTERNAL STOCK"
              ) {
                deStock += transaction.quantity;
              } else {
                reservedStock += transaction.quantity;
              }
            }
          }
        } else {
          if (transaction.transactionType === "RECEIVED") {
            if (
              transaction.poId === "NO_PO" ||
              transaction.poId === "INTERNAL STOCK"
            ) {
              deStock += transaction.quantity;
            } else {
              reservedStock += transaction.quantity;
            }
          } else if (transaction.transactionType === "ALLOCATED") {
            if (transaction.receivedFrom === "VEHICLE") {
              if (
                transaction.poId === "NO_PO" ||
                transaction.poId === "INTERNAL STOCK"
              ) {
                deStock -= transaction.quantity;
              } else {
                reservedStock -= transaction.quantity;
              }
            }
            if (transaction.location === "VEHICLE") {
              if (
                transaction.poId === "NO_PO" ||
                transaction.poId === "INTERNAL STOCK"
              ) {
                deStock += transaction.quantity;
              } else {
                reservedStock += transaction.quantity;
              }
            }
          }
        }
      });
    }
    return { reservedStock, deStock };
  };

  cancelHandler = () => {
    this.props.history(`/${INVENTORY}`);
  };

  render() {
    const { vehicleStock, wareHouseStock } = this.state;
    const warehouseReserveStock = parseFloat(
      (wareHouseStock && wareHouseStock.reservedStock) || 0
    );
    const warehouseDeStock = parseFloat(
      (wareHouseStock && wareHouseStock.deStock) || 0
    );
    const vehicleReserveStock = parseFloat(
      (vehicleStock && vehicleStock.reservedStock) || 0
    );
    const vehicleDeStock = parseFloat(
      (vehicleStock && vehicleStock.deStock) || 0
    );

    return (
      <div className="purchase-order-block">

        <h2 className="content-heading text-uppercase">View Stock</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={this.cancelHandler}>INVENTORY </Breadcrumb.Item>
            <Breadcrumb.Item active> View Stock</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="material-list-block mt-3">
          <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch w-100">
              <thead>
                <tr>
                  <th className="w-auto">Store Location</th>
                  <th className="w-auto">Reserve Stock</th>
                  <th>Internal Stock</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>All</td>
                  <td>{warehouseReserveStock + vehicleReserveStock}</td>
                  <td>{warehouseDeStock + vehicleDeStock}</td>
                </tr>
                <tr>
                  <td>WareHouse</td>
                  <td>{wareHouseStock && wareHouseStock.reservedStock}</td>
                  <td>{wareHouseStock && wareHouseStock.deStock}</td>
                </tr>
                <tr>
                  <td>Vehicle</td>
                  <td>{vehicleStock && vehicleStock.reservedStock}</td>
                  <td>{vehicleStock && vehicleStock.deStock}</td>
                </tr>
              </tbody>
            </Table>
          </div>

        </div>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getItem: bindActionCreators(getItem, dispatch),
  getTransactionsList: bindActionCreators(getTransactionsList, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(POStock)
);
