import React from 'react'
import Modal from './../../components/modal/modal'
import Input from './../../components/inputs/input-simple'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  addStage,
  getStage,
  updateStage,
  saveRFQ,
  editRFQ,
  getProject
} from '../../actions/project-management.action'
import { getVendorList } from '../../actions/vendor-management.action'
import { warningSnackBar } from '../../actions/common.action'
import Icons from './../../components/icons/icons'
import _ from 'lodash'
import './scss/project-management.scss'
import PreviewRFQ from './preview-rfq'
import SendRFQ from './send-rfq'
import './scss/dialog.scss'
import Cookies from 'js-cookie'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileUploader from './../../components/file-uploader'
import idx from 'idx'
import AddSupplier from '../suppliers/add-supplier';
import AddSubContractor from '../sub-contractor/add-sub-contractor';
import { fileValidator } from 'src/util/validators/file';

class CreateRFQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        description: {
          name: 'description',
          value: '',
          type: 'textarea',
          label: 'Description'
        },
        attachments: {
          name: 'attachments',
          label: 'Attachments',
          type: 'file',
          touched: false,
          error: false,
          valid: true,
          multiple: true,
          value: [],
          attachmentArr: [],
          validation: []
        },
        vendorType: {
          name: 'vendorType',
          type: 'select',
          label: 'Vendor Type',
          items: [
            { value: 'Supplier', key: 'Supplier' },
            { value: 'sub-contractor', key: 'Sub-Contractor' },
          ],
          value: 'Supplier'
        },
        vendorList: {
          name: 'vendorList',
          type: 'autoComplete',
          label: '',
          options: [],
          value: [],
          touched: false,
          error: false,
          valid: true,
        }
      },
      materialData: [],
      viewMaterialData: [],
      viewOptionMaterialData: [],
      selectedItems: [],
      step: 1,
      allIds: [],
      vendorEmails: [],
      filteredData: [],
      stages: [],
      options: [],
      rfqId: null,
      attachmentArr: [],
      allVendorsList: [],
      addVendorDialog: false,
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.open, this.props.open) && nextProps.open) {
      const allVendorsList = await this.props.getVendorList()
      this.setState({ allVendorsList }, () => this.setVendorList())
    }

    if (!_.isEqual(nextProps.rfqId, this.props.rfqId)) {
      this.setState({ rfqId: nextProps.rfqId, })
    }

    if (!_.isEqual(nextProps.description, this.props.description)) {
      const fields = this.state.fields
      fields.description.value = nextProps.description
      this.setState({ fields })
    }

    if (!_.isEqual(nextProps.vendorType, this.props.vendorType)) {
      const fields = this.state.fields
      fields.vendorType.value = 'Supplier'
      if (nextProps.vendorType) {
        fields.vendorType.value = nextProps.vendorType
      }
      this.setState({ fields })
    }
    const stages = this.formatProjectData(nextProps.projectData)
    const options = this.formatOptions(nextProps.options)
    this.setState({ stages, options })
    this.viewTaskDetails(nextProps.projectData)
    this.viewOptionDetails(nextProps.options)
  }

  setVendorList = (vendorType = 'Supplier') => {
    const { allVendorsList = [], fields } = this.state
    const contacts = []
    Array.isArray(allVendorsList) && allVendorsList.forEach(vendor => {
      vendor.contacts && vendor.contacts.forEach(contact => {
        if (contact.primary && vendor.type === vendorType) {
          const title = contact.email + '(' + vendor.businessName + ')'
          const value = contact.email
          const name = vendor.businessName
          const vendorId = vendor.id
          contacts.push({ title, value, name, vendorId })
        }
      })
    })
    fields.vendorList.options = contacts
    fields.vendorList.value = []
    this.setState({ fields })
  }

  formatOptions = (options = null) => {
    if (options === null) {
      options = this.state.options
    }
    options.map((option, optionIndex) => {
      option.selectedRFQ = option.selected
      if (option.selected) {
        option.material && option.material.map((material, materialIndex) => {
          if (_.isString(material)) {
            material = JSON.parse(material)
            options[optionIndex].material[materialIndex] = material
          }
          material.selected = true
        })
      }
    })
    return options
  }


  formatProjectData = (stages = null) => {
    if (stages === null) {
      stages = this.state.stages
    }
    stages.map((stage, stageIndex) => {
      stage.selectedRFQ = stage.selected
      stage.tasks.map((task, taskIndex) => {
        task.selectedRFQ = task.selected
        if (task.selected) {
          task.material && task.material.map((material, materialIndex) => {
            if (_.isString(material)) {
              material = JSON.parse(material)
              stages[stageIndex].tasks[taskIndex].material[materialIndex] = material
            }
            material.selected = true
          })
        }
        task.subTasks.map((subTask, subTaskIndex) => {
          subTask.selectedRFQ = subTask.selected
          if (subTask.selected) {
            subTask.material && subTask.material.map((material, materialIndex) => {
              if (_.isString(material)) {
                material = JSON.parse(material)
                stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material[materialIndex] = material
              }
              material.selected = true
            })
          }
        })
      })
    })
    return stages
  }
  viewOptionDetails = (options = null) => {

    if (options === null) {
      options = this.state.options
    }
    const optionDetails = []
    options.forEach((option, optionIndex) => {
      if (true) {
        if (option.selected) {
          option.material && option.material.forEach(material => {
            if (_.isString(material)) {
              material = JSON.parse(material)
            }
            optionDetails.push({
              ...material,
              selected: material.selected,
              optionIndex
            })
          })
        }
      }
    })
    this.setState({ viewOptionMaterialData: optionDetails })
  }

  viewTaskDetails = (stages = null) => {

    if (stages === null) {
      stages = this.state.stages
    }
    const taskDetails = []
    stages.forEach((stage, stageIndex) => {
      if (true) {
        stage.tasks.forEach((task, taskIndex) => {
          if (task.selected) {
            task.material && task.material.forEach(material => {
              if (_.isString(material)) {
                material = JSON.parse(material)
              }
              taskDetails.push({
                ...material,
                selected: material.selected,
                taskIndex,
                stageIndex,
                subTaskIndex: null,
              })
            })
          }
          task.subTasks.forEach((subTask, subTaskIndex) => {
            if (subTask.selected) {
              subTask.material && subTask.material.forEach(material => {
                if (_.isString(material)) {
                  material = JSON.parse(material)
                }
                taskDetails.push({
                  ...material,
                  selected: material.selected,
                  taskIndex,
                  stageIndex,
                  subTaskIndex
                })
              })
            }
          })
        })
      }
    })
    this.setState({ viewMaterialData: taskDetails })
  }

  seletectFile = () => {
    document.getElementById("fileInput").click();
  }

  removeFile = (index, type) => {
    const fields = this.state.fields
    if (type === 'new') {
      const data = []
      for (var x = 0; x < fields.attachments.value.length; x++) {
        if (x !== index) {
          data.push(fields.attachments.value[x])
        }
      }
      fields.attachments.value = data
    }
    if (type === 'old') {
      const data = this.state.attachmentArr.filter((doc, i) => i !== index)
      this.setState({ attachmentArr: data })
    }
    this.setState({ fields })
  }

  stageHandler = (value, stageIndex) => {

    const stages = this.state.stages
    stages[stageIndex].selected = value
    stages[stageIndex].tasks.forEach((task, taskIndex) => {
      task.selected = value
      task.material && task.material.forEach((material, materialIndex) => {
        if (_.isString(material)) {
          material = JSON.parse(material)
          stages[stageIndex].tasks[taskIndex].material[materialIndex] = material
        }
        material.selected = value
      })
      task.subTasks.forEach((subTask, subTaskIndex) => {
        subTask.selected = value
        subTask.material && subTask.material.forEach((material, materialIndex) => {
          if (_.isString(material)) {
            material = JSON.parse(material)
            stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material[materialIndex] = material
          }
          material.selected = value
        })
      })
    })
    this.setState({ stages })
    this.viewTaskDetails()
  }

  taskHandler = (value, stageIndex, taskIndex) => {

    const stages = this.state.stages
    stages[stageIndex].tasks[taskIndex].selected = value
    stages[stageIndex].tasks[taskIndex].material && stages[stageIndex].tasks[taskIndex].material.forEach((material, materialIndex) => {
      if (_.isString(material)) {
        material = JSON.parse(material)
        stages[stageIndex].tasks[taskIndex].material[materialIndex] = material
      }
      material.selected = value
    })
    stages[stageIndex].tasks[taskIndex].subTasks.forEach((subTask, subTaskIndex) => {
      subTask.selected = value
      subTask.material && subTask.material.forEach((material, materialIndex) => {
        if (_.isString(material)) {
          material = JSON.parse(material)
          stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material[materialIndex] = material
        }
        material.selected = value
      })
    })
    this.setState({ stages })
    this.viewTaskDetails()
  }

  optionHandler = (value, optionIndex) => {

    const options = this.state.options
    options[optionIndex].selected = value
    options[optionIndex].material && options[optionIndex].material.forEach((material, materialIndex) => {
      if (_.isString(material)) {
        material = JSON.parse(material)
        options[optionIndex].material[materialIndex] = material
      }
      material.selected = value
    })
    this.setState({ options })
    this.viewOptionDetails()
  }


  subTaskHandler = (value, stageIndex, taskIndex, subTaskIndex) => {

    const stages = this.state.stages
    stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].selected = value
    stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material && stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material.forEach((material, materialIndex) => {
      if (_.isString(material)) {
        material = JSON.parse(material)
        stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material[materialIndex] = material
      }
      material.selected = value
    })

    this.setState({ stages })
    this.viewTaskDetails()
  }

  selecteItemHandler = (value, { stageIndex, taskIndex, subTaskIndex, id }) => {
    const stages = this.state.stages
    if (subTaskIndex !== null) {
      stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material.map((material, materialIndex) => {
        if (_.isString(material)) {
          material = JSON.parse(material)
          stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].material[materialIndex] = material
        }
        if (material.id === id) {
          material.selected = value
        }
      })
    } else {
      stages[stageIndex].tasks[taskIndex].material.map((material, materialIndex) => {

        if (_.isString(material)) {
          material = JSON.parse(material)
          stages[stageIndex].tasks[taskIndex].material[materialIndex] = material
        }
        if (material.id === id) {
          material.selected = value
        }
      })
    }
    this.setState({ stages })
    this.viewTaskDetails()
  }

  selectedOptionItemHandler = (value, { optionIndex, id }) => {
    const options = this.state.options
    options[optionIndex].material.map((material, materialIndex) => {

      if (_.isString(material)) {
        material = JSON.parse(material)
        options[optionIndex].material[materialIndex] = material
      }
      if (material.id === id) {
        material.selected = value
      }
    })
    this.setState({ options })
    this.viewOptionDetails()
  }


  inputChangeHandler = (e, fieldName = '') => {
    let { fields } = this.state
    let isValid = true;
    if (e.target.type === 'file') {
      if (e.target.files.length > 10) {
        this.props.errorSnackBar('You Can not Upload more than 10 files')
      } else {
        Array.from(e.target.files).map(file => {
          const fileValidation = fileValidator({ file });
          const { validFileSize, validFileType, error } = fileValidation
          if (!validFileSize.valid && !error) {
            isValid = false
            this.props.errorSnackBar(validFileSize.error)
          }
          else if (!validFileType.valid && !error) {
            isValid = false
            this.props.errorSnackBar(validFileType.error)
          } else if (error) {
            isValid = false
            this.props.errorSnackBar(error.error)
          } else fields[e.target.name].value.push(file);
        })
        if (!isValid) return
      }
    } else if (e.target.type === 'checkbox') {
      fields[e.target.name].value = e.target.checked
    } else {
      fields[e.target.name].value = e.target.value
    }
    if (e.target.name === 'vendorType') {
      this.setVendorList(e.target.value)
    }
    fields[e.target.name].touched = true
    fields[e.target.name].error = false
    fields[e.target.name].valid = true
    this.setState({ fields })
  }

  editHandler = () => {
    this.setState({
      viewMode: false
    })
  }

  getVendorEmail = (emailsData) => {
    const { fields } = this.state
    const emails = []
    emailsData && emailsData.forEach(email => emails.push({ email: email.value, name: email.name, vendorId: email.vendorId, title: email.title }))
    fields.vendorList.value = emails
    this.setState({ vendorEmails: emails, fields })
  }

  getFilteredData = (data) => {
    this.setState({ filteredData: data })
  }

  dialogClose = (res = {}) => {
    const fields = this.state.fields
    fields.description.value = ''
    fields.vendorType.value = 'Supplier'
    fields.attachments.value = []

    this.setState({
      step: 1,
      fields,
      rfqId: null
    })
    this.props.dialogClose(res)
  }
  changeStep = () => {
    let step = 1
    if (this.state.step === 1) {
      const fields = this.state.fields
      if (fields.description.value.trim() === '') {
        fields.description.touched = true
        fields.description.error = 'This Field is Required'
        fields.description.valid = false
      } else if (!Array.isArray(fields.vendorList.value) || !fields.vendorList.value.length) {
        fields.vendorList.touched = true
        fields.vendorList.error = 'Select at least One Vendor!'
        fields.vendorList.valid = false
      } else {
        this.setState({
          step: 2
        })
      }
      this.setState({ fields })
    } else if (this.state.step === 2) {
      const { filteredData, fields, attachmentArr } = this.state

      const attachmentsLength = Array.isArray(fields.attachments.value) && fields.attachments.value.length
      const projectAttachmentsLenght = Array.isArray(attachmentArr) && attachmentArr.length
      const filteredDataLength = Array.isArray(filteredData) && filteredData.length
      if (!attachmentsLength && !projectAttachmentsLenght && !filteredDataLength) {
        this.props.warningSnackBar('Please select at least one Item or Attachment!')
      } else {
        this.setState({ step: 3 })
      }
    } else if (this.state.step === 3) {
      this.setState({
        step
      })
    }

  }
  backStep = () => {
    let step = 1
    if (this.state.step > 1) {
      step = this.state.step - 1
    }
    this.setState({
      step
    })
  }

  sendRFQ = () => {
    const { fields, vendorEmails } = this.state
    const formData = new FormData()
    formData.set('vendorType', fields.vendorType.value)
    formData.set('description', fields.description.value)
    formData.set('dueDate', this.props.projectDueDate || new Date())
    formData.set('draft', false)

    if (Array.isArray(vendorEmails) && !vendorEmails.length) {
      this.props.warningSnackBar('Please select at least one Vendor!')
      return
    }

    for (let i = 0; i < vendorEmails.length; i++) {
      formData.append('vendorList[]', JSON.stringify(vendorEmails[i]))
    }

    for (let i = 0; i < this.state.filteredData.length; i++) {
      formData.append('items[]', JSON.stringify(this.state.filteredData[i]))
    }
    let { attachmentArr } = this.state
    for (let i = 0; i < attachmentArr.length; i++) {
      if (!_.isString(attachmentArr[i])) {
        attachmentArr[i] = JSON.stringify(attachmentArr[i])
      }
      formData.append('attachment[]', (attachmentArr[i]))
    }

    for (let x = 0; x < fields.attachments.value.length; x++) {
      formData.append('attachment', fields.attachments.value[x])
    }
    if (this.state.rfqId) {
      this.props.editRFQ(formData, this.state.rfqId, 'RFQ Send Successfully').then(res => {
        this.dialogClose(res)
      }).catch(e => console.log(e))
    } else {
      this.props.saveRFQ(formData, Cookies.get('projectId'), 'RFQ Send Successfully').then(res => {
        this.dialogClose()
      }).catch(e => console.log(e))
    }

  }

  saveAsDraft = () => {
    const { fields } = this.state
    const formData = new FormData()
    formData.set('vendorType', fields.vendorType.value)
    formData.set('description', fields.description.value)
    formData.set('dueDate', this.props.projectDueDate)
    formData.set('draft', true)
    //formData.append('vendorList[]', null)

    for (let i = 0; i < this.state.stages.length; i++) {
      formData.append('items[]', JSON.stringify(this.state.stages[i]))
    }
    for (let i = 0; i < this.state.options.length; i++) {
      formData.append('option_items[]', JSON.stringify(this.state.options[i]))
    }
    let { attachmentArr } = this.state
    for (let i = 0; i < attachmentArr.length; i++) {
      if (!_.isString(attachmentArr[i])) {
        attachmentArr[i] = JSON.stringify(attachmentArr[i])
      }
      formData.append('attachment[]', (attachmentArr[i]))
    }

    for (let x = 0; x < fields.attachments.value.length; x++) {
      formData.append('attachment', fields.attachments.value[x])
    }
    if (this.state.rfqId) {
      this.props.editRFQ(formData, this.state.rfqId, 'RFQ Updated Successfully').then(res => {
      })
    } else {
      this.props.saveRFQ(formData, Cookies.get('projectId'), 'RFQ Saved Successfully').then(res => {
      })
    }
  }

  addProjectDocuments = async () => {
    const projectId = Cookies.get('projectId')
    const project = await this.props.getProject(projectId)
    const data = idx(project, _ => _.attachment) || []
    const attachmentArr = []
    Array.isArray(data) && data.forEach(doc => {
      if (_.isString(doc) && !(_.isError(_.attempt(JSON.parse, doc)))) {
        attachmentArr.push(JSON.parse(doc))
      } else {
        attachmentArr.push(doc)
      }
    })
    this.setState({ attachmentArr })
  }

  handleAddVendorClose = async () => {
    const { fields } = this.state
    const allVendorsList = await this.props.getVendorList()
    this.setState({ addVendorDialog: false, allVendorsList }, () => this.setVendorList(idx(fields, _fields => _fields.vendorType.value) || 'Supplier'))
  }

  render() {
    const {
      fields,
      viewMaterialData,
      viewOptionMaterialData,
      selectedItems,
      step,
      materialData,
      allIds,
      stages,
      options,
      vendorEmails,
      addVendorDialog
    } = this.state
    const { projectName, customerName, totalAmount, projectDueDate, hasStages = true } = this.props
    const { open } = this.props
    const writePermission = this.props.userAccessControl === 2 ? true : false
    const title = `Create a RFQ for - "${projectName}"`
    let sno = 0

    const files = []
    const allAttachments = []
    for (var x = 0; x < fields.attachments.value.length; x++) {
      const index = x
      allAttachments.push({ name: fields.attachments.value[x].name, url: null })
      files.push(
        <div className="attachedFiles">
          <p style={{ margin: '0 10px 0 0' }}>{fields.attachments.value[x].name}</p>
          <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(index, 'new')}><DeleteIcon /></p>
        </div>
      )
    }

    const attachments = this.state.attachmentArr.map((doc, i) => {
      if (_.isString(doc) && !(_.isError(_.attempt(JSON.parse, doc)))) {
        doc = JSON.parse(doc)
      }
      allAttachments.push({ name: doc.name, url: doc.url })
      return (
        <div className="attachedFiles">
          <p style={{ margin: '0 10px 0 0' }}><a href={doc?.url} rel="noopener noreferrer" target="_blank">{doc.name}</a></p>
          <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'old')}><DeleteIcon /></p>
        </div>
      )
    })

    return (<>
      <Modal
        open={addVendorDialog}
        fullWidth={true}
        maxWidth='lg'
        onClose={this.handleAddVendorClose}
        modalHeading={idx(fields, _fields => _fields.vendorType.value) === 'Supplier' ? 'Add New Supplier' : 'Add New Contractor'}
      >
        {idx(fields, _fields => _fields.vendorType.value) == 'Supplier' && <AddSupplier
          open={addVendorDialog}
          isDialog={true}
          vendorType={idx(fields, _fields => _fields.vendorType.value) || 'Supplier'}
          handleClose={this.handleAddVendorClose}
          writePermission={this.props.permissions.supplier == 2}
        />}

        {idx(fields, _fields => _fields.vendorType.value) == 'sub-contractor' && <AddSubContractor
          open={addVendorDialog}
          isDialog={true}
          vendorType={idx(fields, _fields => _fields.vendorType.value) || 'Supplier'}
          handleClose={this.handleAddVendorClose}
          writePermission={this.props.permissions.contractor == 2}
        />}

      </Modal>
      <Dialog className="primary"
        onClose={this.dialogClose}
        open={open}
        aria-labelledby='form-dialog-title'
        maxWidth='lg'
        fullWidth={this.state.step !== 3 ? true : false}
      >
        <DialogTitle id='form-dialog-title'>
          <Grid container spacing={3}>
            <Grid item sm={11} xs={10}>{title}</Grid>
            <Grid item sm={1} xs={2} align='right'><Icons icon='cancel' onClick={this.dialogClose} /></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <div className='content createrfqcontainer'>
            <>
              {step === 1 &&
                <>
                  <Grid container>
                    <Grid md={8} xs={12} item>
                      <Grid container className="subtaskrow-1" style={{ margin: '0px' }}>
                        <Grid sm={3} xs={12} item className='stageLabel' style={{ margin: "12px 0px 0px 0px" }}>
                          Description:
                        </Grid>
                        <Grid sm={8} xs={12} item className='stageLabel rfqdesc addStageDesc' style={{ marginTop: '10px', maxWidth: '100%', width: '500px' }}>
                          <Input {...fields.description} onChange={(e) => this.inputChangeHandler(e)} />
                        </Grid>
                      </Grid>
                      {step === 1 && <Grid container className="subtaskrow-1" style={{ margin: "15px 0px" }}>
                        <Grid lg={3} sm={3} xs={12} item>
                          Vendor Type
                        </Grid>
                        <Grid lg={5} sm={8} xs={12} item className='stageLabel attachFile '>
                          <Input {...fields.vendorType} onChange={(e) => this.inputChangeHandler(e)} />
                        </Grid>
                      </Grid>}
                      <Grid container className="subtaskrow-1" style={{ margin: "15px 0px" }}>
                        <Grid xl={3} lg={3} sm={3} item>
                          Send To
                        </Grid>
                        <Grid xl={5} lg={5} sm={4} xs={12} item className='vendorAutocomplete'>
                          <Input
                            {...fields.vendorList}
                            variant='outlined'
                            margin='dense'
                            style={{ width: '100%' }}
                            onChange={(event, values) => { this.getVendorEmail(values) }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>
                                  <IconButton>
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid xl={3} lg={4} sm={5} xs={12} item className='vendorAutocomplete' style={{ textAlign: 'right' }}>
                          {writePermission && <span onClick={() => this.setState({ addVendorDialog: true })} className="addNewRole">
                            <span className="or">Or</span>
                            <div className="AddNewVendorIcon">
                              <IconButton aria-label="add" disabled="true" >
                                <EditIcon />
                              </IconButton>
                              {idx(fields, _fields => _fields.vendorType.value) === 'Supplier' ? 'Add New Supplier' : 'Add New Contractor'}
                            </div>
                          </span>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid md={4} xs={12} item className='stageLabel attachFile '>
                      <Grid xs={12} style={{ alignItems: 'unset' }}>
                        <FileUploader
                          fileHandler={(files) => this.inputChangeHandler({ target: { files, type: 'file', name: 'attachments' } })}
                        />
                        {files}
                        {attachments}
                        <div style={{ margin: '20px 0' }} className="createRFQAddBtn">
                          <Button variant='contained' className='cancel-btn' onClick={this.addProjectDocuments} >Add Project Documents</Button>
                        </div>
                        {/* <p style={{ marginTop: '10px' }} onClick={this.addProjectDocuments} className='cancel-btn'>App Project Documents</p> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  {step === 1 && <Grid container>
                    <Grid md={4} xs={12} item>
                      {stages && stages.map((stage, index) => {
                        return (
                          <div key={index} className="mobileCreateRfqStage" style={{ background: '#FCFCFC', border: '1px solid #f4f4f4', margin: '0px 20px 20px 0px' }}>
                            {stage.selectedRFQ && hasStages &&
                              <div style={{ display: 'flex', background: "#f4f4f4", margin: "0px", padding: "15px" }}>
                                <Input type='checkbox' value={stage.selected} onChange={(e) => this.stageHandler(e.target.checked, index)} />
                                <p>Stage {index + 1}: <b>{stage.name}</b></p>
                              </div>}
                            {stage.tasks.map((task, taskIndex) => {
                              return (
                                <div key={taskIndex} style={{ marginLeft: '14px' }}>
                                  {task.selectedRFQ &&
                                    <div style={{ display: 'flex' }}>
                                      <Input type='checkbox' value={task.selected} onChange={(e) => this.taskHandler(e.target.checked, index, taskIndex)} />
                                      <p>Task {taskIndex + 1}: <b>{task.name}</b></p>
                                    </div>}
                                  <div style={{ marginLeft: '28px' }}>
                                    {task.subTasks.map((subTask, subTaskIndex) => {
                                      return (
                                        subTask.selectedRFQ ?
                                          <div key={subTaskIndex} style={{ display: 'flex' }}>
                                            <Input type='checkbox' value={subTask.selected} onChange={(e) => this.subTaskHandler(e.target.checked, index, taskIndex, subTaskIndex)} />
                                            <p>SubTask {subTaskIndex + 1}:{subTask.name}</p>
                                          </div>
                                          : '')
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                      {options && options.map((option, optionIndex) => {
                        return (
                          <>
                            {option.selectedRFQ &&
                              <div key={optionIndex} style={{ background: '#FCFCFC', border: '1px solid #f4f4f4', margin: '0px 20px 20px 0px' }}>
                                <div style={{ paddingLeft: option.revision ? '28px' : '14px' }}>
                                  <div style={{ display: 'flex' }}>
                                    <Input type='checkbox' value={option.selected} onChange={(e) => this.optionHandler(e.target.checked, optionIndex)} />
                                    <p><div>Option {option.sno}: <span>{option.name}</span>{option.revisionNumber !== 1 ? ' - ' + option.revisionName : ''}</div></p>
                                  </div>
                                </div>
                              </div>
                            }
                          </>
                        )
                      })
                      }
                    </Grid>
                    {fields.vendorType.value === 'Supplier' ?
                      <>
                        <Grid md={8} xs={12} item className="mobileCreateRfqCol">
                          <Grid container className="mobileCreateRfq">
                            <Grid container style={{ background: "#f4f4f4", margin: "0px", padding: "0px 15px" }}>
                              <Grid xs={3} className='create-rfq-items' item><p><b>Items</b></p></Grid>
                              <Grid xs={3} className='create-rfq-items' item><p><b>Quantity</b></p></Grid>
                              <Grid xs={3} className='create-rfq-items' item><p><b>Manufacturer</b></p></Grid>
                              <Grid xs={3} className='create-rfq-items' item><p><b>Part No.</b></p></Grid>
                            </Grid>

                            {viewMaterialData && viewMaterialData.map((material, index) => (

                              <Grid key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: "0px 15px" }}>
                                <Grid xs={3} item>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input type='checkbox' value={material.selected} onChange={(e) => this.selecteItemHandler(e.target.checked, { ...material })} />
                                    {material.item}
                                  </div>
                                </Grid>
                                <Grid xs={3} className='create-rfq-items' item >{material.qty}  {material.unit}</Grid>
                                <Grid xs={3} className='create-rfq-items' item style={{ wordBreak: 'break-word' }}>{material.manufacturer}</Grid>
                                <Grid xs={3} className='create-rfq-items' item >{material.partNo}</Grid>
                              </Grid>
                            ))}
                            {viewOptionMaterialData && viewOptionMaterialData.map((material, index) => (

                              <Grid key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4' }}>
                                <Grid xs={3} item>
                                  <div >
                                    <Input type='checkbox' value={material.selected} onChange={(e) => this.selectedOptionItemHandler(e.target.checked, { ...material })} />
                                    {material.item}
                                  </div>
                                </Grid>
                                <Grid xs={3} className='create-rfq-items' item >{material.qty}  {material.unit}</Grid>
                                <Grid xs={3} className='create-rfq-items' item >{material.manufacturer}</Grid>
                                <Grid xs={3} className='create-rfq-items' item >{material.partNo}</Grid>
                              </Grid>
                            ))}
                          </Grid></Grid>
                      </> :
                      <>
                        <Grid xs={8} item >
                          <Grid container style={{ background: "#f4f4f4", margin: "0px", padding: "1px 15px" }}>
                            <Grid xs={5} item><p>Task</p></Grid>
                            <Grid xs={7} item><p>Description</p></Grid>
                          </Grid>
                          {stages && stages.map((stage, index) => {
                            sno = sno + 1
                            return (
                              <>
                                {stage.selected && hasStages && <Grid key={index} container style={{ backgroundColor: sno % 2 === 0 ? '#F4F4F4' : '#fcfcfc' }}>
                                  <Grid xs={5} item>
                                    <div style={{ display: 'flex', marginLeft: "14px" }}>
                                      <p>Stage {index + 1}: <b>{stage.name}</b></p>
                                    </div>
                                  </Grid>
                                  <Grid xs={7} item><p>{stage.description}</p></Grid>
                                </Grid>}
                                {stage.tasks.map((task, taskIndex) => {
                                  sno = sno + 1
                                  return (
                                    <>
                                      {task.selected && <Grid key={taskIndex} container style={{ backgroundColor: sno % 2 === 0 ? '#F4F4F4' : '#fcfcfc' }}>
                                        <Grid xs={5} item>
                                          <div style={{ display: 'flex', marginLeft: '14px' }}>
                                            <p >Task {taskIndex + 1}: <b>{task.name}</b></p>
                                          </div>
                                        </Grid>
                                        <Grid xs={7} item><p>{task.description}</p></Grid>
                                      </Grid>}
                                      {task.subTasks.map((subTask, subTaskIndex) => {
                                        sno = sno + 1
                                        return (
                                          <>
                                            {subTask.selected && <Grid key={subTaskIndex} container style={{ backgroundColor: sno % 2 === 0 ? '#F4F4F4' : '#fcfcfc' }}>
                                              <Grid xs={5} item>
                                                <div style={{ display: 'flex', marginLeft: '28px' }}>
                                                  <p>SubTask {subTaskIndex + 1}:{subTask.name}</p>
                                                </div>
                                              </Grid>
                                              <Grid xs={7} item><p>{subTask.description}</p></Grid>
                                            </Grid>}
                                          </>
                                        )
                                      })}
                                    </>
                                  )
                                })
                                }
                              </>
                            )
                          })}
                          {options && options.map((option, index) => {
                            sno = sno + 1
                            return (
                              <>
                                {option.selected &&
                                  <Grid key={index} container style={{ backgroundColor: sno % 2 === 0 ? '#F4F4F4' : '#fcfcfc' }}>
                                    <Grid xs={5} item>
                                      <div style={{ display: 'flex', marginLeft: "14px" }}>
                                        <p><div>Option {option.sno}: <span>{option.name}</span>{option.revisionNumber !== 1 ? ' - ' + option.revisionName : ''}</div></p>
                                      </div>
                                    </Grid>
                                    <Grid xs={7} item><p>{option.description}</p></Grid>
                                  </Grid>
                                }
                              </>
                            )
                          }
                          )}
                        </Grid>
                      </>
                    }
                  </Grid>}
                </>}
              {step === 2 &&
                <PreviewRFQ
                  selectedItems={selectedItems}
                  materialData={materialData}
                  allIds={allIds}
                  description={fields.description.value}
                  vendorType={fields.vendorType.value}
                  getfilteredData={this.getFilteredData}
                  projectDueDate={projectDueDate}
                  allAttachments={allAttachments}
                  stages={stages}
                  options={options}
                  vendorEmails={vendorEmails}
                />}
              {step === 3 &&
                <SendRFQ
                  projectName={projectName}
                  customerName={customerName}
                  totalAmount={totalAmount}
                  selectedItems={selectedItems}
                  materialData={materialData}
                  allIds={allIds}
                  vendorType={fields.vendorType.value}
                  getVendorEmail={this.getVendorEmail}
                  projectDueDate={projectDueDate}
                />}
            </>
          </div>
        </DialogContent>
        <DialogActions className="RfqActionButtons" style={{ justifyContent: 'flex-end', padding: "10px 24px 24px" }} >
          {this.state.step !== 1 && <Button variant='contained' className="cancel-btn" style={{ float: 'left' }} onClick={this.backStep} >Back</Button>}
          <Button variant='contained' className="cancel-btn" onClick={this.dialogClose}>Cancel</Button>
          {this.state.step === 1 && <Button variant='contained' className="cancel-btn" onClick={this.saveAsDraft}>Save as Draft</Button>}
          {this.state.step === 1 && <Button variant='contained' className="cancel-btn" onClick={this.changeStep}>Preview RFQ</Button>}
          {this.state.step !== 1 && <Button variant='contained' className="cancel-btn" onClick={this.state.step === 2 && this.sendRFQ}>Send</Button>}
        </DialogActions>
      </Dialog >
    </>)
  }
}

const mapStateToProps = state => ({
  permissions: state.user.userData.permissions
});

const mapDispatchToProps = dispatch => ({
  addStage: bindActionCreators(addStage, dispatch),
  getStage: bindActionCreators(getStage, dispatch),
  updateStage: bindActionCreators(updateStage, dispatch),
  saveRFQ: bindActionCreators(saveRFQ, dispatch),
  editRFQ: bindActionCreators(editRFQ, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getVendorList: bindActionCreators(getVendorList, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateRFQ);
