import React, { Fragment } from 'react'
import { toUSD } from '../../util/currency-formatter'
import idx from 'idx'

function SummaryProposal({ optionsData, stagesData, hasStages, stagesCost, taskCost, optionsCost, totalCost, isVariation }) {

    const viewVariationsData = () => {
        return (
            <>
                <tr>
                    <td className="ps-2 text-left" colSpan={2}>Options: </td>
                </tr>
                {optionsData && optionsData.map((option) => {
                    return (
                        <tr key={option.id}>
                            <td className="text-left">
                                {option.name}{option.revisionNumber !== 1 ? ' - ' + option.revisionName : ''}
                            </td>
                            <td>
                                <span className="text-right">{toUSD(idx(optionsCost, _ => _[option.id].total))}</span>
                            </td>
                        </tr>
                    )
                })}
            </>
        )
    }


    return (
        <div className="col-12 my-3 table-responsive">
            <table className="table-create mb-0 table table-bordered table-row-color">
                <thead>
                    <tr>
                        <th className='w-auto text-left'>Description</th>
                        <th className='text-right'>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {stagesData && stagesData.map((stage, index) => {
                        return (
                            <Fragment key={index}>
                                {stage.selected && hasStages &&
                                    <tr key={stage.id}>
                                        <td className="text-left">
                                            Stage {index + 1}: <b>{stage.name} </b>
                                        </td>
                                        <td>
                                            <span className="text-right">
                                                {toUSD(idx(stagesCost, _ => _[stage.id].total))}
                                            </span>
                                        </td>
                                    </tr>
                                }
                                {stage.tasks.map((stageTask, i) => {
                                    if (stageTask.selected) {
                                        return (
                                            <tr key={stageTask.id}>
                                                <td className="text-left">
                                                    <b>{stageTask.name}</b>
                                                </td>
                                                <td>
                                                    <span className="text-right">
                                                        {toUSD(idx(taskCost, _ => _[stageTask.id].total))}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </Fragment>
                        )
                    })}
                    {isVariation && viewVariationsData()}
                    <tr>
                        <td className='text-right'>
                            <span className="tableinfo">Total</span>
                        </td>
                        <td>
                            <span className="tableinfo text-right">{toUSD(totalCost)}</span>
                        </td>
                    </tr>
                    {(!isVariation && Array.isArray(optionsData) && optionsData.length > 0 && viewVariationsData()) || ''}
                </tbody>
            </table>
        </div>
    )
}

export default SummaryProposal
