import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import _ from "lodash";
import { ADD_ASSET, ASSET_MANAGEMENT } from "./../../constants/route-paths";
import {
  getAssetList,
  discardAsset,
} from "./../../actions/asset-management.action";
import Modal from "../../components/modal/modal";
import AssetSubmit from "./asset-submit";
import "./scss/asset-management.scss";
import { withRouter } from "../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';

class AssetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      assets: [],
      assetId: "",
      submitDialog: false,
      discardDialog: false,
      plantAssetCount: null,
      vehicleAssetCount: null,
      selectedCategory: "ALL",
    };
  }

  componentDidMount() {
    this.getAssetList("ALL");
  }

  getAssetList = async (selectedCategory = "ALL") => {
    let category = "";
    if (selectedCategory === "VEHICLE") category = "VEHICLE";
    else if (selectedCategory === "PLANT_AND_EQUIPMENT")
      category = "PLANT_AND_EQUIPMENT";

    const assets = await this.props.getAssetList(category);

    const data = assets.map((asset) => {
      let allocationName = "",
        allocationDate = "";

      if (_.isObject(asset.assetAllocation)) {
        allocationName = asset.assetAllocation.allocationName;
        allocationDate = asset.assetAllocation.allocationDate;
      }

      return {
        id: asset.id,
        name: asset.name,
        assetNo: asset.assetNo,
        category: asset.category,
        status: asset.status,
        notes: asset.description || "-",
        allocationName: allocationName || "-",
        allocationDate: allocationDate
          ? format(new Date(allocationDate), "dd/MM/yyyy")
          : "-",
      };
    });

    let { vehicleAssetCount, plantAssetCount } = this.state;
    if (vehicleAssetCount === null || plantAssetCount === null) {
      vehicleAssetCount = data.filter(
        (asset) => asset.category === "VEHICLE"
      ).length;
      plantAssetCount = data.filter(
        (asset) => asset.category === "PLANT_AND_EQUIPMENT"
      ).length;
    }

    const columns = [
      { title: "ID No", field: "assetNo" },
      { title: "Asset Name", field: "name" },
      {
        title: "Asset Category",
        field: "category",
        lookup: {
          VEHICLE: "Vehicle",
          PLANT_AND_EQUIPMENT: "Plant & Equipment",
        },
      },
      {
        title: "Status",
        field: "status",
        lookup: {
          AVAILABLE: "Available",
          INUSE: "In-Use",
          MAINTENANCE: "Maintenance",
          DISCARD: "Discard",
          DAMAGED: "Damaged",
        },
      },
      { title: "Notes", field: "notes" },
      { title: "Allocated To", field: "allocationName" },
      { title: "Allocation Date", field: "allocationDate" },
    ];
    this.handleMenuClose();
    this.setState({
      assets: data,
      columns: columns,
      discardDialog: false,
      submitDialog: false,
      selectedCategory,
      vehicleAssetCount,
      plantAssetCount,
    });
  };

  handleMenu = (rowData, writePermission) => {
    this.setState({ assetId: rowData.id, });
  };

  handleAddAsset = () => {
    this.props.history(`/${ASSET_MANAGEMENT}/${ADD_ASSET}`);
  };

  handleDiscardClick = async () => {
    const { assetId } = this.state;
    await this.props.discardAsset(assetId);
    this.getAssetList();
  };

  handleClose = () => {
    this.handleMenuClose();
    this.setState({ submitDialog: false, discardDialog: false });
  };

  handleMenuClose = () => {
    this.setState({ assetId: "" });
  };

  render() {
    const {
      columns,
      assets,
      submitDialog,
      discardDialog,
      assetId,
      vehicleAssetCount,
      plantAssetCount,
    } = this.state;
    const writePermission = this.props.writePermission;

    return (
      <>
        <AssetSubmit
          submitDialog={submitDialog}
          assetId={assetId}
          getAssetList={this.getAssetList}
          handleClose={this.handleClose}
        />
        <Modal
          open={discardDialog}
          onClose={this.handleClose}
          onClick={this.handleDiscardClick}
          modalHeading={
            "Do you want to 'DISCARD' this Asset? You cannot undo this action!"
          }
          modalSaveTxt={"Yes"}
          modalCloseTxt="No"
          showActionBtn={true}
          fullWidth={true}
          maxWidth={"xs"}
          cxlbtnClass="cancelBtn"
          savebtnClass="createBtn"
          primary
        />

        <div className="purchase-order-block">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">Assets</h2>
            </div>
            {writePermission && (<div className="w-auto ms-auto mt-auto">
              <button onClick={this.handleAddAsset} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Add Asset</button>
            </div>)}
          </div>

          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="total"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(k) => {
                  switch (k) {
                    case 'total': this.getAssetList('ALL'); break;
                    case 'vehicle': this.getAssetList('VEHICLE'); break;
                    case 'plant': this.getAssetList('PLANT_AND_EQUIPMENT'); break;
                    default: break;
                  }
                }}
              >
                <Tab eventKey="total" title={`Total Asset (${parseInt(vehicleAssetCount) + parseInt(plantAssetCount)})`}>
                  <CustomTable
                    columns={columns}
                    data={assets}
                    isAction={true}
                    handleMenu={(data) => this.handleMenu(data, writePermission)}
                    menu={(data) => (
                      <Popover id="popover-basic">
                        <Popover.Body>
                          <ul className="action-block-list">
                            <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/edit/${data.id}`) }}>View/Edit Asset</li>
                            {writePermission && <>
                              {data.status == "AVAILABLE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/allocate/${data.id}`) }}>Allocate Asset</li>}
                              {(data.status == "DAMAGED" || data.status == "AVAILABLE") && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/maintenance/${data.id}`) }}>Asset Maintainance</li>}
                              {data.status == "MAINTENANCE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/repair/${data.id}`) }}>Asset Repaired</li>}
                              {data.status == "INUSE" && <li onClick={() => { this.setState({ submitDialog: true }) }}>Submit</li>}
                              {(data.status == "MAINTENANCE" || data.status == "AVAILABLE") && <li onClick={() => { this.setState({ discardDialog: true }) }}>Discard</li>}
                            </>}
                          </ul>
                        </Popover.Body>
                      </Popover>
                    )}
                    fileName='Total Asset List'
                  />
                </Tab>
                <Tab eventKey="vehicle" title={`Vehicle (${parseInt(vehicleAssetCount)})`}>
                  <CustomTable
                    columns={columns}
                    data={assets}
                    isAction={true}
                    handleMenu={(data) => this.handleMenu(data, writePermission)}
                    menu={(data) => (
                      <Popover id="popover-basic">
                        <Popover.Body>
                          <ul className="action-block-list">
                            <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/edit/${data.id}`) }}>View/Edit Asset</li>
                            {data.status == "AVAILABLE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/allocate/${data.id}`) }}>Allocate Asset</li>}
                            {(data.status == "DAMAGED" || data.status == "AVAILABLE") && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/maintenance/${data.id}`) }}>Asset Maintainance</li>}
                            {data.status == "MAINTENANCE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/repair/${data.id}`) }}>Asset Repaired</li>}
                            {data.status == "INUSE" && <li onClick={() => { this.setState({ submitDialog: true }) }}>Submit</li>}
                            {(data.status == "MAINTENANCE" || data.status == "AVAILABLE") && <li onClick={() => { this.setState({ discardDialog: true }) }}>Discard</li>}
                          </ul>
                        </Popover.Body>
                      </Popover>
                    )}
                    fileName='Vehicle List'
                  />
                </Tab>
                <Tab eventKey="plant" title={`Plant & Equipment (${parseInt(plantAssetCount)})`}>
                  <CustomTable
                    columns={columns}
                    data={assets}
                    isAction={true}
                    handleMenu={(data) => this.handleMenu(data, writePermission)}
                    menu={(data) => (
                      <Popover id="popover-basic">
                        <Popover.Body>
                          <ul className="action-block-list">
                            <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/edit/${data.id}`) }}>View/Edit Asset</li>
                            {data.status == "AVAILABLE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/allocate/${data.id}`) }}>Allocate Asset</li>}
                            {(data.status == "DAMAGED" || data.status == "AVAILABLE") && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/maintenance/${data.id}`) }}>Asset Maintainance</li>}
                            {data.status == "MAINTENANCE" && <li onClick={() => { this.props.history(`/${ASSET_MANAGEMENT}/repair/${data.id}`) }}>Asset Repaired</li>}
                            {data.status == "INUSE" && <li onClick={() => { this.setState({ submitDialog: true }) }}>Submit</li>}
                            {(data.status == "MAINTENANCE" || data.status == "AVAILABLE") && <li onClick={() => { this.setState({ discardDialog: true }) }}>Discard</li>}
                          </ul>
                        </Popover.Body>
                      </Popover>
                    )}
                    fileName='Plant & Equipment List'
                  />
                </Tab>
              </Tabs>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAssetList: bindActionCreators(getAssetList, dispatch),
  discardAsset: bindActionCreators(discardAsset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetList)
);
