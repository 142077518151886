import React, { useState } from 'react'
import Modal from '../../components/modal/modal'
import './scss/login.scss'
import Buttons from '../../components/buttons/buttons'
import { Grid } from '@material-ui/core'
import Input from '../../components/inputs/input-simple'
import { emailValidator } from '../../util/validators/email'

function ForgotPassword(props) {
  const { handleDialog } = props
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const submitForm = (fileFormat = 'pdf') => {
    if (email === '') {
      setError('Email is Required')
      return
    }
    if (!error) {
      props.forgotPassword(email)
    }
  }

  const emailChangeHandler = (value) => {
    let status = emailValidator(value)
    if ( !status ) {
      setError('Enter a valid Email!')
    } else {
      setError(false)
    }
    setEmail(value)
  }

  return (
    <Modal
      open
      onClose={handleDialog}
      onClick={submitForm}
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      fullWidth={true}
      maxWidth='sm'
      modalHeading='Forgot Password'
      primary
    >
      <Grid className="forgot-pass-form" xs={12} container >
        <Grid item xs={3} >
          <p style={{ textAlign: 'center' }}><b>Enter Email</b></p>
        </Grid>
        <Grid item xs={6} className='forgot-password'>
          <Input
            type='text'
            value={email}
            onChange={(e) => emailChangeHandler(e.target.value)}
            error={error}
            valid={!error}
            touched
          />
        </Grid>
      </Grid>
      <Grid xs={12} container className='buttonContainer'>
        <Grid xs={12} item>
          <Buttons className='cancel-btn' title='Reset Password' onClick={submitForm} />
          <p className='cancel-btn' onClick={handleDialog}>Cancel</p>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ForgotPassword
