import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import InventoryList from "./inventory-list";
import AddStock from "./add-stock";
import Transactions from "./transactions";
import AllocateItem from "./allocate-item";
import POStock from "./po-stock";
import CreateRFQ from "./create-rfq";
import OverAllStock from "./overall-stock";
import * as paths from "../../constants/route-paths";
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {
  let element = useRoutes([
    {
      path: "",
      element: <RBACApplicationAuth moduleURL={location.pathname} component={InventoryList} />,
    },
    {
      path: paths.ADD_STOCK,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddStock} />,
    },
    {
      path: paths.TRANSACTIONS,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={Transactions} />,
    },
    {
      path: paths.ALLOCATE_ITEM,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AllocateItem} />,
    },
    {
      path: paths.PO_STOCK,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={POStock} />,
    },
    {
      path: paths.CREATE_RFQ,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={CreateRFQ} />,
    },
    {
      path: paths.EDIT_RFQ,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={CreateRFQ} />,
    },
    {
      path: paths.OVERALL_STOCK,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={OverAllStock} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
