import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/user-management.scss";
import UserRole from "./user-role";
import { USER_MANAGEMENT } from "./../../constants/route-paths";
import {
  addUser,
  getRolesList,
  getUser,
  editUser,
} from "./../../actions/user-management.action";
import { emailValidator } from "../../util/validators/email";
import _ from "lodash";
import idx from "idx";
import { errorSnackBar } from "../../actions/common.action";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import SelectInput from "src/components/inputs/select";
import CustomInput, { AmountInput } from "src/components/inputs/new-input";
import { phoneValidator } from "src/util/validators/phone";
import { passwordValidator } from "src/util/validators/password";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        role: {
          value: "",
          touched: false,
          error: false,
        },
        employmentType: {
          value: "",
          touched: false,
          error: false,
        },
        hourlyRate: {
          value: "",
          touched: false,
          error: false,
        },
        firstName: {
          value: "",
          touched: false,
          error: false,
        },
        lastName: {
          value: "",
          touched: false,
          error: false,
        },
        email: {
          value: "",
          touched: false,
          error: false,
          disabled: false,
        },
        phoneNumber: {
          value: "",
          touched: false,
          error: false,
        },
        passwordType: {
          // value: 'default',
          touched: false,
          error: false,
        },
        password: {
          value: "",
          touched: false,
          error: false,
        },
        modifiedPassword: {
          value: "",
          touched: false,
          error: false,
          disabled: false,
        },
        availableDays: {
          days: {
            monday: {
              label: "Monday",
              value: false,
            },
            tuesday: {
              label: "Tuesday",
              value: false,
            },
            wednesday: {
              label: "Wednesday",
              value: false,
            },
            thursday: {
              label: "Thursday",
              value: false,
            },
            friday: {
              label: "Friday",
              value: false,
            },
            saturday: {
              label: "Saturday",
              value: false,
            },
            sunday: {
              label: "Sunday",
              value: false,
            },
          },
          value: "",
          touched: false,
          error: false,
        },
      },
      opneAddRoleDialog: false,
      isFormValid: false,
      roles: [],
      employmentType: [],
      passwordType: [
        { value: "default", key: "Default" },
        { value: "custom", key: "Custom" },
      ],
    };
  }

  componentDidMount() {
    this.getRolesList();
    if (this.props.params.userId) {
      this.props.getUser(this.props.params.userId).then((user) => {
        this.editUser(user);
      });
    }
    const employmentType = [
      { value: "5aafb344-fa46-45cd-a1b8-7a08f34e75fb", key: "Contract" },
      { value: "d8c8969f-b824-4ed1-a4a5-db7037374656", key: "Full Time" },
    ];
    this.setState({
      employmentType: employmentType,
    });
  }

  getRolesList = () => {
    this.props.getRolesList().then((roles) => {
      const userRoles = roles.map((role) => {
        return {
          key: role.name,
          value: role.id,
        };
      });
      this.setState({ roles: userRoles });
    });
  };

  editUser = (user) => {
    const { fields } = this.state;
    fields.role.value = user.roles && user.roles.id;
    fields.email.value = user.email || "";
    fields.firstName.value = user.firstName;
    fields.lastName.value = user.lastName;
    fields.employmentType.value = user.employmentType.id;
    fields.hourlyRate.value = user.hourlyRate;
    fields.phoneNumber.value = user.phone_number;
    fields.email.disabled = !this.props.aUserPermission;
    fields.modifiedPassword.disabled = !this.props.aUserPermission;
    let available_days = idx(user, (_) => _.available_days.days) || {};
    for (let day in available_days) {
      fields.availableDays.days[day].value = available_days[day] || false;
    }
    this.setState({
      fields,
      edit: true,
      userId: user.id,
    });
  };

  inputChangeHandler = (e, fieldName = "") => {
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value || "";
    }
    if (fieldName === "phoneNumber") {
      name = fieldName;
    }

    if (fieldName === "hourlyRate") {
      if (parseFloat(value || 0) < 0) {
        return;
      }
    }

    let fields = this.state.fields;
    if (fields[name].disabled) return
    fields[name].value = value;
    fields[name].touched = true;
    const { error } = this.handleValidation({ name, value });
    fields[name].error = error;
    let isFormValid = true;
    if (name === "passwordType") {
      if (value === "default") {
        fields.password.value = "";
        fields.password.touched = false;
        fields.password.error = false;
      } else {
        fields.password.value = "";
        fields.password.touched = false;
        fields.password.error = true;
      }
    }

    for (let key of Object.keys(this.state.fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }
    this.setState({ fields: fields, isFormValid });
  };

  daysInputChangeHandler = (e, name = "") => {
    var value = e.target.checked || false;
    let fields = this.state.fields;
    fields.availableDays.days[name].value = value;
    fields.availableDays.touched = true;
    let isFormValid = true;
    for (let key of Object.keys(this.state.fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }
    this.setState({ fields: fields, isFormValid });
  };

  handleValidation = ({ name, type, value }) => {
    let error = false;
    if (name === "modifiedPassword") {
      let status = passwordValidator(value)
      if (!value) {
        return { error };
      } else if (!status.valid) {
        return status;
      }
    }
    if (
      value === undefined ||
      (!_.isBoolean(value) &&
        (typeof value === "string" ? value.trim() === "" : ""))
    ) {
      return { error: "This Field is Required" };
    }
    if (name === "email") {
      let status = emailValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Email" };
      }
    }
    if (name === "phoneNumber") {
      let status = phoneValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Mobile Number" };
      }
    }

    if (name === "password") {
      let status = passwordValidator(value);
      return status;
    }
    if (name === "firstName") {
      const firstexp = /^(?!.*[`!@#$%^&*_=+?{}[]|:;"<>?]).*/;
      if (!firstexp.test(value)) {
        return { error: "FirstName must contain only characters" };
      }
    }
    if (name === "lastName") {
      if (!value) {
        return { error: "This Field is required " };
      }
      const lastexp = /^(?!.*[`!@#$%^&*_=+?{}[]|:;"<>?]).*/;
      if (!lastexp.test(value)) {
        return { error: "LastName must contain only characters" };
      }
    }
    if (name === "hourlyRate") {
      if (value === 0 || value === "") {
        return { error: "This Field is required " };
      }
    }
    return { error };
  };

  cancelHandler = () => {
    this.props.history(`/${USER_MANAGEMENT}`);
  };

  submitForm = () => {
    const { fields, edit, userId } = this.state;

    let availableDays = { days: {} };
    let availableDaysCheck = false;
    for (let key in fields.availableDays.days) {
      availableDays.days[key] = fields.availableDays.days[key].value;
      if (availableDays.days[key]) {
        availableDaysCheck = true;
      }
    }
    if (!availableDaysCheck) {
      this.props.errorSnackBar("Select Available Days");
      return;
    }
    // var trycheck = Object.entries(availableDays).filter(([key, value]) => value === true);

    let isFormValid = true;
    for (let key in fields) {
      if (
        key === "modifiedPassword" ||
        fields.passwordType.value === "default" ||
        ((key === "passwordType" || key === "password") && edit) ||
        (key === "availableDays" && availableDaysCheck)
      ) {
        isFormValid = isFormValid && true;
      } else {
        const { error } = this.handleValidation({ name: key, ...fields[key] });
        fields[key].touched = true;
        fields[key].error = error;
        isFormValid = isFormValid && !error;
      }
    }
    if (!isFormValid) {
      this.setState({ fields: fields, isFormValid });
    } else {
      const data = {
        firstName: fields.firstName.value,
        lastName: fields.lastName.value || '',
        role: fields.role.value,
        employmentType: fields.employmentType.value,
        hourlyRate: fields.hourlyRate.value,
        phoneNumber: fields.phoneNumber.value,
        email: fields.email.value,
        availableDays,
      };
      if (!edit) {
        data.password =
          fields.passwordType.value === "custom" ? fields.password.value : null;
        data.status = "ACTIVE";
      }
      if (fields.modifiedPassword.value) {
        data.modifiedPassword = fields.modifiedPassword.value;
      }
      if (edit) {
        this.props.editUser(data, userId).then((res) => {
          this.props.history("/user-management");
        }).catch(e => console.error(e));
      } else {
        this.props.addUser(data).then((res) => {
          this.props.history("/user-management");
        }).catch(e => console.error(e));
      }
    }
  };

  AddRoleDialogHandler = () => {
    this.getRolesList();
    const dialogState = this.state.opneAddRoleDialog;
    this.setState({ opneAddRoleDialog: !dialogState });
  };

  render() {
    const {
      fields,
      roles,
      employmentType,
      passwordType,
      opneAddRoleDialog,
      edit,
    } = this.state;
    const { writePermission } = this.props;
    let availableDaysFormElements = Object.keys(fields.availableDays.days).map((key) => {
      return (
        <Form key={key} className="usercheckbox me-3">
          <div className="table-checkbox">
            <Form.Check
              type={'checkbox'}
              id={`${key}-check`}
              label={fields.availableDays.days[key].label}
              value={fields.availableDays.days[key].value}
              checked={fields.availableDays.days[key].value}
              onChange={(e) => this.daysInputChangeHandler(e, key)}
            />
          </div>
        </Form>
      );
    });

    return (
      <>
        <UserRole
          open={opneAddRoleDialog}
          handleClose={this.AddRoleDialogHandler}
          addUser
        />
        <div className="purchase-order-block">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">
                {this.props.params.userId ? "Edit User" : "Add a User"}
              </h2>
              <div className="col-12 breadcrumb-block p-0">
                <Breadcrumb>
                  <Breadcrumb.Item onClick={this.cancelHandler}>USERS</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {this.props.params.userId ? "Edit User" : "Add a User"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="w-auto ms-auto">
              {writePermission && <button onClick={this.AddRoleDialogHandler} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">
                Create New User Role
              </button>}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2 className="content-heading content-heading-lg text-uppercase my-auto me-auto">Basic Details</h2>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <SelectInput
                label="Select User Role"
                value={fields.role.value}
                valid={!fields.role.error}
                touched={fields.role.touched}
                error={fields.role.error}
                onChange={(e) => this.inputChangeHandler(e)}
                name="role"
                items={roles}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <SelectInput
                label="Select Employment Type"
                value={fields.employmentType.value}
                valid={!fields.employmentType.error}
                touched={fields.employmentType.touched}
                error={fields.employmentType.error}
                onChange={(e) => this.inputChangeHandler(e)}
                name="employmentType"
                items={employmentType}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <AmountInput
                type="number"
                label="Hourly Rate"
                value={fields.hourlyRate.value}
                valid={!fields.hourlyRate.error}
                touched={fields.hourlyRate.touched}
                error={fields.hourlyRate.error}
                onChange={(e) => this.inputChangeHandler(e, "hourlyRate")}
                name="hourlyRate"
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                label="First Name"
                value={fields.firstName.value}
                valid={!fields.firstName.error}
                touched={fields.firstName.touched}
                error={fields.firstName.error}
                onChange={(e) => this.inputChangeHandler(e, "firstName")}
                name="firstName"
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                label="Last Name"
                value={fields.lastName.value}
                valid={!fields.lastName.error}
                touched={fields.lastName.touched}
                error={fields.lastName.error}
                onChange={(e) => this.inputChangeHandler(e, "lastName")}
                name="lastName"
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                label="Email"
                value={fields.email.value}
                valid={!fields.email.error}
                touched={fields.email.touched}
                error={fields.email.error}
                disabled={fields.email.disabled}
                onChange={(e) => this.inputChangeHandler(e, "email")}
                name="email"
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                label="Phone Number"
                value={fields.phoneNumber.value}
                valid={!fields.phoneNumber.error}
                touched={fields.phoneNumber.touched}
                error={fields.phoneNumber.error}
                onChange={(e) => this.inputChangeHandler(e, "phoneNumber")}
                name="phoneNumber"
              />
            </div>
            {edit ? (
              writePermission && (
                <div className="col-lg-4 col-md-6 mt-3">
                  <CustomInput
                    label="New password"
                    value={fields.modifiedPassword.value}
                    valid={!fields.modifiedPassword.error}
                    touched={fields.modifiedPassword.touched}
                    error={fields.modifiedPassword.error}
                    disabled={fields.modifiedPassword.disabled}
                    onChange={(e) => this.inputChangeHandler(e)}
                    name="modifiedPassword"
                  />
                  {fields.modifiedPassword.value && !fields.modifiedPassword.error && (
                    <small className="text-danger">
                      Password will be modified for this user!
                    </small>
                  )}
                </div>
              )
            ) : (
              <div className="col-lg-4 col-md-6 mt-3">
                <SelectInput
                  label="Password Type"
                  value={fields.passwordType.value}
                  valid={!fields.passwordType.error}
                  touched={fields.passwordType.touched}
                  error={fields.passwordType.error}
                  onChange={(e) => this.inputChangeHandler(e)}
                  name="passwordType"
                  items={passwordType}
                />
              </div>
            )}
            {fields.passwordType.value === "custom" && (
              <div className="col-lg-4 col-md-6 mt-3">
                <CustomInput
                  label="Password"
                  value={fields.password.value}
                  valid={!fields.password.error}
                  touched={fields.password.touched}
                  error={fields.password.error}
                  onChange={(e) => this.inputChangeHandler(e)}
                  name="password"
                />
              </div>
            )}
            <div className="col-12 my-2">
              <hr></hr>
            </div>
            <div className="col-12">
              <h2 className="content-heading content-heading-sm text-uppercase my-auto me-auto">Available Days</h2>
            </div>
            <div className="col-12 mt-3 text-left mb-3">
              {availableDaysFormElements}
            </div>
          </div>

          <div className="col-12 d-flex justify-content-end gap-3 mt-3">
            {writePermission && <Button onClick={this.submitForm} className="secondarybtn">DONE</Button>}
            <Button onClick={this.cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addUser: bindActionCreators(addUser, dispatch),
  getRolesList: bindActionCreators(getRolesList, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
  editUser: bindActionCreators(editUser, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUser)
);
