import { subTaskCalculation } from "src/util/calculations/calculate-sub-task"
import { formatLabors, formatMaterials, formatSubcontractors } from "./task"

export const formatSubTaskData = (data, name = '') => {
    let { material = [], labor = [], subcontractor = [] } = data || {}
    material = formatMaterials(material).map(item => JSON.stringify(item))
    labor = formatLabors(labor).map(item => JSON.stringify(item))
    subcontractor = formatSubcontractors(data.subcontractor).map(item => JSON.stringify(item))
    let calculation = subTaskCalculation({
        subtask: { ...data, material, labor, subcontractor },
        profitMargin: 0,
        profitMarginType: 'PERCENTAGE',
    })

    return {
        description: name || data.description,
        material_cost: parseFloat(calculation.material.cost || 0),
        subcontractor_cost: parseFloat(calculation.subcontractor.cost || 0),
        labor_cost: parseFloat(calculation.labor.cost || 0),
        material: material,
        labor: labor,
        subcontractor: subcontractor
    }
}