import React from "react";
import idx from "idx";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "src/util/history";

export default function RBACProjectAuth({ moduleURL = '', component: Component }) {

  let [searchParams] = useSearchParams();
  let user = useSelector(state => state.user.userData)
  let projectCategories = useSelector(state => state.project.projectCategories) || []
  let projectType = useSelector(state => state.project.projectType)
  const globalUser = idx(user, _ => _.global_user) || false;
  const userPermissions = user.permissions || {};

  projectType = searchParams.get("project-type") || projectType
  if (!projectType) projectType = Cookies.get("projectType");

  let projectTypeObj = projectCategories.find((o) => o.type === projectType);

  let financePermission = 0;
  let userAccessControl = 0;
  let updatePermission = false;
  let writePermission = false;

  if (projectTypeObj) {
    financePermission = globalUser && idx(user, _ => _.selected_account.id) ? 2 : idx(userPermissions, _ => _[`project${projectTypeObj.type}Finance`]);
    userAccessControl = globalUser && idx(user, _ => _.selected_account.id) ? 2 : idx(userPermissions, _ => _[`project${projectTypeObj.type}`]);
  }

  updatePermission = userAccessControl === 2 || globalUser ? true : false;
  writePermission = userAccessControl === 2 || globalUser ? true : false;

  const path = moduleURL.split("/")[2] || "";

  switch (path) {
    // case 'add':
    //   addProject = writePermission;
    //   break;
    case 'variation':
      if (financePermission === 0) {
        if (history.navigate)
          history.navigate(Cookies.get("projectId") ? `/projects/view/${Cookies.get("projectId")}` : `/projects?project-type=${projectType}`);
        return "";
      }
      break;
    case 'invoices':
      if (financePermission === 0) {
        if (history.navigate)
          history.navigate(Cookies.get("projectId") ? `/projects/view/${Cookies.get("projectId")}` : `/projects?project-type=${projectType}`);
        return "";
      }
      break;
    case 'rfq':
      if (financePermission === 0) {
        if (history.navigate)
          history.navigate(Cookies.get("projectId") ? `/projects/view/${Cookies.get("projectId")}` : `/projects?project-type=${projectType}`);
        return "";
      }
      break;
    case 'budget':
      if (financePermission === 0) {
        if (history.navigate)
          history.navigate(Cookies.get("projectId") ? `/projects/view/${Cookies.get("projectId")}` : `/projects?project-type=${projectType}`);
        return "";
      }
      break;
    default: break;
  }

  return (
    <Component
      permissions={{
        financePermission,
        userAccessControl,
        updatePermission,
        writePermission,
      }}
      userAccessControl={userAccessControl}
      financePermission={financePermission > 0 ? true : false}
      rFinancePermission={globalUser && user.selected_account?.id ? true : userPermissions.finance > 0 ? true : false}
      wFinancePermission={globalUser && user.selected_account?.id ? true : userPermissions.finance > 1 ? true : false}
      rSupplierPermission={globalUser && user.selected_account?.id ? true : userPermissions.supplier > 0 ? true : false}
      wSupplierPermission={globalUser && user.selected_account?.id ? true : userPermissions.supplier > 1 ? true : false}
      rContractorPermission={globalUser && user.selected_account?.id ? true : userPermissions.contractor > 0 ? true : false}
      wContractorPermission={globalUser && user.selected_account?.id ? true : userPermissions.contractor > 1 ? true : false}
      wTemplatePermission={globalUser && user.selected_account?.id ? true : userPermissions.template > 1 ? true : false}
      updatePermission={updatePermission}
      writePermission={writePermission}
    />
  )

}
