import React, { useEffect, useState } from "react";
import AddMaterialPO from "src/util/add-material-po";
import { getMaterialsList } from "src/util/common";
import Modal from "src/components/modal/modal";
import DatePickerInput from "src/components/inputs/date-picker";
import SelectInput from "src/components/inputs/select";
import { useParams } from "react-router";
import CustomInput from "src/components/inputs/new-input";
import { createPOFields } from "./data/create-po";
import TextAreaInput from "src/components/inputs/textarea-input";
import idx from "idx";
import _ from "lodash";
import { deliveryOptions } from "src/constants/fields.constant";

const CreatePOComponent = ({ projectId, breadcrumb, ...props }) => {


    const [fields, setFields] = useState(createPOFields)
    const [state, setState] = useState({
        poId: '',
        tasksList: [],
        taskItems: [],
        tasks: [],
        selectedItems: [],
        confirmDialog: false,
        edit: false,
        vendorsList: [],
    })

    const { poId } = useParams();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        await getVendorList();
        if (projectId && !poId) {
            await projectChangeHandler(projectId);
        } else {
            await getProjectList();
        }
        if (poId) await getPO();
    }

    const getPO = async () => {
        const query = `?poId=${poId}`;
        let res = await props.getPurchaseOrder(query);

        setFields(prev => ({
            ...prev,
            quote_no: { ...prev.quote_no, value: res.quoteNo || "" },
            project: { ...prev.project, value: res.projectId || "" },
            tasksList: { ...prev.tasksList, value: res.taskId || "" },
            vendor: { ...prev.vendor, value: res.vendorId || "" },
            deliveryOption: { ...prev.deliveryOption, value: res.deliveryOption || "" },
            deliveryDate: { ...prev.deliveryDate, value: res.deliveryDate || "" },
            notes: { ...prev.notes, value: res.notes || "" },
            street_address: { ...prev.street_address, value: res.street_address || '' },
            suburb: { ...prev.suburb, value: res.suburb || '' },
            state: { ...prev.state, value: res.state || '' },
            postcode: { ...prev.postcode, value: res.postcode || '' },
            country: { ...prev.country, value: res.country || '' },
        }));

        setState(prev => ({
            ...prev,
            taskItems: res.items || [],
            poId: res.u_id,
            uPoId: res.id,
            edit: true,
        }));

        if (!projectId) await getProjectList();
        await projectChangeHandler(res.projectId);
    };

    const getProjectList = async () => {
        // let poCount;
        let list = await props.getProjectList(null, "PROJECT");
        // if (!poId) poCount = await props.getPoCount();

        let projects = [];
        list && list.forEach(({ name, id }) => projects.push({ key: name, value: id }));
        projects = _.orderBy(projects, [({ key }) => key.toLowerCase()], ["asc"]);

        setFields(prev => ({
            ...prev,
            project: {
                ...prev.project, items: projects,
                ...(projectId ? { value: projectId } : {})
            }
        }));
        setState(prev => ({
            ...prev,
            poId: poId ? poId : undefined, //poCount && poCount.maxId + 1,
        }));
    };

    const getVendorList = async () => {
        const vendorsList = await props.getVendorList("Supplier");
        const vendors = (Array.isArray(vendorsList) && vendorsList.map(({ id, businessName }) => ({
            key: businessName,
            value: id,
        }))) || [];
        setFields(prev => ({
            ...prev,
            vendor: { ...prev.vendor, items: vendors },
            deliveryOption: { ...prev.deliveryOption, items: deliveryOptions },
        }));
        setState(prev => ({
            ...prev,
            vendorsList
        }))
    };

    const changeHandler = (type, value) => {
        setFields(prev => ({
            ...prev,
            [type]: { ...prev[type], value: value, error: false, valid: true },
        }));
    };

    const projectChangeHandler = async (value) => {
        const project = await props.getProject(value);
        const stages = idx(project, (_) => _.stages);
        const hasStages = idx(project, (_) => _.hasStages);
        setFields(prev => ({
            ...prev,
            project: {
                ...prev.project, value: value,
                ...(projectId ? { items: [{ key: project.name, value: value }] } : {})
            },
            tasksList: { ...prev.tasksList, value: '', items: [] },
        }));
        setState(prev => ({
            ...prev,
            tasksList: [],
            taskItems: [],
            tasks: [],
            selectedItems: [],
        }))

        getStages(stages, hasStages);
    };

    const getStages = async (stages, hasStages) => {
        let tasksList = [], _tasks = [];
        Array.isArray(stages) && stages.forEach(({ tasks, id, name, description }) => {
            _tasks.push(...tasks);
            Array.isArray(tasks) && tasks.forEach(({ id, name, subTasks, description, material }) => {
                tasksList.push({
                    key: name,
                    value: id,
                    taskDescription: description,
                    taskType: "TASK",
                });
            });
        });
        setState(prev => ({
            ...prev,
            tasksList, stages, tasks: _tasks, taskItems: []
        }));
        setFields(prev => ({
            ...prev,
            tasksList: { ...prev.tasksList, items: tasksList, all_items: _tasks },
        }));
    };

    const vendorChangeHandler = (value) => {
        let { vendorsList = [] } = state;
        let vendor = vendorsList.find(item => item.id == value) || {}

        setFields(prev => ({
            ...prev,
            vendor: { ...prev.vendor, value: value, error: false, valid: true },
            street_address: { ...prev.street_address, value: vendor.street_address || '', error: false, valid: true },
            suburb: { ...prev.suburb, value: vendor.suburb || '', error: false, valid: true },
            state: { ...prev.state, value: vendor.state || '', error: false, valid: true },
            postcode: { ...prev.postcode, value: vendor.postcode || '', error: false, valid: true },
            country: { ...prev.country, value: vendor.country || '', error: false, valid: true },
        }));
    };

    const handleValidation = ({ name, value, validation }) => {
        let error = false;
        let valid = true;
        if (validation.includes("required") && _.isEmpty(value)) {
            return { error: "This Field is Required", valid: false };
        }
        if (validation.includes("optional") && _.isEmpty(value)) {
            return { error: "", valid: true };
        }
        return { error, valid };
    };

    const submitForm = async (selectedItems) => {
        let isFormValid = true;
        let isConfirmDialog = false;
        let _fields = _.cloneDeep(fields)
        for (let key in _fields) {
            const { error, valid } = handleValidation(fields[key]);
            _fields[key].touched = true;
            _fields[key].valid = valid;
            _fields[key].error = error;
            isFormValid = isFormValid && valid;
        }

        if (!_fields.project.value) {
            props.warningSnackBar("Please Select Project");
        }

        if (!_fields.vendor.value) {
            props.warningSnackBar("Please Select Vendor");
        }

        if (selectedItems.length === 0 || (selectedItems.selectedItems && selectedItems.selectedItems.length === 0)) {
            isConfirmDialog = true;
        }

        if (!isFormValid) {
            setFields(_fields)
            setState(prev => ({
                ...prev,
                selectedItems
            }));
        } else if (isConfirmDialog) {
            setState(prev => ({
                ...prev,
                confirmDialog: isConfirmDialog, selectedItems
            }));
        } else {
            setFields(_fields)
            setState(prev => ({
                ...prev,
                selectedItems
            }));
            await createPO(selectedItems)
        }
    };

    const createPO = async (selectedItems) => {
        let { edit } = state;
        selectedItems.forEach((item) => {
            item.costPerItem = item.costPerItem.value;
            item.name = item.name.value;
            item.qty = parseFloat(item.qty.value || 0);
            item.total = item.total.value;
            item.unit = item.unit.value;
        });

        const data = {
            vendorId: fields.vendor.value,
            items: selectedItems,
            taskId: fields.tasksList.value || undefined,
            deliveryOption: fields.deliveryOption.value || undefined,
            deliveryDate: fields.deliveryDate.value || undefined,
            quoteNo: fields.quote_no.value,
            notes: fields.notes.value,
            street_address: fields.street_address.value,
            suburb: fields.suburb.value,
            state: fields.state.value,
            postcode: fields.postcode.value,
            country: fields.country.value,
        };

        data.projectId = fields.project.value;
        if (edit) {
            await props.editPurchaseOrder(data, poId, "Purchase Order Updated Successfully");
        } else {
            await props.savePurchaseOrder(data, "", "Purchase Order Created Successfully");
        }

        props.cancelHandler();
        setState(prev => ({
            ...prev,
            confirmDialog: false
        }));
    };

    const addItemsHandler = () => {
        if (!fields.tasksList.value) return "";
        let tasks = fields.tasksList.all_items || [];
        let taskId = fields.tasksList.value;
        let task = (fields.tasksList.items || []).find((item) => item.value === taskId) || {};
        let materials = getMaterialsList(tasks, task);
        if (materials.status) {
            setState(prev => ({
                ...prev,
                taskItems: materials.data
            }));
        } else {
            props.warningSnackBar(materials.msg);
        }
    };

    return (
        <>
            <Modal
                open={state.confirmDialog}
                onClose={() => {
                    setState(prev => ({
                        ...prev,
                        confirmDialog: false
                    }));
                }}
                onClick={createPO}
                modalHeading={"Confirm"}
                modalContent={
                    "You are creating an empty Purchase Order. Please return to fill in the details once they are available"
                }
                modalSaveTxt={"Continue"}
                modalCloseTxt="Cancel"
                showActionBtn={true}
                maxWidth={"xs"}
                cxlbtnClass="cancelBtn"
                savebtnClass="createBtn"
                primary
            />
            <div className="purchase-order-block">
                <h2 className="content-heading text-uppercase">{state.edit ? `Edit PO - ${state.uPoId}` : "Create PO"}</h2>
                <div className="col-12 breadcrumb-block p-0">
                    {breadcrumb}
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <SelectInput
                                    {...fields.project}
                                    label={'Select Project'}
                                    onChange={(e) => projectChangeHandler(e.target.value)}
                                    disabled={projectId}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <SelectInput
                                    {...fields.vendor}
                                    label={'Select Vendor'}
                                    onChange={(e) => vendorChangeHandler(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mt-3">
                                <SelectInput
                                    {...fields.deliveryOption}
                                    label={'Select Delivery Option'}
                                    onChange={(e) => changeHandler(fields.deliveryOption.name, e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mt-3">
                                <DatePickerInput
                                    {...fields.deliveryDate}
                                    label={'Delivery/Pickup Date'}
                                    onChange={(e) => changeHandler(fields.deliveryDate.name, e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <TextAreaInput
                                    {...fields.notes}
                                    label={'Notes'}
                                    onChange={(e) => changeHandler(fields.notes.name, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 mt-3">
                        <CustomInput
                            {...fields.quote_no}
                            onChange={(e) => changeHandler(fields.quote_no.name, e.target.value)}
                        />
                    </div>
                    <div className="col-lg-4 mt-3">
                        <CustomInput
                            {...fields.street_address}
                            onChange={(e) => changeHandler(fields.street_address.name, e.target.value)}
                        />
                    </div>
                    <div className="col-4 mt-3">
                        <CustomInput
                            {...fields.suburb}
                            onChange={(e) => changeHandler(fields.suburb.name, e.target.value)}
                        />
                    </div>
                    <div className="col-4 mt-3">
                        <CustomInput
                            {...fields.state}
                            onChange={(e) => changeHandler(fields.state.name, e.target.value)}
                        />
                    </div>
                    <div className="col-4 mt-3">
                        <CustomInput
                            {...fields.postcode}
                            onChange={(e) => changeHandler(fields.postcode.name, e.target.value)}
                        />
                    </div>
                    <div className="col-4 mt-3">
                        <CustomInput
                            {...fields.country}
                            onChange={(e) => changeHandler(fields.country.name, e.target.value)}
                        />
                    </div>
                </div>

                <div className="row mt-2 mb-1">
                    <div className="col-12">
                        <hr className="mb-1"></hr>
                    </div>
                    {fields.project.value && (fields.tasksList.items || []).length > 0 && (<>
                        <div className="col-lg-8 col-md-7 mt-1">
                            <SelectInput
                                {...fields.tasksList}
                                label={'Select Task'}
                                onChange={(e) => changeHandler(fields.tasksList.name, e.target.value)}
                            />
                        </div>
                        {fields.tasksList.value && (<div className="col-lg-4 col-md-3 mt-3">
                            <p onClick={addItemsHandler} className="add-item-text">Add items to PO</p>
                        </div>)}
                    </>)}
                </div>


                <AddMaterialPO
                    taskItems={state.taskItems || []}
                    isProjectPO={true}
                    tasksList={state.tasksList}
                    viewPoList={props.cancelHandler}
                    submitForm={submitForm}
                    isSkippable={true}
                />

            </div>
        </>
    )
}

export default CreatePOComponent;