import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getGlobalSetting } from '../../actions/global-setting.action'
import { getAllGlobalSettingTemplate, getGlobalSettingTemplate, deleteGlobalSettingTemplate } from '../../actions/global-setting-template.action'
import { errorSnackBar } from '../../actions/common.action'
import DeleteDialog from '../../components/delete-dialog'
import './scss/templates.scss'
import AddTemplate from './add-template'
import CustomTable from 'src/components/table/table'


class TemplateSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateValue: '',
      fileToUpload: {},
      selectFileType: '',
      tabledata: [],
      deleteDialogState: false,
      deleteObject: null,
      templateOptions: [],
      templateid: null,
      editdata: {},
      templates: {},
      allowedFileExtension: {
        templates: ['.docx']
      }
    }
  }

  componentDidMount() {
    this.getTemplateSetting()
  }

  getTemplateSetting = async () => {
    const templateOptions = [{ "value": 'variation', "key": 'Variation' },
    { "value": 'work_order', "key": 'Work Order' },
    { "value": 'rfq_supplier', "key": 'RFQ Supplier' },
    { "value": 'project_quote', "key": 'Project Quote' },
    { "value": 'purchase_order', "key": 'Purchase Order' },
    { "value": 'rfq_subcontractor', "key": 'RFQ Contractor' },
    { "value": 'project_progress_claim', "key": 'Project Progress Claim' },
    { "value": 'project_progress_claim_breakup', "key": 'Project Progress Claim Breakup' },
    { "value": 'subcontractor_payment_schedule', "key": 'Contractor Payment Schedule' },
    { "value": 'unquoted_project_progress_claim', "key": 'Unquoted Project Progress Claim' }];
    const alltemplatesData = await this.props.getAllGlobalSettingTemplate()

    const data = Object.keys(alltemplatesData).map(key => {
      return {
        type: alltemplatesData[key].type,
        id: alltemplatesData[key].id,
        name: alltemplatesData[key].name,
        filename: alltemplatesData[key].fileName
      }
    })

    this.setState({
      tabledata: data
    })

    const columns = [
      { title: 'Name', field: 'name' },
      { title: 'Type', field: 'type' },
      { title: 'File', field: 'filename' }
    ]

    this.setState({
      columns: columns
    })
    this.setState({ templateOptions, selectFileType: '', fileToUpload: {} })
  }

  handleTemplateChange = (e) => {
    this.setState({ templateValue: e.target.value })
  }



  inputChangeHandler = (e) => {
    let fields = this.state.fields, isFormValid = true

    fields[e.target.name].value = e.target.value
    fields[e.target.name].touched = true

    const { error, valid } = this.handleValidation(fields[e.target.name])

    fields[e.target.name].error = error
    fields[e.target.name].valid = valid

    for (let key of Object.keys(fields)) {
      isFormValid = isFormValid && !fields[key].error
    }

    this.setState({ fields, isFormValid })
  }

  handleValidation = ({ value = '', validation = [] }) => {
    let error = false, valid = true

    if (!validation.includes('skip') && validation.includes('required') && (value === null || value === '')) {
      return { error: 'This Field is Required', valid: false }
    }

    return { error, valid }
  }
  handleFileUpload = (e) => {
    let { selectFileType, fileToUpload, allowedFileExtension } = this.state, isValid = true

    Array.from(e.target.files).map(file => {
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
      if (file.size > 1250000) {
        isValid = false
        this.props.errorSnackBar('File size is too big. Maximum 1.25MB')
      }
      if (!(allowedFileExtension.templates.some(item => item.includes(fileExt)))) {
        isValid = false
        this.props.errorSnackBar(`File format invalid! Allowed formats (${allowedFileExtension.templates.join(',')})`)
      }
    })

    if (isValid)
      this.setState({ fileToUpload: { ...fileToUpload, [selectFileType]: [...(e.target.files)] } })

    e.target.value = ''
  }

  handleTemplateClick = async (templateid = '', editTemplate = false, templateValue = '', templates = '', action = 'new', templateNamePrefix = '', selectedFileName = '') => {
    let { editdata } = this.state
    if (templateid) {
      await this.props.getGlobalSettingTemplate(templateid).then(res => {
        editdata = res
        this.setState({
          editdata: res
        })
      })
    }
    this.setState(prevState => ({
      templateid,
      templateValue,
      templates,
      editTemplate,
      editdata,
      action,
      templateNamePrefix: 'test',
      templateDialogState: !prevState.templateDialogState,
      proposalDialogState: false
    }))
    this.setState({
      templateValue: '',
      templateName: '',
      selectedFileName: '',
    })
    this.getTemplateSetting()
  }



  handleSelectFile = (selectFileType) => {
    this.setState({ selectFileType }, () => {
      document.getElementById("fileInput").click()
    })
  }
  deleteGlobalSetting = async (templateid) => {
    await this.props.deleteGlobalSettingTemplate(templateid)
    this.deleteDialogHandler()
    this.getTemplateSetting()
    this.handleClose()
  }
  deleteDialogHandler = (object = null) => {
    this.setState(prevState => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteObject: object
    }))
  }
  handleClose = () => {
    this.setState({
      templateValue: '',
      templateName: '',
      selectedFileName: '',
    })
  }
  render() {
    let { editdata, selectedFileName, templateNamePrefix, templateid, action, editTemplate, templateValue, templates, deleteDialogState, deleteObject, tabledata, columns, templateDialogState } = this.state
    const writePermission = this.props.writePermission;

    return (
      <>
        <AddTemplate
          action={action}
          open={templateDialogState}
          dialogClose={() => this.handleTemplateClick()}
          editTemplate={editTemplate}
          templates={templates}
          templateid={templateid}
          editdata={editdata}
          handleClose={() => this.handleClose()}
          templateNamePrefix={templateNamePrefix}
          selectedFileName={selectedFileName}
          templateValue={templateValue}
        />
        <DeleteDialog
          open={deleteDialogState}
          id={deleteObject}
          deleteHandler={this.deleteGlobalSetting}
          dialogClose={this.deleteDialogHandler}
          moduleName={'Selected File'}
        />
        <div className="purchase-order-block">
          <CustomTable
            columns={columns}
            data={tabledata}
            addButton={writePermission ? true : false}
            addHandler={() => this.handleTemplateClick()}
            isEdit={writePermission ? true : false}
            editHandler={(item) => this.handleTemplateClick(item.id, true)}
            isDelete={writePermission ? true : false}
            deleteHandler={(item) => this.deleteDialogHandler(item.id)}
            fileName='Templates List'
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getGlobalSetting: bindActionCreators(getGlobalSetting, dispatch),
  getAllGlobalSettingTemplate: bindActionCreators(getAllGlobalSettingTemplate, dispatch),
  getGlobalSettingTemplate: bindActionCreators(getGlobalSettingTemplate, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  deleteGlobalSettingTemplate: bindActionCreators(deleteGlobalSettingTemplate, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetting);
