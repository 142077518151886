import { ValidatorTypes } from "src/util/validators";

export const subContractorContactFields = {
    isPrimary: {
        name: "isPrimary",
        label: "Set as Primary",
        type: "checkbox",
        touched: false,
        error: false,
        valid: true,
        value: false,
    },
    firstName: {
        name: "firstName",
        label: "Given Name(s)",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    lastName: {
        name: "lastName",
        label: "Last Name",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    email: {
        name: "email",
        label: "Email",
        type: "email",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED, ValidatorTypes.EMAIL],
    },
    phoneNumber: {
        name: "phoneNumber",
        label: "Phone No",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED, ValidatorTypes.PHONE_NUMBER],
    },
    designation: {
        name: "designation",
        label: "Job Role",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
}