import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  getAssetList,
  allocateAsset,
} from "../../../actions/asset-management.action";
import { getProject } from "../../../actions/project-management.action";
import Modal from "../../../components/modal/modal";
import "../scss/project-management.scss";
import { withRouter } from "../../../util/with-router";
import { PROJECT_MANAGEMENT } from "../../../constants/route-paths";
import CustomTable from "src/components/table/table";
import Button from 'react-bootstrap/Button';

class AddAssetProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: "",
    };
  }

  componentDidMount() {
    if (this.props.params.projectId) {
      this.getAssetList();
    }
  }

  getAssetList = async () => {
    const projectId = this.props.params.projectId;

    const assets = await this.props.getAssetList("", projectId);

    const data = assets
      .filter((asset) => {
        if (
          _.isObject(asset.assetAllocation) &&
          asset.assetAllocation.typeId === projectId
        ) {
          return false;
        }
        return true;
      })
      .map((asset) => {
        return {
          id: asset.id,
          assetNo: asset.assetNo,
          name: asset.name,
          category: asset.category,
          status: asset.status,
          notes: asset.description || "-",
        };
      });

    const columns = [
      { title: "ID No", field: "assetNo", render: (rowData) => rowData.assetNo },
      { title: "Asset Name", field: "name", render: (rowData) => rowData.name },
      {
        title: "Asset Category",
        field: "category",
        lookup: {
          VEHICLE: "Vehicle",
          PLANT_AND_EQUIPMENT: "Plant & Equipment",
        },
        render: (rowData) => rowData.category == 'VEHICLE' ? 'Vehicle' : rowData.category == 'PLANT_AND_EQUIPMENT' ? 'Plant & Equipment' : rowData.category
      },
      {
        title: "Asset Status",
        field: "status",
        lookup: { INUSE: "In-Use", AVAILABLE: "Available" },
        render: (rowData) => rowData.status == 'INUSE' ? 'In-Use' : rowData.status == 'AVAILABLE' ? 'Available' : rowData.status
      },
      { title: "Notes", field: "notes", render: (rowData) => rowData.notes },
      {
        title: "Action",
        field: "id",
        class: 'text-right',
        render: (rowData) => (
          <Button
            onClick={() => this.handleAddAsset(rowData.id)}
            className="ms-auto secondarybtn"
          >
            Add Asset
          </Button>
        ),
      },
    ];

    this.setState({ assets: data, columns: columns, projectId });
  };

  handleClose = () => {
    const { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/asset/${projectId}`);
  };

  handleAddAsset = async (assetId) => {
    const { projectId } = this.state;
    const { user } = this.props;

    const projectData = await this.props.getProject(projectId);

    const data = {
      type: "PROJECT",
      typeId: projectId,
      allocationDate: new Date(),
      assetId: assetId,
      typeName: projectData.name,
      allocatedBy: user.id,
    };

    await this.props.allocateAsset(data);
    this.getAssetList();
  };

  render() {
    const { columns, assets } = this.state;
    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        onClick={this.submitForm}
        modalHeading={"Add Asset to Project"}
        modalCloseTxt="Done"
        showActionBtn={true}
        fullWidth={true}
        maxWidth={"md"}
        cxlbtnClass="cancelBtn"
        savebtnClass="createBtn"
        primary
      >
        <div className="addAssetModal">
          <CustomTable
            columns={columns}
            data={assets}
            fileName='Assets'
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetList: bindActionCreators(getAssetList, dispatch),
  allocateAsset: bindActionCreators(allocateAsset, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAssetProject)
);
