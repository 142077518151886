import { addLevelAction, addProjectAction, updateProjectAction } from "src/slice/project-detail";
import Cookies from "js-cookie";
import { getLevel } from "../../../actions/inventory.action";
import {
    getProject,
    getProgressClaimList,
    getPoCount,
    getWorkOrderCount,
} from "../../../actions/project-management.action";
import { getInvoiceList } from "../../../actions/invoice.action";
import _ from "lodash";
import { stageCalculation } from "../../../util/calculations";
import idx from "idx";
import { setCookie } from "../../../util/auth";


export const getOnlyProjectData = async ({ projectId, dispatch }) => {
    if (!projectId) return;

    setCookie({ name: "projectId", value: projectId });

    dispatch(getProject(projectId)).then((projectData) => {
        const stagesData = projectData.stages;
        const allStages = [];

        stagesData.forEach((stage) => {
            projectData.hasStages && (stage.selected = true);
            stage.tasks.forEach((task) => {
                task.selected = true;
                task.subTasks.forEach((subTask) => {
                    subTask.selected = true;
                });
            });
        });

        Object.keys(stagesData).map((key) => {
            stagesData[key].tasks.forEach((task, index) => {
                (task.material || []).forEach((material, mIndex) => {
                    if (_.isString(material)) {
                        material = JSON.parse(material);
                        stagesData[key].tasks[index].material[mIndex] = material;
                    }
                    task.subTasks.forEach((subTask, subTaskIndex) => {
                        if (_.isString(material)) {
                            material = JSON.parse(material);
                            stagesData[key].tasks[index].subTask[subTaskIndex].material[mIndex] = material;
                        }
                    });
                });
            });
            allStages.push(stagesData[key]);
        });

        dispatch(addProjectAction({
            res: projectData,
            stages: allStages,
        }))

    });
}

export const getProjectData = async ({ projectId, dispatch }) => {
    if (!projectId) return;

    let progressCostSubcontractor = 0, progressCostLabor = 0, progressCostMaterial = 0;
    let totalApprovedInvoices = 0, invoiceProgress = 0, forecastProfit = 0;
    let completedProgress = 0, totalClaimAmount = 0, totalRetentionAmount = 0;
    let showStageSelect = false;

    setCookie({ name: "projectId", value: projectId });

    const progressClaims = await dispatch(getProgressClaimList(projectId));

    progressClaims.forEach((claims) => {
        if (claims.progressClaimStatus) {
            totalClaimAmount += claims.claimAmount;
            totalRetentionAmount += claims.retentionAmount;
            totalApprovedInvoices++;
        }
    });
    const progressClaimCount = (Array.isArray(progressClaims) && progressClaims.length) || 0;
    const invoices = await dispatch(getInvoiceList(projectId));
    invoices.forEach((inv) => {
        if (inv.progressClaim) {
            invoiceProgress += inv.progressClaim.totalValue;
        }
    });

    const invoicesCount = (Array.isArray(invoices) && invoices.length) || 0;
    const { count: purchaseOrdersCount } = await dispatch(getPoCount(projectId));
    const { count: workOrdersCount } = await dispatch(getWorkOrderCount(projectId));


    dispatch(getProject(projectId)).then((projectData) => {
        getLevels({ levelId: projectData.level, dispatch });
        // updateProjectData({ projectData: projectData, dispatch })

        const stagesData = projectData.stages;
        const allStages = [];
        let totalTasks = 0;
        let calculations = {};

        stagesData.forEach((stage) => {
            projectData.hasStages && (stage.selected = true);
            stage.tasks.forEach((task) => {
                task.selected = true;
                task.subTasks.forEach((subTask) => {
                    subTask.selected = true;
                });
            });
        });

        Object.keys(stagesData).map((key) => {
            totalTasks += (idx(stagesData, _ => _[key].tasks) || []).length;
            stagesData[key].tasks.forEach((task, index) => {
                (task.material || []).forEach((material, mIndex) => {
                    if (_.isString(material)) {
                        material = JSON.parse(material);
                        stagesData[key].tasks[index].material[mIndex] = material;
                    }
                    task.subTasks.forEach((subTask, subTaskIndex) => {
                        if (_.isString(material)) {
                            material = JSON.parse(material);
                            stagesData[key].tasks[index].subTask[subTaskIndex].material[mIndex] = material;
                        }
                    });
                });
            });
            allStages.push(stagesData[key]);
        });

        if (stagesData.length > 0) {
            calculations = stageCalculation({ stages: stagesData });

            stagesData.forEach((stage) => {
                if (stage.tasks.length > 0) {
                    const tasksActualBudget = stage.tasks.map((task) => calculateActualBudget(task));
                    const totalActualMaterialCost = tasksActualBudget.reduce(
                        (acc, budget) => acc + parseFloat(budget.actualMaterialCost || 0), 0
                    );
                    progressCostMaterial = (totalActualMaterialCost / calculations.material.sale) * 100;
                    const totalActualLaborCost = tasksActualBudget.reduce(
                        (acc, task) => acc + parseFloat(task.actualLaborCost || 0), 0
                    );
                    progressCostLabor = (totalActualLaborCost / calculations.labor.sale) * 100;
                    const totalActualSubContractorCost = tasksActualBudget.reduce(
                        (acc, budget) => acc + parseFloat(budget.actualSubContractorCost || 0), 0
                    );
                    progressCostSubcontractor = (totalActualSubContractorCost / calculations.subcontractor.sale) * 100;

                    stage.tasks.forEach((task) => {
                        completedProgress += parseFloat(task.percentageComplete) || 0;

                        let totalPercentage = 0;
                        task.subTasks.forEach((subTask) => {
                            totalPercentage += parseFloat(subTask.percentageComplete);
                        });
                        let taskPercentage = parseFloat(totalPercentage) / parseFloat(task.subTasks.length);

                        task.percentageComplete = taskPercentage;
                        task.totalProfit = calculations.task[task.id].total_profit;
                    });
                }

            });
        } else if (Array.isArray(stagesData) && stagesData.length === 0 && projectData.hasStages) {
            showStageSelect = true;
        }

        invoiceProgress = invoiceProgress ? (invoiceProgress / parseFloat(calculations.revised_cost || 1)) * 100 : 0;
        forecastProfit = forecastProfit ? parseFloat(calculations.revised_cost || 0) - forecastProfit : 0;
        completedProgress = completedProgress ? (completedProgress / (totalTasks * 100)) * 100 : 0;


        dispatch(addProjectAction({
            projectId: projectData.id,
            projectName: projectData.name,
            projectManagerName: idx(projectData, _ => _.manager.name),
            quoteType: projectData.quoteType,
            projectDescription: projectData.description,
            customerName: projectData.customer && projectData.customer.businessName,
            hasStages: projectData.hasStages,
            projectType: projectData.type,
            projectDataType: projectData.projectType,
            customerLevel: projectData.customer ? projectData.customer.level : "",
            contactData: projectData.customerContact,
            projectMarginValue: projectData.profitMarginValue || "",
            siteAddress: projectData.address,
            projectDueDate: projectData.dateOfCreation,
            attachmentArr: projectData.attachment,

            taskCost: calculations.task,
            stagesCost: calculations.stage,

            showStageSelect,
            stagesData,
            totalTasks,
            stages: allStages,
            progressClaimCount,
            purchaseOrdersCount,
            workOrdersCount,
            invoicesCount,
            totalApprovedInvoices,
            invoiceProgress,
            forecastProfit,
            completedProgress,
            totalClaimAmount,
            totalRetentionAmount,
            progressCostSubcontractor,
            progressCostLabor,
            progressCostMaterial,
            calculations,

            res: projectData,
        }))

    });
}

export const updateProjectData = async ({ projectData, dispatch }) => {
    let projectId = projectData.id;
    let progressCostSubcontractor = 0, progressCostLabor = 0, progressCostMaterial = 0;
    let totalApprovedInvoices = 0, invoiceProgress = 0, forecastProfit = 0;
    let completedProgress = 0, totalClaimAmount = 0, totalRetentionAmount = 0;
    let showStageSelect = false;

    setCookie({ name: "projectId", value: projectId });

    const progressClaims = await dispatch(getProgressClaimList(projectId));

    progressClaims.forEach((claims) => {
        if (claims.progressClaimStatus) {
            totalClaimAmount += claims.claimAmount;
            totalRetentionAmount += claims.retentionAmount;
            totalApprovedInvoices++;
        }
    });
    const progressClaimCount = (Array.isArray(progressClaims) && progressClaims.length) || 0;
    const invoices = await dispatch(getInvoiceList(projectId));
    invoices.forEach((inv) => {
        if (inv.progressClaim) {
            invoiceProgress += inv.progressClaim.totalValue;
        }
    });

    const invoicesCount = (Array.isArray(invoices) && invoices.length) || 0;
    const { count: purchaseOrdersCount } = await dispatch(getPoCount(projectId));
    const { count: workOrdersCount } = await dispatch(getWorkOrderCount(projectId));

    const stagesData = projectData.stages;
    const allStages = [];
    let totalTasks = 0;
    let calculations = {};

    stagesData.forEach((stage) => {
        projectData.hasStages && (stage.selected = true);
        stage.tasks.forEach((task) => {
            task.selected = true;
            task.subTasks.forEach((subTask) => {
                subTask.selected = true;
            });
        });
    });

    Object.keys(stagesData).map((key) => {
        totalTasks += (idx(stagesData, _ => _[key].tasks) || []).length;
        stagesData[key].tasks.forEach((task, index) => {
            (task.material || []).forEach((material, mIndex) => {
                if (_.isString(material)) {
                    material = JSON.parse(material);
                    stagesData[key].tasks[index].material[mIndex] = material;
                }
                task.subTasks.forEach((subTask, subTaskIndex) => {
                    if (_.isString(material)) {
                        material = JSON.parse(material);
                        stagesData[key].tasks[index].subTask[subTaskIndex].material[mIndex] = material;
                    }
                });
            });
        });
        allStages.push(stagesData[key]);
    });

    if (stagesData.length > 0) {
        calculations = stageCalculation({ stages: stagesData });

        stagesData.forEach((stage) => {
            if (stage.tasks.length > 0) {
                const tasksActualBudget = stage.tasks.map((task) => calculateActualBudget(task));
                const totalActualMaterialCost = tasksActualBudget.reduce(
                    (acc, budget) => acc + parseFloat(budget.actualMaterialCost || 0), 0
                );
                progressCostMaterial = (totalActualMaterialCost / calculations.material.sale) * 100;
                const totalActualLaborCost = tasksActualBudget.reduce(
                    (acc, task) => acc + parseFloat(task.actualLaborCost || 0), 0
                );
                progressCostLabor = (totalActualLaborCost / calculations.labor.sale) * 100;
                const totalActualSubContractorCost = tasksActualBudget.reduce(
                    (acc, budget) => acc + parseFloat(budget.actualSubContractorCost || 0), 0
                );
                progressCostSubcontractor = (totalActualSubContractorCost / calculations.subcontractor.sale) * 100;

                stage.tasks.forEach((task) => {
                    completedProgress += parseFloat(task.percentageComplete) || 0;

                    let totalPercentage = 0;
                    task.subTasks.forEach((subTask) => {
                        totalPercentage += parseFloat(subTask.percentageComplete);
                    });
                    let taskPercentage = parseFloat(totalPercentage) / parseFloat(task.subTasks.length);

                    task.percentageComplete = taskPercentage;
                    task.totalProfit = calculations.task[task.id].total_profit;
                });
            }

        });
    } else if (Array.isArray(stagesData) && stagesData.length === 0 && projectData.hasStages) {
        showStageSelect = true;
    }

    invoiceProgress = invoiceProgress ? (invoiceProgress / parseFloat(calculations.revised_cost || 1)) * 100 : 0;
    forecastProfit = forecastProfit ? parseFloat(calculations.revised_cost || 0) - forecastProfit : 0;
    completedProgress = completedProgress ? (completedProgress / (totalTasks * 100)) * 100 : 0;


    dispatch(updateProjectAction({
        projectId: projectData.id,
        projectName: projectData.name,
        projectManagerName: projectData.manager.name,
        quoteType: projectData.quoteType,
        projectDescription: projectData.description,
        customerName: projectData.customer && projectData.customer.businessName,
        hasStages: projectData.hasStages,
        projectType: projectData.type,
        projectDataType: projectData.projectType,
        customerLevel: projectData.customer ? projectData.customer.level : "",
        contactData: projectData.customerContact,
        projectMarginValue: projectData.profitMarginValue || "",
        siteAddress: projectData.address,
        projectDueDate: projectData.dateOfCreation,
        attachmentArr: projectData.attachment,

        taskCost: calculations.task,
        stagesCost: calculations.stage,

        showStageSelect,
        stagesData,
        totalTasks,
        stages: allStages,
        progressClaimCount,
        purchaseOrdersCount,
        workOrdersCount,
        invoicesCount,
        totalApprovedInvoices,
        invoiceProgress,
        forecastProfit,
        completedProgress,
        totalClaimAmount,
        totalRetentionAmount,
        progressCostSubcontractor,
        progressCostLabor,
        progressCostMaterial,
        calculations,

        res: projectData,
    }))
}

export const getLevels = async ({ levelId, dispatch }) => {
    const level = await dispatch(getLevel(levelId))

    const labors = [];
    level.hourlyCost && level.hourlyCost.forEach((cost) => {
        labors.push({
            ...cost,
            calloutCost: level.calloutCost,
            travelCost: level.travelCost,
        });
    });

    dispatch(addLevelAction(labors))
};

const calculateActualBudget = (task = {}) => {
    const actualMaterialCost =
        (Array.isArray(task.materialPoCost) &&
            task.materialPoCost
                .reduce((acc, _material) => {
                    let materialBudget = 0;
                    if (_material && _material.budget && "PO" in _material.budget) {
                        materialBudget +=
                            (Array.isArray(_material.budget["PO"]) &&
                                _material.budget["PO"].reduce(
                                    (acc, poBudget) => acc + parseFloat(poBudget.amountPaid),
                                    0
                                )) ||
                            0;
                    }
                    if (_material && _material.budget && "WPO" in _material.budget) {
                        materialBudget +=
                            (Array.isArray(_material.budget["WPO"]) &&
                                _material.budget["WPO"].reduce(
                                    (acc, poBudget) => acc + parseFloat(poBudget.amountPaid),
                                    0
                                )) ||
                            0;
                    }
                    return acc + materialBudget;
                }, 0)
                .toFixed(2)) ||
        0;
    const actualLaborCost = parseFloat(task.laborCost).toFixed(2) || 0;
    const actualSubContractorCost =
        (Array.isArray(task.subContractorInvoiceCost) &&
            task.subContractorInvoiceCost
                .reduce((acc, _invoices) => {
                    return acc + parseFloat(_invoices.amountPaid || 0);
                }, 0)
                .toFixed(2)) ||
        0;

    return { actualMaterialCost, actualLaborCost, actualSubContractorCost };
};