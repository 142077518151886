import React from 'react'
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@material-ui/icons/AttachFile'

export default function FileUplaoder(props) {
  const {files, title,fileHandler,acceptedFileTypes  } = props
  const onDrop = React.useCallback(acceptedFiles => {
    fileHandler(acceptedFiles)
  }, [fileHandler])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: acceptedFileTypes })

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className='attachmentText'><AttachFileIcon /> {title || 'Attachments'}</p>
      </div>
      {files && files.length > 0 ? (
        <div>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      ) : (
        null
      )}
    </section>
  )
}
