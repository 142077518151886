import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import AccountsList from "./accounts-list";
import * as paths from "../../constants/route-paths";
import AddAccount from "./add-account";
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {

  let element = useRoutes([
    {
      path: "",
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AccountsList} />,
    },
    {
      path: paths.ADD_ACCOUNT,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddAccount} />,
    },
    {
      path: paths.EDIT_ACCOUNT,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddAccount} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;

    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
