import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getGlobalSetting } from '../../actions/global-setting.action'
import { addGlobalSettingTemplate, getAllGlobalSettingTemplate, getGlobalSettingTemplate, editGlobalSettingTemplate } from '../../actions/global-setting-template.action'
import { errorSnackBar, successSnackBar } from '../../actions/common.action'
import './scss/templates.scss'
import Modal from './../../components/modal/modal'
import SelectInput from 'src/components/inputs/select'
import CustomInput from 'src/components/inputs/new-input'
import Button from 'react-bootstrap/Button';
import { fileValidator, allowedWordTypes } from '../../util/validators/file';

class AddTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateValue: null,
            fileToUpload: {},
            selectFileType: '',
            tabledata: [],
            templateOptions: [],
            templateName: null,
            selectedFileName: '',
            fileurl: null,
            templates: {},
            isTemplateFormValid: false
        }
    }

    componentDidMount() {
        this.getTemplateSetting()
    }

    getTemplateSetting = async () => {
        const templateOptions = [
            { "value": 'variation', "key": 'Summary Variation' },
            { "value": 'detailed_variation', "key": 'Detailed Variation' },
            { "value": 'work_order', "key": 'Work Order' },
            { "value": 'rfq_supplier', "key": 'RFQ Supplier' },
            { "value": 'project_quote', "key": 'Summary Project Quote' },
            { "value": 'detailed_project_quote', "key": 'Detailed Project Quote' },
            { "value": 'purchase_order', "key": 'Purchase Order' },
            { "value": 'rfq_subcontractor', "key": 'RFQ Contractor' },
            { "value": 'project_progress_claim', "key": 'Project Progress Claim' },
            { "value": 'project_progress_claim_breakup', "key": 'Project Progress Claim Breakup' },
            { "value": 'subcontractor_payment_schedule', "key": 'Contractor Payment Schedule' },
            { "value": 'unquoted_project_progress_claim', "key": 'Unquoted Project Progress Claim' }
        ];//.sort((a, b) => (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0);

        this.setState({ templateOptions, selectFileType: '', fileToUpload: {} })
    }

    handleTemplateChange = (e) => {
        this.setState({ templateValue: e.target.value })
    }
    handleTemplateNameChange = (e) => {
        this.setState({ templateName: e.target.value })

    }
    addGlobalSettingTemplate = (isTemplateFormValid) => {
        let { fileToUpload, templateName, templateValue } = this.state
        const { templateid, editTemplate } = this.props
        const dataToSend = new FormData()
        // const invalidFile = false;
        for (const item in fileToUpload) {
            Array.isArray(fileToUpload[item]) && fileToUpload[item].forEach(file => {
                if (item === templateValue) {
                    dataToSend.append(`${item}`, file)
                }
            })
        }
        dataToSend.append('templateName', templateName)
        dataToSend.append('templateValue', templateValue)
        if (isTemplateFormValid) {
            if (!editTemplate) {
                this.props.addGlobalSettingTemplate(dataToSend).then(res => {
                    this.props.successSnackBar('New Template Added Successfully')
                    this.dialogClose()
                })
            }
            if (editTemplate) {
                this.props.editGlobalSettingTemplate(dataToSend, templateid).then(res => {
                    this.dialogClose()
                }).catch(e => console.log(e));
            }
        }

    }
    handleFormValidation = (data) => {
        const { selectedFileName, templateName, templateValue } = this.state
        let { isTemplateFormValid } = this.state

        if ((data == {}) || (selectedFileName == '')) {
            this.props.errorSnackBar('File is Required Field')
        }
        else {
            if (templateValue == null) {
                this.props.errorSnackBar('Please select a template type')
            }
            if (templateName == null) {
                this.props.errorSnackBar('Please enter a template name')
            }
            if (templateValue && templateName && selectedFileName) {
                isTemplateFormValid = true
                this.setState({ isTemplateFormValid })
                if (isTemplateFormValid == true) {
                    this.addGlobalSettingTemplate(isTemplateFormValid)
                }
            }
        }

    }
    handleFileUpload = (e) => {
        let { selectedFileName, selectFileType, fileToUpload } = this.state, isValid = true
        Array.from(e.target.files).map(file => {
            const fileValidation = fileValidator({ file, acceptedFileSize: 2, allowedMimeTypes: allowedWordTypes });
            const { validFileSize, validFileType, error } = fileValidation
            if (!validFileSize.valid && !error) {
                isValid = false
                this.props.errorSnackBar(validFileSize.error)
            }
            else if (!validFileType.valid && !error) {
                isValid = false
                this.props.errorSnackBar(validFileType.error)
            } else if (error) {
                isValid = false
                this.props.errorSnackBar(error.error)
            }
        })
        if (isValid) {
            this.setState({ fileToUpload: { ...fileToUpload, [selectFileType]: [...(e.target.files)] } })
            selectedFileName = e.target.files[0].name;
            this.setState({ selectedFileName })
        }

        e.target.value = ''
    }
    handleSelectFile = (selectFileType) => {
        this.setState({ selectFileType }, () => {
            document.getElementById("fileInput").click()
        })
    }

    afterOpenModal = async () => {
        const { templateid } = this.props
        if (templateid) {
            await this.props.getGlobalSettingTemplate(templateid).then(res => {
                this.setState({
                    id: res.id,
                    templateValue: res.type,
                    templateName: res.name,
                    selectedFileName: res.fileName
                })
            })

        }
    }
    dialogClose = () => {
        this.setState({
            templateValue: '',
            templateName: '',
            selectedFileName: '',
        })
        this.props.handleClose()
        this.props.dialogClose()
    }
    render() {
        const { fileurl, templateOptions, templateName, templateValue, selectedFileName } = this.state
        const { editdata, open, editTemplate, templateid } = this.props

        let modalHeading = 'Create Template'

        let savebtnText = 'Save'
        if (editTemplate) {
            modalHeading = 'Edit Template'
            savebtnText = 'Update'
            if (editdata.id === templateid) {
                // If templateValue is not set, use the type from editdata
                if (!templateValue) {
                    this.setState({ templateValue: editdata.type });
                }

                // If templateName or selectedFileName is not set, use the values from editdata
                if (!templateName || !selectedFileName) {
                    this.setState({
                        templateName: editdata.name,
                        selectedFileName: editdata.fileName,
                    });
                }

                // If fileurl has changed, update it
                if (fileurl !== editdata.url) {
                    this.setState({
                        fileurl: editdata.url, templateName: editdata.name,
                        selectedFileName: editdata.fileName,
                        templateValue: editdata.type
                    });
                }

                // If fileurl is not set, use the value from editdata
                if (!fileurl && editdata.url) {
                    this.setState({ fileurl: editdata.url});
                }
            }

        }
        return (
            <Modal
                open={open}
                onClose={this.dialogClose}
                fullWidth={true}
                maxWidth={"lg"}
                modalHeading={modalHeading}
                modalSaveTxt={savebtnText}
                modalCloseTxt={'Cancel'}
                onClick={this.handleFormValidation}
                onAfterOpen={this.afterOpenModal}
                showActionBtn
                savebtnClass='createBtn'
                primary
            >
                <div className="purchase-order-block">
                    <div className='row'>
                        <div className="col-lg-5">
                            <SelectInput
                                name={'templateSelect'}
                                label={'Select Template'}
                                items={templateOptions}
                                value={templateValue}
                                onChange={this.handleTemplateChange}
                            />
                        </div>
                        <div className="col-lg-5">
                            <CustomInput
                                name={'templateName'}
                                value={templateName}
                                label={'Template Name'}
                                onChange={this.handleTemplateNameChange}
                            />
                        </div>
                        <div className="col-lg-2 mt-auto">
                            <Button className="ms-auto me-3 secondarybtn" onClick={() => this.handleSelectFile(templateValue)} >Upload</Button>
                            <input style={{ marginBottom: '15px', display: 'none' }} id='fileInput' name='attachments' type='file' onChange={this.handleFileUpload} accept={allowedWordTypes.join(',')} />
                        </div>
                    </div>
                    <div className='mt-1'>
                        {templateValue && <small className='mt-1'>Type: {templateValue}</small>}
                    </div>
                    <div className='row mt-4'>
                        {fileurl && <div className="col-lg-12 mt-auto">
                            <p>
                                <strong>Uploaded File Name:</strong>
                                <a className='ms-4' href={fileurl} rel="noopener noreferrer" target="_blank">{selectedFileName}</a>
                            </p>
                        </div>}
                        {!fileurl && selectedFileName && <div className="col-lg-12 mt-auto">
                            <p>
                                <strong>Uploaded File Name:</strong>
                                <span className='ms-4'>{selectedFileName}</span>
                            </p>
                        </div>}
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getGlobalSetting: bindActionCreators(getGlobalSetting, dispatch),
    getAllGlobalSettingTemplate: bindActionCreators(getAllGlobalSettingTemplate, dispatch),
    addGlobalSettingTemplate: bindActionCreators(addGlobalSettingTemplate, dispatch),
    editGlobalSettingTemplate: bindActionCreators(editGlobalSettingTemplate, dispatch),
    getGlobalSettingTemplate: bindActionCreators(getGlobalSettingTemplate, dispatch),
    errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
    successSnackBar: bindActionCreators(successSnackBar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
