import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_INVOICE_LIST = '/invoice/list'
const SAVE_INVOICE = '/invoice'
const GET_PROGRESS_CLAIM_LIST = '/progress-claim/list'
const GET_SERVICE_INVOICE_LIST = '/service-invoice/list'
const SAVE_SERVICE_INVOICE = '/service-invoice'
const GET_SERVICE_PROGRESS_CLAIM_LIST = '/service-progress-claim/list'
const PROJECT_INVOICE_DOWNLOAD = '/invoice/download'
const PROJECT_PCB_DOWNLOAD = '/progress-claim/download'
const SERVICE_INVOICE_DOWNLOAD = '/service-invoice/download'
const SERVICE_PCB_DOWNLOAD = '/service-progress-claim/download'
const GET_Invoice_AND_PROGRESS_CLAIM_LIST = '/invoice/progress-claim/list'
const PROGRESS_CLAIM = '/progress-claim'
const GET_PROJECT_CUSTOMER = '/project/customer'


// // ************API call Functions starts here **************

export const getInvoiceList = async (projectId, startDate, endDate, allowedModules) => {
  return await axios('get', GET_INVOICE_LIST, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(projectId ? { projectId } : {}),
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      allowedModules
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveInvoice = async (data) => {
  return await axios('post', `${SAVE_INVOICE}`, data, { successMessage: 'Invoice Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProgressClaimList = async (query) => {
  return await axios('get', `${GET_PROGRESS_CLAIM_LIST}${query ? query : ''}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getServiceInvoiceList = async (serviceId, startDate, endDate) => {
  return await axios('get', GET_SERVICE_INVOICE_LIST, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(serviceId ? { serviceId } : {}),
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveServiceInvoice = async (data) => {
  return await axios('post', `${SAVE_SERVICE_INVOICE}`, data, { successMessage: 'Invoice Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getServiceProgressClaimList = async (query) => {
  return await axios('get', `${GET_SERVICE_PROGRESS_CLAIM_LIST}${query ? query : ''}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectProgressBreakup = async (id, format,tempId) => {
  return await axios('get', PROJECT_PCB_DOWNLOAD, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? {tempId} : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectInvoice = async (id, format,tempId) => {
  return await axios('get', PROJECT_INVOICE_DOWNLOAD, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? {tempId} : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadServiceProgressBreakup = async (id, format) => {
  return await axios('get', SERVICE_PCB_DOWNLOAD, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadServiceInvoice = async (id, format) => {
  return await axios('get', SERVICE_INVOICE_DOWNLOAD, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getInvoiceAndProgressClaimList = async (query) => {
  return await axios('get', `${GET_Invoice_AND_PROGRESS_CLAIM_LIST}${query ? query : ''}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteProgressClaim = async (id,type) => {
  return await axios('delete', `${PROGRESS_CLAIM}/${id}/${type}`, {}, { successMessage: 'Progress Claim Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProjectCustomer = async (id) => {
  return await axios('get', `${GET_PROJECT_CUSTOMER}?id=${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}
