import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../util/with-router";
import { TransitionGroup } from "react-transition-group";
import * as paths from "./../constants/route-paths";
import ProjectManagement from "./../routes/project-management";
import UserManagment from "./../routes/user-management";
import CustomerManagement from "./../routes/customer-management";
import Inventory from "./../routes/inventory-management";
import Profile from "./../routes/profile";
import AssetManagement from "./../routes/asset-management";
import VendorPayment from "./../routes/vendor-payment";
import Dashboard from "./../routes/dashboard";
import GlobalSetting from "./../routes/global-setting";
import HomeLayout from "./home-layout";
import Timesheet from "../routes/timesheet";
import GlobalAccount from "../routes/global-account";
import ErrorBoundary from "./error";
import SuppliersManagement from "src/routes/suppliers";
import SubContractorManagement from 'src/routes/sub-contractor';
import ReceivablesManagement from 'src/routes/receivables';
import CatalogsManagement from 'src/routes/catalogs';
import TemplatesManagement from 'src/routes/templates';
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {

  let element = useRoutes([
    {
      path: `/${paths.DASHBOARD}/*`,
      element: (
        <ErrorBoundary fallback={<p></p>}>
          <RBACApplicationAuth moduleURL={location.pathname} component={Dashboard} />
        </ErrorBoundary>
      ),
    },
    {
      path: `/${paths.PROJECT_MANAGEMENT}/*`,
      element: <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <RBACApplicationAuth moduleURL={location.pathname} component={ProjectManagement} />
        {/* <WrpProjectManagement moduleURL={location.pathname} /> */}
      </ErrorBoundary>,
    },
    {
      path: `/${paths.CUSTOMER_MANAGEMENT}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={CustomerManagement} />,
    },
    {
      path: `/${paths.SUPPLIERS}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={SuppliersManagement} />,
    },
    {
      path: `/${paths.CONTRACTORS}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={SubContractorManagement} />,
    },
    {
      path: `/${paths.RECEIVABLES}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={ReceivablesManagement} />,
    },
    {
      path: `/${paths.CATALOGS}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={CatalogsManagement} />,
    },
    {
      path: `/${paths.TEMPLATES}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={TemplatesManagement} />,
    },
    {
      path: `/${paths.VENDOR_PAYMENT}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={VendorPayment} />,
    },
    {
      path: `/${paths.INVENTORY}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={Inventory} />,
    },
    {
      path: `/${paths.ASSET_MANAGEMENT}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AssetManagement} />,
    },
    {
      path: `/${paths.TIMESHEET}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={Timesheet} />,
    },
    {
      path: `/${paths.USER_MANAGEMENT}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={UserManagment} />,
    },
    {
      path: `/${paths.GLOBAL_SETTING}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={GlobalSetting} />,
    },
    {
      path: `/${paths.PROFILE}/*`,
      element: (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <RBACApplicationAuth moduleURL={location.pathname} component={Profile} />
        </ErrorBoundary>
      ),
    },
    {
      path: `/${paths.GLOBAL_ACCOUNT}/*`,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={GlobalAccount} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;

    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <HomeLayout>
            <CustomRoutes location={location} />
          </HomeLayout>
        </TransitionGroup>
      </div>
    );
  }
}

export default withRouter(Root);
