import React from "react";
import '../../project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import idx from "idx";
import { editPurchaseOrder, getProject, getProjectList, getPurchaseOrder, savePurchaseOrder } from "src/actions/project-management.action";
import { getVendorList } from "src/actions/vendor-management.action";
import { warningSnackBar } from "src/actions/common.action";
import { PROJECT_MANAGEMENT } from "src/constants/route-paths";
import { useSelector } from "react-redux";
import CreatePOComponent from "src/components/po/create";
import { bindActionCreators } from "redux";

const CreatePurchaseOrder = (props) => {

  const { projectId, poId } = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { projectDetail = {} } = useSelector(state => state)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  const viewPoList = () => navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}`);
  const viewProject = () => navigate(`/projects/view/${projectId}`)
  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)

  return (
    <>
      <CreatePOComponent
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewPoList} >PO LIST</Breadcrumb.Item>
            <Breadcrumb.Item active>{poId ? 'Edit Purchase Order' : 'Create Purchase Order'}</Breadcrumb.Item>
          </Breadcrumb>
        }
        projectId={projectId}
        cancelHandler={viewPoList}
        warningSnackBar={props.warningSnackBar}
        getProject={props.getProject}
        getProjectList={props.getProjectList}
        savePurchaseOrder={props.savePurchaseOrder}
        editPurchaseOrder={props.editPurchaseOrder}
        getVendorList={props.getVendorList}
        getPurchaseOrder={props.getPurchaseOrder}
      />
    </>
  );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  savePurchaseOrder: bindActionCreators(savePurchaseOrder, dispatch),
  editPurchaseOrder: bindActionCreators(editPurchaseOrder, dispatch),
  getVendorList: bindActionCreators(getVendorList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrder);