import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withRouter } from "../../../util/with-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPOList,
  deletePurchaseOrder,
  downloadProjectPO,
} from "./../../../actions/project-management.action";
import { VENDOR_PAYMENT, } from "../../../constants/route-paths";
import ViewWo from "./view-wo";
import CreateWO from "./create-work-order";
import PayInvoice from "../../vendor-payment/pay-invoice";
import idx from "idx";
import { numberFormat } from "../../../util/currency-formatter";
import DownloadDialog from "../../../components/download-dialog";
import DeleteDialog from "../../../components/delete-dialog";
import { Link } from "react-router-dom";
import { getGlobalSettingTemplateTypes } from "./../../../actions/global-setting-template.action";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Popover from 'react-bootstrap/Popover';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getProjectData } from "../utils/get-project";
import CustomTable from "src/components/table/table";

function WoList({ wContractorPermission, ...props }) {

  const [columns, setColumns] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [pending, setPending] = useState([]);
  const [woId, setwoId] = useState();
  const [poData, setPoData] = useState({});
  const [showPayInvoice, setShowInvoice] = useState(false);
  const [isSubContractor, setIsSubContractor] = useState(false);
  let [templateOptions, setTemplateOptions] = useState([]);
  const [downloadData, setDownloadData] = useState({
    open: false,
    id: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { projectId } = useParams();
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const projectDetail = useSelector(state => state.projectDetail) || {}
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  useEffect(() => {
    getWOList();

    subContractorColums();

    if (!idx(projectDetail, _ => _.project.res) || (idx(projectDetail, _ => _.project.projectId) != projectId))
      getProjectData({ projectId, dispatch })
  }, [projectId]);

  const subContractorColums = () => {
    const columns = [
      {
        title: "WO No",
        field: "id",
        render: (rowData) => (
          <Link to={`view/${rowData.woId}`}>
            {rowData.id}
          </Link>
        ),
      },
      {
        title: "Total",
        field: "totalCost",
        render: (rowData) => `${numberFormat(rowData.totalCost)}`,
      },
      { title: "Vendor", field: "vendorName", render: (rowData) => rowData.vendorName },
      { title: "Invoiced", field: "invclaim", render: (rowData) => rowData.invclaim },
      { title: "Paid", field: "invpaid", render: (rowData) => rowData.invpaid },
      { title: "Retained", field: "invretention", render: (rowData) => rowData.invretention },
      /* { title: 'Vendor Type', field: 'type', render: rowData => rowData.type === 'sub-contractor' ? 'Contractor' : rowData.type } */
    ];

    setColumns(columns);
  };

  const getWOList = () => {
    const POs = [];
    const subContractors = [];
    const completed = [];
    const pending = [];
    props.getPOList({
      projectId: projectId,
      vendorType: "sub-contractor",
    }).then((woList) => {
      Array.isArray(woList) &&
        woList.forEach((res) => {
          const vendorName = res.vendor && res.vendor.business_name;
          let invclaim = 0;
          let invpaid = 0;
          let invretention = 0;
          const invoice = res.invoice;
          invoice.forEach((listItem) => {
            invclaim += listItem.invoiceClaim;
            invpaid += listItem.amountPaid;
            invretention += listItem.retentionAmount;
          });
          const type = res.vendor && res.vendor.type;
          const poObj = {
            id: res.id,
            vendorName,
            invclaim,
            invpaid,
            invretention,
            type,
            ...res,
            woId: res.u_id,
          };
          POs.push(poObj);
          subContractors.push(poObj);
          const status = idx(res, (_) => _.status) || "";
          if (status === "Completed") {
            completed.push(poObj);
          } else if (
            status === "Pending" ||
            status === "Partially Stocked" ||
            status === ""
          ) {
            pending.push(poObj);
          }
        });

      setCompleted(completed);
      setPending(pending);

    });
  };

  const handlePayInvoiceModal = () => {
    setShowInvoice(!showPayInvoice);
  };

  const handleMenu = (rowData) => {
    setwoId(rowData.woId);
    setPoData(rowData);
    setIsSubContractor(true);
  };

  const deleteHandler = async () => {
    deleteDialogState();
    await props.deletePurchaseOrder(woId, "Purchase Order Deleted successfully");
    getWOList();
  };

  const deleteDialogState = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const gettemplate = async () => {
    if (templateOptions.length == 0) {
      templateOptions = [];
      let type = "work_order";
      const templateData = await props.getGlobalSettingTemplateTypes(type);
      for (let key in templateData) {
        templateOptions.push({
          value: templateData[key].id,
          key: templateData[key].name,
        });
      }
      setTemplateOptions(templateOptions);
    }
  };

  const handleDownloadDialog = (id = "", template = "") => {
    const newDownloadData = {};
    newDownloadData.open = !downloadData.open;
    newDownloadData.id = woId;
    newDownloadData.template = template;
    if (templateOptions.length == 0) {
      gettemplate();
    }
    setDownloadData(newDownloadData);
  };

  const downloadFile = async (fileFormat = "pdf", tempId) => {
    if (fileFormat === "pdf" || fileFormat === "ALL") {
      await props.downloadProjectPO(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    if (fileFormat === "docx" || fileFormat === "ALL") {
      await props.downloadProjectPO(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    handleDownloadDialog();
  };

  const viewProject = () => navigate(`/projects/view/${projectId}`)
  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  const viewWO = () => navigate(`view/${woId}`);
  const createWO = () => navigate(`/projects/work-order/${projectId}/create-work-order`);
  const viewInvoices = () => navigate(`/${VENDOR_PAYMENT}?project=${projectId}&woId=${woId}`);

  return (
    <>
      <Routes>
        <Route
          path={`view/:woId`}
          element={<ViewWo poData={poData} />}
        />
        <Route
          path={`create-work-order`}
          element={<CreateWO />}
        />

        <Route
          path=""
          element={
            <>
              {showPayInvoice && (
                <PayInvoice
                  poNumber={woId}
                  poNo={poData.id}
                  isSubContractor={isSubContractor}
                  workType="project"
                  handleClose={handlePayInvoiceModal}
                  handleDownloadDialog={handleDownloadDialog}
                  payInvoiceType="workOrder"
                  getWOList={() => getWOList()}
                />
              )}
              <div className="purchase-order-block">
                <div className="row">
                  <div className="w-auto">
                    <h2 className="content-heading text-uppercase">Work Order List</h2>
                    <div className="col-12 breadcrumb-block p-0">
                      <Breadcrumb>
                        <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Work Order List</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </div>
                  <div className="w-auto ms-auto mt-auto">
                    {wContractorPermission && <button
                      type="submit"
                      className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary"
                      onClick={createWO}>
                      Create Work Order
                    </button>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="inprogress"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="inprogress" title={`Active / Inprogress (${pending.length})`}>
                        <CustomTable
                          columns={columns}
                          data={pending}
                          isAction={true}
                          handleMenu={(data) => handleMenu(data)}
                          menu={(item) => (
                            <Popover id="popover-basic">
                              <Popover.Body>
                                <ul className="action-block-list">
                                  <li onClick={() => viewWO()}>
                                    View WO
                                  </li>
                                  <li onClick={() => handleDownloadDialog()}>
                                    Download WO
                                  </li>
                                  {wContractorPermission && <li onClick={handlePayInvoiceModal}>
                                    Receive Invoice
                                  </li>}
                                  <li onClick={viewInvoices}>View Invoices</li>
                                </ul>
                              </Popover.Body>
                            </Popover>
                          )}
                          fileName='Pending WO List'
                        />
                      </Tab>
                      <Tab eventKey="completed" title={`Completed (${completed.length})`}>
                        <CustomTable
                          columns={columns}
                          data={completed}
                          isAction={true}
                          handleMenu={(data) => handleMenu(data)}
                          menu={(item) => (
                            <Popover id="popover-basic">
                              <Popover.Body>
                                <ul className="action-block-list">
                                  <li onClick={() => viewWO()}>
                                    View WO
                                  </li>
                                  <li onClick={() => handleDownloadDialog()}>
                                    Download WO
                                  </li>
                                  {wContractorPermission && <li onClick={handlePayInvoiceModal}>
                                    Receive Invoice
                                  </li>}
                                  <li onClick={viewInvoices}>View Invoices</li>
                                </ul>
                              </Popover.Body>
                            </Popover>
                          )}
                          fileName='Completed WO List'
                        />
                      </Tab>
                    </Tabs>

                  </div>
                </div>

              </div>
            </>
          }
        />
      </Routes>
      <DeleteDialog
        open={openDeleteDialog}
        id={woId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogState}
        moduleName="Purchase Order"
      />
      <DownloadDialog
        {...downloadData}
        downloadFile={downloadFile}
        templateOptions={templateOptions}
        handleDownloadDialog={handleDownloadDialog}
        downloadModuleName="WorkOrder List"
      />
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPOList: bindActionCreators(getPOList, dispatch),
  deletePurchaseOrder: bindActionCreators(deletePurchaseOrder, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(
    getGlobalSettingTemplateTypes,
    dispatch
  ),
  downloadProjectPO: bindActionCreators(downloadProjectPO, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WoList));
