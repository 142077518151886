import { createSlice } from '@reduxjs/toolkit'
import _ from "lodash";

// Slice
const initialCustomer = {
    customers: [],
    dropdown: [],
}

const slice = createSlice({
    name: 'customer',
    initialState: {
        ...initialCustomer,
    },
    reducers: {
        addCustomers: (state, action) => {
            const { customers, dropdown } = action.payload
            state.customers = customers
            state.dropdown = dropdown;
        },
        clearCustomers: (state, action) => {
            state.customers = [];
            state.dropdown = [];
        }
    },
});

export default slice.reducer

// Actions
const { addCustomers, clearCustomers } = slice.actions

export const addCustomersAction = (data) => async dispatch => {
    try {
        let _dropdown = (data.customers || []).map((customer) => {
            return {
                value: customer.id,
                key: customer.businessName,
            };
        });
        _dropdown = _.orderBy(_dropdown, [({ key }) => key.toLowerCase()], ["asc"]);
        dispatch(addCustomers({ ...data, dropdown: _dropdown }));
    } catch (e) {
        return console.error(e);
    }
}

export const clearCustomersAction = (data) => async dispatch => {
    dispatch(clearCustomers());
}