import React, { useEffect, useState } from "react";
import { GLOBAL_ACCOUNT } from "./../../constants/route-paths";
import {
  getAccount,
  addAccount,
  updateAccount,
} from "./../../actions/global-account.action";
import _ from "lodash";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import CustomInput from "src/components/inputs/new-input";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { ValidateField, ValidatorTypes } from "src/util/validators";
import { warningSnackBar } from "src/actions/common.action";
import SelectInput from "src/components/inputs/select";
import { minifySubscription } from "src/util/enums/subscriptions";
import Form from 'react-bootstrap/Form';
import { useSelector } from "react-redux";
import idx from "idx";
/* all otp releated lines are commented for temporarily  for changes made in task/1620 */
// import { generateOTP } from "src/actions/otp.action";
import { emailValidator } from "src/util/validators/email";
import { phoneValidator } from "src/util/validators/phone";

function AddAccount({ writePermission, ...props }) {

  const [fields, setFields] = useState({
    name: { value: "", touched: false, error: false, validation: [ValidatorTypes.REQUIRED] },
    address: { value: "", touched: false, error: false, validation: [ValidatorTypes.OPTIONAL] },
    email: { value: "", touched: false, error: false, validation: [ValidatorTypes.REQUIRED] },
    phone_number: {
      value: "",
      touched: false,
      error: false, validation: [ValidatorTypes.REQUIRED]
    },
    website: { value: "", touched: false, error: false, validation: [ValidatorTypes.OPTIONAL] },
    subscription: { touched: false, error: false, validation: [ValidatorTypes.REQUIRED] },
    /* all otp releated lines are commented for temporarily  for changes made in task/1620 */
    // otp: { value: "", touched: false, error: false, required: false },
  })
  const [entitlement, setEntitlement] = useState({})
  const [state, setState] = useState({
    edit: false,
    opneAddRoleDialog: false,
  })
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscriptions = useSelector(state => state.subscription.list) || [];

  useEffect(() => {
    init()
  }, [accountId])

  useEffect(() => {
    initSubscription()
  }, [subscriptions])

  const initSubscription = () => {
    if (!fields.subscription.value && subscriptions.length) {
      changeSubscription(subscriptions[0].id)
    }
  }

  const init = async () => {
    fetchAccount()
  }

  const fetchAccount = () => {
    if (!accountId) return;
    dispatch(getAccount(accountId)).then((account) => {
      editAccount(account);
    });
  }

  const editAccount = (account) => {
    let subscription = account.subscription_id || idx(subscriptions, _ => _[0].id);
    let subscription_value = subscriptions.find(subs => subs.id == subscription)?.metadata || {};
    setFields(prev => ({
      ...prev,
      name: { ...prev.name, value: account.name },
      address: { ...prev.address, value: account.address },
      email: { ...prev.email, value: account.email },
      phone_number: { ...prev.phone_number, value: account.phone_number },
      website: { ...prev.website, value: account.website },
      subscription: { ...prev.subscription, value: subscription }
    }));
    setEntitlement(
      _.merge(
        _.cloneDeep(subscription_value),
        _.cloneDeep(account.subscription_metadata?.raw || {}),
      )
    )
    setState(prev => ({
      ...prev,
      edit: true
    }));
  };

  const inputChangeHandler = (e, fieldName = "") => {
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value || "";
    }
    const { error } = handleValidation({ name, value, required: fields[name].required });

    setFields(prev => ({
      ...prev,
      [name]: { ...prev[name], value: value, touched: true, error: error },
    }));

    let isFormValid = true;
    for (let key of Object.keys(fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }
    setState(prev => ({
      ...prev,
      isFormValid
    }));
  };

  const handleValidation = ({ name, value, required }) => {
    let error = false;
    if ((value === undefined || (!_.isBoolean(value) && value.trim() === "")) && required) {
      return { error: "This Field is Required" };
    }
    if (name === "email") {
      let status = emailValidator(value);
      if (!status) return { error: "Please Enter Valid Email" };
    }
    if (name === "phone_number") {
      let status = phoneValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Mobile Number" };
      }
    }
    return { error };
  };

  /* all otp releated lines are commented for temporarily  for changes made in task/1620 */
  // const sendOTP = () => {
  //   if (!fields.email.value || !fields.phone_number.value) {
  //     props.errorSnackBar("Please Provide Email and Phone Number");
  //     return;
  //   }
  //   const data = {
  //     name: fields.name.value,
  //     address: fields.address.value,
  //     email: fields.email.value,
  //     phone_number: fields.phone_number.value,
  //     website: fields.website.value,
  //   };
  //   dispatch(generateOTP(data)).then(data => {
  //     console.log(data)
  //   }).catch(e => {
  //     console.log(e)
  //   })
  // }

  const cancelHandler = () => {
    navigate(`/${GLOBAL_ACCOUNT}`);
  };

  const submitForm = () => {
    let isFormValid = true;
    let _fields = _.cloneDeep(fields)
    for (let key of Object.keys(_fields)) {
      const { error, valid } = ValidateField({ name: key, ..._fields[key] });
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && !error;
    }
    if (!isFormValid) {
      dispatch(warningSnackBar('Please enter the mandatory fields'));
      setFields(_fields);
    } else {
      const { edit } = state;
      const data = {
        name: fields.name.value,
        address: fields.address.value,
        email: fields.email.value,
        phone_number: fields.phone_number.value,
        website: fields.website.value,
        subscription_id: fields.subscription.value,
        subscription_metadata: { raw: entitlement, minified: minifySubscription(entitlement) }
      };

      if (edit) {
        dispatch(updateAccount(data, accountId)).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      } else {
        /* all otp releated lines are commented for temporarily  for changes made in task/1620 */
        dispatch(addAccount({ ...data, /*otp: fields.otp.value */ })).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      }
    }
  };

  const changeSubscription = (value) => {
    let _subscription = subscriptions.find(item => item.id == value) || {};
    setEntitlement(_.cloneDeep(_subscription.metadata) || {})
    setFields(prev => ({
      ...prev,
      subscription: { ...prev.subscription, value: value }
    }));
  }

  return (
    <div className="purchase-order-block">
      <div className="row">
        <div className="w-auto">
          <h2 className="content-heading text-uppercase">
            {state.edit ? "Edit Account" : "Add an Account"}
          </h2>
          <div className="col-12 breadcrumb-block p-0">
            <Breadcrumb>
              <Breadcrumb.Item onClick={cancelHandler}>Accounts</Breadcrumb.Item>
              <Breadcrumb.Item active>
                {state.edit ? "Edit Account" : "Add an Account"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 mt-3">
          <CustomInput
            label="Account Name"
            {...fields.name}
            onChange={(e) => inputChangeHandler(e)}
            name="name"
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <CustomInput
            label="Account Address"
            {...fields.address}
            onChange={(e) => inputChangeHandler(e)}
            name="address"
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <CustomInput
            label="Account Website"
            {...fields.website}
            onChange={(e) => inputChangeHandler(e)}
            name="website"
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <CustomInput
            type="email"
            label="Account Email"
            {...fields.email}
            onChange={(e) => inputChangeHandler(e)}
            name="email"
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <CustomInput
            label="Account Phone Number"
            {...fields.phone_number}
            onChange={(e) => inputChangeHandler(e)}
            name="phone_number"
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <SelectInput
            label="Subscription"
            placeholder={'Select Subscription'}
            {...fields.subscription}
            items={subscriptions.map(item => ({ key: item.name, value: item.id }))}
            onChange={(e) => changeSubscription(e.target.value)}
            name="subscription"
          />
        </div>
      </div>

      {/* all otp releated lines are commented for temporarily  for changes made in task/1620  */}
      {/* {!state.edit && <>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              label="OTP"
              type="number"
              {...fields.otp}
              onChange={(e) => {
                if (((e.target.value || '').length < 7 && /\d{0,}/.test(e.target.value)))
                  inputChangeHandler(e)
              }}
              name="otp"
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-auto text-left">
            <p className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={sendOTP}>Generate OTP</p>
          </div>
        </div>
      </>} */}

      <div className="row mt-3">
        <div className="col-12 mb-2 d-flex">
          <h2 className="content-heading content-heading-lg text-uppercase my-auto me-auto">Entitlements</h2>
          <em className="fw-bold">* (-1) in limit fields will represent the unlimited access.</em>
        </div>
        {Object.entries(entitlement).map(item => {
          let key = item[0], value = item[1];
          return (
            <div key={key} className="row mx-1">
              <div className="row mt-2">
                <h2 className="content-heading content-heading-lg text-decoration-underline text-uppercase text-secondary my-0 me-auto">
                  {key == 'project' && 'Projects'}
                  {key == 'users' && 'User Management'}
                  {key == 'workforce' && 'Workforce Management'}
                  {key == 'inventory' && 'Inventory'}
                  {key == 'asset' && 'Asset Management'}
                </h2>
              </div>

              {(value.availability || []).length > 0 && <>
                <div className="row mt-2 mx-4">
                  <h2 className="content-heading content-heading-lg text-uppercase text-body-tertiary my-0 me-auto">
                    Availability
                  </h2>
                </div>
                <div className="row mx-4">
                  {(value.availability || []).map((option, idx, arr) => (
                    <div key={option.key} className="col-lg-4 col-md-6 mt-2 text-left">
                      <Form className="usercheckbox me-3">
                        <div className="table-checkbox">
                          <Form.Check
                            type={'checkbox'}
                            id={`${option.key}-check`}
                            label={option.label}
                            value={option.value}
                            checked={option.value}
                            onChange={(e) => {
                              arr[idx].value = e.target.checked;
                              setEntitlement(prev => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  availability: arr,
                                }
                              }))
                            }}
                          />
                        </div>
                      </Form>
                    </div>
                  ))}
                </div>
              </>}

              {(value.limit || []).length > 0 && <>
                <div className="row mt-2 mx-4">
                  <h2 className="content-heading content-heading-lg text-uppercase text-body-tertiary my-0 me-auto">
                    Maximum Limit
                  </h2>
                </div>
                <div className="row mt-2 mx-4">
                  {(value.limit || []).map((limit, idx, arr) => (
                    <div key={limit.key} className="col-lg-4 col-md-6 mt-2">
                      <CustomInput
                        label={limit.label}
                        value={limit.value}
                        className="input-block-sm"
                        type="number"
                        onChange={(e) => {
                          if (e.target.value < -1) return;
                          arr[idx].value = e.target.value;
                          setEntitlement(prev => ({
                            ...prev,
                            [key]: {
                              ...prev[key],
                              limit: arr,
                            }
                          }))
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>}

              <div className="px-2"><hr /></div>
            </div>
          )
        })}
      </div>

      <div className="col-12 d-flex justify-content-end gap-3 mt-3">
        {writePermission && (<Button onClick={submitForm} className="secondarybtn">DONE</Button>)}
        <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>
    </div>
  );

}

export default AddAccount
