import idx from "idx";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../scss/project-management.scss";
import { Paper } from "@material-ui/core";
import {
    updateStage,
    updateTask,
    updateSubTask,
    getOptions,
    updateOption,
    convertTask,
    deleteTask,
    deleteSubTask,
    updateTaskOrderingId,
    duplicateProjectTask,
    deleteStage,
} from "../../../actions/project-management.action";
import { PROJECT_MANAGEMENT, } from "../../../constants/route-paths";
import _ from "lodash";
import { assignTaskFields } from "../../../constants/fields.constant";
import { getOptionsData } from "../utils/get-options";
import { getOnlyProjectData, getProjectData } from "../utils/get-project";
import { updateProjectAction } from "src/slice/project-detail";
import { clearTaskAction } from "src/slice/add-task";
import AddTask from "../add-task";
import { getVariationsData } from "../utils/get-variations";
import CompletionPercentageDialog from "../../../components/completion-percentage-dialog";
import DeleteDialog from "../../../components/delete-dialog";
import StageSelectDialog from "../stage-select-dialog";
// import AddStage from "../add-stage";
import Modal from "../../../components/modal/modal";
import { getSubcontractorsData } from "../utils/get-subcontractors";
import { getQuotationListData } from "../utils/get-quotations";
import { getWorkAllocationsData } from "../utils/get-work-allocations";
import { handleDragEnd } from "../../../util/reorder";
import { Menu, MenuItem, Grid, Typography } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import Tasks from "../tasks";
import ListTaskTemplate from "src/components/list-template/task";
import { Button } from "react-bootstrap";

export default function TaskList({ writePermission, updatePermission, financePermission, ...props }) {
    const [taskListState, setTaskListState] = useState({
        anchorEl: null,
        convertOptiontoTask: false,
        stageDialogState: false,
        menuItems: {},
        selectedTaskTemplateStage: '',
        showTaskTemplateDialog: false,
        selectedTemplateTask: null,

        open: false,
        customstyle: "",
        taskCost: [],
        stagesCost: [],
        quoteType: "",
        variations: [],
        proposal: {},
        userNames: [],
        taskData: {},
        totalVariationAmount: 0,
        poDialogState: false,
        assignTaskFields: JSON.parse(JSON.stringify(assignTaskFields)),
        completionPerctangeTaskType: "",
        completedProgress: 0,
        invoiceProgress: 0,
        timeSpentProgress: 0,
        costToDate: 0,
        totalApprovedInvoices: 0,
        profitMarginValue: "",
        showStageSelect: false,
        accOpen: false,
        rows: [],
        rowFields: {},
        deleteopen: false,
        deleteId: "",

        material_Profit: 0,
        sub_Contractor_Total_Profit: 0,
        labor_total_Profit: 0,
        totalProfit: 0,
        optionsTotal: {},
        variationsTotal: {},
        materialTotalCost: 0,
        subcontractorTotal_Cost: 0,
        taskDialogState: false
    })
    const { projectId } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const projectDetail = useSelector(state => state.projectDetail) || {}

    useEffect(() => {
        initLoad()
    }, [])

    const initLoad = async () => {
        getSubcontractorsData({ type: "sub-contractor", dispatch })
        if (projectId) {
            getQuotationListData({ projectId, dispatch })
            getWorkAllocationsData({ projectId, dispatch })
        }
        // getOptionsData({ projectId, dispatch });
        getVariationsData({ projectId, dispatch });
    }


    const convertOptiontoTask = () => {
        const { currentEditId = {} } = taskListState;
        const { project: { options = [], stages = [], } } = projectDetail
        const option = options.find((item) => item.id === currentEditId.optionId);
        if (option) {
            let optionData = {
                revisionId: option.revisionId,
                optionId: option.id,
                stageId: stages.length ? stages[0].id : "",
            };

            _convertTask(optionData);
            handleConvertOptiontoTaskClose();
        }
    };

    const _convertTask = (data) => {
        dispatch(convertTask(data)).then((res) => {
            getOptionsData({ projectId, dispatch })
            getProjectData({ projectId, dispatch })
            if (props.getStages) props.getStages()
        });
    };

    const handleConvertOptiontoTaskClose = () => {
        setTaskListState(prev => ({
            ...prev,
            convertOptiontoTask: false,
            taskDialogState: false
        }));

        handleMenuClose();
    };

    const stageSelectHandler = async (choice) => {

        dispatch(updateProjectAction({
            showStageSelect: !idx(projectDetail, _ => _.project.showStageSelect),
            ...(!choice && { hasStages: false }),
        }))

        if (!choice) await getProjectData({ projectId, dispatch })
        // For Add Stage
        // if (idx(projectDetail, _ => _.project.hasStages))
        //     setTaskListState(prev => ({
        //         ...prev,
        //         stageDialogState: true
        //     }));
    };

    const deleteHandler = (id = "") => {
        markAsDeleted();
    };

    const markAsDeleted = () => {
        const { deleteId, deleteType } = taskListState;
        if (deleteId && deleteType) {
            if (deleteType == "Task" || deleteType == "OptionTask") {
                dispatch(deleteTask(deleteId, deleteType == "OptionTask" ? true : false))
                    .then((res) => {
                        getProjectData({ projectId, dispatch });
                        getOptionsData({ projectId, dispatch });
                    });
            } else if (deleteType == "Subtask" || deleteType == "OptionSubtask") {
                dispatch(deleteSubTask(deleteId, deleteType == "OptionSubtask" ? true : false))
                    .then((res) => {
                        getProjectData({ projectId, dispatch });
                        getOptionsData({ projectId, dispatch });
                    });
            }

            deleteDialogHandler();
        }
    };


    const deleteDialogHandler = (id = "", type = "") => {
        handleClose();
        setTaskListState(prev => ({
            ...prev,
            deleteopen: !prev.deleteopen,
            deleteId: id,
            deleteType: type
        }));
    };

    const handleClose = () => {
        setTaskListState(prev => ({
            ...prev,
            anchorEl: null,
            menuItems: {},
        }));
        dispatch(updateProjectAction({
            hasStages: null,
        }))
    };

    const setCompletionPercentage = async (value) => {
        const {
            _stageId,
            _taskId,
            _subTaskId,
            _optionId,
            completionPerctangeTaskType,
        } = taskListState;

        if (!value) {
            this.props.warningSnackBar("Please Enter value");
            return;
        }

        const formData = new FormData();
        formData.set("percentageComplete", value);

        if (completionPerctangeTaskType === "STAGE") {
            await dispatch(updateStage(formData, _stageId));
            getProjectData({ projectId, dispatch })
        } else if (completionPerctangeTaskType === "TASK") {
            await dispatch(updateTask(formData, _taskId));
            getProjectData({ projectId, dispatch })
        } else if (completionPerctangeTaskType === "SUBTASK") {
            await dispatch(updateSubTask(formData, _subTaskId));
            getProjectData({ projectId, dispatch })
        } else if (completionPerctangeTaskType === "VARIATION") {
            await dispatch(updateOption(formData, _optionId));
            getOptions({ projectId, dispatch })
        }

        completionPercentageTaskHandler({});
    };

    const completionPercentageTaskHandler = ({
        type,
        percentageCompleted,
        stageId,
        taskId,
        subTaskId,
        optionId,
    }) => {
        setTaskListState(prev => ({
            ...prev,
            showPercentageDialog: !prev.showPercentageDialog,
            _stageId: stageId,
            _taskId: taskId,
            _subTaskId: subTaskId,
            completionPerctangeTaskType: type,
            percentageCompleted,
            _optionId: optionId,
        }));
    };


    const assignTaskSave = async () => {
        const { assignTaskFields, taskData, id } = taskListState;
        const formData = new FormData();
        formData.set("status", assignTaskFields.status.value);
        formData.set("percentageComplete", assignTaskFields.percentageComplete.value);
        formData.set("reminder", assignTaskFields.reminder.value);
        for (var i = 0; i < assignTaskFields.attachments.attachmentArr.length; i++) {
            formData.append("attachment[]", JSON.stringify(assignTaskFields.attachments.attachmentArr[i]));
        }

        for (var x = 0; x < assignTaskFields.attachments.value.length; x++) {
            formData.append("attachment", assignTaskFields.attachments.value[x]);
        }

        if (taskData.type === "stage") {
            await dispatch(updateStage(formData, id));
        } else if (taskData.type === "task") {
            await dispatch(updateTask(formData, id));
        } else if (taskData.type === "subTask") {
            await dispatch(updateSubTask(formData, id));
        }

        setTaskListState(prev => ({
            ...prev,
            assignTaskEdit: false,
            assignTaskFields: JSON.parse(JSON.stringify(assignTaskFields))
        }));
        // getProjectData({ projectId, dispatch })
        navigate(`${PROJECT_MANAGEMENT}/view/${projectId}`);

    };

    const timeSheetHandler = () => {
        navigate(`/${PROJECT_MANAGEMENT}/view/${projectId}/timesheet/${taskListState.id}`);
    };


    const handleMenuClose = () => {
        setTaskListState(prev => ({
            ...prev,
            anchorEl: null,
            currentEditType: "",
            currentEditId: {},
        }));
    };

    // const handleStageClick = (stageId = "") => {
    //     setTaskListState(prev => ({
    //         ...prev,
    //         stageDialogState: !prev.stageDialogState,
    //     }));

    //     if (stageId) {
    //         setTaskListState(prev => ({
    //             ...prev,
    //             stageId: stageId
    //         }));
    //         getProjectData({ projectId, dispatch })
    //     }
    // };

    const handleTaskClick = (
        isVariation = false,
        stageId = "",
        taskId = "",
        isSubTask = false,
        subTaskId = "",
        editTask = false,
        editSubTask = false,
        optionId = "",
        isOption = false,
        editOption = false,
        action = "new",
        selectedTemplateTask = null
    ) => {
        dispatch(clearTaskAction());
        setTaskListState(prev => ({
            ...prev,
            stageId,
            taskId,
            projectId: projectId,
            isSubTask,
            subTaskId,
            editTask,
            editSubTask,
            optionId,
            action,
            isOption,
            editOption,
            isVariation,
            taskDialogState: !prev.taskDialogState,
            proposalDialogState: false,
            selectedTemplateTask: selectedTemplateTask
        }));
    };

    const optionDialogHandler = (
        optionId = "",
        isOption = true,
        editOption = false,
        action = "new",
        stageId = "",
        taskId = "",
        isSubTask = false,
        subTaskId = "",
        editTask = false,
        editSubTask = false
    ) => {
        dispatch(clearTaskAction());
        setTaskListState(prev => ({
            ...prev,
            stageId,
            taskId,
            isSubTask,
            subTaskId,
            editTask,
            editSubTask,
            action,
            optionId,
            isOption,
            editOption,
            taskDialogState: !prev.taskDialogState,
            proposalDialogState: false,
            selectedTemplateTask: null
        }));
    };

    const taskDialogHandler = (
        stageId = "",
        taskId = "",
        isSubTask = false,
        subTaskId = "",
        editTask = false,
        editSubTask = false,
        optionId = "",
        isOption = false,
        editOption = false,
        action = "new"
    ) => {
        setTaskListState(prev => ({
            ...prev,
            stageId,
            taskId,
            isSubTask,
            subTaskId,
            editTask,
            editSubTask,
            optionId,
            action,
            isOption,
            editOption,
            taskDialogState: !prev.taskDialogState,
            proposalDialogState: false,
            selectedTemplateTask: null
        }));
    };





    const _editProject = async () => {
        if (!projectId) return;

        navigate(`/${PROJECT_MANAGEMENT}/edit/${projectId}`);
        if (props.callStepOne) props.callStepOne();
    };
    const onDragEnd = async (result) => {
        // dropped outside the list
        let destinationid = "";
        if (!result.destination) {
            //console.info("no-change");
            return;
        } else {
            destinationid = result.destination.droppableId.replace("droppable", "");
        }

        const stages = _.cloneDeep(idx(projectDetail, _ => _.project.stages) || []);
        let ordering = await handleDragEnd(result, stages);
        await dispatch(updateTaskOrderingId(
            { ordering_id: ordering.newOrderingId },
            ordering.taskId,
            destinationid
        ));

        getOnlyProjectData({ projectId, dispatch })
    }

    const handleOptionMenu = (
        event,
        type,
        stageId = "",
        taskId = "",
        subTaskId = "",
        optionId = ""
    ) => {
        let menuItem = {};
        const { project: { projectType } } = projectDetail;
        if (type === "OPTION") {
            if (projectType === "TENDER") menuItem.convert = "Convert to Task";
            menuItem.revise = "Create Revision";
        }

        setTaskListState(prev => ({
            ...prev,
            anchorEl: event.currentTarget,
            menuItems: menuItem,
            currentEditType: type,
            currentEditId: { stageId, taskId, subTaskId, optionId },
        }));
    };

    const handleMenuItemClick = async (type) => {
        const { currentEditType, currentEditId = {} } = taskListState;
        const stages = idx(projectDetail, _ => _.project.stages) || [];

        if (type === "add") {
            if (currentEditType === "STAGE") {
                taskDialogHandler(currentEditId.stageId);
            } else if (currentEditType === "TASK") {
                let hasSubTask = false;
                stages.forEach((stage) => {
                    stage.tasks.filter((task) => {
                        if (task.id === currentEditId.taskId && task.stageId === currentEditId.stageId)
                            hasSubTask = task.subTasks.length ? true : false;
                        return task
                    });
                });
                if (hasSubTask) {
                    taskDialogHandler(currentEditId.stageId, currentEditId.taskId, true);
                } else {
                    setTaskListState(prev => ({
                        ...prev,
                        subTaskConfirmModal: !prev.subTaskConfirmModal,
                        proposalDialogState: false,
                    }));
                }
            }
        } else if (type === "copy") {
            await dispatch(duplicateProjectTask(
                currentEditType,
                currentEditId.stageId,
                currentEditId.taskId,
                currentEditId.subTaskId
            ));
            getProjectData({ projectId, dispatch })
        } else if (type === "delete") {
            if (currentEditType === "STAGE") {
                _deleteStage(currentEditId.stageId);
            } else if (currentEditType === "TASK") {
                _deleteTask(currentEditId.taskId);
            } else if (currentEditType === "SUBTASK") {
                _deleteSubTask(currentEditId.subTaskId);
            }
        } else if (type === "convert") {
            setTaskListState(prev => ({
                ...prev,
                convertOptiontoTask: !prev.subTaskConfirmModal,
                proposalDialogState: false,
            }));
        } else if (type === "revise") {
            createOptionRevision(currentEditId.optionId);
        }
    };

    const createOptionRevision = (id) => {
        optionDialogHandler(id, true, true, "revise");
    };

    const _deleteStage = (id) => {
        dispatch(deleteStage(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };

    const _deleteTask = (id) => {
        dispatch(deleteTask(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };
    const _deleteSubTask = (id) => {
        dispatch(deleteSubTask(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };

    const handleTaskTemplateDialog = (id = '') => {
        setTaskListState(prev => ({
            ...prev,
            selectedTaskTemplateStage: id,
            showTaskTemplateDialog: !prev.showTaskTemplateDialog,
        }));
    }

    const loadTaskFromTemplate = (selectedTask) => {
        handleTaskClick(false, taskListState.selectedTaskTemplateStage, "", false, "", false, false, "", false, false, "new", selectedTask)
        handleTaskTemplateDialog()
    };

    return (
        <div className="viewProject">
            <Menu
                id="simple-menu"
                anchorEl={taskListState.anchorEl}
                keepMounted
                open={Boolean(taskListState.anchorEl)}
                onClose={handleMenuClose}
            >
                {Object.entries(taskListState.menuItems).map(([key, value], index) => (
                    <MenuItem key={index} disabled={!updatePermission} onClick={() => handleMenuItemClick(key)}>
                        {value}
                    </MenuItem>
                ))}
            </Menu>
            {taskListState.convertOptiontoTask && <Modal
                open={taskListState.convertOptiontoTask}
                onClose={handleConvertOptiontoTaskClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                fullWidth={false}
                maxWidth="lg"
                modalHeading="Convert Option to Task"
                modalSaveTxt="Yes"
                modalCloseTxt="No"
                onClick={convertOptiontoTask}
                showActionBtn
                savebtnClass="createBtn"
                primary
            >
                <Grid container direction="row" alignItems="center">
                    <Grid md={12} item>
                        Are you sure you would like to convert this Option to a Task and
                        include it in the Project?
                    </Grid>
                </Grid>
            </Modal>}
            {/* <AddStage
                {...props}
                projectName={idx(projectDetail, _ => _.project.projectName)}
                customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                projectId={projectId}
                open={taskListState.stageDialogState}
                dialogClose={() => handleStageClick()}
                stageId={taskListState.stageId}
                totalStageCost={
                    (idx(projectDetail, (_) => _.project.stagesCost[taskListState.stageId].material.sale) || 0) +
                    (idx(projectDetail, (_) => _.project.stagesCost[taskListState.stageId].labor.sale) || 0)
                }
                getStages={() => getProjectData()}
                userAccessControl={props.userAccessControl}
            /> */}
            {idx(projectDetail, _ => _.project.showStageSelect) && <StageSelectDialog
                projectId={projectId}
                open={idx(projectDetail, _ => _.project.showStageSelect)}
                dialogClose={stageSelectHandler}
            />}
            {taskListState.taskDialogState && <AddTask
                {...props}
                open={taskListState.taskDialogState}
                dialogClose={() => handleTaskClick()}
                taskTemplateData={taskListState.selectedTemplateTask}

                projectName={idx(projectDetail, _ => _.project.projectName)}
                customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                projectId={projectId}
                handleConvertOptiontoTaskClose={() => handleConvertOptiontoTaskClose()}
                profitMargin={idx(projectDetail, _ => _.project.projectMarginValue)}
                stageId={taskListState.stageId}
                projectType={idx(projectDetail, _ => _.project.projectType)}
                taskId={taskListState.taskId}
                isSubTask={taskListState.isSubTask}
                isOption={taskListState.isOption}
                getVariations={() => getVariationsData({ projectId, dispatch })}
                isVariation={taskListState.isVariation}
                optionId={taskListState.optionId}
                editOption={taskListState.editOption}
                subTaskId={taskListState.subTaskId}
                action={taskListState.action}
                currentTask={taskListState.currentTask}
                currentTaskCost={(idx(projectDetail, _ => _.project.taskCost) || {})[taskListState.currentTask?.id] || {}}
                customerLevel={idx(projectDetail, _ => _.project.customerLevel)}
                editTask={taskListState.editTask}
                editSubTask={taskListState.editSubTask}
                subcontractors={(idx(projectDetail, _ => _.subcontractors) || [])}
                getStages={() => getProjectData({ projectId, dispatch })}
                getOptions={() => getOptionsData({ projectId, dispatch })}
                options={(idx(projectDetail, _ => _.project.options) || [])}
                liststages={true}
                listvariation={true}
                userAccessControl={props.userAccessControl}
                updatePermission={updatePermission}
            />}
            {taskListState.deleteopen && <DeleteDialog
                open={taskListState.deleteopen}
                deleteId={taskListState.deleteId}
                deleteType={taskListState.deleteType}
                deleteHandler={() => deleteHandler()}
                dialogClose={() => deleteDialogHandler()}
                moduleName={taskListState.deleteType}
            />}
            {taskListState.showPercentageDialog && (
                <CompletionPercentageDialog
                    dialogClose={completionPercentageTaskHandler}
                    setCompletionPercentage={setCompletionPercentage}
                    percentageCompleted={taskListState.percentageCompleted}
                    open={taskListState.showPercentageDialog}
                />
            )}
            {taskListState.showTaskTemplateDialog && (
                <ListTaskTemplate
                    open={taskListState.showTaskTemplateDialog}
                    dialogClose={handleTaskTemplateDialog}
                    saveHandler={() => {
                        getProjectData({ projectId, dispatch })
                        handleTaskTemplateDialog()
                    }}
                    addHandler={loadTaskFromTemplate}
                    stageId={taskListState.selectedTaskTemplateStage}
                    profitMargin={idx(projectDetail, _ => _.project.projectMarginValue)}
                />
            )}
            <div className="view-project-page">
                <div className="overviewContainer">
                    <Paper className="main">
                        <Routes>
                            <Route
                                path={`assigntask/:id/:taskType`}
                                // path={`${PROJECT_MANAGEMENT}/${VIEW_PROJECT}/assigntask/:id/:taskType`}
                                element={
                                    <Grid container style={{ marginBottom: "20px" }}>
                                        <Grid xs={4} className="projectName" item>
                                            {idx(projectDetail, _ => _.project.projectName)}
                                        </Grid>
                                        <Grid xs={8} item className="headerInfo">
                                            <Grid xs={6} item>
                                                <div className="projectInfo">
                                                    {idx(projectDetail, _ => _.project.hasStages) && (<p>
                                                        Stages: <b>{(idx(projectDetail, _ => _.project.stages) || []).length}</b>
                                                    </p>)}
                                                    <p>Tasks: <b>{idx(projectDetail, _ => _.project.totalTasks)}</b></p>
                                                </div>
                                            </Grid>
                                            <Grid xs={6} item style={{ textAlign: "right" }}>
                                                <div className="stockHeader">
                                                    <p className="cancel-btn" onClick={assignTaskSave}>Save</p>
                                                    <p className="cancel-btn" onClick={timeSheetHandler}>Update TimeSheet</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            />
                            {/* <Route
                                path={`assigntask/:id/:taskType`}
                                // path={`${PROJECT_MANAGEMENT}/${EDIT_PROJECT}/assigntask/:id/:taskType`}
                                element={
                                    <Grid container style={{ marginBottom: "20px" }}>
                                    <Grid xs={4} className="projectName" item>
                                        {idx(projectDetail, _ => _.project.projectName)}
                                    </Grid>
                                    <Grid xs={8} item className="headerInfo">
                                        <Grid xs={6} item>
                                        <div className="projectInfo">
                                            {idx(projectDetail, _ => _.project.hasStages) && (
                                            <p>
                                                Stages: <b>{(idx(projectDetail, _ => _.project.stages) || []).length}</b>
                                            </p>
                                            )}
                                            <p>
                                            Tasks: <b>{idx(projectDetail, _ => _.project.totalTasks)}</b>
                                            </p>
                                        </div>
                                        </Grid>
                                        <Grid xs={6} item style={{ textAlign: "right" }}>
                                        <div className="stockHeader">
                                            <p
                                            className="cancel-btn"
                                            onClick={assignTaskSave}
                                            >
                                            Save
                                            </p>
                                            <p
                                            className="cancel-btn"
                                            onClick={timeSheetHandler}
                                            >
                                            Update TimeSheet
                                            </p>
                                        </div>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                }
                                /> */}
                            <Route
                                path=""
                                element={
                                    <Paper className="paperContainer viewProjectCol">
                                        <div className="projectableContainer">
                                            <Grid container className="table-top-row">
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="h6">Projects Tasks</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} className="buttons">
                                                    {/* Commented Add Stage as we have only one stage in project */}
                                                    {/* {idx(projectDetail, _ => _.project.hasStages) && financePermission && (
                                                        <Button variant="contained" onClick={() => handleStageClick()}>ADD STAGE</Button>
                                                    )} */}
                                                    {writePermission && idx(projectDetail, _ => _.project.projectType) === "TENDER" && (
                                                        <Button className="primarybtn" onClick={() => optionDialogHandler()}>ADD OPTION</Button>
                                                    )}
                                                    {writePermission && idx(projectDetail, _ => _.project.projectType) === "TENDER" && (
                                                        <Button className="primarybtn" onClick={() => _editProject()}>EDIT TENDER</Button>
                                                    )}
                                                    {writePermission && (idx(projectDetail, _ => _.project.stages) || []).map((stage) => (
                                                        <div className="task-buttons" key={stage.id}>
                                                            {!(idx(projectDetail, _ => _.project.projectType) == 'PROJECT' && idx(projectDetail, _ => _.project.quoteType) == 'quote') && (<div className="ms-auto btn-task d-flex me-2">
                                                                <Button className="secondarybtn" onClick={() => handleTaskTemplateDialog(stage.id)}>
                                                                    TASK TEMPLATES
                                                                </Button>
                                                                <Button className="secondarybtn" onClick={() => handleTaskClick(false, stage.id)}>
                                                                    ADD TASK
                                                                </Button>
                                                            </div>)}
                                                        </div>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                            <div className="privewTable ">
                                                <DragDropContext
                                                    onDragEnd={onDragEnd}
                                                // onDragUpdate={this.onDragUpdate}
                                                >
                                                    <div style={{ width: "fit-content", minWidth: "100%" }}>
                                                        <ul className="tabel-list table-head">
                                                            <li className="first-cell">
                                                                <span style={{ width: '26px' }}>
                                                                    {/* <DragHandleIcon className="pan-tool" /> */}
                                                                </span>
                                                                Task
                                                            </li>
                                                            {idx(projectDetail, _ => _.project.projectType) != "TENDER" && (
                                                                <li className="my-auto">Completion %</li>
                                                            )}
                                                            {financePermission && idx(projectDetail, _ => _.project.projectType) === "TENDER" && (
                                                                <li className="my-auto text-right">Material</li>
                                                            )}
                                                            {financePermission && idx(projectDetail, _ => _.project.projectType) === "TENDER" && (
                                                                <li className="my-auto text-right">Resource</li>
                                                            )}
                                                            {financePermission && idx(projectDetail, _ => _.project.projectType) === "TENDER" && (
                                                                <li className="my-auto text-right">Contractor</li>
                                                            )}
                                                            {financePermission && <li className="my-auto text-right">Total</li>}
                                                            {financePermission && <li className="my-auto">Actions</li>}
                                                        </ul>
                                                        {/* {(idx(projectDetail, _ => _.project.stages) || []).map((stage) => (
                                                            <div key={stage.id} className="col-12 d-flex py-2 border gap-2">
                                                                {stage.name !== "DummyStage" ? (
                                                                    <div align="left" className="project-stage-name stage-custom-style"
                                                                        onClick={() => handleStageClick(stage.id)}
                                                                    >{stage.name}</div>
                                                                ) : <div align="left" className="project-stage-name stage-custom-style"></div>}
                                                            </div>
                                                        ))} */}
                                                        <Tasks
                                                            stages={idx(projectDetail, _ => _.project.stages) || []}
                                                            deleteDialogHandler={deleteDialogHandler}
                                                            handleTaskClick={handleTaskClick}
                                                            stagesCost={idx(projectDetail, _ => _.project.stagesCost) || {}}
                                                            taskCost={idx(projectDetail, _ => _.project.taskCost) || {}}
                                                            projectType={idx(projectDetail, _ => _.project.projectType)}
                                                            financePermission={financePermission}
                                                            updatePermission={updatePermission}
                                                            quoteType={idx(projectDetail, _ => _.project.quoteType)}
                                                            toggleId={taskListState.toggleId}
                                                        />
                                                        {idx(projectDetail, _ => _.project.projectType) !== "TENDER" && (((idx(projectDetail, _ => _.project.variations) || []).length && (
                                                            <p style={{ "textAlign": "left" }}>
                                                                <b>Variations:</b>
                                                            </p>
                                                        )) || "")}
                                                        <Tasks
                                                            variations={(idx(projectDetail, _ => _.project.variations) || [])}
                                                            handleTaskClick={handleTaskClick}
                                                            deleteDialogHandler={deleteDialogHandler}
                                                            optionDialogHandler={optionDialogHandler}
                                                            handleOptionMenu={handleOptionMenu}
                                                            projectType={idx(projectDetail, _ => _.project.projectType)}
                                                            financePermission={financePermission}
                                                            updatePermission={updatePermission}
                                                            quoteType={idx(projectDetail, _ => _.project.quoteType)}
                                                            optionsTotal={idx(projectDetail, _ => _.project.variationsTotal) || {}}
                                                            toggleId={taskListState.toggleId}
                                                        />
                                                        {idx(projectDetail, _ => _.project.projectType) == "TENDER" && (
                                                            <>
                                                                {((idx(projectDetail, _ => _.project.options) || []).length && (
                                                                    <p style={{ "textAlign": "left" }}>
                                                                        <b>Options:</b>
                                                                    </p>
                                                                )) || ""}
                                                                <Tasks
                                                                    options={(idx(projectDetail, _ => _.project.options) || [])}
                                                                    handleOptionMenu={handleOptionMenu}
                                                                    handleTaskClick={handleTaskClick}
                                                                    optionDialogHandler={optionDialogHandler}
                                                                    deleteDialogHandler={deleteDialogHandler}
                                                                    projectType={idx(projectDetail, _ => _.project.projectType)}
                                                                    financePermission={financePermission}
                                                                    updatePermission={updatePermission}
                                                                    quoteType={idx(projectDetail, _ => _.project.quoteType)}
                                                                    optionsTotal={idx(projectDetail, _ => _.project.optionsTotal) || {}}
                                                                    toggleId={taskListState.toggleId}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </Paper>
                                }
                            />
                        </Routes>
                    </Paper>
                </div>
            </div>
        </div>
    );
}
