import idx from 'idx'
import { microServicesURLs } from '../api/axios'

export const getMaterialsList = (tasks, taskDetail) => {
    let _task = []
    Array.isArray(tasks) && tasks.map((task) => {
        _task.push(task)
        Array.isArray(task.subTasks) && task.subTasks.map((subTask) => {
            _task.push({ ...subTask, taskId: task.id })
        })
    })
    let task = _task.filter(item => (item.id === taskDetail.value || item.taskId === taskDetail.value))
    if (!task.length) return { status: false, msg: 'No Task found' }
    let items = []
    task.map(iterator => {
        Array.isArray(iterator.material) && iterator.material.forEach((item) => {
            let _item = JSON.parse(item)
            items.push({
                ..._item,
                taskDescription: taskDetail.taskDescription,
                taskId: taskDetail.value,
                taskName: taskDetail.key,
                taskType: taskDetail.taskType
            })
        })
    })

    if (!items.length) return { status: false, msg: 'No Items found' }
    return { status: true, data: items }
}

export const filterManufacturer = (items, filterValue) => {
    items = filterValidCatalog(items)
    const filters = makeFilter(items, filterValue);
    if (Object.keys(filters).length) {
        let validItems = items.map(item => { return { ...item, catalog: item?.category?.name || '' } })
        items = customFilter(validItems, filters)
    }
    items = filterUnique(items, 'manufacturer').map(item => { return { key: item, value: item, manufacturer: item } })
    return items;
}

export const filterItems = (items, filterValue) => {
    let _items = filterValidCatalog(items)
    const filters = makeFilter(_items, filterValue);
    if (Object.keys(filters).length) {
        let validItems = _items.map(item => { return { ...item, catalog: item?.category?.name || '' } })
        _items = customFilter(validItems, filters)
    }
    _items = _items.map(item => { return { key: item.item, value: item.id } })
    return _items;
}

const filterUnique = (items, prop) => {
    return [...new Set(items.map((item) => { return item[prop] }))]
}
const filterValidCatalog = (items) => {
    return items.map((item) => { if (item.categoryId) return item }).filter(item => item)
}
const makeFilter = (options, filterValue) => {
    let filters = {};
    const catalog = idx(filterValue, _ => _['catalog']) || '';
    const manufacturer = idx(filterValue, _ => _['manufacturer']) || '';
    const is_manufacturer = manufacturer ? options.some(item => ((item['manufacturer']) ? item['manufacturer'].toLowerCase() : '') === manufacturer.toLowerCase()) : '';
    const partNo = idx(filterValue, _ => _['partNo']) || '';
    const item = idx(filterValue, _ => _['item']) || '';
    const item_key = idx(options, _ => _[0]['item']) ? 'item' : 'key';

    if (catalog) filters['catalog'] = { 'includes': true, 'value': catalog, 'starts': false }
    if (manufacturer) filters['manufacturer'] = { 'includes': !is_manufacturer, 'value': manufacturer, 'starts': false }
    if (partNo) filters['partNo'] = { 'includes': false, 'value': partNo, 'starts': true }
    if (item) filters[item_key] = { 'includes': true, 'value': item, 'starts': false }

    return filters
}
const customFilter = (options, filters) => {
    for (let filter in filters) {
        options = options.filter((item) => {
            if (item[filter]) {
                if (filters[filter].includes) {
                    if (item[filter].toLowerCase().includes(filters[filter].value.toLowerCase())) return item;
                } else if (filters[filter].starts) {
                    if (item[filter].toLowerCase().startsWith(filters[filter].value.toLowerCase())) return item;
                } else {
                    if (item[filter].toLowerCase() === filters[filter].value.toLowerCase()) return item;
                }
            }
        })
    }
    return options;
}
