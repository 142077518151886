import { FINANCE_TAX } from '../constants/action.constant'

const initialState = {
    finance_tax: {}
}

const saveFinanceTaxData = (state, action) => {
    const finance_tax = action.data?.data

    return ({
        ...state,
        finance_tax
    })
}

const globalSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FINANCE_TAX: return saveFinanceTaxData(state, action)
        default: return state
    }
}

export default globalSettingReducer
