import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import ReceiptIcon from '@material-ui/icons/Receipt'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import InsightsIcon from '@mui/icons-material/Insights';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ConstructionIcon from '@mui/icons-material/Construction';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ViewListIcon from '@mui/icons-material/ViewList';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CategoryIcon from '@mui/icons-material/Category';
// import AssignmentIcon from '@mui/icons-material/Assignment';
import TaskIcon from '@mui/icons-material/Task';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import * as paths from 'src/constants/route-paths'

export const navLinks = ({ projectCategories, user, entitlements = {}, permissions }) => {

    let types = [];
    const projectPermissionArr = [];
    projectCategories && projectCategories.forEach((item, i) => {
        let obj = {};
        obj['name'] = item.name;
        obj['path'] = `/${paths.PROJECT_MANAGEMENT}?project-type=${item.type}`;
        obj['projectType'] = item.type;
        obj['icon'] = <BuildCircleIcon fontSize='inherit' />;
        obj['permission'] = user.global_user && user.selected_account.id ? 2 : permissions[`project${item.type}`] || 0;
        obj['permission'] > 0 && types.push(obj);
        projectPermissionArr.push(permissions[`project${item.type}`] || 0);
    })
    let projectPermission = user.global_user && user.selected_account.id ? 2 : Math.max(...projectPermissionArr);

    return [
        {
            name: 'Dashboard',
            path: `/${paths.DASHBOARD}`,
            permission: permissions.dashboard,
            icon: <InsightsIcon fontSize='inherit' />
        },
        {
            name: 'Projects',
            permission: projectPermission,
            icon: <BusinessCenterIcon fontSize='inherit' />,
            subListItems: types
        },
        {
            name: 'Customers',
            path: `/${paths.CUSTOMER_MANAGEMENT}`,
            permission: permissions.customer,
            icon: <AccountCircleIcon fontSize='inherit' />
        },
        {
            name: 'Suppliers',
            permission: permissions.supplier,
            path: `/${paths.SUPPLIERS}/${paths.SUPPLIERS_LIST}`,
            icon: <StorefrontIcon fontSize='inherit' />,
            subListItems: [
                {
                    name: 'Suppliers List',
                    path: `/${paths.SUPPLIERS}/${paths.SUPPLIERS_LIST}`,
                    permission: permissions.supplier,
                    icon: <ListAltIcon fontSize='inherit' />
                },
                {
                    name: 'Purchase Orders',
                    path: `/${paths.SUPPLIERS}/${paths.PURCHASE_ORDER}`,
                    permission: permissions.supplier,
                    icon: <RequestQuoteIcon fontSize='inherit' />,
                },
                {
                    name: 'Supplier Invoices',
                    path: `/${paths.SUPPLIERS}/${paths.VENDOR_PAYMENT}`,
                    permission: permissions.supplier,
                    icon: <PaymentsIcon fontSize='inherit' />,
                }
            ]
        },
        {
            name: 'Contractors',
            permission: permissions.contractor,
            path: `/${paths.CONTRACTORS}/${paths.CONTRACTORS_LIST}`,
            icon: <ConstructionIcon fontSize='inherit' />,
            subListItems: [
                {
                    name: 'Contractors List',
                    path: `/${paths.CONTRACTORS}/${paths.CONTRACTORS_LIST}`,
                    permission: permissions.contractor,
                    icon: <ListAltIcon fontSize='inherit' />
                },
                {
                    name: 'Work Orders',
                    path: `/${paths.CONTRACTORS}/${paths.WORK_ORDER}`,
                    permission: permissions.contractor,
                    icon: <PersonAddAlt1Icon fontSize='inherit' />,
                },
                {
                    name: 'Contractor Invoices',
                    path: `/${paths.CONTRACTORS}/${paths.VENDOR_PAYMENT}`,
                    permission: permissions.contractor,
                    icon: <PaymentsIcon fontSize='inherit' />,
                },
                {
                    name: 'Retention',
                    path: `/${paths.CONTRACTORS}/${paths.RETENTION}`,
                    permission: permissions.contractor,
                    icon: <AccountBalanceWalletIcon fontSize='inherit' />,
                }
            ]
        },
        {
            name: 'Receivables',
            icon: <MonetizationOnIcon fontSize='inherit' />,
            permission: permissions.finance,
            subListItems: [
                {
                    name: 'Invoices',
                    path: `/${paths.RECEIVABLES}/${paths.INVOICES}`,
                    permission: permissions.finance,
                    icon: <PaymentsIcon fontSize='inherit' />,
                },
                {
                    name: 'Retention',
                    path: `/${paths.RECEIVABLES}/${paths.RETENTION}`,
                    permission: permissions.finance,
                    icon: <AccountBalanceWalletIcon fontSize='inherit' />,
                },
            ]
        },
        {
            name: 'Catalogs',
            icon: <ViewListIcon fontSize='inherit' />,
            permission: permissions.catalog,
            subListItems: [
                {
                    name: 'Resources',
                    path: `/${paths.CATALOGS}/${paths.RESOURCES}`,
                    permission: permissions.catalog,
                    icon: <CoPresentIcon fontSize='inherit' />,
                },
                {
                    name: 'Material',
                    path: `/${paths.CATALOGS}/${paths.MATERIAL}`,
                    permission: permissions.catalog,
                    icon: <CategoryIcon fontSize='inherit' />,
                }
            ]
        },
        {
            name: 'Templates',
            icon: <CollectionsBookmarkIcon fontSize='inherit' />,
            permission: permissions.template,
            subListItems: [
                // {
                //     name: 'Project Templates',
                //     path: `/${paths.TEMPLATES}/${paths.PROJECT_TEMPLATE}`,
                //     permission: permissions.template,
                //     icon: <AssignmentIcon fontSize='inherit' />,
                // },
                {
                    name: 'Task Templates',
                    path: `/${paths.TEMPLATES}/${paths.TASK_TEMPLATE}`,
                    permission: permissions.template,
                    icon: <TaskIcon fontSize='inherit' />,
                },
                {
                    name: 'Item Templates',
                    path: `/${paths.TEMPLATES}/${paths.ITEM_TEMPLATE}`,
                    permission: permissions.template,
                    icon: <LabelImportantIcon fontSize='inherit' />,
                }
            ]
        },
        {
            name: 'Inventory',
            icon: <Inventory2OutlinedIcon fontSize='inherit' />,
            path: `/${paths.INVENTORY}`,
            disabled: !entitlements.inventoryMenu,
            permission: permissions.inventory
        },
        {
            name: 'Asset Management',
            icon: <EmojiTransportationIcon fontSize='inherit' />,
            path: `/${paths.ASSET_MANAGEMENT}`,
            disabled: !entitlements.assetMenu,
            permission: permissions.asset
        },
        {
            name: 'Workforce Management',
            icon: <EngineeringIcon fontSize='inherit' />,
            disabled: !(entitlements.timesheetMenu || entitlements.scheduleMenu),
            permission: Math.max(permissions.workforce, permissions.timesheetApprover),
            subListItems: [
                {
                    name: 'TimeSheet',
                    path: `/${paths.TIMESHEET}/${paths.ALLOCATIONS}`,
                    icon: <CalendarMonthIcon fontSize='inherit' />,
                    disabled: !entitlements.timesheetMenu,
                    permission: permissions.timesheetApprover
                },
                {
                    name: 'Schedule',
                    path: `/${paths.TIMESHEET}/${paths.SCHEDULE}`,
                    icon: <PunchClockIcon fontSize='inherit' />,
                    disabled: !entitlements.scheduleMenu,
                    permission: permissions.workforce
                }
            ]
        },
        {
            name: 'User Management', icon: <PeopleAltIcon fontSize='inherit' />, path: `/${paths.USER_MANAGEMENT}`,
            permission: permissions.user
        },
        {
            name: 'Settings', icon: <TuneIcon fontSize='inherit' />, path: `/${paths.GLOBAL_SETTING}`,
            permission: permissions.globalSetting
        },
        {
            name: 'Reports', icon: <ReceiptIcon fontSize='inherit' />, path: '/',
            permission: permissions.reports
        },
        {
            name: 'Accounts Admin', icon: <ManageAccountsIcon fontSize='inherit' />, path: `/${paths.GLOBAL_ACCOUNT}`,
            permission: user.global_user ? 2 : 0
        },

    ]
}