import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/modal';
import './../modal/modal.scss';

function ProfitMarginLock(props) {
  const { lockIconChange, open, dialogClose } = props;
  let {
    message = 'Are you sure you want to revert back to the Project default profit margin? - Any items using default profit margins will also be updated to this value'
  } = props;
  if (!message) message = 'Are you sure you want to revert back to the Project default profit margin? - Any items using default profit margins will also be updated to this value'
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='sm'
      modalHeading={`Profit Margin Lock Changes`}
      modalSaveTxt='Yes'
      modalCloseTxt='No'
      onClick={() => lockIconChange()}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <p>
        {message}
      </p>
    </Modal>
  );
}

ProfitMarginLock.defaultProps = {
  open: false
};

ProfitMarginLock.propTypes = {
  open: PropTypes.bool
};

export default ProfitMarginLock;
