import React, { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import HomeLayout from "./../../layout";
import { connect } from "react-redux";
import * as paths from "./../../constants/route-paths";
import Login from "./../login";

function CustomRoutes() {
  let element = useRoutes([
    { path: paths.LOGIN, element: <Login /> },
    { path: "", element: <HomeLayout /> },
    { path: "*", element: <HomeLayout />, },
  ]);

  return element;
}

class AuthContent extends React.PureComponent {
  state = {
    hideHeader: false,
    setFooterSpace: false,
    hideInMobile: false,
  };
  render() {
    const { location } = this.props;
    return (
      <Fragment>
        <CustomRoutes location={location} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(AuthContent);
