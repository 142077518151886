import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  setVendorPayment,
  getProjectList,
  getPoVendors,
  getPOList,
  getPurchaseOrder
} from 'src/actions/project-management.action'
import './scss/style.scss'
import {
  warningSnackBar
} from 'src/actions/common.action'
import Modal from 'src/components/modal/modal'
import FileUploader from 'src/components/file-uploader'
import idx from 'idx'
import DatePickerInput from "src/components/inputs/date-picker";
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table';
import SelectInput from 'src/components/inputs/select'
import CustomInput from 'src/components/inputs/new-input'
import TextAreaInput from 'src/components/inputs/textarea-input'

function PayInvoice({ invoiceNumber, invoiceClaim, notes, approvedPayment, invoiceUrl, poNumber, isSubContractor, workType, ...props }) {

  const [fields, setFields] = useState({
    invoiceNumber: {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      type: 'text',
      touched: false,
      error: false,
      valid: true,
      value: invoiceNumber || '',
      required: true,
    },
    invoiceDate: {
      name: 'invoiceDate',
      label: 'Invoice Date',
      type: 'datepicker',
      touched: false,
      error: false,
      valid: true,
      value: '',
      required: true,
    },
    invoiceClaim: {
      name: 'invoiceClaim',
      label: 'Invoice Claim',
      type: 'number',
      touched: false,
      error: false,
      valid: true,
      disable: !isSubContractor,
      value: invoiceClaim || 0,
      required: true,
    },
    approvedPayment: {
      name: 'approvedPayment',
      label: 'Approved For Payment',
      disable: !isSubContractor,
      type: 'number',
      touched: false,
      error: false,
      valid: true,
      value: approvedPayment || invoiceClaim || 0,
      required: true,
    },
    lessRetention: {
      name: 'lessRetention',
      label: 'Retention',
      type: 'number',
      touched: false,
      error: false,
      valid: true,
      value: (invoiceClaim - approvedPayment) || 0,
      disable: true
    },
    notes: {
      name: 'notes',
      label: 'Approval Notes',
      type: 'textarea',
      touched: false,
      error: false,
      valid: true,
      value: notes || '',
      required: true,
    },
    attachment: {
      name: 'invoiceURL',
      label: 'Invoice Document',
      type: 'file',
      touched: false,
      error: false,
      valid: true,
      value: invoiceUrl || '',
      required: true,
    },
  })
  const [state, setState] = useState({
    workType,
    projectId: null,
    projectsList: [],
    vendorId: null,
    vendorsList: [],
    poId: poNumber,
    poList: [],
    items: [],
    isSubContractor
  })

  useEffect(() => {
    getProject()
    const { poId } = state
    if (poId) getPurchaseOrder()
  }, [])

  const inputChangeHandler = (e, fieldName = '') => {
    let name
    let value
    let type
    if (e.target.files) {
      name = e.target.name
      value = e.target.files[0]
    } else if (_.isObject(e)) {
      name = e.target.name
      value = e.target.value
      type = e.target.type
    }
    if (type === 'number') {
      value = isNaN(value) || parseFloat(value) < 0 ? 0 : parseFloat(value)
    }

    setFields(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
        touched: true,
      },
      ...((state.isSubContractor && (fieldName === 'invoiceClaim' || fieldName === 'approvedPayment')) ?
        { lessRetention: { ...prev.lessRetention, value: prev.invoiceClaim.value - prev.approvedPayment.value } } : {}),
      ...((fieldName === 'invoiceClaim' && !state.isSubContractor) ?
        { approvedPayment: { ...prev.approvedPayment, value: value } } : {}),
    }));
  }

  const handleValidation = ({ name, value, required }) => {
    let error = false
    let valid = true
    if (required && !value) {
      return { error: 'This Field is Required', valid: false }
    }
    return { error, valid }
  }

  const submitForm = async (generateInvoice = false) => {
    const { poId, items, isSubContractor } = state
    let _fields = _.cloneDeep(fields)
    let isFormValid = true
    for (let key in _fields) {
      if (_fields[key]) {
        const { error, valid } = handleValidation(_fields[key])
        _fields[key].touched = true
        _fields[key].valid = valid
        _fields[key].error = error
        isFormValid = isFormValid && valid
      }
    }

    if (_fields.attachment.value === '') {
      props.warningSnackBar('Please upload invoice')
      return
    }

    if (!isFormValid) {
      setFields(_fields)
    } else {
      const formData = new FormData()
      Object.keys(fields).forEach(key => {
        if (fields[key] && fields[key].name !== 'lessRetention') {
          formData.append(key, fields[key].value)
        }
      })

      const selectedItems = items.filter(item => item.selected === true)
      if (selectedItems.length === 0) {
        props.warningSnackBar('Please select at least one Task')
        return
      }
      for (var i = 0; i < selectedItems.length; i++) {
        delete selectedItems[i].selected
        selectedItems[i].costPerItem = selectedItems[i].cost
        selectedItems[i].name = selectedItems[i].item
        selectedItems[i].newStock = -Math.abs(parseFloat(selectedItems[i].invoicedQty || 0))
        formData.append('items[]', JSON.stringify(selectedItems[i]))
      }

      let invoiceStatus = 'PARTIAL_PAID'
      if (fields.invoiceClaim.value === fields.approvedPayment.value) {
        invoiceStatus = 'PAID'
      }
      formData.append('invoiceStatus', invoiceStatus)
      formData.append('isSubContractor', isSubContractor)

      const amountPaid = parseFloat(fields.approvedPayment.value || 0) - parseFloat(fields.lessRetention.value || 0)
      formData.append('amountPaid', amountPaid)

      let result = await props.setVendorPayment(formData, poId).catch(e => console.log(e))
      props.handleDownloadDialog(result.id)
      props.handleClose()
      if (props.payInvoiceType == 'workOrder') { props.getWOList() }
    }
  }

  const getPurchaseOrder = async (id = '') => {
    const { poId, isSubContractor } = state
    const query = `?poId=${id || poId}`;
    let po = await props.getPurchaseOrder(query)
    const poItems = idx(po, _ => _.items) || []
    const items = []

    Array.isArray(poItems) && poItems.forEach(item => {
      if (isSubContractor) {
        if (item.previousInv < item.total) {
          item.retention = 0
          items.push(item)
        }
      } else {
        const thisInv = parseFloat(item.thisInv || 0)
        if (thisInv > 0) {
          item.invoicedQty = parseFloat(item.thisInv || 0)
          item.amountPaid = parseFloat(item.thisInv || 0) * parseFloat(item.cost || 0)
          items.push(item)
        }
      }
    })
    setState(prev => ({
      ...prev,
      items,
      isDEpo: po.is_de_po
    }));
  }

  const getProject = async () => {
    let projects = await props.getProjectList(null, 'PROJECT')
    let projectsList = []
    projects.forEach(({ name, id }) => { projectsList.push({ key: name, value: id }) })
    projectsList = _.orderBy(projectsList, [({ key }) => key.toLowerCase()], 'asc')
    setState(prev => ({
      ...prev,
      projectsList
    }));
  }

  const changeProject = async (value) => {
    try {
      setState(prev => ({
        ...prev,
        projectId: value
      }));
      let vendorsList = await props.getPoVendors(value)
      const vendors = []
      Array.isArray(vendorsList) && vendorsList.forEach(vendor => {
        const isVendorSelected = vendors.find(({ value }) => value === vendor.vendorId)
        if (!isVendorSelected) {
          vendors.push({
            key: idx(vendor, _ => _.vendor.business_name) || '',
            value: idx(vendor, _ => _.vendor.id) || '',
          })
        }
      })
      setState(prev => ({
        ...prev,
        vendorsList: vendors,
        poId: null,
        vendorId: null,
        poList: []
      }));
    } catch (e) {
      console.error(e)
    }
  }

  const changeVendor = async (value) => {
    if (!value) return;
    setState(prev => ({
      ...prev,
      vendorId: value
    }));
    let poList = await props.getPOList({ vendorId: value })
    const POs = []
    poList.forEach(po => {
      POs.push({
        key: po.id,
        value: po.u_id,
        vendorType: idx(po, _ => _.vendor.type)
      })
    })
    setState(prev => ({
      ...prev,
      poList: POs,
      poId: null
    }));
  }

  const changePO = (value) => {
    const { poList } = state
    const po = poList.find((item) => item.value === value) || {}
    const isSubContractor = po?.vendorType === 'sub-contractor' ? true : false
    setState(prev => ({
      ...prev,
      poId: po.value,
      isSubContractor,
    }));
    setFields(prev => ({
      ...prev,
      approvedPayment: { ...prev.approvedPayment, disable: !isSubContractor }
    }));
    getPurchaseOrder(po.value);
  }

  const itemChangeHandler = (value, index, inputType) => {
    const items = _.cloneDeep(state.items)
    const { isSubContractor } = state
    const item = _.cloneDeep(items[index])

    if (inputType === 'checkbox') {
      item.selected = value
    } else if (inputType === 'approvedAmount') {
      const claimAmount = parseFloat(item.claimAmount || 0)
      const amount = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      if (claimAmount < amount) {
        props.warningSnackBar('Approved amount can be greater than claim amount')
        return
      }
      item.approvedAmount = amount
      item.amountPaid = amount - parseFloat(item.retention || 0)
    } else if (inputType === 'invQty') {
      const invQty = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      item.amountPaid = invQty * parseFloat(item.cost || 0)
      item.invoicedQty = invQty
    } else if (inputType === 'retention') {
      const approvedAmount = parseFloat(item.approvedAmount || 0)
      const retention = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      if (approvedAmount < retention) {
        props.warningSnackBar('Retention amount can be greater than approved amount')
        return
      }
      item.retention = retention
      item.amountPaid = parseFloat(item.approvedAmount || 0) - retention
    } else if (inputType === 'claimAmount') {
      const amount = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      item.claimAmount = amount
    }

    items[index] = _.cloneDeep(item)

    let totalAmountPaid = 0
    let total = 0
    let retentionAmount = 0
    items.forEach(({ selected, amountPaid, approvedAmount, claimAmount, retention }) => {
      if (selected) {
        if (isSubContractor) {
          total += (parseFloat(claimAmount || 0))
          retentionAmount += parseFloat(retention || 0)
          totalAmountPaid += (parseFloat(approvedAmount || 0))
        } else {
          total += (parseFloat(amountPaid || 0))
          totalAmountPaid += (parseFloat(amountPaid || 0))
        }

      }
    })
    setState(prev => ({
      ...prev,
      items
    }));
    setFields(prev => ({
      ...prev,
      invoiceClaim: { ...prev.invoiceClaim, value: total },
      approvedPayment: { ...prev.approvedPayment, value: totalAmountPaid },
      lessRetention: { ...prev.lessRetention, value: retentionAmount }
    }));
  }

  return (
    <Modal
      open={true}
      onClose={props.handleClose}
      onClick={() => submitForm(true)}
      modalHeading={'Receive Invoice'}
      modalSaveTxt={'Receive Invoice & Download'}
      modalCloseTxt='Cancel'
      modalCloseBtn={() => props.handleClose(false)}
      showActionBtn={true}
      fullWidth={true}
      maxWidth={'lg'}
      cxlbtnclassName='cancelBtn'
      savebtnclassName='createBtn'
      primary
    >
      <div className="purchase-order-block mb-3">
        <div className="row">
          {poNumber && <div className="col-12 mt-3">
            <div className="view-block d-flex">
              <label>PO NO</label>
              <p className="ms-3"><b>{props.poNo || poNumber}</b></p>
            </div>
          </div>}
          {!poNumber && <>
            <div className="col-lg-4 col-md-4 mt-3">
              <SelectInput
                label='Select Project'
                items={state.projectsList}
                value={state.projectId}
                onChange={(e) => changeProject(e.target.value)}
              />
            </div>
            <div className="col-lg-4 col-md-4 mt-3">
              <SelectInput
                label='Select Vendor'
                items={state.vendorsList}
                value={state.vendorId}
                onChange={(e) => changeVendor(e.target.value)}
              />
            </div>
            <div className="col-lg-4 col-md-4 mt-3">
              <SelectInput
                label='Select Purchase Order'
                items={state.poList}
                value={state.poId}
                onChange={(e) => changePO(e.target.value)}
              />
            </div>
          </>}
          <div className="col-lg-4 col-md-4 mt-3">
            <CustomInput
              placeholder="Enter Invoice Number"
              {...fields.invoiceNumber}
              onChange={(e) => inputChangeHandler(e, 'invoiceNumber')}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <DatePickerInput
              {...fields.invoiceDate}
              onChange={(e, context) => {
                inputChangeHandler({
                  target: { value: e, name: 'invoiceDate', type: 'date' },
                }, "invoiceDate")
              }
              }
            />
          </div>
        </div>
      </div>
      {state.poId && <>
        <div className="col-12">
          <div className="material-list-block mt-4">
            <div className="table-responsive">
              <Table bordered className="table-create table-material table-material-ch tbl-fixed">
                <thead>
                  <tr>
                    {!state.isSubContractor ?
                      <>
                        <th className="w-auto">Select Item</th>
                        {!state.isDEpo && <th className="w-auto">Task</th>}
                        <th>Catalog</th>
                        <th>Items</th>
                        <th>Manufacturer</th>
                        <th>Part No</th>
                        <th className="text-right">Unit Cost</th>
                        <th>Qty Ordered</th>
                        <th>Qty Received</th>
                        <th className="text-right">Previous Inv</th>
                        <th >This Inv</th>
                        <th>Unit</th>
                        <th className="text-right">Total</th>
                      </>
                      :
                      <>
                        <th className="w-auto">Select Task</th>
                        <th className="w-auto">Task</th>
                        <th>Task Description</th>
                        <th className="text-right">Total Cost</th>
                        <th className="text-right">Previous Inv</th>
                        <th>Invoice Claim</th>
                        <th>Approved Amount</th>
                        <th>Retained</th>
                        <th className="text-right">Total</th>
                      </>
                    }
                  </tr>
                </thead>
                <tbody>
                  {!state.isSubContractor ?
                    <>
                      {(Array.isArray(state.items) && state.items.length && state.items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="table-checkbox">
                              <Form.Check
                                type={'checkbox'}
                                value={item.selected}
                                onChange={(e) => itemChangeHandler(e.target.checked, index, 'checkbox')}
                              />
                            </div>
                          </td>
                          {!state.isDEpo && <td>{item.taskName}</td>}
                          <td>{item.category}</td>
                          <td>{item.item}</td>
                          <td>{item.manufacturer}</td>
                          <td>{item.partNo}</td>
                          <td className="text-right">{item.cost}</td>
                          <td>{item.qty}</td>
                          <td>{item.receivedQty}</td>
                          <td className="text-right">{item.previousInv}</td>
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              type='number'
                              value={item.invoicedQty}
                              onChange={(e) => itemChangeHandler(e.target.value, index, 'invQty')}
                            />
                          </td>
                          <td>{item.unit}</td>
                          <td className="text-right">${item.amountPaid}</td>
                        </tr>
                      ))) || <tr>
                          <td className='text-center' colSpan={!state.isDEpo ? 13 : 12}>No Items to Show</td>
                        </tr>
                      }
                    </>
                    :
                    <>
                      {(Array.isArray(state.items) && state.items.length && state.items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="table-checkbox">
                              <Form.Check
                                type={'checkbox'}
                                value={item.selected}
                                onChange={(e) => itemChangeHandler(e.target.checked, index, 'checkbox')}
                              />
                            </div>
                          </td>
                          <td>{item.taskName}</td>
                          <td>{item.taskDescription}</td>
                          <td className="text-right">${parseFloat(item.total) || 0}</td>
                          <td className="text-right">${parseFloat(item.previousInv) || 0}</td>
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              type='number'
                              value={item.claimAmount}
                              onChange={(e) => itemChangeHandler(e.target.value, index, 'claimAmount')}
                            />
                          </td>
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              type='number'
                              value={item.approvedAmount}
                              onChange={(e) => itemChangeHandler(e.target.value, index, 'approvedAmount')}
                            />
                          </td>
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              type='number'
                              value={item.retention}
                              onChange={(e) => itemChangeHandler(e.target.value, index, 'retention')}
                            />
                          </td>
                          <td className="text-right">${item.amountPaid}</td>
                        </tr>
                      ))) || <p style={{ textAlign: 'center', width: '100%' }}>No Items to Show</p>}
                    </>
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-6 mt-3">
            <div className="view-block">
              <label>Invoice Claim</label>
              <p><b>${parseFloat(fields.invoiceClaim.value || 0)}</b></p>
            </div>
          </div>
          {state.isSubContractor && <>
            <div className="col-lg-3 col-6  mt-3">
              <div className="view-block">
                <label>Approved For Payment</label>
                <p><b>${parseFloat(fields.approvedPayment.value || 0)}</b></p>
              </div>
            </div>
            <div className="col-lg-3 col-6 mt-3">
              <div className="view-block">
                <label>Retention</label>
                <p><b>${parseFloat(fields.lessRetention.value || 0)}</b></p>
              </div>
            </div>
            <div className="col-lg-3 col-6 mt-3">
              <div className="view-block">
                <label>Amount To Pay</label>
                <p><b>${parseFloat(fields.approvedPayment.value || 0) - parseFloat(fields.lessRetention.value || 0)}</b></p>
              </div>
            </div>
          </>}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 mt-3 fileupload-section">
            <div className="file-section-part position-relative h-auto px-0">
              <div className="file-part">
                <FileUploader
                  fileHandler={(files) =>
                    inputChangeHandler({ target: { files, type: 'file', name: 'attachment' } })
                  }
                />
                <label htmlFor="#file-section"></label>
              </div>
              <input type="file" id="file-section"></input>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mt-3">
            <div className="form-group text-left">
              <div className="input-block">
                <label className="input-label">{fields.attachment.label}</label>
                <p className="m-0 doc-info">
                  <span>{fields.attachment?.value?.name || ' '}</span>
                  {/* <span><DeleteIcon /></span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 mb-3">
            <TextAreaInput
              {...fields.notes}
              required={true}
              label={'Notes'}
              onChange={(e) => inputChangeHandler(e, 'notes')}
            />
          </div>
        </div>
      </>}
    </Modal >
  )

}

const mapDispatchToProps = dispatch => ({
  setVendorPayment: bindActionCreators(setVendorPayment, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getPoVendors: bindActionCreators(getPoVendors, dispatch),
  getPOList: bindActionCreators(getPOList, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
})

export default connect(null, mapDispatchToProps)(PayInvoice);
