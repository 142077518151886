import Cookies from 'js-cookie'

const setExpirationTime = (minutes) => {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
};
export const cookieOption = {
  secure: true,
  sameSite: 'Strict'
}

export const setCookie = ({ name = '', value = '', addExpirationTime = false, expirationTime = 60 }) => {
  if (!name || !value) {
    console.log(`Invalid Cookie cookie name or value missing`)
    return
  }
  const expires = addExpirationTime ? setExpirationTime(expirationTime) : '';
  Cookies.set(name, value, {
    ...cookieOption,
    ...(addExpirationTime ? { expires: setExpirationTime(expirationTime) } : {}),
  })
}

export const getAccessToken = () => {
  return Cookies.get('authToken')
}

export const saveAccessToken = (value) => {
  setCookie({ name: 'authToken', value: value, addExpirationTime: true })
}

export const removeAccessToken = () => {
  Cookies.remove('authToken')
}

export const getRefreshToken = () => {
  return Cookies.get('refreshToken')
}

export const saveRefreshToken = (value) => {
  setCookie({ name: 'refreshToken', value: value, addExpirationTime: true, expirationTime: 120 })
}

export const removeRefreshToken = (value) => {
  Cookies.remove('refreshToken')
}
