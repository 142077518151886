import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  setVendorPayment,
  getProjectList,
  getPoVendors,
  getPOList,
  getPurchaseOrder
} from '../../actions/project-management.action'
import {
  setVendorPayment as setServiceVendorPayment,
  getServicesList,
  getPoVendors as getServicePOVendors,
  getPOList as getServicePOList,
  getPurchaseOrder as getServicePurchaseOrder
} from '../../actions/service.action'
import './scss/style.scss'
import {
  warningSnackBar
} from '../../actions/common.action'
import Modal from '../../components/modal/modal'
import FileUploader from '../../components/file-uploader'
import idx from 'idx'
import DatePickerInput from "src/components/inputs/date-picker";
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table';
import SelectInput from 'src/components/inputs/select'
import CustomInput from 'src/components/inputs/new-input'

class PayInvoice extends Component {
  constructor(props) {
    super(props);
    const {
      invoiceNumber,
      invoiceClaim,
      notes,
      approvedPayment,
      invoiceUrl,
      poNumber,
      poId,
      isSubContractor,
      workType
    } = props
    const fields = {
      invoiceNumber: {
        name: 'invoiceNumber',
        label: 'Invoice Number',
        type: 'text',
        touched: false,
        error: false,
        valid: true,
        value: invoiceNumber || '',
        required: true,
      },
      invoiceDate: {
        name: 'invoiceDate',
        label: 'Invoice Date',
        type: 'datepicker',
        touched: false,
        error: false,
        valid: true,
        value: '',
        required: true,
      },
      invoiceClaim: {
        name: 'invoiceClaim',
        label: 'Invoice Claim',
        type: 'number',
        touched: false,
        error: false,
        valid: true,
        disable: !this.props.isSubContractor,
        value: invoiceClaim || 0,
        required: true,
      },
      approvedPayment: {
        name: 'approvedPayment',
        label: 'Approved For Payment',
        disable: !this.props.isSubContractor,
        type: 'number',
        touched: false,
        error: false,
        valid: true,
        value: approvedPayment || invoiceClaim || 0,
        required: true,
      },
      lessRetention: {
        name: 'lessRetention',
        label: 'Retention',
        type: 'number',
        touched: false,
        error: false,
        valid: true,
        value: (invoiceClaim - approvedPayment) || 0,
        disable: true
      },
      notes: {
        name: 'notes',
        label: 'Approval Notes',
        type: 'textarea',
        touched: false,
        error: false,
        valid: true,
        value: notes || '',
        required: true,
      },
      attachment: {
        name: 'invoiceURL',
        label: 'Invoice Document',
        type: 'file',
        touched: false,
        error: false,
        valid: true,
        value: invoiceUrl || '',
        required: true,
      },
    }
    this.state = {
      fields: fields,
      workType,
      projectId: null,
      projectsList: [],
      vendorId: null,
      vendorsList: [],
      poId: poId,
      poNumber: poNumber,
      poList: [],
      items: [],
      isSubContractor: isSubContractor || false
    }
  }

  componentDidMount() {
    this.getProject()
    const { poId } = this.state
    if (poId) {
      this.getPurchaseOrder()
    }
  }

  inputChangeHandler = (e, fieldName = '') => {
    let fields = _.cloneDeep(this.state.fields)
    let name
    let value
    let type
    if (e.target.files) {
      name = e.target.name
      value = e.target.files[0]
    } else if (_.isObject(e)) {
      name = e.target.name
      value = e.target.value
      type = e.target.type
    }

    if (type === 'number') {
      value = isNaN(value) || parseFloat(value) < 0 ? 0 : parseFloat(value)
    }

    fields[name].value = value
    fields[name].touched = true
    if (this.state.isSubContractor && (fieldName === 'invoiceClaim' || fieldName === 'approvedPayment')) {
      fields['lessRetention'].value = fields.invoiceClaim.value - fields.approvedPayment.value
    }

    if (fieldName === 'invoiceClaim' && !this.state.isSubContractor) {
      fields['approvedPayment'].value = e.target.value
    }
    this.setState({ fields })
  }

  handleValidation = ({ name, value, required }) => {
    let error = false
    let valid = true

    if (required && !value) {
      return { error: 'This Field is Required', valid: false }
    }

    return { error, valid }
  }

  submitForm = async (generateInvoice = false) => {

    const { fields, poId, workType, items, isSubContractor } = this.state
    let isFormValid = true
    for (let key in fields) {
      if (fields[key]) {
        const { error, valid } = this.handleValidation(fields[key])
        fields[key].touched = true
        fields[key].valid = valid
        fields[key].error = error
        isFormValid = isFormValid && valid
      }
    }

    if (!isFormValid) {
      this.setState({ fields })
    } else {

      const formData = new FormData()
      Object.keys(fields).forEach(key => {
        if (fields[key] && fields[key].name !== 'lessRetention') {
          formData.append(key, fields[key].value)
        }
      })

      const selectedItems = items.filter(item => item.selected === true)
      for (const fieldValue in fields) {
        const field = fields[fieldValue];
        if (!field.valid) {
          if (field.name === 'approvedPayment' || field.name === 'invoiceClaim') {
            if (selectedItems.length === 0) {
              this.props.warningSnackBar('Please select at least one Task')
              return
            }
          } else if (field.name === 'invoiceURL') {
            this.props.warningSnackBar('Please upload invoice')
            return
          } else {
            this.props.warningSnackBar(`Field "${field.label}" is invalid.`)
            return
          }
        }
      }

      for (var i = 0; i < selectedItems.length; i++) {
        delete selectedItems[i].selected
        selectedItems[i].costPerItem = selectedItems[i].cost
        selectedItems[i].name = selectedItems[i].item
        selectedItems[i].newStock = -Math.abs(parseFloat(selectedItems[i].invoicedQty || 0))
        formData.append('items[]', JSON.stringify(selectedItems[i]))
      }

      let invoiceStatus = 'PARTIAL_PAID'
      if (fields.invoiceClaim.value === fields.approvedPayment.value) {
        invoiceStatus = 'PAID'
      }
      formData.append('invoiceStatus', invoiceStatus)
      formData.append('isSubContractor', isSubContractor)

      const amountPaid = parseFloat(fields.approvedPayment.value || 0) - parseFloat(fields.lessRetention.value || 0)
      formData.append('amountPaid', amountPaid)

      let result = ''
      if (workType === 'service') {
        result = await this.props.setServiceVendorPayment(formData, poId)
      } else {
        result = await this.props.setVendorPayment(formData, poId)
      }
      this.props.handleDownloadDialog(result.id)
      this.props.handleClose()
      if (this.props.payInvoiceType == 'workOrder') {
        this.props.getWOList()
      }
    }
  }

  generateInvoice = () => {
    this.props.generateInvoice().then()
  }
  seletectFile = () => {
    document.getElementById("fileInput").click();
  }

  changeWorkType = (value) => {
    this.setState({
      workType: value,
      projectId: null,
      poId: null,
      vendorId: null,
      vendorsList: [],
      poList: []
    }, () => this.getProject())
  }

  getPurchaseOrder = async () => {
    const { poId, workType, isSubContractor } = this.state
    const query = `?poId=${poId}`
    let po
    if (workType === 'service') {
      po = await this.props.getServicePurchaseOrder(query)
    } else if (workType === 'project') {
      po = await this.props.getPurchaseOrder(query)
    }

    const poItems = idx(po, _ => _.items) || []
    const items = []

    Array.isArray(poItems) && poItems.forEach(item => {
      if (isSubContractor) {
        if (item.previousInv < item.total) {
          item.retention = 0
          items.push(item)
        }
      } else {
        const thisInv = parseFloat(item.thisInv || 0)
        if (thisInv > 0) {
          item.invoicedQty = parseFloat(item.thisInv || 0)
          item.amountPaid = parseFloat(item.thisInv || 0) * parseFloat(item.cost || 0)
          items.push(item)
        }
      }
    })

    this.setState({ items, isDEpo: po.is_de_po })
  }

  getProject = async () => {
    const { workType } = this.state
    let projects
    if (workType === 'service') {
      projects = await this.props.getServicesList(null, 'SERVICE')
    } else {
      projects = await this.props.getProjectList(null, 'PROJECT')
    }

    let projectsList = []
    projects.forEach(({ name, id }) => { projectsList.push({ key: name, value: id }) })
    projectsList = _.orderBy(projectsList, [({ key }) => key.toLowerCase()], 'asc')

    this.setState({ projectsList })
  }

  changeProject = async (value) => {
    try {
      this.setState({ projectId: value })
      let vendorsList
      vendorsList = await this.props.getPoVendors(value)
      const vendors = []
      Array.isArray(vendorsList) && vendorsList.forEach(vendor => {
        const isVendorSelected = vendors.find(({ value }) => value === vendor.vendorId)
        if (!isVendorSelected) {
          vendors.push({
            key: idx(vendor, _ => _.vendor.business_name) || '',
            value: idx(vendor, _ => _.vendor.id) || '',
          })
        }
      })
      this.setState({
        vendorsList: vendors,
        poId: null,
        vendorId: null,
        poList: []
      })
    } catch (e) {
      console.error(e)
    }
  }

  changeVendor = async (value) => {
    this.setState({ vendorId: value })
    const { workType } = this.state
    let poList
    if (workType === 'project') {
      poList = await this.props.getPOList({ vendorId: value })
    } else if (workType === 'service') {
      poList = await this.props.getServicePOList({ vendorId: value })
    }

    const POs = []
    poList.forEach(po => {
      POs.push({
        key: po.id,
        value: po.u_id,
        vendorType: idx(po, _ => _.vendor.type)
      })
    })
    this.setState({
      poList: POs,
      poId: null
    })
  }

  changePO = (value) => {
    const { poList } = this.state
    const po = poList.find((item) => item.value === value) || {}
    const isSubContractor = po?.vendorType === 'sub-contractor' ? true : false
    const fields = _.cloneDeep(this.state.fields)
    fields.approvedPayment.disable = !isSubContractor
    this.setState({ poId: po.value, isSubContractor, fields }, () => this.getPurchaseOrder())
  }

  itemChangeHandler = (value, index, inputType) => {
    const items = _.cloneDeep(this.state.items)
    const fields = _.cloneDeep(this.state.fields)
    const { isSubContractor } = this.state
    const item = _.cloneDeep(items[index])

    if (inputType === 'checkbox') {
      item.selected = value
    } else if (inputType === 'approvedAmount') {
      const claimAmount = parseFloat(item.claimAmount || 0)
      const amount = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)

      if (claimAmount < amount) {
        this.props.warningSnackBar('Approved amount can be greater than claim amount')
        return
      }

      item.approvedAmount = amount
      item.amountPaid = amount - parseFloat(item.retention || 0)
    } else if (inputType === 'invQty') {
      const invQty = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      item.amountPaid = invQty * parseFloat(item.cost || 0)
      item.invoicedQty = invQty
    } else if (inputType === 'retention') {
      const approvedAmount = parseFloat(item.approvedAmount || 0)
      const retention = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)

      if (approvedAmount < retention) {
        this.props.warningSnackBar('Retention amount can be greater than approved amount')
        return
      }

      item.retention = retention
      item.amountPaid = parseFloat(item.approvedAmount || 0) - retention
    } else if (inputType === 'claimAmount') {
      const amount = parseFloat(value || 0) < 0 ? 0 : parseFloat(value || 0)
      item.claimAmount = amount
    }

    items[index] = _.cloneDeep(item)

    let totalAmountPaid = 0
    let total = 0
    let retentionAmount = 0
    items.forEach(({ selected, amountPaid, approvedAmount, claimAmount, retention }) => {
      if (selected) {
        if (isSubContractor) {
          total += (parseFloat(claimAmount || 0))
          retentionAmount += parseFloat(retention || 0)
          totalAmountPaid += (parseFloat(approvedAmount || 0))
        } else {
          total += (parseFloat(amountPaid || 0))
          totalAmountPaid += (parseFloat(amountPaid || 0))
        }

      }
    })
    fields.invoiceClaim.value = total
    fields.approvedPayment.value = totalAmountPaid
    fields.lessRetention.value = retentionAmount
    this.setState({ items, fields })
  }

  render() {
    const {
      fields,
      workType,
      projectId,
      projectsList,
      vendorId,
      vendorsList,
      poId,
      poList,
      isSubContractor,
      items,
      isDEpo
    } = this.state
    const { poNumber, poNo } = this.props

    return (
      <Modal
        open={true}
        onClose={this.props.handleClose}
        onClick={() => this.submitForm(true)}
        modalHeading={'Receive Invoice'}
        modalSaveTxt={'Receive Invoice & Download'}
        modalCloseTxt='Cancel'
        modalCloseBtn={() => this.props.handleClose(false)}
        showActionBtn={true}
        fullWidth={true}
        maxWidth={'lg'}
        cxlbtnclassName='cancelBtn'
        savebtnclassName='createBtn'
        primary
      >
        <div className="purchase-order-block mb-3">
          <div className="row">
            {poNumber && <div className="col-12 mt-3">
              <div className="view-block d-flex">
                <label>PO NO</label>
                <p className="ms-3"><b>{poNo || poNumber}</b></p>
              </div>
            </div>}
            {!poNumber && <>
              <div className="col-lg-4 col-md-4 mt-3">
                <SelectInput
                  label='Select Work Type'
                  items={[
                    { key: 'Project', value: 'project' }
                  ]}
                  value={workType}
                  onChange={(e) => this.changeWorkType(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-4 mt-3">
                <SelectInput
                  label='Select Project'
                  items={projectsList}
                  value={projectId}
                  onChange={(e) => this.changeProject(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-4 mt-3">
                <SelectInput
                  label='Select Vendor'
                  items={vendorsList}
                  value={vendorId}
                  onChange={(e) => this.changeVendor(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-4 mt-3">
                <SelectInput
                  label='Select Purchase Order'
                  items={poList}
                  value={poId}
                  onChange={(e) => this.changePO(e.target.value)}
                />
              </div>
            </>}
            <div className="col-lg-4 col-md-4 mt-3">
              <CustomInput
                placeholder="Enter Invoice Number"
                {...fields.invoiceNumber}
                onChange={(e) => this.inputChangeHandler(e, 'invoiceNumber')}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <DatePickerInput
                {...fields.invoiceDate}
                onChange={(e, context) => {
                  this.inputChangeHandler({
                    target: { value: e, name: 'invoiceDate', type: 'date' },
                  }, "invoiceDate")
                }
                }
              />
            </div>
          </div>
        </div>
        {poId && <>
          <div className="col-12">
            <div className="material-list-block mt-4">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch tbl-fixed">
                  <thead>
                    <tr>
                      {!isSubContractor ?
                        <>
                          <th className="w-auto">Select Item</th>
                          {!isDEpo && <th className="w-auto">Task</th>}
                          <th>Catalog</th>
                          <th>Items</th>
                          <th>Manufacturer</th>
                          <th>Part No</th>
                          <th className="text-right">Unit Cost</th>
                          <th>Qty Ordered</th>
                          <th>Qty Received</th>
                          <th className="text-right">Previous Inv</th>
                          <th >This Inv</th>
                          <th>Unit</th>
                          <th className="text-right">Total</th>
                        </>
                        :
                        <>
                          <th className="w-auto">Select Task</th>
                          <th className="w-auto">Task</th>
                          <th>Task Description</th>
                          <th className="text-right">Total Cost</th>
                          <th className="text-right">Previous Inv</th>
                          <th>Invoice Claim</th>
                          <th>Approved Amount</th>
                          <th>Retained</th>
                          <th className="text-right">Total</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {!isSubContractor ?
                      <>
                        {(Array.isArray(items) && items.length && items.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="table-checkbox">
                                <Form.Check
                                  type={'checkbox'}
                                  value={item.selected}
                                  onChange={(e) => this.itemChangeHandler(e.target.checked, index, 'checkbox')}
                                />
                              </div>
                            </td>
                            {!isDEpo && <td>{item.taskName}</td>}
                            <td>{item.category}</td>
                            <td>{item.item}</td>
                            <td>{item.manufacturer}</td>
                            <td>{item.partNo}</td>
                            <td className="text-right">{item.cost}</td>
                            <td>{item.qty}</td>
                            <td>{item.receivedQty}</td>
                            <td className="text-right">{item.previousInv}</td>
                            <td>
                              <CustomInput
                                className="input-block-sm"
                                type='number'
                                value={item.invoicedQty}
                                onChange={(e) => this.itemChangeHandler(e.target.value, index, 'invQty')}
                              />
                            </td>
                            <td>{item.unit}</td>
                            <td className="text-right">${item.amountPaid}</td>
                          </tr>
                        ))) || <tr>
                            <td className='text-center' colSpan={!isDEpo ? 13 : 12}>No Items to Show</td>
                          </tr>
                        }
                      </>
                      :
                      <>
                        {(Array.isArray(items) && items.length && items.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="table-checkbox">
                                <Form.Check
                                  type={'checkbox'}
                                  value={item.selected}
                                  onChange={(e) => this.itemChangeHandler(e.target.checked, index, 'checkbox')}
                                />
                              </div>
                            </td>
                            <td>{item.taskName}</td>
                            <td>{item.taskDescription}</td>
                            <td className="text-right">${parseFloat(item.total) || 0}</td>
                            <td className="text-right">${parseFloat(item.previousInv) || 0}</td>
                            <td>
                              <CustomInput
                                className="input-block-sm"
                                type='number'
                                value={item.claimAmount}
                                onChange={(e) => this.itemChangeHandler(e.target.value, index, 'claimAmount')}
                              />
                            </td>
                            <td>
                              <CustomInput
                                className="input-block-sm"
                                type='number'
                                value={item.approvedAmount}
                                onChange={(e) => this.itemChangeHandler(e.target.value, index, 'approvedAmount')}
                              />
                            </td>
                            <td>
                              <CustomInput
                                className="input-block-sm"
                                type='number'
                                value={item.retention}
                                onChange={(e) => this.itemChangeHandler(e.target.value, index, 'retention')}
                              />
                            </td>
                            <td className="text-right">${item.amountPaid}</td>
                          </tr>
                        ))) || <p style={{ textAlign: 'center', width: '100%' }}>No Items to Show</p>}
                      </>
                    }
                  </tbody>
                </Table>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-6 mt-3">
              <div className="view-block">
                <label>Invoice Claim</label>
                <p><b>${parseFloat(fields.invoiceClaim.value || 0)}</b></p>
              </div>
            </div>
            {isSubContractor && <>
              <div className="col-lg-3 col-6  mt-3">
                <div className="view-block">
                  <label>Approved For Payment</label>
                  <p><b>${parseFloat(fields.approvedPayment.value || 0)}</b></p>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-3">
                <div className="view-block">
                  <label>Retention</label>
                  <p><b>${parseFloat(fields.lessRetention.value || 0)}</b></p>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-3">
                <div className="view-block">
                  <label>Amount To Pay</label>
                  <p><b>${parseFloat(fields.approvedPayment.value || 0) - parseFloat(fields.lessRetention.value || 0)}</b></p>
                </div>
              </div>
            </>}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mt-3 fileupload-section">
              <div className="file-section-part position-relative h-auto px-0">
                <div className="file-part">
                  <FileUploader
                    fileHandler={(files) =>
                      this.inputChangeHandler({ target: { files, type: 'file', name: 'attachment' } })
                    }
                  />
                  <label htmlFor="#file-section"></label>
                </div>
                <input type="file" id="file-section"></input>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-3">
              <div className="form-group text-left">
                <div className="input-block">
                  <label className="input-label">{fields.attachment.label}</label>
                  <p className="m-0 doc-info">
                    <span>{fields.attachment?.value?.name || ' '}</span>
                    {/* <span><DeleteIcon /></span> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="form-group">
                <div className="input-block">
                  <label className="input-label">Notes</label>
                  <textarea
                    className="form-control"
                    {...fields.notes}
                    onChange={(e) => this.inputChangeHandler(e, 'notes')}
                  />
                </div>
              </div>
            </div>
          </div>
        </>}
      </Modal >
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setVendorPayment: bindActionCreators(setVendorPayment, dispatch),
  setServiceVendorPayment: bindActionCreators(setServiceVendorPayment, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
  getPoVendors: bindActionCreators(getPoVendors, dispatch),
  getServicePOVendors: bindActionCreators(getServicePOVendors, dispatch),
  getPOList: bindActionCreators(getPOList, dispatch),
  getServicePOList: bindActionCreators(getServicePOList, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
  getServicePurchaseOrder: bindActionCreators(getServicePurchaseOrder, dispatch),
})

export default connect(null, mapDispatchToProps)(PayInvoice);
