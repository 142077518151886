import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import CategoryCard from '../../components/category-card'
import './scss/project-management.scss'
import { withRouter } from "../../util/with-router";
import { PROJECT_MANAGEMENT, VENDOR_PAYMENT } from '../../constants/route-paths'
import { getvendorsPOInvoiceCount } from './../../actions/project-management.action'
import commonReducer from '../../reducers/common.reducer';
import { useNavigate } from "react-router-dom";
import BudgetImg from '../../assets/images/budget.png';
import PurchaseOrderImg from '../../assets/images/po.png';
import WorkOrderImg from '../../assets/images/wo.png';
import InvoiceImg from '../../assets/images/invoice.png';
import VendorInvoiceImg from '../../assets/images/vendor-invoice.png';
import InternalStockImg from '../../assets/images/internal-stock.png';
import ReservedStockImg from '../../assets/images/reserved-stock.png';
import VariationImg from '../../assets/images/variation.png';
const App_Version = Number(commonReducer('', { type: 'APP_VERSION' })) || 1;

function OverviewDetails({ financePermission, rFinancePermission, rSupplierPermission, rContractorPermission, ...props }) {

  const [vendorsPaymentsCount, setVendorsPaymentsCount] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    if (props.projectId && vendorsPaymentsCount === null) {
      props.getvendorsPOInvoiceCount(props.projectId).then(({ invoicecount }) => setVendorsPaymentsCount(invoicecount))
    }
  }, [props.projectId])

  const {
    totalRFQSend,
    totalVariation,
    projectId,
    quoteType,
    progressClaimCount,
    purchaseOrdersCount,
    workOrdersCount,
    invoicesCount,
  } = props

  return (
    <div className='projectOverviewContainer' style={{ margin: '0' }}>

      <Grid container className='task-cards-section'>
        {(rFinancePermission && <Grid item className='p-0-10 category-card-wrapper'>
          <CategoryCard
            className='budgetPink'
            icon={<span className='bk-icon'><img src={BudgetImg} alt='budget' /></span>}
            name='Budget'
            count={progressClaimCount || 0}
            totalHeading='Total Budget'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/budget/${projectId}`)}
          />
        </Grid>) || ''}
        {(financePermission && App_Version > 2 && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='rfqBlue'
            icon={<span className='bk-icon bk-icon2'><img src={PurchaseOrderImg} alt='rfq' /></span>}
            name='RFQ'
            count={totalRFQSend || 0}
            totalHeading='Total RFQs'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/rfq/${projectId}`)}
          />
        </Grid>) || ''}

        {rSupplierPermission && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='poBlue'
            icon={<span className='bk-icon bk-icon3'><img src={PurchaseOrderImg} alt='po' /></span>}
            name='Purchase Orders'
            count={purchaseOrdersCount || 0}
            totalHeading='Total POs'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}`)}
            createLinkText={'Create Purchase Order'}
            createLink={props.wSupplierPermission ? () => navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}/create-purchase-order`) : undefined}
          />
        </Grid>}
        {rContractorPermission && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='poBlue'
            icon={<span className='bk-icon bk-icon8'><img src={WorkOrderImg} alt='wo' /></span>}
            name='Work Orders'
            count={workOrdersCount || 0}
            totalHeading='Total WOs'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/work-order/${projectId}`)}
            createLinkText={'Create Work Order'}
            createLink={props.wContractorPermission ? () => navigate(`/${PROJECT_MANAGEMENT}/work-order/${projectId}/create-work-order`) : undefined}
          />
        </Grid>}
        {(rFinancePermission && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='rfqPink'
            icon={<span className='bk-icon bk-icon4'><img src={InvoiceImg} alt='invoice' /></span>}
            name='Invoices'
            count={invoicesCount || 0}
            totalHeading='Total Invoices'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/invoices/${projectId}`)}
          />
        </Grid>) || ''}
        {(rFinancePermission && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='vendorPaymnts'
            icon={<span className='bk-icon bk-icon5'><img src={VendorInvoiceImg} alt='vendor-invoice' /></span>}
            name='Vendor Invoices'
            count={vendorsPaymentsCount || 0}
            totalHeading='Total Vendor Invoices'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${VENDOR_PAYMENT}?project=${projectId}`)}
          />
        </Grid>) || ''}
        <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='vendorPaymnts'
            icon={<span className='bk-icon bk-icon6'><img src={InternalStockImg} alt='internal-stock' /></span>}
            name='Internal Stock'
            count={false}
            totalHeading='Add Internal Stock'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/receive-de-stock/${projectId}`)}
          />
        </Grid>
        <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='vendorPaymnts'
            icon={<span className='bk-icon bk-icon7'><img src={ReservedStockImg} alt='reserved-stock' /></span>}
            name='Reserved Stock'
            count={false}
            totalHeading='Add Reserved Stock'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/receive-reserved-stock/${projectId}`)}
          />
        </Grid>
        {(financePermission && (quoteType == 'quote') && <Grid item className='p-0-10 category-card-wrapper' >
          <CategoryCard
            className='variationGreen'
            icon={<span className='bk-icon bk-icon2'><img src={VariationImg} alt='variation' /></span>}
            name='Variation'
            count={totalVariation || 0}
            totalHeading='Total Variations'
            arrow
            ProjectCard={true}
            onClick={() => navigate(`/${PROJECT_MANAGEMENT}/variation/${projectId}`)}
            createLinkText={'Create Variation'}
            createLink={() => navigate(`/${PROJECT_MANAGEMENT}/variation/add/${projectId}`)}
          />
        </Grid>) || ''}
      </Grid>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getvendorsPOInvoiceCount: bindActionCreators(getvendorsPOInvoiceCount, dispatch)
})

export default withRouter(connect(null, mapDispatchToProps)(OverviewDetails))

