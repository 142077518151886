import { showPercent } from "src/util/currency-formatter"
import * as paths from "src/constants/route-paths";
import { history } from "src/util/history";
import Cookies from 'js-cookie'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { setCookie } from "../../../util/auth";

const navigateToProject = (data) => {
    setCookie({ name: 'projectType', value: data.project_type })
    if (data.is_quote) {
        history.navigate(`/projects/edit/${data.id}?project-step=TENDERVIEW`);
    } else {
        history.navigate(`/projects/edit/${data.id}?project-step=VIEW`);
    }
}

const navigateToPO = (data) => {
    if (!data.is_quote) {
        setCookie({ name: 'projectType', value: data.project_type })
        redirectTo(`/projects/purchase-order/${data.id}`)
    }
}

const navigateToWO = (data) => {
    if (!data.is_quote) {
        setCookie({ name: 'projectType', value: data.project_type })
        redirectTo(`/projects/work-order/${data.id}`);
    }
}

const navigateToCreateInvoice = (data) => {
    setCookie({ name: 'projectType', value: data.project_type })
    history.navigate(`/projects/invoice/${data.id}/create`);
}

const navigateToInvoiceList = (data) => {
    setCookie({ name: 'projectType', value: data.project_type })
    history.navigate(`/projects/invoices/${data.id}`);
}

const redirectTo = (path) => {
    history.navigate(path)
}

export const projectCompletion = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Completion', render: (data) => showPercent(data.completion) },
    { class: 'text-right', title: 'Expected Completion', render: (data) => showPercent(data.expected_completion) },
    {
        class: 'text-center', title: 'Action',
        render: (data) => (
            <div onClick={() => redirectTo(`/${paths.TIMESHEET}/${paths.SCHEDULE}?project_id=${data.id}`)} class="action-edit text-center">
                <AccessTimeIcon />
            </div>
        )
    },
];

export const projectInvoiceStatus = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    {
        class: 'text-right', title: 'Create Invoice',
        render: (data) => (<>
            {data.progress_claim_id ?
                <div class="action-edit text-success"><DoneOutlinedIcon /></div>
                :
                !data.is_quote && <a onClick={() => navigateToCreateInvoice(data)} className="table-text-hidden">
                    Create
                </a>
            }
        </>)
    },
    {
        class: 'text-right', title: 'Finalise Invoice',
        render: (data) => (<>
            {data.progress_claim_id && data.progress_claim_status != 'Finalised' ?
                <a onClick={() => navigateToInvoiceList(data)} className="table-text-hidden">
                    Finalise
                </a>
                :
                !data.is_quote && <div class="action-edit text-success"><DoneOutlinedIcon /></div>
            }
        </>)
    },
];

export const projectBudgetStatus = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    {
        class: 'text-right', title: 'Status',
        render: (data) => <span className={`tbl-success-badge ${data.budget_difference > 0 ? 'tbl-danger-badge' : ''}`}>{data.budget_status}</span>
    },
];

export const timesheetColumn = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Missing Timesheets', field: 'missing' },
    { class: 'text-right', title: 'Timesheets Added', field: 'overall' },
    { class: 'text-right', title: 'Timesheets Unapproved', field: 'pending' },
];

export const projectPurchaseOrder = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: `Missing PO's`, field: 'missing' },
    { class: 'text-right', title: 'Not Invoiced', field: 'not_invoiced' },
    { class: 'text-right', title: 'Not Received', field: 'not_received' },
    {
        class: 'text-center', title: 'Action', rowClass: 'p-0',
        render: (data) => (
            <>
                {!data.is_quote && <div onClick={() => navigateToPO(data)} class="action-edit text-center">
                    <DescriptionOutlinedIcon />
                </div>}
            </>
        )
    },
];

export const projectWorkOrder = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: `Missing WO's`, field: 'missing' },
    { class: 'text-right', title: 'Not Invoiced', field: 'not_invoiced' },
    {
        class: 'text-center', title: 'Action', rowClass: 'p-0',
        render: (data) => (
            <>
                {!data.is_quote && <div onClick={() => navigateToWO(data)} class="action-edit text-center">
                    <DescriptionOutlinedIcon />
                </div>}
            </>
        )
    },
];

export const projectList = [
    {
        class: 'project-name-width', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    {
        class: 'text-right', title: `Status`, field: 'missing',
        render: (data) => <span className="tbl-success-badge">{data.status}</span>,
    },
    { class: 'text-right', title: 'Days Since Creation', field: 'since_days' },
    {
        class: 'text-center', title: 'Action', rowClass: 'p-0',
        render: (data) => (
            <div onClick={() => navigateToProject(data)
                // redirectTo(`/projects/budget/${data.id}`)
            } class="action-edit text-center">
                <DescriptionOutlinedIcon />
            </div>
        )
    },
];  