import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveUnits, getUnits } from 'src/actions/inventory.action'
import _ from 'lodash'
import Modal from 'react-bootstrap/Modal';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from 'react-bootstrap/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, Chip } from "@mui/material";

function AddUnit(props) {
  const { dialogClose, open, allUnits, unitList } = props
  const [selectedUnit, setSelectedUnit] = useState([])

  useEffect(() => {
    const selUnit = _.map(allUnits, 'value')
    setSelectedUnit(selUnit)
  }, [allUnits])

  const handleChange = (e) => {
    setSelectedUnit(e.target.value)
  }

  const saveUnits = () => {
    const data = []
    selectedUnit.forEach(_unit => {
      unitList.forEach(unit => {
        if (unit.value === _unit) {
          data.push({ name: unit.key, value: unit.value })
        }
      })
    })
    props.saveUnits({ units: data }).then((data) => {
      dialogClose()
    })
  }

  return (
    <Modal
      show={open}
      onHide={dialogClose}
      dialogClassName="modal-70w create-modal"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Add Unit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="purchase-order-block">
          <div className="row my-4 px-4">
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label">Select Units</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedUnit}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => selected.map(val => <Chip key={val} label={val} style={{ margin: '2px 2px' }} />)}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    zIndex: 35001
                  }
                }}
              >
                {unitList.map((unit) => (
                  <MenuItem key={unit} value={unit.value}>
                    <Checkbox checked={selectedUnit.indexOf(unit.value) > -1} />
                    <ListItemText primary={unit.key} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-12 d-flex mt-3">
          <Button onClick={saveUnits} className="ms-auto me-3 secondarybtn">SAVE</Button>
          <Button onClick={dialogClose} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
const mapStateToProps = state => ({

})
const mapDispatchToProps = dispatch => ({
  getUnits: bindActionCreators(getUnits, dispatch),
  saveUnits: bindActionCreators(saveUnits, dispatch)

})
export default connect(mapStateToProps, mapDispatchToProps)(AddUnit)
