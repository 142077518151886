import {
  NAVBAR_SET_NOTIFICATION,
  NAVBAR_ADD_NOTIFICATION,
  NAVBAR_REMOVE_NOTIFICATION,
  NAVBAR_SET_NOTIFICATION_READ
} from '../constants/action.constant'

const initialState = { list: null }

const setNotification = (state, data) => {
  const _state = { ...state, list: data }
  _state.list = data
  return _state
}

const addNotification = (state, data) => {
  const list = state.list ? [...state.list, data] : [data]
  const _state = { ...state, list }
  return _state
}

const removeNotification = (state, notificationId) => {
  const list = state.list.filter(notification => notification._id !== notificationId)
  return { ...state, list }
}

const setNotificationRead = (state) => {
  const list = state.list.map(notification => {
    notification.read = true
    return notification
  })
  const _state = { ...state, list }
  return _state
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVBAR_SET_NOTIFICATION: return setNotification(state, action.data)
    case NAVBAR_ADD_NOTIFICATION: return addNotification(state, action.data)
    case NAVBAR_REMOVE_NOTIFICATION: return removeNotification(state, action.notificationId)
    case NAVBAR_SET_NOTIFICATION_READ: return setNotificationRead(state)
    default: return state
  }
}

export default notificationReducer
