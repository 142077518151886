import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from './../../components/modal/modal';
import { saveUserRole, getRolesList, deleteRole, editUserRole, updateUserRoles, getUserList } from './../../actions/user-management.action'
import { getLaborList } from '../../actions/inventory.action'
import { errorSnackBar } from './../../actions/common.action'
import _ from 'lodash'
import CustomTable from 'src/components/table/table';
import SelectInput from 'src/components/inputs/select';
import Form from 'react-bootstrap/Form'
import CustomInput from 'src/components/inputs/new-input';
import { loadPermissions, minifyPermissions } from './data/permissions';

let roleTypeOptions = [{
  text: 'New Role',
  value: 'NEWROLE',
}, {
  text: 'Role from Resource Type',
  value: 'SKILLSET',
}]

function UserRole({ projectCategories, ...props }) {

  const [fields, setFields] = useState([])
  const [state, setState] = useState({
    userRole: {
      value: '',
      error: true,
      touched: false,
    },
    isFormValid: false,
    list: false,
    id: '',
    userRoleList: [],
    openDeleteHandler: false,
    deleteId: '',
    roleType: 'NEWROLE',
    skillSetId: '',
    skillSetName: '',
    skillSetItems: [],
    typeDisabled: false,
    role: {
      name: 'role',
      label: 'Roles',
      type: 'select',
      touched: false,
      error: false,
      valid: true,
      value: null,
      items: []
    }
  })

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    let _fields = _.cloneDeep(loadPermissions(projectCategories));
    setFields(_fields);
    fetchRoleList();
  }

  const fetchRoleList = async () => {
    props.getRolesList().then((data) => {
      let Roles = []
      let all = JSON.parse(JSON.stringify(data))
      all.forEach((Role, i) => {
        Role.sno = i + 1
        Roles.push(Role)
      })
      setState(prev => ({
        ...prev,
        userRoleList: Roles
      }));
    })
    let laborList = await props.getLaborList()
    let skillSetItems = laborList.map(labor => ({
      key: labor.skill,
      value: labor.id,
    }))
    setState(prev => ({
      ...prev,
      skillSetItems
    }));
    if ((skillSetItems.length === 0) && (roleTypeOptions.length === 2))
      roleTypeOptions = roleTypeOptions.pop()
  }


  const dialogClose = () => {
    const { list, typeDisabled, id } = state
    if ((id) || (!list && typeDisabled)) {
      setState(prev => ({
        ...prev,
        list: true, typeDisabled: false, id: ''
      }));
    } else if (list && typeDisabled) {
      setState(prev => ({
        ...prev,
        list: true, typeDisabled: false
      }));
    } else {
      if (list && !typeDisabled) {
        setState(prev => ({
          ...prev,
          list: false
        }));
      } else if (typeDisabled && !list) {
        setState(prev => ({
          ...prev,
          list: true
        }));
      } else {
        props.handleClose();
      }
    }

  }

  const deleteDialogHandler = (id = '') => {
    const { openDeleteHandler, userRoleList, role } = state
    role.items = userRoleList
      .filter((item) => item.id !== id)
      .map(v => ({ key: v.name, value: v.id })) || [];
    setState(prev => ({
      ...prev,
      openDeleteHandler: !openDeleteHandler, deleteId: id, role
    }));
  }

  const deleteHandler = async (id) => {
    let { role } = state
    if (!role.value) {
      role.touched = true
      role.valid = false
      role.error = 'This Field is Required'
      setState(prev => ({
        ...prev,
        role
      }));
    } else {
      await props.updateUserRoles({ roleId: role.value }, id)
      props.deleteRole(id).then(res => {
        setState(prev => ({
          ...prev,
          openDeleteHandler: false
        }));
        fetchRoleList()
        props.getUserList()
      })
    }
  }

  const inputChangeHandler = (e) => {
    const userRole = state.userRole
    let isFormValid
    if (e.target.value.trim() === '') {
      userRole.error = 'This field is Required'
      isFormValid = false
    } else {
      userRole.error = false
      isFormValid = true
    }
    userRole.value = e.target.value
    userRole.touched = true
    setState(prev => ({
      ...prev,
      userRole, isFormValid
    }));
  }

  const editRole = (rowData) => {
    let roleType = 'NEWROLE', skillSetName = ''
    if (rowData.skillSetId) {
      roleType = 'SKILLSET'
      skillSetName = rowData.name
    }
    const userRole = state.userRole
    userRole.value = rowData.name
    setState(prev => ({
      ...prev,
      userRole, id: rowData.id,
      list: false, typeDisabled: true,
      skillSetId: rowData.skillSetId, roleType,
      skillSetName
    }));
    let _fields = _.cloneDeep(loadPermissions(projectCategories, rowData.modules));
    setFields(_fields)
  }

  const reset = () => {
    let _fields = _.cloneDeep(loadPermissions(projectCategories));
    setFields(_fields);
    setState(prev => ({
      ...prev,
      id: '',
      userRole: {
        value: '',
        error: true,
        touched: false,
      },
      roleType: 'NEWROLE',
      skillSetId: '',
      skillSetName: '',
      typeDisabled: false
    }));
  }

  const submitHandler = () => {
    let valid = true, name = '', skillSetIdis = null
    let { userRole, roleType, skillSetId, skillSetName } = state

    if (roleType === 'NEWROLE') {
      if (userRole.value === '') {
        userRole.touched = true
        userRole.error = 'This field is Required'
        setState(prev => ({
          ...prev,
          userRole
        }));
        valid = false
      }
      name = state.userRole.value
    } else if (roleType === 'SKILLSET') {
      if (!skillSetId) {
        props.errorSnackBar('Select a Resource Type!')
        valid = false
      }
      skillSetIdis = skillSetId
      name = skillSetName
    }
    if (valid) {
      const data = {
        name: name,
        modules: minifyPermissions(fields),
        status: 'ACTIVE',
        ...(skillSetIdis && { skillSetId: skillSetIdis })
      }
      if (state.id === '') {
        props.saveUserRole(data).then((res) => {
          fetchRoleList()
          dialogClose()
          reset()
        }).catch(e => console.error(e))
      }
      else {
        props.editUserRole(data, state.id).then((res) => {
          fetchRoleList()
          dialogClose()
          reset()
        }).catch(e => console.error(e))
      }
    }
  }

  const inputRoleChangeHandler = (e) => {
    let role = state.role
    let value
    if (_.isObject(e)) {
      value = e.target.value
    }
    role.value = value
    role.touched = true
    setState(prev => ({
      ...prev,
      role
    }));
  }

  const changePermission = (e, idx, type, aIdx = 0) => {
    let checked = e.target.checked;
    setFields(
      fields.map((item, pIdx) => {
        if (pIdx == idx) {
          if (type != 'additional') {
            item[type].value = checked;
            if (type == 'write' && checked) item['read'] = { ...(item['read'] || {}), value: true };
            if (type == 'read' && !checked) item['write'] = { ...(item['write'] || {}), value: false };
          } else {
            item[type][aIdx].value = checked;
          }
        }
        return item;
      })
    );
  }

  const writePermission = props.permissions.user === 2 ? true : false

  return (
    <Modal
      open={props.open}
      onClose={dialogClose}
      modalHeading={state.list ? 'User Role List' : state.id === '' ? 'Create a New User Role' : 'Update User Role'}
      modalSaveTxt={!state.list && 'Save'}
      modalCloseTxt='Cancel'
      fullWidth={true}
      maxWidth={state.list ? 'md' : 'md'}
      onClick={submitHandler}
      cxlbtnClass='cancelBtn'
      showActionBtn
      additionalbtnTxt={!state.list && !state.typeDisabled && 'List'}
      additionalbtn={() => {
        setState(prev => ({
          ...prev,
          list: true
        }));
      }}
      primary>
      <Modal
        open={state.openDeleteHandler}
        onClose={deleteDialogHandler}
        onClick={() => deleteHandler(state.deleteId)}
        modalHeading={`Delete User Role`}
        modalSaveTxt={'Save'}
        modalCloseTxt='Cancel'
        showActionBtn={true}
        fullWidth='true'
        maxWidth={'sm'}
        cxlbtnClass='cancelBtn'
        primary
      >
        <p>Are You Sure You Want to Delete this Role?</p>
        <p>To do so please select the new role you would like to move users to. Then select Save. Otherwise select Cancel to exit.</p>
        <div className='mb-4'>
          <SelectInput
            {...state.role}
            label={'Select Role'}
            onChange={(e) => inputRoleChangeHandler(e)}
          />
        </div>
      </Modal>
      {!state.list &&
        <div className="purchase-order-block">

          <div className="row">
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="form-group text-left">
                <label className="label-default">User Role Type</label>
                <div className="input-block mt-0 d-flex">
                  <Form>
                    <div className="d-flex">
                      <Form.Check
                        type={'radio'}
                        id={`new-role`}
                        label='New Role'
                        className="me-3"
                        name="role-type"
                        value={'NEWROLE'}
                        disabled={state.typeDisabled}
                        checked={state.roleType == 'NEWROLE' ? true : false}
                        onChange={e => setState(prev => ({ ...prev, roleType: e.target.value }))}
                      />

                      <Form.Check
                        type={'radio'}
                        label='Role from Resource Type'
                        id={`skillset`}
                        name="role-type"
                        value={'SKILLSET'}
                        disabled={state.typeDisabled}
                        checked={state.roleType == 'SKILLSET' ? true : false}
                        onChange={e => setState(prev => ({ ...prev, roleType: e.target.value }))}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {state.roleType === 'NEWROLE' && (<div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                label='User Role Name'
                value={state.userRole.value}
                valid={!state.userRole.error}
                touched={state.userRole.touched}
                error={state.userRole.error}
                onChange={(e) => inputChangeHandler(e)}
                name='userRole'
              />
            </div>)}
            {(state.roleType === 'SKILLSET') && (state.skillSetItems.length !== 0) && (<div className="col-lg-4 col-md-6 mt-3">
              <SelectInput
                label={'Resource Type'}
                value={state.skillSetId}
                items={state.skillSetItems}
                onChange={e => {
                  let value = e.target.value;
                  let skill = state.skillSetItems.find(item => item.value == value)
                  setState(prev => ({ ...prev, skillSetId: value, skillSetName: skill.key }))
                }}
              // onChange={e => setState({ skillSetId: e.target.value, skillSetName: e.nativeEvent.target.textContent })}
              />
            </div>)}
          </div>

          <div className="material-list-block mt-3">
            <div className="table-responsive">
              <table className="table-create table-material table table-bordered w-100-lg">
                <thead>
                  <tr>
                    <th>Access & Permissions</th>
                    <th>Read only</th>
                    <th>Edit</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item, idx) => (
                    <tr key={item.name}>
                      <td>{item.display}</td>
                      <td>
                        {!item.read.hidden && <div className="form-check form-switch">
                          <input className="form-check-input switch-checkbox"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault22"
                            onChange={(e) => changePermission(e, idx, 'read')}
                            name={item.name}
                            disabled={item.read.disabled}
                            checked={item.read.value}
                          />
                        </div>}
                      </td>
                      <td>
                        {!item.write.hidden && <div className="form-check form-switch">
                          <input className="form-check-input switch-checkbox"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault22"
                            onChange={(e) => changePermission(e, idx, 'write')}
                            name={item.name}
                            disabled={item.write.disabled}
                            checked={item.write.value}
                          />
                        </div>}
                      </td>
                      <td>
                        {(item.additional || []).map((additional, aIdx) => (
                          <Form.Check
                            key={`${idx}-${aIdx}`}
                            id={additional.name}
                            className='align-content-center'
                            type={'checkbox'}
                            onChange={(e) => changePermission(e, idx, 'additional', aIdx)}
                            name={additional.name}
                            disabled={additional.disabled}
                            checked={additional.value}
                            label={additional.display}
                          />
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      {state.list && <CustomTable
        columns={[
          { title: 'S No', field: 'sno' },
          { title: 'User Role', field: 'name' }
        ]}
        data={state.userRoleList}
        isEdit={writePermission}
        editHandler={(data) => editRole(data)}
        isDelete={writePermission}
        deleteHandler={(data) => deleteDialogHandler(data.id)}
        addButton={writePermission}
        addHandler={() => setState(prev => ({ ...prev, list: false }))}
        fileName='User Role List'
      />}
    </Modal>
  )

}

const mapStateToProps = state => ({
  permissions: state.user.userData.permissions,
  projectCategories: state.project.projectCategories,
});

const mapDispatchToProps = dispatch => ({
  saveUserRole: bindActionCreators(saveUserRole, dispatch),
  getRolesList: bindActionCreators(getRolesList, dispatch),
  deleteRole: bindActionCreators(deleteRole, dispatch),
  editUserRole: bindActionCreators(editUserRole, dispatch),
  getLaborList: bindActionCreators(getLaborList, dispatch),
  getUserList: bindActionCreators(getUserList, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  updateUserRoles: bindActionCreators(updateUserRoles, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);
