export const ProposalType = {
    SUMMARY: 'SUMMARY_PROPOSAL',
    DETAILED: 'DETAILED_PROPOSAL'
}

export const TemplateType = {
    PURCHASE_ORDER: 'ProjectPO',
    INVOICE: 'INVOICE',
    PROGRESSCLAIM: 'PROGRESSCLAIM',
}
