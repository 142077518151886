import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _ from 'lodash'
import { getTimesheet, updateTimesheetAttendance } from '../../actions/project-management.action'
import Modal from '../../components/modal/modal'
import Input from '../../components/inputs/input-simple'
import Grid from '@material-ui/core/Grid'
import './scss/user-management.scss'

class ApplyLeave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            allocationId: '',
            attendanceData: {},
            leaveDate: {
                name: 'leaveDate',
                label: 'From Date',
                type: 'datepicker',
                touched: false,
                error: false,
                valid: true,
                value: null,
                format: 'EEEE dd/MM/yyyy',
                required: true,
                minDate: null,
                maxDate: null,
            },
            leaves: []
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.userData, this.props.userData)) {
            this.setState({ userData: this.props.userData })
        }
        if (!_.isEqual(prevProps.open, this.props.open) && this.props.open) {
            this.getAllocationData(this.props.userData)
        }
    }

    getAllocationData = async (userData) => {
        const { leaveDate } = this.state
        const allocationId = userData && userData.allocationId
        const timesheetArray = await this.props.getTimesheet('', allocationId)
        const timesheetData = timesheetArray[0]

        const leaves = timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.filter(x => x.onLeave === true).map(x => {
                return (moment(x.reportingDate).format('YYYY-MM-DD'))
            })

        this.setState({
            timesheetData,
            leaves,
            leaveDate: { ...leaveDate, minDate: timesheetData.fromDate, maxDate: timesheetData.toDate }
        })
    }

    inputChangeHandler = (e) => {
        const { leaves } = this.state
        const index = leaves.findIndex(x => moment(e).format('YYYY-MM-DD') === x)

        if (index > -1 && index <= leaves.length) {
            leaves.splice(index, 1)
        } else {
            leaves.push(moment(e).format('YYYY-MM-DD'))
        }

        this.setState({ leaves })
    }

    submitForm = async () => {
        const { leaves, timesheetData } = this.state

        const data = { leaves }

        await this.props.updateTimesheetAttendance(timesheetData.id, data)
        this.handleClose()
        this.props.getAllocations()
    }

    handleClose = () => {
        this.props.handleClose()
    }

    renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
        const { timesheetData, leaves } = this.state
        const attendanceData = timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.find(x => moment(x.reportingDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))

        let classes = 'apply-leave-timesheet'
        if (attendanceData && attendanceData.inTime && !attendanceData.onLeave) {
            classes = 'timesheet-filled-present'
        } else if (leaves.includes(moment(date).format('YYYY-MM-DD'))) {
            classes = 'timesheet-filled-leave'
        }

        return (<div className={classes}>
            {dayComponent}
        </div>)
    }

    shouldDisableDate = (date) => {
        const { timesheetData } = this.state
        const attendanceData = timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.find(x => moment(x.reportingDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))

        if (attendanceData) {
            if (attendanceData.inTime) {
                return true
            }
        }
        return false
    }

    render() {
        const { open } = this.props
        const { leaveDate } = this.state

        return (
            <Modal
                open={open}
                onClose={this.handleClose}
                onClick={this.submitForm}
                modalHeading={'Apply Leaves'}
                modalSaveTxt={'Save'}
                modalCloseTxt='Cancel'
                showActionBtn={true}
                fullWidth={true}
                maxWidth={'xs'}
                cxlbtnClass='cancelBtn'
                savebtnClass='createBtn'
                primary
            >
                <Grid container xs={12} className="userleave">
                    <Grid item xs={12}>
                        <p style={{margin:"0px"}}>Select Dates to Apply for leave.</p>
                    </Grid>
                    <Grid item xs={10} >
                        <Input
                            {...leaveDate}
                            defaultValue={null}
                            variant={'static'}
                            renderDay={this.renderDayInPicker}
                            shouldDisableDate={this.shouldDisableDate}
                            onChange={this.inputChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}

ApplyLeave.defaultProps = {
    open: false,
}

ApplyLeave.propTypes = {
    open: PropTypes.bool,
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    updateTimesheetAttendance: bindActionCreators(updateTimesheetAttendance, dispatch),
    getTimesheet: bindActionCreators(getTimesheet, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyLeave);
