import { getProjectList } from "src/actions/project-management.action";
import { updateProjectAction } from "src/slice/project-detail";
import { format } from "date-fns";

export const getRevisionData = async ({ projectId, dispatch }) => {

    if (!projectId) return;

    dispatch(getProjectList(projectId)).then((projects) => {
        const revision = [];
        projects.forEach((project) => {
            if (project.type === "TENDER" && project.status !== "LOST") {
                revision.push({
                    id: project.id,
                    tenderNumber: project.tenderNumber,
                    projectName: project.name,
                    customer: project.customer && project.customer.business_name,
                    address: project.address,
                    status: project.status,
                    rootProjectId: project.rootProjectId,
                    createdAt: format(new Date(project.dateOfCreation), "dd/MM/yyyy"),
                });
            }
        });

        dispatch(updateProjectAction({ revision }))
    });

};
