import {
    getGlobalSetting as getGlobalSettingAPI,
    editGlobalSetting as editGlobalSettingAPI,
    deleteGlobalSetting as deleteGlobalSettingAPI,
    editProjectTypeSetting as editProjectTypeSettingAPI,
    UpdateProjectType as UpdateProjectTypeAPI,
} from '../api/global-setting.api'
import { SAVE_PROJECT_CATEGORIES, FINANCE_TAX } from '../constants/action.constant'
import idx from 'idx'

export const getGlobalSetting = (settingId) => {
    return (dispatch, getState) => {
        return getGlobalSettingAPI(settingId)
    }
}

export const editGlobalSetting = (settingData, settingId) => {
    return (dispatch, getState) => {
        return editGlobalSettingAPI(settingData, settingId)
    }
}

export const deleteGlobalSetting = (settingData, settingId) => {
    return (dispatch, getState) => {
        return deleteGlobalSettingAPI(settingData, settingId)
    }
}

export const editProjectTypeSetting = (settingData, settingId) => {
    return (dispatch, getState) => {
        return editProjectTypeSettingAPI(settingData, settingId)
    }
}

export const saveProjectCategory = data => ({ type: SAVE_PROJECT_CATEGORIES, data })

export const getProjectCategory = (data) => {
    return (dispatch, getState) => {
        return getGlobalSettingAPI(data).then((response) => {
            dispatch(saveProjectCategory({ user: getState().user, projectCategory: idx(response, _response => _response) }))
        }).catch((e) => {
            console.error(e)
        })
    }
}

export const saveFinanceTax = data => ({ type: FINANCE_TAX, data })

export const getGlobalSettingReducer = (type) => {
    return (dispatch, getState) => {
        return getGlobalSettingAPI(type).then((response) => {
            if (type === 'FINANCE_TAX')
                dispatch(saveFinanceTax(idx(response, _response => _response)))
        })
    }
}

export const UpdateProjectType = (settingData, settingId) => {
    return (dispatch, getState) => {
        return UpdateProjectTypeAPI(settingData, settingId)
    }
}
