import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import List from "./list";
import * as paths from "../../constants/route-paths";
import ViewPO from "./view-po";
import "./scss/style.scss";
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {
  let element = useRoutes([
    {
      path: "",
      element: <RBACApplicationAuth moduleURL={location.pathname} component={List} />,
    },
    {
      path: paths.VIEW_PO,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={ViewPO} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
