import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteStage,
    deleteSubTask,
    deleteTask,
    convertTask,
} from "../../../actions/project-management.action";
import {
    Paper,
    Grid,
} from "@material-ui/core";
import Modal from "../../../components/modal/modal";
import "../scss/project-management.scss";
import TaskList from "../task-list";
import ProposalDialog from "../proposal-dialog";
import CreateRFQDialog from "../create-rfq";
import Cookies from "js-cookie";
import IconButton from "@material-ui/core/IconButton";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import ViewRFQ from "../view-rfq";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import WarningIcon from "@material-ui/icons/Warning";
import commonReducer from "../../../reducers/common.reducer";
import ProjectSummary from "../project-summary";
import Button from 'react-bootstrap/Button';
import ProjectMenu from "../project-menu";
import idx from "idx";
import { getProjectData } from "../utils/get-project";
import { getQuotationListData } from "../utils/get-quotations";
import { updateProjectAction } from "src/slice/project-detail";
import { getRevisionData } from "../utils/get-revisions";
import { getVariationsData } from "../utils/get-variations";
import { getOptionsData } from "../utils/get-options";
import { Breadcrumb } from "react-bootstrap";
import { ProposalType } from "src/util/enums";
import { setCookie } from "../../../util/auth";

const App_Version = Number(commonReducer("", { type: "APP_VERSION" })) || 1;

export default function AddProjectStepTwo({ writePermission, ...props }) {
    const [taskListState, setTaskListState] = useState({
        anchorEl: null,
        anchorProjectEl: null,
        menuItems: {},
        stageId: "",
        taskId: "",
        isSubTask: true,
        optionId: "",
        isOption: false,
        editOption: false,
        proposalType: '',
        proposalDialogState: false,
        rfqDialogState: false,
        viewrfqDialogstate: false,
        showStageSelect: false,
        currentEditType: "",
        currentEditId: "",
        subTaskConfirmModal: false,
        convertOptiontoTask: false,
    })
    const { projectId } = useParams();
    let [searchParams] = useSearchParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const projectDetail = useSelector(state => state.projectDetail) || {}

    useEffect(() => {
        initLoad()
    }, [projectId])

    const initLoad = async () => {
        if (!projectId) {
            navigate(`/projects?project-type=${searchParams.get("project-type")}`);
        }

        // this.props.getCategories().then((categories) => {
        //     this.setState({ categories });
        // });

        // getQuotationListData({ projectId, dispatch })
        // getProjectData({ projectId, dispatch })

    }

    const handleMenuClose = () => {
        setTaskListState(prev => ({
            ...prev,
            anchorProjectEl: null,
            anchorEl: null,
            menuItems: {},
            currentEditType: "",
            currentEditId: {},
        }));
    };

    const handleMenuItemClick = async (type) => {
        const { currentEditType, currentEditId = {} } = taskListState;
        const stages = idx(projectDetail, _ => _.project.stages) || [];

        if (type === "add") {
            if (currentEditType === "STAGE") {
                taskDialogHandler(currentEditId.stageId);
            } else if (currentEditType === "TASK") {
                let hasSubTask = false;
                stages.map((stage) => {
                    stage.tasks.filter((task) => {
                        if (task.id === currentEditId.taskId && task.stageId === currentEditId.stageId)
                            hasSubTask = task.subTasks.length ? true : false;
                    });
                });
                if (hasSubTask) {
                    taskDialogHandler(currentEditId.stageId, currentEditId.taskId, true);
                } else {
                    setTaskListState(prev => ({
                        ...prev,
                        subTaskConfirmModal: !prev.subTaskConfirmModal,
                        proposalDialogState: false,
                    }));
                }
            }
        } else if (type === "copy") {
            await props.duplicateProjectTask(currentEditType, currentEditId.stageId, currentEditId.taskId, currentEditId.subTaskId);
            getProjectData({ projectId, dispatch })
        } else if (type === "delete") {
            if (currentEditType === "STAGE") {
                _deleteStage(currentEditId.stageId);
            } else if (currentEditType === "TASK") {
                _deleteTask(currentEditId.taskId);
            } else if (currentEditType === "SUBTASK") {
                _deleteSubTask(currentEditId.subTaskId);
            }
        } else if (type === "convert") {
            setTaskListState(prev => ({
                ...prev,
                convertOptiontoTask: !prev.subTaskConfirmModal,
                proposalDialogState: false,
            }));
        } else if (type === "revise") {
            createOptionRevision(currentEditId.optionId);
        }
    };

    const createOptionRevision = (id) => {
        optionDialogHandler(id, true, true, "revise");
    };

    const optionDialogHandler = (
        optionId = "",
        isOption = true,
        editOption = false,
        action = "new",
        stageId = "",
        taskId = "",
        isSubTask = false,
        subTaskId = "",
        editTask = false,
        editSubTask = false
    ) => {
        setTaskListState(prev => ({
            ...prev,
            stageId,
            taskId,
            isSubTask,
            subTaskId,
            editTask,
            editSubTask,
            action,
            optionId,
            isOption,
            editOption,
            taskDialogState: !prev.taskDialogState,
            proposalDialogState: false,
        }));
    };

    const _deleteStage = (id) => {
        dispatch(deleteStage(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };
    const _deleteTask = (id) => {
        dispatch(deleteTask(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };
    const _deleteSubTask = (id) => {
        dispatch(deleteSubTask(id)).then((res) => {
            getProjectData({ projectId, dispatch })
        });
    };

    const taskDialogHandler = (
        stageId = "",
        taskId = "",
        isSubTask = false,
        subTaskId = "",
        editTask = false,
        editSubTask = false,
        optionId = "",
        isOption = false,
        editOption = false,
        action = "new"
    ) => {
        setTaskListState(prev => ({
            ...prev,
            stageId,
            taskId,
            isSubTask,
            subTaskId,
            editTask,
            editSubTask,
            optionId,
            action,
            isOption,
            editOption,
            taskDialogState: !prev.taskDialogState,
            proposalDialogState: false,
        }));
    };
    const proposalDialogHandler = (proposalType = ProposalType.SUMMARY) => {
        setTaskListState(prev => ({
            ...prev,
            proposalType,
            proposalDialogState: !prev.proposalDialogState,
        }));
    };
    const rfqDialogHandler = (stageId = "") => {
        getQuotationListData({ projectId, dispatch })
        setTaskListState(prev => ({
            ...prev,
            rfqDialogState: !prev.rfqDialogState,
        }));
    };

    const ViewrfqDialogHandler = () => {
        setTaskListState(prev => ({
            ...prev,
            viewrfqDialogstate: !prev.viewrfqDialogstate,
        }));
    };

    const handleClose = () => {
        setTaskListState(prev => ({
            ...prev,
            anchorProjectEl: null,
        }));
        dispatch(updateProjectAction({
            hasStages: false,
        }))
    };

    const viewRevisions = (id = null) => {
        handleClose();
        getRevisionData({ projectId, dispatch })
    };

    const handleProjectMenu = () => {
        setTaskListState(prev => ({
            ...prev,
            anchorProjectEl: true,
        }));
    };

    const getStages = () => {
        getProjectData({ projectId, dispatch })
        handleMenuClose()
    };

    const stepOne = (projectId) => {
        setCookie({ name: "projectId", value: projectId });
        setTaskListState(prev => ({
            ...prev,
            currentStep: 1,
        }));
        window.location.reload();
    };

    const handlesubTaskClose = () => {
        setTaskListState(prev => ({
            ...prev,
            subTaskConfirmModal: false
        }));
        handleMenuClose();
    };

    const subTaskConfirmHandler = () => {
        setTaskListState(prev => ({
            ...prev,
            subTaskConfirmModal: false
        }));
        const { currentEditId = {} } = taskListState;
        taskDialogHandler(currentEditId.stageId, currentEditId.taskId, true);
    };

    const handleConvertOptiontoTaskClose = () => {
        setTaskListState(prev => ({
            ...prev,
            convertOptiontoTask: false
        }));
        handleMenuClose();
    };

    const convertOptiontoTask = () => {
        const { currentEditId = {} } = taskListState;

        const stages = idx(projectDetail, _ => _.project.stages) || [];
        const options = idx(projectDetail, _ => _.project.options) || [];

        const option = options.find((item) => item.id === currentEditId.optionId);
        if (option) {
            let optionData = {
                revisionId: option.revisionId,
                optionId: option.id,
                stageId: stages.length ? stages[0].id : "",
            };

            _convertTask(optionData);
            handleConvertOptiontoTaskClose();
        }
    };

    const _convertTask = (data) => {
        dispatch(convertTask(data)).then((res) => {
            getOptionsData({ projectId, dispatch })
            getProjectData({ projectId, dispatch })
        });
    };

    const viewProjectList = () => navigate(`/projects?project-type=${idx(projectDetail, _ => _.project.projectDataType)}&project-category=${idx(projectDetail, _ => _.project.projectType) || ''}`)

    return (
        <div className="viewProject-ch">
            <Menu
                id="simple-menu"
                anchorEl={taskListState.anchorEl}
                keepMounted
                open={Boolean(taskListState.anchorEl)}
                onClose={handleMenuClose}
            >
                {Object.entries(taskListState.menuItems).map(([key, value], index) => (
                    <MenuItem
                        key={index}
                        disabled={!writePermission}
                        onClick={() => handleMenuItemClick(key)}
                    >
                        {value}
                    </MenuItem>
                ))}
            </Menu>

            {taskListState.proposalDialogState && <ProposalDialog
                type={taskListState.proposalType}
                projectName={idx(projectDetail, _ => _.project.projectName)}
                customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                projectId={projectId}
                projectDescription={idx(projectDetail, _ => _.project.projectDescription) || ""}
                open={taskListState.proposalDialogState}
                dialogClose={() => proposalDialogHandler()}
                stages={JSON.parse(JSON.stringify(idx(projectDetail, _ => _.project.stages) || []))}
                hasStages={idx(projectDetail, _ => _.project.hasStages)}
                options={idx(projectDetail, _ => _.project.options) || []}
                isVariation={false}
            />}
            <CreateRFQDialog
                open={taskListState.rfqDialogState}
                projectName={idx(projectDetail, _ => _.project.projectName)}
                dialogClose={rfqDialogHandler}
                projectData={JSON.parse(JSON.stringify(idx(projectDetail, _ => _.project.stages) || []))}
                stages={idx(projectDetail, _ => _.project.stages) || []}
                hasStages={idx(projectDetail, _ => _.project.hasStages)}
                customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                projectDueDate={idx(projectDetail, _ => _.project.projectDueDate)}
                options={JSON.parse(JSON.stringify(idx(projectDetail, _ => _.project.options) || []))}
                attachmentArr={idx(projectDetail, _ => _.project.attachmentArr) || []}
                totalAmount={idx(projectDetail, _ => _.project.calculations.total) || 0}
                userAccessControl={props.userAccessControl}
            />
            {taskListState.viewrfqDialogstate && (
                <ViewRFQ
                    open={taskListState.viewrfqDialogstate}
                    dialogClose={ViewrfqDialogHandler}
                    isModal
                    userAccessControl={props.userAccessControl}
                />
            )}
            <div>
                {/* <p className="projectName">Project Name: <b>{idx(projectDetail, _ => _.project.projectName)}</b></p> */}
            </div>
            <div className="view-project-page">
                {/* ---------------------progress financial---------------------------- */}
                <div
                    className="mobileViewProject"
                    style={{ background: "transparent" }}
                >
                    <ProjectMenu
                        open={Boolean(taskListState.anchorProjectEl)}
                        anchorEl={taskListState.anchorProjectEl}
                        projectId={projectId}
                        type={idx(projectDetail, _ => _.project.projectType)}
                        projectType={idx(projectDetail, _ => _.project.projectDataType)}
                        writePermission={writePermission}
                        history={navigate}
                        onClose={handleClose}
                        hasStages={idx(projectDetail, _ => _.project.hasStages)}
                        viewRevisions={viewRevisions}
                        menuType={"View"}
                        projectStepTwo={true}
                        customstyle={"project-view-menuStyle"}
                    />

                    <div className="row">
                        <div className="w-auto">
                            <div className="col-12 breadcrumb-block p-0">
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="w-auto ms-auto mt-auto">
                            <Button
                                className="secondarybtn"
                                onClick={handleProjectMenu}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                            >
                                ...
                            </Button>
                        </div>
                    </div>

                    <Paper>
                        <ProjectSummary
                            projectId={projectId}
                            moduleType={"editproject"}
                            completedProgress={""}
                            invoiceProgress={""}
                            timeSpentProgress={""}
                            totalApprovedInvoices={""}
                            forecastProfit={""}
                            costToDate={""}
                            projectManagerName={idx(projectDetail, _ => _.project.projectManagerName)}
                            projectName={idx(projectDetail, _ => _.project.projectName)}
                            customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                            siteAddress={idx(projectDetail, _ => _.project.siteAddress) || ''}
                            contactData={[idx(projectDetail, _ => _.project.contactData.firstName), idx(projectDetail, _ => _.project.contactData.lastName)].filter(item => item).join(' ')}
                        />
                    </Paper>
                </div>
                {/* ----------------------task cards-------------------------- */}
            </div>

            <div className="table-responsive tender-table ">
                <TaskList
                    {...props}
                    getStages={() => getStages()}
                    callStepOne={() => stepOne(projectId)}
                    getOptions={() => getVariationsData()}
                    projectMarginValue={idx(projectDetail, _ => _.project.projectMarginValue)}
                    ischeckVariation={false}
                ></TaskList>
            </div>

            <div className="mt-4 pe-0">
                <div style={{ flex: "1", display: "flex" }}>
                    {App_Version > 2 && (
                        <p className="RFQsent">{idx(projectDetail, _ => _.project.totalRFQSend)} RFQ sent</p>
                    )}
                    {App_Version > 2 && (
                        <span
                            className="openDraft"
                            title="View RFQ"
                            onClick={ViewrfqDialogHandler}
                        >
                            <IconButton
                                aria-label="add"
                                disabled="true"
                                className="AddNewCustomerIcon"
                            >
                                <VisibilityRoundedIcon />
                            </IconButton>
                            View
                        </span>
                    )}
                </div>
                <div className="d-flex gap-2">
                    {writePermission && App_Version > 2 && (
                        <Button type="submit" className="secondarybtn" onClick={rfqDialogHandler}>
                            Create RFQ
                        </Button>
                    )}
                    <Button type="submit" className="secondarybtn" onClick={() => proposalDialogHandler(ProposalType.SUMMARY)}>
                        Summary Proposal
                    </Button>
                    <Button type="submit" className="secondarybtn" onClick={() => proposalDialogHandler(ProposalType.DETAILED)}>
                        Detailed Proposal
                    </Button>
                </div>
            </div>
            <Modal
                open={taskListState.subTaskConfirmModal}
                onClose={handlesubTaskClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                fullWidth={false}
                maxWidth="lg"
                modalHeading="Add Sub-Task"
                modalSaveTxt="Yes"
                modalCloseTxt="No"
                onClick={subTaskConfirmHandler}
                showActionBtn
                savebtnClass="createBtn"
                primary
            >
                <Grid container direction="row" alignItems="center">
                    <Grid container>
                        <Grid md={2} xs={2} item>
                            <IconButton aria-label="warning" style={{ width: "100%" }}>
                                <WarningIcon fontSize="large" style={{ color: "#FF6600" }} />
                            </IconButton>
                        </Grid>
                        <Grid md={10} xs={10} item>
                            <Grid container>
                                Warning: Adding Sub-Tasks to a Task will transition all Task
                                inclusions to the Sub-Tasks.
                            </Grid>
                            <Grid container>Do you wish to proceed with this change?</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                open={taskListState.convertOptiontoTask}
                onClose={handleConvertOptiontoTaskClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                fullWidth={false}
                maxWidth="lg"
                modalHeading="Convert Option to Task"
                modalSaveTxt="Yes"
                modalCloseTxt="No"
                onClick={convertOptiontoTask}
                showActionBtn
                savebtnClass="createBtn"
                primary
            >
                <Grid container direction="row" alignItems="center">
                    <Grid md={12} item>
                        Are you sure you would like to convert this Option to a Task and
                        include it in the Project?
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
}
