import { React, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../project-management/scss/task.scss'
import { useDispatch } from 'react-redux';
import { getOperationDetails } from 'src/actions/dashboard.action';
import OperationsTab from './operations';
import AdminFinanceTab from './admin-finance';
import InsightsStrategyTab from './insights-strategy';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

function Dashboard() {

  const filter = useSelector(state => state.dashboard.filter) || {}
  const is_fetched = useSelector(state => state.dashboard.is_fetched)

  const dispatch = useDispatch();

  useEffect(() => {
    if (!is_fetched) fetchDashboard(filter)
  }, [is_fetched])

  const fetchDashboard = ({ start, end }) => {
    try {
      dispatch(getOperationDetails({ start: format(new Date(start), 'yyyy-MM-dd'), end: format(new Date(end), 'yyyy-MM-dd') }))
    } catch (e) {
      console.error(e)
    }
  }

  const updateFilter = ({ start, end }) => {
    start = new Date(start)
    end = new Date(end)
    if (start.getTime() != new Date(filter.start).getTime() || end.getTime() != new Date(filter.end).getTime()) {
      fetchDashboard({ start, end })
    }
  }

  return (
    <div className="purchase-order-block position-relative">
      <div className="row">
        <div className="col-12">
          <Tabs defaultActiveKey="operations" className="mb-3" >
            <Tab eventKey="operations" title="Operations">
              <OperationsTab />
            </Tab>
            <Tab eventKey="admin" title="Admin/finance">
              <AdminFinanceTab
                filter={filter}
                filterChange={updateFilter}
              />
            </Tab>
            <Tab eventKey="insights" title="INSIGHTS/STRATEGY">
              <InsightsStrategyTab
                filter={filter}
                filterChange={updateFilter}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );

}

export default Dashboard
