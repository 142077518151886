import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_PROJECT_TEMPLATES = '/template/project'
const ADD_PROJECT_TEMPLATE = '/template/project'
const GET_TASK_TEMPLATES = '/template/task'
const ADD_TASK_TEMPLATE = '/template/task'
const GET_ITEM_TEMPLATES = '/template/sub-task'
const ADD_ITEM_TEMPLATE = '/template/sub-task'

// // ************API call Functions starts here **************

export const getProjectTemplatesAPI = async () => {
  return await axios('get', GET_PROJECT_TEMPLATES, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {} }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addProjectTemplateAPI = async (data) => {
  return await axios('post', ADD_PROJECT_TEMPLATE, data, { loader: loaders.OVERLAY, successMessage: 'Project Template Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTaskTemplatesAPI = async (id) => {
  return await axios('get', `${GET_TASK_TEMPLATES}${id ? `/${id}` : ''}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {} }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addTaskTemplateAPI = async (data) => {
  return await axios('post', ADD_TASK_TEMPLATE, data, { loader: loaders.OVERLAY, successMessage: 'Task Template Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editTaskTemplateAPI = async (id, data) => {
  return await axios('patch', `${ADD_TASK_TEMPLATE}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Template Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteTaskTemplateAPI = async (id) => {
  return await axios('delete', `${ADD_TASK_TEMPLATE}/${id}`, {}, { loader: loaders.OVERLAY, successMessage: 'Task Template Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getItemTemplatesAPI = async (id) => {
  return await axios('get', `${GET_ITEM_TEMPLATES}${id ? `/${id}` : ''}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {} }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addItemTemplateAPI = async (data) => {
  return await axios('post', ADD_ITEM_TEMPLATE, data, { loader: loaders.OVERLAY, successMessage: 'Item Template Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editItemTemplateAPI = async (id, data) => {
  return await axios('patch', `${ADD_ITEM_TEMPLATE}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Item Template Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteItemTemplateAPI = async (id) => {
  return await axios('delete', `${ADD_ITEM_TEMPLATE}/${id}`, {}, { loader: loaders.OVERLAY, successMessage: 'Item Template Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}