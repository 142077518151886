import React from 'react';
import {
  addTemplateFields
} from './../../constants/fields.constant'
import Modal from './../../components/modal/modal'
import CustomInput from 'src/components/inputs/new-input';
import TextAreaInput from 'src/components/inputs/textarea-input';

class AddTemplateModal extends React.Component {

  state = {
    templateFields: JSON.parse(JSON.stringify(addTemplateFields)),
  }


  inputChangeHandler = (e, fieldName = '') => {
    let templateFields = JSON.parse(JSON.stringify(this.state.templateFields))
    templateFields[e.target.name].value = e.target.value
    templateFields[e.target.name].touched = true
    const { error, valid } = this.handleValidation(e.target, templateFields[e.target.name].validation)
    templateFields[e.target.name].error = error
    templateFields[e.target.name].valid = valid
    this.setState({ templateFields })
  }

  handleValidation = ({ value }, validation) => {
    let error = false
    let valid = true
    if (validation.includes('required') && value.trim() === '') {
      return { error: 'This Field is Required', valid: false }
    }

    return { error, valid }
  }

  submitHandler = () => {
    let templateFields = JSON.parse(JSON.stringify(this.state.templateFields))
    let isFormValid = true
    for (let key in templateFields) {
      const { error, valid } = this.handleValidation(templateFields[key], templateFields[key].validation)
      templateFields[key].touched = true
      templateFields[key].valid = valid
      templateFields[key].error = error
      isFormValid = isFormValid && valid
    }
    if (!isFormValid) {
      this.setState({ templateFields })
    } else {
      this.props.saveTemplate({
        name: templateFields.name.value,
        description: templateFields.description.value,
      })
    }
  }

  closeDialog = () => {
    this.setState({ templateFields: JSON.parse(JSON.stringify(addTemplateFields)), })
    this.props.templateModalHandler(false)
  }

  render() {
    const { templateFields } = this.state;
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.closeDialog()}
        modalHeading={'Copy project content as template'}
        showActionBtn
        modalCloseTxt='Cancel'
        fullWidth={true}
        modalSaveTxt='Save as Template'
        onClick={() => this.submitHandler()}
        maxWidth={'sm'}
        primary
      >
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mt-3">
            <CustomInput
              {...templateFields.name}
              label={'Template Name'}
              onChange={(e) => this.inputChangeHandler(e)}
            />
          </div>
          <div className="col-lg-12 col-md-12 mt-3">
            <TextAreaInput
              {...templateFields.description}
              label={'Description'}
              onChange={(e) => this.inputChangeHandler(e)}
            />
          </div>
        </div>
      </Modal>

    )
  }
}

export default AddTemplateModal;