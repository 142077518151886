import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/account-management.scss";
import { Link } from "react-router-dom";
import { GLOBAL_ACCOUNT, ADD_ACCOUNT } from "./../../constants/route-paths";
import {
  getAccountsList,
  updateAccount,
  deleteAccount,
  loginToAccount,
} from "./../../actions/global-account.action";
import DeleteDialog from "./../../components/delete-dialog";
import { withRouter } from "../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "./../../components/table/table";
import LoginIcon from '@mui/icons-material/Login';

class AccountsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveAccount: [],
      InactiveAccount: [],
      deleteDialogState: false,
      data: [],
      type: "active",
      accountId: null,
    };
  }

  componentDidMount() {
    this.getAccountsList();
  }

  getAccountsList = () => {
    this.props.getAccountsList().then((accounts) => {
      const data = accounts.map((account) => {
        return {
          name: account.name,
          status: account.status,
          email: account.email,
          phone_number: account.phone_number,
          id: account.id,
        };
      });
      const ActiveAccount = data.filter((account) => {
        if (account.status === "ACTIVE") {
          return account;
        } else {
          return undefined;
        }
      });
      const InactiveAccount = data.filter((account) => {
        if (account.status === "INACTIVE") {
          return account;
        } else {
          return undefined;
        }
      });

      this.setState({
        accounts: data,
        data: ActiveAccount,
        ActiveAccount,
        InactiveAccount,
      });

      const { type } = this.state;
      this.filter(type);
    });
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        field: "name",
        render: (rowData) => (
          <Link to={`/${GLOBAL_ACCOUNT}/edit/${rowData.id}`}>
            {rowData.name}
          </Link>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: (rowData) =>
          rowData.status === "ACTIVE" ?
            (<span style={{ color: "#2BAA0E" }}>Active</span>)
            : (<span style={{ color: "#D0021B" }}>Inactive</span>),
      },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phone_number" },
      {
        title: "Active",
        field: "status",
        lookup: { true: "ACTIVE", false: "INACTIVE" },
        render: (rowData) => (
          <div className="form-check form-switch">
            <input
              className={`form-check-input switch-checkbox ${rowData.status === "ACTIVE" ? '' : 'inactive'}`}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              disabled={!this.props.writePermission}
              value={rowData.status === "ACTIVE" ? true : false}
              checked={rowData.status === "ACTIVE" ? true : false}
              onChange={(e) =>
                this.handleStatusChange(e.target.checked, rowData.id)
              }
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
          </div>
        ),
      },
    ];
  };

  addAccount = () => {
    this.props.history(`/${GLOBAL_ACCOUNT}/${ADD_ACCOUNT}`);
  };

  updateAccount = (id) => {
    this.props.history(`/${GLOBAL_ACCOUNT}/edit/${id}`);
  };

  deleteHandler = (id) => {
    this.props.deleteAccount(this.state.deleteId).then((res) => {
      this.deleteDialogHandler();
      this.getAccountsList();
    });
  };

  handleStatusChange = (status, id = "") => {
    status = status ? "ACTIVE" : "INACTIVE";
    this.props.updateAccount({ status }, id).then((res) => {
      this.getAccountsList();
    });
  };

  handleLoginToAccount = (id = "") => {
    const { accounts } = this.state;
    let account = accounts.filter((account) => account.id === id)[0];
    this.props.loginToAccount(id, account.name);
  };

  deleteDialogHandler = (id = "") => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteId: id,
    }));
  };

  filter = (status) => {
    if (status === "active") {
      this.setState((prevState) => ({
        data: prevState.ActiveAccount,
        type: status,
      }));
    } else if (status === "inactive") {
      this.setState((prevState) => ({
        data: prevState.InactiveAccount,
        type: status,
      }));
    }
  };

  render() {
    const {
      ActiveAccount,
      InactiveAccount,
      deleteDialogState,
      deleteId,
    } = this.state;

    return (
      <>
        <DeleteDialog
          open={deleteDialogState}
          id={deleteId}
          deleteHandler={this.deleteHandler}
          dialogClose={this.deleteDialogHandler}
          moduleName="Account"
        />
        <div className="purchase-order-block">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">Accounts</h2>
            </div>

            {this.props.writePermission && (<div className="w-auto ms-auto mt-auto">
              <button onClick={this.addAccount} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Add Account</button>
            </div>)}
          </div>

          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="active"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="active" title={`Active Accounts (${ActiveAccount.length})`}>
                  <CustomTable
                    columns={this.getColumns()}
                    data={ActiveAccount}
                    isMore={(item) => this.props.user.selected_account.id != item.id ? this.props.writePermission : false}
                    isMoreIcon={<LoginIcon />}
                    isMoreHandler={(item) => this.handleLoginToAccount(item.id)}
                    isEdit={this.props.writePermission}
                    editHandler={(item) => this.updateAccount(item.id)}
                    isDelete={(item) => this.props.user.selected_account.id != item.id ? this.props.writePermission : false}
                    deleteHandler={(item) => this.deleteDialogHandler(item.id)}
                    fileName="Active Accounts List"
                  />
                </Tab>
                <Tab eventKey="inactive" title={`In-Active Accounts (${InactiveAccount.length})`}>
                  <CustomTable
                    columns={this.getColumns()}
                    data={InactiveAccount}
                    isMore={(item) => this.props.user.selected_account.id != item.id ? this.props.writePermission : false}
                    isMoreIcon={<LoginIcon />}
                    isMoreHandler={(item) => this.handleLoginToAccount(item.id)}
                    isEdit={this.props.writePermission}
                    editHandler={(item) => this.updateAccount(item.id)}
                    isDelete={(item) => this.props.user.selected_account.id != item.id ? this.props.writePermission : false}
                    deleteHandler={(item) => this.deleteDialogHandler(item.id)}
                    fileName="In Active Accounts List"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountsList: bindActionCreators(getAccountsList, dispatch),
  loginToAccount: bindActionCreators(loginToAccount, dispatch),
  updateAccount: bindActionCreators(updateAccount, dispatch),
  deleteAccount: bindActionCreators(deleteAccount, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountsList)
);
