import React, { useEffect, useState } from 'react'
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Table from "react-bootstrap/Table";
import SearchIcon from '@mui/icons-material/Search';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { CSVLink } from "react-csv";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PaginationComponent from './pagination';
import _ from 'lodash'
import './table.scss'
import { csvValidator } from '../../util/validators/csv'

const CustomTable = ({
  columns = [],
  data = [],
  isAction = false,
  menu,
  handleMenu,
  isMore,
  isMoreIcon = <MoreHorizIcon />,
  isMoreHandler,
  isEdit,
  editHandler,
  isDelete,
  deleteHandler,
  exportButton = true,
  addButton = false,
  addHandler,
  toolbar = true,
  fileName = 'data',
  enableSearch = true,
  limit = 20
}) => {

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(data)
  const [sort, setSort] = useState('')

  useEffect(() => {
    if (!_.isEqual(data, rows)) setRows(data)
  }, [data])

  useEffect(() => {
    if (sort) sortRows()
  }, [sort])

  useEffect(() => {
    if (search && search.length > 1) {
      filterRows()
    } else if ((!search || (search && search.length < 2)) && rows.length != data.length) {
      setRows(data)
    }
  }, [search])

  const sortRows = () => {
    if (sort) {
      let isDesc = sort.startsWith('-')
      let key = sort.replace('-', '')

      const sortedArray = _.orderBy(rows, [user => typeof user[key] == 'string' ? user[key].toLowerCase() : user[key]], [isDesc ? 'desc' : 'asc']);
      setRows(sortedArray || [])
    }
  }

  const filterRows = () => {
    let _data = [];
    if (search && search.length > 1) {
      _data = _.cloneDeep(data).map(item => {
        let show = columns.find(column => (item[column.field] || '').toString().search(new RegExp(search, 'gi')) > -1)
        if (show) return item;
      }).filter(item => item);
    }
    setRows(_data || [])
    setPage(1)
  }

  return (
    <div className="row">
      <div className="col-12">
        {toolbar && <div className="row m-0">
          {enableSearch && <div className="col-8 col-lg-4 col-md-4 col-xxl-4 ms-auto">
            <div className="form-group">
              <div className="input-block search-block mt-0">
                <span className="search-icon"><SearchIcon /></span>
                <input className="form-control" type="text" placeholder="Search..." onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>
          </div>}
          {exportButton && <span className="download-icon">
            <CSVLink
              data={data.map(item => {
                const modifiedItem = {};
                for (const key in item) {
                  const value = item[key];
                  if (typeof value === 'string' && csvValidator(value)) {
                    modifiedItem[key] = `'${value}`;
                  } else {
                    modifiedItem[key] = value;
                  }
                }
                return columns.map((column) => {
                  let f_value = column.render ? column.render(modifiedItem) : modifiedItem[column.field];
                  return typeof f_value === 'string' ? f_value : modifiedItem[column.field];
                });
              })}
              headers={columns.map(column => column.title)}
              filename={`${fileName}.csv`}
            >
              <VerticalAlignBottomIcon />
            </CSVLink>
          </span>}
          {addButton && addHandler && <span onClick={addHandler} className="ms-2 download-icon"><AddIcon /></span>}
        </div>}
        <div className="material-list-block mt-3">
          <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} className={column.class}>
                      <span
                        onClick={() => {
                          if (sort.includes(column.field)) {
                            setSort(sort.startsWith('-') ? column.field : `-${column.field}`)
                          } else { setSort(column.field) }
                        }}
                        style={{ cursor: 'pointer' }}
                      >{column.title}</span>
                      {sort.includes(column.field) && <>
                        {sort.startsWith('-') ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize='small' />}
                      </>}
                    </th>
                  ))}
                  {isAction && <th>Action</th>}
                  {(isEdit || isDelete || isMore) && <th className='text-center'> </th>}
                </tr>
              </thead>
              <tbody>
                {/* {rows.map((item, itemIndex) => (
                  <tr key={itemIndex}>
                    {columns.map((column, index) => (
                      <td key={index} className={column.class}>{column.render ? column.render(item) : item[column.field]}</td>
                    ))}
                    {isAction && <td>
                      <div className="action-dropdown">
                        <OverlayTrigger trigger="focus" placement="bottom" overlay={menu ? menu(item) : (<></>)}>
                          <Button variant="default" onClick={() => { if (handleMenu) handleMenu(item) }}>
                            <MoreHorizIcon />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </td>}
                    {(isEdit || isDelete || isMore) && <td className='text-center'>
                      <div className="d-flex justify-content-center gap-2">
                        {isMore && ((typeof isMore == 'function' && isMore(item)) || typeof isMore == 'boolean') && <div className="action-edit">
                          <span onClick={() => { if (isMoreHandler) isMoreHandler(item) }}> {isMoreIcon}</span>
                        </div>}
                        {isEdit && <div className="action-edit">
                          <EditIcon onClick={() => { if (editHandler) editHandler(item) }} />
                        </div>}
                        {isDelete && ((typeof isDelete == 'function' && isDelete(item)) || typeof isDelete == 'boolean') && <div className="action-edit">
                          <DeleteIcon className="deleteicon" onClick={() => { if (deleteHandler) deleteHandler(item) }} />
                        </div>}
                      </div>
                    </td>}
                  </tr>
                ))}
                <tr><td className='text-center' colSpan={columns.length}>---------------------------</td></tr> */}
                {_.slice(rows, (limit * (page - 1)), (limit * page)).map((item, itemIndex) => (
                  <tr key={itemIndex}>
                    {columns.map((column, index) => (
                      <td key={index} className={column.class}>{column.render ? column.render(item) : item[column.field]}</td>
                    ))}
                    {isAction && <td>
                      <div className="action-dropdown">
                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={menu ? menu(item) : (<></>)}>
                          <Button variant="default" onClick={() => { if (handleMenu) handleMenu(item) }}>
                            <MoreHorizIcon />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </td>}
                    {(isEdit || isDelete || isMore) && <td className='text-center'>
                      <div className="d-flex justify-content-center gap-2">
                        {isMore && ((typeof isMore == 'function' && isMore(item)) || typeof isMore == 'boolean') && <div className="action-edit">
                          <span onClick={() => { if (isMoreHandler) isMoreHandler(item) }}> {isMoreIcon}</span>
                        </div>}
                        {isEdit && <div className="action-edit">
                          <EditIcon onClick={() => { if (editHandler) editHandler(item) }} />
                        </div>}
                        {isDelete && ((typeof isDelete == 'function' && isDelete(item)) || typeof isDelete == 'boolean') && <div className="action-edit">
                          <DeleteIcon className="deleteicon" onClick={() => { if (deleteHandler) deleteHandler(item) }} />
                        </div>}
                      </div>
                    </td>}
                  </tr>
                ))}
                {
                  data.length < 1 && <tr>
                    <td colSpan={columns.length} className='text-center'>
                      No Data
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {
        rows.length > limit && <div className='col-12 pt-3'>
          <PaginationComponent
            itemsCount={rows.length}
            itemsPerPage={limit}
            currentPage={page}
            setCurrentPage={(index) => { setPage(index) }}
          />
        </div>
      }
    </div >
  )

}

export default CustomTable
