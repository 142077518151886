import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { deleteProject, duplicateProject, editProject, getProjectList, saveTemplate } from "../../../actions/project-management.action";
import { useDispatch } from "react-redux";
import "../scss/project-management.scss";
import { PROJECT_MANAGEMENT, ADD_PROJECT } from "../../../constants/route-paths";
import { format } from "date-fns";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import _ from 'lodash'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';
import ChangeProjectStatus from "../change-project-status";
import DeleteDialog from '../../../components/delete-dialog'
import Modal from '../../../components/modal/modal'
import AddProject from '../add-project'
import AddTemplate from '../add-template-modal'
import { getCustomerList } from "src/actions/customer-management.action";
import DuplicateProjectDialog from '../../../components/duplicate-project-dialog'
import { getProjectType } from "../utils/getProjectStatus";
import { getProjectStatus } from "./get-status";
import { useSelector } from "react-redux";
import { errorSnackBar } from "src/actions/common.action";

export default function ProjectList({ writePermission, ...props }) {

  const entitlements = useSelector(state => state.user.entitlements)
  let [searchParams] = useSearchParams();
  let [projectState, setProjectState] = useState({
    actions: null,
    anchorEl: null,
    projectId: null,
    projectsCount: {},
    type: entitlements.currentProject ? "PROJECT" : entitlements.quotesProject ? 'tender' : 'dlp',
    tableTitle: "",
    projects: [],
    project: [],
    tender: [],
    dlp: [],
    completed: [],
    lost: [],
    revision: [],
    archived: [],
    columns: {
      common: [
        { title: "Project No", field: "tenderNumber", defaultSort: "desc" },
        {
          title: "Project Name",
          field: "projectName",
          render: (row) => (
            <Link to={row.type == "TENDER" ? `/${PROJECT_MANAGEMENT}/edit/${row.id}` : `/${PROJECT_MANAGEMENT}/view/${row.id}`}>
              {row.projectName}
            </Link>
          ),
        },
        { title: "Customer", field: "customer" },
        { title: "Address", field: "address" },
        { title: "Poject Type", field: "quotetype" },
        {
          title: "Project Status",
          field: "status",
          render: (row) => getProjectStatus(row.status),
        },
        { title: "Project Created", field: "createdAt" },
      ],
      quotes: [
        { title: "Tender No.", field: "tenderNumber", defaultSort: "desc" },
        {
          title: "Project Name",
          field: "projectName",
          render: (row) => (
            <Link to={row.type == "TENDER" ? `/${PROJECT_MANAGEMENT}/edit/${row.id}` : `/${PROJECT_MANAGEMENT}/edit/${row.id}?project-step=TENDERVIEW`}>
              {row.projectName}
            </Link>
          ),
        },
        { title: "Customer", field: "customer" },
        { title: "Address", field: "address" },
        { title: "Poject Type", field: "quotetype" },
        {
          title: "Project Status",
          field: "status",
          render: (row) => getProjectStatus(row.status),
        },
        { title: "Project Created", field: "createdAt" },
      ],
      archived: [
        { title: "Project No", field: "tenderNumber", defaultSort: "desc" },
        {
          title: "Project Name",
          field: "projectName",
          render: (row) => (
            <Link to={row.type == "TENDER" ? `/${PROJECT_MANAGEMENT}/edit/${row.id}` : `/${PROJECT_MANAGEMENT}/view/${row.id}`}>
              {row.projectName}
            </Link>
          ),
        },
        {
          title: "Project Status",
          field: "status",
          render: (row) => getProjectStatus(row.status),
        },
        { title: "Reason", field: "reasonOfLost" },
      ], //LOST,ARCHIVED,CANCELLED
      dlp: [
        { title: "Project No", field: "tenderNumber", defaultSort: "desc" },
        {
          title: "Project Name",
          field: "projectName",
          render: (row) => (
            <Link to={row.type == "TENDER" ? `/${PROJECT_MANAGEMENT}/edit/${row.id}` : `/${PROJECT_MANAGEMENT}/view/${row.id}`}>
              {row.projectName}
            </Link>
          ),
        },
        {
          title: "Project Status",
          field: "status",
          render: (row) => getProjectStatus(row.status),
        },
        { title: "DLP Date", field: "dateOfDLP" },
      ]
    },
    projectType: null,
  });
  const [changeStatusDialog, setchangeStatusDialog] = useState(false)
  const [newStatus, setnewStatus] = useState('')
  const [deleteopen, setdeleteopen] = useState(false)
  const [deleteId, setdeleteId] = useState('')
  const [viewProjectOpen, setviewProjectOpen] = useState(false)
  const [showTemplateModal, setshowTemplateModal] = useState('')
  const [customersList, setcustomersList] = useState([])
  const [showDuplicateDialog, setshowDuplicateDialog] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    initLoad();
  }, [searchParams.get("project-type")])

  const initLoad = async () => {
    if (searchParams.get("project-type")) await getProjetListHandler();
    await changeProjectType(searchParams.get("project-category") ? searchParams.get("project-category") : projectState.type);
  }

  const getProjetListHandler = async () => {
    const projectList = [];
    const tender = [];
    const dlp = [];
    const completed = [];
    const archived = [];

    try {
      let projects = await dispatch(getProjectList("", "", "", searchParams.get("project-type")));
      (projects || []).forEach((project) => {
        let { isCurrent, isQuote, isDLP, isCompleted, isArchived } = getProjectType(project)
        if (isCurrent) {
          projectList.push(formatObj(project));
        } else if (isQuote) {
          tender.push(formatObj(project));
        } else if (isArchived) {
          archived.push(formatObj(project));
        } else if (isCompleted) {
          completed.push(formatObj(project));
        } else if (isDLP) {
          dlp.push(formatObj(project));
        }
      });
      setProjectState(prev => ({
        ...prev,
        projectsCount: {
          totalProjects: projectList.length,
          totalTenders: tender.length,
          totalDLP: dlp.length,
          totalArchived: archived.length,
          totalCompleted: completed.length,
          total: projectList.length + tender.length + dlp.length + completed.length + archived.length
        },
        project: projectList,
        tender: tender,
        dlp,
        completed,
        archived,
        projectType: searchParams.get("project-type")
      }));
    } catch (e) {
      console.error(e)
    }
  };

  const changeProjectType = async (type, rootProjectId) => {
    let actions = null;
    if (Boolean(projectState.anchorEl)) {
      setProjectState(prev => ({
        ...prev,
        anchorEl: false,
        hasStages: null,
      }));
    }

    if (type === "PROJECT" || type === "TENDER" || type === "DLP" || type === "COMPLETED") {
      actions = [
        {
          icon: () => (
            <MoreHorizIcon
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ color: "#d5d5d5" }}
            />
          ),
          tooltip: "Edit",
          onClick: (event, rowData) => {
            handleMenu(
              event,
              rowData.id,
              rowData.hasStages,
              rowData.projectName
            );
          },
        },
      ];
    }


    setProjectState(prev => ({
      ...prev,
      actions,
      type,
      tableTitle: !rootProjectId ? '' : projectState.tableTitle
    }));
  };

  const handleMenu = (projectId, hasStages, projectName) => {
    setProjectState(prev => ({
      ...prev,
      projectId,
      hasStages,
      projectName,
    }));
  };


  const formatObj = (project) => {
    return {
      id: project.id,
      tenderNumber: project.tenderNumber,
      projectName: project.name,
      customer: project.customer && project.customer.business_name,
      address: project.address,
      quotetype: project.projectQuoteType,
      status: project.status,
      reasonOfLost: project.reasonOfLost,
      rootProjectId: project.rootProjectId,
      hasStages: project.hasStages,
      createdAt: format(new Date(project.dateOfCreation), "dd/MM/yyyy"),
      dateOfDLP: project.dateOfDLP ? format(new Date(project.dateOfDLP), "dd/MM/yyyy") : "",
    };
  };


  const viewRevisions = (id = null) => {
    if (!id) id = projectState.projectId;

    const { rootProjectId, projectName } = (projectState.tender || []).find((project) => project.id === id) || {};
    if (!rootProjectId) return;

    handleClose();
    setProjectState(prev => ({
      ...prev,
      tableTitle: `Project - ${projectName}`,
      type: "REVISION"
    }));
    dispatch(getProjectList(rootProjectId)).then((projects) => {
      const revision = [];
      projects.forEach((project) => {
        if (project.type === "TENDER" && project.status !== "LOST") {
          revision.push({
            id: project.id,
            tenderNumber: project.tenderNumber,
            projectName: project.name,
            customer: project.customer && project.customer.business_name,
            address: project.address,
            status: project.status,
            rootProjectId: project.rootProjectId,
            createdAt: format(new Date(project.dateOfCreation), "dd/MM/yyyy"),
          });
        }
      });
      setProjectState(prev => ({
        ...prev,
        revision
      }));
    });
  }

  const handleClose = () => {
    setProjectState(prev => ({
      ...prev,
      anchorEl: null,
      hasStages: null
    }));
  };

  const addProject = () => {
    if ((entitlements.totalProjects <= (projectState.projectsCount.total || 0)) && entitlements.totalProjects >= 0) {
      dispatch(errorSnackBar('You have reached the limit of creating projects. Please contact our Support Team'));
      return;
    }
    navigate(`/${PROJECT_MANAGEMENT}/${ADD_PROJECT}?project-type=${projectState.projectType}`);
  };

  const _editProject = (id) => {
    if (!id) return;
    navigate(`/${PROJECT_MANAGEMENT}/edit/${id}`)
  }

  const assetDialogHandler = (id) => {
    if (!id) return;
    navigate(`/projects/asset/${id}`)
  }

  const markAsInProgress = (id) => {
    handleClose()
    dispatch(editProject({ status: 'IN_PROGRESS' }, id)).then(res => {
      getProjetListHandler()
    })
  }

  const markAsCompleted = (id) => {
    handleClose()
    dispatch(editProject({ status: 'COMPLETED' }, id)).then(res => {
      getProjetListHandler()
    })
  }

  const changeStatusHandler = () => {
    if (changeStatusDialog) {
      getProjetListHandler()
    }
    setchangeStatusDialog(!changeStatusDialog)
  }

  const markAsDLP = () => {
    setnewStatus('DLP')
    handleClose()
    changeStatusHandler()
  }

  const changeStatus = (data) => {
    dispatch(editProject(data, projectState.projectId)).then(res => {
      changeStatusHandler()
      getProjetListHandler()
    })
  }

  const markAsArchived = () => {
    handleClose()
    setnewStatus('ARCHIVED')
    changeStatusHandler()
  }

  const deleteDialogHandler = (id = '') => {
    handleClose()
    setdeleteopen(!deleteopen)
    setdeleteId(id)
  }

  const deleteHandler = (id = '') => {
    markAsDeleted();
  }

  const markAsDeleted = () => {
    handleClose()
    if (deleteId)
      dispatch(deleteProject(deleteId, projectState.type)).then(res => {
        if (projectState.type == 'REVISION') {
          viewRevisions(deleteId)
        }
        getProjetListHandler()
      })
    deleteDialogHandler()
  }

  const handleViewProjectClose = () => {
    const dialogState = viewProjectOpen
    setviewProjectOpen(!dialogState)
    if (dialogState) {
      handleClose()
      getProjetListHandler()
    }
  }

  const templateModalHandler = (status) => {
    setshowTemplateModal(status)
  }

  const _saveTemplate = (data) => {
    templateModalHandler(false)
    let hasStages = projectState.hasStages;
    let projectId = projectState.projectId;
    dispatch(saveTemplate({ ...data, projectId, hasStages }))
      .then((res) => { })
      .catch(err => templateModalHandler(true))
    handleClose()
  }

  const reviseProject = (id = null) => {
    if (!id) return;
    navigate(`/${PROJECT_MANAGEMENT}/edit/${id}?action=revise`)
  }

  const _duplicateProject = async (customerId = null, data = {}) => {
    const projectId = data?.id || projectState.projectId;
    const projectName = data?.projectName;

    handleClose()
    if (customerId) {
      await dispatch(duplicateProject({ projectId, customerId })).then(res => {
        getProjetListHandler()
      })
    }
    if (!showDuplicateDialog) {

      let customersList = await dispatch(getCustomerList())
      let projectCustomers = await dispatch(getProjectList(null, null, null, null, projectName))

      customersList = (Array.isArray(customersList) && customersList.map(customer => {
        return {
          key: customer.businessName,
          value: customer.id
        }
      })) || []

      customersList = customersList.filter(({ value }) => {
        const customer = Array.isArray(projectCustomers) && projectCustomers.find(({ customer }) => customer.id === value)
        return !customer
      })
      setcustomersList(customersList)
    }
    setshowDuplicateDialog(!showDuplicateDialog)
  }

  const markAsSubmit = (id) => {
    handleClose()
    dispatch(editProject({ status: 'TENDER_SUBMITTED' }, id)).then(res => {
      getProjetListHandler()
    })
  }

  return (
    <>
      {changeStatusDialog && <ChangeProjectStatus
        open={changeStatusDialog}
        dialogClose={changeStatusHandler}
        changeStatus={changeStatus}
        projectId={projectState.projectId}
        newStatus={newStatus}
      />}
      {deleteopen && <DeleteDialog
        open={deleteopen}
        id={deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName='Project'
      />}
      {viewProjectOpen && <Modal
        open={viewProjectOpen}
        onClose={handleViewProjectClose}
        modalHeading={'Convert To Project'}
        modalSaveTxt='Convert'
        modalCloseTxt='Cancel'
        fullWidth={true}
        maxWidth={'md'}
        modalContent={
          <AddProject
            {...props}
            currentStep={0}
            tenderEditPage={true}
            projectId={projectState.projectId}
            convertTender={true}
            convertToProject={true}
            handleClose={handleViewProjectClose}
          />}
        primary
      />}
      {showTemplateModal && <AddTemplate
        templateModalHandler={templateModalHandler}
        open={showTemplateModal}
        saveTemplate={_saveTemplate}
      />}
      {showDuplicateDialog && <DuplicateProjectDialog
        open={showDuplicateDialog}
        dialogClose={_duplicateProject}
        customersList={customersList}
      />}

      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Projects</h2>
          </div>
          <div className="w-auto ms-auto mt-auto">
            {writePermission && <button onClick={addProject} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">
              Create Project
            </button>}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey={projectState.type.toLowerCase()}
              activeKey={projectState.type.toLowerCase()}
              id="uncontrolled-tab-example" className="mb-3"
              onSelect={(k) => {
                switch (k) {
                  case 'project': changeProjectType('PROJECT'); break;
                  case 'revision':
                  case 'tender': changeProjectType('TENDER'); break;
                  case 'dlp': changeProjectType('DLP'); break;
                  case 'completed': changeProjectType('COMPLETED'); break;
                  case 'archived': changeProjectType('ARCHIVED'); break;
                  default: break;
                }
              }}
            >
              {entitlements.currentProject && <Tab eventKey="project" title={`Current Projects (${projectState.projectsCount.totalProjects})`}>
                <CustomTable
                  columns={projectState.columns.common}
                  data={_.orderBy(projectState.project, ['tenderNumber'], ['desc'])}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data.id, data.hasStages, data.projectName)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={() => _editProject(data.id)}>Edit</li>
                          <li onClick={() => assetDialogHandler(data.id)}>Assets</li>
                          {writePermission && <>
                            <li onClick={() => markAsInProgress(data.id)}>Mark as In Progress</li>
                            <li onClick={() => markAsDLP()}>Mark as DLP</li>
                            <li onClick={() => markAsCompleted(data.id)}>Mark as Completed</li>
                            <li onClick={() => markAsArchived()}>Archive</li>
                            <li onClick={() => deleteDialogHandler(data.id)}>Delete</li>
                          </>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Current Project List'
                />
              </Tab>}
              {entitlements.quotesProject && projectState.type != 'REVISION' && <Tab eventKey="tender" title={`Quotes (${projectState.projectsCount.totalTenders})`}>
                <CustomTable
                  columns={projectState.columns.quotes}
                  data={_.orderBy(projectState[projectState.type.toLowerCase()], ['tenderNumber'], ['desc'])}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data.id, data.hasStages, data.projectName)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={() => viewRevisions(data.id)}>View Revisions</li>
                          {writePermission && <>
                            <li
                              className={entitlements.currentProject ? '' : 'disabled'}
                              onClick={() => {
                                if (entitlements.currentProject) handleViewProjectClose(data.id)
                              }}>
                              Convert to Project
                            </li>
                            <li onClick={() => templateModalHandler(true)}>Create as Template</li>
                            <li onClick={() => reviseProject(data.id)}>New Revision</li>
                            <li onClick={() => _duplicateProject(null, data)}>Duplicate Project</li>
                            <li onClick={() => markAsSubmit(data.id)}>Mark as Submit</li>
                            <li onClick={() => markAsArchived()}>Archive</li>
                            <li onClick={() => deleteDialogHandler(data.id)}>Delete</li>
                          </>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Quotes List'
                />
              </Tab>}
              {projectState.type == 'REVISION' && <Tab eventKey="revision" title={`Quotes Rev (${(projectState[projectState.type.toLowerCase()] || []).length})`}>
                <CustomTable
                  columns={projectState.columns.quotes}
                  data={_.orderBy(projectState[projectState.type.toLowerCase()], ['tenderNumber'], ['desc'])}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data.id, data.hasStages, data.projectName)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={() => viewRevisions(data.id)}>View Revisions</li>
                          {writePermission && <>
                            <li
                              className={entitlements.currentProject ? '' : 'disabled'}
                              onClick={() => {
                                if (entitlements.currentProject) handleViewProjectClose(data.id)
                              }}>
                              Convert to Project
                            </li>
                            <li onClick={() => templateModalHandler(true)}>Create as Template</li>
                            <li onClick={() => reviseProject(data.id)}>New Revision</li>
                            <li onClick={() => _duplicateProject(null, data)}>Duplicate Project</li>
                            <li onClick={() => markAsSubmit(data.id)}>Mark as Submit</li>
                            <li onClick={() => markAsArchived()}>Archive</li>
                            <li onClick={() => deleteDialogHandler(data.id)}>Delete</li>
                          </>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Quotes Rev List'
                />
              </Tab>}
              {entitlements.currentProject && <Tab eventKey="dlp" title={`DLP Projects (${projectState.projectsCount.totalDLP})`}>
                <CustomTable
                  columns={projectState.columns.dlp}
                  data={_.orderBy(projectState.dlp, ['tenderNumber'], ['desc'])}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data.id, data.hasStages, data.projectName)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          {writePermission && <>
                            <li
                              className={entitlements.currentProject ? '' : 'disabled'}
                              onClick={() => {
                                if (entitlements.currentProject) handleViewProjectClose(data.id)
                              }}>
                              Mark as Current
                            </li>
                            <li onClick={() => markAsCompleted(data.id)}>Mark as Completed</li>
                            <li onClick={() => markAsArchived()}>Mark as Archived</li>
                          </>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='DLP Project List'
                />
              </Tab>}
              {entitlements.currentProject && <Tab eventKey="completed" title={`Completed (${projectState.projectsCount.totalCompleted})`}>
                <CustomTable
                  columns={projectState.columns.common}
                  data={_.orderBy(projectState.completed, ['tenderNumber'], ['desc'])}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data.id, data.hasStages, data.projectName)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          {writePermission && <>
                            <li
                              className={entitlements.currentProject ? '' : 'disabled'}
                              onClick={() => {
                                if (entitlements.currentProject) handleViewProjectClose(data.id)
                              }}>
                              Mark as Current
                            </li>
                            <li onClick={() => markAsArchived()}>Mark as Archived</li>
                            <li onClick={() => markAsDLP()}>Mark as DLP</li>
                          </>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Completed Project List'
                />
              </Tab>}
              {writePermission && <Tab eventKey="archived" title={`Archived (${projectState.projectsCount.totalArchived})`}>
                <CustomTable
                  columns={projectState.columns.archived}
                  data={_.orderBy(projectState.archived, ['tenderNumber'], ['desc'])}
                  fileName='Archived Project List'
                />
              </Tab>}
            </Tabs>

          </div>
        </div>
      </div>
    </>
  );
}
