import '../project-management/scss/task.scss'
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { deleteItemTemplate, getItemTemplates } from 'src/actions/template-management.action';
import { toUSD } from 'src/util/currency-formatter';
import CustomTable from 'src/components/table/table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTemplate from './edit-templates';
import DeleteDialog from "src/components/delete-dialog";
import { clearTaskAction } from 'src/slice/add-task';

function ItemTempalteList(props) {
    const [state, setState] = useState({
        columns: [
            {
                title: "Description", field: "isSelected", class: "w-auto fw-bold",
                render: (rowData) => rowData.description
            },
            {
                title: "Material", field: "material_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.material_total_cost)}</>
            },
            {
                title: "Resource", field: "labor_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.labor_total_cost)}</>
            },
            {
                title: "Contractor", field: "subcontractor_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.subcontractor_total_cost)}</>
            },
            {
                title: "Total", field: "total", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(parseFloat(rowData.material_total_cost || 0) + parseFloat(rowData.labor_total_cost || 0) + parseFloat(rowData.subcontractor_total_cost || 0))}</>
            },
            {
                title: "Action", field: "action", class: 'fw-bold text-center',
                render: (rowData) => (
                    <div class="d-flex gap-2 justify-content-center">
                        <div className="action-edit">
                            <EditIcon onClick={() => editHandler(rowData.id)} />
                        </div>
                        <div className="action-edit">
                            <DeleteIcon className='deleteicon' onClick={() => deleteDialogHandler(rowData.id)} />
                        </div>
                    </div>
                )
            },
        ],
        itemTemplates: [],
        editItem: {
            open: false,
            id: ''
        },
        deleteId: '',
    })

    const dispatch = useDispatch()

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        fetchTemplates()
    }

    const fetchTemplates = (closeDialog = false) => {
        setState(prev => ({
            ...prev,
            itemTemplates: []
        }));
        dispatch(getItemTemplates()).then((res) => {
            let items = res.map(item => item);
            setState(prev => ({
                ...prev,
                itemTemplates: items
            }));
            if (closeDialog) editHandler()
        }).catch(e => console.error(e))
    }

    const editHandler = (id) => {
        dispatch(clearTaskAction());
        setState(prev => ({
            ...prev,
            editItem: {
                open: !prev.editItem.open,
                id: id
            }
        }));
    }

    const deleteDialogHandler = (id = '') => {
        setState(prev => ({
            ...prev,
            deleteId: id
        }));
    }

    const deleteHandler = (id) => {
        dispatch(deleteItemTemplate(id)).then((res) => {
            deleteDialogHandler();
            fetchTemplates();
        }).catch(e => console.error(e))
    }

    return (
        <div className="purchase-order-block">

            <DeleteDialog
                open={state.deleteId}
                id={state.deleteId}
                deleteHandler={deleteHandler}
                dialogClose={() => deleteDialogHandler()}
                moduleName="Task Template"
            />

            {state.editItem.open && <EditTemplate
                open={state.editItem.open}
                dialogClose={() => editHandler('')}
                isSubTask={true}
                subTaskId={state.editItem.id}
                saveHandler={() => { fetchTemplates(true) }}
            />}

            <h2 className="content-heading text-uppercase">Item Templates</h2>
            <CustomTable
                toolbar={false}
                columns={state.columns}
                data={state.itemTemplates}
            />

        </div>
    )
}

export default ItemTempalteList;