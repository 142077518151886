import { createSlice } from '@reduxjs/toolkit'
import { startOfYear } from 'date-fns';

// Slice
const initial = {
    is_fetched: false,
    operations: {},
    admin: {},
    insights: {},
    filter: { start: startOfYear(new Date()), end: new Date() }
}

const slice = createSlice({
    name: 'dashboard',
    initialState: {
        ...initial,
    },
    reducers: {
        addData: (state, action) => {
            let { operations = {}, admin = {}, insights = {}, filter = null } = action.payload;
            state.is_fetched = true;
            state.operations = operations;
            state.admin = admin;
            state.insights = insights;
            if (filter) state.filter = filter
        },
        clearData: (state, action) => {
            state.is_fetched = false;
            state.operations = {};
            state.admin = {};
            state.insights = {};
        }
    },
});

export default slice.reducer

// Actions
const { addData, clearData } = slice.actions

export const addDashboardAction = (data) => async dispatch => {
    try {
        dispatch(addData(data));
    } catch (e) {
        return console.error(e);
    }
}

export const clearDashboardAction = () => async dispatch => {
    dispatch(clearData());
}