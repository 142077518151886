import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Icons from './../icons/icons'
import './button.scss'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
}))

export default function Buttons(props) {
  const classes = useStyles()
  let element = <Button variant='contained' color={props.class ? props.class : 'primary'} className={classes.button + ' ' + (props.className ? props.className : '' ) + ' '   } onClick={props.onClick} {...props} disabled={props.disable ? props.disable : undefined}> {props.title} </Button>
  if (props.class === 'icon') {
    element = <Fab size='small' color='secondary' aria-label='add' className={classes.button + ' ' + (props.className ? props.className : '')} onClick={props.onClick}>
      <Icons icon={props.icon} />
    </Fab>
  }
  return (
    <span>
      {element}
    </span>
  )
}
