import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_CUSTOMER_LIST = '/customer/list'
const ADD_CUSTOMER = '/customer'
const GET_CUSTOMER = '/customer'
const EDIT_CUSTOMER = '/customer'
const GET_CUSTOMER_SITE_ADDRESS = '/customer/site-address'

// // ************API call Functions starts here **************

export const getCustomerList = async () => {
  return await axios('get', GET_CUSTOMER_LIST, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const getCustomer = async (id) => {
  return await axios('get', `${GET_CUSTOMER}?id=${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const addCustomer = async (data) => {
  return await axios('post', ADD_CUSTOMER, data, { successMessage: 'Customer Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const editCustomer = async (data, id) => {
  return await axios('put', `${EDIT_CUSTOMER}/${id}`, data, { successMessage: 'Customer Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const addContact = async (data, id) => {
  return await axios('post', `${EDIT_CUSTOMER}/${id}/contact`, data, { server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const deleteCustomer = async (id) => {
  return await axios('delete', `${EDIT_CUSTOMER}/${id}`, {}, { successMessage: 'Customer Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

export const getCustomerSiteAddress = async (cutomerId) => {
  return await axios('get', `${GET_CUSTOMER_SITE_ADDRESS}/${cutomerId}`, {}, {  loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
    return data
  },
  error => { // request level error
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}
