import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from './../../components/modal/modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import './scss/project-management.scss'
import {
  fetchTemplates,
  deleteTemplate
} from '../../actions/project-management.action'

const Templates = ({
  fetchTemplates,
  closeModal,
  chooseTemplate,
  deleteTemplate
}) => {
  const [templateList, setTemplatesList] = useState(null)
  const [selectedIndex, setTemplateIndex] = useState(-1)

  useEffect(() => {
    getAllTemplates()
  }, [])

  const getAllTemplates = () => {
    fetchTemplates().then((templates) => {
      setTemplatesList(templates)
    })
  }

  const deleteProjectTemplate = async (id) => {
    await deleteTemplate(id)
    getAllTemplates()
  }

  return (<Modal
    open={true}
    onClose={() => chooseTemplate(null)}
    modalHeading={'Choose Template'}
    modalSaveTxt='Next'
    fullWidth={true}
    maxWidth={'lg'}
    onClick={() => chooseTemplate(selectedIndex > -1 ? templateList[selectedIndex] : null)}
    showActionBtn={templateList && true}
    cxlbtnClass='cancelBtn'
    savebtnClass='createBtn'
    primary
  >
    <div className="templates-container"  >
      {
        !templateList ? <div className={'template-loader'} ><CircularProgress /> </div> : <>
          <div
            className={`template-box ${selectedIndex === -1 ? "template-selected" : ''}`}
            onClick={() => setTemplateIndex(-1)}  >
            Add New
          </div>
          {templateList.map((template, index) => (<>
            <div key={index} className={`template-box ${selectedIndex === index ? "template-selected" : ''}`}
              onClick={(e) => e.target === e.currentTarget && setTemplateIndex(index)} >
              <IconButton aria-label='add' className='template-delete-button' onClick={() => deleteProjectTemplate(template.id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              <h4>{template.name}</h4>
              <h6>{template.description}</h6>
            </div>
          </>))}
        </>
      }
    </div>
  </Modal >)
}

const mapDispatchToProps = dispatch => ({
  fetchTemplates: bindActionCreators(fetchTemplates, dispatch),
  deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
})

export default connect(null, mapDispatchToProps)(Templates)
