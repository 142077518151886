import { addSubscriptionAPI, deleteSubscriptionAPI, getSubscriptionAPI, listSubscriptionAPI, updateSubscriptionAPI } from 'src/api/subscription.api'
import { addSubscriptionsAction } from 'src/slice/subscription.slice'

export const listSubscriptions = (params) => {
  return (dispatch, getState) => {
    return listSubscriptionAPI(params).then(async (response) => {
      dispatch(addSubscriptionsAction(response))
      return response;
    }).catch(e => {
      return e
    })
  }
}

export const getSubscription = (id) => {
  return (dispatch, getState) => {
    return getSubscriptionAPI(id)
  }
}

export const addSubscription = (params) => {
  return (dispatch, getState) => {
    addSubscriptionAPI(params).then(async (response) => {
      dispatch(listSubscriptions())
    }).catch(e => {
      return e
    })
  }
}

export const updateSubscription = (params, id) => {
  return (dispatch, getState) => {
    updateSubscriptionAPI(params, id).then(async (response) => {
      dispatch(listSubscriptions())
    }).catch(e => {
      return e
    })
  }
}

export const deleteSubscription = (params, id) => {
  return (dispatch, getState) => {
    deleteSubscriptionAPI(params, id).then(async (response) => {
      dispatch(listSubscriptions())
    }).catch(e => {
      return e
    })
  }
}
