import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCategories,
  getItems,
  getUnits,
  addItem,
} from "../actions/inventory.action";
import Input from "../components/inputs/input-simple";
import { getFormatedUnit } from "./index";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  itemFields,
  categoriesFields,
  categoryItemFields,
} from "../constants/fields.constant";
import Buttons from "./../components/buttons/buttons";
import "../routes/inventory-management/scss/inventory.scss";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import {
  warningSnackBar,
  errorSnackBar,
  successSnackBar,
} from "../actions/common.action";
import idx from "idx";
import { filterManufacturer, filterItems } from "./common";
import { TableCell, TableRow } from "@material-ui/core";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import deleteIcon from "../assets/Dustbin.png";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ProfitMarginLock from "../components/profitMarginLock-dialog";
import { calculateSaleAmount } from "./calculations/sale-amount";
const filter = createFilterOptions();

class AddMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesList: [],
      itemsList: [],
      item: itemFields,
      categoryName: [],
      profitMarginDialogState: false,
      confirmLock: false,
      lastCategoryId: null,
      lastInputValues: null,
      lockArrayValue: null,
      selectedItemsNewIndex: null,
      selectedItems: [],
      selectedItemsList: props.selectedItemsList,
      showAddItem: false,
      addItemDialogState: false,
      categories: JSON.parse(JSON.stringify(categoriesFields)),
      categoryItem: JSON.parse(JSON.stringify(categoryItemFields)),
      items: [],
      newunitList: [],
      newunitvalue: "",
      newtotalvalue: 0,
      newitemid: "",
      isInlineItem: false,
      newItem: false,
      checked: true,
      showMaterialLockIcon: true,
      materialTotal_cost: 0,
      materialTotal_SaleAmount: 0,
      filterValue: {},
    };
    this.functionInChildComponent = this.functionInChildComponent.bind(this);
  }

  async componentDidMount() {
    await this.props.getCategories().then((res) => {
      const categoriesList = res.map((category) => ({
        key: category.name,
        name: category.name,
        value: category.id,
      }));
      const categories = this.state.categories;
      categories.items = categoriesList;
      this.setState({ categories, categoriesList });
    });

    await this.props.getItems().then((items) => {
      const activeItems =
        (Array.isArray(items) &&
          items.filter(({ status }) => status === "ACTIVE")) ||
        [];
      this.setState({ itemsList: activeItems });
    });

    await this.props.getUnits().then((res) => {
      const allUnits =
        (res && Array.isArray(res) && res.length > 0 && res[0].units) || [];
      const units = getFormatedUnit(allUnits);
      const item = this.state.item;

      item.unit.items = units;
      const newunitList = units;
      this.setState({ item, allUnits, newunitList });
    });
  }
  componentDidUpdate = (prevProps) => {
    const { itemsList } = this.state;

    const { items } = this.props;
    const itemsLength = (Array.isArray(items) && items.length) || 0;
    if (
      this.state.items.length !== itemsLength &&
      itemsList.length !== 0 &&
      itemsLength !== 0
    ) {
      this.setState({ items, selectedItems: [], categoryName: [] }, () =>
        this.editItems(items)
      );
    }
    if (prevProps.selectedItemsList !== this.props.selectedItemsList) {
      this.functionInChildComponent();
    }
  };
  functionInChildComponent = () => {
    const { categoryName, selectedItemsList, selectedItems } = this.state;
    const updatedSelectedItemsList = JSON.parse(
      JSON.stringify(selectedItemsList)
    );
    updatedSelectedItemsList.forEach((item, index) => {
      if (item["saleAmountLock"].value) {
        const profitMarginCost = calculateSaleAmount({
          cost: item["costPerItem"].value,
          profitMargin: this.props.profitMargin || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMargin,
          profitMarginType: this.props.profitMarginType || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMarginType,
        })
        // this.props.calculateProfitMargin(
        //   item["costPerItem"].value
        // );
        updatedSelectedItemsList[index]["saleAmount"].value =
          parseFloat(item["costPerItem"].value) + parseFloat(profitMarginCost);
        updatedSelectedItemsList[index]["total"].value =
          (parseInt(item["qty"].value) || 0) *
          parseFloat(item["saleAmount"].value);
      }
    });
    const updatedSelectedItems = { ...selectedItems };
    if (categoryName) {
      categoryName.forEach((category) => {
        if (updatedSelectedItems[category.id]) {
          updatedSelectedItems[category.id].forEach((item) => {
            if (item["saleAmountLock"].value) {
              const profitMarginCost = calculateSaleAmount({
                cost: item["costPerItem"].value,
                profitMargin: this.props.profitMargin || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMargin,
                profitMarginType: this.props.profitMarginType || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMarginType,
              })
              // this.props.calculateProfitMargin(
              //   item["costPerItem"].value
              // );
              item["saleAmount"].value =
                parseFloat(item["costPerItem"].value) +
                parseFloat(profitMarginCost);
              item["total"].value =
                (parseFloat(item["qty"].value) || 0) *
                parseFloat(item["saleAmount"].value);
            }
          });
        }
      });
    }

    this.setState(
      {
        selectedItems: updatedSelectedItems,
        selectedItemsList: updatedSelectedItemsList,
      },
      () => {
        for (let i = 0; i < this.props.subTaskArray.length; i++) {
          this.props.setSelectedItems(updatedSelectedItems, null, i);
        }
      }
    );
  };

  profitMarginDialogHandler = (
    index,
    categoryId,
    inputValue,
    selectedItemsNewIndex
  ) => {
    let { profitMarginDialogState } = this.state;
    this.setState({ lockArrayValue: index });
    this.setState({ lastCategoryId: categoryId });
    this.setState({ lastInputValues: inputValue });
    this.setState({ selectedItemsNewIndex: selectedItemsNewIndex });
    this.setState(
      { profitMarginDialogState: !profitMarginDialogState },
      () => { }
    );
  };
  lockIconChange = () => {
    const { showlockIcon } = this.state;
    this.setState({ showlockIcon: !showlockIcon });

    this.profitMarginDialogHandler();
  };
  editItems = (items) => {
    const { categoryName } = this.state;
    items &&
      items.forEach((material) => {
        if (_.isString(material)) {
          material = JSON.parse(material);
        }
        if (material.isInlineItem) {
          this.AddInlineItem(material);
        } else {
          if (
            _.filter(categoryName, { id: material.categoryId }).map((v) => v.id)
              .length === 0
          ) {
            this.addCategory(material.categoryId);
          }
          this.addItem(material.id, material.categoryId, material, true);
        }
      });
  };
  setChecked = (checked) => {
    this.setState({ checked: checked });
  };

  setConfirmed = () => {
    this.setState({ confirmed: true });
    const selectedItems = this.state.selectedItems;
    const selectedItemsList = this.state.selectedItemsList;
    selectedItemsList[this.state.lockArrayValue]["saleAmountLock"].value =
      !selectedItemsList[this.state.lockArrayValue]["saleAmountLock"].value;
    selectedItems[this.state.lastCategoryId][this.state.selectedItemsNewIndex][
      "saleAmountLock"
    ].value =
      !selectedItems[this.state.lastCategoryId][
        this.state.selectedItemsNewIndex
      ]["saleAmountLock"].value;

    if (this.props.profitValueChanges) this.props.profitValueChanges(this.state.lockArrayValue);

    this.setState({
      profitMarginDialogState: !this.state.profitMarginDialogState,
    });
  };

  addCategory = (categoryId) => {
    const { categoryName, selectedItems } = this.state;
    const categoryExist = categoryName.find(
      (category) => category.id === categoryId
    );

    if (!categoryExist) {
      categoryName.push({
        id: categoryId,
        name: this.filterCategory(categoryId)?.name,
      });
      selectedItems[categoryId] = [];
      this.props.setCategoryName(categoryName);
      this.props.setSelectedItems(
        selectedItems,
        null,
        this.props.subTaskArrayIndex
      );
      this.setState({ selectedItems, categoryName });
    }
  };

  addFilter = (value, type) => {
    let { filterValue, showMaterialLockIcon } = this.state;
    if (!filterValue) filterValue = {};
    if (filterValue && filterValue[type] !== value) {
      filterValue[type] = value;
      if (type === "saleAmount") {
        filterValue["saleAmount"] = value;
        filterValue["saleAmountLock"] = !filterValue["saleAmountLock"];
      }
      if (type === "costPerItem" || type === "qty") {
        const totalvalue =
          (parseInt(filterValue["qty"]) || 1) *
          (parseFloat(filterValue["saleAmount"]) || 1);
        if (!this.props.isProjectPO) {
          const profitMarginCost = calculateSaleAmount({
            cost: filterValue["costPerItem"],
            profitMargin: this.props.profitMargin || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMargin,
            profitMarginType: this.props.profitMarginType || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMarginType,
          })
          // this.props.calculateProfitMargin(
          //   parseFloat(filterValue["costPerItem"])
          // );
          filterValue["saleAmount"] =
            parseFloat(filterValue["saleAmount"]) <= 0 ||
              filterValue["saleAmount"] == null ||
              showMaterialLockIcon
              ? parseFloat(filterValue["costPerItem"]) +
              parseFloat(profitMarginCost)
              : parseFloat(filterValue["saleAmount"]);
        }
        this.setState({ newtotalvalue: parseFloat(totalvalue) });
      }
      if (filterValue.noresult) {
        if (type === "unit") {
          const newunitvalue = value;
          this.setState({ newunitvalue });
        }
      }
      this.setState({ filterValue });

      this.itemsDropDownOption(true);
    }
  };
  manufacturerDropDown = () => {
    const { filterValue, itemsList } = this.state;
    let filterOptions = filterManufacturer(itemsList, filterValue);
    if (filterOptions.length === 0) {
      this.setFilterResult(true, "");
    } else {
      this.setFilterResult(false, "");
    }
    return filterOptions;
  };

  itemsDropDownOption = (skip) => {
    const { filterValue, itemsList } = this.state;
    let filterOptions = filterItems(itemsList, filterValue);
    if (filterOptions.length === 0) {
      this.setFilterResult(true, "");
    } else {
      this.setFilterResult(false, "");
    }
    return filterOptions;
  };

  setFilterResult = (value, msg) => {
    const { filterValue } = this.state;
    let prevValue = idx(filterValue, (_) => _.noresult);
    if (prevValue !== value) {
      filterValue["noresult"] = value;
      filterValue["noresult_msg"] = msg;
      this.setState({ filterValue });
    }
  };

  addItem = (value, category, itemData = null, skip = false) => {
    const { categoryName } = this.state;
    const itemExist = this.state.selectedItemsList.find(
      (item) => item.id === value && item.categoryId === category
    );

    if (!itemExist) {
      const selectedItem = itemData ? itemData : this.filterItem(value);
      if (!category) category = selectedItem.categoryId;
      if (
        _.filter(categoryName, { id: category }).map((v) => v.id).length === 0
      )
        this.addCategory(category);

      const item = JSON.parse(JSON.stringify(this.state.item));
      item.name.value = selectedItem.item;
      item.taskId.value = selectedItem.taskId || null;
      item.id = selectedItem.id || uuidv4();
      item.manufacturer = selectedItem.manufacturer;
      item.partNo = selectedItem.partNo || selectedItem.part_no;
      item.categoryId = selectedItem.categoryId;
      item.costPerItem.value = selectedItem.cost;
      item.saleAmount.value =
        (selectedItem.saleAmount === 0
          ? selectedItem.cost
          : selectedItem.saleAmount) || 0;
      item.saleAmountLock.value = selectedItem.saleAmountLock;
      item.unit.value = selectedItem.unit || null;
      item.qty.value = itemData ? selectedItem.qty : 1;
      item.total.value = itemData ? selectedItem.total : 0;

      this.setState(
        (prevState) => {
          const selectedItems = { ...prevState.selectedItems };
          const selectedItemsList = [...prevState.selectedItemsList];

          if (!selectedItems[category]) {
            selectedItems[category] = [];
          }

          selectedItems[category].push(item);
          selectedItemsList.push(item);

          this.props.setSelectedItems(
            selectedItems,
            skip,
            this.props.subTaskArrayIndex
          );
          if (this.props.profitValueChanges) this.props.profitValueChanges();
          return {
            selectedItems,
            selectedItemsList,
          };
        },
        () => this.hideAddItem()
      );
    } else {
      if (!skip) {
        this.props.warningSnackBar("This Item is Already Selected");
      }
    }
  };

  filterItem = (id) => {
    const item =
      this.state.itemsList &&
      this.state.itemsList.find((item) => item.id === id);
    return item;
  };

  filterCategory = (id) => {
    const category =
      this.state.categoriesList &&
      this.state.categoriesList.find((category) => category.value === id);
    return category;
  };

  itemChangeHandler = (e, category, index) => {
    const selectedItems = this.state.selectedItems;
    const selectedItemsList = this.state.selectedItemsList;

    const selectedCategoryId = selectedItemsList[index].categoryId;
    const selectedItemArray = selectedItems[selectedCategoryId];
    const itemId = selectedItemsList[index].id;
    const selectedItemsNewIndex = selectedItemArray.findIndex(
      (item) => item.id === itemId
    );
    const profitMarginCost = calculateSaleAmount({
      cost: selectedItems[category][selectedItemsNewIndex]["costPerItem"].value,
      profitMargin: this.props.profitMargin || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMargin,
      profitMarginType: this.props.profitMarginType || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMarginType,
    })
    // this.props.calculateProfitMargin(selectedItems[category][selectedItemsNewIndex]["costPerItem"].value);

    if (e == "saleAmountLock") {
      this.profitMarginDialogHandler(index, category, e, selectedItemsNewIndex);
      if (this.state.profitMarginDialogState && this.state.confirmLock) {
        selectedItemsList[index]["saleAmountLock"].value =
          !selectedItemsList[index]["saleAmountLock"].value;
        selectedItemsList[index]["saleAmount"].value =
          selectedItemsList[index]["saleAmount"].value <= 0 ||
            selectedItemsList[index]["saleAmountLock"].value === true
            ? parseFloat(
              selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                .value
            ) + parseFloat(profitMarginCost)
            : parseFloat(
              selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                .value
            );
        selectedItems[category][selectedItemsNewIndex]["saleAmount"].value =
          selectedItemsList[selectedItemsNewIndex]["saleAmount"].value <= 0 ||
            selectedItemsList[selectedItemsNewIndex]["saleAmountLock"].value ===
            true
            ? parseFloat(
              selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                .value
            ) + parseFloat(profitMarginCost)
            : parseFloat(
              selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                .value
            );
      }
    } else {
      if (e.target && e.target.name == "taskId" && this.props.isProjectPO) {
        const { tasksList = [] } = this.props;
        const _task =
          tasksList.find((_task) => _task.value === e.target.value) || {};
        selectedItemsList[index]["taskName"] = _task.key;
        selectedItemsList[index]["taskDescription"] = _task.taskDescription;
        selectedItemsList[index]["taskType"] = _task.taskType;
        selectedItemsList[index]["task"] = {
          error: "",
          valid: true,
        };
      }
      if (e.target.name === "costPerItem" || e.target.name === "qty") {
        if (e.target.value < 0) {
          return;
        }
      }

      const selectedCategoryId = selectedItemsList[index].categoryId;
      const selectedItemArray = selectedItems[selectedCategoryId];
      const itemId = selectedItemsList[index].id;
      const selectedItemsNewIndex = selectedItemArray.findIndex(
        (item) => item.id === itemId
      );

      selectedItemsList[index][e.target.name].value = e.target.value;
      selectedItems[category][selectedItemsNewIndex][e.target.name].value =
        e.target.value;
      if (!this.props.isProjectPO) {
        /*CON-200 profitMarginCost= ((profitMargin%) / (costPerItem)) * 100 */
        const profitMarginCost = calculateSaleAmount({
          cost: selectedItems[category][selectedItemsNewIndex]["costPerItem"].value,
          profitMargin: this.props.profitMargin || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMargin,
          profitMarginType: this.props.profitMarginType || this.props.subTaskArray[this.props.subTaskArrayIndex].profitMarginType,
        })
        // this.props.calculateProfitMargin(
        //   selectedItems[category][selectedItemsNewIndex]["costPerItem"].value
        // );
        if (e.target.name === "saleAmountLock") {
          selectedItemsList[index]["saleAmountLock"].value =
            !selectedItemsList[index]["saleAmountLock"].value;
        }
        if (e.target.name === "costPerItem" || e.target.name === "qty") {
          selectedItemsList[index]["saleAmount"].value =
            selectedItemsList[index]["saleAmount"].value <= 0 ||
              selectedItemsList[index]["saleAmountLock"].value === true
              ? parseFloat(
                selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                  .value
              ) + parseFloat(profitMarginCost)
              : parseFloat(
                selectedItems[category][selectedItemsNewIndex]["saleAmount"]
                  .value
              );
          selectedItems[category][selectedItemsNewIndex]["saleAmount"].value =
            selectedItemsList[selectedItemsNewIndex]["saleAmount"].value <= 0 ||
              selectedItemsList[selectedItemsNewIndex]["saleAmountLock"].value ===
              true
              ? parseFloat(
                selectedItems[category][selectedItemsNewIndex]["costPerItem"]
                  .value
              ) + parseFloat(profitMarginCost)
              : parseFloat(
                selectedItems[category][selectedItemsNewIndex]["saleAmount"]
                  .value
              );
        }
      }
      /*CON-200 Material total Value= qty * saleamount */
      selectedItemsList[index]["total"].value =
        (parseInt(selectedItemsList[index]["qty"].value) || 0) *
        parseFloat(selectedItemsList[index]["saleAmount"].value);
      selectedItems[category][selectedItemsNewIndex]["total"].value =
        (parseFloat(
          selectedItems[category][selectedItemsNewIndex]["qty"].value
        ) || 0) *
        parseFloat(
          selectedItems[category][selectedItemsNewIndex]["saleAmount"].value
        );
    }
    if (this.props.isProjectPO) {
      this.props.setSelectedItems(
        selectedItemsList,
        null,
        this.props.subTaskArrayIndex
      );
    } else {
      this.props.setSelectedItems(
        selectedItems,
        null,
        this.props.subTaskArrayIndex
      );
    }
    this.setState({ selectedItems, selectedItemsList });
    if (this.props.calculateMaterialCost) this.props.calculateMaterialCost(this.props.subTaskArrayIndex);
  };

  removeItem = (material, index, remove) => {
    const { selectedItems, categoryName, selectedItemsList } = this.state;

    const selectedItemsIndex = selectedItems[material.categoryId].findIndex(
      (item) => item.id === material.id
    );
    if (selectedItemsIndex !== -1) {
      selectedItems[material.categoryId][selectedItemsIndex]["qty"].value = "0";
    }

    _.remove(selectedItemsList, (item) => item.id === material.id);
    _.remove(
      selectedItems[material.categoryId],
      (item) => item.id === material.id
    );

    // if (selectedItems[material.categoryId] && selectedItems[material.categoryId].length === 0) {
    //   _.remove(categoryName, (item) => item.id === material.categoryId);
    //   this.props.setCategoryName(categoryName);

    // }
    this.props.setSelectedItems(
      selectedItems,
      null,
      this.props.subTaskArrayIndex
    );
    this.setState({ selectedItems, categoryName, selectedItemsList });
  };
  AddItemDialogHandler = (item = "", categoryId = "") => {
    this.setState((prevState) => ({
      addItemDialogState: !prevState.addItemDialogState,
      categoryId: categoryId,
      itemName: item,
    }));
  };

  AddItemDialogClose = (res = {}, inlineItem = false) => {
    if (!_.isEmpty(res) && !inlineItem) {
      const { itemsList } = this.state;
      res.categoryId = res.category_id;
      itemsList.push(res);
      this.setState({ itemsList });
      this.addItem(res.id, res.categoryId);
    } else if (!_.isEmpty(res) && inlineItem) {
      const { categoryList, selectedItems, categoryName } = this.state;
      let value = categoryList.find(
        (category) => category.value === res.categoryId
      );
      value = value.value;
      const categoryExist = categoryName.filter(
        (category) => category.id === value
      );
      if (categoryExist.length === 0) {
        let category = value;
        categoryName.push({
          id: value,
          name: this.filterCategory(value) && this.filterCategory(value).name,
        });
        selectedItems[category] = [];
        this.props.setCategoryName(categoryName);
        this.props.setSelectedItems(
          selectedItems,
          null,
          this.props.subTaskArrayIndex
        );
        this.setState({ selectedItems, categoryName });
        this.addItem(res.item, value, res);
      } else {
        this.addItem(res.item, value, res);
      }
    }
    this.setState({ addItemDialogState: false, isInlineItem: false });
  };

  hideAddItem = () => {
    this.setState({ showAddItem: false });
    this.setState({ filterValue: {} });
  };

  showAddItem = () => {
    if (this.state.filterValue.noresult) {
      if (
        !this.state.checked &&
        this.state.filterValue.catalogid &&
        !this.state.filterValue.id
      ) {
        return this.props.errorSnackBar("Please select an Item");
      }
      if (this.state.checked && !this.state.filterValue.catalogid) {
        return this.props.errorSnackBar("Please select a Catalogue");
      }
      if (this.state.checked && this.state.filterValue.catalogid) {
        this.submitItemForm(this.state.filterValue);
      }
      if (!this.state.checked && !this.state.filterValue.catalogid) {
        this.AddInlineItem(this.state.filterValue);
        this.props.successSnackBar("Task Item Added Successfully");
      }
    }
    const newFilterValue = {};
    const newTotalValue = 0;
    const newUnitValue = "";

    this.setState({
      showAddItem: true,
      filterValue: newFilterValue,
      newtotalvalue: newTotalValue,
      newunitvalue: newUnitValue,
    });
  };

  handleInlineItem = () => {
    this.setState((prevState) => ({
      addItemDialogState: !prevState.addItemDialogState,
      isInlineItem: true,
    }));
  };
  lockMaterialIconChange = () => {
    const { showMaterialLockIcon } = this.state;

    this.setState({ showMaterialLockIcon: !showMaterialLockIcon });
  };
  AddInlineItem = (data) => {
    if (data.noresult) {
      const { selectedItems, item, categoryName, selectedItemsList } =
        this.state;
      const itemField = JSON.parse(JSON.stringify(item));
      itemField.name.value = data.item;
      itemField.id = uuidv4();
      itemField.manufacturer = data.manufacturer;
      itemField.partNo = data.partNo;
      itemField.categoryId = "INLINE_ITEM";
      itemField.costPerItem.value = data.cost ? data.cost : data.costPerItem;
      itemField.unit.value = data.unit || null;
      itemField.saleAmount.value = data.saleAmount || 0;
      itemField.saleAmountLock.value = data.saleAmountLock || true;
      itemField.qty.value = parseFloat(data.qty || 1);
      itemField.total.value =
        parseFloat(data.cost ? data.cost : data.costPerItem) *
        parseFloat(data.qty || 1);
      itemField.isInlineItem = true;
      itemField.newItem = false;
      if (
        !categoryName.filter((category) => category.id === "INLINE_ITEM").length
      ) {
        selectedItems["INLINE_ITEM"] = [];
        categoryName.push({ id: "INLINE_ITEM", name: "INLINE_ITEM" });
        selectedItems["INLINE_ITEM"] = [];
        this.props.setCategoryName(categoryName);
      }
      this.props.setSelectedItems(
        selectedItems,
        null,
        this.props.subTaskArrayIndex
      );
      selectedItems["INLINE_ITEM"].push(itemField);
      selectedItemsList.push(itemField);
      this.setState({ selectedItems, selectedItemsList }, () =>
        this.hideAddItem()
      );
      this.setState({ showAddItem: false });
    }
    this.setState({ checked: true });
  };

  submitForm = () => {
    const { selectedItemsList } = this.state;
    const { isSkippable = false } = this.props;

    if (
      !isSkippable &&
      Array.isArray(selectedItemsList) &&
      !selectedItemsList.length
    ) {
      this.props.warningSnackBar("Select task and item to Generate PO!");
    } else if (Array.isArray(selectedItemsList)) {
      let isFormValid = true;
      selectedItemsList.map((_item) => {
        if (!_item.taskId) {
          _item.task.valid = false;
          _item.task.error = "Error!";
          isFormValid = isFormValid && false;
        }

        const { error: cpiError, valid: cpiValid } = this.handleValidation(
          _item.costPerItem
        );
        _item.costPerItem["error"] = cpiError;
        _item.costPerItem["valid"] = cpiValid;
        _item.costPerItem.touched = true;
        isFormValid = isFormValid && cpiValid;

        const { error: qtyError, valid: qtyValid } = this.handleValidation(
          _item.qty
        );
        _item.qty.error = qtyError;
        _item.qty.valid = qtyValid;
        _item.qty.touched = true;
        isFormValid = isFormValid && qtyValid;
      });
      if (!isFormValid) {
        this.setState({ selectedItemsList: selectedItemsList });
      } else {
        this.props.submitForm({ selectedItems: selectedItemsList });
      }
    }
  };

  handleValidation = ({ type, value }) => {
    let error = false;
    let valid = true;

    if (type === "number") {
      if (isNaN(value) || value <= 0) {
        return { error: "Error!", valid: false };
      }
    }
    return { error, valid };
  };
  submitItemForm = (filterValue) => {
    const data = {
      categoryId: this.state.filterValue["catalogid"],
      item: this.state.filterValue["item"],
      cost: this.state.filterValue["costPerItem"],
      saleAmount: this.state.filterValue["saleAmount"] || 0,
      saleAmountLock: this.state.filterValue["saleAmountLock"] || true,
      manufacturer: this.state.filterValue["manufacturer"],
      partNo: this.state.filterValue["partNo"],
      unit: this.state.filterValue["unit"] || null,
    };
    this.props.addItem(data).then((res) => {
      const newitemid = res.id;
      this.setState({ newitemid });
      if (res.id) {
        if (filterValue.noresult) {
          const category = filterValue.catalogid;
          const itemField = JSON.parse(JSON.stringify(this.state.item));
          const { selectedItems, selectedItemsList, categoryName } = this.state;
          itemField.name.value = filterValue.item;
          itemField.id = filterValue.id || uuidv4();
          itemField.manufacturer = filterValue.manufacturer;
          itemField.partNo = filterValue.partNo;
          itemField.categoryId = filterValue.catalogid;
          itemField.saleAmount.value = filterValue.saleAmount || 0;
          itemField.saleAmountLock.value = filterValue.saleAmountLock || true;
          itemField.costPerItem.value = filterValue.costPerItem;
          itemField.unit.value = filterValue.unit || null;
          itemField.qty.value = filterValue.qty;
          itemField.total.value =
            parseInt(filterValue.qty) * parseFloat(filterValue.costPerItem);
          itemField.newItem = true;
          itemField.isInlineItem = false;
          const categoryExist = categoryName.filter(
            (category) => category.id === this.state.filterValue["catalogid"]
          );
          if (categoryExist.length === 0) {
            categoryName.push({ id: category, name: filterValue.catalog });
            selectedItems[category] = [];
            this.props.setCategoryName(categoryName);
            this.props.setSelectedItems(
              selectedItems,
              null,
              this.props.subTaskArrayIndex
            );
            selectedItems[category].push(itemField);
            selectedItemsList.push(itemField);
            this.setState({ selectedItems, selectedItemsList });

            this.setState({ showAddItem: false });
            if (this.props.calculateMaterialCost) this.props.calculateMaterialCost(this.props.subTaskArrayIndex);
            this.setState({ filterValue: {} });
          }
        }
      }
    });
  };

  render() {
    const {
      selectedItemsList,
      categoryName,
      categories,
      showAddItem,
      showMaterialLockIcon,
      newtotalvalue,
      filterValue,
    } = this.state;

    let Sno = 0;
    return (
      <>
        <Stack className="add-material">
          <Stack className="list-title">
            <Typography variant="h6" className="po-material-header">
              Material List
            </Typography>
            <div className="user-list-table table-responsive">
              <Table>
                <TableHead>
                  <TableRow className="po-material-tablehead">
                    {this.props.isProjectPO && (
                      <TableCell>Select Task</TableCell>
                    )}
                    <TableCell>Catalog</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Part No</TableCell>
                    <TableCell>Items</TableCell>
                    {/* <TableCell>Average Cost</TableCell> */}

                    <TableCell>Unit Cost($)</TableCell>
                    {!this.props.isProjectPO && (
                      <TableCell>Sale Amount($)</TableCell>
                    )}
                    <TableCell>Quantity</TableCell>
                    <TableCell>Total ($)</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedItemsList && selectedItemsList.length > 0 ? (
                    selectedItemsList.map((material, index) => {
                      let category = categoryName.find(
                        (item) => item.id === material.categoryId
                      );
                      let totalMaterialValue = material.costPerItem.value * material.qty.value;
                      Sno = Sno + 1;
                      return (
                        <TableRow key={index}>
                          {this.props.isProjectPO && (
                            <TableCell>
                              <Input
                                label={"Select Task"}
                                value={material.taskId}
                                {...material.taskId}
                                touched={true}
                                type={"select"}
                                items={this.props.tasksList}
                                simple
                                onChange={(e) =>
                                  this.itemChangeHandler(e, category.id, index)
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            {material.isInlineItem
                              ? "NON CATALOG"
                              : category?.name}
                          </TableCell>
                          <TableCell>{material.manufacturer}</TableCell>
                          <TableCell>{material.partNo}</TableCell>
                          <TableCell>
                            {material.name.value ? material.name.value : ""}
                          </TableCell>
                          <TableCell>
                            <Input
                              {...material.costPerItem}
                              simple
                              onChange={(e) =>
                                this.itemChangeHandler(e, category.id, index)
                              }
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          {!this.props.isProjectPO && (
                            <>
                              <TableCell className="Lock-Icon-InputStyle-header">
                                <div className="Lock-Icon-InputStyle-header-cell">
                                  <Input
                                    disable={
                                      typeof material.saleAmountLock ===
                                        "object" &&
                                        material.saleAmountLock.value
                                        ? true
                                        : false
                                    }
                                    {...material.saleAmount}
                                    simple
                                    onChange={(e) =>
                                      this.itemChangeHandler(
                                        e,
                                        category.id,
                                        index
                                      )
                                    }
                                    inputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    className={
                                      material.saleAmountLock.value
                                        ? "Lock-Icon-InputStyle"
                                        : "Lock-Icon-InputStyle Lock-OpenIcon-InputStyle"
                                    }
                                  />
                                  {material.saleAmountLock.value && (
                                    <span
                                      className="Lock-Icon-style"
                                      onClick={(e) =>
                                        this.itemChangeHandler(
                                          "saleAmountLock",
                                          category.id,
                                          index
                                        )
                                      }
                                    >
                                      <LockIcon color="primary"></LockIcon>
                                    </span>
                                  )}
                                  {!material.saleAmountLock.value && (
                                    <span
                                      className="Lock-Icon-style"
                                      onClick={(e) =>
                                        this.itemChangeHandler(
                                          "saleAmountLock",
                                          category.id,
                                          index
                                        )
                                      }
                                    >
                                      <LockOpenIcon color="danger"></LockOpenIcon>
                                    </span>
                                  )}
                                </div>
                              </TableCell>{" "}
                            </>
                          )}

                          <TableCell>
                            <Input
                              {...material.qty}
                              simple
                              onChange={(e) =>
                                this.itemChangeHandler(e, category.id, index)
                              }
                            />
                          </TableCell>

                          {this.props.isProjectPO && (
                            <TableCell>
                              $
                              {(totalMaterialValue
                                ? parseFloat(totalMaterialValue).toFixed(2)
                                : parseFloat(totalMaterialValue).toFixed(2)) ||
                                0}
                            </TableCell>
                          )}

                          {!this.props.isProjectPO && (
                            <TableCell>
                              $
                              {(!material.total.value &&
                                material.total.value == 0
                                ? parseFloat(material.saleAmount.value).toFixed(
                                  2
                                )
                                : parseFloat(material.total.value).toFixed(
                                  2
                                )) || 0}
                            </TableCell>
                          )}

                          <TableCell>
                            <img
                              src={deleteIcon}
                              alt="edit"
                              onClick={() => this.removeItem(material, index)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                  {!this.props.isProjectPO && (
                    <TableRow>
                      <TableCell colSpan={4}></TableCell>
                      <TableCell
                        style={{ textAlign: "left", paddingLeft: "30px" }}
                      ></TableCell>

                      <TableCell style={{ textAlign: "right" }} colSpan={2}>
                        Subtotal
                      </TableCell>
                      <TableCell>
                        $
                        {(isNaN(parseFloat(this.props.materialTotal_SaleAmount))
                          ? 0
                          : parseFloat(
                            this.props.materialTotal_SaleAmount
                          ).toFixed(2)) || 0}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {!showAddItem ? (
              <>
                <Typography
                  style={{ margin: "15px" }}
                  variant="caption"
                  className="assign-user"
                  onClick={this.showAddItem}
                >
                  + Add Material
                </Typography>
              </>
            ) : (
              ""
            )}
          </Stack>
          <Stack>
            {showAddItem ? (
              <Stack id="add-material" className="add">
                <Typography className="po-material-header" variant="h6">
                  Add Material
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid container className="addMaterialSection">
                      <Grid item xs={12} sm={4} md={4} lg={6}>
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                            } else if (newValue && newValue.inputValue) {
                            } else if (newValue) {
                              this.addFilter(newValue.key, "catalog");
                              this.addFilter(newValue.value, "catalogid");
                            } else {
                              this.addFilter("", "catalog");
                            }
                          }}
                          filterOptions={(options, params) => {
                            return filter(options, params);
                          }}
                          id="free-solo-dialog-demo-catalog"
                          options={categories.items}
                          getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            return option.key;
                          }}
                          onInputChange={(event, newValue) => {
                            this.addFilter(newValue.key, "catalog");
                            this.addFilter(newValue.value, "catalogid");
                          }}
                          selectOnFocus
                          handleHomeEndKeys
                          renderOption={(option) => option.key}
                          freeSolo
                          margin="dense"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="dense"
                              label="Catalog"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={6}>
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                            } else if (newValue && newValue.inputValue) {
                            } else if (newValue) {
                              this.addFilter(newValue.value, "manufacturer");
                            } else {
                              this.addFilter("", "manufacturer");
                            }
                          }}
                          filterOptions={(options, params) => {
                            return filter(options, params);
                          }}
                          id="free-solo-dialog-demo-manufacture"
                          options={this.manufacturerDropDown()}
                          getOptionLabel={(option) =>
                            option.key ? option.key : ""
                          }
                          getOptionSelected={(option, value) => {
                            //nothing that is put in here will cause the warning to go away
                            if (value === "") {
                              return true;
                            } else if (value === option.key) {
                              return true;
                            }
                          }}
                          onInputChange={(event, newValue) => {
                            this.addFilter(newValue, "manufacturer");
                          }}
                          selectOnFocus
                          //clearOnBlur
                          handleHomeEndKeys
                          renderOption={(option) => option.key}
                          freeSolo
                          margin="dense"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="dense"
                              label="Manufacturer."
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3}>
                        <TextField
                          margin="dense"
                          label="Part No."
                          variant="filled"
                          onChange={(e) => {
                            if (e.target.value && e.target.value.length > 2) {
                              this.addFilter(e.target.value, "partNo");
                            } else {
                              this.addFilter("", "partNo");
                            }
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={9}
                        className="addMaterialSection"
                      >
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                            } else if (newValue && newValue.inputValue) {
                              this.AddItemDialogHandler();
                            } else if (newValue) {
                              this.addItem(newValue.value);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== "") {
                              filtered.push({
                                inputValue: params.inputValue,
                                key: `Add "${params.inputValue}"`,
                              });
                            }
                            return filtered;
                          }}
                          id="free-solo-dialog-demo"
                          options={this.itemsDropDownOption(false)}
                          getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.key;
                          }}
                          onInputChange={(event, newValue) => {
                            this.addFilter(newValue, "item");
                          }}
                          selectOnFocus
                          //clearOnBlur
                          handleHomeEndKeys
                          renderOption={(option) => option.key}
                          // style={{ width: 110 }}
                          freeSolo
                          margin="dense"
                          renderInput={(params) => (
                            <div className="material-textarea">
                              <TextField
                                {...params}
                                margin="dense"
                                label="Add items"
                                row={6}
                              />
                            </div>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        className="addMaterialSection"
                      >
                        <TextField
                          type="number"
                          margin="dense"
                          variant="filled"
                          label="UnitCost"
                          onChange={(e) => {
                            if (e.target.value) {
                              this.addFilter(e.target.value, "costPerItem");
                            }
                          }}
                        />
                      </Grid>
                      {!this.props.isProjectPO && (
                        <>
                          {" "}
                          <Grid
                            className="Lock-Icon-InputStyle-header sub"
                            style={{ marginTop: " 12px" }}
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={3}
                          >
                            <Input
                              disable={showMaterialLockIcon ? true : false}
                              type="number"
                              margin="dense"
                              label="Sale Amount"
                              variant="filled"
                              value={filterValue["saleAmount"]}
                              className={
                                showMaterialLockIcon
                                  ? "Lock-Icon-InputStyle"
                                  : "Lock-Icon-InputStyle Lock-OpenIcon-InputStyle"
                              }
                              inputProps={{ min: 0 }}
                              onChange={(e) => {
                                if (e.target.value) {
                                  this.addFilter(e.target.value, "saleAmount");
                                }
                              }}
                            />
                            {showMaterialLockIcon && (
                              <span
                                className="Lock-Icon-style"
                                style={{ margin: " 0 15px 0 0 " }}
                                onClick={() => this.lockMaterialIconChange()}
                              >
                                <LockIcon color="primary"></LockIcon>
                              </span>
                            )}
                            {!showMaterialLockIcon && (
                              <span
                                className="Lock-Icon-style"
                                style={{ margin: " 0 15px 0 0 " }}
                                onClick={() => this.lockMaterialIconChange()}
                              >
                                <LockOpenIcon color="danger"></LockOpenIcon>
                              </span>
                            )}
                          </Grid>{" "}
                        </>
                      )}

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                          type="number"
                          margin="dense"
                          variant="filled"
                          label="Quantity"
                          onChange={(e) => {
                            if (e.target.value) {
                              this.addFilter(e.target.value, "qty");
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                          id="outlined-name"
                          label="Total"
                          value={newtotalvalue}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {filterValue?.noresult && (
                  <>
                    {" "}
                    <Stack className="add-btns-row" direction="row" spacing={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.hideAddItem()}
                      >
                        RESET
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          this.submitItemForm(this.state.filterValue)
                        }
                      >
                        ADD
                      </Button>
                    </Stack>{" "}
                  </>
                )}
              </Stack>
            ) : null}

            {this.props.isProjectPO && (
              <Grid container className="buttonContainer">
                <Grid xs={12} item>
                  <Buttons
                    className="cancel-btn"
                    title={"Save"}
                    onClick={this.submitForm}
                  />
                  <p className="cancel-btn" onClick={this.props.viewPoList}>
                    Cancel
                  </p>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Stack>
        <ProfitMarginLock
          open={this.state.profitMarginDialogState}
          lockIconChange={() => this.setConfirmed()}
          dialogClose={this.profitMarginDialogHandler}
          moduleName="Task"
          modalHeading={"Sale Amount Lock changes"}
          text={"sale Amount"}
          message={'Are you sure you want to change the Project profit margin? - Any items using default profit margins will also be updated to this value'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getItems: bindActionCreators(getItems, dispatch),
  addItem: bindActionCreators(addItem, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  successSnackBar: bindActionCreators(successSnackBar, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  getUnits: bindActionCreators(getUnits, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMaterial);
