import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import idx from "idx";
import moment from "moment";
import {
  warningSnackBar,
  errorSnackBar,
} from "../../../../actions/common.action";
import { addProgressClaim } from "../../../../actions/project-management.action";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./budget.scss";
import { numberFormat } from "../../../../util/currency-formatter";
import { getLaborCost } from "../../../../actions/dashboard.action";
import {
  getProject,
  getOptions,
  getProgressClaimList,
  getProgressClaimById,
  editProgressClaim,
  getAllProgressClaimList,
} from "../../../../actions/project-management.action";
import { getLevel } from "../../../../actions/inventory.action";
import { PROJECT_MANAGEMENT } from "./../../../../constants/route-paths";
import { withRouter } from "../../../../util/with-router";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import "../../../project-management/scss/task.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "react-bootstrap/Collapse";
import DatePickerInput from "src/components/inputs/date-picker";
import { AmountInput } from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";

class CreateBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open2: false,
      open3: false,
      tasks: [],
      variations: [],
      description: {
        name: "description",
        label: "Description",
        type: "textarea",
        touched: false,
        error: false,
        valid: true,
        value: "",
        open: {},
      },
      retentionClaimAmount: {
        name: "retentionInput",
        label: "",
        type: "number",
        touched: true,
        error: false,
        valid: true,
        value: "",
        inputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        },
      },
      retentionPCAmount: {
        name: "retentionPCAmount",
        label: "",
        type: "number",
        touched: true,
        error: false,
        valid: true,
        value: "",
        inputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        },
      },
      dueDate: {
        validation: ["required"],
      },
      invoiceDate: {
        validation: ["required"],
      },
      projectId: "",
      customerPaymentTermDays: 0,
      projectQuoteType: "",
      progressClaims: [],
      open: {},
      paramsProjectId: null,
      progressClaimId: null,
    };
  }

  async componentDidMount() {
    const paramsProjectId = this.props.match
      ? this.props.params.projectId
      : null;
    const progressClaimId = this.props.match
      ? this.props.params.progressClaimId
      : null;
    const {
      projectId,
      selectedTask,
      progressClaims,
      stages,
      hasStages,
      optionData,
    } = this.props;
    let {
      open,
      description,
      retentionClaimAmount,
      retentionPCAmount,
      invoiceDate,
      dueDate,
    } = this.state;

    if (paramsProjectId) {
      this.getProject(paramsProjectId);
    }

    Array.isArray(selectedTask) &&
      selectedTask.map((task) => (open[task.id] = false));
    if (progressClaimId) {
      const query = `?id=${progressClaimId}`;
      const progressClaimDetails = await this.props.getProgressClaimById(query);
      description.value = progressClaimDetails.description;
      retentionClaimAmount.value = progressClaimDetails.retentionClaimAmount;
      retentionPCAmount.value = progressClaimDetails.retentionAmount;
      invoiceDate = progressClaimDetails.invoiceDate
        ? new Date(progressClaimDetails.invoiceDate)
        : "";
      dueDate = progressClaimDetails.dueDate
        ? new Date(progressClaimDetails.dueDate)
        : "";
    } else {
      dueDate = new Date();
      invoiceDate = new Date();
    }
    this.sumRetentionAmount(paramsProjectId, progressClaimId);
    this.setState(
      {
        projectId,
        progressClaims,
        open,
        stages,
        optionData,
        hasStages,
        paramsProjectId,
        progressClaimId,
        description,
        retentionClaimAmount,
        retentionPCAmount,
        invoiceDate,
        dueDate,
      },
      () => this.calculateTotal(selectedTask)
    );
  }

  getProject = async (projectId) => {
    let optionData = "",
      projectData = {},
      selectedTask = [],
      sortedtempArray = [],
      customerPaymentTermDays = 0;
    const progressClaimIdexist = this.props.match
      ? this.props.params.progressClaimId
      : null;
    let dueDate = this.state;
    let projectQuoteType = this.state;
    if (projectId) {
      const project = await this.props.getProject(projectId);
      optionData = await this.props.getOptions(projectId);
      await this.getLaborCost(projectId);
      await this.getProgressClaimList(projectId);
      await this.getLevels(project.level);
      projectData = {
        projectId: project.id,
        projectName: project.name,
        projectDescription: project.description,
        customerName: project.customer && project.customer.businessName,
        hasStages: project.hasStages,
        projectType: project.type,
        projectQuoteType: project.quoteType,
        customerLevel: project.customer ? project.customer.level : "",
        levelId: project.level,
        projectStages: project.stages,
        projectDataType: project.projectType,
      };

      projectQuoteType = project.quoteType;
      this.setState({ projectQuoteType });

      customerPaymentTermDays = project.customer
        ? project.customer.paymentTerms
        : 0;
      project.stages &&
        Array.isArray(project.stages) &&
        project.stages.map((stage) => {
          stage &&
            Array.isArray(stage.tasks) &&
            stage.tasks.map((task) => {
              task.allocatedMaterialCost =
                idx(task, (_task) => task.materialTotal_SaleAmount) || 0;
              task.allocatedLaborCost =
                idx(task, (_task) => task.laborTotal_SaleAmount) || 0;
              task.allocatedSubContractorCost =
                idx(task, (_task) => task.subcontractorTotal_SaleAmount) || 0;
              task.allocatedProfitMarginCost =
                idx(task, (_task) => task.profitMarginCost) || 0;
              task.remainingPercentage = 0;
              task.remainingAmount = 0;
              task.claimRemainingAmount = false;
              selectedTask.push({ ...task, type: "TASK" });
              task &&
                Array.isArray(task.subTasks) &&
                task.subTasks.map((subTask) => {
                  subTask.allocatedMaterialCost =
                    idx(
                      subTask,
                      (_subTask) => subTask.materialTotal_SaleAmount
                    ) || 0;
                  subTask.allocatedLaborCost =
                    idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) ||
                    0;
                  subTask.allocatedSubContractorCost =
                    idx(
                      subTask,
                      (_subTask) => subTask.subcontractorTotal_SaleAmount
                    ) || 0;
                  subTask.allocatedProfitMarginCost =
                    idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
                  subTask.remainingPercentage = 0;
                  subTask.remainingAmount = 0;
                  subTask.claimRemainingAmount = false;
                  selectedTask.push({ ...subTask, type: "SUBTASK" });
                });
            });
        });
      let tempArray = [];
      optionData.forEach((option) => {
        option.revisionNumber =
          option.taskRevision && option.taskRevision[0].RevisionNumber;
        option.revisionId =
          option.taskRevision && option.taskRevision[0].RevisionId;
        option.revisionName =
          option.taskRevision && option.taskRevision[0].revisionName;
        option.subTasks = option.subTasks ? option.subTasks : [];
        tempArray.push(option);
      });
      sortedtempArray = _.chain(tempArray)
        .groupBy("revisionId")
        .map((revisions, revisionId) => {
          const sortedRevisions =
            _.sortBy(revisions, [(o) => new Date(o.createdAt || null)]) || [];
          return { revisionId, revisions: sortedRevisions };
        })
        .sortBy((o) => o.revisions[0].createdAt)
        .map((data) => data.revisions)
        .value()
        .flat(1);
      const uniqueIdArr = _.uniqBy(sortedtempArray, "revisionId");
      sortedtempArray &&
        Array.isArray(sortedtempArray) &&
        sortedtempArray.map((option, optionIndex) => {
          let index = _.findIndex(uniqueIdArr, function (item) {
            return item.id === option.id;
          });
          if (index !== -1) {
            option.revision = true;
          }
          option.variationNo = this.generateVariationNumber(optionIndex + 1, 3);
          if (
            option.proposal_status === "APPROVED" &&
            option.proposal_selected === true
          ) {
            option.calculatedProfitMargin = option.profitMarginCost;
            option.remainingPercentage = 0;
            option.remainingAmount = 0;
            option.claimRemainingAmount = false;

            selectedTask.push({ ...option, type: "VARIATION" });
            option &&
              Array.isArray(option.subTasks) &&
              option.subTasks.map((subTask) => {
                subTask.allocatedMaterialCost =
                  idx(
                    subTask,
                    (_subTask) => subTask.materialTotal_SaleAmount
                  ) || 0;
                subTask.allocatedLaborCost =
                  idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) ||
                  0;
                subTask.allocatedSubContractorCost =
                  idx(
                    subTask,
                    (_subTask) => subTask.subcontractorTotal_SaleAmount
                  ) || 0;
                subTask.allocatedProfitMarginCost =
                  idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
                subTask.remainingPercentage = 0;
                subTask.remainingAmount = 0;
                subTask.claimRemainingAmount = false;
                selectedTask.push({ ...subTask, type: "SUBTASK" });
              });
          }
        });
      this.setState({ selectedTask }, () =>
        this.projectCalculation(project.stages, optionData)
      );
      const { progressClaims, stages, hasStages } = this.state;
      this.setState(
        { projectId, progressClaims, stages, optionData, hasStages },
        () => this.calculateTotal(selectedTask)
      );
    }

    if (!progressClaimIdexist) {
      dueDate = new Date();
      dueDate = dueDate.setDate(
        dueDate.getDate() +
        (customerPaymentTermDays ? customerPaymentTermDays : 0)
      );
      this.setState({ dueDate });
    }
    this.setState({
      ...projectData,
      optionsData: sortedtempArray,
    });
  };

  getLaborCost = async (projectId) => {
    const laborData = await this.props.getLaborCost({ id: projectId });
    const laborCost = laborData.taskData || {};

    this.setState({ laborCost });
  };

  getProgressClaimList = async (projectId) => {
    const progressClaims = await this.props.getProgressClaimList(projectId);
    this.setState({ progressClaims });
  };

  getLevels = (levelId) => {
    this.props.getLevel(levelId).then((level) => {
      const labors = [];
      level.hourlyCost &&
        level.hourlyCost.forEach((cost) => {
          labors.push({
            ...cost,
            calloutCost: level.calloutCost,
            travelCost: level.travelCost,
          });
        });
      this.setState({ labors });
    });
  };

  calculateActualBudget = (task = {}, taskType, laborCost) => {
    let actualProfitMarginCost = 0,
      profitMarginCost = 0;
    const actualMaterialCost =
      (Array.isArray(task.materialPoCost) &&
        task.materialPoCost.reduce((acc, _material) => {
          let materialBudget = 0;
          if (_material && _material.budget && "PO" in _material.budget) {
            materialBudget +=
              (Array.isArray(_material.budget["PO"]) &&
                _material.budget["PO"].reduce(
                  (acc, poBudget) => acc + parseFloat(poBudget.amountPaid),
                  0
                )) ||
              0;
          }
          if (_material && _material.budget && "WPO" in _material.budget) {
            materialBudget +=
              (Array.isArray(_material.budget["WPO"]) &&
                _material.budget["WPO"].reduce(
                  (acc, poBudget) => acc + parseFloat(poBudget.amountPaid),
                  0
                )) ||
              0;
          }
          return acc + materialBudget;
        }, 0)?.toFixed(2)) || 0;
    const actualLaborCost =
      idx(laborCost, (_laborCost) => _laborCost[task.id]) || 0;
    const actualSubContractorCost =
      (Array.isArray(task.subContractorInvoiceCost) &&
        task.subContractorInvoiceCost.reduce((acc, _invoices) => {
          return acc + parseFloat(_invoices.amountPaid || 0);
        }, 0)?.toFixed(2)) || 0;

    actualProfitMarginCost += parseFloat(task.profitMarginCost || 0);
    profitMarginCost += parseFloat(task.profitMarginCost || 0);

    return {
      actualMaterialCost,
      actualLaborCost,
      actualSubContractorCost,
      actualProfitMarginCost,
      profitMarginCost,
    };
  };

  generateVariationNumber = (n, width, z) => {
    z = z || "0";
    n = n + "";
    return n.length >= width
      ? `V-${n}`
      : `V-${new Array(width - n.length + 1).join(z) + n}`;
  };

  projectCalculation = (stages, optionData) => {
    let { laborCost, selectedTask } = this.state;
    let projectSummary = {
      contractedPrice: 0,
      reviedContractedPrice: 0,
      actualCost: 0,
    };
    stages &&
      Array.isArray(stages) &&
      stages.map((stage) => {
        stage &&
          Array.isArray(stage.tasks) &&
          stage.tasks.map((task) => {
            let calculatedPercentageComplete = 0;
            task.allocatedMaterialCost =
              idx(task, (_task) => task.materialTotal_SaleAmount) || 0;
            task.allocatedLaborCost =
              idx(task, (_task) => task.laborTotal_SaleAmount) || 0;
            task.allocatedSubContractorCost =
              idx(task, (_task) => task.subcontractorTotal_SaleAmount) || 0;
            const {
              actualMaterialCost,
              actualLaborCost,
              actualSubContractorCost,
              actualProfitMarginCost,
            } = this.calculateActualBudget(task, "taskHistory", laborCost);
            task.allocatedProfitMarginCost =
              idx(task, (_task) => task.profitMarginCost) || 0;
            task.actualMaterialCost = actualMaterialCost;
            task.actualLaborCost = actualLaborCost;
            task.actualSubContractorCost = actualSubContractorCost;
            task.actualProfitMarginCost = actualProfitMarginCost;
            task.calculatedPercentageComplete = task.percentageComplete;
            if (selectedTask.findIndex((t) => t.id === task.id) !== -1) {
              projectSummary.contractedPrice += task.taskHistory[0]
                ? (parseFloat(task.taskHistory[0].materialTotal_SaleAmount) ||
                  0) +
                (parseFloat(task.taskHistory[0].laborTotal_SaleAmount) || 0) +
                (parseFloat(
                  task.taskHistory[0].subcontractorTotal_SaleAmount
                ) || 0)
                : 0;
              projectSummary.reviedContractedPrice +=
                (parseFloat(task.materialTotal_SaleAmount) || 0) +
                (parseFloat(task.laborCost) || 0) +
                (parseFloat(task.subContractorCost) || 0) +
                (parseFloat(task.profitMarginCost) || 0);
              projectSummary.actualCost +=
                (parseFloat(actualMaterialCost) || 0) +
                (parseFloat(actualLaborCost) || 0) +
                (parseFloat(actualSubContractorCost) || 0);
            }
            task &&
              Array.isArray(task.subTasks) &&
              task.subTasks.map((subTask) => {
                subTask.allocatedMaterialCost =
                  idx(
                    subTask,
                    (_subTask) => subTask.materialTotal_SaleAmount
                  ) || 0;
                subTask.allocatedLaborCost =
                  idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) ||
                  0;
                subTask.allocatedSubContractorCost =
                  idx(
                    subTask,
                    (_subTask) => subTask.subcontractorTotal_SaleAmount
                  ) || 0;
                const {
                  actualMaterialCost,
                  actualLaborCost,
                  actualSubContractorCost,
                  actualProfitMarginCost,
                } = this.calculateActualBudget(
                  subTask,
                  "subTaskHistory",
                  laborCost
                );
                subTask.allocatedProfitMarginCost =
                  idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
                subTask.actualMaterialCost = actualMaterialCost;
                subTask.actualLaborCost = actualLaborCost;
                subTask.actualSubContractorCost = actualSubContractorCost;
                subTask.actualProfitMarginCost = actualProfitMarginCost;
                subTask.calculatedPercentageComplete =
                  subTask.percentageComplete;
                calculatedPercentageComplete += parseFloat(
                  subTask.percentageComplete || 0
                );
                if (selectedTask.findIndex((t) => t.id === subTask.id) !== -1) {
                  // projectSummary.contractedPrice += (parseFloat(subTask.allocatedMaterialCost) || 0) + (parseFloat(subTask.allocatedLaborCost) || 0) + (parseFloat(subTask.allocatedSubContractorCost) || 0) + (parseFloat(subTask.allocatedProfitMarginCost) || 0)
                  // projectSummary.reviedContractedPrice += (parseFloat(subTask.materialCost) || 0) + (parseFloat(subTask.laborCost) || 0) + (parseFloat(subTask.subContractorCost) || 0) + (parseFloat(profitMarginCost) || 0)
                  // projectSummary.actualCost += (parseFloat(actualMaterialCost) || 0) + (parseFloat(actualLaborCost) || 0) + (parseFloat(actualSubContractorCost) || 0)
                }
              });
            if (task && Array.isArray(task.subTasks) && task.subTasks.length)
              task.calculatedPercentageComplete =
                calculatedPercentageComplete / task.subTasks.length;
          });
      });

    optionData &&
      Array.isArray(optionData) &&
      optionData.map((option, optionIndex) => {
        let optionMaterials = [],
          optionLabor = [];
        option.material &&
          Array.isArray(option.material) &&
          option.material.map((item = {}, index) => {
            const materialData = (_.isString(item) && JSON.parse(item)) || item;
            optionMaterials.push(materialData);
          });
        option.labor &&
          Array.isArray(option.labor) &&
          option.labor.map((item = {}, index) => {
            const laborData = (_.isString(item) && JSON.parse(item)) || item;
            optionLabor.push(laborData);
          });
        let calculatedPercentageComplete = 0;
        option.material = optionMaterials;
        option.actualMaterialCost = 0;
        option.actualLaborCost = 0;
        option.actualSubContractorCost = 0;
        option.actualProfitMargin = option.profitMarginCost;
        option.calculatedProfitMargin = option.profitMarginCost;
        if (
          option.proposal_status === "APPROVED" &&
          selectedTask.findIndex((t) => t.id === option.id) !== -1
        ) {
          projectSummary.reviedContractedPrice +=
            (parseFloat(option.materialTotal_SaleAmount) || 0) +
            (parseFloat(option.laborTotal_SaleAmount) || 0) +
            (parseFloat(option.subcontractorTotal_SaleAmount) || 0);
        }

        option.proposal_status === "APPROVED" &&
          Array.isArray(option.subTasks) &&
          option.subTasks.map((subTask) => {
            subTask.allocatedMaterialCost =
              idx(subTask, (_subTask) => subTask.materialTotal_SaleAmount) || 0;
            subTask.allocatedLaborCost =
              idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) || 0;
            subTask.allocatedSubContractorCost =
              idx(
                subTask,
                (_subTask) => subTask.subcontractorTotal_SaleAmount
              ) || 0;
            const {
              actualMaterialCost,
              actualLaborCost,
              actualSubContractorCost,
              actualProfitMarginCost,
            } = this.calculateActualBudget(
              subTask,
              "subTaskHistory",
              laborCost
            );
            subTask.allocatedProfitMarginCost =
              idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
            subTask.actualMaterialCost = actualMaterialCost;
            subTask.actualLaborCost = actualLaborCost;
            subTask.actualSubContractorCost = actualSubContractorCost;
            subTask.actualProfitMarginCost = actualProfitMarginCost;
            subTask.calculatedPercentageComplete = subTask.percentageComplete;
            calculatedPercentageComplete += parseFloat(
              subTask.percentageComplete || 0
            );
            if (selectedTask.findIndex((t) => t.id === subTask.id) !== -1) {
              // projectSummary.contractedPrice += (parseFloat(subTask.allocatedMaterialCost) || 0) + (parseFloat(subTask.allocatedLaborCost) || 0) + (parseFloat(subTask.allocatedSubContractorCost) || 0) + (parseFloat(subTask.allocatedProfitMarginCost) || 0)
              // projectSummary.reviedContractedPrice += (parseFloat(subTask.materialCost) || 0) + (parseFloat(subTask.laborCost) || 0) + (parseFloat(subTask.subContractorCost) || 0) + (parseFloat(profitMarginCost) || 0)
              // projectSummary.actualCost += (parseFloat(actualMaterialCost) || 0) + (parseFloat(actualLaborCost) || 0) + (parseFloat(actualSubContractorCost) || 0)
            }
          });
        if (
          option.proposal_status === "APPROVED" &&
          Array.isArray(option.subTasks) &&
          option.subTasks.length
        )
          option.calculatedPercentageComplete =
            calculatedPercentageComplete / option.subTasks.length;
      });
    this.setState({ stages, optionData, projectSummary }, () =>
      this.handleClose()
    );
  };

  handleClose = () => {
    this.setState({
      taskDialogState: false,
      taskData: {},
      stageId: "",
      taskId: "",
      budgetRetentionDialog: false,
      subTaskId: "",
      editSubTask: false,
      editTask: false,
      optionId: "",
    });
  };

  calculateTotal = (selectedTask) => {
    const { progressClaims, progressClaimId } = this.state;
    let previous_total_claim_amount = 0,
      progressClaimTotal = 0,
      variationsTotal = 0,
      retentionTotal = 0,
      previousClaim = 0,
      claimAmount = 0;
    selectedTask &&
      Array.isArray(selectedTask) &&
      selectedTask.map((task) => {
        if (
          task.type === "VARIATION" ||
          task.type === "TASK" ||
          task.type === "SUBTASK"
        ) {
          let prevProgress = [];
          Array.isArray(progressClaims) &&
            progressClaims.map((claim) => {
              if (Array.isArray(claim.progressClaimTask)) {
                const prevProgressTask = claim.progressClaimTask.find(
                  (t) => t.taskId === task.id
                );
                prevProgressTask && prevProgress.push(prevProgressTask);
              }
            });
          const prevCompletionPercentage = task.previousPercentageComplete || 0;
          task.previous_total = 0;
          task.previous_percentageComplete = 0;
          progressClaims &&
            Array.isArray(progressClaims) &&
            progressClaims.map((claim) => {
              const prgclaim = claim.progressClaimTask;
              prgclaim.map((previous_progressclaim) => {
                if (
                  previous_progressclaim.taskId === task.id &&
                  progressClaimId
                ) {
                  task.previous_total = previous_progressclaim.previous_total;
                  task.previous_percentageComplete =
                    previous_progressclaim.previous_percentageComplete;
                  previous_total_claim_amount +=
                    previous_progressclaim.previous_total;
                }
              });
            });
          if (task.type === "VARIATION") {
            let variationTotal =
              (task.materialTotal_SaleAmount
                ? parseFloat(task.materialTotal_SaleAmount)
                : 0) +
              (task.laborTotal_SaleAmount
                ? parseFloat(task.laborTotal_SaleAmount)
                : 0) +
              (task.subcontractorTotal_SaleAmount
                ? parseFloat(task.subcontractorTotal_SaleAmount)
                : 0);

            task.total =
              variationTotal * ((task.percentageComplete || 0) / 100);
            task.remainingAmount =
              variationTotal * ((task.remainingPercentage || 0) / 100);

            progressClaimTotal += task.total;

            variationsTotal += task.total;
            task.newName = `${task.variationNo} ${task.name} ${task.revisionNumber !== 1 ? " - " + task.revisionName : ""
              }`;
            previousClaim +=
              (prevCompletionPercentage *
                ((task.materialTotal_SaleAmount
                  ? parseFloat(task.materialTotal_SaleAmount)
                  : 0) +
                  (task.laborTotal_SaleAmount
                    ? parseFloat(task.laborTotal_SaleAmount)
                    : 0) +
                  (task.subcontractorTotal_SaleAmount
                    ? parseFloat(task.subcontractorTotal_SaleAmount)
                    : 0))) /
              100;
          } else {
            let taskTotal =
              (task.allocatedMaterialCost
                ? parseFloat(task.allocatedMaterialCost)
                : 0) +
              (task.allocatedLaborCost
                ? parseFloat(task.allocatedLaborCost)
                : 0) +
              (task.allocatedSubContractorCost
                ? parseFloat(task.allocatedSubContractorCost)
                : 0);

            task.total = taskTotal * ((task.percentageComplete || 0) / 100);

            task.remainingAmount =
              taskTotal * ((task.remainingPercentage || 0) / 100);

            progressClaimTotal += task.total;

            task.newName = task.type == "TASK" ? task.name : task.description;
            previousClaim +=
              (prevCompletionPercentage *
                ((task.allocatedMaterialCost
                  ? parseFloat(task.allocatedMaterialCost)
                  : 0) +
                  (task.allocatedLaborCost
                    ? parseFloat(task.allocatedLaborCost)
                    : 0) +
                  (task.allocatedSubContractorCost
                    ? parseFloat(task.allocatedSubContractorCost)
                    : 0))) /
              100;
          }
        } else if (task.type === "RETENTION") {
          retentionTotal += task.retentionAmount;
          progressClaimTotal += task.retentionAmount;
          claimAmount += task.retentionAmount;
        }
      });
    progressClaimTotal = parseFloat(progressClaimTotal)?.toFixed(2);
    previousClaim = parseFloat(previousClaim)?.toFixed(2);
    claimAmount = parseFloat(progressClaimTotal - previousClaim)?.toFixed(2);

    this.setState({
      previous_total_claim_amount,
      selectedTask,
      progressClaimTotal,
      variationsTotal,
      retentionTotal,
      claimAmount,
    });
  };

  cancelHandler = () => {
    const { paramsProjectId } = this.state;
    if (paramsProjectId) {
      this.props.history(`/${PROJECT_MANAGEMENT}/invoices/${paramsProjectId}`);
      return false;
    }
    this.props.handleBudgetClick();
  };

  handlePercentageChange = (e, id) => {
    let { selectedTask } = this.state;
    let value = parseFloat(e.target.value);
    const index = selectedTask.findIndex((t) => t.id === id);
    if (
      value > 100 - selectedTask[index].previousPercentageComplete ||
      value < 0
    ) {
      return;
    }
    selectedTask[index].remainingPercentage = value;
    selectedTask[index].percentageComplete = selectedTask[index].previousPercentageComplete + selectedTask[index].remainingPercentage;
    this.calculateTotal(selectedTask);
  };

  generateProgressClaim = async () => {
    const {
      selectedTask = [],
      projectId,
      description,
      progressClaimTotal,
      progressClaimId,
      retentionClaimAmount,
      retentionPCAmount,
      invoiceDate,
      dueDate,
    } = this.state;
    if (!description.value || (description.value || "").length < 2) {
      description.touched = true;
      description.valid = false;
      description.error = !description.value
        ? "Please fill description"
        : "Description should be atleast 2 characters long";
      this.setState({ description });
      return;
    }
    if (!selectedTask.length) {
      this.props.warningSnackBar("No Task!");
      return;
    }
    if (progressClaimId) {
      await this.props
        .editProgressClaim(
          {
            tasks: selectedTask,
            projectId,
            description: description.value,
            total: progressClaimTotal,
            invoiceDate: invoiceDate ? invoiceDate : null,
            dueDate: dueDate ? dueDate : null,
            retentionAmount: retentionPCAmount.value
              ? retentionPCAmount.value
              : 0,
            retentionClaimAmount: retentionClaimAmount.value
              ? retentionClaimAmount.value
              : 0,
          },
          progressClaimId
        )
        .catch((e) => console.error(e));
    } else {
      await this.props
        .addProgressClaim({
          tasks: selectedTask,
          projectId,
          description: description.value,
          total: progressClaimTotal,
          invoiceDate: invoiceDate ? invoiceDate : null,
          dueDate: dueDate ? dueDate : null,
          retentionAmount: retentionPCAmount.value
            ? retentionPCAmount.value
            : 0,
          retentionClaimAmount: retentionClaimAmount.value
            ? retentionClaimAmount.value
            : 0,
        })
        .catch((e) => console.error(e));
    }
    this.cancelHandler();
  };

  sumRetentionAmount = async (projectId, progressClaimId) => {
    let claimsRetainedTotal = 0,
      claimsRetentionClaimTotal = 0;
    const progressClaims = await this.props.getAllProgressClaimList(projectId);
    Array.isArray(progressClaims) &&
      progressClaims.filter((claim) => {
        if (claim.id !== progressClaimId) {
          claimsRetainedTotal += claim.retentionAmount;
          claimsRetentionClaimTotal += claim.retentionClaimAmount;
        }
      });
    this.setState({ claimsRetainedTotal, claimsRetentionClaimTotal });
  };

  handleClaimAmountChange = (e, id) => {
    let { selectedTask } = this.state;
    const index = selectedTask.findIndex((t) => t.id === id);
    const inputValue = parseFloat(e.target.value);
    if (selectedTask[index].totalBefore - selectedTask[index].totalBefore * (selectedTask[index].previousPercentageComplete / 100) < inputValue) {
      return;
    }

    const percentage = (inputValue / parseFloat(selectedTask[index].totalBefore)) * 100;
    selectedTask[index].remainingPercentage = percentage;
    selectedTask[index].percentageComplete = selectedTask[index].previousPercentageComplete + percentage;
    selectedTask[index].remainingAmount = inputValue;
    selectedTask[index].remainingClaimAmount = inputValue;
    this.calculateTotal(selectedTask);
  };
  inputChangeDateHandler = (date, name) => {
    let { dueDate, invoiceDate } = this.state;
    if (name == "dueDate") {
      dueDate = date;
      this.setState({ dueDate });
    }
    if (name == "invoiceDate") {
      invoiceDate = date;
      this.setState({ invoiceDate });
    }
  };
  handleRemainingClaimAmountChange = (e, id) => {
    let { selectedTask } = this.state;
    const index = selectedTask.findIndex((t) => t.id === id);
    selectedTask[index].claimRemainingAmount = e.target.checked;
    if (selectedTask[index].claimRemainingAmount) {
      selectedTask[index].remainingPercentage =
        100 - selectedTask[index].previousPercentageComplete;
      selectedTask[index].remainingAmount =
        selectedTask[index].totalBefore -
        selectedTask[index].totalBefore *
        (selectedTask[index].previousPercentageComplete / 100);
      selectedTask[index].percentageComplete =
        selectedTask[index].previousPercentageComplete +
        selectedTask[index].remainingPercentage;
    } else {
      selectedTask[index].percentageComplete =
        selectedTask[index].previousPercentageComplete;
      selectedTask[index].remainingPercentage = 0;
      selectedTask[index].remainingAmount = 0;
    }
    this.calculateTotal(selectedTask);
  };

  renderRowData = (task = {}) => {
    const { progressClaims = [], open, selectedTask = [] } = this.state;

    let prevProgress = [],
      disable = true;

    Array.isArray(progressClaims) &&
      progressClaims.map((claim) => {
        if (Array.isArray(claim.progressClaimTask)) {
          const prevProgressTask = claim.progressClaimTask.find(
            (t) => t.taskId === task.id
          );
          prevProgressTask && prevProgress.push(prevProgressTask);
        }
      });

    prevProgress = _.orderBy(prevProgress, "percentageComplete", "desc");
    const maxPercentage = 100 - task.previousPercentageComplete;
    const selectedIndex = selectedTask.findIndex((st) => st.id === task.id);

    if (selectedIndex > -1) {
      task = selectedTask[selectedIndex];
      disable = false;
    }

    if (task.type === "VARIATION") {
      task.totalBefore =
        (task.materialTotal_SaleAmount
          ? parseFloat(task.materialTotal_SaleAmount)
          : 0) +
        (task.laborCost ? parseFloat(task.laborCost) : 0) +
        (task.subContractorCost ? parseFloat(task.subContractorCost) : 0);
    } else {
      task.totalBefore =
        (task.allocatedMaterialCost
          ? parseFloat(task.allocatedMaterialCost)
          : 0) +
        (task.allocatedLaborCost ? parseFloat(task.allocatedLaborCost) : 0) +
        (task.allocatedSubContractorCost
          ? parseFloat(task.allocatedSubContractorCost)
          : 0);
    }

    const maxTotal =
      task.totalBefore -
      task.totalBefore * ((task.previousPercentageComplete || 0) / 100);

    return (
      <Fragment key={task.id}>
        <tr>
          <td className="expand-icon">
            <ExpandMoreIcon
              onClick={() => {
                this.setState((prevState) => ({
                  ...open,
                  open: { [task.id]: !prevState.open[task.id] },
                }));
              }}
              aria-controls="example-collapse-text"
              aria-expanded={open[task.id]}
            />
          </td>
          <td>{task.newName}</td>
          <td className="text-right">
            {numberFormat(parseFloat(task.totalBefore || 0))}
          </td>
          <td className="text-right">
            {idx(task, (_) => _.subTasks.length) ? "" : `${(Math.round((task.previousPercentageComplete || 0) * 100) / 100)?.toFixed(2) || 0}%`}
          </td>
          <td className="text-right">
            {numberFormat(parseFloat(task.totalBefore - task.totalBefore * ((task.previousPercentageComplete || 0) / 100))) || "-"}
          </td>
          <td>
            {task.subTasks && task.subTasks.length > 0 ? ("") : (
              <div className="input-block input-block-sm d-flex">
                <input
                  className="form-control text-right"
                  type="number"
                  max={maxPercentage}
                  value={
                    task.remainingPercentage == 0
                      ? task.previous_percentageComplete +
                      task.remainingPercentage
                      : parseFloat(task?.remainingPercentage?.toFixed(2))
                  }
                  disabled={disable}
                  onChange={(e) => {
                    this.handlePercentageChange(e, task.id)
                  }}
                  onBlur={(e) => {
                    const value = parseFloat(e.target.value)?.toFixed(2);
                    e.target.value = value;
                    this.handlePercentageChange(e, task.id);
                  }}
                />
                <span className="my-auto ms-2">%</span>
              </div>
            )}
          </td>
          <td>
            {task.subTasks && task.subTasks.length > 0 ? (
              ""
            ) : (
              <div className="input-block input-block-sm">
                <input
                  className="form-control text-right"
                  type="number"
                  step="0.1"
                  min={0}
                  max={maxTotal}
                  value={!task.remainingClaimAmount && !isNaN(task.remainingClaimAmount) && task.remainingClaimAmount !== null && task.remainingClaimAmount !== undefined ? task.remainingClaimAmount : task.previous_total + parseFloat(task.remainingAmount?.toFixed(2))}
                  onChange={(e) => this.handleClaimAmountChange(e, task.id)}
                />
              </div>
            )}
          </td>
          <td>
            {task.subTasks && task.subTasks.length > 0 ? (
              ""
            ) : (
              <div className="table-checkbox">
                <Form.Check
                  type={"checkbox"}
                  checked={task.claimRemainingAmount}
                  onChange={(e) =>
                    this.handleRemainingClaimAmountChange(e, task.id)
                  }
                  disabled={
                    task.previousPercentageComplete === 100 ? true : false
                  }
                />
              </div>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan={8} className="p-0 border-0">
            <Collapse in={open[task.id]}>
              <div id="example-collapse-text" className="history-table">
                <h3>History</h3>
                <div className="table-responsive">
                  <Table
                    bordered
                    className="table-create table-material table-material-ch"
                  >
                    <thead>
                      <tr>
                        <th className="w-auto">Progress Claim Id</th>
                        <th className="w-auto">Date</th>
                        <th className="text-right">Total Amount</th>
                        <th className="text-right">Claim Percentage</th>
                        <th className="text-right">Claim Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(prevProgress) && prevProgress.length ? (
                        prevProgress.map((historyRow) => (
                          <tr key={historyRow.id}>
                            <td>{historyRow.id}</td>
                            <td>
                              {moment(historyRow.createdAt).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="text-right">
                              {numberFormat(
                                historyRow.subTotal
                                  ? parseFloat(historyRow.subTotal)
                                  : 0
                              )}
                            </td>
                            <td className="text-right">
                              {idx(historyRow, (_) => _.percentageComplete)
                                ? `${(
                                  Math.round(
                                    historyRow.percentageComplete * 100
                                  ) / 100
                                )?.toFixed(2) || 100
                                }%`
                                : "100%"}
                            </td>
                            <td className="text-right">
                              {numberFormat(parseFloat(historyRow.total)) &&
                                numberFormat(parseFloat(historyRow.total))}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={5} className="text-center">
                          {"No History to Show"}
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Collapse>
          </td>
        </tr>
      </Fragment>
    );
  };

  ViewInvoice = () => {
    const { paramsProjectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/invoices/${paramsProjectId}`);
  };

  ViewProjectList = () => {
    const { projectDataType } = this.state;
    this.props.history(
      `/${PROJECT_MANAGEMENT}?project-type=${projectDataType}`
    );
  };

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  };

  render() {
    const {
      projectQuoteType,
      selectedTask,
      description,
      variationsTotal,
      progressClaimTotal,
      retentionTotal,
      stages,
      hasStages,
      optionData,
      claimAmount,
      retentionClaimAmount,
      retentionPCAmount,
      claimsRetainedTotal,
      claimsRetentionClaimTotal,
      previous_total_claim_amount,
      invoiceDate,
      dueDate,
      projectName,
    } = this.state;
    const retentionData =
      (Array.isArray(selectedTask) &&
        selectedTask.filter((t) => t.type === "RETENTION")) ||
      [];

    return (
      <div className="purchase-order-block">
        <div className="budget-container">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">
                Generate Invoice
              </h2>
              <div className="col-12 breadcrumb-block p-0">
                <Breadcrumb>
                  <Breadcrumb.Item onClick={this.ViewProjectList}>
                    PROJECTS
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={this.ViewProject}>
                    {projectName}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={this.ViewInvoice}>
                    Invoices
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Generate Invoice</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <TextAreaInput
                  {...description}
                  required={true}
                  onChange={(e) => {
                    const { description } = this.state;
                    this.setState({
                      description: { ...description, value: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 mt-3">
              <DatePickerInput label={"Invoice Date"} value={invoiceDate} onChange={(e) => this.inputChangeDateHandler(e, "invoiceDate")} />
            </div>
            <div className="col-lg-3 col-md-6 mt-3">
              <DatePickerInput label={"Due Date"} value={dueDate} onChange={(e) => this.inputChangeDateHandler(e, "dueDate")} />
            </div>
            {projectQuoteType == "quote" && (
              <>
                <div className="col-lg-3 col-md-6 mt-3">
                  <AmountInput
                    {...retentionPCAmount}
                    label={"Amount Retained"}
                    onChange={(e) => {
                      const { retentionPCAmount } = this.state;
                      const amount = parseFloat(e.target.value || 0);
                      this.setState({
                        retentionPCAmount: {
                          ...retentionPCAmount,
                          value: amount,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-lg-3 col-md-6 mt-3">
                  <AmountInput
                    {...retentionClaimAmount}
                    label={"Retention Claim"}
                    onChange={(e) => {
                      const { retentionClaimAmount } = this.state;
                      if (claimsRetainedTotal >= e.target.value) {
                        const amount = parseFloat(e.target.value || 0);
                        this.setState({
                          retentionClaimAmount: {
                            ...retentionClaimAmount,
                            value: amount,
                          },
                        });
                      } else {
                        this.props.errorSnackBar(
                          "Amount should be less than or equal to Total Retained Amount"
                        );
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="material-list-block mt-4">
                <div className="table-responsive">
                  <Table
                    bordered
                    className="table-create table-material table-material-ch"
                  >
                    <thead>
                      <tr>
                        <th className="w-auto"></th>
                        <th className="w-auto">Task</th>
                        <th className="text-right">Cost</th>
                        <th className="text-right">% Completed</th>
                        <th className="text-right">Remaining Amount </th>
                        <th>Claim %</th>
                        <th>Claim Amount</th>
                        <th>Claim Remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stages && Array.isArray(stages) && stages.length ? (
                        <>
                          {stages.map((stage, index) => (
                            <Fragment key={stage.id}>
                              {hasStages && (
                                <tr>
                                  <td></td>
                                  <td>{stage.name || ""}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                              {(stage.tasks &&
                                Array.isArray(stage.tasks) &&
                                stage.tasks.map((task, i) => {
                                  const subTaskJSX =
                                    (task.subTasks &&
                                      Array.isArray(task.subTasks) &&
                                      task.subTasks.map((subTask, si) =>
                                        this.renderRowData(subTask)
                                      )) ||
                                    [];
                                  return (
                                    <Fragment key={i}>
                                      {this.renderRowData(task)}
                                      {subTaskJSX}
                                    </Fragment>
                                  );
                                })) ||
                                []}
                            </Fragment>
                          ))}
                        </>
                      ) : (
                        <td colSpan={8} className="text-center">
                          {"No Data to Show"}
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="material-list-block mt-4">
                <div className="row">
                  <div className="w-auto">
                    <h2 className="content-heading text-uppercase mb-0">
                      Variations
                    </h2>
                  </div>
                </div>

                <div className="table-responsive">
                  <Table
                    bordered
                    className="table-create table-material table-material-ch"
                  >
                    <thead>
                      <tr>
                        <th className="w-auto"></th>
                        <th className="w-auto">Task</th>
                        <th className="text-right">Cost</th>
                        <th className="text-right">% Completed</th>
                        <th className="text-right">Remaining Amount </th>
                        <th>Claim %</th>
                        <th>Claim Amount</th>
                        <th>Claim Remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionData &&
                        Array.isArray(optionData) &&
                        optionData.length ? (
                        optionData
                          .filter((o) => o.proposal_status === "APPROVED")
                          .map((optiontask, i) => {
                            const optionsubTaskJSX =
                              (optiontask.subTasks &&
                                Array.isArray(optiontask.subTasks) &&
                                optiontask.subTasks.map((subTask, si) =>
                                  this.renderRowData(subTask)
                                )) ||
                              [];
                            return (
                              <Fragment key={i}>
                                {this.renderRowData(optiontask)}
                                {optionsubTaskJSX}
                              </Fragment>
                            );
                          })
                      ) : (
                        <td colSpan={6} className="text-center">
                          {"No Data to Show"}
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="material-list-block mt-4">
                <div className="row">
                  <div className="w-auto">
                    <h2 className="content-heading text-uppercase mb-0">
                      Retentions
                    </h2>
                  </div>
                </div>

                <div className="table-responsive">
                  <Table
                    bordered
                    className="table-create table-material table-material-ch"
                  >
                    <thead>
                      <tr>
                        <th className="w-auto"></th>
                        <th className="w-auto">Progress Claim Id</th>
                        <th>Date</th>
                        <th className="text-right">Total Amount</th>
                        <th className="text-right">Claim Amount</th>
                        <th className="text-right">Retention Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {retentionData &&
                        Array.isArray(retentionData) &&
                        retentionData.length ? (
                        retentionData.map((retention) => (
                          <tr key={retention.id}>
                            <td />
                            <td>{retention.progressClaimId}</td>
                            <td>
                              {moment(retention.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-right">
                              {numberFormat(retention.total || 0)}
                            </td>
                            <td className="text-right">
                              {numberFormat(retention.claimAmount || 0)}
                            </td>
                            <td className="text-right">
                              <b>
                                {numberFormat(retention.retentionAmount || 0)}
                              </b>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={6} className="text-center">
                          {"No Data to Show"}
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 total-table mt-3 table-responsive">
            <table className="table-create table-variation mb-0 table table-bordered">
              <thead>
                <tr>
                  {projectQuoteType == "quote" && (
                    <th className="w-auto">Total Retained By Customer</th>
                  )}
                  {projectQuoteType == "quote" && (
                    <th>Total Retention Claimed Back</th>
                  )}
                  {variationsTotal ? <th> Variation Claim Total</th> : ""}
                  {retentionTotal && projectQuoteType == "quote" ? (
                    <th>Retention Claim Total</th>
                  ) : (
                    ""
                  )}
                  {projectQuoteType == "quote" && (
                    <th>Remaining Retention to Claim</th>
                  )}
                  <th>Total of Previous Claims</th>
                  <th>This Claim Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {projectQuoteType == "quote" && (
                    <td>
                      <span className="tableinfo">
                        {numberFormat(claimsRetainedTotal || 0)}
                      </span>
                    </td>
                  )}
                  {projectQuoteType == "quote" && (
                    <td>
                      <span className="tableinfo">
                        {numberFormat(claimsRetentionClaimTotal || 0)}
                      </span>
                    </td>
                  )}
                  {variationsTotal ? (
                    <td>
                      <span className="tableinfo">
                        {numberFormat(variationsTotal || 0)}
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                  {retentionTotal && projectQuoteType == "quote" ? (
                    <td>
                      <span className="tableinfo">
                        {numberFormat(retentionTotal || 0)}
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                  {projectQuoteType == "quote" && (
                    <td>
                      <span className="tableinfo">
                        {numberFormat(
                          claimsRetainedTotal - claimsRetentionClaimTotal || 0
                        )}
                      </span>
                    </td>
                  )}
                  <td>
                    <span className="tableinfo">
                      {numberFormat(progressClaimTotal || 0)}
                    </span>
                  </td>
                  <td>
                    <span className="tableinfo">
                      {claimAmount == 0
                        ? numberFormat(previous_total_claim_amount)
                        : claimAmount
                          ? numberFormat(
                            claimAmount + retentionClaimAmount.value || 0
                          )
                          : numberFormat(0)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12 d-flex mt-3">
            <Button
              type="submit"
              className="ms-auto me-3 secondarybtn"
              onClick={this.generateProgressClaim}
            >
              Generate
            </Button>
            <Button
              className="secondarybtn cancelbtn me-auto"
              onClick={this.cancelHandler}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  addProgressClaim: bindActionCreators(addProgressClaim, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getOptions: bindActionCreators(getOptions, dispatch),
  getProgressClaimList: bindActionCreators(getProgressClaimList, dispatch),
  getLaborCost: bindActionCreators(getLaborCost, dispatch),
  getLevel: bindActionCreators(getLevel, dispatch),
  getProgressClaimById: bindActionCreators(getProgressClaimById, dispatch),
  editProgressClaim: bindActionCreators(editProgressClaim, dispatch),
  getAllProgressClaimList: bindActionCreators(
    getAllProgressClaimList,
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateBudget)
);
