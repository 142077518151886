import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import UserList from "./users-list";
import UserAllocationList from "./user-allocation-list";
import * as paths from "../../constants/route-paths";
import AddUser from "./add-user";
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {

  let element = useRoutes([
    {
      path: "",
      element: <RBACApplicationAuth moduleURL={location.pathname} component={UserList} />,
    },
    {
      path: paths.ADD_USER,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddUser} />,
    },
    {
      path: paths.EDIT_USER,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddUser} />,
    },
    {
      path: paths.USER_ALLOCATIONS,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={UserAllocationList} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
