import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { format } from 'date-fns'
import { getAllocationHistory, getMaintenanceHistory } from '../../actions/asset-management.action'
import Modal from '../../components/modal/modal'
import './scss/asset-management.scss'
import CustomTable from 'src/components/table/table'

class AssetHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetId: '',
            assetData: {},
            openDialog: false,
            columns: [],
            data: [],
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.assetId, this.props.assetId)) {
            this.setState({ assetId: this.props.assetId })
        }
    }

    handleAllocationHistory = async () => {
        const { assetId } = this.state
        const { allocationHistory, ...assetData } = await this.props.getAllocationHistory(assetId)

        let data = Array.isArray(allocationHistory) && allocationHistory.map(history => ({
            id: history.id,
            allocationName: history.allocationName,
            type: history.type,
            allocationDate: (history.allocationDate && format(new Date(history.allocationDate), 'dd/MM/yyyy')) || '-',
            returnDate: (history.returnDate && format(new Date(history.returnDate), 'dd/MM/yyyy')) || '-',
            submitDescription: history.submitDescription || '-'
        }))

        let columns = [
            { title: 'Allocated To', field: 'allocationName' },
            { title: 'Type', field: 'type', lookup: { 'VEHICLE': 'Vehicle', 'USER': 'User', 'PROJECT': 'Project', 'SERVICE': 'Service' } },
            { title: 'Allocation Date', field: 'allocationDate' },
            { title: 'Return Date', field: 'returnDate' },
            { title: 'Submit Reason', field: 'submitDescription' },
        ]

        this.setState({ openDialog: true, columns, data, assetData })
    }

    handleMaintenanceHistory = async () => {
        const { assetId } = this.state
        const { maintenanceHistory, ...assetData } = await this.props.getMaintenanceHistory(assetId)

        let data = Array.isArray(maintenanceHistory) && maintenanceHistory.map(history => ({
            id: history.id,
            reason: history.reason,
            description: history.description || '-',
            serviceDetail: history.serviceDetail || '-',
            repairExpense: history.repairExpense || '-',
            attachment: history.attachment || [],
            maintenanceData: (history.createdAt && format(new Date(history.createdAt), 'dd/MM/yyyy')) || '-',
            vehicleKilometre: history.vehicleKilometre
        }))

        let columns = [
            { title: 'Maintenance Reason', field: 'reason' },
            { title: 'Description', field: 'description' },
            { title: 'Service Detail', field: 'serviceDetail' },
            { title: 'Repair Expense', field: 'repairExpense', type: 'currency', currencySetting: { currencyCode: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
            { title: 'Maintenance Date', field: 'maintenanceData' },
            { title: 'Vehicle Kilometer', field: 'vehicleKilometre' },
            {
                title: 'Attachments',
                field: 'attachment',
                render: rowData => (Array.isArray(rowData.attachment) && rowData.attachment.map((file, index) => (
                    <p key={index} style={{ margin: '0 10px 0 0' }}><a href={`${file.url}`} rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'underline' }} >{file.name}</a></p>
                ))) || '-'
            },
        ]

        this.setState({ openDialog: true, columns, data, assetData })
    }

    handleClose = () => {
        this.setState({
            assetData: {},
            openDialog: false,
            data: [],
            columns: [],
        })
    }

    render() {
        const { columns, data, assetData, openDialog } = this.state

        const assetInformation = (
            <div className="row">
                <div className="col-lg-6 mt-3">
                    <div className="view-block">
                        <label>Asset Name</label>
                        <p>{assetData.name}</p>
                    </div>
                </div>
                <div className="col-lg-6 mt-3">
                    <div className="view-block">
                        <label>Asset No</label>
                        <p>{assetData.assetNo}</p>
                    </div>
                </div>
            </div>
        )

        return (<>
            <Modal
                open={openDialog}
                onClose={this.handleClose}
                modalHeading={'Asset History'}
                fullWidth={true}
                maxWidth={'md'}
                primary
            >
                <div className="purchase-order-block">
                    {assetInformation}
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                </div>
            </Modal>
            <button onClick={this.handleMaintenanceHistory} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary me-3" >
                Maintenance History
            </button>
            <button onClick={this.handleAllocationHistory} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary" >
                Allocation History
            </button>
        </>)
    }
}

AssetHistory.defaultProps = {
    assetId: ''
}

AssetHistory.propTypes = {
    assetId: PropTypes.string
}


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    getAllocationHistory: bindActionCreators(getAllocationHistory, dispatch),
    getMaintenanceHistory: bindActionCreators(getMaintenanceHistory, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetHistory);
