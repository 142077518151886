import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { subcontractorObjFields } from "src/constants/fields.constant";
import { updateItemAction, updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { calculateSaleAmount } from "src/util/calculations/sale-amount";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ProfitMarginLock from "src/components/profitMarginLock-dialog";
import CustomInput, { LockInput } from "src/components/inputs/new-input";
import { toUSD } from "src/util/currency-formatter";

const filter = createFilterOptions();

export default function Subcontractor({ subcontractor = [], newContractor = [], total = {}, subTaskArrayIndex, profitMargin, profitMarginType, subcontractorOptions = [], isDisabled, handleAddContractor, ...props }) {

    const dispatch = useDispatch()
    const [subcontractorState, setSubcontractorState] = useState({
        subcontractorObj: subcontractorObjFields,
        subSaleAmountLock: false,
        confirmLock: false,
        lockArrayValue: null,
    })

    // const filterSubcontractor = (id) => {
    //     return subcontractorOptions.find((_) => _.value === id) || {};
    // };
    // const updateSubcontractor = (e, index) => {
    //     const { subTaskArray, } = props;
    //     let _temp = _.cloneDeep(subTaskArray);

    //     const selectedSubcontractor = filterSubcontractor(e.value);

    //     const _subcontractor = _.cloneDeep(subcontractorState.subcontractorObj);

    //     _temp[subTaskArrayIndex]["subcontractor"][index]["name"].value = selectedSubcontractor.key;
    //     _temp[subTaskArrayIndex]["subcontractor"][index]["id"].value = selectedSubcontractor.value;
    //     _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value = _subcontractor.Cost.value;
    //     _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value = calculateSaleAmount({
    //         cost: _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value,
    //         profitMargin,
    //         profitMarginType
    //     }).toFixed(2);
    //     _temp[subTaskArrayIndex]["subcontractor"][index]["description"].value = _subcontractor.description.value;
    //     _temp[subTaskArrayIndex]["subcontractor"][index]["total"].value = parseInt(_subcontractor.Cost.value) || 0;
    //     dispatch(updateSubTaskAction(_temp))
    // };

    const subcontractorChangeHandler = (e, index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray);

        if (e == "saleAmountLock") {
            saleAmountDialogHandler(e, index);
            if (subcontractorState.subSaleAmountLock && subcontractorState.confirmLock) {
                _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmountLock"].value =
                    !_temp[subTaskArrayIndex]["subcontractor"][index]["saleAmountLock"].value;
            }
        } else {
            const value = e.target.value.replace("-", "");
            if (_temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].name === "saleAmount") {
                _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value = e.target.value;
                _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmountLock"].value = false;
            } else if (_temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].name === "Cost") {
                _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value = e.target.value;

                _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value =
                    _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"]
                        .value <= 0 ||
                        _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmountLock"].value == true
                        ? calculateSaleAmount({
                            cost: _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value,
                            profitMargin,
                            profitMarginType
                        }).toFixed(2)
                        : parseFloat(_temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value);
            } else {
                _temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].value = value;
            }
            _temp[subTaskArrayIndex]["subcontractor"][index]["total"].value =
                parseFloat(_temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value) || 0;
        }
        dispatch(updateSubTaskAction(_temp))
    };

    const saleAmountDialogHandler = (e, index) => {
        setSubcontractorState(prev => ({
            ...prev,
            subSaleAmountLock: !prev.subSaleAmountLock,
            lockArrayValue: index,
        }));
    };

    const removeSubcontractor = (index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        const subcontractor = _temp[subTaskArrayIndex]["subcontractor"][index];

        subcontractor.Cost.value = "0";
        _temp[subTaskArrayIndex]["subcontractor"].splice(index, 1);
        dispatch(updateSubTaskAction(_temp))
    };

    const addSubcontractor = (e, tmpId) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)

        const subcontractor = _.cloneDeep(subcontractorState.subcontractorObj);
        subcontractor.name.value = e.key;
        subcontractor.id.value = e.value;

        let contractors = getNewContractors()
        _temp[subTaskArrayIndex]["newContractor"] = contractors.filter(item => item.id != tmpId)
        _temp[subTaskArrayIndex]["subcontractor"].push(subcontractor);
        dispatch(updateSubTaskAction(_temp))
    };

    const setConfirmed = () => {
        const { subTaskArray } = props;
        const { lockArrayValue } = subcontractorState;
        let _temp = _.cloneDeep(subcontractor);
        _temp[lockArrayValue]["saleAmountLock"].value = !_temp[lockArrayValue]["saleAmountLock"].value;

        _temp[lockArrayValue]["saleAmount"].value = _temp[lockArrayValue]["saleAmount"].value <= 0 || _temp[lockArrayValue]["saleAmountLock"].value == true
            ? calculateSaleAmount({
                cost: _temp[lockArrayValue]["Cost"].value,
                profitMargin,
                profitMarginType
            }).toFixed(2)
            : parseFloat(_temp[lockArrayValue]["saleAmount"].value);
        let _subTaskArray = _.cloneDeep(subTaskArray);
        _subTaskArray[subTaskArrayIndex]['subcontractor'] = _temp;
        dispatch(updateSubTaskAction(_subTaskArray))
        setSubcontractorState(prev => ({
            ...prev,
            subSaleAmountLock: !prev.subSaleAmountLock
        }));
    };

    const getNewContractors = () => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        return _temp[subTaskArrayIndex]["newContractor"] || []
    }
    const handleRemoveContractor = (tmpId) => {
        let contractors = getNewContractors()
        contractors = contractors.filter(item => item.id != tmpId)
        dispatch(updateItemAction({ contractor: contractors, index: subTaskArrayIndex }));
    }

    return (
        <>
            {[...subcontractor, ...newContractor].length > 0 && <div className="mb-2">
                <div className="border border-bottom-0 d-flex px-2 py-1 gap-3">
                    <h2 className="py-1 tbl-heading">Contractor List</h2>
                </div>

                <div className="material-list-block">
                    <div className="table-responsive">
                        <Table bordered className="table-create table-material tbl-fixed">
                            <thead>
                                <tr>
                                    <th className="contractor-width">Contractor(s)</th>
                                    <th className="">Description</th>
                                    <th className="cost-width">Cost($)</th>
                                    <th className="cost-width">Sale Amount($)</th>
                                    <th className="qty-width"></th>
                                    <th className="text-right total-width">Total ($)</th>
                                    <th className="text-right qty-width">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subcontractor.map((_subcontractor, subcontractorIndex) => {
                                    if (!_subcontractor) return;
                                    return (
                                        <tr key={subcontractorIndex}>
                                            <td>
                                                <p title={idx(_subcontractor, _ => _.name.value)} className="text-truncate m-0 table-text-sm">
                                                    {idx(_subcontractor, _ => _.name.value)}
                                                </p>
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={isDisabled}
                                                    {..._subcontractor.description}
                                                    label={''}
                                                    onChange={(e) => subcontractorChangeHandler(e, subcontractorIndex)}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={isDisabled}
                                                    {..._subcontractor.Cost}
                                                    onChange={(e) => subcontractorChangeHandler(e, subcontractorIndex)}
                                                    min={1}
                                                />
                                            </td>
                                            <td>
                                                <LockInput
                                                    lock={
                                                        <span className="lock-icon">
                                                            {idx(_subcontractor, _ => _.saleAmountLock.value) && <LockIcon
                                                                onClick={(e) => !isDisabled && subcontractorChangeHandler("saleAmountLock", subcontractorIndex)}
                                                            />}
                                                            {!idx(_subcontractor, _ => _.saleAmountLock.value) && <LockOpenIcon
                                                                onClick={(e) => !isDisabled && subcontractorChangeHandler("saleAmountLock", subcontractorIndex)}
                                                            />}
                                                        </span>
                                                    }
                                                    className="input-block-sm"
                                                    {..._subcontractor.saleAmount}
                                                    disabled={isDisabled || idx(_subcontractor, _ => _.saleAmountLock.value) ? true : false}
                                                    onChange={(e) => subcontractorChangeHandler(e, subcontractorIndex)}
                                                />
                                            </td>
                                            <td></td>
                                            <td className="text-right">
                                                {toUSD(idx(_subcontractor, _ => _.saleAmount.value))}
                                            </td>
                                            <td className="text-right">
                                                {!isDisabled && <DeleteIcon
                                                    className="deleteicon"
                                                    onClick={() => removeSubcontractor(subcontractorIndex)}
                                                />}
                                            </td>
                                        </tr>
                                    )
                                })}

                                {newContractor.map((_contractor) => (
                                    <tr key={_contractor.id}>
                                        <td>
                                            <div className="input-block-sm autocomplete-block autocomplete-block-ch">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === "string") {
                                                            newValue = { inputValue: newValue, };
                                                        } else if (newValue && newValue.inputValue) {
                                                        } else if (newValue) {
                                                            addSubcontractor(newValue, _contractor.id);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => filter(options, params)}
                                                    selectOnFocus
                                                    handleHomeEndKeys
                                                    options={subcontractorOptions}
                                                    getOptionLabel={(option) => typeof option === "string" ? option : option.key}
                                                    renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                    renderInput={(params) => (<TextField {...params} variant='standard' label='Contractor' />)}
                                                    disablePortal={true}
                                                    id="combo-box-demo"
                                                />
                                            </div>
                                        </td>
                                        <td></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right">
                                            {!isDisabled && <DeleteIcon
                                                className="deleteicon"
                                                onClick={() => handleRemoveContractor(_contractor.id)}
                                            />}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right fs-6 py-1">
                                        <span>
                                            {toUSD(idx(total, _ => _.subcontractor.sale))}
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>}

            <ProfitMarginLock
                open={subcontractorState.subSaleAmountLock}
                lockIconChange={() => setConfirmed()}
                dialogClose={saleAmountDialogHandler}
                moduleName="Task"
                modalHeading={"Sale Amount Lock changes"}
                text={"sale Amount"}
                message={'Are you sure you want to change the Project profit margin? - Any items using default profit margins will also be updated to this value'}
            />
        </>
    );

}
