import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './scss/login.scss'
import Buttons from './../../components/buttons/buttons'
import { Grid } from '@material-ui/core'
import Input from './../../components/inputs/input-simple'
import { login, forgetPassword } from './../../actions/user-management.action'
import { closeSnackbar } from './../../actions/common.action'
import { withRouter } from "../../util/with-router";
import ForgotPassword from './forgot-password'
import { emailValidator } from '../../util/validators/email'
import { DASHBOARD, GLOBAL_ACCOUNT } from 'src/constants/route-paths'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: false,
      emailTouched: true,
      password: '',
      passwordError: false,
      passwordTouched: true,
      showDialog: false
    }
  }

  componentDidMount() {
  }
  static getDerivedStateFromProps(props, state) {
    if (props.isLoggedIn) {
      this.props.history('');
    }
    // Return null to indicate no change to state.
    return null;
  }

  handleLogin = async (e) => {
    e.preventDefault();
    let valid = true;
    // eslint-disable-next-line
    let status = emailValidator(this.state.email)
    if (!status) {
      valid = false;
      this.setState({ emailError: "Enter a valid Email!" });
    }
    if (this.state.password.trim() === "") {
      valid = false;
      this.setState({ passwordError: "Enter a valid Password!" });
    }
    if (valid) {
      this.props.closeSnackbar()
      let data = await this.props.login({ email: this.state.email, password: this.state.password })
      if (this.props.location.pathname === '/login' || this.props.location.pathname === '' || this.props.location.pathname === '/') {
        if (data?.is_global === 1) {
          this.props.history(`/${GLOBAL_ACCOUNT}`)
        } else {
          this.props.history(`/${DASHBOARD}`)
        }
      }

    } else {
      e.preventDefault();
    }
  }

  forgotPassword = async (email) => {
    await this.props.forgetPassword(email).catch(error=>console.log(error))
    this.handleDialog()
  }

  handleDialog = () => {
    this.setState(({ showDialog }) => ({
      showDialog: !showDialog
    }))
  }

  render() {
    const {
      email,
      emailError,
      emailTouched,
      password,
      passwordError,
      passwordTouched,
      showDialog
    } = this.state
    return (
      <div className='login'>
        {showDialog && <ForgotPassword
          handleDialog={this.handleDialog}
          forgotPassword={this.forgotPassword}
        />}
        {/* <div className='logo'>
          <img src={require('./../../assets/logo.svg')} alt='Logo' />
        </div> */}
        <div className="login-form-begetter">
          <div className="login-form-abs-content">
            <div className="login-form-abs-content-img">
              <img src={require('./../../assets/logo.svg').default} alt='Logo' />
            </div>
            <p className='LoginHeading'>Login</p>
          </div>
          <div className='loginForm'>
            <div className='loginFormStart'>
              <form onSubmit={this.handleLogin}>
                <Grid xs={12} item>
                  {/* <p className='LoginHeading'>Login</p> */}
                  {/* <label className='LoginLabel'>Email</label> */}
                  <div className="email-sec">
                    <div className="field-img-sec">
                      <img src={require('./../../assets/mail.png')} alt='mail' />
                    </div>
                    <Input
                      id='email'
                      grid={12}
                      type='email'
                      label='Email'
                      value={email}
                      valid={!emailError}
                      message={emailError}
                      touched={emailTouched}
                      onChange={(e) => this.setState({ email: e.target.value, emailTouched: true, emailError: false, })}
                    />
                  </div>
                  {/* <label className='LoginLabel'>Password</label> */}
                  <div className="email-sec password-sec">
                    <div className="field-img-sec">
                      <img src={require('./../../assets/password.png')} alt='password' />
                    </div>
                    <Input
                      id='password'
                      type='password'
                      label='Password'
                      value={password}
                      valid={!passwordError}
                      message={passwordError}
                      touched={passwordTouched}
                      onChange={(e) => this.setState({ password: e.target.value, passwordTouched: true, passwordError: false, })}
                    />
                  </div>
                  <div className='ForgotDiv'>
                    <p onClick={this.handleDialog}>Forgot Password?</p>
                  </div>
                  <Buttons className='loginButton login-pg' type='submit' title='LETS GO' color='secondary' />
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn
})

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  closeSnackbar: bindActionCreators(closeSnackbar, dispatch),
  forgetPassword: bindActionCreators(forgetPassword, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
