import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserList } from "../../actions/user-management.action";
import { getProjectList } from "../../actions/project-management.action";
import { getResourceAllocations } from "../../actions/timesheet.action";
import { Menu, MenuItem } from "@material-ui/core";
import { TIMESHEET, SCHEDULE } from "../../constants/route-paths";
import idx from "idx";
import moment from "moment";
import Filter from "./filter";
import _ from "lodash";
import { CSVLink } from "react-csv";
import "./scss/timesheet.scss";
import { getGlobalSetting } from "../../actions/global-setting.action";
import { withRouter } from "../../util/with-router";
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';
import '../project-management/scss/task.scss';
import { csvValidator } from "src/util/validators/csv";

export class ResourcesList extends Component {


  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      columns: [],
      csvData: [],
    };

  }

  componentDidMount = () => {
    this.getColums();
  };

  getColums = () => {
    const columns = [
      { title: "Emp Name", field: "empName" },
      { title: "Allocated Hrs", field: "allocatedHrs" },
      { title: "Approved Hrs", field: "approvedHrs" },
      { title: "Overtime", field: "overTime" },
      { title: "In-review", field: "reviewHrs" },
    ];

    this.setState({ columns });
  };

  getUserList = async (params = {}) => {
    const { permissions, id: managerId } = this.props.user;
    const writePermission =
      permissions.timesheetApprover === 2 ? true : false;

    if (!permissions.timesheetApprover) return;

    const [projects, services, finance] = await Promise.all([
      this.props.getProjectList(null, null, null, null, null, managerId),
      this.props.getGlobalSetting("FINANCE_TAX"),
    ]);

    const projectIds = [managerId];
    Array.isArray(projects) &&
      projects.map((project) => projectIds.push(project.id));
    Array.isArray(services) &&
      services.map((service) => projectIds.push(service.id));

    params.projectIds = projectIds;
    if (writePermission) {
      params.projectIds = null;
    }
    const resourceAllocations = await this.props.getResourceAllocations(params);

    const userIds =
      (Array.isArray(resourceAllocations) &&
        resourceAllocations.map(({ userId }) => userId)) ||
      [];

    // userIds.push(managerId)
    const list = await this.props.getUserList({ userIds });
    const userList = [];

    Array.isArray(list) &&
      list.map(({ id, firstName, lastName }) => {
        // Filter allocations for the user from the list of all allocations
        const usersTimesheets =
          (Array.isArray(resourceAllocations) &&
            resourceAllocations.filter(({ userId }) => userId === id)) ||
          [];

        let allocatedHrs = 0;
        let approvedHrs = 0;
        let overTime = 0;
        let reviewHrs = 0;
        let overTimeAmount = 0;
        let amountWithoutOverTime = 0;
        const overTimeRate =
          idx(finance, (_) => parseFloat(_.data.finance_overtime || 0)) || 0;

        Array.isArray(usersTimesheets) &&
          usersTimesheets.map((user) => {
            const {
              startTime,
              endTime,
              approvedStartTime,
              approvedEndTime,
              status,
              hourlyRate,
              overtime: _overTime,
            } = user;

            const start = moment(startTime);
            const end = moment(endTime);
            const _allocatedHrs = moment.duration(end.diff(start)).asHours();
            allocatedHrs += _allocatedHrs;

            if (status === "PENDING") {
              reviewHrs += _allocatedHrs;
            }

            if (status === "APPROVED") {
              // check if timesheet is approved
              const approvedStart = moment(approvedStartTime);
              const approvedEnd = moment(approvedEndTime);

              const _approvedHrs = moment
                .duration(approvedEnd.diff(approvedStart))
                .asHours();
              approvedHrs += _approvedHrs;

              overTime += _overTime;

              overTimeAmount += _overTime * overTimeRate * hourlyRate;
              amountWithoutOverTime += (_approvedHrs - _overTime) * hourlyRate;
            }
          });

        reviewHrs =
          Math.floor(reviewHrs) + ":" + parseInt((reviewHrs * 60) % 60);
        allocatedHrs =
          Math.floor(allocatedHrs) + ":" + parseInt((allocatedHrs * 60) % 60);
        approvedHrs =
          Math.floor(approvedHrs) + ":" + parseInt((approvedHrs * 60) % 60);
        overTime = Math.floor(overTime) + ":" + parseInt((overTime * 60) % 60);
        const totalAmount = (overTimeAmount + amountWithoutOverTime).toFixed(2);
        overTimeAmount = overTimeAmount.toFixed(2);
        amountWithoutOverTime = amountWithoutOverTime.toFixed(2);

        userList.push({
          id,
          empName: firstName + " " + lastName,
          allocatedHrs,
          approvedHrs,
          overTime,
          reviewHrs,
          overTimeAmount,
          amountWithoutOverTime,
          totalAmount,
        });
      });

    this.setState({ resources: userList, managerId });
  };

  handleMenu = (event, rowData) => {
    this.setState({ anchorEl: event.currentTarget, userId: rowData.id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, vendorData: {} });
  };

  allocate = (userId) => {
    this.props.history(`/${TIMESHEET}/${SCHEDULE}${userId ? `?user=${userId}` : ""}`);
  };

  viewTimesheet = (userId) => {
    this.props.history(`/${TIMESHEET}/view-timesheet/${userId}`);
  };

  applyFilter = (data) => {
    const fields = _.cloneDeep(data);
    const { projectCategories } = this.props;
    const allowedModules = [...this.props.allowedModules];

    let projectType;
    if (fields.projectType.value === "all") {
      projectType = null;
    } else if (
      projectCategories.find((o) => o.type === fields.projectType.value)
    ) {
      projectType = fields.projectType.value;
    }

    let projectId = fields.project.value;
    if (projectId === "all") {
      projectId = null;
    }

    const startTime = fields.startDate.value;
    const endTime = fields.endDate.value;

    const params = {
      projectType,
      projectId,
      startTime,
      endTime,
      allowedModules,
    };

    this.getUserList(params);
  };

  generateReport = () => {
    const { resources } = this.state;
    const csvHeaders = [
      "Emp Name",
      "Allocated Hrs",
      "Approved Hrs",
      "OverTime",
      "In-review",
      "Overtime Amount",
      "Amount without overtime",
      "Overtime Amount",
      "Total Amount"
    ];

    const csvData = (Array.isArray(resources) && resources.map((_resource, index) => {
      delete _resource.id;
      return {
        sno: index + 1,
        ..._resource,
      };
    })) || [];

    this.setState({ csvHeaders, csvData }, () => {
      setTimeout(() => {
        document.getElementById("downloadCSV").click();
      }, 500);
    });

  };

  render() {
    const { columns, resources, anchorEl, csvHeaders, csvData, managerId } = this.state;

    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.viewTimesheet}>View timesheet</MenuItem>
          <MenuItem onClick={this.allocate}>Allocate</MenuItem>
        </Menu>

        <div style={{ marginBottom: "20px" }}>
          <Filter
            applyFilter={this.applyFilter}
            showReportButton
            generateReport={this.generateReport}
            timesheetFilter={true}
            managerId={managerId}
          />
        </div>

        <CSVLink
          id="downloadCSV"
          data={csvData.map(item => {
            const modifiedItem = {};
            for (const key in item) {
              const value = item[key];
              if (typeof value === 'string' && csvValidator(value)) {
                modifiedItem[key] = `'${value}`;
              } else {
                modifiedItem[key] = value;
              }
            }
            return columns.map((column) => {
              let f_value = column.render ? column.render(modifiedItem) : modifiedItem[column.field];
              return typeof f_value === 'string' ? f_value : modifiedItem[column.field];
            });
          })}
          headers={csvHeaders}
          style={{ display: "none" }}
          filename="Pay-roll-report.csv"
        />

        <div className="tableContainer timesheetScrollMobile">
          <div className="timesheetScroll w-100">
            <div className="mobileTimesheet">
              <CustomTable
                columns={columns}
                data={resources}
                isAction={true}
                menu={(data) => (
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <ul className="action-block-list">
                        <li onClick={() => this.viewTimesheet(data.id)}>View Timesheet</li>
                        <li onClick={() => this.allocate(data.id)}>Allocate</li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                )}
                fileName="Allocation List"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  projectCategories: state.project.projectCategories,
  allowedModules: state.project.allowedModules,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: bindActionCreators(getUserList, dispatch),
  getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getGlobalSetting: bindActionCreators(getGlobalSetting, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcesList)
);
