import {
  getUserList as getUserListApi,
  saveUserRole as saveUserRoleAPI,
  addUser as addUserAPI,
  getRolesList as getRolesListAPI,
  login as loginAPI,
  getUserData as getUserDataAPI,
  updateProfile as updateProfileAPI,
  updatePassword as updatePasswordAPI,
  getUser as getUserAPI,
  editUser as editUserAPI,
  deleteRole as deleteRoleAPI,
  editUserRole as editUserRoleAPI,
  getUserNames as getUserNamesAPI,
  useRefreshToken as refreshTokenAPI,
  forgetPassword as forgetPasswordAPI,
  deleteUser as deleteUserAPI,
  getActiveUser as getActiveUserAPI,
  updateUserRoles as updateUserRolesAPI
} from '../api/user-management.api'
import {
  SAVE_USER_DATA,
  REMOVE_USER_DATA
} from '../constants/action.constant'
import { saveAccessToken, removeAccessToken, getRefreshToken, saveRefreshToken, removeRefreshToken } from './../util/auth'
import idx from 'idx'
import { getProjectCategory } from '../actions/global-setting.action'
import { listSubscriptions } from './subscription.action'

export const saveUserData = data => ({ type: SAVE_USER_DATA, data })

export const removeUserData = () => ({ type: REMOVE_USER_DATA })

export const getUserList = (params) => {
  return (dispatch, getState) => {
    return getUserListApi(params)
  }
}

export const saveUserRole = (data) => {
  return (dispatch, getState) => {
    return saveUserRoleAPI(data)
  }
}

export const editUserRole = (data, id) => {
  return (dispatch, getState) => {
    return editUserRoleAPI(data, id)
  }
}

export const addUser = (data) => {
  return (dispatch, getState) => {
    return addUserAPI(data)
  }
}

export const getRolesList = (data) => {
  return (dispatch, getState) => {
    return getRolesListAPI(data)
  }
}

export const login = (data) => {
  return (dispatch, getState) => {
    return loginAPI(data).then((response) => {
      saveAccessToken(response.token)
      saveRefreshToken(response.refreshToken)
      dispatch(getUserData())
      return response;
    }).catch(e => {
      dispatch(removeUserData())
    })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    removeAccessToken()
    dispatch(removeUserData())
  }
}

export const getUserData = () => {
  return (dispatch, getState) => {
    return getUserDataAPI().then(async (response) => {
      dispatch(saveUserData(idx(response, _response => _response)))
      await dispatch(getProjectCategory('PROJECTCATEGORIES'))
      if (idx(response, _ => _.is_global) == 1) {
        await dispatch(listSubscriptions());
      }
    }).catch((e) => {
      if (getRefreshToken()) {
        dispatch(useRefreshToken())
      } else {
        removeAccessToken()
        removeRefreshToken()
      }
    })
  }
}

export const getUser = (id) => {
  return (dispatch, getState) => {
    return getUserAPI(id)
  }
}

export const deleteRole = (id) => {
  return (dispatch, getState) => {
    return deleteRoleAPI(id)
  }
}

export const updateProfile = (data) => {
  return (dispatch, getState) => {
    return updateProfileAPI(data)
  }
}

export const updatePassword = (data) => {
  return (dispatch, getState) => {
    return updatePasswordAPI(data)
      .then(response => {
        saveAccessToken(response.token)
        saveRefreshToken(response.refreshToken)
        dispatch(getUserData())
        return response;
      }).catch(e => {
        dispatch(removeUserData())
      })
  }
}

export const editUser = (data, id) => {
  return (dispatch, getState) => {
    return editUserAPI(data, id)
  }
}

export const getUserNames = () => {
  return (dispatch, getState) => {
    return getUserNamesAPI()
  }
}

export const useRefreshToken = () => {
  return (dispatch, getState) => {
    const data = { refresh_token: getRefreshToken() }
    return refreshTokenAPI(data).then((response) => {
      saveAccessToken(response.token)
      dispatch(getUserData())
    }).catch(e => {
      removeAccessToken()
      removeRefreshToken()
    })
  }
}

export const forgetPassword = (email) => {
  return (dispatch, getState) => {
    return forgetPasswordAPI({ email })
  }
}

export const deleteUser = (id) => {
  return (dispatch, getState) => {
    return deleteUserAPI(id)
  }
}

export const getActiveUser = () => {
  return (dispatch, getState) => {
    return getActiveUserAPI()
  }
}


export const updateUserRoles = (data, id) => {
  return (dispatch, getState) => {
    return updateUserRolesAPI(data, id)
  }
}
