import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./invoice.scss";
import { errorSnackBar } from "./../../../actions/common.action";
import { INVOICES, RECEIVABLES } from "./../../../constants/route-paths";
import {
  getProjectList,
  getProject,
  getOptions,
} from "./../../../actions/project-management.action";
import { getCustomerList } from "./../../../actions/customer-management.action";
import {
  getProgressClaimList,
  saveInvoice,
} from "./../../../actions/invoice.action";
import _ from "lodash";
import { numberFormat } from "../../../util/currency-formatter";
import { warningSnackBar } from "./../../../actions/common.action";
import { withRouter } from "../../../util/with-router";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DatePickerInput from "src/components/inputs/date-picker";
import SelectInput from "src/components/inputs/select";

function CreateInvoice(props) {

  const [fields, setFields] = useState({
    customer: {
      name: "customer",
      value: "",
      items: [],
      type: "select",
    },
    workType: {
      name: "workType",
      value: "project",
      items: [{ key: "Project", value: "project" }],
      type: "select",
    },
    project: {
      name: "project",
      value: "",
      items: [],
      type: "select",
    },
    progressClaim: {
      name: "progressClaim",
      value: "",
      items: [],
      type: "select",
    },
    invoiceDate: {
      name: "invoiceDate",
      value: new Date(),
      type: "datepicker",
    },
    dueDate: {
      name: "dueDate",
      value: "",
      type: "datepicker",
    },
  })
  const [state, setState] = useState({
    projectsList: [],
    customersList: [],
    invoiceData: {},
    projectId: "",
    workType: "",
  })

  useEffect(() => {
    getCustomersList();
  }, [])

  const getCustomersList = () => {
    props.getCustomerList().then((customers) => {
      const customersList = [];
      customers.forEach((customer) =>
        customersList.push({ key: customer.businessName, value: customer.id })
      );
      setFields(prev => ({
        ...prev,
        customer: { ...prev.customer, items: customersList }
      }));
    });
  };

  const getProjectList = async (value = '') => {
    const customerId = value || fields.customer.value;
    let list = await props.getProjectList(null, null, customerId);

    let projects = [];
    list &&
      list.forEach(({ name, id }) => projects.push({ key: name, value: id }));

    projects = _.orderBy(projects, [({ key }) => key.toLowerCase()], ["asc"]);
    setFields(prev => ({
      ...prev,
      project: { ...prev.project, items: projects }
    }));
  };

  const getProgressClaims = async (projectId) => {
    let query = `?projectId=${projectId}`;
    let claims = await props.getProgressClaimList(query);
    const progressClaims = [];
    claims.forEach((claim) =>
      progressClaims.push({ key: claim.id, value: claim.u_id, ...claim })
    );
    setFields(prev => ({
      ...prev,
      progressClaim: { ...prev.progressClaim, items: progressClaims }
    }));
  };

  const changeCustomer = (value) => {
    const invoiceData = {};
    const workType = "";
    setFields(prev => ({
      ...prev,
      customer: { ...prev.customer, value: value },
      project: { ...prev.project, value: '' },
      progressClaim: { ...prev.progressClaim, value: '' },
      dueDate: { ...prev.dueDate, value: '' },
      invoiceDate: { ...prev.invoiceDate, value: '' }
    }));
    setState(prev => ({
      ...prev,
      workType, invoiceData, projectId: ""
    }));
    getProjectList(value)
  };

  const changeProject = (value) => {
    const invoiceData = {};
    setFields(prev => ({
      ...prev,
      project: { ...prev.project, value: value },
      progressClaim: { ...prev.progressClaim, value: '' },
      dueDate: { ...prev.dueDate, value: '' },
      invoiceDate: { ...prev.invoiceDate, value: '' },
    }));
    setState(prev => ({
      ...prev,
      projectId: value, invoiceData
    }));
    getProgressClaims(value);
  };

  const changeClaim = (value) => {
    const claims = fields.progressClaim.items;
    const invoiceData = claims.find((claim) => claim.value == value);
    setFields(prev => ({
      ...prev,
      progressClaim: { ...prev.progressClaim, value: value },
    }));
    setState(prev => ({
      ...prev,
      invoiceData: invoiceData
    }));
  };

  const changeDate = (value, type) => {
    setFields(prev => ({
      ...prev,
      ...(type == "invoice" ? { invoiceDate: { ...prev.invoiceDate, value: value } } : {}),
      ...(type != "invoice" ? { dueDate: { ...prev.dueDate, value: value } } : {}),
    }));
  };

  const cancelHandler = () => {
    props.history(`/${RECEIVABLES}/${INVOICES}`);
  };

  const generateInvoice = async () => {
    if (fields.progressClaim.value === "") {
      props.errorSnackBar("Please Select Progress Claim");
      return;
    }
    if (fields.invoiceDate.value === "") {
      props.errorSnackBar("Please Select Invoice Date");
      return;
    }
    if (fields.dueDate.value === "") {
      props.errorSnackBar("Please Select Due Date");
      return;
    }
    const data = {
      progressClaimId: fields.progressClaim.value,
      dueDate: fields.dueDate.value,
      invoiceDate: fields.invoiceDate.value,
    };
    await props.saveInvoice(data);
    cancelHandler();
  };

  return (
    <div className="purchase-order-block">
      <h2 className="content-heading text-uppercase">Create Invoice</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>INVOICE</Breadcrumb.Item>
          <Breadcrumb.Item active>Create Invoice</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 mt-3">
          <SelectInput
            {...fields.customer}
            label={'Customer'}
            onChange={(e) => changeCustomer(e.target.value)}
          />
        </div>

        <div className="col-lg-4 col-md-6 mt-3">
          <SelectInput
            {...fields.project}
            label={'Project'}
            onChange={(e) => changeProject(e.target.value)}
          />
        </div>
        <div className="col-lg-4 col-md-6 mt-3">
          <SelectInput
            {...fields.progressClaim}
            label={'Progress Claim'}
            onChange={(e) => changeClaim(e.target.value)}
          />
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <DatePickerInput
            {...fields.invoiceDate}
            label={'Invoice Date'}
            onChange={(e) => changeDate(e, "invoice")}
          />
        </div>

        <div className="col-lg-4 col-md-4 mt-3">
          <DatePickerInput
            {...fields.dueDate}
            label={'End Date'}
            onChange={(e) => changeDate(e, "due")}
          />
        </div>
      </div>
      <div className="material-list-block mt-3">
        <div className="table-responsive">
          <Table bordered className="table-create table-material table-material-ch w-100">
            <thead>
              <tr>
                <th>Description</th>
                <th className="text-right">Ex-GST</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contract Price</td>
                <td className="text-right"> {numberFormat(state.invoiceData?.contractPrice || 0)}</td>
              </tr>
              <tr>
                <td>Variations</td>
                <td className="text-right">{numberFormat(state.invoiceData?.variation || 0)}</td>
              </tr>
              <tr>
                <td>Revised Contract Price</td>
                <td className="text-right">{numberFormat((state.invoiceData?.contractPrice || 0) + (state.invoiceData?.variation || 0))}</td>
              </tr>
              <tr>
                <td>Total Value of Work Complete</td>
                <td className="text-right">{numberFormat(state.invoiceData?.totalValue || 0)}</td>
              </tr>
              <tr>
                <td>Less Previously Claimed</td>
                <td className="text-right"> {numberFormat(state.invoiceData?.previousClaim || 0)}</td>
              </tr>
              <tr>
                <td>This Claim Amount</td>
                <td className="text-right">{numberFormat(state.invoiceData?.claimAmount || 0)}</td>
              </tr>
              <tr>
                <td>Contract Balance</td>
                <td className="text-right">{numberFormat((state.invoiceData?.contractPrice || 0) + (state.invoiceData?.variation || 0) - (state.invoiceData?.claimAmount || 0))}</td>
              </tr>
              <tr className="tblfooter">
                <td className="text-right">Sub Total</td>
                <td className="text-right">{numberFormat(state.invoiceData?.claimAmount || 0)}</td>
              </tr>
              <tr className="tblfooter">
                <td className="text-right">GST</td>
                <td className="text-right">{numberFormat((state.invoiceData?.claimAmount || 0) / 10)}</td>
              </tr>
              <tr className="tblfooter">
                <td className="text-right"><b>Total Inc-GST</b></td>
                <td className="text-right"><b>{numberFormat(((state.invoiceData?.claimAmount || 0) + (state.invoiceData?.claimAmount || 0)) / 10)}</b></td>
              </tr>
            </tbody>
          </Table>
        </div>

      </div>



      <div className="col-12 d-flex mt-3">
        <Button onClick={generateInvoice} className="ms-auto me-3 secondarybtn">SUBMIT</Button>
        <Button onClick={cancelHandler} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
      </div>
    </div>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getCustomerList: bindActionCreators(getCustomerList, dispatch),
  getProgressClaimList: bindActionCreators(getProgressClaimList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getOptions: bindActionCreators(getOptions, dispatch),
  saveInvoice: bindActionCreators(saveInvoice, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateInvoice));
