import { minifySubscription } from 'src/util/enums/subscriptions';
import {
  SAVE_USER_DATA,
  REMOVE_USER_DATA
} from '../constants/action.constant'

const initialState = {
  userData: {
    isLoggedIn: false,
    name: '',
    role: '',
    email: '',
    phoneNumber: '',
    permissions: {
      dashboard: 0,
      customer: 0,
      supplier: 0,
      finance: 0,
      contractor: 0,
      catalog: 0,
      template: 0,
      inventory: 0,
      asset: 0,
      timesheetApprover: 0,
      workforce: 0,
      user: 0,
      globalSetting: 0,
      projectMAJOR: 0,
      projectMINOR: 0,
      projectMAJORFinance: 0,
      projectMINORFinance: 0,
      dashboardProjects: 0,
      userAdmin:0
    },
    account: {},
    global_user: 0,
    selected_account: { id: '', name: '' },
  },
  subscription_metadata: {},
  entitlements: {}
}

const saveUserData = (state, action) => {
  const { firstName, lastName, roles, permission, email, phone_number, id, accounts, is_global, selected_account } = action.data
  let allocatedPermissions = (roles && roles.modules) || {};
  let subscription_metadata = selected_account?.subscription_metadata || {}
  let isGlobalUser = is_global === 1 ? true : false;

  if (permission) allocatedPermissions = permission;
  if (isGlobalUser && !selected_account.id) {
    allocatedPermissions = initialState.userData.permissions
    allocatedPermissions.timesheetManagement = 0
  }

  let entitlements = minifySubscription(subscription_metadata?.raw, isGlobalUser)

  const userData = {
    firstName: firstName,
    lastName: lastName,
    name: [firstName, lastName].filter(item => item).join(' '),
    role: (roles && roles.name) || '',
    id: id,
    email: email,
    phoneNumber: phone_number,
    permissions: allocatedPermissions || {},
    account: accounts || {},
    global_user: isGlobalUser,
    selected_account: selected_account,
  }
  return ({
    ...state,
    userData,
    isLoggedIn: true,
    subscription_metadata: subscription_metadata,
    entitlements
  })
}

const removeUserData = (state, data) => {
  return { ...state, isLoggedIn: false }
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DATA: return saveUserData(state, action)
    case REMOVE_USER_DATA: return removeUserData(state, action)
    default: return state
  }
}

export default userReducer
