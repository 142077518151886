import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/po.scss";
import { CONTRACTORS, WORK_ORDER } from "./../../../constants/route-paths";
import { getPurchaseOrder } from "./../../../actions/project-management.action";
import idx from "idx";
import { numberFormat } from "../../../util/currency-formatter";
import { withRouter } from "../../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { Button } from "react-bootstrap";

function ViewWorkOrder(props) {
  const [PO, setPO] = useState({});
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getPO();
  }, []);

  const getPO = async () => {
    const poId = props.params.poId;
    const query = `?poId=${poId}`;
    let res = await props.getPurchaseOrder(query);
    setPO(res);
    let totalCost = 0;

    Array.isArray(res.items) &&
      res.items.forEach(({ total }) => (totalCost += total));
    setTotal(totalCost);
  };

  const cancelHandler = () => {
    props.history(`/${CONTRACTORS}/${WORK_ORDER}`);
  };

  return (
    <div className="purchase-order-block">
      <h2 className="content-heading text-uppercase">View WO</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>Work Order</Breadcrumb.Item>
          <Breadcrumb.Item active>View WO</Breadcrumb.Item>
        </Breadcrumb>

      </div>

      <div className="row">
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>PO Number</label>
            <p>{PO.id}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Vendor Type</label>
            <p>Contractor</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Vendor Name</label>
            <p>{idx(PO, (_) => _.vendor.business_name) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Delivery Option</label>
            <p>{idx(PO, (_) => _.deliveryOption) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Delivery Date</label>
            <p>{idx(PO, (_) => _.deliveryDate) ? moment(idx(PO, (_) => _.deliveryDate)).format('YYYY-MM-DD HH:mm') : "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Notes</label>
            <p>{idx(PO, (_) => _.notes) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Street Address</label>
            <p>{idx(PO, (_) => _.street_address) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Suburb</label>
            <p>{idx(PO, (_) => _.suburb) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>State</label>
            <p>{idx(PO, (_) => _.state) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Postcode</label>
            <p>{idx(PO, (_) => _.postcode) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Country</label>
            <p>{idx(PO, (_) => _.country) || "-"}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Total</label>
            <p>{numberFormat(total)}</p>
          </div>
        </div>
      </div>


      <div className="material-list-block mt-3">
        <div className="table-responsive">
          <Table bordered className="table-create table-material table-material-ch">
            <thead>
              <tr>
                <th className="w-auto">Name</th>
                <th className="w-auto">Type</th>
                <th className="text-right">Quotation</th>
              </tr>
            </thead>
            <tbody>
              {(PO.items || []).map((item, index) => (
                <tr key={item.id}>
                  <td>{item.taskName}</td>
                  <td>{item.taskDescription}</td>
                  <td className="text-right">{numberFormat(item.total)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="col-12 d-flex mt-3 justify-content-end">
        <Button type="submit" onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewWorkOrder)
);
