import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './../modal/modal.scss'
import Modal from '../modal/modal'
import { warningSnackBar } from '../../actions/common.action'
import SelectInput from '../inputs/select';

function DuplicateProject(props) {
  const { open, dialogClose, customersList } = props

  const [customerId, setCustomerId] = useState('')

  const duplicateProject = () => {
    if (!customerId) {
      props.warningSnackBar('Please Select Customer')
      return
    }
    dialogClose(customerId)
  }

  return (
    <Modal
      open={open}
      onClose={() => dialogClose()}
      fullWidth={true}
      maxWidth='sm'
      modalHeading={'Duplicate Project'}
      modalSaveTxt='Yes'
      modalCloseTxt='No'
      onClick={duplicateProject}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <div className="col-12 mt-3 mb-3">
        <SelectInput
          name={'templateSelect'}
          label={'Select Customer'}
          items={customersList}
          onChange={(e) => setCustomerId(e.target.value)}
          value={customerId}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateProject);