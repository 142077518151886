import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { geocodeByPlaceID } from "mui-places-autocomplete";
import {
  getGlobalSetting,
  editGlobalSetting,
  deleteGlobalSetting,
  getGlobalSettingReducer
} from "../../actions/global-setting.action";
import { errorSnackBar } from "../../actions/common.action";
import { emailValidator } from "../../util/validators/email";
import DeleteDialog from "../../components/delete-dialog";
import "./scss/templates.scss";
import idx from "idx";
import CustomInput from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { phoneValidator } from "src/util/validators/phone";
import { websiteValidator } from "src/util/validators/website";
import moment from "moment";
import TimePickerInput from "src/components/inputs/time-picker";
import Form from 'react-bootstrap/Form';
import _ from "lodash";

class LetterheadSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        letterhead_address: {
          name: "letterhead_address",
          label: "Company Address",
          type: "address",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        letterhead_name: {
          name: "letterhead_name",
          label: "Company Name",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        letterhead_email: {
          name: "letterhead_email",
          label: "Company Email",
          type: "email",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "email"],
        },
        letterhead_phoneno: {
          name: "letterhead_phoneno",
          label: "Company Phone",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "phoneNumber"],
        },
        letterhead_website: {
          name: "letterhead_website",
          label: "Company Website",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: [],
        },
        finance_overtime: {
          name: "finance_overtime",
          label: "OverTime Rates",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "positive"],
        },
        finance_gst: {
          name: "finance_gst",
          label: "GST Rate",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "positive"],
        },
        start_time: {
          name: "start_time",
          label: "Standard Work Start Time",
          type: "timepicker",
          format: "LT",
          touched: false,
          error: false,
          valid: true,
          value: null,
          validation: ["required"],
        },
        finish_time: {
          name: "finish_time",
          label: "Standard Work Finish Time",
          type: "timepicker",
          format: "LT",
          touched: false,
          error: false,
          valid: true,
          value: null,
          validation: ["required"],
        },
        total_hrs: {
          name: "total_hrs",
          label: "Standard Work Hours per Day",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "single_digit"],
        },
        workingDays: {
          name: "workingDays",
          label: "Standard Working Days",
          days: {
            monday: {
              label: "Monday",
              value: false,
            },
            tuesday: {
              label: "Tuesday",
              value: false,
            },
            wednesday: {
              label: "Wednesday",
              value: false,
            },
            thursday: {
              label: "Thursday",
              value: false,
            },
            friday: {
              label: "Friday",
              value: false,
            },
            saturday: {
              label: "Saturday",
              value: false,
            },
            sunday: {
              label: "Sunday",
              value: false,
            },
          },
          value: [],
          touched: false,
          error: false,
          valid: true,
          validation: ["required"],
        },
        sunday_as_new_week: {
          name: "sunday_as_new_week",
          label: "Include Sunday in the new Week",
          hideLabel: true,
          type: "checkbox",
          value: false,
          touched: false,
          error: false,
          valid: true,
          validation: [],
        },
      },
      images: {
        logo: "",
        signature: "",
      },
      fileToUpload: {
        logo: [],
        signature: [],
      },
      selectFileType: "",
      deleteDialogState: false,
      deleteObject: null,
      allowedFileExtension: {
        images: [".png", ".jpg", ".jpeg"],
      },
    };
  }

  componentDidMount() {
    this.getLetterheadSetting();
  }

  getLetterheadSetting = async (overrideFiles = false) => {
    let { fields, images } = this.state;
    const letterheadData = await this.props.getGlobalSetting("LETTERHEAD");
    if (idx(letterheadData, (_letterheadData) => _letterheadData.data))
      Object.entries(letterheadData.data).forEach(([key, value]) => {
        if (key in fields) {
          fields[key].value = value || "";
        } else {
          images[key] = value || "";
        }
      });

    await this.props.getGlobalSettingReducer("FINANCE_TAX");

    const financeData = idx(this.props.globalSetting, (_) => _.finance_tax) || {};
    financeData && Object.entries(financeData).forEach(([key, value]) => {
      if (key in fields) fields[key].value = value || "";
    });

    fields.workingDays.value = "";
    let workingDays = idx(financeData, (_) => _.workingDays.days) || {};
    for (let day in workingDays) {
      fields.workingDays.days[day].value = workingDays[day];
    }

    if (!fields.start_time.value && !fields.finish_time.value) {
      let start = new Date().setHours(7, 0, 0, 0);
      let end = new Date().setHours(15, 30, 0, 0);
      fields.start_time.value = start;
      fields.finish_time.value = end;
    }

    this.setState({
      fields,
      images,
      selectFileType: "",
      ...(overrideFiles ? { fileToUpload: { logo: [], signature: [] } } : {})
    });
  };

  editFinanceSetting = async () => {
    this.validateWorkingDays();
    const { fields } = this.state;
    let [isFormValid, dataToSend] = [true, {}];
    const financeData = idx(this.props.globalSetting, (_) => _.finance_tax) || {};

    fields && Object.entries(fields).forEach(([key, value]) => {
      const { error, valid } = this.handleValidation(value);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
      dataToSend[key] = value.value;
    });

    const start = moment(fields.start_time.value);
    const finish = moment(fields.finish_time.value);

    if (start.format("hh:mm a") === finish.format("hh:mm a")) {
      fields.start_time.valid = false;
      fields.start_time.error = "Start time and Finish time are the same. Please adjust.";
      fields.finish_time.valid = false;
      fields.finish_time.error = "Start time and Finish time are the same. Please adjust.";
      isFormValid = false;
    }

    if (start.isSameOrAfter(finish)) {
      fields.finish_time.valid = false;
      fields.finish_time.error = "Finish Time must be after the Start Time.";
      isFormValid = false;
    }

    delete dataToSend.workingDays;
    let workingDays = { days: {} };
    for (let key in fields.workingDays.days) {
      workingDays.days[key] = fields.workingDays.days[key].value;
    }
    dataToSend["workingDays"] = workingDays;
    if (!isFormValid) {
      if (!fields.workingDays.value.length) this.props.errorSnackBar(`Please select at least one ${fields.workingDays.label}`);
      this.setState({ fields });
      return;
    }

    const difference = _.difference(_.keys(financeData), _.keys(dataToSend));
    const FinanceAndTaxTabData = _.pick(financeData, difference);
    const mergedObj = _.merge(dataToSend, FinanceAndTaxTabData);
    dataToSend = mergedObj;

    await this.props.editGlobalSetting(dataToSend, "FINANCE_TAX");
  };

  editLetterheadSetting = async () => {
    this.editFinanceSetting()
    const { fields, fileToUpload, images } = this.state;
    let isFormValid = true;

    fields && Object.entries(fields).forEach(([key, value]) => {
      const { error, valid } = this.handleValidation(value);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    });
    if (!isFormValid) {
      this.setState({ fields });
      return;
    }

    const dataToSend = new FormData();

    fields && Object.entries(fields).forEach(([key, value]) => {
      dataToSend.set(key, value.value);
    });

    fields && Object.entries(images).forEach(([key, value]) => {
      dataToSend.set(key, value);
    });

    Array.isArray(fileToUpload.logo) && fileToUpload.logo.forEach((file) => {
      dataToSend.append(`logo`, file);
    });

    Array.isArray(fileToUpload.signature) && fileToUpload.signature.forEach((file) => {
      dataToSend.append(`signature`, file);
    });

    await this.props.editGlobalSetting(dataToSend, "LETTERHEAD");
    this.getLetterheadSetting(true);
  };

  onSuggestionSelected = (suggestion) => {
    geocodeByPlaceID(suggestion.place_id).then((results) => {
      const e = { target: { value: results[0].formatted_address, name: "address" } };
      this.inputChangeHandler(e);
    }).catch((err) => {
      console.error("Error", err);
    });
  };

  inputChangeHandler = (e, key = '') => {
    let fields = this.state.fields, isFormValid = true, value = "";

    if (key === "sunday_as_new_week") value = e.target.checked;
    else value = (key === "start_time" || key === "finish_time") ? e : e.target.value;

    fields[key || e.target.name].value = value;
    fields[key || e.target.name].touched = true;

    const { error, valid } = this.handleValidation(fields[e.target.name || key]);

    fields[key || e.target.name].error = error;
    fields[key || e.target.name].valid = valid;

    Object.values(fields).every(field => isFormValid && !field.error)

    this.setState({ fields, isFormValid });
  };

  daysInputChangeHandler = (e, name = "") => {
    var value = e.target.checked || false;
    let fields = this.state.fields;
    fields.workingDays.days[name].value = value;
    fields.workingDays.touched = true;
    this.setState({ fields: fields });
  };

  validateWorkingDays() {
    let fields = this.state.fields;
    fields.workingDays.touched = true;
    fields.workingDays.value = [];
    for (let key in fields.workingDays.days) {
      if (fields.workingDays.days[key].value)
        fields.workingDays.value.push(key);
    }
    if (fields.workingDays.value.length === 0) {
      fields.workingDays.error = `Please select at least one ${fields.workingDays.label}`;
      fields.workingDays.valid = false;
    }
    let isFormValid = true;
    for (let key of Object.keys(fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }

    this.setState({ fields: fields, isFormValid });
  }

  handleValidation = ({ value = "", validation = [] }) => {
    let [error, valid] = [false, true];

    if (!validation.includes("skip") && validation.includes("required") && (value === null || value === ""))
      return { error: "This Field is Required", valid: false }
    else if (!validation.includes("skip") && validation.includes("email")) {
      if (!emailValidator(value)) return { error: "Please Enter Valid Email", valid: false };
    } else if (!validation.includes("skip") && validation.includes("phoneNumber")) {
      if (!phoneValidator(value)) return { error: "Please Enter Valid Mobile Number", valid: false };
    } else if (!validation.includes("skip") && validation.includes("website")) {
      if (!websiteValidator(value)) return { error: "Please Enter Valid URL", valid: false }
    } else if (validation.includes("required") && (value === null || value === "" || value.length === 0))
      return { error: "This Field is Required", valid: false }
    else if (validation.includes("positive") && (isNaN(value) || Number(value) <= 0))
      return { error: "Enter Positive Number!", valid: false }
    else if (validation.includes("email")) {
      let status = emailValidator(value);
      if (!status) return { error: "Email is not valid. Please Enter a valid Email format", valid: false, };
    } else if (validation.includes("single_digit") && (Number(value) >= 10 || Number(value) <= 0))
      return { error: "Please Enter a value between (1-9)", valid: false }

    return { error, valid };
  };

  handleSelectFile = (selectFileType) => {
    this.setState({ selectFileType }, () => {
      document.getElementById("fileInput").click();
    });
  };

  handleFileUpload = (e) => {
    let { selectFileType, fileToUpload, allowedFileExtension } = this.state
    let isValid = true;

    Array.from(e.target.files).map((file) => {
      const fileExt = file.name.substring(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      if (file.size > 1250000) {
        isValid = false;
        this.props.errorSnackBar("File size is too big. Maximum 1.25MB");
      }
      if (!allowedFileExtension.images.some((item) => item.includes(fileExt))) {
        isValid = false;
        this.props.errorSnackBar(`File format invalid! Allowed formats (${allowedFileExtension.images.join(",")})`
        );
      }
    });

    if (isValid)
      this.setState({
        fileToUpload: {
          ...fileToUpload,
          [selectFileType]: [...e.target.files],
        },
      });

    e.target.value = "";
  };
  deleteGlobalSetting = async (deleteObject) => {
    await this.props.deleteGlobalSetting(deleteObject.type, "LETTERHEAD");
    this.deleteDialogHandler();
    this.getLetterheadSetting();
  };

  deleteDialogHandler = (object = null) => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteObject: object,
    }));
  };

  render() {
    const {
      fields,
      images,
      deleteDialogState,
      deleteObject,
      allowedFileExtension,
      fileToUpload,
    } = this.state;
    const writePermission = this.props.writePermission;
    let workingDaysFormElements = Object.keys(fields.workingDays.days).map((key) => {
      return (
        <Form key={key} className="usercheckbox me-3">
          <div className="table-checkbox">
            <Form.Check
              type={'checkbox'}
              id={`${key}-check`}
              label={fields.workingDays.days[key].label}
              value={fields.workingDays.days[key].value}
              checked={fields.workingDays.days[key].value}
              onChange={(e) => this.daysInputChangeHandler(e, key)}
            />
          </div>
        </Form>
      );
    });

    return (
      <>
        <DeleteDialog
          open={deleteDialogState}
          id={deleteObject}
          deleteHandler={this.deleteGlobalSetting}
          dialogClose={this.deleteDialogHandler}
          moduleName={"Selected File"}
        />
        <div className="purchase-order-block">
          <div className="row">
            <div className="col-12">
              <h2 className="content-heading content-heading-lg text-uppercase mt-0 me-auto">Company Details</h2>
            </div>

            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-6 mt-3">
                  <CustomInput
                    {...fields.letterhead_name}
                    disabled={!writePermission}
                    onChange={this.inputChangeHandler}
                  />
                </div>
                <div className="col-lg-6 col-md-6 mt-3">
                  <CustomInput
                    {...fields.letterhead_email}
                    disabled={!writePermission}
                    onChange={this.inputChangeHandler}
                  />
                </div>
                <div className="col-lg-6 col-md-6 mt-3">
                  <CustomInput
                    {...fields.letterhead_phoneno}
                    disabled={!writePermission}
                    onChange={this.inputChangeHandler}
                  />
                </div>
                <div className="col-lg-6 col-md-6 mt-3">
                  <CustomInput
                    {...fields.letterhead_website}
                    disabled={!writePermission}
                    onChange={this.inputChangeHandler}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 mt-3">
              <TextAreaInput
                {...fields.letterhead_address}
                disabled={!writePermission}
                onChange={this.inputChangeHandler}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                {...fields.finance_overtime}
                disabled={!writePermission}
                onChange={(e) => this.inputChangeHandler(e, fields.finance_overtime.name)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                {...fields.finance_gst}
                disabled={!writePermission}
                onChange={(e) => this.inputChangeHandler(e, fields.finance_gst.name)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <TimePickerInput
                {...fields.start_time}
                disabled={!writePermission}
                onChange={(e) => this.inputChangeHandler(e, fields.start_time.name)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <TimePickerInput
                {...fields.finish_time}
                disabled={!writePermission}
                onChange={(e) => this.inputChangeHandler(e, fields.finish_time.name)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                {...fields.total_hrs}
                disabled={!writePermission}
                onChange={(e) => this.inputChangeHandler(e, fields.total_hrs.name)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <h2 className="content-heading content-heading-lg text-uppercase my-auto me-auto">
                Working Days
              </h2>
            </div>
            <div className="col-12 mt-3 text-left">
              {workingDaysFormElements}
            </div>
            <div className="col-lg-4 col-md-6 mt-3 text-left">
              <Form className="usercheckbox">
                <div className="table-checkbox">
                  <Form.Check
                    type={'checkbox'}
                    id={`sunday-as-new-week-check`}
                    label={fields.sunday_as_new_week.label}
                    value={fields.sunday_as_new_week.value}
                    checked={fields.sunday_as_new_week.value}
                    onChange={(e) => this.inputChangeHandler(e, fields.sunday_as_new_week.name)}
                  />
                </div>
              </Form>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex gap-5">
              <h2 className="content-heading content-heading-lg text-uppercase my-auto">Logo</h2>
              {writePermission && (<Button onClick={() => this.handleSelectFile("logo")} className="secondarybtn">
                Update
              </Button>)}
            </div>

            <div className="col-lg-4 col-md-4 mt-3">
              {fileToUpload.logo.length > 0 ?
                <img
                  src={`${window.URL.createObjectURL(new Blob([fileToUpload.logo[0]]))}`}
                  className='w-100'
                  alt="logo"
                /> : images.logo && (
                  <img crossorigin="anonymous" src={images.logo} alt="logo" className='w-100' />
                )}
            </div>
            <div className="col-lg-4 col-md-4 col-6 align-self-center text-left">
              {images.logo && writePermission && (fileToUpload.logo || []).length < 1 && (
                <div className="w-auto ms-auto">
                  <span
                    onClick={() =>
                      this.deleteDialogHandler({
                        url: images.logo,
                        type: "logo",
                      })
                    }
                    className="filter-icon text-danger">
                    <DeleteIcon />
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-md-4 col-6 mt-3">
              <input
                style={{ marginBottom: "15px", display: "none" }}
                id="fileInput"
                name="attachments"
                type="file"
                onChange={this.handleFileUpload}
                accept={allowedFileExtension.images.join(",")}
              />
            </div>

          </div>

          {writePermission && (<div className="col-12 d-flex mt-3">
            <Button onClick={this.editLetterheadSetting} className="ms-auto me-3 secondarybtn">SAVE</Button>
          </div>)}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  globalSetting: state.globalSetting
});

const mapDispatchToProps = (dispatch) => ({
  getGlobalSetting: bindActionCreators(getGlobalSetting, dispatch),
  editGlobalSetting: bindActionCreators(editGlobalSetting, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  deleteGlobalSetting: bindActionCreators(deleteGlobalSetting, dispatch),
  getGlobalSettingReducer: bindActionCreators(getGlobalSettingReducer, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LetterheadSetting);
