import React, { useEffect, useState } from "react";
import { ScheduleDatePickerInput } from "src/components/inputs/date-picker";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import Table from 'react-bootstrap/Table';
import CloseIcon from '@mui/icons-material/Close';
import '../project-management/scss/task.scss';
import { connect } from "react-redux";
import SelectInput from "src/components/inputs/select";
import { bindActionCreators } from "redux";
import { getRolesList, getUserList } from "src/actions/user-management.action";
import idx from "idx";
import AutocompleteInput from "src/components/inputs/autocomplete";
import CustomInput from "src/components/inputs/new-input";
import { getResourceAllocations, getUserLeaves } from "src/actions/timesheet.action";
import {
  isBefore,
  isAfter,
  subWeeks,
  format,
  eachDayOfInterval,
  isSameDay,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  parse,
  differenceInMinutes,
  addMinutes,
  differenceInDays,
} from 'date-fns';
import { getGlobalSettingReducer } from "src/actions/global-setting.action";
import { warningSnackBar } from "src/actions/common.action";
import { SelectableGroup, createSelectable } from 'react-selectable';
import _ from 'lodash';
import ScheduleAllocateResource from "./schedule-allocate-resource";
import { v4 as uuidv4 } from "uuid";

const SelectableItem = ({
  key,
  selected,
  children
}) => {

  return (
    <div key={key} selected={selected} selectableKey={key}>
      {children}
    </div>
  )
};

const SelectableComponent = createSelectable(SelectableItem);

function Schedule({ writePermission, ...props }) {

  const today = new Date();
  const [state, setState] = useState({
    timesheetApprover: false,
    usersList: [],
    roles: [],
    allocations: {}
  })
  const [search, setSearch] = useState({
    filter: {
      from: subWeeks(today, 1),
      to: today
    },
    dates: eachDayOfInterval({
      start: subWeeks(today, 1),
      end: today,
    }),
    result: []
  })
  const [showAllocateDialog, setShowAllocateDialog] = useState(false);

  useEffect(() => {
    getUsersList();
    getRolesList();
  }, [])

  const getUsersList = async (isSearch) => {
    const { permissions } = props.user;
    if (!permissions.timesheetApprover) {
      setState(prev => ({
        ...prev,
        timesheetApprover: false
      }));
      props.warningSnackBar(`Sorry, you don't have permissions to do this action`)
      return;
    }
    const status = "ACTIVE";
    let filter = {};
    if (idx(search, _ => _.filter.name)) filter.name = idx(search, _ => _.filter.name)
    if (idx(search, _ => _.filter.role)) filter.role = idx(search, _ => _.filter.role)
    if (idx(search, _ => _.filter.cost)) filter.cost = idx(search, _ => _.filter.cost)

    const list = await props.getUserList({ status, filter: filter });
    const usersList = (Array.isArray(list) && list.map(({ id, firstName, lastName, available_days = {}, hourlyRate }) => ({
      key: [firstName, lastName].filter(item => item).join(' '),
      value: id,
      rate: hourlyRate,
      selected: false,
      dates: [],
      available_days: idx(available_days, _ => _.days) || {
        friday: true,
        monday: true,
        saturday: true,
        sunday: true,
        thursday: true,
        tuesday: true,
        wednesday: true
      }
    }))) || [];

    if (isSearch) {
      setSearch(prev => ({
        ...prev,
        result: usersList
      }));
    } else {
      setState(prev => ({
        ...prev,
        usersList,
        timesheetApprover: true
      }));
    }
  };

  const getRolesList = async () => {
    props.getRolesList().then((roles) => {
      const userRoles = roles.map((role) => {
        return {
          key: role.name,
          value: role.id,
        };
      });
      setState(prev => ({
        ...prev,
        roles: userRoles
      }));
    });

    let globalSetting = props.globalSetting
    if (!Object.keys(globalSetting.finance_tax).length) {
      await props.getGlobalSettingReducer('FINANCE_TAX')
    }
  };

  const addSearchUser = (user = []) => {
    setSearch(prev => ({
      ...prev,
      result: [...prev.result, ...user]
    }))
  }

  const getAllocations = async (id) => {
    setState(prev => ({
      ...prev,
      allocations: { ...prev.allocations, [id]: [] }
    }));
    Promise.all([getResourceAllocations(id), getResourceLeaves(id)]).then(([works, leaves]) => {
      let newAllocations = calculateAvailableDays([...(search.dates || [])], { userId: id, allocations: [...works.allocations, ...leaves.allocations] })
      setState(prev => ({
        ...prev,
        allocations: { ...prev.allocations, [id]: newAllocations }
      }));
    });
  }

  const getMinutes = (value) => {
    var hours = Math.floor(value);;
    var minutes = (value - hours) % 60;
    return Math.abs(Math.round(hours * 60 + minutes));
  }

  const calculateAvailableDays = (days, { allocations }) => {
    let startTime = new Date(idx(props, _ => _.globalSetting.finance_tax.start_time) || '')
    let endTime = new Date(idx(props, _ => _.globalSetting.finance_tax.finish_time) || '')
    let totalHrs = Number(idx(props, _ => _.globalSetting.finance_tax.total_hrs) || 8)
    let totalMins = getMinutes(totalHrs)
    let newAllocations = [];
    for (const iterator of days) {
      let iteratorAllocations = allocations.filter(item => isSameDay(item.start, iterator)).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
      let mins = 0;
      let startHr = startTime.getHours(), startMin = startTime.getMinutes();
      iteratorAllocations.map(item => {
        let diffMins = Math.abs(differenceInMinutes(item.start, item.end))
        mins += item.type == 'LEAVE' && item.nature == 'LONG_TERM' ? totalMins : diffMins
        if (diffMins < totalMins) {
          startHr = new Date(item.end).getHours();
          startMin = new Date(item.end).getMinutes();
        }
      })
      if (mins == 0) {
        newAllocations.push({
          start: iterator.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0),
          end: iterator.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0),
          allocationId: `tmp-${uuidv4()}`,
          class: 'available',
          display: 'Available',
          type: "AVAILABLE",
        })
      } else if (mins < totalMins) {
        let _start = iterator.setHours(startHr, startMin, 0, 0);
        let _end = addMinutes(_start, totalMins - mins);
        if (!isSameDay(iterator, _end)) {
          _start = new Date(iterator.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0));
          _end = new Date(addMinutes(_start, totalMins - mins));
          // if ((_end.getHours() >= (idx(iteratorAllocations, _ => _[0].start) || startTime).getHours()) && (_end.getMinutes() >= (idx(iteratorAllocations, _ => _[0].start) || startTime).getMinutes())) {
          //   _end = iterator.setHours((idx(iteratorAllocations, _ => _[0].start) || startTime).getHours(), (idx(iteratorAllocations, _ => _[0].start) || startTime).getMinutes() - 1, 0, 0);
          // }
        }
        newAllocations.push({
          start: new Date(_start).getTime(),
          end: new Date(addMinutes(_start, totalMins - mins)).getTime(),
          allocationId: `tmp-${uuidv4()}`,
          class: 'partial-available',
          display: 'Partially Available',
          type: "AVAILABLE",
        })
      }
    }
    return _.uniqBy([...newAllocations, ...allocations], 'allocationId');
  }

  const getResourceAllocations = async (id) => {
    let allowedModules = [...props.allowedModules];
    const params = {
      userId: id,
      allowedModules,
    };
    const resourceAllocations = await props.getResourceAllocations(params);
    let allocationsEvent = [];
    Array.isArray(resourceAllocations) && resourceAllocations.forEach((allocation) => {
      let projectName;
      projectName = idx(allocation.project, (_) => _.name) || "";
      let startDate = new Date(allocation.startTime);
      let endDate = new Date(allocation.endTime)
      let diffDays = differenceInDays(startDate, endDate)
      if (diffDays > 1) {
        let days = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });
        for (let iterator of days) {
          let start = iterator.setHours(startDate.getHours, startDate.getMinutes, 0, 0);
          let end = iterator.setHours(endDate.getHours, endDate.getMinutes, 0, 0);
          allocationsEvent.push({
            start: start,
            end: end,
            title: `${projectName} ${allocation.taskName ? `| ${allocation.taskName}` : ``}`,
            projectId: idx(allocation.project, (_) => _.id),
            projectType: allocation.projectType,
            allocationResourceId: allocation.id,
            allocationId: allocation.id,
            status: allocation.status,
            class: 'booked',
            display: 'Booked',
            type: "WORK",
            previousType: 'WORK',
            userId: allocation.userId
          });
        }
      } else {
        allocationsEvent.push({
          start: startDate.getTime(),
          end: endDate.getTime(),
          title: `${projectName} ${allocation.taskName ? `| ${allocation.taskName}` : ``}`,
          projectId: idx(allocation.project, (_) => _.id),
          projectType: allocation.projectType,
          allocationResourceId: allocation.id,
          allocationId: allocation.id,
          status: allocation.status,
          class: 'booked',
          display: 'Booked',
          type: "WORK",
          previousType: 'WORK',
          userId: allocation.userId
        });
      }
    });
    return { allocations: allocationsEvent }
  };

  const getResourceLeaves = async (id) => {
    const leaves = await props.getResourceLeaves({ userId: id });
    let leavesEvent = [];
    Array.isArray(leaves) && leaves.map((leave) => {
      let startDate = new Date(leave.startTime);
      let endDate = new Date(leave.endTime)
      let diffDays = differenceInDays(startDate, endDate)
      if (diffDays > 1) {
        let days = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });
        for (let iterator of days) {
          leavesEvent.push({
            start: iterator.setHours(startDate.getHours, startDate.getMinutes, 0, 0),
            end: iterator.setHours(endDate.getHours, endDate.getMinutes, 0, 0),
            title: `${leave.type === "SHORT_TERM" ? "Short Term" : leave.type === "LONG_TERM" ? "Long Term" : ""} Leave`,
            allocationResourceId: leave.userId,
            allocationId: leave.id,
            nature: leave.type,
            class: 'not-available',
            display: 'Not Available',
            type: "LEAVE",
            previousType: 'LEAVE',
            userId: leave.userId
          });
        }
      } else {
        leavesEvent.push({
          start: startDate.getTime(),
          end: endDate.getTime(),
          title: `${leave.type === "SHORT_TERM" ? "Short Term" : leave.type === "LONG_TERM" ? "Long Term" : ""} Leave`,
          allocationResourceId: leave.userId,
          allocationId: leave.id,
          nature: leave.type,
          class: 'not-available',
          display: 'Not Available',
          type: "LEAVE",
          previousType: 'LEAVE',
          userId: leave.userId
        });
      }
    });
    return { allocations: leavesEvent }
  };

  const selectDates = (userId, selectedDates, nature = 'add') => {
    let res = _.cloneDeep(search.result);
    res = res.map(item => {
      if (item.value == userId) {
        let dates = idx(item, _ => _.dates) || [];
        // let temp = selectedDates.map(_date => format(_date, 'yyyy-MM-dd'))
        // if (nature == 'add') {
        //   item.dates = _.uniq([...dates, ...temp]);
        // } else {
        //   item.dates = dates.filter(_item => !temp.includes(_item))
        // }
        item.dates = _.xor(dates, selectedDates)
      }
      return item
    })
    setSearch(prev => ({
      ...prev,
      result: res
    }))
  }
  const addFilter = ({ name, value }) => {
    setSearch(prev => ({
      ...prev,
      filter: { ...prev.filter, [name]: value }
    }))
  }

  const addUser = (userId, isAdd) => {
    let updated = search.result.map(item => {
      return {
        ...item,
        selected: item.value == userId ? isAdd ? true : false : item.selected,
        ...(item.value == userId && !isAdd ? { dates: [] } : {})
      }
    });
    setSearch(prev => ({
      ...prev,
      result: updated
    }))
    if (isAdd) getAllocations(userId)
  }

  const dateInterval = ({ from, to }) => {
    let days = eachDayOfInterval({
      start: from,
      end: to,
    });
    setSearch(prev => ({
      ...prev,
      filter: { ...prev.filter, to: to, from: from },
      dates: days
    }))

    let _users = search.result.filter(item => item.selected)
    let allocations = {}
    for (const iterator of _users) {
      let _allocations = state.allocations[iterator.value] || []
      let newAllocations = calculateAvailableDays(days, { userId: iterator.value, allocations: _allocations })
      allocations[iterator.value] = newAllocations
    }
    setState(prev => ({
      ...prev,
      allocations: { ...prev.allocations, ...allocations }
    }));

  }

  const submitHandler = () => {
    let userIds = search.result.filter(item => item.selected).map(item => item.value)
    Promise.all([getResourceAllocations(userIds), getResourceLeaves(userIds)]).then(([works, leaves]) => {
      var work = _.groupBy(works.allocations, 'userId');
      var leave = _.groupBy(leaves.allocations, 'userId');
      let allocations = {}
      for (const iterator of userIds) {
        let _allocations = [...(work[iterator] || []), ...(leave[iterator] || [])]
        let newAllocations = calculateAvailableDays([...(search.dates || [])], { userId: iterator, allocations: _allocations })
        allocations[iterator] = newAllocations
      }
      setState(prev => ({
        ...prev,
        allocations: { ...prev.allocations, ...allocations }
      }));
      setShowAllocateDialog(false)
      let updated = search.result.map(item => {
        return {
          ...item,
          dates: []
        }
      });
      setSearch(prev => ({
        ...prev,
        result: updated
      }))
    });
  }

  return (
    <>
      {showAllocateDialog && <ScheduleAllocateResource
        show={showAllocateDialog}
        allocations={state.allocations || {}}
        userData={search.result.filter(item => item.selected) || []}
        handleClose={() => setShowAllocateDialog(false)}
        handleSubmit={() => submitHandler()}
      />}
      <div className="allocation-block">
        <div className="find-allocation">
          <div className="find-allocation-header">
            <h2>Find</h2>
          </div>
          <div className="find-allocation-form row m-0">
            <div className="col-12 col-xxl-12 col-md-4 col-lg-3 mt-3">
              <AutocompleteInput
                label={'Name'}
                items={state.usersList}
                value={idx(search, _ => _.filter.name) || ''}
                onChange={(value) => {
                  let item = (state.usersList || []).find(item => item.value == value)
                  if (item)
                    addSearchUser([{ ...item, selected: false }])
                }}
                addFilter={(value) => addFilter({ name: 'name', value: value })}
              />
            </div>
            <div className="col-12 col-xxl-12 col-md-4 col-lg-3 mt-3">
              <SelectInput
                label={'Role'}
                placeholder={'Select Role'}
                items={state.roles}
                value={idx(search, _ => _.filter.role) || ''}
                onChange={(e) => {
                  let item = (state.roles || []).find(item => item.value == e.target.value)
                  if (item) addFilter({ name: 'role', value: item.value })
                }}
              />
            </div>
            <div className="col-12 col-xxl-12 col-md-4 col-lg-3 mt-3">
              <CustomInput
                label={'Max. Cost'}
                type="number"
                value={idx(search, _ => _.filter.cost) || ''}
                onChange={(e) => {
                  if (e.target.value >= 0) addFilter({ name: 'cost', value: e.target.value })
                }}
              />
            </div>
            <div className="col-12 col-xxl-12 col-md-12 col-lg-3 mt-3 d-flex allocation-search-btn">
              {writePermission && <button onClick={() => getUsersList(true)} class="secondarybtn btn-ch ms-auto btn btn-primary">Search</button>}
            </div>
          </div>
          <div className="allocation-list">
            <ul>
              {search.result.map(_search => (
                <li key={_search.value}>
                  <p className={`d-flex m-0 ${_search.selected ? 'active' : ''}`}>
                    <span className="list-icon my-auto"><PersonIcon /></span>
                    <span className="list-info my-auto">{_search.key}</span>
                    <span className="list-action-icon ms-auto my-auto">
                      {_search.selected ? <ArrowForwardIosIcon /> : <AddCircleOutlineIcon onClick={() => addUser(_search.value, true)} />}
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="schedule-allocation">
          <div className="schedule-allocation-header d-flex mb-3">
            {/* <h2 className="mt-auto mb-0 me-auto">December 2023</h2> */}
            <button
              onClick={() => {
                let from = subWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1);
                let to = subWeeks(endOfWeek(today, { weekStartsOn: 1 }), 1);
                dateInterval({ from, to })
              }}
              class="secondarybtn secondarybtn-outline btn-ch mt-auto mb-0 me-2 btn btn-primary mt-auto">
              Previous Week
            </button>
            <button
              onClick={() => {
                let from = startOfWeek(today, { weekStartsOn: 1 });
                let to = endOfWeek(today, { weekStartsOn: 1 });
                dateInterval({ from, to })
              }}
              class="secondarybtn secondarybtn-outline btn-ch mt-auto mb-0 me-2 btn btn-primary mt-auto">
              Current Week
            </button>
            <button
              onClick={() => {
                let firstDayOfMonth = parse(format(today, "MMM-yyyy"), "MMM-yyyy", new Date());
                let from = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
                let to = endOfMonth(firstDayOfMonth);
                dateInterval({ from, to })
              }}
              class="secondarybtn secondarybtn-outline btn-ch mt-auto mb-0 btn btn-primary me-2 mt-auto">
              Current Month
            </button>
            {writePermission && <button
              onClick={() => {
                let selected = search.result.filter(item => item.selected)
                if (selected.length) {
                  setShowAllocateDialog(!showAllocateDialog)
                } else {
                  props.warningSnackBar('Please Select atleast a day to allocate')
                }
              }}
              class="secondarybtn go-btn btn-ch ms-auto btn btn-primary mt-auto">
              GO
            </button>}
            <div className="col-lg-4 col-md-5 ms-auto">
              <div className="d-flex">
                <ScheduleDatePickerInput
                  className="mt-0"
                  value={idx(search, _ => _.filter.from)}
                  onChange={(value) => {
                    let _value = new Date(value)
                    _value.setHours(0, 0, 0, 0)
                    if (idx(search, _ => _.filter.to)) {
                      if (isSameDay(_value, search.filter.to)) {
                      } else if (isAfter(_value, search.filter.to)) {
                        props.warningSnackBar(`To date is less than From Date`);
                        return;
                      }
                    }
                    dateInterval({ from: _value, to: search.filter?.to })
                  }} />
                <span className="my-auto mx-2">-</span>
                <ScheduleDatePickerInput
                  className="mt-0"
                  value={idx(search, _ => _.filter.to)}
                  onChange={(value) => {
                    let _value = new Date(value)
                    _value.setHours(0, 0, 0, 0)
                    if (idx(search, _ => _.filter.from)) {
                      if (isSameDay(_value, search.filter.from)) {
                      } else if (isBefore(_value, search.filter.from)) {
                        props.warningSnackBar('From date is greater than To date');
                        return;
                      }
                    }
                    dateInterval({ to: _value, from: search.filter?.from })
                  }
                  } />
              </div>
            </div>
          </div>
          <div className="schedule-allocation-block">
            <div className="material-list-block">
              <div className="col-12 p-0">
                <Table bordered className="table-create table-material table-material-ch">
                  <thead>
                    <tr>
                      <th><span>Time</span></th>
                      {(search.dates || []).map((date, index) => (
                        <th key={date.toISOString()}>{format(date, 'd MMM yyyy, E')}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {search.result.filter(item => item.selected).map((_search) => {
                      return (
                        <tr key={_search.value}>
                          <td>
                            <p className="timeinfo active">
                              <span className="usericon"><PersonIcon /></span>
                              <span className="my-auto ms-1">{_search.key}</span>
                              <span className="closeicon"><CloseIcon onClick={() => addUser(_search.value, false)} /></span>
                            </p>
                          </td>
                          <td colSpan={(search.dates || []).length} className='selectable-block'>
                            <SelectableGroup
                              // onNonItemClick={(e) => console.log(e)}
                              onEndSelection={(keys) => selectDates(_search.value, keys)}
                            >
                              <table>
                                <tr>
                                  {(search.dates || []).map((date, index) => {
                                    let status = (state.allocations[_search.value] || []).filter(item => isSameDay(item.start, date))
                                    let selected = status.some(item => (_search.dates || []).includes(item.allocationId))//  (_search.dates || []).some(x => status.includes(x))

                                    return (
                                      <td key={`${date.toISOString()}-${_search.value}`} className={selected ? 'selected-td' : ''}>
                                        {status.map((item, _index) => {
                                          return (
                                            <div key={`${format(item.start, 'yyyy-MM-ddHH:mm:ss')}-${_index}`}>
                                              <SelectableComponent
                                                selected={selected}
                                                selectableKey={item.allocationId}
                                              >
                                                <p className={`${item.class || ''} tablestatus my-2 w-100`}>
                                                  <span> {item.display || ''}</span>
                                                  {item.start && (item.display != 'Not Available' || item.type == 'LEAVE') && <span className="ms-auto">{format(item.start, 'HH:mm aa')} to {format(item.end, 'HH:mm aa')}</span>}
                                                </p>
                                              </SelectableComponent>
                                            </div>
                                          );
                                        })}
                                      </td>
                                    )
                                  })}
                                </tr>
                              </table>
                            </SelectableGroup>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );

}



const mapStateToProps = (state) => ({
  user: state.user.userData,
  allowedModules: state.project.allowedModules,
  globalSetting: state.globalSetting,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: bindActionCreators(getUserList, dispatch),
  getRolesList: bindActionCreators(getRolesList, dispatch),
  getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
  getResourceLeaves: bindActionCreators(getUserLeaves, dispatch),
  getGlobalSettingReducer: bindActionCreators(getGlobalSettingReducer, dispatch),
  // allocateSchedule: bindActionCreators(allocateSchedule, dispatch),
  // allocateResource: bindActionCreators(allocateResource, dispatch),
  // deleteResource: bindActionCreators(deleteResource, dispatch),
  // editTimesheet: bindActionCreators(editTimesheet, dispatch),
  // applyLeave: bindActionCreators(applyLeave, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  // getProjectList: bindActionCreators(getProjectList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);