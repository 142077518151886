import React from "react";
import { connect } from "react-redux";
import "./../scss/project-management.scss";
import { bindActionCreators } from "redux";
import {
  receiveDEStock,
  getProject,
  getPOList,
  receiveReserveStock,
} from "./../../../actions/project-management.action";
import {
  updateItemStock,
  getCategories,
} from "./../../../actions/inventory.action";
import {
  getService,
  receiveDEStock as receiveServiceDEStock,
  getPOList as getServicePOlist,
  receiveReserveStock as receiveServiceReserveStock,
} from "./../../../actions/service.action";
import { getItems } from "./../../../actions/inventory.action";
import { warningSnackBar } from "./../../../actions/common.action";
import { PROJECT_MANAGEMENT } from "../../../constants/route-paths";
import _ from "lodash";
import idx from "idx";
import { withRouter } from "../../../util/with-router";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import '../../project-management/scss/task.scss';
import SelectInput from "src/components/inputs/select";
import CustomInput from "src/components/inputs/new-input";

class ReceiveStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      projectId: this.props.params.projectId,
      tasksList: [],
      reservedStock: false,
    };
  }

  componentDidMount() {
    let reservedStock = false;
    if (this.props.location.pathname.includes("reserved")) {
      reservedStock = true;
    }

    const { projectId } = this.props.params;
    this.setState({ projectId, reservedStock }, () => {
      if (projectId) {
        this.getStages();
      }

      this.getItemsList();
    });
  }

  getItemsList = () => {
    const { reservedStock } = this.state;
    if (reservedStock) {
      this.getPoList();
    } else {
      this.getItems();
    }
  };

  getPoList = async () => {
    const { projectId } = this.state;

    let poList;

    if (projectId) {
      poList = await this.props.getPOList({
        projectId,
        vendorType: "Supplier",
      });
    }
    const categoriesList = await this.props.getCategories();

    const categories = {};

    Array.isArray(categoriesList) &&
      categoriesList.forEach(({ id, name }) => {
        categories[id] = name;
      });

    const stockItems = [];
    Array.isArray(poList) &&
      poList.map(({ items }) => {
        Array.isArray(items) &&
          items.map((item) => {
            if (
              _.isObject(item.stock) &&
              parseFloat(item.stock.warehouse || 0) > 0
            ) {
              const itemIndex = stockItems.findIndex(
                (_item) => _item.id === item.id
              );
              if (itemIndex > -1) {
                stockItems[itemIndex].reservedStock =
                  parseFloat(stockItems[itemIndex].reservedStock || 0) +
                  parseFloat(item.stock.warehouse || 0);
              } else {
                item.category = categories[item.categoryId];
                item.reservedStock = parseFloat(item.stock.warehouse || 0);
                stockItems.push(item);
              }
            }
          });
      });

    this.setState({ items: stockItems });
  };

  getStages = async () => {
    const { projectId } = this.state;

    const project = await this.props.getProject(projectId);
    const tasksList = [];

    const hasStages = idx(project, (_) => project.hasStages) || false;
    const projectName = idx(project, (_) => project.name) || "";
    const projectType = idx(project, (_) => project.projectType) || "";
    const stages = idx(project, (_) => project.stages) || [];
    Array.isArray(stages) &&
      stages.forEach(({ id, tasks, name }) => {
        hasStages &&
          tasksList.push({
            key: name,
            value: id,
            subHeader: true,
            type: "STAGE",
          });
        Array.isArray(tasks) &&
          tasks.forEach(({ id, name, subTasks }) => {
            tasksList.push({ key: name, value: id, type: "TASK" });
            /* Array.isArray(subTasks) && subTasks.forEach(({ id, name }) => {
          tasksList.push({ key: name, value: id, type: 'SUBTASK' })
        }) */
          });
      });
    this.setState({ tasksList, projectName, projectType });
  };

  getItems = async () => {
    const { reservedStock } = this.state;
    let itemsList = await this.props.getItems();
    const items = [];

    Array.isArray(itemsList) &&
      itemsList.map((item) => {
        let allowItem = false;
        if (reservedStock && parseFloat(item.reservedStock || 0) > 0) {
          allowItem = true;
        } else if (!reservedStock && parseFloat(item.deStock || 0) > 0) {
          allowItem = true;
        }

        if (allowItem)
          items.push({
            ...item,
            category: idx(item, (_) => _.category.name) || "",
          });
      });

    this.setState({ items });
  };

  itemChangeHandler = (e, index) => {
    const items = _.cloneDeep(this.state.items);
    const { reservedStock } = this.state;
    const item = items[index];
    const enteredValue = parseFloat(e.target.value || 0);

    if (enteredValue < 0) {
      return;
    }

    let availableStock;

    if (reservedStock) {
      availableStock = parseFloat(item.reservedStock || 0);
    } else {
      availableStock = parseFloat(item.deStock || 0);
    }

    if (enteredValue > availableStock) {
      items[index].error = "You can not enter more than available Stock";
    } else {
      items[index].error = false;
    }
    items[index].newStock = enteredValue;
    this.setState({
      items,
    });
  };

  viewProject = () => {
    const { projectId } = this.props.params;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  };

  submitForm = async () => {
    let {
      items,
      tasksList,
      selectedTask,
      projectId,
      reservedStock,
      projectName,
      serviceName,
    } = this.state;
    let isFormValid = true;

    items &&
      items.forEach((item) => {
        if (item.error) {
          isFormValid = false;
        }
      });

    if (!selectedTask) {
      this.props.warningSnackBar("Please Select Task");
      return;
    }

    if (isFormValid) {
      const data = {
        allocationLocation: "SITE",
        transactionType: "ALLOCATED",
      };

      selectedTask = tasksList.find((task) => task.value === selectedTask);
      if (!selectedTask) {
        this.props.warningSnackBar("Some Error Occured, Refresh and try again");
        return;
      }

      data.taskType = selectedTask.type;
      data.taskId = selectedTask.value;
      data.allocateToName = projectId ? projectName : serviceName;
      data.allocateToId = projectId;
      data.poId = reservedStock ? "RESERVED STOCK" : "INTERNAL Stock";
      data.receivedFrom = "WAREHOUSE";

      const projectType = projectId ? "PROJECT" : "SERVICE";
      const receivedFrom = reservedStock ? "RESERVED_STOCK" : "INTERNAL_STOCK";

      const selectedItems = [];
      Array.isArray(items) &&
        items.forEach((item) => {
          if (item.newStock) {
            delete item.deStock;
            item.cost =
              parseFloat(item.recommendedCost) > 0
                ? parseFloat(item.recommendedCost)
                : parseFloat(item.cost);
            item.newStock = -Math.abs(parseFloat(item.newStock) || 0);
            item.taskType = selectedTask.type;
            item.taskId = selectedTask.value;
            item.projectType = projectType;
            item.receivedFromStock = receivedFrom;
            selectedItems.push(item);
          }
        });

      if (selectedItems.length === 0) {
        this.props.warningSnackBar("Please Enter Stock in atleast one item");
        return;
      }

      data.items = selectedItems;
      if (reservedStock) {
        // data.reservedStock = true
        // data.reduceStock = true

        if (projectId) {
          data.projectId = projectId;
          await this.props.receiveReserveStock(data);
          this.getItemsList();
        }
      } else if (projectId) {
        await this.props.receiveDEStock(data);
        this.getItemsList();
      } else {
        this.props.warningSnackBar("Some Error Occured, Refresh and try again");
        return;
      }
    }
  };

  ViewProjectList = () => {
    const { projectType } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      tasksList,
      selectedTask,
      items,
      reservedStock,
      projectName,
    } = this.state;
    const writePermission = this.props.userAccessControl === 2 ? true : false;

    return (
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">
              {reservedStock ? "Receive Reserved Stock" : "Receive Internal Stock"}
            </h2>
            <div className="col-12 breadcrumb-block p-0">
              <Breadcrumb>
                <Breadcrumb.Item onClick={this.ViewProjectList}>PROJECTS</Breadcrumb.Item>
                <Breadcrumb.Item onClick={this.ViewProject}>{projectName}</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {reservedStock ? "Receive Reserved Stock" : "Receive Internal Stock"}
                </Breadcrumb.Item>
              </Breadcrumb>

            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 ms-auto">
            <SelectInput
              items={tasksList}
              label={"Select Task"}
              value={selectedTask}
              onChange={(e) =>
                this.setState({ selectedTask: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="material-list-block mt-3">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch">
                  <thead>
                    <tr>
                      <th className="w-auto">Item</th>
                      <th className="w-auto">Catalog</th>
                      <th>Manufacturer</th>
                      <th>Part No</th>
                      <th>Unit</th>
                      <th>Unit Cost</th>
                      <th>{reservedStock ? 'Available Reserved Stock' : 'Available Internal Stock'}</th>
                      <th>Enter Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.item}</td>
                          <td>{item.category}</td>
                          <td>{item.manufacturer}</td>
                          <td>{item.partNo}</td>
                          <td>{item.unit}</td>
                          <td>${item.recommendedCost || item.cost}</td>
                          <td>
                            {reservedStock ? parseFloat(item.reservedStock || 0) : parseFloat(item.deStock || 0)}
                          </td>
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              type="number"
                              label="Stock"
                              value={item.newStock}
                              onChange={(e) => this.itemChangeHandler(e, index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

            </div>


          </div>
          <div className="col-12 d-flex mt-5">
            {writePermission && (
              <Button
                type="submit"
                className="ms-auto me-3 secondarybtn"
                onClick={this.submitForm}
              >
                SAVE
              </Button>
            )}
            <Button
              type="submit"
              className="secondarybtn cancelbtn me-auto"
              onClick={this.viewProject}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getProject: bindActionCreators(getProject, dispatch),
  getItems: bindActionCreators(getItems, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  receiveDEStock: bindActionCreators(receiveDEStock, dispatch),
  receiveServiceDEStock: bindActionCreators(receiveServiceDEStock, dispatch),
  getService: bindActionCreators(getService, dispatch),
  updateItemStock: bindActionCreators(updateItemStock, dispatch),
  getServicePOlist: bindActionCreators(getServicePOlist, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  getPOList: bindActionCreators(getPOList, dispatch),
  receiveServiceReserveStock: bindActionCreators(
    receiveServiceReserveStock,
    dispatch
  ),
  receiveReserveStock: bindActionCreators(receiveReserveStock, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceiveStock)
);
