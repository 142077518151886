import Modal from 'react-bootstrap/Modal';
import DashboardTable from "../table/dashboard-table"

export const DashboardTableModal = ({ show = false, handleClose, title = '', columns = [], data = [] }) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg" className="modal-dashboard">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="material-list-block">
                    <DashboardTable
                        columns={columns}
                        data={data}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}