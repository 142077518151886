import React from "react";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './date-picker.scss'

export default function TimePickerInput({ maxDate, disabled, label, placeholder, required, validation = [], value, onChange, error, touched, ...props }) {
    let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

    return (
        <div className="form-group">
            <div className="input-block datepicker-block">
                <span className="input-icon"><AccessTimeIcon /></span>
                <label className="input-label">
                    <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                        id={props.name}
                        margin='dense'
                        inputVariant='outlined'
                        value={moment(value)}
                        onChange={(value) => onChange(moment(value).toISOString())}
                        disabled={disabled}
                        {...props}
                    />
                </LocalizationProvider>
                <br />
                {touched && error && <span style={{ color: 'red' }}>{error}</span>}
            </div>
        </div>
    );
}
