export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getDraggedRow = (list, index) => {
  const result = Array.from(list);
  if (result.length <= index) {
    return null;
  }
  return index >= 0 ? result[index] : null;
}

export const handleDragEnd = (result, stages) => {
  const tasks = Reorder(
    stages[parseInt(result.type, 10)].tasks,
    result.source.index,
    result.destination.index
  );

  let destinationIndex = result.destination.index;
  let task = getDraggedRow(tasks, destinationIndex);
  let beforeDraggedTask = getDraggedRow(tasks, destinationIndex - 1);
  let afterDraggedTask = getDraggedRow(tasks, destinationIndex + 1);
  let newOrderingId = null;
  if (destinationIndex === 0) {
    newOrderingId = parseFloat(afterDraggedTask.ordering_id) - 1;
  } else if (destinationIndex === tasks.length - 1) {
    newOrderingId = parseFloat(beforeDraggedTask.ordering_id) + 1;
  } else {
    newOrderingId = (parseFloat(beforeDraggedTask.ordering_id) + parseFloat(afterDraggedTask.ordering_id)) / 2;
  }
  return { newOrderingId: newOrderingId, taskId: task.id };
}