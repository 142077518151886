import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  getAsset,
  assetMaintenance,
} from "../../actions/asset-management.action";
import Modal from "../../components/modal/modal";
import "./scss/asset-management.scss";
import { withRouter } from "../../util/with-router";
import { ASSET_MANAGEMENT } from "../../constants/route-paths";
import TextAreaInput from "src/components/inputs/textarea-input";

const assetCategory = {
  VEHICLE: "Vehicle",
  PLANT_AND_EQUIPMENT: "Plant & Equipment",
};

class AssetMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        reason: {
          name: "reason",
          label: "Reason for Maintenance",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        description: {
          name: "description",
          label: "Description",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
      },
      assetId: "",
      assetData: {},
    };
  }

  async componentDidMount() {
    if (this.props.params.assetId) {
      const assetId = this.props.params.assetId;

      const assetData = await this.props.getAsset(assetId);

      this.setState({ assetData, assetId });
    }
  }

  inputChangeHandler = async (e, fieldName = "") => {
    let fields = this.state.fields,
      typeName = "";
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    fields[name].value = value;
    fields[name].touched = true;

    this.setState({ fields, ...(typeName && { typeName: typeName }) });
  };

  handleValidation = ({ name, value, required }) => {
    let error = false;
    let valid = true;

    if (required && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }

    return { error, valid };
  };

  submitForm = async () => {
    const { fields, assetId } = this.state;
    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = {
        reason: fields.reason.value,
        description: fields.description.value,
        assetId: assetId,
      };
      await this.props.assetMaintenance(data);
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history(`/${ASSET_MANAGEMENT}`);
  };

  render() {
    const { fields, assetData } = this.state;

    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        onClick={this.submitForm}
        modalHeading={"Asset Maintenance"}
        modalSaveTxt={"Save"}
        modalCloseTxt="Cancel"
        showActionBtn={true}
        fullWidth={true}
        maxWidth={"md"}
        cxlbtnClass="cancelBtn"
        savebtnClass="createBtn"
        primary
      >
        {this.props.writePermission && (
          <div className="purchase-order-block">
            <div className="row">

              <div className="col-lg-4 mt-3">
                <div className="view-block">
                  <label>Asset category</label>
                  <p>{assetCategory[assetData.category]}</p>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="view-block">
                  <label>Asset Name</label>
                  <p>{assetData.name}</p>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="view-block">
                  <label>Asset No</label>
                  <p>{assetData.assetNo}</p>
                </div>
              </div>

              <div className="col-6 mt-3">
                <div className="input-block ">
                  <label className="input-label">{fields.reason.label}</label>
                  <TextAreaInput
                    {...fields.reason}
                    onChange={(e) => this.inputChangeHandler(e, fields.reason.name)}
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <div className="input-block ">
                  <label className="input-label">{fields.description.label}</label>
                  <TextAreaInput
                    {...fields.description}
                    onChange={(e) => this.inputChangeHandler(e, fields.description.name)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAsset: bindActionCreators(getAsset, dispatch),
  assetMaintenance: bindActionCreators(assetMaintenance, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetMaintenance)
);
