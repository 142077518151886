import {
  getProjectList as getProjectListAPI,
  addProject as addProjectAPI,
  deleteProject as deleteProjectAPI,
  getProject as getProjectAPI,
  addStage as addStageAPI,
  addTask as addTaskAPI,
  getAllTasks as getAllTasksAPI,
  addTaskWithoutStage as addTaskWithoutStageAPI,
  getStages as getStagesAPI,
  getStage as getStageAPI,
  updateStage as updateStageAPI,
  getTask as getTaskAPI,
  updateTask as updateTaskAPI,
  updateTaskOrderingId as updateTaskOrderingIdAPI,
  addSubTask as addSubTaskAPI,
  updateSubTask as updateSubTaskAPI,
  editProject as editProjectAPI,
  saveRFQ as saveRFQAPI,
  deleteStage as deleteStageAPI,
  deleteTask as deleteTaskAPI,
  deleteSubTask as deleteSubTaskAPI,
  getrfqList as getrfqListAPI,
  editRFQ as editRFQAPI,
  createQuotation as createQuotationAPI,
  getQuotationList as getQuotationListAPI,
  savePurchaseOrder as savePurchaseOrderAPI,
  editPurchaseOrder as editPurchaseOrderAPI,
  deletePurchaseOrder as deletePurchaseOrderAPI,
  getPurchaseOrder as getPurchaseOrderAPI,
  uploadFileRfq as uploadFileRfqAPI,
  getRfq as getRfqAPI,
  updateOption as updateOptionAPI,
  getOption as getOptionAPI,
  addOption as addOptionAPI,
  getOptions as getOptionsAPI,
  deleteOption as deleteOptionAPI,
  convertTask as convertTaskAPI,
  addResource as addResourceAPI,
  getTimesheet as getTimesheetAPI,
  updateResource as updateResourceAPI,
  getPOList as getPOListAPI,
  updatePOStock as updatePOStockAPI,
  fetchTemplates as fetchTemplatesAPI,
  saveContentFromTemplate,
  saveTemplate as saveTemplateAPI,
  getSubTask as getSubTaskAPI,
  getUserAllocations as getUserAllocationsAPI,
  updateTimesheetAttendance as updateTimesheetAttendanceAPI,
  updateTimesheetHour as updateTimesheetHourAPI,
  addSubContractorDate as addSubContractorDateAPI,
  getUserAllocationReport as getUserAllocationReportAPI,
  updateProposalOptions as updateProposalOptionsAPI,
  addProgressClaim as addProgressClaimAPI,
  getProgressClaimList as getProgressClaimListAPI,
  getvendorsPOList as getvendorsPOListAPI,
  addProgressClaimRetention as addProgressClaimRetentionAPI,
  setVendorPayment as setVendorPaymentAPI,
  getvendorsPOInvoiceCount as getvendorsPOInvoiceCountAPI,
  getDePOList as getDePOListAPI,
  getPoCount as getPoCountAPI,
  getWorkOrderCount as getWorkOrderCountServiceAPI,
  getPoVendors as getPoVendorsAPI,
  getPOInvoices as getPOInvoicesAPI,
  getPOInvoice as getPOInvoiceAPI,
  duplicateProjectTask as duplicateProjectTaskAPI,
  deleteTemplate as deleteTemplateAPI,
  getProjectBudget as getProjectBudgetAPI,
  duplicateProject as duplicateProjectAPI,
  receiveDEStock as receiveDEStockAPI,
  receiveReserveStock as receiveReserveStockAPI,
  editQuotation as editQuotationAPI,
  downloadProjectQuote as downloadProjectQuoteAPI,
  downloadProjectPO as downloadProjectPOAPI,
  downloadProjectRFQ as downloadProjectRFQAPI,
  downloadProjectVariation as downloadProjectVariationAPI,
  editProgressClaim as editProgressClaimAPI,
  getProgressClaimById as getProgressClaimByIdAPI,
  editProgressClaimRetention as editProgressClaimRetentionAPI,
  getAllProgressClaimList as getAllProgressClaimListAPI
} from '../api/project-management.api'

import {
  SAVE_PO_DATA,
  RESET_PO_DATA,
  SAVE_PROJECT_TYPE,
  REMOVE_PROJECT_TYPE
} from '../constants/action.constant'

export const savePODataRed = data => ({ type: SAVE_PO_DATA, data })

export const resetPODataRed = () => ({ type: RESET_PO_DATA })

export const saveProjectTypeRed = data => ({ type: SAVE_PROJECT_TYPE, data })

export const removeProjectTypeRed = () => ({ type: REMOVE_PROJECT_TYPE })

export const getProjectList = (rootProjectId, type, customerId, projectType, projectName, managerId) => {
  return (dispatch, getState) => {
    const allowedModules = getState().project.allowedModules || [];
    return getProjectListAPI(rootProjectId, type, customerId, projectType, projectName, managerId, allowedModules)
  }
}

export const fetchTemplates = () => {
  return () => {
    return fetchTemplatesAPI()
  }
}

export const getProject = (id) => {
  return (dispatch, getState) => {
    return getProjectAPI(id)
  }
}

export const editProject = (data, id) => {
  return (dispatch, getState) => {
    return editProjectAPI(data, id)
  }
}




export const updateProposalOptions = (data) => {
  return (dispatch, getState) => {
    return updateProposalOptionsAPI(data)
  }
}

export const addProject = (data) => {
  return (dispatch, getState) => {
    return addProjectAPI(data)
  }
}

export const deleteProject = (id, type) => {
  return (dispatch, getState) => {
    return deleteProjectAPI(id, type)
  }
}

export const addStage = (data, productId, hideMessage) => {
  return (dispatch, getState) => {
    return addStageAPI(data, productId, !hideMessage && 'Stage Created Successfully')
  }
}

export const addTask = (data, stageId) => {
  return (dispatch, getState) => {
    return addTaskAPI(data, stageId)
  }
}

export const getTask = (data, taskId) => {
  return (dispatch, getState) => {
    return getTaskAPI(data, taskId)
  }
}
export const addTaskWithoutStage = (data, projectId) => {
  return (dispatch, getState) => {
    return addTaskWithoutStageAPI(data, projectId)
  }
}

export const addOption = (data, taskType) => {
  return (dispatch, getState) => {
    return addOptionAPI(data, taskType)
  }
}

export const getOption = (optionId) => {
  return (dispatch, getState) => {
    return getOptionAPI(optionId)
  }
}

export const updateOption = (data, optionId, taskType) => {
  return (dispatch, getState) => {
    return updateOptionAPI(data, optionId, taskType)
  }
}

export const getStages = (projectId) => {
  return (dispatch, getState) => {
    return getStagesAPI(projectId)
  }
}

export const getOptions = (projectId) => {
  return (dispatch, getState) => {
    return getOptionsAPI(projectId)
  }
}

export const getStage = (stageId) => {
  return (dispatch, getState) => {
    return getStageAPI(stageId)
  }
}

export const updateStage = (data, stageId) => {
  return (dispatch, getState) => {
    return updateStageAPI(data, stageId)
  }
}

export const getAllTasks = (projectId) => {
  return (dispatch, getState) => {
    return getAllTasksAPI(projectId)
  }
}

export const updateTask = (data, taskId) => {
  return (dispatch, getState) => {
    return updateTaskAPI(data, taskId)
  }
}

export const updateTaskOrderingId = (data, taskId, destinationid) => {
  return (dispatch, getState) => {
    return updateTaskOrderingIdAPI(data, taskId, destinationid)
  }
}

export const addSubTask = (data, taskId) => {
  return (dispatch, getState) => {
    return addSubTaskAPI(data, taskId)
  }
}

export const updateSubTask = (data, SubTaskId) => {
  return (dispatch, getState) => {
    return updateSubTaskAPI(data, SubTaskId)
  }
}

export const saveRFQ = (data, projectId, message) => {
  return (dispatch, getState) => {
    return saveRFQAPI(data, projectId, message)
  }
}

export const deleteStage = (stageId) => {
  return (dispatch, getState) => {
    return deleteStageAPI(stageId)
  }
}

export const deleteTask = (taskId, isOption) => {
  return (dispatch, getState) => {
    return deleteTaskAPI(taskId, isOption)
  }
}

export const deleteOption = (optionId) => {
  return (dispatch, getState) => {
    return deleteOptionAPI(optionId)
  }
}
export const convertTask = (data) => {
  return (dispatch, getState) => {
    return convertTaskAPI(data)
  }
}

export const deleteSubTask = (subTaskId, isOption) => {
  return (dispatch, getState) => {
    return deleteSubTaskAPI(subTaskId, isOption)
  }
}

export const getrfqList = (projectId) => {
  return (dispatch, getState) => {
    return getrfqListAPI(projectId)
  }
}

export const editRFQ = (data, id, message) => {
  return (dispatch, getState) => {
    return editRFQAPI(data, id, message)
  }
}

export const createQuotation = (data, id) => {
  return (dispatch, getState) => {
    return createQuotationAPI(data, id)
  }
}

export const getQuotationList = (query) => {
  return (dispatch, getState) => {
    return getQuotationListAPI(query)
  }
}

export const savePurchaseOrder = (poItems, rfqId, message) => {
  return (dispatch, getState) => {
    return savePurchaseOrderAPI(poItems, rfqId, message)
  }
}

export const editPurchaseOrder = (poItems, poId, message) => {
  return (dispatch, getState) => {
    return editPurchaseOrderAPI(poItems, poId, message)
  }
}

export const deletePurchaseOrder = (poId, message) => {
  return (dispatch, getState) => {
    return deletePurchaseOrderAPI(poId, message)
  }
}

export const getPurchaseOrder = (query) => {
  return (dispatch, getState) => {
    return getPurchaseOrderAPI(query)
  }
}

export const uploadFileRfq = (formData, id, message) => {
  return (dispatch, getState) => {
    return uploadFileRfqAPI(formData, id, message)
  }
}

export const getRfq = (rfqId) => {
  return (dispatch, getState) => {
    return getRfqAPI(rfqId)
  }
}

export const addResource = (data) => {
  return (dispatch, getState) => {
    return addResourceAPI(data)
  }
}

export const getTimesheet = (id, allocationId) => {
  return (dispatch, getState) => {
    return getTimesheetAPI(id, allocationId)
  }
}

export const updateResource = (id, data) => {
  return (dispatch, getState) => {
    return updateResourceAPI(id, data)
  }
}

export const getPOList = ({ vendorId = '', projectId = '', vendorType = '', isDePo = null, itemId = null }) => {
  return (dispatch, getState) => {
    const allowedModules = getState().project.allowedModules || [];
    return getPOListAPI({ vendorId, projectId, vendorType, isDePo, itemId, allowedModules })
  }
}

export const updatePOStock = (data, id) => {
  return (dispatch, getState) => {
    return updatePOStockAPI(data, id)
  }
}

export const addContentTemplate = (data) => {
  return (dispatch) => {
    return saveContentFromTemplate(data)
  }
}

export const saveTemplate = (data) => {
  return (dispatch) => {
    return saveTemplateAPI(data)
  }
}

export const getSubTask = (subTaskId) => {
  return (dispatch) => {
    return getSubTaskAPI(subTaskId)
  }
}

export const getUserAllocations = (userIds, projectId) => {
  return (dispatch, getState) => {
    return getUserAllocationsAPI(userIds, projectId)
  }
}

export const updateTimesheetAttendance = (allocationId, data, absentId) => {
  return (dispatch, getState) => {
    return updateTimesheetAttendanceAPI(allocationId, data, absentId)
  }
}

export const updateTimesheetHour = (allocationId, data) => {
  return (dispatch, getState) => {
    return updateTimesheetHourAPI(allocationId, data)
  }
}

export const addSubContractorDate = (allocationId, data) => {
  return (dispatch, getState) => {
    return addSubContractorDateAPI(allocationId, data)
  }
}

export const getUserAllocationReport = (userIds) => {
  return (dispatch, getState) => {
    return getUserAllocationReportAPI(userIds)
  }
}

export const addProgressClaim = (data) => {
  return (dispatch, getState) => {
    return addProgressClaimAPI(data)
  }
}

export const editProgressClaim = (data, id) => {
  return (dispatch, getState) => {
    return editProgressClaimAPI(data, id)
  }
}

export const getProgressClaimList = (projectId) => {
  return (dispatch, getState) => {
    return getProgressClaimListAPI(projectId)
  }
}

export const getvendorsPOList = (params) => {
  return (dispatch, getState) => {
    const allowedModules = getState().project.allowedModules || [];
    return getvendorsPOListAPI({ ...params, allowedModules })
  }
}

export const addProgressClaimRetention = (data) => {
  return (dispatch, getState) => {
    return addProgressClaimRetentionAPI(data)
  }
}
export const setVendorPayment = (data, poNumber) => {
  return (dispatch, getState) => {
    return setVendorPaymentAPI(data, poNumber)
  }
}
// total vendor payment invoices for a project
export const getvendorsPOInvoiceCount = (projectId) => {
  return (dispatch, getState) => {
    return getvendorsPOInvoiceCountAPI(projectId)
  }
}

export const getDePOList = (params) => {
  return (dispatch, getState) => {
    return getDePOListAPI(params)
  }
}

export const getPoCount = (projectId) => {
  return (dispatch, getState) => {
    return getPoCountAPI(projectId)
  }
}
export const getWorkOrderCount = (projectId) => {
  return (dispatch, getState) => {
    return getWorkOrderCountServiceAPI(projectId)
  }
}
export const getPoVendors = (projectId, type) => {
  return (dispatch, getState) => {
    return getPoVendorsAPI(projectId, type)
  }
}

export const getPOInvoices = (poId) => {
  return (dispatch, getState) => {
    return getPOInvoicesAPI(poId)
  }
}

export const getPOInvoice = (poId) => {
  return (dispatch, getState) => {
    return getPOInvoiceAPI(poId)
  }
}

export const duplicateProjectTask = (taskType, stageId, taskId, subTaskId) => {
  return (dispatch, getState) => {
    return duplicateProjectTaskAPI(taskType, stageId, taskId, subTaskId)
  }
}

export const deleteTemplate = (templateId) => {
  return (dispatch, getState) => {
    return deleteTemplateAPI(templateId)
  }
}

export const getProjectBudget = (projectId) => {
  return (dispatch, getState) => {
    return getProjectBudgetAPI(projectId)
  }
}

export const savePOData = (data) => {
  return (dispatch, getState) => {
    dispatch(savePODataRed(data))
  }
}

export const resetPOData = () => {
  return (dispatch, getState) => {
    dispatch(resetPODataRed())
  }
}

export const duplicateProject = (data) => {
  return (dispatch, getState) => {
    return duplicateProjectAPI(data)
  }
}

export const receiveDEStock = (data) => {
  return (dispatch, getState) => {
    return receiveDEStockAPI(data)
  }
}

export const receiveReserveStock = (data) => {
  return (dispatch, getState) => {
    return receiveReserveStockAPI(data)
  }
}

export const editQuotation = (data, id) => {
  return (dispatch, getState) => {
    return editQuotationAPI(data, id)
  }
}

export const downloadProjectQuote = (id, format, tempId) => {
  return (dispatch, getState) => {
    return downloadProjectQuoteAPI(id, format, tempId)
  }
}

export const downloadProjectPO = (id, format, tempId, template) => {
  return (dispatch, getState) => {
    return downloadProjectPOAPI(id, format, tempId, template)
  }
}

export const downloadProjectRFQ = (id, format, tempId, vendorId) => {
  return (dispatch, getState) => {
    return downloadProjectRFQAPI(id, format, vendorId, tempId)
  }
}

export const downloadProjectVariation = (id, format, tempId) => {
  return (dispatch, getState) => {
    return downloadProjectVariationAPI(id, format, tempId)
  }
}

export const saveProjectType = (data) => {
  return (dispatch, getState) => {
    return dispatch(saveProjectTypeRed(data))
  }
}

export const removeProjectType = () => {
  return (dispatch, getState) => {
    dispatch(removeProjectTypeRed())
  }
}

export const getProgressClaimById = (query) => {
  return (dispatch, getState) => {
    return getProgressClaimByIdAPI(query)
  }
}

export const editProgressClaimRetention = (data, id) => {
  return (dispatch, getState) => {
    return editProgressClaimRetentionAPI(data, id)
  }
}

export const getAllProgressClaimList = (projectId) => {
  return (dispatch, getState) => {
    return getAllProgressClaimListAPI(projectId)
  }
}
