import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { getProjectList, addResource } from './../../actions/project-management.action'
import Modal from '../../components/modal/modal'
import Input from '../../components/inputs/input-simple'
import Grid from '@material-ui/core/Grid'
import './scss/user-management.scss'
import { getActiveProjects } from '../project-management/utils/getProjectStatus'

class AllocateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                projectId: {
                    name: 'projectId',
                    label: 'Project',
                    type: 'select',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    items: [],
                    required: true,
                },
                fromDate: {
                    name: 'fromDate',
                    label: 'From',
                    type: 'datepicker',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    clearable: true,
                    format: 'EEEE dd/MM/yyyy',
                    required: true,
                },
                toDate: {
                    name: 'toDate',
                    label: 'To',
                    type: 'datepicker',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    clearable: true,
                    format: 'EEEE dd/MM/yyyy',
                    minDate: '',
                    minDateMessage: 'Due Date should be after Start Date',
                    required: true,
                },
            },
            userData: {},
        }
    }

    async componentDidMount() {
        const { fields } = this.state
        const items = await this.getProjectList()

        this.setState({ fields: { ...fields, projectId: { ...fields.projectId, items } } })
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.userData, this.props.userData)) {
            this.setState({ userData: this.props.userData })
        }
    }

    getProjectList = async () => {
        const projects = await this.props.getProjectList('', 'PROJECT')
        const items = projects.filter(project => getActiveProjects(project.status)).map(project => ({
            key: project.name,
            value: project.id
        }))
        return items
    }

    inputChangeHandler = (e, fieldName = '') => {
        let fields = this.state.fields
        var name
        var value
        if (fieldName === 'fromDate' || fieldName === 'toDate') {
            name = fieldName
            value = e
            if (fieldName === 'fromDate') {
                fields['toDate'].minDate = value || ''
            }
        } else if (_.isObject(e)) {
            name = e.target.name
            value = e.target.value
        }
        fields[name].value = value
        fields[name].touched = true

        this.setState({ fields })
    }

    handleValidation = ({ name, value, required }) => {
        let error = false
        let valid = true

        if (required && !value) {
            return { error: 'This Field is Required', valid: false }
        }

        return { error, valid }
    }

    submitForm = async () => {
        const { fields, userData } = this.state
        let isFormValid = true
        for (let key in fields) {
            const { error, valid } = this.handleValidation(fields[key], fields[key].value, fields[key].required)
            fields[key].touched = true
            fields[key].valid = valid
            fields[key].error = error
            isFormValid = isFormValid && valid
        }

        if (!isFormValid) {
            this.setState({ fields })
        } else {
            const data = {
                projectId: fields.projectId.value,
                resourceType: 'DE_RESOURCES',
                user: userData.id,
                name: userData.name,
                fromDate: fields.fromDate.value || '',
                toDate: fields.toDate.value || '',
            }

            await this.props.addResource(data)
            this.handleClose()
            this.props.getAllocations()
        }
    }

    handleClose = () => {
        let { fields } = this.state

        for (let key in fields) {
            fields[key].touched = false
            fields[key].valid = true
            fields[key].error = false
            fields[key].value = ''
        }

        this.setState({ fields }, () => this.props.handleClose())
    }

    render() {
        const { open } = this.props
        const { fields } = this.state

        let formElements = Object.keys(fields).map(key => {
            return (
                <Grid container>
                    <Grid xs={3} className='label' item>
                        {fields[key].label}
                    </Grid>
                    <Grid xs={9} item>
                        <div className="addNewRoleCol">
                            <Input
                                {...fields[key]}
                                grid={8}
                                onChange={(e) => this.inputChangeHandler(e, fields[key].name)}
                            />
                        </div>
                    </Grid>
                </Grid>
            )
        })

        return (
            <Modal
                open={open}
                onClose={this.handleClose}
                onClick={this.submitForm}
                modalHeading={'Allocate User To Project'}
                modalSaveTxt={'Allocate'}
                modalCloseTxt='Cancel'
                showActionBtn={true}
                fullWidth={true}
                maxWidth={'sm'}
                cxlbtnClass='cancelBtn'
                savebtnClass='createBtn'
                primary
            >
                <Grid container xs={12} className="allocateUserForm">
                    <Grid item xs={12}>
                        {formElements}
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    addResource: bindActionCreators(addResource, dispatch),
    getProjectList: bindActionCreators(getProjectList, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllocateUser);
