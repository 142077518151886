import { toPercent, toUSD } from "src/util/currency-formatter"
import { history } from "src/util/history";
import Cookies from 'js-cookie'
import { setCookie } from "../../../util/auth";
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const navigateToProject = (data) => {
    setCookie({ name: 'projectType', value: data.project_type })
    if (data.is_quote) {
        history.navigate(`/projects/edit/${data.id}?project-step=TENDERVIEW`);
    } else {
        history.navigate(`/projects/edit/${data.id}?project-step=VIEW`);
    }
}

const redirectTo = (path) => {
    history.navigate(path)
}

const navigateToItem = (data) => {
    if (!data.id) return;
    switch (data.type) {
        case 'Material':
            history.navigate(`/catalogs/material/edit/${data.id}`); break;
        case 'Resource':
            history.navigate(`/catalogs/resources/edit/${data.id}`); break;
        case 'Contractor':
            history.navigate(`/contractors/edit/${data.id}`); break;
        default: break;
    }
}

export const invoicesList = [
    {
        class: 'project-name-width project-name-width-ch', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Invoice No', field: 'no' },
    { class: 'text-right', title: 'Total Invoice', render: (data) => toUSD(data.total) },
    // {
    //     class: 'text-center', title: 'Action',
    //     render: (data) => (
    //         <div onClick={() => redirectTo(`/${paths.TIMESHEET}/${paths.SCHEDULE}?project_id=${data.id}`)} class="action-edit text-center">
    //             <DescriptionOutlinedIcon />
    //         </div>
    //     )
    // },
];

export const puprchaseOrderList = [
    {
        class: 'project-name-width project-name-width-ch', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Order No', field: 'po_no' },
    { class: 'text-right', title: 'Total', render: (data) => toUSD(data.total) },
    // {
    //     class: 'text-right', title: 'Status', render: (data) => (
    //         <span className="tbl-success-badge tbl-danger-badge">{item.status}</span>
    //     )
    // },
];

export const workOrderList = [
    {
        class: 'project-name-width project-name-width-ch', title: 'Project Name',
        render: (data) => (
            <a onClick={() => navigateToProject(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Order No', field: 'wo_no' },
    { class: 'text-right', title: 'Total', render: (data) => toUSD(data.total) },
    // {
    //     class: 'text-right', title: 'Status', render: (data) => (
    //         <span className="tbl-success-badge tbl-danger-badge">{item.status}</span>
    //     )
    // },
];

export const topProfitItems = [
    {
        class: 'project-name-width project-name-width-ch', title: 'Item Name',
        render: (data) => (
            <a onClick={() => navigateToItem(data)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Item Type', field: 'type' },
    { class: 'text-right', title: 'Total Revenue', render: (data) => toUSD(data.revenue) },
    { class: 'text-right', title: 'Total Cost', render: (data) => toUSD(data.expense) },
    { class: 'text-right', title: 'Total Profit', render: (data) => toUSD(data.profit) },
];

export const topResourceUtilization = [
    {
        class: 'project-name-width', title: 'User Name',
        render: (data) => (
            <a onClick={() => redirectTo(`/user-management/edit/${data.id}`)} title={data.name} className="table-text-hidden">
                {data.name}
            </a>
        )
    },
    { class: 'text-right', title: 'Utilisation', render: (data) => toPercent(data.billed, data.available) },
];
