import React, { useState } from 'react'
import Modal from '../../../components/modal/modal'
import SelectInput from 'src/components/inputs/select'

export default function Filter(props) {
  const { projectsList, changeFilter, workType, dialogClose, open } = props
  const [projectId, setProjectId] = useState('all')

  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='md'
      modalHeading='Select Filter'
      modalSaveTxt='Apply Filter'
      modalCloseTxt='Cancel'
      onClick={() => changeFilter(projectId)}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <div className="purchase-order-block mb-3">
        {workType !== 'de_po' && (<div className="row">
          <div className="col-lg-6 col-md-6 mt-3">
            <SelectInput
              items={projectsList}
              value={projectId}
              label={'Project Filter'}
              onChange={(e) => setProjectId(e.target.value)}
            />
          </div>
        </div>)}
      </div>
    </Modal>
  )
}
