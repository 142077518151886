import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const SERVICE = '/service'
const TASK = '/task'
const DELETE_SERVICE = '/service/delete'
const SERVICE_TASK = '/service-task'
const SERVICE_OPTION = '/service-option'
const SUB_TASK = '/sub-task'
const SERVICE_SUB_TASK = '/service-sub-task'
const SERVICES_LIST = '/service/list'
const SAVE_TEMPLATE = '/service/template'
const SAVE_TEMPLATE_CONTENT = '/service/save/content'
const GET_TEMPLATES_LIST = '/service/templates'
const SERVICE_OPTION_UPDATE = '/service-option/update'
const SAVE_RFQ = '/service-rfq/service'
const RFQ = '/service-rfq'
const CREATE_QUOTATION = '/service-quotation/rfq'
const GET_QUOTATION_LIST = '/service-quotation/service/list'
const SAVE_PURCHASE_ORDER = '/service-purchase-order/create'
const EDIT_PURCHASE_ORDER = '/service-purchase-order/edit'
const GET_PURCHASE_ORDER = '/service-purchase-order'
const UPLOAD_RFQ_FILE = '/service-rfq/upload'
const GET_RFQ = '/service-rfq'
const GET_PURCHASE_ORDER_LIST = '/service-purchase-order/list'
const UPDATE_PURCHASE_ORDER_STOCK = '/service-purchase-order/stock'
const ADD_PROGRESS_CLAIM = '/service-progress-claim/'
const GET_PROGRESS_CLAIM = '/service-progress-claim/list'
const GET_VENDOR_PO_LIST = '/service-purchase-order/vendors'
const ADD_PROGRESS_CLAIM_RETENTION = '/service-progress-claim/retention'
const GET_PO_COUNT = '/service-purchase-order/count'
const GET_PO_VENDORS = '/service-purchase-order/distinct-vendors'
const SET_VENDOR_PAYMENT = '/service-purchase-order/vendor/payment'
const GET_VENDOR_PO_INVOICE_COUNT = '/service-purchase-order/vendor/payment-invoice-count'
const GET_PO_INVOICES = '/service-purchase-order/invoices'
const GET_PO_INVOICE = '/service-purchase-order/invoice'
const DUPLICATE_SERVICE_TASK = '/service/duplicate/task'
const DUPLICATE_SERVICE = '/service/duplicate-service'
const RECEIVE_DE_STOCK = '/service/receive-de-stock'
const RECEIVE_RESERVE_STOCK = '/service/receive-reserve-stock'
const EDIT_QUOTATION = '/service-quotation'
const DOWNLOAD_SERVICE_QUOTE = '/service/download'
const DOWNLOAD_SERVICE_PO = '/service-purchase-order/download'
const DOWNLOAD_SERVICE_RFQ = '/service-rfq/download'
const DOWNLOAD_SERVICE_VARIATION = '/service-option/download'

// // ************API call Functions starts here **************
export const addService = async (data) => {
  return await axios('post', SERVICE, data, { successMessage: 'Service Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addTask = async (data, serviceId) => {
  return await axios('post', `${SERVICE}/${serviceId}${TASK}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const deleteService = async (id,type) => {
  return await axios('delete', `${DELETE_SERVICE}/${id}/${type}`, {}, { successMessage: 'Service Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const addOption = async (data) => {
  return await axios('post', `${SERVICE_OPTION}`, data, { loader: loaders.OVERLAY, successMessage: 'Option Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTask = async (taskId) => {
  return await axios('get', `${SERVICE_TASK}/${taskId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateTask = async (data, taskId) => {
  return await axios('put', `${SERVICE_TASK}/${taskId}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addSubTask = async (data, taskId) => {
  return await axios('post', `${SERVICE_TASK}/${taskId}${SUB_TASK}`, data, {
    loader: loaders.OVERLAY,
    successMessage: 'Sub Task Created Successfully',
    server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateSubTask = async (data, subTaskId) => {
  return await axios('put', `${SERVICE_SUB_TASK}/${subTaskId}`, data, { loader: loaders.OVERLAY, successMessage: 'Sub Task Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTasks = async (serviceId) => {
  return await axios('get', `${SERVICE}/${serviceId}/tasks`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getServicesList = async (customerId, type, serviceDescription, managerId) => {
  return await axios('get', `${SERVICES_LIST}`, {}, {
    server: microServices.DE_CORE,
    params: {
      ...(customerId ? { customerId } : {}),
      ...(type ? { type } : {}),
      ...(serviceDescription ? { serviceDescription } : {}),
      ...(managerId ? { managerId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getService = async (id) => {
  return await axios('get', `${SERVICE}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: { id } }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editService = async (data, id) => {
  return await axios('put', `${SERVICE}/${id}`, data, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getOptions = async (serviceId) => {
  return await axios('get', `${SERVICE}/${serviceId}/options`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteOption = async (id) => {
  return await axios('delete', `${SERVICE_OPTION}/${id}`, {}, { successMessage: 'Option Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getOption = async (optionId) => {
  return await axios('get', `${SERVICE_OPTION}/${optionId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateOption = async (data, optionId) => {
  return await axios('put', `${SERVICE_OPTION}/${optionId}`, data, { loader: loaders.OVERLAY, successMessage: 'Option Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

// save template from service content
export const saveTemplate = async (data) => {
  return await axios('post', SAVE_TEMPLATE, data, { loader: loaders.OVERLAY, successMessage: null, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveContentFromTemplate = async (data) => {
  return await axios('post', SAVE_TEMPLATE_CONTENT, data, { loader: loaders.OVERLAY, successMessage: null, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const fetchTemplates = async () => {
  return await axios('get', `${GET_TEMPLATES_LIST}`,
    {},
    { server: microServices.DE_CORE }
  ).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateProposalOptions = async (data) => {
  return await axios('post', `${SERVICE_OPTION_UPDATE}`, data, { loader: loaders.OVERLAY, successMessage: 'Proposal Saved Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const saveRFQ = async (data, serviceId, message) => {
  return await axios('post', `${SAVE_RFQ}?serviceId=${serviceId}`, data, { loader: loaders.OVERLAY, successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getrfqList = async (id) => {
  return await axios('get', `${SAVE_RFQ}/${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editRFQ = async (data, id, message) => {
  return await axios('put', `${RFQ}/${id}`, data, { successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const createQuotation = async (data, id) => {
  return await axios('post', `${CREATE_QUOTATION}/${id}`, data, { successMessage: 'Quotation Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getQuotationList = async (query) => {
  return await axios('get', `${GET_QUOTATION_LIST}${query && query}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const savePurchaseOrder = async (poItems, rfqId, message) => {
  return await axios('post', `${SAVE_PURCHASE_ORDER}`, poItems, {
    successMessage: message,
    server: microServices.DE_CORE,
    params: { ...(rfqId ? { rfqId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editPurchaseOrder = async (poItems, poId, message) => {
  return await axios('post', `${EDIT_PURCHASE_ORDER}/${poId}`, poItems, {
    successMessage: message,
    server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deletePurchaseOrder = async (poId, message) => {
  return await axios('delete', `${GET_PURCHASE_ORDER}/${poId}`, {},
    {
      successMessage: message,
      server: microServices.DE_CORE
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const getPurchaseOrder = async (query) => {
  return await axios('get', `${GET_PURCHASE_ORDER}${query ? query : ''}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const uploadFileRfq = async (formData, id, message) => {
  return await axios('put', `${UPLOAD_RFQ_FILE}/${id}`, formData, { loader: loaders.OVERLAY, successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getRfq = async (id) => {
  return await axios('get', `${GET_RFQ}/${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteTask = async (id) => {
  return await axios('delete', `${SERVICE_TASK}/${id}`, {}, { successMessage: 'Task Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteSubTask = async (id) => {
  return await axios('delete', `${SERVICE_SUB_TASK}/${id}`, {}, { successMessage: 'SubTask Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

// Get subtask api
export const getSubTask = async (subTaskId) => {
  return await axios('get', `${SERVICE_SUB_TASK}/${subTaskId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOList = async ({ vendorId: vendor, serviceId, vendorType, itemId }) => {
  return await axios('get', `${GET_PURCHASE_ORDER_LIST}`, {}, {
    server: microServices.DE_CORE,
    params: {
      ...(vendor ? { vendor } : {}),
      ...(serviceId ? { serviceId } : {}),
      ...vendorType ? { vendorType } : {},
      ...itemId ? { itemId } : {}
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updatePOStock = async (data, id) => {
  return await axios('put', `${UPDATE_PURCHASE_ORDER_STOCK}`, data, { loader: loaders.OVERLAY, successMessage: 'PO Stock Updated Successfully', server: microServices.DE_CORE, params: { id } }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addProgressClaim = async (data) => {
  return await axios('post', ADD_PROGRESS_CLAIM, data, {
    loader: loaders.OVERLAY, successMessage: 'Progress Claim Generated Successfully', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProgressClaimList = async (serviceId) => {
  return await axios('get', GET_PROGRESS_CLAIM, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(serviceId ? { serviceId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getvendorsPOList = async (params) => {
  return await axios('get', GET_VENDOR_PO_LIST, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const addProgressClaimRetention = async (data) => {
  return await axios('post', ADD_PROGRESS_CLAIM_RETENTION, data, {
    loader: loaders.OVERLAY, successMessage: 'Progress Claim Retention Generated Successfully', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPoCount = async (serviceId) => {
  return await axios('get', `${GET_PO_COUNT}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(serviceId ? { serviceId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPoVendors = async (serviceId, type) => {
  return await axios('get', `${GET_PO_VENDORS}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(serviceId ? { serviceId } : {}), ...(type ? { type } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const setVendorPayment = async (data, poNumber) => {
  return await axios('post', `${SET_VENDOR_PAYMENT}/${poNumber}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Invoice details Updated', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getvendorsPOInvoiceCount = async (projectId) => {
  return await axios('get', `${GET_VENDOR_PO_INVOICE_COUNT}/${projectId}`, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOInvoices = async (poId) => {
  return await axios('get', `${GET_PO_INVOICES}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(poId ? { poId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOInvoice = async (invoiceId) => {
  return await axios('get', `${GET_PO_INVOICE}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(invoiceId ? { invoiceId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const duplicateServiceTask = async (taskType, taskId, subTaskId) => {
  return await axios('put', `${DUPLICATE_SERVICE_TASK}`, {}, {
    loader: loaders.OVERLAY, successMessage: 'Duplication Successfull!', server: microServices.DE_CORE,
    params: {
      ...(taskType ? { taskType } : {}),
      ...(taskId ? { taskId } : {}),
      ...(subTaskId ? { subTaskId } : {})
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const duplicateService = async (data) => {
  return await axios('post', `${DUPLICATE_SERVICE}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Project Duplication Successfull !', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const receiveDEStock = async (data) => {
  return await axios('post', `${RECEIVE_DE_STOCK}`, data, {
    loader: loaders.OVERLAY, successMessage: 'DE Stock Received Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const receiveReserveStock = async (data) => {
  return await axios('post', `${RECEIVE_RESERVE_STOCK}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Reserve Stock Received Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editQuotation = async (data, id) => {
  return await axios('put', `${EDIT_QUOTATION}/${id}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Quotation updated Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const downloadServiceQuote = async (id, format) => {
  return await axios('get', DOWNLOAD_SERVICE_QUOTE, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadServicePO = async (id, format, template) => {
  return await axios('get', DOWNLOAD_SERVICE_PO, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
      ...(template ? { template } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadServiceRFQ = async (id, format, vendorId) => {
  return await axios('get', DOWNLOAD_SERVICE_RFQ, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
      ...(vendorId ? { vendorId } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadServiceVariation = async (id, format) => {
  return await axios('get', DOWNLOAD_SERVICE_VARIATION, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
