import React, { useEffect, useState } from "react";
import "./scss/customer-management.scss";
import { CUSTOMER_MANAGEMENT } from "./../../constants/route-paths";
import {
  addCustomer,
  getCustomer,
  editCustomer,
  addContact,
  getCustomerList,
} from "./../../actions/customer-management.action";
// import { geocodeByPlaceID } from "mui-places-autocomplete";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { emailValidator } from "../../util/validators/email";
import { getLevels } from "./../../actions/inventory.action";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import Collapse from 'react-bootstrap/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectInput from "src/components/inputs/select";
import Button from 'react-bootstrap/Button';
import CustomInput from "src/components/inputs/new-input";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import idx from "idx";
import { phoneValidator } from "src/util/validators/phone";
import { postCodeValidator } from "src/util/validators/postcode";

export default function AddCustomer({ writePermission, ...props }) {

  const [fieldState, setFieldState] = useState({
    businessName: {
      name: "businessName",
      label: "Business Name",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
    type: {
      name: "type",
      label: "Type",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "Business", value: "Business" },
        { key: "Individual", value: "Individual" },
      ],
      validation: ["required"],
    },
    abn: {
      name: "abn",
      label: "Enter 11 digit ABN Number",
      textLabel: "ABN",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["optional"],
    },
    domain: {
      name: "domain",
      label: "Business Domain Name",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Phone Number",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      touched: false,
      error: false,
      valid: true,
      value: "",
    },
    paymentTerms: {
      name: "paymentTerms",
      label: "Payment Terms",
      type: "number",
      touched: false,
      error: false,
      valid: true,
      value: "",
    },
    level_id: {
      name: "level_id",
      label: "Select Cost/Sales Level",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
  })
  const [addressFieldState, setAddressFieldState] = useState({
    address: [],
    fields: {
      name: {
        name: "name",
        label: "Name",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [],
      },
      addressLine1: {
        name: "addressLine1",
        label: "Address Line 1",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      addressLine2: {
        name: "addressLine2",
        label: "Address Line 2",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
      },
      suburb: {
        name: "suburb",
        label: "Suburb",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      state: {
        name: "state",
        label: "State",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      postcode: {
        name: "postcode",
        label: "Postcode",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      country: {
        name: "country",
        label: "Country",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      contact_id: {
        name: "contact_id",
        label: "Contact",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        // items: [],
        validation: ["required"],
      },
      isPrimary: {
        name: "isPrimary",
        label: "Set as Primary",
        type: "checkbox",
        touched: false,
        error: false,
        valid: true,
        value: false,
      },
    },
  })
  const [contactFieldState, setContactFieldState] = useState({
    contacts: [],
    fields: {
      isPrimary: {
        name: "isPrimary",
        label: "Set as Primary",
        type: "checkbox",
        touched: false,
        error: false,
        valid: true,
        value: false,
      },
      firstName: {
        name: "firstName",
        label: "Given Name(s)",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      lastName: {
        name: "lastName",
        label: "Last Name",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
      },
      email: {
        name: "email",
        label: "Email",
        type: "email",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      phoneNumber: {
        name: "phoneNumber",
        label: "Phone Number",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
      },
      designation: {
        name: "designation",
        label: "Job Title",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
      },
    }
  })
  const [addState, setAddState] = useState({
    disable: false,
    edit: false,
    showSiteAddress: true,
    showContactAddress: true,
    isFormValid: false,
    contactsList: []
  })


  const { customerId } = useParams();
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomer(customerId)).then((customer) => {
        _editCustomer(customer);
      })
    } else {
      if (contactFieldState.contacts.length == 0) _addContact();
      if (addressFieldState.address.length == 0) {
        const { fields } = addressFieldState;
        let address = [];
        address.push(_.cloneDeep(fields));
        setAddressFieldState(prev => ({
          ...prev,
          address
        }));
      }
      _getLevels();
    }
  }, [])

  const _editCustomer = (customer) => {
    setFieldState(prev => ({
      ...prev,
      businessName: { ...prev.businessName, value: customer.businessName },
      abn: { ...prev.abn, value: customer.abn },
      domain: { ...prev.domain, value: customer.domain },
      level_id: { ...prev.level_id, value: customer.level_id },//, validation: ["required"] },
      phoneNumber: { ...prev.phoneNumber, value: customer.phoneNumber },
      email: { ...prev.email, value: customer.email || '' },
      paymentTerms: { ...prev.paymentTerms, value: customer.paymentTerms || '' },
      type: { ...prev.type, value: customer.type }
    }));

    const contacts = customer.contacts.map((contact) => {
      const _contact = _.cloneDeep(contactFieldState.fields);
      _contact.firstName.value = contact.firstName;
      _contact.lastName.value = contact.lastName;
      _contact.designation.value = contact.designation;
      _contact.email.value = contact.email;
      _contact.phoneNumber.value = contact.phoneNumber;
      _contact.isPrimary.value = contact.primary ? contact.primary : false;
      _contact.id = contact.id;
      return _contact;
    });

    setContactFieldState(prev => ({
      ...prev,
      contacts: contacts
    }));

    const contactsList = [];
    customer.contacts.forEach((contact) =>
      contactsList.push({
        key: (contact.firstName || '') + " " + (contact.lastName || ''),
        value: contact.id,
      })
    );

    let address = Array.isArray(customer.siteAddress) && customer.siteAddress.map((site) => {
      const _address = _.cloneDeep(addressFieldState.fields);
      _address.name.value = site.name || "";
      _address.addressLine1.value = site.addressLine1;
      _address.addressLine2.value = site.addressLine2;
      _address.suburb.value = site.suburb;
      _address.state.value = site.state;
      _address.postcode.value = site.postcode;
      _address.country.value = site.country;
      _address.contact_id.value = site.contact_id;
      // _address.contact_id.items = contactsList;
      _address.isPrimary.value = site.primary ? site.primary : false;
      // _address.level_id.value = site.level_id;
      _address.id = site.id;
      return _address;
    });

    if (address.length === 0) {
      const _address = _.cloneDeep(addressFieldState.fields);
      // _address.contact_id.items = contactsList;
      address.push(_address);
    }

    setAddressFieldState(prev => ({
      ...prev,
      address
    }));

    setAddState(prev => ({
      ...prev,
      edit: true,
      disable: true,
      contactsList: contactsList
    }));
    _getLevels();
  };

  const _getLevels = () => {
    dispatch(getLevels()).then((res) => {
      const levels = [];
      res.forEach((level) => levels.push({ key: level.name, value: level.id }));
      setFieldState(prev => ({
        ...prev,
        level_id: {
          ...prev.level_id,
          items: levels
        }
      }));
    });
  }

  const inputChangeHandler = (e, fieldName = "") => {
    let fields = _.cloneDeep(fieldState)
    var name, value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "phoneNumber") name = fieldName;
    fields[name].value = value;
    fields[name].touched = true;
    const { error, valid } = handleValidation({ name, value, validation: fields[name].validation || [] });
    fields[name].error = error;
    fields[name].valid = valid;
    let isFormValid = true;
    for (let key of Object.keys(fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }

    setFieldState(fields);
    setAddressFieldState(prev => ({ ...prev, isFormValid }));
  };

  // const onSuggestionSelected = (suggestion) => {
  //   geocodeByPlaceID(suggestion.place_id)
  //     .then((results) => {
  //       // const e = {
  //       //   target: { value: results[0].formatted_address, name: "address" },
  //       // };
  //       // this.inputChangeHandler(e);
  //     })
  //     .catch((err) => {
  //       console.error("Error", err);
  //     });
  // }

  const contactChangeHandler = (e, index, fieldName = "") => {
    let contacts = _.cloneDeep(contactFieldState.contacts)
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "phoneNumber") {
      name = fieldName;
    }
    if (fieldName === "firstName") name = fieldName;
    if (fieldName === "lastName") name = fieldName;
    if (fieldName === "isPrimary") {
      value = e.target.checked;
      if (e.target.checked) {
        contacts.forEach((contact, i) => {
          if (i !== index) contacts[i]['isPrimary']['value'] = false;
        });
      }
    }
    contacts[index][name].value = value;
    const { error, valid } = handleValidation({ name, value, validation: contacts[index][name].validation || [] });
    contacts[index][name].error = error;
    contacts[index][name].valid = valid;
    contacts[index][name].touched = true;

    if (fieldName === "firstName" || fieldName === "lastName") {
      let contactsList = []
      contacts.map((contact, index) => {
        contactsList.push({
          key: (contact.firstName.value || '') + " " + (contact.lastName.value || ''),
          value: contact.id || index,
        })
      })
      setAddState(prev => ({
        ...prev,
        contactsList: contactsList
      }));
    }

    setContactFieldState(prev => ({
      ...prev,
      contacts
    }));
  };

  const handleValidation = ({ name, type, value, validation = [] }) => {
    let error = false, valid = true;
    // let isIndividual = fieldState.type.value === "Individual" ? true : false;

    for (const iterator of validation) {
      if (iterator == 'required') {
        if (!value) {
          return { error: "This Field is Required", valid: false };
        }
      }
    }

    switch (name) {
      case 'abn': {
        if (!value && validation.includes('optional')) return { error: false, valid: true };
        if (value && value.length != 11) {
          return { error: "ABN should be 11 digits", valid: false };
        } else {
          return { error, valid }
        }
      }
      case 'paymentTerms': {
        if (Number(value) < 0) {
          return { error: "Should be greater than 0", valid: false };
        } else {
          return { error, valid }
        }
      }
      case 'email': {
        let status = emailValidator(value);
        if (value && !status) {
          return { error: "Please Enter Valid Email", valid: false };
        } else {
          return { error, valid }
        }
      }
      case 'phoneNumber': {
        if (value) {
          let status = phoneValidator(value);
          if (!status) {
            return { error: "Please Enter Valid Mobile Number", valid: false };
          } else {
            return { error, valid }
          }
        } else { return { error, valid } }
      }
      case 'postcode': {
        if (value) {
          let status = postCodeValidator(value);
          if (!status) {
            return { error: "Please Enter Valid Postcode", valid: false };
          } else {
            return { error, valid }
          }
        } else { return { error, valid } }
      }
      case 'contact_id': {
        if (!value) {
          return { error: "This Field is Required", valid: false };
        } else {
          return { error, valid }
        }
      }
      default: return { error, valid }
    }
  };

  const cancelHandler = () => {
    const redirect = searchParams.get("redirect")
    if (redirect) {
      let url = sessionStorage.getItem('url') || ''
      if (url.includes('?')) {
        url += '&redirect=true'
      } else {
        url += '?redirect=true'
      }
      navigate(url);
    } else {
      navigate(`/${CUSTOMER_MANAGEMENT}`);
    }
  };

  const _addContact = () => {
    setContactFieldState(prev => ({
      ...prev,
      contacts: [...prev.contacts, _.cloneDeep(contactFieldState.fields)]
    }));
  };

  const formatContactData = (contact) => {
    return {
      isPrimary: contact.isPrimary.value,
      firstName: contact.firstName.value,
      lastName: contact.lastName.value,
      email: contact.email.value,
      phoneNumber: contact.phoneNumber.value,
      designation: contact.designation.value,
      id: contact.id ? contact.id : undefined,
    };
  };

  const formatCreatedContactData = (contact) => {
    return {
      isPrimary: contact.primary,
      firstName: contact.first_name,
      lastName: contact.last_name,
      email: contact.email,
      phoneNumber: contact.phone_number,
      designation: contact.designation,
      id: contact.id ? contact.id : undefined,
    };
  };

  const removeContact = (index) => {
    let contacts = _.cloneDeep(contactFieldState.contacts)
    contacts.splice(index, 1);
    setContactFieldState(prev => ({
      ...prev,
      contacts
    }));
  };

  const enableEdit = () => {
    setAddState(prev => ({
      ...prev,
      disable: false,
    }));
  };

  const redirect = () => {
    const redirect = searchParams.get("redirect")
    if (redirect) {
      let url = sessionStorage.getItem('url') || ''
      if (url.includes('?')) {
        url += '&redirect=true'
      } else {
        url += '?redirect=true'
      }
      navigate(url);
    } else {
      navigate(`/${CUSTOMER_MANAGEMENT}`);
    }
  };

  const submitForm = async () => {
    const fields = _.cloneDeep(fieldState)
    const { contacts } = contactFieldState;
    const { edit } = addState;
    const { address } = addressFieldState;

    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    contacts.forEach((contact, i) => {
      for (let key in contact) {
        if (key !== "id") {
          const { error, valid } = handleValidation(contact[key]);
          contacts[i][key].touched = true;
          contacts[i][key].valid = valid;
          contacts[i][key].error = error;
          isFormValid = isFormValid && valid;
        }
      }
    });

    address.map((site, i) => {
      for (let key in site) {
        if (key !== "id") {
          const { error, valid } = handleValidation(site[key]);
          address[i][key].touched = true;
          address[i][key].error = error;
          address[i][key].valid = valid;
          isFormValid = isFormValid && valid;
        }
      }
    });
    if (!isFormValid) {
      setFieldState(prev => ({
        ...prev,
        ...fields,
      }));
      setContactFieldState(prev => ({
        ...prev,
        contacts,
      }));
      setAddressFieldState(prev => ({
        ...prev,
        address,
      }));
    } else {
      const data = {
        businessName: fields.businessName.value,
        abn: fields.abn.value,
        email: fields.email.value,
        phoneNumber: fields.phoneNumber.value,
        paymentTerms: fields.paymentTerms.value ? fields.paymentTerms.value : null,
        type: fields.type.value,
        level_id: fields.level_id.value || null,
        domain: fields.domain.value || '',
      };
      if (edit) {
        const NewContact = contacts.filter((contact) => {
          if (!contact.id) return contact;
        });
        const oldContact = contacts.filter((contact) => {
          if (contact.id) return contact;
        });
        const oldContactsData = oldContact.map((contact) => {
          return formatContactData(contact);
        });

        const newContactsData = NewContact.map((contact) => {
          return formatContactData(contact);
        });

        data.contacts = oldContactsData;

        let createdContacts
        if (newContactsData.length > 0) {
          createdContacts = await dispatch(addContact(newContactsData, customerId));
          let newContact = createdContacts.map((contact) => {
            return formatCreatedContactData(contact);
          });
          data.contacts.push(...newContact)
        }

        data.siteAddress = address.map((site) => {
          let _temp = contacts.find((item, index) => site.contact_id.value == index)

          if (createdContacts && Array.isArray(createdContacts) && _temp) {
            _temp = createdContacts.find(item => item.email == _temp.email.value && item.phone_number == _temp.phoneNumber.value && item.first_name == _temp.firstName.value)
          }

          return {
            id: site.id ? site.id : uuidv4(),
            addressLine1: site.addressLine1.value,
            addressLine2: site.addressLine2.value,
            suburb: site.suburb.value,
            state: site.state.value,
            postcode: site.postcode.value,
            country: site.country.value,
            name: site.name.value || '',
            contact_id: idx(_temp, _ => _.id) || site.contact_id.value,
            isPrimary: site.isPrimary.value
            // level_id: site.level_id.value,
          }
        }) || [];

        dispatch(editCustomer(data, customerId)).then((res) => {
          dispatch(getCustomerList()).catch(e => console.error(e))
          redirect();
        }).catch(e => {
          console.error(e)
        });

      } else {
        const contactsData = contacts.map((contact) => {
          return formatContactData(contact);
        });
        data.contacts = contactsData;
        data.siteAddress = address.map((site) => ({
          id: site.id ? site.id : uuidv4(),
          addressLine1: site.addressLine1.value,
          addressLine2: site.addressLine2.value,
          suburb: site.suburb.value,
          state: site.state.value,
          postcode: site.postcode.value,
          country: site.country.value,
          name: site.name.value || '',
          isPrimary: site.isPrimary.value,
          contact_id: Number(site.contact_id.value),
          // level_id: site.level_id.value,
        })) || [];

        dispatch(addCustomer(data)).then((res) => {
          dispatch(getCustomerList()).catch(e => console.error(e))
          redirect();
        }).catch(e => {
          console.error(e)
        });
      }
    }
  };

  const addSiteAddress = () => {
    const { fields } = addressFieldState;
    setAddressFieldState(prev => ({
      ...prev,
      address: [...prev.address, _.cloneDeep(fields)]
    }));
  };

  const removeSiteAddress = (index) => {
    let address = _.cloneDeep(addressFieldState.address)
    address.splice(index, 1);
    setAddressFieldState(prev => ({
      ...prev,
      address
    }));
  };

  const handleSiteAddressChange = (e, index, fieldName = "") => {
    let address = _.cloneDeep(addressFieldState.address)
    var name, value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "isPrimary") {
      if (e.target.checked) {
        address.forEach((contact, i) => {
          if (i !== index) address[i]['isPrimary']['value'] = false;
        });
      }
      address[index][name].value = e.target.checked;
    } else {
      address[index][name].value = e.target.value;
    }
    address[index][name].value = value;
    const { error, valid } = handleValidation({ name, value, validation: address[index][name].validation || [] });
    address[index][name].error = error;
    address[index][name].valid = valid;
    address[index][name].touched = true;

    let isFormValid = true;
    for (let key of Object.keys(address)) {
      isFormValid = isFormValid && !address[key].error;
    }

    setAddressFieldState(prev => ({ ...prev, address }));
  };

  // siteAddressSuggesstion(suggestion, id) {
  //   geocodeByPlaceID(suggestion.place_id)
  //     .then((results) => {
  //       const e = { target: { value: results[0].formatted_address } };
  //       handleSiteAddressChange(e, id);
  //     })
  //     .catch((err) => {
  //       console.error("Error", err);
  //     });
  // }

  return (
    <div className="purchase-order-block">

      <div className="row">
        <div className="w-auto">
          <h2 className="content-heading text-uppercase">{addState.edit ? "Edit Customer" : "Add a Customer"}</h2>
          <div className="col-12 breadcrumb-block p-0">
            <Breadcrumb>
              <Breadcrumb.Item onClick={cancelHandler}>CUSTOMERS</Breadcrumb.Item>
              <Breadcrumb.Item active>{addState.edit ? "Edit Customer" : "Add a Customer"}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {addState.edit && addState.disable && writePermission && (<div className="w-auto ms-auto my-auto">
          <button
            type="submit"
            className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary"
            onClick={enableEdit}>
            Edit
          </button>
        </div>)}
      </div>

      <div className="col-12 customer-form-block">
        <div className="row m-0">
          <div className="col-12 customer-form-heading mb-3">
            <h2 className="content-heading text-uppercase mb-0">Business Information</h2>
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <SelectInput
              {...fieldState.type}
              disabled={addState.disable}
              onChange={(e) => inputChangeHandler(e, fieldState.type.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              {...fieldState.businessName}
              label={fieldState.type.value == 'Individual' ? 'Family Name' : 'Business Name'}
              disabled={addState.disable}
              onChange={(e) => inputChangeHandler(e, fieldState.businessName.name)}
            />
          </div>
          {fieldState.type.value == 'Business' && <>
            <div className="col-lg-4 col-md-6 mb-3">
              <CustomInput
                disabled={addState.disable}
                {...fieldState.abn}
                // required={fieldState.type.value == 'Business' ? true : false}
                onChange={(e) => inputChangeHandler(e, fieldState.abn.name)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <CustomInput
                disabled={addState.disable}
                {...fieldState.domain}
                onChange={(e) => inputChangeHandler(e, fieldState.domain.name)}
              />
            </div>
          </>}
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={addState.disable}
              {...fieldState.phoneNumber}
              onChange={(e) => inputChangeHandler(e, fieldState.phoneNumber.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={addState.disable}
              {...fieldState.email}
              onChange={(e) => inputChangeHandler(e, fieldState.email.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={addState.disable}
              {...fieldState.paymentTerms}
              onChange={(e) => inputChangeHandler(e, fieldState.paymentTerms.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <SelectInput
              {...fieldState.level_id}
              disabled={addState.disable}
              onChange={(e) => inputChangeHandler(e, fieldState.level_id.name)}
            />
          </div>
        </div>
      </div>

      <div className="col-12 customer-form-block mt-3">
        <div className="col-12 customer-form-heading mb-3">
          <div className="d-flex">
            <h2 className="content-heading text-uppercase my-auto me-auto">Address</h2>
            <div className="expand-icon my-auto">
              <ExpandMoreIcon onClick={() => {
                setAddState(prev => ({
                  ...prev,
                  showSiteAddress: !prev.showSiteAddress,
                }));
              }} aria-controls="example-collapse-text" aria-expanded={addState.showSiteAddress} />
            </div>
          </div>
        </div>
        <Collapse in={addState.showSiteAddress}>
          <div id="example-collapse-text">

            {addressFieldState.address.map((site, index) => {
              return (
                <div className="row m-0">
                  {/* <div className="col-12">
                    <MUIPlacesAutocomplete
                      onSuggestionSelected={onSuggestionSelected}
                      renderTarget={() => (<div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginTop: 16,
                        }}
                      >
                        <Button variant="raised" color="primary" type="submit">Submit</Button>
                      </div>)}
                    />

                  </div> */}
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.name}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.name.name)}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.addressLine1}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.addressLine1.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.addressLine2}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.addressLine2.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.suburb}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.suburb.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.state}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.state.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.postcode}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.postcode.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...site.country}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.country.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <SelectInput
                      {...site.contact_id}
                      items={addState.contactsList}
                      disabled={addState.disable}
                      onChange={(e) => handleSiteAddressChange(e, index, site.contact_id.name)}
                    />
                    <small>Please fill contact as it will be populated here</small>
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2 row">
                    <div className="col-lg-5 col-md-5 align-self-center">
                      <div className="form-group">
                        <Form>
                          <div className="table-checkbox table-checkbox-ch text-left">
                            <Form.Check
                              id={`${index}-address-primary`}
                              type={'checkbox'}
                              label={`Set as primary`}
                              checked={site.isPrimary.value}
                              {...site.isPrimary}
                              disabled={addState.disable}
                              onChange={(e) => handleSiteAddressChange(e, index, site.isPrimary.name)}
                            />
                          </div>
                        </Form>
                      </div>
                    </div>
                    {!addState.disable && <div className="col-lg-7 col-md-7 d-flex my-auto text-right">
                      <button
                        type="button"
                        onClick={() => removeSiteAddress(index)}
                        className="ms-auto primarybtn cancelbtn btn btn-primary text-uppercase">
                        Remove Site Address
                      </button>
                    </div>}
                  </div>
                  <div className="col-12">
                    <hr></hr>
                  </div>
                </div>
              )
            })}

            {!addState.disable && <div className="row m-0">
              <div className="col-12 d-flex mb-2">
                <button
                  type="button"
                  onClick={addSiteAddress}
                  className="ms-auto primarybtn btn btn-primary text-uppercase">
                  Add Site Address
                </button>
              </div>
            </div>}

          </div>
        </Collapse>
      </div>

      <div className="col-12 customer-form-block mt-3">
        <div className="col-12 customer-form-heading mb-3">
          <div className="d-flex">
            <h2 className="content-heading text-uppercase my-auto me-auto">Contacts</h2>
            <div className="expand-icon my-auto">
              <ExpandMoreIcon onClick={() => {
                setAddState(prev => ({
                  ...prev,
                  showContactAddress: !prev.showContactAddress,
                }));
              }} aria-controls="example-collapse-text" aria-expanded={addState.showContactAddress} />
            </div>
          </div>

        </div>
        <Collapse in={addState.showContactAddress}>
          <div id="example-collapse-text2">

            {contactFieldState.contacts.map((contact, index) => {
              return (
                <div className="row m-0">
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...contact.firstName}
                      disabled={addState.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.firstName.name)}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...contact.lastName}
                      disabled={addState.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.lastName.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...contact.email}
                      disabled={addState.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.email.name)}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...contact.phoneNumber}
                      disabled={addState.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.phoneNumber.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2">
                    <CustomInput
                      {...contact.designation}
                      disabled={addState.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.designation.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 pb-2 row">
                    <div className="col-lg-5 col-md-5 align-self-center">
                      <div className="form-group">
                        <Form>
                          <div className="table-checkbox table-checkbox-ch text-left">
                            <Form.Check
                              id={`${index}-contact-primary`}
                              type={'checkbox'}
                              label={`Set as primary`}
                              checked={contact.isPrimary.value}
                              {...contact.isPrimary}
                              disabled={addState.disable}
                              onChange={(e) => contactChangeHandler(e, index, contact.isPrimary.name)}
                            />
                          </div>
                        </Form>
                      </div>
                    </div>
                    {!addState.disable && <div className="col-lg-7 col-md-7 d-flex my-auto">
                      <button
                        type="button"
                        onClick={() => removeContact(index)}
                        className="ms-auto primarybtn cancelbtn btn btn-primary text-uppercase">
                        Remove Contact
                      </button>
                    </div>}
                  </div>
                  <div className="col-12">
                    <hr></hr>
                  </div>
                </div>
              )
            })}

            {!addState.disable && <div className="row m-0">
              <div className="col-12 d-flex mb-3">
                <button
                  type="button"
                  onClick={_addContact}
                  className="ms-auto primarybtn btn btn-primary text-uppercase">
                  Add Contact
                </button>
              </div>
            </div>}
          </div>
        </Collapse>
      </div>

      <div className="col-12 d-flex justify-content-end gap-3 mt-3">
        {!addState.disable && writePermission && (
          <Button type="submit" className="secondarybtn" onClick={submitForm}>
            SAVE
          </Button>
        )}
        <Button type="submit" className="secondarybtn cancelbtn" onClick={cancelHandler}>CANCEL</Button>
      </div>

    </div >
  );

}
