import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getvendorsPOList,
  downloadProjectPO,
  getProject,
} from "src/actions/project-management.action";
import { warningSnackBar } from "src/actions/common.action";
import PayInvoice from "./pay-invoice";
import { PROJECT_MANAGEMENT, SUPPLIERS, VENDOR_PAYMENT } from "src/constants/route-paths";
import { format } from "date-fns";
import VendorPaymentFilter from "./vendor-payment-filter";
import { numberFormat } from "src/util/currency-formatter";
import DownloadDialog from "src/components/download-dialog";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { withRouter } from "src/util/with-router";
import { getGlobalSettingTemplateTypes } from "src/actions/global-setting-template.action";
import 'src/routes/project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Popover from 'react-bootstrap/Popover';
import idx from "idx";
import CustomTable from "src/components/table/table";

function SupplierInvoiceList({ projectModule, writePermission, ...props }) {

  const [state, setState] = useState({
    showPayInvoice: false,
    columns: [],
    list: [],
    submitDialog: false,
    discardDialog: false,
    supplierCount: null,
    subContractorCount: null,
    vendorType: "ALL",
    invoiceData: {},
    workType: "project",
    projectId: null,
    suppliers: [],
    templateOptions: [],
    subContractors: [],
    allInvoices: [],
    breadCumbText: null,
    showFilter: false,
    downloadData: {
      open: false,
      id: "",
      template: "",
    },
    projectData: null
  })

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project");
    const poId = urlParams.get("poId");

    let breadCumbText = null;
    if (projectId) {
      breadCumbText = "Project";
    }

    setState(prev => ({
      ...prev,
      projectId, poId, breadCumbText
    }));
    fetchList()

    if (!state.projectData && projectId) {
      props.getProject(projectId).then((projectData) => {
        setState(prev => ({
          ...prev,
          projectData: projectData
        }));
      })
    }
  }

  const handleMenu = (rowData) => {
    setState(prev => ({
      ...prev,
      invoiceId: rowData.invoiceId,
      invoiceData: {
        poNo: rowData.poNumber,
        poNumber: rowData.poId,
        isSubContractor: rowData.vendorType === "sub-contractor",
      },
    }));
  };

  const handlePayInvoiceModal = (status, allProjects = false) => {
    if (!status) {
      fetchList();
    }
    setState(prev => ({
      ...prev,
      showPayInvoice: status,
      invoiceData: { ...(allProjects ? {} : { ...prev.invoiceData }) },
    }));
  };

  const fetchList = async ({ pageNo, pageSize, vendorType, startDate, endDate, } = {}) => {
    const params = {
      pageNo,
      pageSize,
      vendorType: 'Supplier',
      startDate,
      endDate,
    };
    let { projectId, poId } = state;
    if (projectId) {
      params.projectId = projectId;
    }
    if (poId) {
      params.poId = poId;
    }
    setState(prev => ({
      ...prev,
      vendorType: vendorType || "ALL",
    }));

    let { list, vendorTypesCount } = await props.getvendorsPOList(params);

    const suppliers = (Array.isArray(list) && list.filter(({ vendorType }) => vendorType === "Supplier")) || [];
    const subContractors = (Array.isArray(list) && list.filter(({ vendorType }) => vendorType === "sub-contractor")) || [];
    const allInvoices = (Array.isArray(list) && list) || [];
    const supplierCount = vendorTypesCount.suppliersCount || 0;
    const subContractorCount = vendorTypesCount.subContractorsCount || 0;
    const columns = [
      { title: "Vendor Name", field: "vendorName", render: (rowData) => rowData.vendorName },
      // {
      //   title: "Vendor Type",
      //   field: "vendorType",
      //   // lookup: { "sub-contractor": "Sub-Contractor", Supplier: "Supplier" },
      //   render: (rowData) => rowData.vendorType == 'sub-contractor' ? "Sub-Contractor" : rowData.vendorType
      // },
      {
        title: "Invoice No",
        field: "invoiceNo",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => viewInvoice(rowData)}
          >
            {rowData.invoiceNo}
          </span>
        ),
      },
      { title: "PO Number", field: "poNumber", render: (rowData) => rowData.poNumber },
      { title: "Project/Job", field: "projectName", render: (rowData) => rowData.projectName },
      {
        title: "Invoice Date",
        field: "invoiceDate",
        render: (rowData) => (rowData.invoiceDate && format(new Date(rowData.invoiceDate), "dd/MM/yyyy")) || "",
      },
      {
        title: "Amount Paid",
        field: "amountPaid",
        render: (rowData) => `${numberFormat(rowData.amountPaid)}`,
        class: 'text-right',
      },
      {
        title: "Retained Amount",
        field: "retainedAmount",
        render: (rowData) => `${numberFormat(rowData.retainedAmount)}`,
        class: 'text-right',
      },
      {
        title: "Received By",
        field: "userName",
        render: (rowData) => rowData.userName
      },
    ];
    list.forEach((item, index) => {
      item["id"] = index;
      return item;
    });
    setState(prev => ({
      ...prev,
      supplierCount,
      subContractorCount,
      columns,
      list,
      suppliers,
      subContractors,
      allInvoices,
    }));
  };

  const viewPO = () => {
    const { workType, invoiceId, projectId, poId } = state;
    props.history(
      `/${SUPPLIERS}/${VENDOR_PAYMENT}/view/${invoiceId}${`?type=${workType}`}${projectId ? `&id=${projectId}` : ""}${poId ? `&poId=${poId}` : ""}`
    );
  };

  const showFilterHandler = () => {
    setState(prev => ({
      ...prev,
      showFilter: !prev.showFilter,
    }));
  };

  const dateChangeFilter = (startDate, endDate) => {
    fetchList({ startDate, endDate });
    showFilterHandler()
  };

  const gettemplate = async (type) => {
    let templateOptions = state;
    let newdata = [];
    let projectId = props.params.projectId;
    const templateData = await props.getGlobalSettingTemplateTypes(type, projectId);
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    setState(prev => ({
      ...prev,
      templateOptions
    }));
  };

  const handleDownloadDialog = (id = "", template = "") => {
    const { downloadData = {}, invoiceId } = state;
    downloadData.open = !downloadData.open;
    downloadData.id = id || invoiceId;
    // downloadData.template = "subcontractor_payment_schedule";
    gettemplate("subcontractor_payment_schedule");
    setState(prev => ({
      ...prev,
      downloadData
    }));
  };

  const downloadFile = async (fileFormat = "pdf", templatevalue) => {
    try {
      const { downloadData = {} } = state;
      if (fileFormat === "pdf" || fileFormat === "ALL") {
        await props.downloadProjectPO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        await props.downloadProjectPO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
      }
      handleDownloadDialog();
    } catch (e) {
      console.error(e)
    }
  };

  const viewInvoice = (rowData) => {
    setState(prev => ({
      ...prev,
      invoiceId: rowData.invoiceId,
      invoiceData: {
        poNo: rowData.poNumber,
        poNumber: rowData.poId,
        isSubContractor: rowData.vendorType === "sub-contractor",
      },
    }));
    viewPO();
  }

  const ViewProjectList = () => {
    let projectType = idx(state, _ => _.projectData.projectType)
    props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  const ViewProject = () => {
    let { projectId } = state;
    props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  return (
    <>
      <DownloadDialog
        {...(state.downloadData || {})}
        downloadFile={downloadFile}
        handleDownloadDialog={handleDownloadDialog}
        templateOptions={state.templateOptions}
        downloadModuleName="Vendor Payment"
      />
      {state.showPayInvoice && (
        <PayInvoice
          {...(state.invoiceData || {})}
          workType={state.workType}
          handleDownloadDialog={handleDownloadDialog}
          handleClose={() => handlePayInvoiceModal(false)}
        />
      )}
      {state.showFilter && <VendorPaymentFilter
        changeFilter={dateChangeFilter}
        workType={state.workType}
        dialogClose={showFilterHandler}
        open={state.showFilter}
      />}

      {projectModule === false ? (
        <p>
          You do not currently have access to any Projects. Please
          contact your Administrator if this is required.
        </p>
      ) : (
        <>
          <div className="purchase-order-block">
            <div className="row">
              <div className="w-auto">
                <h2 className="content-heading text-uppercase">Supplier Invoices</h2>
                <div className="col-12 breadcrumb-block p-0">
                  <Breadcrumb>
                    {state.breadCumbText && (
                      <>
                        <Breadcrumb.Item onClick={ViewProjectList}>PROJECTS</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ViewProject}>{idx(state, _ => _.projectData.name)}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Vendor Invoices</Breadcrumb.Item>
                      </>
                    )}
                  </Breadcrumb>
                </div>
              </div>
              {!state.projectId && (<div className="w-auto ms-auto mt-auto">
                {writePermission && (
                  <button
                    className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary"
                    onClick={() => handlePayInvoiceModal(true, true)}
                  >
                    Receive Invoice
                  </button>)}
                <button
                  onClick={showFilterHandler}
                  aria-label="filter"
                  className="btn"
                >
                  <FilterAltIcon />
                </button>
              </div>)}
            </div>

            <div className="row">
              <div className="col-12 mt-3">

                <CustomTable
                  columns={state.columns}
                  data={state.suppliers}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          {writePermission && <li onClick={() => handlePayInvoiceModal(true)}>
                            Receive Invoice
                          </li>}
                          <li onClick={() => viewPO()}>
                            View Invoice
                          </li>
                          {data.vendorType === "sub-contractor" && (<li onClick={() => handleDownloadDialog()}>
                            Download Payment Schedule
                          </li>)}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Supplier Invoices List'
                />

              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  projectModule: state.project.projectModule,
  projectDetail: state.projectDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getvendorsPOList: bindActionCreators(getvendorsPOList, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(getGlobalSettingTemplateTypes, dispatch),
  downloadProjectPO: bindActionCreators(downloadProjectPO, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierInvoiceList));
