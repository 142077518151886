import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import { useSelector } from "react-redux";
import idx from 'idx'
import { updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import SubTask from "./subtask";
import { numberFormat } from "src/util/currency-formatter";
import CustomInput from "src/components/inputs/new-input";
import SaveTemplate from "src/components/save-template";
import ListItemTemplate from "src/components/list-template/item";
import { materialToForm } from "./binders/material";
import { subcontractorToForm } from "./binders/subcontractor";
import { laborToForm } from "./binders/labor";
import { editItemTemplate, editTaskTemplate, getItemTemplates, getTaskTemplates } from "src/actions/template-management.action";
import { getSubcontractorsData } from "src/routes/project-management/utils/get-subcontractors";
import { getLaborList } from "src/actions/inventory.action";
import { addLevelAction } from "src/slice/project-detail";
import { formatTaskData } from "src/components/save-template/formatters/task";
import { formatSubTaskData } from "src/components/save-template/formatters/subtask";
import { addResourcesAction } from "src/slice/resources";

export default function EditTemplate({ open, dialogClose, isSubTask = false, subTaskId = '', isTask = false, taskId = '', saveHandler, ...props }) {

  const dispatch = useDispatch()

  const { projectDetail = {}, task } = useSelector(state => state)
  let laborOptions = useSelector(state => state.resources.active) 
  const [editMode, setEditMode] = useState((props.isSubTask || props.isTask) || false);
  const [fields, setFields] = useState({
    taskName: {
      name: 'taskName',
      label: 'Task Name',
      type: 'text',
      touched: false,
      error: false,
      valid: true,
      value: '',
      validation: ['required']
    },
  })
  const [taskState, setTaskState] = useState({
    showlockIcon: true,
    profitMarginDialogState: false,
    showItemTemplateDialog: false,
    saveTemplateDialog: false,
    saveTemplateData: {
      type: '',
      data: {}
    }
  })
  // 
  useEffect(() => {
    initLoad();
  }, [taskId, subTaskId])

  const initLoad = async () => {
    await getSubcontractorsData({ type: "sub-contractor", dispatch })
    if(!laborOptions.length) {
      getLevel()
    }

    if (taskId) {
      dispatch(getTaskTemplates(taskId)).then((data) => {
        preFill(data);
        loadItemFromTemplate(data.subTasks, true, true)
      });
    } else if (subTaskId) {
      dispatch(getItemTemplates(subTaskId)).then((data) => {
        loadItemFromTemplate([data], true, true)
      });
    }
  }

  const getLevel = async () => {
    dispatch(getLaborList()).then((data) => {
      let labors = (data || []).map((labor, i) => {
        labor.sno = i + 1;
        return labor;
      })
      dispatch(addResourcesAction(labors));
    });
  }

  const preFill = (task) => {
    setFields(prev => ({
      ...prev,
      taskName: {
        ...prev.taskName,
        value: task.name
      }
    }))
  };

  const getModalHeader = () => {
    let modalHeading = "Edit Task Template";

    if (!editMode) {
      modalHeading = isSubTask ? "Item Template Details" : "Task Template Details"
    } else {
      if (isSubTask) modalHeading = "Edit Item Template";
    }
    return modalHeading;
  }

  const inputChangeHandler = (e, fieldName = "", index = "") => {
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)

    let description;
    let descriptionLength;
    if (e.target.value) {
      description = e?.target?.value.trim();
      descriptionLength = description.length;
    }

    if (_temp[index]) {
      _temp[index].descriptionError = !description;
      _temp[index].descriptionLength = description && descriptionLength < 2;
    } else {
      _temp[index] = {
        descriptionError: !description,
        descriptionLength: description && descriptionLength < 2,
      };
    }

    fields[e.target.name].value = e.target.value;
    fields[e.target.name].touched = true;
    fields[e.target.name].error = false;
    fields[e.target.name].valid = true;
    setFields(prev => ({
      ...prev,
      ...fields
    }))
  };

  const handleAddTask = () => {
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)
    const clonedAccordionFields = {
      labor: [],
      subcontractor: [],
      material: [],
      taskName: "",
      is_actual_materialcost: false,
      descriptionError: true,
      descriptionLength: true,
    };
    _temp.push(clonedAccordionFields);
    dispatch(updateSubTaskAction(_temp))
  };

  const submitHandler = async (type = "") => {

    let req = {}
    if (taskId) {
      req = formatTaskData(idx(task, _ => _.task), fields.taskName.value, false);
      dispatch(editTaskTemplate(taskId, req)).then((res) => {
        saveHandler()
      }).catch(e => console.error(e))
    } else if (subTaskId) {
      let { subTaskArray = [] } = idx(task, _ => _.task) || {};
      if (subTaskArray.length == 1) {
        req = formatSubTaskData(subTaskArray[0]);
        dispatch(editItemTemplate(subTaskId, req)).then((res) => {
          saveHandler()
        }).catch(e => console.error(e))
      }
    }
  };

  const handleSaveTemplateDialog = (type = '', data = {}) => {
    setTaskState(prev => ({
      ...prev,
      saveTemplateDialog: !prev.saveTemplateDialog,
      saveTemplateData: {
        type: type,
        data: data
      }
    }));
  }

  const handleItemTemplateDialog = () => {
    setTaskState(prev => ({
      ...prev,
      showItemTemplateDialog: !prev.showItemTemplateDialog,
    }));
  }

  const loadItemFromTemplate = (items = [], skipClose = false, isAdd = false) => {
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = isAdd ? [] : _.cloneDeep(subTaskArray)

    for (const iterator of items) {
      const { material = [], labor = [], subcontractor = [], description } = iterator;

      const cloneSubTask = {
        id: isAdd ? iterator?.id : '',
        description: description,
        labor: labor.map(_labor => laborToForm(_labor, { qty: iterator.selectedQty })),
        subcontractor: subcontractor.map(_subcontractor => subcontractorToForm(_subcontractor, { qty: iterator.selectedQty, })),
        material: material.map(_material => materialToForm(_material, { qty: iterator.selectedQty, })),
        is_actual_materialcost: false,
        descriptionError: !description ? true : false,
        descriptionLength: !description ? true : false,
      };
      _temp.push(cloneSubTask);
    }
    dispatch(updateSubTaskAction(_temp))
    if (!skipClose) handleItemTemplateDialog()
  };

  return (
    <>
      <Modal
        show={open}
        onHide={() => dialogClose()}
        dialogClassName="modal-90w create-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {getModalHeader()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SaveTemplate
            open={taskState.saveTemplateDialog}
            dialogClose={() => handleSaveTemplateDialog()}
            data={taskState.saveTemplateData.data}
            type={taskState.saveTemplateData.type}
          />
          {taskState.showItemTemplateDialog && <ListItemTemplate
            open={taskState.showItemTemplateDialog}
            dialogClose={() => handleItemTemplateDialog()}
            addHandler={loadItemFromTemplate}
          />}
          {isTask && <div className="row mb-3">
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                placeholder="Enter task name"
                label={isSubTask ? "SubTask Name" : "Task Name"}
                {...fields.taskName}
                disabled={!editMode}
                onChange={(e) => inputChangeHandler(e)}
              />
            </div>
          </div>}
          <Tabs
            defaultActiveKey="items"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="items" title="Items">
              <SubTask
                isSubTask={isSubTask}
                profitMargin={0}
                laborOptions={laborOptions}
                profitMarginType={'PERCENTAGE'}
                subcontractors={idx(projectDetail, _ => _.subcontractors) || []}
                laborList = {projectDetail.labors}
                subTaskArray={idx(task, _ => _.task.subTaskArray) || []}
                isDisabled={!editMode}
                handleSaveTemplateDialog={handleSaveTemplateDialog}
              />

              {editMode && !isSubTask && <div className="col-12 justify-content-end mt-2 gap-2 d-flex btn-res">
                <Button onClick={handleItemTemplateDialog} className="primarybtn">ADD TEMPLATE</Button>
                <Button onClick={handleAddTask} className="primarybtn">ADD ITEM</Button>
              </div>}
              <div className="col-12 total-table mt-3 table-responsive">
                <Table bordered className="table-create mb-0 tbl-fixed tbl-fixed-width">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Material</th>
                      <th>Resource</th>
                      <th>Contractor</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span className="tableinfo">
                          {numberFormat(idx(task, _ => _.task.total.material.sale) || 0)}
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {numberFormat(idx(task, _ => _.task.total.labor.sale) || 0)}
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {numberFormat(idx(task, _ => _.task.total.subcontractor.sale) || 0)}
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {numberFormat(idx(task, _ => _.task.total.total) || 0)}
                        </span>
                      </td>
                      <td>
                        <span className="empty-icon"></span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>

          <div className="col-12 d-flex mt-3 gap-2">
            {/* <Button onClick={() => handleSaveTemplateDialog('task', idx(task, _ => _.task))} className="primarybtn">SAVE AS TEMPLATE</Button> */}

            {editMode && <>
              <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => submitHandler()}>SAVE</Button>
              <Button type="submit" className="secondarybtn cancelbtn" onClick={dialogClose}>CANCEL</Button>
            </>}
            {!editMode && <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => setEditMode(true)}>
              {isSubTask ? "Edit Item" : "Edit Task"}
            </Button>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

}
