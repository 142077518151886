import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getVendorList } from './../../actions/vendor-management.action'
import Input from './../../components/inputs/input-simple'
import {
  Grid,
  Divider,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

function SendRFQ(props) {
  const { projectName } = props
  // const { customerName, totalAmount } = props
  const [vendorContacts, setVendorContacts] = useState([])

  useEffect(() => {
    if (vendorContacts.length === 0) {
      props.getVendorList().then(vendors => {
        const contacts = []
        vendors && vendors.forEach(vendor => {
          vendor.contacts && vendor.contacts.forEach(contact => {
            if (contact.primary && vendor.type === props.vendorType) {
              const title = contact.email + '(' + vendor.businessName + ')'
              const value = contact.email
              const name = vendor.businessName
              const vendorId = vendor.id
              contacts.push({ title, value, name, vendorId })
            }
          })
        })
        setVendorContacts(contacts)
      })
    }
  }, [])
  return (
    <div>
      <Grid container style={{ flexDirection: 'column', padding: '10px' }}>
        <Grid xs={12} item style={{ paddingTop: '10px' }}>
          Proposal Name: <b>{projectName}</b>
        </Grid>
        {/* <Grid xs={12} item style={{ paddingTop: '10px' }}>
          Quotation number: <b>{projectName}</b>
        </Grid>
        <Grid xs={12} item style={{ paddingTop: '10px' }}>
          Proposal for: <b>{customerName}</b>
        </Grid>
        <Grid xs={12} item style={{ paddingTop: '10px' }}>
          <b>Total Cost: ${totalAmount}</b>
        </Grid> */}
      </Grid>
      <Divider style={{ backgroundColor: '#A9A9A9', height: '2px' }} />
      <Grid container style={{ flexDirection: 'column', padding: '10px' }}>
        <Grid xs={12} item style={{ paddingTop: '10px' }}>
          Send to:
        </Grid>
        <Grid xs={12} className='vendorAutocomplete' item style={{ paddingTop: '10px' }}>
          <Input
            label=''
            variant='outlined'
            margin='dense'
            type='autoComplete'
            style={{ width: '400px' }}
            options={vendorContacts}
            onChange={(event, values) => { props.getVendorEmail(values) }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  getVendorList: bindActionCreators(getVendorList, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SendRFQ)
