import { laborObjFields } from "../../constants/fields.constant";
import { cloneDeep, isString } from "lodash";

export const convertToLaborForm = (labors = []) => {
    let _labors = labors.map((iterator) => {
        if (isString(iterator)) {
            iterator = JSON.parse(iterator);
        }

        let laborObj = cloneDeep(laborObjFields);

        laborObj.name.value = iterator.name;
        laborObj.level.value = iterator.level;
        laborObj.id.value = iterator.id;
        laborObj.costPerItem.value = iterator.cost;
        laborObj.qty.value = iterator.qty;
        laborObj.total.value = iterator.total;
        laborObj.calloutCost.value = iterator.calloutCost ? iterator.calloutCost : 0;
        laborObj.travelCost.value = iterator.travelCost ? iterator.travelCost : 0;
        laborObj.labourCost.value = iterator.averageCost ?? (iterator.cost || 0);
        laborObj.skillDescription.value = iterator.skillDescription;

        return laborObj;
    }) || [];

    return _labors;
}