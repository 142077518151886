import { React, useState } from 'react';
import { defaults } from "chart.js/auto";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import '../project-management/scss/task.scss'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import idx from 'idx';
import _ from 'lodash';
import { random_rgba } from 'src/util/color';
import { noDataPlugin } from './charts/plugin';
import DashboardTable from 'src/components/table/dashboard-table';
import { projectBudgetStatus, projectCompletion, projectInvoiceStatus, projectList, projectPurchaseOrder, projectWorkOrder, timesheetColumn } from './data/operations-columns';
import { DashboardTableModal } from 'src/components/modal/dsahboard-table';
import { useSelector } from 'react-redux';

defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = false;
defaults.plugins.title.align = "center";
defaults.plugins.title.font.size = 15;
defaults.plugins.title.color = "black";
defaults.plugins.legend.display = false;

function OperationsTab() {
    const [view, setView] = useState({});
    const handleClose = () => setView({ show: false });
    const handleShow = (data) => setView({ show: true, ...data });
    const data = useSelector(state => state.dashboard.operations)

    return (
        <div className="row row-padding">
            <div className="col-12 chart-heading">
                <h2>CURRENT OPERATIONs</h2>
            </div>
            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Tasks Overdue</h3>
                    <div className="donut-chart">
                        <Doughnut
                            data={{
                                labels: [...(idx(data, _ => _.project.task.past.total) ? ['Completed', 'Pending'] : [])],
                                datasets: [{
                                    label: 'Value',
                                    data: [...(idx(data, _ => _.project.task.past.total) ? [
                                        { label: 'Completed', value: idx(data, _ => _.project.task.past.total) },
                                        { label: 'Pending', value: 100 - idx(data, _ => _.project.task.past.total) }
                                    ] : [])],
                                    backgroundColor: ["rgba(35, 207, 207,0.8)", "rgb(225, 225, 225)"],
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Task Pending</h3>
                    <div className="donut-chart">
                        <Doughnut
                            data={{
                                labels: [...(idx(data, _ => _.project.task.future.total) ? ['Completed', 'Pending'] : [])],
                                datasets: [{
                                    label: 'Value',
                                    data: [...(idx(data, _ => _.project.task.future.total) ? [
                                        { label: 'Completed', value: idx(data, _ => _.project.task.future.total) },
                                        { label: 'Pending', value: 100 - idx(data, _ => _.project.task.future.total) }
                                    ] : [])],
                                    backgroundColor: ["rgba(35, 207, 207,0.8)", "rgb(225, 225, 225)"],
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Invoices</h3>
                    <div className="bar-chart">
                        <Bar
                            data={{
                                labels: ['Created', 'Submitted', 'Approved', 'Finalised'],
                                datasets: [
                                    {
                                        label: "Task",
                                        data: [
                                            idx(data, _ => _.invoice.created),
                                            idx(data, _ => _.invoice.submitted),
                                            idx(data, _ => _.invoice.approved),
                                            idx(data, _ => _.invoice.finalised)
                                        ],
                                        backgroundColor: [
                                            'rgba(54, 162, 235, 0.4)',
                                            'rgba(255, 205, 86, 0.4)',
                                            'rgba(75, 192, 192, 0.4)',
                                            'rgba(153, 102, 255, 0.4)',
                                        ],
                                        borderColor: [
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 205, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                        ],
                                    },
                                ],
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Actual / Forecast Cost</h3>
                    <div className="bar-chart">
                        <Bar
                            data={{
                                labels: ["Material", "Resource", "Contractor"],
                                datasets: [{
                                    label: 'Total Forecast Cost',
                                    data: [
                                        idx(data, _ => _.project.actual_forecast.material.forecast),
                                        idx(data, _ => _.project.actual_forecast.labor.forecast),
                                        idx(data, _ => _.project.actual_forecast.contractor.forecast),
                                    ],
                                    backgroundColor: "rgba(75, 192, 192, 0.6)"
                                }, {
                                    label: 'Total Actual Cost',
                                    data: [
                                        idx(data, _ => _.project.actual_forecast.material.actual),
                                        idx(data, _ => _.project.actual_forecast.labor.actual),
                                        idx(data, _ => _.project.actual_forecast.contractor.actual),
                                    ],
                                    backgroundColor: "rgba(255, 205, 86, 0.6)"
                                }]
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative">Project Completion
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project Completion', columns: projectCompletion, data: idx(data, _ => _.project.completion) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectCompletion}
                                data={_.slice((idx(data, _ => _.project.completion) || []), 0, 5)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative"> Project Invoice Status
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project Invoice Status', columns: projectInvoiceStatus, data: idx(data, _ => _.project.progress_claim) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectInvoiceStatus}
                                data={_.slice((idx(data, _ => _.project.progress_claim) || []), 0, 5)}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-lg-3 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative">  Project Budget Status
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project Budget Status', columns: projectBudgetStatus, data: idx(data, _ => _.project.budget_status) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectBudgetStatus}
                                data={_.slice((idx(data, _ => _.project.budget_status) || []), 0, 5)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Tasks Completed vs Timesheet</h3>
                    <div className="bar-chart">
                        <Bar
                            data={{
                                labels: ['Task Completed', ' Timesheet Scheduled', 'Timesheet Approved'],
                                datasets: [{
                                    label: 'Total Hours',
                                    data: [
                                        idx(data, _ => _.project.timesheet.labor_hrs),
                                        idx(data, _ => _.project.timesheet.scheduled),
                                        idx(data, _ => _.project.timesheet.approved)
                                    ],
                                    backgroundColor: [
                                        'rgba(54, 162, 235, 0.4)',
                                    ],
                                    borderColor: [
                                        'rgba(54, 162, 235, 1)',
                                    ],
                                }]
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>RESOURCE PROGRESS</h3>
                    <div className="donut-chart">
                        <Pie
                            data={{
                                labels: (idx(data, _ => _.project.labor.future) || []).filter(item => item.hrs).map(item => item.name),
                                datasets: [{
                                    label: 'Sum of Resource hours',
                                    data: (idx(data, _ => _.project.labor.future) || []).filter(item => item.hrs).map(item => item.hrs),
                                    backgroundColor: (idx(data, _ => _.project.labor.future) || []).map(() => random_rgba()),
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Material Progress</h3>
                    <div className="donut-chart">
                        <Doughnut
                            data={{
                                labels: [...(idx(data, _ => _.project.material.progress.total) ? ['Completed', 'Pending'] : [])],
                                datasets: [{
                                    label: 'Value',
                                    data: [...(idx(data, _ => _.project.material.progress.total) ? [
                                        { label: 'Completed', value: idx(data, _ => _.project.material.progress.total) || 0 },
                                        { label: 'Pending', value: 100 - (idx(data, _ => _.project.material.progress.total) || 0) }
                                    ] : [])],
                                    backgroundColor: ["rgba(35, 207, 207,0.8)", "rgb(225, 225, 225)"],
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Purchase Order Stats</h3>
                    <div className="bar-chart">
                        <Bar
                            data={{
                                labels: ['Purchase Orders', 'Invoice Received', 'Material Fully Received'],
                                datasets: [{
                                    label: 'Count',
                                    data: [
                                        idx(data, _ => _.project.po.total),
                                        idx(data, _ => _.project.po.invoice_received),
                                        idx(data, _ => _.project.po.material_received),
                                    ],
                                    backgroundColor: [
                                        'rgba(0, 0, 128, 0.4)',
                                    ],
                                    borderColor: [
                                        'rgba(0, 0, 128, 1)',
                                    ],
                                    borderWidth: 1,
                                }]
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-12 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative">Timesheets
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Timesheets', columns: timesheetColumn, data: idx(data, _ => _.project.timesheet.overview) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={timesheetColumn}
                                data={_.slice((idx(data, _ => _.project.timesheet.overview) || []), 0, 5)}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-lg-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative">Project Purchase Order
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project Purchase Order', columns: projectPurchaseOrder, data: idx(data, _ => _.project.po.overview) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectPurchaseOrder}
                                data={_.slice((idx(data, _ => _.project.po.overview) || []), 0, 5)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative"> Project Work Order
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project Work Order', columns: projectWorkOrder, data: idx(data, _ => _.project.wo.overview) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectWorkOrder}
                                data={_.slice((idx(data, _ => _.project.wo.overview) || []), 0, 5)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <hr></hr>
            </div>
            <div className="col-12 chart-heading">
                <h2>FUTURE OPERATIONS</h2>
            </div>

            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Quotes</h3>
                    <div className="donut-chart">
                        <Pie
                            data={{
                                labels: ['Submitted Quotes', 'Not Submitted Quotes',],
                                datasets: [{
                                    label: 'Resource hours',
                                    data: [
                                        idx(data, _ => _.quotes.labor.submitted),
                                        idx(data, _ => _.quotes.labor.not_submitted),
                                    ].filter(item => item),
                                    backgroundColor: [
                                        'rgba(54, 162, 235, 0.4)',
                                        'rgba(225, 225, 225,0.6)',
                                    ],
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Resource Hours</h3>
                    <div className="donut-chart">
                        <Doughnut
                            data={{
                                labels: (idx(data, _ => _.labor) || []).filter(item => item.hrs).map(item => item.name),
                                datasets: [{
                                    label: 'Sum of Resource Hours',
                                    data: (idx(data, _ => _.labor) || []).filter(item => item.hrs).map(item => item.hrs),
                                    backgroundColor: (idx(data, _ => _.labor) || []).map(() => random_rgba()),
                                    hoverOffset: 4
                                }]
                            }}
                            plugins={[noDataPlugin]}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3>Active Projects</h3>
                    <div className="bar-chart">
                        <Line
                            data={{
                                labels: (idx(data, _ => _.project.date) || []).map(item => item.date),
                                datasets: [
                                    {
                                        label: 'Count',
                                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                                        borderColor: "rgba(75, 192, 192, 1)",
                                        fill: false,
                                        data: (idx(data, _ => _.project.date) || []).map(item => item.count)
                                    }
                                ],
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-6 mt-2 mb-1">
                <div className="card chart-block">
                    <h3 className="position-relative"> Project List
                        <span
                            className="tbl-expand-icon"
                            onClick={() => handleShow({ title: 'Project List', columns: projectList, data: idx(data, _ => _.project.overview) || [] })}
                        >
                            <ZoomOutMapIcon />
                        </span>
                    </h3>
                    <div className="p-2 h-100">
                        <div className="material-list-block h-100">
                            <DashboardTable
                                columns={projectList}
                                data={_.slice((idx(data, _ => _.project.overview) || []), 0, 5)}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <DashboardTableModal
                show={view.show}
                columns={view?.columns}
                data={view?.data}
                title={view?.title}
                handleClose={handleClose}
            />

        </div>
    );

}

export default OperationsTab
