import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router";
import { bindActionCreators } from "redux";
import _ from "lodash";
import idx from "idx";
import {
  PROJECT_MANAGEMENT,
} from "../../../../constants/route-paths";
import { warningSnackBar } from "../../../../actions/common.action";
import { getLaborCost } from "../../../../actions/dashboard.action";
import {
  getProject,
  getOptions,
  getProgressClaimList,
} from "../../../../actions/project-management.action";
import {
  getCategories,
  getItems,
  getLevel,
} from "../../../../actions/inventory.action";
import AddTask from "../../add-task";
import BudgetRetention from "./budget-retention";
import PreviewBudget from "./preview-budget";
import AllocateTimesheetTask from "../../../timesheet/allocate-timesheet-task";
import { numberFormat } from "../../../../util/currency-formatter";
import "./budget.scss";
import { withRouter } from "../../../../util/with-router";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import '../../../project-management/scss/task.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Collapse from 'react-bootstrap/Collapse';
import { stageCalculation } from "src/util/calculations";
import { taskCalculation } from "src/util/calculations/calculate-task";

class CreateBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTask: [],
      categories: [],
      items: [],
      projectId: "",
      optionData: [],
      taskDialogState: false,
      taskData: {},
      stageId: "",
      taskId: "",
      budgetRetentionDialog: false,
      projectCalculations: {},
      projectSummary: {
        contractedPrice: 0,
        reviedContractedPrice: 0,
        actualCost: 0,
      },
      projectQuoteType: "",
      laborCost: {},
      resourceAllocations: [],
      unAllocatedTimesheetTime: 0,
      allocateTimesheetTaskDialog: false,
      open: {}
    };
  }

  componentDidMount() {
    this.getProject();
    this.props.getCategories().then((categories) => {
      this.setState({ categories });
    });

    this.props.getItems().then((items) => {
      this.setState({ items });
    });
  }

  submitData = async () => {
    await this.getProject();
    this.setState({ allocateTimesheetTaskDialog: false });
  };

  getProject = async () => {
    const projectId = this.props.params.projectId;
    let projectQuoteType = this.state;
    let optionData = "", projectData = {}, selectedTask = [], sortedtempArray = [];
    if (projectId) {
      const project = await this.props.getProject(projectId);
      optionData = await this.props.getOptions(projectId);
      await this.getLaborCost(projectId);
      await this.getProgressClaimList(projectId);
      await this.getLevels(project.level);
      projectData = {
        projectId: project.id,
        projectName: project.name,
        projectDescription: project.description,
        customerName: project.customer && project.customer.businessName,
        hasStages: project.hasStages,
        projectType: project.projectType,
        customerLevel: project.customer ? project.customer.level : "",
        levelId: project.level,
        projectStages: project.stages,
      };
      projectQuoteType = project.quoteType;
      this.setState({ projectQuoteType });
      project.stages && Array.isArray(project.stages) && project.stages.map((stage) => {
        stage && Array.isArray(stage.tasks) && stage.tasks.map((task) => {
          task.allocatedMaterialCost =
            idx(task, (_task) => task.materialTotal_SaleAmount) || 0;
          task.allocatedLaborCost =
            idx(task, (_task) => task.laborTotal_SaleAmount) || 0;
          task.allocatedSubContractorCost =
            idx(task, (_task) => task.subcontractorTotal_SaleAmount) || 0;
          task.allocatedProfitMarginCost =
            idx(task, (_task) => task.profitMarginCost) || 0;
          selectedTask.push({ ...task, type: "TASK" });
          task &&
            Array.isArray(task.subTasks) &&
            task.subTasks.map((subTask) => {
              subTask.allocatedMaterialCost =
                idx(
                  subTask,
                  (_subTask) => subTask.materialTotal_SaleAmount
                ) || 0;
              subTask.allocatedLaborCost =
                idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) ||
                0;
              subTask.allocatedSubContractorCost =
                idx(
                  subTask,
                  (_subTask) => subTask.subcontractorTotal_SaleAmount
                ) || 0;
              subTask.allocatedProfitMarginCost =
                idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
              selectedTask.push({ ...subTask, type: "SUBTASK" });
            });
        });
      });
      let tempArray = [];
      optionData.forEach((option) => {
        option.revisionNumber =
          option.taskRevision && option.taskRevision[0].RevisionNumber;
        option.revisionId =
          option.taskRevision && option.taskRevision[0].RevisionId;
        option.revisionName =
          option.taskRevision && option.taskRevision[0].revisionName;
        option.subTasks = option.subTasks ? option.subTasks : [];
        tempArray.push(option);
      });
      sortedtempArray = _.chain(tempArray)
        .groupBy("revisionId")
        .map((revisions, revisionId) => {
          const sortedRevisions =
            _.sortBy(revisions, [(o) => new Date(o.createdAt || null)]) || [];
          return { revisionId, revisions: sortedRevisions };
        })
        .sortBy((o) => o.revisions[0].createdAt)
        .map((data) => data.revisions)
        .value()
        .flat(1);
      const uniqueIdArr = _.uniqBy(sortedtempArray, "revisionId");
      sortedtempArray && Array.isArray(sortedtempArray) && sortedtempArray.map((option, optionIndex) => {
        let index = _.findIndex(uniqueIdArr, function (item) { return item.id === option.id; });
        if (index !== -1) option.revision = true;

        option.variationNo = this.generateVariationNumber(optionIndex + 1, 3);
        if (option.proposal_status === "APPROVED" && option.proposal_selected === true) {
          option.calculatedProfitMargin = option.profitMarginCost;
          selectedTask.push({ ...option, type: "VARIATION" });
          option && Array.isArray(option.subTasks) && option.subTasks.map((subTask) => {
            subTask.allocatedMaterialCost = idx(subTask, (_subTask) => subTask.materialTotal_SaleAmount) || 0;
            subTask.allocatedLaborCost = idx(subTask, (_subTask) => subTask.laborTotal_SaleAmount) || 0;
            subTask.allocatedSubContractorCost = idx(subTask, (_subTask) => subTask.subcontractorTotal_SaleAmount) || 0;
            subTask.allocatedProfitMarginCost = idx(subTask, (_subTask) => subTask.profitMarginCost) || 0;
            subTask.remainingPercentage = 0;
            subTask.remainingAmount = 0;
            subTask.claimRemainingAmount = false;
            selectedTask.push({ ...subTask, type: "SUBTASK" });
          });
        }
      });
      this.setState({ selectedTask }, () =>
        this.projectCalculation(project.stages, optionData)
      );
    }

    this.setState({
      ...projectData,
      optionsData: sortedtempArray,
    });
  };

  getLaborCost = async (projectId) => {
    const laborData = await this.props.getLaborCost({ id: projectId });
    const laborCost = laborData.taskData || {};
    const unAllocatedTimesheetTime = idx(laborCost, (_) => _.null) || 0;
    this.setState({ laborCost, unAllocatedTimesheetTime });
  };

  getProgressClaimList = async (projectId) => {
    const progressClaims = await this.props.getProgressClaimList(projectId);

    this.setState({ progressClaims });
  };

  calculateActualBudget = (task = {}, laborCost) => {
    let actualProfitMarginCost = 0, profitMarginCost = 0;
    const actualMaterialCost =
      (Array.isArray(task.materialPoCost) && task.materialPoCost.reduce((acc, _material) => {
        let materialBudget = 0;
        if (idx(_material, _ => _.budget['PO'])) {
          materialBudget +=
            (_material.budget["PO"] || []).reduce((acc, poBudget) => acc + parseFloat(poBudget.amountPaid), 0) || 0;
        }
        if (idx(_material, _ => _.budget['WPO'])) {
          materialBudget +=
            (_material.budget["WPO"] || []).reduce((acc, poBudget) => acc + parseFloat(poBudget.amountPaid), 0) || 0;
        }
        return acc + materialBudget;
      }, 0).toFixed(2)) || 0;
    const actualLaborCost = idx(laborCost, (_laborCost) => _laborCost[task.id]) || 0;
    const actualSubContractorCost =
      (task.subContractorInvoiceCost || []).reduce((acc, _invoices) => acc + parseFloat(_invoices.amountPaid || 0), 0).toFixed(2) || 0;

    actualProfitMarginCost += parseFloat(task.profitMarginCost || 0);
    profitMarginCost += parseFloat(task.profitMarginCost || 0);

    return {
      actualMaterialCost,
      actualLaborCost,
      actualSubContractorCost,
      actualProfitMarginCost,
      profitMarginCost,
    };
  };

  projectCalculation = (stages, optionData) => {
    let { laborCost, selectedTask } = this.state;
    let projectSummary = {
      contractedPrice: 0,
      reviedContractedPrice: 0,
      actualCost: 0,
    };
    let calculations = stageCalculation({ stages: stages })

    stages && Array.isArray(stages) && stages.map((stage) => {
      stage && Array.isArray(stage.tasks) && stage.tasks.map((task) => {
        let calculatedPercentageComplete = 0;
        task.allocatedMaterialCost = calculations.task[task.id].material.sale;
        task.allocatedLaborCost = calculations.task[task.id].labor.sale;
        task.allocatedSubContractorCost = calculations.task[task.id].subcontractor.sale;
        task.allocatedProfitMarginCost = calculations.task[task.id].total_profit;
        const {
          actualMaterialCost,
          actualLaborCost,
          actualSubContractorCost,
          actualProfitMarginCost,
        } = this.calculateActualBudget(task, laborCost);
        task.actualMaterialCost = actualMaterialCost;
        task.actualLaborCost = actualLaborCost;
        task.actualSubContractorCost = actualSubContractorCost;
        task.actualProfitMarginCost = actualProfitMarginCost;
        task.calculatedPercentageComplete = task.percentageComplete;
        if (selectedTask.findIndex((t) => t.id === task.id) !== -1) {
          projectSummary.contractedPrice += task.taskHistory[0]
            ?
            (parseFloat(task.taskHistory[0].materialTotal_SaleAmount) || 0) +
            (parseFloat(task.taskHistory[0].laborTotal_SaleAmount) || 0) +
            (parseFloat(task.taskHistory[0].subcontractorTotal_SaleAmount) || 0)
            : 0;
          projectSummary.reviedContractedPrice +=
            parseFloat(calculations.task[task.id].material.sale) +
            parseFloat(calculations.task[task.id].labor.sale) +
            parseFloat(calculations.task[task.id].subcontractor.sale);
          projectSummary.actualCost +=
            (parseFloat(actualMaterialCost) || 0) +
            (parseFloat(actualLaborCost) || 0) +
            (parseFloat(actualSubContractorCost) || 0);
        }
        task && Array.isArray(task.subTasks) && task.subTasks.map((subTask) => {
          subTask.allocatedMaterialCost = calculations.subtask[subTask.id].material.sale;
          subTask.allocatedLaborCost = calculations.subtask[subTask.id].labor.sale;
          subTask.allocatedSubContractorCost = calculations.subtask[subTask.id].subcontractor.sale;
          subTask.allocatedProfitMarginCost = calculations.subtask[subTask.id].total_profit;
          const {
            actualMaterialCost,
            actualLaborCost,
            actualSubContractorCost,
            actualProfitMarginCost,
          } = this.calculateActualBudget(subTask, laborCost);
          subTask.actualMaterialCost = actualMaterialCost;
          subTask.actualLaborCost = actualLaborCost;
          subTask.actualSubContractorCost = actualSubContractorCost;
          subTask.actualProfitMarginCost = actualProfitMarginCost;
          subTask.calculatedPercentageComplete = subTask.percentageComplete;
          calculatedPercentageComplete += parseFloat(subTask.percentageComplete || 0);
        });
        if (task && Array.isArray(task.subTasks) && task.subTasks.length)
          task.calculatedPercentageComplete = calculatedPercentageComplete / task.subTasks.length;
      });
    });

    optionData && Array.isArray(optionData) && optionData.map((option, optionIndex) => {
      let calculation = taskCalculation({
        material: option.material,
        subtasks: option.subTasks,
        profitMargin: option.profitMargin,
        profitMarginType: option.profitMarginType
      })
      calculations.task[option.id] = calculation
      calculations.subtask = {
        ...calculations.subtask,
        ...calculation.subtask
      }
      let calculatedPercentageComplete = 0;
      let optionMaterials = [], optionLabor = [];
      option.material && Array.isArray(option.material) && option.material.map((item = {}, index) => {
        const materialData = (_.isString(item) && JSON.parse(item)) || item;
        optionMaterials.push(materialData);
      });
      option.labor && Array.isArray(option.labor) && option.labor.map((item = {}, index) => {
        const laborData = (_.isString(item) && JSON.parse(item)) || item;
        optionLabor.push(laborData);
      });
      option.material = optionMaterials;
      option.actualMaterialCost = 0;
      option.actualLaborCost = 0;
      option.actualSubContractorCost = 0;
      option.actualProfitMargin = calculation.total_profit;
      option.calculatedProfitMargin = calculation.total_profit;
      if (option.proposal_status === "APPROVED") {
        calculations.total += calculation.total;
        calculations.subtotal += calculation.subtotal;
      }
      if (option.proposal_status === "APPROVED" && selectedTask.findIndex((t) => t.id === option.id) !== -1) {
        projectSummary.reviedContractedPrice += (parseFloat(calculation.material.sale)) + (parseFloat(calculation.labor.sale)) + (parseFloat(calculation.subcontractor.sale));
      }

      option && Array.isArray(option.subTasks) && option.subTasks.map((subTask) => {
        subTask.allocatedMaterialCost = calculation.subtask[subTask.id].material.sale;
        subTask.allocatedLaborCost = calculation.subtask[subTask.id].labor.sale;
        subTask.allocatedSubContractorCost = calculation.subtask[subTask.id].subcontractor.sale;
        subTask.allocatedProfitMarginCost = calculation.subtask[subTask.id].total_profit;
        const {
          actualMaterialCost,
          actualLaborCost,
          actualSubContractorCost,
          actualProfitMarginCost,
        } = this.calculateActualBudget(subTask, laborCost);
        subTask.actualMaterialCost = actualMaterialCost;
        subTask.actualLaborCost = actualLaborCost;
        subTask.actualSubContractorCost = actualSubContractorCost;
        subTask.actualProfitMarginCost = actualProfitMarginCost;
        subTask.calculatedPercentageComplete = subTask.percentageComplete;
        calculatedPercentageComplete += parseFloat(subTask.percentageComplete || 0);
      });

      if (option && Array.isArray(option.subTasks) && option.subTasks.length)
        option.calculatedPercentageComplete = calculatedPercentageComplete / option.subTasks.length;
    });

    this.setState({ stages, optionData, projectSummary, calculations }, () =>
      this.handleClose()
    );
  };

  generateVariationNumber = (n, width, z) => {
    z = z || "0";
    n = n + "";
    return n.length >= width
      ? `V-${n}`
      : `V-${new Array(width - n.length + 1).join(z) + n}`;
  };

  handleCheckboxClick = (task, checked, type) => {
    let { selectedTask = [], projectStages, optionData } = this.state;
    if (checked) {
      selectedTask.push({ ...task, type });
    } else {
      const index = selectedTask.findIndex((t) => t.id === task.id);
      selectedTask.splice(index, 1);
    }
    this.setState({ selectedTask }, () =>
      this.projectCalculation(projectStages, optionData)
    );
  };

  handleEditClick = (
    task,
    type,
    stageId = "",
    taskId = "",
    subTaskId = "",
    optionId = ""
  ) => {
    const { projectId } = this.state;
    const isSubTask = type === "SUBTASK" ? true : false;
    const editSubTask = isSubTask;
    const editTask = !isSubTask && !optionId;
    const isOption = type === "VARIATION" ? true : false;
    const isVariation = type === "VARIATION" ? true : false;
    const editOption = isOption;
    this.setState({
      taskDialogState: true,
      taskData: { ...task, type },
      stageId,
      taskId,
      isSubTask,
      subTaskId,
      editSubTask,
      isOption,
      editOption,
      optionId,
      editTask,
      projectId: projectId,
      isVariation: isVariation,
    });
  };

  cancelHandler = () => {
    const { projectId } = this.state;
    this.setState({ selectedTask: [] });
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  };

  handleClose = () => {
    this.setState({
      taskDialogState: false,
      taskData: {},
      stageId: "",
      taskId: "",
      budgetRetentionDialog: false,
      subTaskId: "",
      editSubTask: false,
      editTask: false,
      optionId: "",
    });
  };

  getLevels = (levelId) => {
    this.props.getLevel(levelId).then((level) => {
      const labors = [];
      level.hourlyCost &&
        level.hourlyCost.forEach((cost) => {
          labors.push({
            ...cost,
            calloutCost: level.calloutCost,
            travelCost: level.travelCost,
          });
        });
      this.setState({ labors });
    });
  };

  handleBudgetClick = () => {
    const { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/budget/${projectId}`);
    this.getProject(projectId);
  };

  handleGeneratePC = () => {
    let { selectedTask = [], projectId } = this.state;
    if (!selectedTask.length) {
      this.props.warningSnackBar("Select a Task to Generate Invoice!");
      return;
    }
    this.props.history(`/${PROJECT_MANAGEMENT}/budget/${projectId}/create`);
  };

  ViewProjectList = () => {
    const { projectType } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      stages,
      hasStages = true,
      selectedTask,
      progressClaims,
      taskDialogState,
      projectId,
      levelId,
      optionData,
      budgetRetentionDialog,
      projectName,
      customerName,
      stageId,
      taskId,
      categories,
      items,
      isSubTask,
      subTaskId,
      labors,
      editSubTask,
      editTask,
      isOption,
      editOption,
      optionId,
      projectSummary,
      projectType,
      projectQuoteType,
      unAllocatedTimesheetTime,
      allocateTimesheetTaskDialog,
      open,
      calculations
    } = this.state;

    let currentTask, currentTaskCost;
    if (!isSubTask && !subTaskId && taskId) {
      currentTask = stages.filter((item) => item.id === stageId)
        .map((item) => item.tasks)[0]
        ?.filter((item) => item.id === taskId)[0] || null;
      if (currentTask) {
        currentTaskCost = {
          materialCost: currentTask.materialTotal_SaleAmount,
          laborCost: currentTask.laborTotal_SaleAmount,
          subContractorCost: currentTask.subcontractorTotal_SaleAmount,
          profitMarginCost: currentTask.profitMarginCost,
          total:
            currentTask.materialTotal_SaleAmount +
            currentTask.laborTotal_SaleAmount +
            currentTask.subcontractorTotal_SaleAmount,
        };
        currentTaskCost["noOfItems"] = 0;
        currentTaskCost["noOfResources"] = 0;
        for (let subTask of currentTask.subTasks) {
          currentTaskCost["noOfItems"] += subTask.material.length || 0;
          currentTaskCost["noOfResources"] += subTask.labor.length || 0;
        }
      }
    }

    let lessRetentions = [];
    progressClaims && Array.isArray(progressClaims) && progressClaims.map((claim) => {
      lessRetentions.push({
        progressClaimId: claim.id,
        progressClaimUniqueId: claim.u_id,
        total: claim.totalValue.toFixed(2),
        claimAmount: claim.claimAmount.toFixed(2),
        retentionAmount: claim.retentionAmount,
      });
    });

    return (
      <Routes>
        <Route
          path={`create`}
          element={
            <PreviewBudget
              selectedTask={selectedTask}
              projectId={projectId}
              progressClaims={progressClaims}
              stages={stages}
              hasStages={hasStages}
              optionData={optionData}
              handleBudgetClick={this.handleBudgetClick}
              handleClose={this.cancelHandler}
            />
          }
        />
        <Route
          path=""
          element={
            <>
              {taskDialogState && <AddTask
                key="AddTask"
                projectName={projectName}
                customerName={customerName}
                projectId={projectId}
                open={taskDialogState}
                dialogClose={this.handleClose}
                stageId={stageId}
                taskId={taskId}
                categories={categories}
                items={items}
                isSubTask={isSubTask}
                isOption={isOption}
                optionId={optionId}
                editOption={editOption}
                subTaskId={subTaskId}
                customerLevel={levelId}
                editTask={editTask}
                editSubTask={editSubTask}
                labors={labors}
                currentTask={currentTask}
                currentTaskCost={currentTaskCost}
                getStages={() => this.getProject()}
                getOptions={() => this.getProject()}
                userAccessControl={this.props.userAccessControl}
              />}
              {allocateTimesheetTaskDialog && (
                <AllocateTimesheetTask
                  key="AllocateTimesheetTask"
                  open={allocateTimesheetTaskDialog}
                  selectedTask={selectedTask}
                  projectId={projectId}
                  projectType={projectType}
                  submitData={this.submitData}
                  dialogClose={() =>
                    this.setState({ allocateTimesheetTaskDialog: false })
                  }
                />
              )}
              <BudgetRetention
                open={budgetRetentionDialog}
                progressClaims={progressClaims}
                projectId={projectId}
                key="BudgetRetention"
                getProgressClaimList={this.getProgressClaimList}
                dialogClose={this.handleClose}
                userAccessControl={this.props.userAccessControl}
              />

              <div className="purchase-order-block">
                <div className="row">
                  <div className="w-auto">
                    <h2 className="content-heading text-uppercase">Budget</h2>
                    <div className="col-12 breadcrumb-block p-0">
                      <Breadcrumb>
                        <Breadcrumb.Item onClick={this.ViewProjectList}>PROJECTS</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={this.ViewProject}>{projectName}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Budget</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </div>
                  {projectQuoteType == "quote" && (<div className="w-auto ms-auto mt-auto">
                    <button
                      className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary mb-3"
                      onClick={() => this.setState({ budgetRetentionDialog: true })}
                    >
                      Progress Claim Retention
                    </button>
                  </div>)}
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="material-list-block mt-4">
                      <div className="table-responsive">
                        <Table bordered className="table-create table-material table-material-ch budget-table">
                          <thead>
                            <tr>
                              <th>Task</th>
                              <th>Completion</th>
                              <th className="text-right">Sale Amount</th>
                              <th className="text-right">Forecast Cost</th>
                              <th className="text-right">Actual Cost</th>
                              <th className="text-right">Forecast Profit</th>
                              <th className="text-right">Actual Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stages && Array.isArray(stages) && stages.length ? (
                              <>
                                {stages.map((stage, index) => (
                                  <Fragment key={stage.id}>

                                    {hasStages && (
                                      <tr>
                                        <td>{stage.name || ""}</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td></td>
                                      </tr>
                                    )}

                                    {(stage.tasks && Array.isArray(stage.tasks) && stage.tasks.map((task, i) => {
                                      let taskTotal = calculations.task[task.id]
                                      let budget = (parseFloat(task.actualMaterialCost || 0) + parseFloat(task.actualLaborCost || 0) + parseFloat(task.actualSubContractorCost || 0)) || 0;

                                      return (
                                        <Fragment key={task.id}>
                                          <tr key={task.id}>
                                            <td>
                                              <p className="m-0 budget-task">
                                                {task.name || ""}
                                                <span className="task-edit-icon">
                                                  <EditIcon
                                                    onClick={() => this.handleEditClick(task, "TASK", stage.id, task.id)}
                                                  />
                                                </span>
                                                <span className="expand-icon">
                                                  <ExpandMoreIcon onClick={() => this.setState({ open: { [task.id]: !open[task.id] } })} aria-controls="example-collapse-text" aria-expanded={open[task.id]} />
                                                </span>
                                              </p>
                                            </td>
                                            <td>
                                              {task.calculatedPercentageComplete}%
                                            </td>
                                            <td className="text-right">
                                              {numberFormat(taskTotal.total)}
                                            </td>
                                            <td className="text-right">
                                              {numberFormat(taskTotal.subtotal)}
                                            </td>
                                            <td className="text-right">
                                              {numberFormat(budget)}
                                            </td>
                                            <td className="text-right">
                                              {numberFormat(taskTotal.total_profit)}
                                            </td>
                                            <td className="text-right">
                                              {numberFormat(taskTotal.total - budget)}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={8} className="p-0 border-0">
                                              <Collapse in={open[task.id]}>
                                                <div id="example-collapse-text">
                                                  <Table bordered className="table-create table-material table-material-ch">
                                                    <thead>
                                                      <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th className="text-right"></th>
                                                        <th className="text-right"></th>
                                                        <th className="text-right"></th>
                                                        <th className="text-right"></th>
                                                        <th className="text-right"></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className="p-0"></td>
                                                        <td className="collapse-title">Material</td>
                                                        <td className="text-right">{numberFormat(taskTotal.material.sale)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.material.cost || 0)}</td>
                                                        <td className="text-right">{numberFormat(task.actualMaterialCost || 0)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.material.profit || 0)}</td>
                                                        <td className={`${(taskTotal.material.sale) - (task.actualMaterialCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                          {((taskTotal.material.sale) - (task.actualMaterialCost || 0)) > 0 ?
                                                            numberFormat((taskTotal.material.sale) - (task.actualMaterialCost || 0))
                                                            : numberFormat(((taskTotal.material.sale) - (task.actualMaterialCost || 0)) * -1)}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="p-0"></td>
                                                        <td className="collapse-title">Resource</td>
                                                        <td className="text-right">{numberFormat(taskTotal.labor.sale)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.labor.cost || 0)}</td>
                                                        <td className="text-right">{numberFormat(task.actualLaborCost || 0)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.labor.profit || 0)}</td>
                                                        <td className={`${(taskTotal.labor.sale) - (task.actualLaborCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                          {((taskTotal.labor.sale) - (task.actualLaborCost || 0)) > 0 ?
                                                            numberFormat((taskTotal.labor.sale) - (task.actualLaborCost || 0))
                                                            : numberFormat(((taskTotal.labor.sale) - (task.actualLaborCost || 0)) * -1)}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="p-0"></td>
                                                        <td className="collapse-title">Contractor</td>
                                                        <td className="text-right">{numberFormat(taskTotal.subcontractor.sale)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.subcontractor.cost || 0)}</td>
                                                        <td className="text-right">{numberFormat(task.actualSubContractorCost || 0)}</td>
                                                        <td className="text-right">{numberFormat(taskTotal.subcontractor.profit || 0)}</td>
                                                        <td className={`${(taskTotal.subcontractor.sale) - (task.actualSubContractorCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                          {((taskTotal.subcontractor.sale) - (task.actualSubContractorCost || 0)) > 0 ?
                                                            numberFormat((taskTotal.subcontractor.sale) - (task.actualSubContractorCost || 0))
                                                            : numberFormat(((taskTotal.subcontractor.sale) - (task.actualSubContractorCost || 0)) * -1)}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </Collapse>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })) || []}

                                    {(optionData && Array.isArray(optionData) && optionData.length && (
                                      <>
                                        <tr>
                                          <td colSpan={7}><b>Variations</b></td>
                                        </tr>
                                        {optionData.filter((o) => o.proposal_status === "APPROVED" && o.proposal_selected === true).map((option, i) => {
                                          let taskTotal = calculations.task[option.id]
                                          let budget = (parseFloat(option.actualMaterialCost || 0) + parseFloat(option.actualLaborCost || 0) + parseFloat(option.actualSubContractorCost || 0)) || 0;

                                          return (
                                            <Fragment key={i}>
                                              <tr key={option.id}>
                                                <td>
                                                  <p className="m-0 budget-task">
                                                    {`${option.variationNo} ${option.name}`}
                                                    {option.revisionNumber !== 1 ? " - " + option.revisionName : ""}
                                                    <span className="task-edit-icon">
                                                      <EditIcon
                                                        onClick={() => this.handleEditClick(option, "VARIATION", "", "", "", option.id)}
                                                      />
                                                    </span>
                                                    <span className="expand-icon">
                                                      <ExpandMoreIcon onClick={() => this.setState({ open: { [option.id]: !open[option.id] } })} aria-controls="example-collapse-text" aria-expanded={open[option.id]} />
                                                    </span>
                                                  </p>
                                                </td>
                                                <td>
                                                  {option.percentageComplete}%
                                                </td>
                                                <td className="text-right">
                                                  {numberFormat(taskTotal.total)}
                                                </td>
                                                <td className="text-right">
                                                  {numberFormat(taskTotal.subtotal)}
                                                </td>
                                                <td className="text-right">
                                                  {numberFormat(budget)}
                                                </td>
                                                <td className="text-right">
                                                  {numberFormat(taskTotal.total_profit)}
                                                </td>
                                                <td className="text-right">
                                                  {numberFormat(taskTotal.total - budget)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colSpan={8} className="p-0 border-0">
                                                  <Collapse in={open[option.id]}>
                                                    <div id="example-collapse-text">
                                                      <Table bordered className="table-create table-material table-material-ch">
                                                        <thead>
                                                          <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th className="text-right"></th>
                                                            <th className="text-right"></th>
                                                            <th className="text-right"></th>
                                                            <th className="text-right"></th>
                                                            <th className="text-right"></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="p-0"></td>
                                                            <td className="collapse-title">Material</td>
                                                            <td className="text-right">{numberFormat(taskTotal.material.sale)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.material.cost || 0)}</td>
                                                            <td className="text-right">{numberFormat(option.actualMaterialCost || 0)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.material.profit || 0)}</td>
                                                            <td className={`${(taskTotal.material.sale) - (option.actualMaterialCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                              {((taskTotal.material.sale) - (option.actualMaterialCost || 0)) > 0 ?
                                                                numberFormat((taskTotal.material.sale) - (option.actualMaterialCost || 0))
                                                                : numberFormat(((taskTotal.material.sale) - (option.actualMaterialCost || 0)) * -1)}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="p-0"></td>
                                                            <td className="collapse-title">Resource</td>
                                                            <td className="text-right">{numberFormat(taskTotal.labor.sale)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.labor.cost || 0)}</td>
                                                            <td className="text-right">{numberFormat(option.actualLaborCost || 0)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.labor.profit || 0)}</td>
                                                            <td className={`${(taskTotal.labor.sale) - (option.actualLaborCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                              {((taskTotal.labor.sale) - (option.actualLaborCost || 0)) > 0 ?
                                                                numberFormat((taskTotal.labor.sale) - (option.actualLaborCost || 0))
                                                                : numberFormat(((taskTotal.labor.sale) - (option.actualLaborCost || 0)) * -1)}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="p-0"></td>
                                                            <td className="collapse-title">Contractor</td>
                                                            <td className="text-right">{numberFormat(taskTotal.subcontractor.sale)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.subcontractor.cost || 0)}</td>
                                                            <td className="text-right">{numberFormat(option.actualSubContractorCost || 0)}</td>
                                                            <td className="text-right">{numberFormat(taskTotal.subcontractor.profit || 0)}</td>
                                                            <td className={`${(taskTotal.subcontractor.sale) - (option.actualSubContractorCost || 0) >= 0 ? "budget-under" : "budget-exceed"} text-right`}>
                                                              {((taskTotal.subcontractor.sale) - (option.actualSubContractorCost || 0)) > 0 ?
                                                                numberFormat((taskTotal.subcontractor.sale) - (option.actualSubContractorCost || 0))
                                                                : numberFormat(((taskTotal.subcontractor.sale) - (option.actualSubContractorCost || 0)) * -1)}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </Table>
                                                    </div>
                                                  </Collapse>
                                                </td>
                                              </tr>
                                            </Fragment>
                                          );
                                        })}
                                      </>
                                    )) || ""}

                                    <tr className="budget-total">
                                      <td></td>
                                      <td><b>Total</b></td>
                                      <td className="text-right">
                                        {numberFormat(calculations.total)}
                                      </td>
                                      <td className="text-right">
                                        {numberFormat(calculations.subtotal)}
                                      </td>
                                      <td className="text-right">
                                        {numberFormat(projectSummary.actualCost)}
                                      </td>
                                      <td className="text-right">
                                        {numberFormat(calculations.total_profit)}
                                      </td>
                                      <td className="text-right">
                                        {numberFormat(calculations.total - projectSummary.actualCost)}
                                      </td>
                                    </tr>

                                  </Fragment>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={7} className="text-center">
                                  {"No Data to Show"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row mt-2 mb-4">
                  <div className="col-12">
                    <div className="material-list-block mt-3">
                      <h2>Progress Claim Retentions</h2>
                      <div className="table-responsive">
                        <Table bordered className="table-create table-material table-material-ch">
                          <thead>
                            <tr>
                              <th className="w-auto">Progress Claim Id</th>
                              <th className="text-right">Total Amount</th>
                              <th className="text-right">Progress Claim Amount	</th>
                              {projectQuoteType == "quote" && (<th className="text-right">Retention Amount</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            {lessRetentions && Array.isArray(lessRetentions) && lessRetentions.length ? (
                              lessRetentions.map((retention, index) => (
                                <tr key={index}>
                                  <td>{retention.progressClaimId || ""}</td>
                                  <td className="text-right">{numberFormat(retention.total)}</td>
                                  <td className="text-right">{numberFormat(retention.claimAmount)}</td>
                                  {projectQuoteType == "quote" && (
                                    <td className="text-right">{numberFormat(retention.retentionAmount)}</td>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={projectQuoteType == "quote" ? 4 : 3}>
                                  {"No Data to Show"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>

                    </div>
                  </div>
                </div>
                {this.props.entitlements.scheduleMenu && unAllocatedTimesheetTime ? (
                  <div className="row mt-2 table-responsive">
                    <div className="col-4">
                      Unallocated Resource
                    </div>
                    <div className="col-4">
                      {numberFormat(unAllocatedTimesheetTime)}
                    </div>
                    <div className="col-4">
                      <button
                        className="secondarybtn secondarybtn-outline btn-ch btn btn-primary"
                        onClick={() => this.setState({ allocateTimesheetTaskDialog: true, })}
                      >
                        Allocate
                      </button>
                    </div>
                  </div>
                ) : ""}
                <div className="col-12 total-table mt-3 table-responsive">
                  <table className="table-create mb-0 tbl-fixed tbl-fixed-width table table-bordered">
                    <thead>
                      <tr>
                        <th>Original Contract</th>
                        <th>Revised Contract Amount</th>
                        <th>Actual Cost To Date</th>
                        <th>Profit/Loss</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="tableinfo">{numberFormat(projectSummary.contractedPrice || 0)}</span>
                        </td>
                        <td>
                          <span className="tableinfo">{numberFormat(projectSummary.reviedContractedPrice || 0)}</span>
                        </td>
                        <td>
                          <span className="tableinfo">
                            {numberFormat((projectSummary.actualCost || 0) + (unAllocatedTimesheetTime || 0))}
                          </span>
                        </td>
                        <td>
                          <span className="tableinfo">
                            {numberFormat(projectSummary.reviedContractedPrice - ((projectSummary.actualCost || 0) + (unAllocatedTimesheetTime || 0)))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 d-flex justify-content-end gap-3 mt-5">
                  {this.props.wFinancePermission && (<Button
                    onClick={this.handleGeneratePC}
                    className="secondarybtn"
                  >
                    Generate Invoice
                  </Button>)}
                  <Button onClick={this.cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
                </div>
              </div>

            </>
          }
        />
      </Routes>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  entitlements: state.user.entitlements
});

const mapDispatchToProps = (dispatch) => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getOptions: bindActionCreators(getOptions, dispatch),
  getProgressClaimList: bindActionCreators(getProgressClaimList, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  getItems: bindActionCreators(getItems, dispatch),
  getLevel: bindActionCreators(getLevel, dispatch),
  getLaborCost: bindActionCreators(getLaborCost, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateBudget)
);
