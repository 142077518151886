export const getProjectType = ({ type, status, projectQuoteType }) => {

    let isCurrent = type == 'PROJECT' && (projectQuoteType == 'noquote' || (projectQuoteType == 'quote' && status == 'IN_PROGRESS')) && getActiveProjects(status);
    let isQuote = (type == 'TENDER' || (projectQuoteType == 'quote' && status != 'IN_PROGRESS')) && getActiveProjects(status);
    let isDLP = status == "DLP";
    let isCompleted = status == "COMPLETED";
    let isArchived = ["LOST", "CANCELLED", "ARCHIVED"].includes(status);

    return { isCurrent, isQuote, isDLP, isCompleted, isArchived }
}

export const getActiveProjects = (status) => {
    return !(["DLP", "COMPLETED", "ARCHIVED", "CANCELLED", "LOST"].includes(status))
}