import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();
    let [searchParams] = useSearchParams();

    return (
      <Component
        history={navigate}
        location={location}
        params={params}
        match={location}
        searchParams={searchParams}
        {...props}
      />
    );
  };

  return Wrapper;
};
