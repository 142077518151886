import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************/project-type/:id
const GLOBAL_SETTING = '/global-setting'
const EDIT_GLOBAL_SETTING = '/global-setting/edit'
const DELETE_GLOBAL_SETTING = '/global-setting'
const EDIT_PROJECT_TYPE_SETTING = '/global-setting/edit/project-type'
const UPDATE_PROJECT_TYPE = '/project/project-type'

// // ************API call Functions starts here **************

export const getGlobalSetting = async (id) => {
    return await axios('get', `${GLOBAL_SETTING}/${id}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const editGlobalSetting = async (data, id) => {
    return await axios('put', `${EDIT_GLOBAL_SETTING}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Global Setting Updated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const deleteGlobalSetting = async (data, id) => {
    return await axios('delete', `${DELETE_GLOBAL_SETTING}/${id}/${data}`, {}, { loader: loaders.OVERLAY, successMessage: 'Global Setting Deleted Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const editProjectTypeSetting = async (data, id) => {
    return await axios('put', `${EDIT_PROJECT_TYPE_SETTING}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Global Settings Updated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
    error => { // request level error
        return new Promise((resolve, reject) => {
            reject(error)
        })
    })
}

export const UpdateProjectType = async (data, id) => {
    return await axios('put', `${UPDATE_PROJECT_TYPE}/${id}`, data, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
    error => { // request level error
        return new Promise((resolve, reject) => {
            reject(error)
        })
    })
}
