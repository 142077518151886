import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Modal from '../../components/modal/modal'
import Modal from 'react-bootstrap/Modal';
import { Grid } from '@material-ui/core'
import Input from '../../components/inputs/input-simple'
import { getProjectList, getProject } from '../../actions/project-management.action'
import { getServicesList, getService } from '../../actions/service.action'
import { getResourceAllocations, getUserLeaves } from '../../actions/timesheet.action'
import { warningSnackBar } from '../../actions/common.action'
import { getGlobalSettingReducer } from '../../actions/global-setting.action'
import { getUserList } from '../../actions/user-management.action'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import idx from 'idx'
import moment from 'moment'
import './scss/timesheet.scss'
import SelectInput from 'src/components/inputs/select'
import Button from 'react-bootstrap/Button';

export class AllocateResource extends Component {

  constructor(props) {
    super(props)
    this.state = {
      projectType: '',
      disableFields: false,
      fields: {
        allocationType: {
          name: 'allocationType',
          type: 'radio',
          value: 'WORK',
          options: [
            { text: 'Work Allocation', value: 'WORK' },
            { text: 'Leave Allocation', value: 'LEAVE' }
          ]
        },
        projectType: {
          name: 'projectType',
          type: 'select',
          items: []
        },
        user: {
          name: 'user',
          type: 'select',
          value: ''
        },
        projectId: {
          name: 'projectId',
          type: 'select',
          items: []
        },
        task: {
          name: 'task',
          type: 'select',
          items: []
        },
        startDate: {
          name: 'startDate',
          type: 'datepicker',
          value: ''
        },
        endDate: {
          name: 'endDate',
          type: 'datepicker',
          value: ''
        },
        startTime: {
          name: 'startTime',
          type: 'timepicker',
          format: 'LT',
          value: ''
        },
        endTime: {
          name: 'endTime',
          type: 'timepicker',
          format: 'LT',
          value: ''
        },
        allDayWithBreaks: {
          name: 'allDayWithBreaks',
          type: 'checkbox',
          value: true,
          label: 'All Day (including breaks)'
        }
      },
      leaveFields: {
        date: {
          type: 'datepicker',
          value: ''
        },
        startTime: {
          type: 'timepicker',
          format: 'LT'
        },
        endTime: {
          type: 'timepicker',
          format: 'LT'
        },
        startDate: {
          type: 'datepicker',
          value: ''
        },
        endDate: {
          type: 'datepicker',
          value: ''
        },
        type: {
          type: 'select',
          items: [
            { key: 'Short Term leave', value: 'SHORT_TERM' },
            { key: 'Long Term leave', value: 'LONG_TERM' }
          ],
          value: 'SHORT_TERM'
        }
      },
      allocation: {
        status: '',
        updated_date: '',
        updated_by: '',
        updated_by_name: ''
      },
      usersList: []
    }
  }

  componentDidMount = async () => {
    const { allocationId, usersList = [], projectCategories } = this.props

    let fields = _.cloneDeep(this.state.fields)

    let user = this.props.user;
    let permissions = user && user.permissions;
    projectCategories.forEach(item => {
      if (permissions[`projectManagement${item.type}`] > 0 || (user.global_user && user.selected_account.id)) {
        fields.projectType.items.push({
          key: item.name,
          value: item.type
        })
      }
    });


    if (usersList.length === 0) {
      let users = await this.getUsersList()
      this.setState({ usersList: users })
    } else {
      this.setState({ usersList })
    }
    let globalSetting = this.props.globalSetting

    if (!Object.keys(globalSetting.finance_tax).length) {
      await this.props.getGlobalSettingReducer('FINANCE_TAX')
      globalSetting = this.props.globalSetting
    }
    if (!allocationId) {
      let { allocationDate, userId } = this.props
      // const fields = _.cloneDeep(this.state.fields)
      const leaveFields = _.cloneDeep(this.state.leaveFields)
      allocationDate = allocationDate || new Date()

      let globalStartTime = idx(globalSetting, _ => _.finance_tax.start_time) || ''
      let globalFinishTime = idx(globalSetting, _ => _.finance_tax.finish_time) || ''

      fields.startTime.value = globalStartTime
      fields.endTime.value = globalFinishTime
      fields.startDate.value = allocationDate
      fields.endDate.value = allocationDate
      fields.user.value = userId

      leaveFields.date.value = allocationDate
      leaveFields.startDate.value = allocationDate
      leaveFields.endDate.value = allocationDate

      this.setState({ leaveFields })
    }
    if (this.props.taskId) {
      this.handleProjectType(this.props.project.projectType)
      this.handleProjectChange(this.props.projectId)
    }
    this.setState({ fields }, () => {
      if (allocationId) { this.modifyTimeSheet(allocationId) }
    })

  }

  modifyTimeSheet = async (id) => {
    const { allocationType, userId, editTimesheet } = this.props
    const fields = _.cloneDeep(this.state.fields)
    const leaveFields = _.cloneDeep(this.state.leaveFields)
    if (allocationType === 'LEAVE') {
      const leave = await this.props.getUserLeaves({ userId, allocationId: id })
      let globalSetting = this.props.globalSetting
      let globalStartTime = idx(globalSetting, _ => _.finance_tax.start_time) || ''
      let globalFinishTime = idx(globalSetting, _ => _.finance_tax.finish_time) || ''

      fields.allocationType.value = allocationType
      fields.user.value = idx(leave, _ => _[0].userId)
      leaveFields.type.value = idx(leave, _ => _[0].type)
      leaveFields.date.value = idx(leave, _ => _[0].startTime)
      leaveFields.startTime.value = idx(leave, _ => _[0].startTime)
      leaveFields.endTime.value = idx(leave, _ => _[0].endTime)
      leaveFields.startDate.value = idx(leave, _ => _[0].startTime)
      leaveFields.endDate.value = idx(leave, _ => _[0].endTime)

      fields.startTime.value = globalStartTime
      fields.endTime.value = globalFinishTime
      fields.startDate.value = idx(leave, _ => _[0].startTime)
      fields.endDate.value = idx(leave, _ => _[0].endTime)

      this.setState({ leaveFields, editTimesheet, fields })
    } else if (allocationType === 'WORK') {
      const allocation = await this.props.getResourceAllocations({ allocationId: id })
      fields.allocationType.value = allocationType
      fields.user.value = idx(allocation, _ => _[0].userId)
      const projectType = idx(allocation, _ => _[0].projectType)
      fields.projectType.value = idx(allocation, _ => _[0].projectType)
      fields.task.value = idx(allocation, _ => _[0].taskId)
      fields.projectId.value = idx(allocation, _ => _[0].projectId)
      fields.startDate.value = idx(allocation, _ => _[0].startTime)
      fields.endDate.value = idx(allocation, _ => _[0].endTime)
      fields.startTime.value = idx(allocation, _ => _[0].startTime)
      fields.endTime.value = idx(allocation, _ => _[0].endTime)
      fields.endTime.value = idx(allocation, _ => _[0].endTime)
      fields.allDayWithBreaks.value = idx(allocation, _ => _[0].allDayWithBreaks)
      leaveFields.date.value = idx(allocation, _ => _[0].startTime)
      leaveFields.startDate.value = idx(allocation, _ => _[0].startTime)
      leaveFields.endDate.value = idx(allocation, _ => _[0].endTime)
      const status = idx(allocation, _ => _[0].status) || ''
      const updated_date = idx(allocation, _ => _[0].updated_date) || ''
      const updated_by = idx(allocation, _ => _[0].updated_by) || ''
      const updated_by_name = this.getUserName(updated_by)

      const disableFields = editTimesheet && status !== 'PENDING'

      this.setState({ leaveFields, fields, disableFields, editTimesheet, projectType, allocation: { status, updated_date, updated_by, updated_by_name } }, () => {
        if (idx(allocation, _ => _[0].projectType))
          this.handleProjectType(idx(allocation, _ => _[0].projectType), true)
        if (idx(allocation, _ => _[0].projectId))
          this.handleProjectChange(idx(allocation, _ => _[0].projectId), true)
      })
    }
  }

  componentWillUnmount = () => {
    const fields = _.cloneDeep(this.state.fields)

    fields.projectType.value = ''
    fields.task.value = ''
    fields.projectId.value = ''
    fields.startTime.value = ''
    fields.endTime.value = ''
    const status = ''
    const editTimesheet = false
    const disableFields = false
    const projectType = ''

    this.setState({ fields, status, editTimesheet, disableFields, projectType })
  }

  handleProjectType = async (value, skip = false) => {
    const { projectCategories } = this.props

    let list
    if (projectCategories.find(o => o.type === value)) {
      list = await this.props.getProjectList(null, 'PROJECT', null, value)
    }

    list = (Array.isArray(list) && list.map(project => ({ key: project.name, value: project.id }))) || []
    list = _.orderBy(list, [({ key }) => key.toLowerCase()], ['asc'])

    const fields = _.cloneDeep(this.state.fields)

    fields.projectType.value = value
    fields.projectId.items = _.cloneDeep(list)
    if (!skip) {
      fields.projectId.value = ''
      fields.task.value = ''
    }
    this.setState({ fields, projectType: value })

  }

  handleProjectChange = async (value, skip = false) => {

    const { projectType } = this.state

    let data

    data = await this.props.getProject(value)


    let tasks
    if (projectType === 'SERVICE') {
      tasks = await this.getTasks(data.tasks)
    } else {
      tasks = await this.getStages(data.stages, data.hasStages)
    }

    const variations = this.getOptions(data.options)
    tasks = [...tasks, ...variations]

    const fields = _.cloneDeep(this.state.fields)

    fields.task.items = _.cloneDeep(tasks)
    fields.projectId.value = value
    if (!skip) fields.task.value = ''
    if (this.props.taskId && fields.task.value == '') {
      fields.task.value = this.props.taskId
    }
    this.setState({ fields })

  }

  getStages = async (stages, hasStages) => {

    let tasksList = []
    Array.isArray(stages) && stages.forEach(({ tasks, id, name, description }) => {
      hasStages && tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'STAGE', subHeader: true })
      Array.isArray(tasks) && tasks.forEach(({ id, name, subTasks, description, material }) => {
        tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'TASK' })
        /* Array.isArray(subTasks) && subTasks.forEach(({ id, name, description, material }) => {
          tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'SUBTASK' })
        }) */
      })
    })
    return tasksList
  }

  getTasks = async (tasks) => {
    const tasksList = []
    Array.isArray(tasks) && tasks.forEach(({ id, name, subTasks, description }) => {
      tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'TASK' })
      /* Array.isArray(subTasks) && subTasks.forEach(({ id, name, description }) => {
        tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'SUBTASK' })
      }) */
    })
    return tasksList
  }

  getOptions = (options) => {
    const variations = []
    Array.isArray(options) && options.map(({ id, name, proposal_status }) => {
      if (proposal_status === 'APPROVED') {
        variations.push({ key: name, value: id, taskType: 'VARIATION' })
      }
    })

    if (variations.length) {
      variations.unshift({ key: 'Variations', subHeader: true })
    }

    return variations
  }

  inputChangeHandler = (value, type, isLeaveFields = false) => {
    const fields = _.cloneDeep(this.state.fields)
    const leaveFields = _.cloneDeep(this.state.leaveFields)

    if (isLeaveFields) {
      leaveFields[type].value = value
    } else {
      fields[type].value = value
    }
    this.setState({ leaveFields, fields })
  }

  allocateWeeklyHandler = () => {
    const fields = _.cloneDeep(this.state.fields)
    if (!fields.startDate.value) {
      this.props.warningSnackBar('Please Select Start Date')
      return
    }
    const { globalSetting } = this.props
    const finance_data = idx(globalSetting, _ => _.finance_tax) || {}
    let startDate = new Date(fields.startDate.value)
    let no_of_days_remaining_in_week = 6 - startDate.getUTCDay()
    if (!idx(finance_data, _ => _.sunday_as_new_week)) no_of_days_remaining_in_week += 1
    fields.endDate.value = startDate.setDate(startDate.getDate() + no_of_days_remaining_in_week)
    this.setState({ fields })
  }

  submitData = () => {

    const fields = _.cloneDeep(this.state.fields)

    if (fields.allocationType.value === 'LEAVE') {
      this.applyLeave()
      return
    }

    if (!fields.projectType.value) {
      this.props.warningSnackBar('Please Select Project Type')
      return
    }

    if (!fields.projectId.value) {
      this.props.warningSnackBar('Please Select Project')
      return
    }
    if (!fields.startTime.value || !fields.endTime.value) {
      this.props.warningSnackBar('Please Enter Start and End time')
      return
    }

    const startDate = moment(fields.startDate.value)
    const endDate = moment(fields.endDate.value)
    if (startDate.isAfter(endDate)) {
      this.props.warningSnackBar('End Date can not be before the Start Date')
      return
    }
    const start = moment(fields.startTime.value)
    const end = moment(fields.endTime.value)

    if (start.format('hh:mm a') === end.format('hh:mm a')) {
      this.props.warningSnackBar('Start time and Finish time are the same. Please adjust.')
      return
    }

    if (start.isSameOrAfter(end)) {
      this.props.warningSnackBar('End time can not before the start time')
      return
    }

    const selectedTask = Array.isArray(fields.task.items) && fields.task.items.find(task => task.value === fields.task.value)
    const data = {
      userId: fields.user.value,
      projectType: fields.projectType.value,
      projectId: fields.projectId.value,
      taskId: idx(selectedTask, _ => _.value) || '',
      taskType: idx(selectedTask, _ => _.taskType) || '',
      taskName: idx(selectedTask, _ => _.key) || '',
      startDate: fields.startDate.value,
      endDate: fields.endDate.value,
      startTime: fields.startTime.value,
      endTime: fields.endTime.value,
      allDayWithBreaks: fields.allDayWithBreaks.value
    }

    this.props.allocateResource(data)
  }

  handleMenu = (event, rowData) => {
    this.setState({ anchorEl: event.currentTarget, userId: rowData.id });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, vendorData: {} })
  }

  applyLeave = () => {
    const { leaveFields, fields } = this.state
    const data = {}

    if (leaveFields.type.value === 'SHORT_TERM') {
      if (!leaveFields.date.value) {
        this.props.warningSnackBar('Please Enter a Date')
        return
      }
      if (!leaveFields.startTime.value || !leaveFields.endTime.value) {
        this.props.warningSnackBar('Please Enter Start Time and End Time')
        return
      }

      let start = moment(leaveFields.startTime.value)
      let end = moment(leaveFields.endTime.value)

      if (start.isSameOrAfter(end)) {
        this.props.warningSnackBar('End Time can not before the Start Time')
        return
      }

      start = new Date(leaveFields.startTime.value)
      end = new Date(leaveFields.endTime.value)

      let startTime = new Date(leaveFields.date.value)
      startTime.setHours(start.getHours(), start.getMinutes(), 0, 0)

      let endTime = new Date(leaveFields.date.value)
      endTime.setHours(end.getHours(), end.getMinutes(), 0, 0)

      data.startTime = moment.utc(startTime).format('YYYY-MM-DDTHH:mmZ').toString()
      data.endTime = moment.utc(endTime).format('YYYY-MM-DDTHH:mmZ').toString()
    } else if (leaveFields.type.value === 'LONG_TERM') {
      if (!leaveFields.startDate.value || !leaveFields.endDate.value) {
        this.props.warningSnackBar('Please Enter Start Date and end Date')
        return
      }

      let start = new Date(leaveFields.startDate.value)
      let end = new Date(leaveFields.endDate.value)
      const globalSetting = this.props.globalSetting
      let globalStartTime = idx(globalSetting, _ => _.finance_tax.start_time) || ''
      let globalFinishTime = idx(globalSetting, _ => _.finance_tax.finish_time) || ''
      if (globalStartTime) globalStartTime = new Date(globalStartTime)
      start.setHours(globalStartTime ? globalStartTime.getHours() : 7, globalStartTime ? globalStartTime.getMinutes() : 0, 0, 0)
      if (globalFinishTime) globalFinishTime = new Date(globalFinishTime)
      end.setHours(globalFinishTime ? globalFinishTime.getHours() : 3, globalFinishTime ? globalFinishTime.getMinutes() : 30, 0, 0)
      if (end.getTime() < start.getTime()) {
        this.props.warningSnackBar('Start Date can not be greater than the End Date')
        return
      }

      data.startTime = moment.utc(start).format('YYYY-MM-DDTHH:mmZ').toString()
      data.endTime = moment.utc(end).format('YYYY-MM-DDTHH:mmZ').toString()
    }
    data.userId = fields.user.value
    data.type = leaveFields.type.value
    this.props.applyLeave(data)

  }

  getUserName = (updated_by) => {
    const { usersList } = this.state
    if (!updated_by || !usersList) return ''
    const user = usersList.find(user => user.value === updated_by)
    return user?.key || ''
  }

  getUsersList = async () => {
    const list = await this.props.getUserList()
    return (Array.isArray(list) && list.map(({ id, firstName, lastName }) => ({ key: (firstName + ' ' + lastName), value: id }))) || []
  }

  render() {
    const { open, dialogClose, allocationId, allocationType, moduleName } = this.props
    const { fields, disableFields, editTimesheet, leaveFields, allocation, usersList } = this.state
    const title = (editTimesheet || allocationId) ? 'Edit Timesheet' : 'Allocate Resource'
    const save_title = (editTimesheet || allocationId) ? 'Save' : 'Allocate Resource'
    const { permissions } = this.props.user
    const writePermission = permissions && permissions.timesheetManagement === 2 ? true : false

    return (
      <Modal
        show={open}
        onHide={dialogClose}
        dialogClassName="modal-90w create-modal"
        aria-labelledby="example-custom-modal-styling-title"
      // fullWidth
      // maxWidth='md'
      // modalHeading={title}
      // modalSaveTxt={save_title}
      // modalCloseTxt='Cancel'
      // onClick={this.submitData}
      // showActionBtn={!disableFields && writePermission}
      // savebtnClass='createBtn'
      // additionalTxt={(allocationId && allocationType !== fields.allocationType.value) ? `You are about to change the type of the Schedule Allocation. This will remove the original Allocation.` : ''}
      // primary
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt-4 mb-2'>
            {allocation.status && <div style={{ float: 'left' }}>
              <Grid container style={{ marginBottom: "10px" }}>
                <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}>Status </p></Grid>
                <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}><b>{allocation.status}</b></p></Grid>
                {allocation.status !== 'PENDING' && allocation.updated_date && <>
                  <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}>Updated At </p></Grid>
                  <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}><b>{`${moment(allocation.updated_date).format('DD-MM-YYYY')} ${moment(allocation.updated_date).format('LT')}`}</b></p></Grid>
                  {allocation.updated_by && <>
                    <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}>Updated By </p></Grid>
                    <Grid sm={6} xs={6} item><p style={{ margin: "8px 0" }}><b>{allocation.updated_by_name ? allocation.updated_by_name : `Inactive User (${allocation.updated_by})`}</b></p></Grid>
                  </>}
                </>}
              </Grid>
            </div>}
            {(moduleName !== 'TaskDetails') && <div style={{ float: 'right' }}>
              <Input
                {...fields.allocationType}
                onChange={(e) => this.inputChangeHandler(e.target.value, 'allocationType')}
                disable={disableFields}
              />
            </div>}
            <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
              <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>User</p></Grid>
              <Grid sm={5} xs={12} item>
                <SelectInput
                  {...fields.user}
                  items={usersList}
                  onChange={(e) => this.inputChangeHandler(e.target.value, 'user')}
                  disabled={disableFields}
                />
              </Grid>
            </Grid>
            {fields.allocationType.value === 'WORK' && <>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Allocation Type</p></Grid>
                <Grid sm={5} xs={12} item>
                  <SelectInput
                    {...fields.projectType}
                    onChange={(e) => this.handleProjectType(e.target.value)}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Project Name</p></Grid>
                <Grid sm={5} xs={12} item>
                  <SelectInput
                    {...fields.projectId}
                    onChange={(e) => this.handleProjectChange(e.target.value)}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Task/Variation</p></Grid>
                <Grid sm={5} xs={12} item>
                  <SelectInput
                    {...fields.task}
                    onChange={(e) => this.inputChangeHandler(e.target.value, 'task')}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Start Date</p></Grid>
                <Grid sm={5} xs={12} item>
                  <Input
                    {...fields.startDate}
                    onChange={(value) => this.inputChangeHandler(value, 'startDate')}
                    disable={disableFields}
                  />
                </Grid>
                {!disableFields && <Grid sm={3} xs={12} item>
                  <p><span style={{ paddingLeft: '15px' }} className='link' onClick={() => this.allocateWeeklyHandler()}>{'Allocate for rest of Week'}</span></p>
                </Grid>}
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Finish Date</p></Grid>
                <Grid sm={5} xs={12} item>
                  <Input
                    {...fields.endDate}
                    onChange={(value) => this.inputChangeHandler(value, 'endDate')}
                    disable={disableFields}
                  />
                </Grid>
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Start Time</p></Grid>
                <Grid sm={5} xs={12} item>
                  <Input
                    {...fields.startTime}
                    onChange={(value) => this.inputChangeHandler(value, 'startTime')}
                    disable={disableFields || fields.allDayWithBreaks.value}
                  />
                </Grid>
                <Grid sm={3} xs={12} item>
                  <Input
                    {...fields.allDayWithBreaks}
                    onChange={(e) => this.inputChangeHandler(e.target.checked, 'allDayWithBreaks')}
                    disable={disableFields}
                  />
                </Grid>
              </Grid>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>End Time</p></Grid>
                <Grid sm={5} xs={12} item>
                  <Input
                    {...fields.endTime} onChange={(value) => this.inputChangeHandler(value, 'endTime')}
                    disable={disableFields || fields.allDayWithBreaks.value}
                  />
                </Grid>
              </Grid>
            </>
            }
            {fields.allocationType.value === 'LEAVE' && <>
              <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Leave Type</p></Grid>
                <Grid sm={5} xs={12} item>
                  <SelectInput
                    {...leaveFields.type}
                    onChange={(e) => this.inputChangeHandler(e.target.value, 'type', true)}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              {leaveFields.type.value === 'SHORT_TERM' &&
                <>
                  <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                    <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Date</p></Grid>
                    <Grid sm={5} xs={12} item>
                      <Input
                        {...leaveFields.date}
                        onChange={(value) => this.inputChangeHandler(value, 'date', true)}
                        disable={disableFields}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                    <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Start Time</p></Grid>
                    <Grid sm={5} xs={12} item>
                      <Input
                        {...leaveFields.startTime}
                        onChange={(value) => this.inputChangeHandler(value, 'startTime', true)}
                        disable={disableFields}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                    <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>End Time</p></Grid>
                    <Grid sm={5} xs={12} item>
                      <Input
                        {...leaveFields.endTime}
                        onChange={(value) => this.inputChangeHandler(value, 'endTime', true)}
                        disable={disableFields}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              {leaveFields.type.value === 'LONG_TERM' &&
                <>
                  <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                    <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>Start Date</p></Grid>
                    <Grid sm={5} xs={12} item>
                      <Input
                        {...leaveFields.startDate}
                        onChange={(value) => this.inputChangeHandler(value, 'startDate', true)}
                        disable={disableFields}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="subtaskrow-1" style={{ marginBottom: "10px" }}>
                    <Grid sm={3} xs={12} item><p style={{ margin: "8px 0" }}>End Date</p></Grid>
                    <Grid sm={5} xs={12} item>
                      <Input
                        {...leaveFields.endDate}
                        onChange={(value) => this.inputChangeHandler(value, 'endDate', true)}
                        disable={disableFields}
                      />
                    </Grid>
                  </Grid>
                </>
              }
            </>}

            {!disableFields && writePermission &&
              <small>
                {(allocationId && allocationType !== fields.allocationType.value) ? `You are about to change the type of the Schedule Allocation. This will remove the original Allocation.` : ''}
              </small>
            }

            <div className="col-12 d-flex mt-3">
              <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={this.submitData}>
                {save_title}
              </Button>
              <Button type="submit" className="secondarybtn cancelbtn" onClick={dialogClose}>CANCEL</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  projectCategories: state.project.projectCategories,
  project: state.project,
  globalSetting: state.globalSetting
})

const mapDispatchToProps = dispatch => ({
  getUserList: bindActionCreators(getUserList, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getService: bindActionCreators(getService, dispatch),
  getUserLeaves: bindActionCreators(getUserLeaves, dispatch),
  getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getGlobalSettingReducer: bindActionCreators(getGlobalSettingReducer, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AllocateResource)
