import { addCustomersAction } from 'src/slice/customer';
import {
  getCustomerList as getCustomerListAPI,
  addCustomer as addCustomerAPI,
  getCustomer as getCustomerApI,
  editCustomer as editCustomerAPI,
  addContact as addContactAPI,
  deleteCustomer as deleteCustomerAPI,
  getCustomerSiteAddress as getCustomerSiteAddressAPI,
} from '../api/customer-management.api'

export const getCustomerList = () => {
  return (dispatch, getState) => {
    return getCustomerListAPI().then((customers) => {
      dispatch(addCustomersAction({ customers: customers }))
      return customers;
    }).catch(e => {
      console.error(e)
    })
  }
}

export const getCustomer = (id) => {
  return (dispatch, getState) => {
    return getCustomerApI(id)
  }
}

export const addCustomer = (data) => {
  return (dispatch, getState) => {
    return addCustomerAPI(data)
  }
}

export const editCustomer = (data, id) => {
  return (dispatch, getState) => {
    return editCustomerAPI(data, id)
  }
}

export const addContact = (data, id) => {
  return (dispatch, getState) => {
    return addContactAPI(data, id)
  }
}

export const deleteCustomer = (id) => {
  return (dispatch, getState) => {
    return deleteCustomerAPI(id)
  }
}

export const getCustomerSiteAddress = (customerId) => {
  return (dispatch, getState) => {
    return getCustomerSiteAddressAPI(customerId)
  }
}
