import {
  getInventoryList as getInventoryListAPI,
  getItems as getItemsAPI,
  getCategories as getCategoriesAPI,
  getVendorCategories as getVendorCategoriesAPI,
  addItem as addItemAPI,
  getItem as getItemAPI,
  editItem as editItemAPI,
  deleteItem as deleteItemAPI,
  getLaborList as getLaborListAPI,
  addLabor as addLaborAPI,
  getLabor as getLaborAPI,
  editLabor as editLaborAPI,
  deleteLabor as deleteLaborAPI,
  addCategory as addCategoryAPI,
  deleteCategory as deleteCategoryAPI,
  addLevel as addLevelAPI,
  getLevels as getLevelsAPI,
  getLevel as getLevelAPI,
  updateLevel as updateLevelAPI,
  updateCategory as updateCategoryAPI,
  deleteLevel as deleteLevelAPI,
  getLabors as getLaborsAPI,
  getUnits as getUnitsAPI,
  saveUnits as saveUnitsAPI,
  updateItemStock as updateItemStockAPI,
  getTransactionsList as getTransactionsListAPI,
  addNoPoStock as addNoPoStockAPI
} from '../api/inventory.api'

export const getInventoryList = () => {
  return (dispatch, getState) => {
    return getInventoryListAPI()
  }
}

export const getLevels = () => {
  return (dispatch, getState) => {
    return getLevelsAPI()
  }
}

export const getLevel = (id, allLabors = false) => {
  return (dispatch, getState) => {
    return getLevelAPI(id, allLabors)
  }
}

export const getItems = (categoryId = '') => {
  return (dispatch, getState) => {
    return getItemsAPI(categoryId)
  }
}

export const getCategories = (categoryFilter) => {
  return (dispatch, getState) => {
    return getCategoriesAPI(categoryFilter)
  }
}
export const getVendorCategories = (categoryFilter) => {
  return (dispatch, getState) => {
    return getVendorCategoriesAPI(categoryFilter)
  }
}
export const addItem = (data) => {
  return (dispatch, getState) => {
    return addItemAPI(data)
  }
}

export const getItem = (data) => {
  return (dispatch, getState) => {
    return getItemAPI(data)
  }
}

export const editItem = (data, itemId) => {
  return (dispatch, getState) => {
    return editItemAPI(data, itemId)
  }
}

export const updateLevel = (data, itemId) => {
  return (dispatch, getState) => {
    return updateLevelAPI(data, itemId)
  }
}

export const updateCategory = (data, itemId) => {
  return (dispatch, getState) => {
    return updateCategoryAPI(data, itemId)
  }
}

export const deleteLevel = (data, itemId) => {
  return (dispatch, getState) => {
    return deleteLevelAPI(data, itemId)
  }
}

export const deleteCategory = (data, itemId) => {
  return (dispatch, getState) => {
    return deleteCategoryAPI(data, itemId)
  }
}

export const deleteItem = (data, itemId) => {
  return (dispatch, getState) => {
    return deleteItemAPI(data, itemId)
  }
}

export const addCategory = (data) => {
  return (dispatch, getState) => {
    return addCategoryAPI(data)
  }
}

export const addLevel = (data) => {
  return (dispatch, getState) => {
    return addLevelAPI(data)
  }
}

export const getLaborList = () => {
  return (dispatch, getState) => {
    return getLaborListAPI()
  }
}

export const addLabor = (data) => {
  return (dispatch, getState) => {
    return addLaborAPI(data)
  }
}

export const getLabor = (data) => {
  return (dispatch, getState) => {
    return getLaborAPI(data)
  }
}

export const editLabor = (data, LaborId) => {
  return (dispatch, getState) => {
    return editLaborAPI(data, LaborId)
  }
}

export const deleteLabor = (data, LaborId) => {
  return (dispatch, getState) => {
    return deleteLaborAPI(data, LaborId)
  }
}

export const getLabors = (id) => {
  return (dispatch, getState) => {
    return getLaborsAPI(id)
  }
}

export const saveUnits = (data) => {
  return (dispatch, getState) => {
    return saveUnitsAPI(data)
  }
}

export const getUnits = () => {
  return (dispatch, getState) => {
    return getUnitsAPI()
  }
}

export const updateItemStock = (data) => {
  return (dispatch, getState) => {
    return updateItemStockAPI(data)
  }
}

export const getTransactionsList = (location = '', poId = '', itemId = '') => {
  return (dispatch, getState) => {
    return getTransactionsListAPI(location, poId, itemId)
  }
}

export const addNoPoStock = (data) => {
  return (dispatch, getState) => {
    return addNoPoStockAPI(data)
  }
}
