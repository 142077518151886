import _ from 'lodash';
import React from 'react'

export default function TextAreaInput({ name, type = 'text', label, className = '', placeholder, value, error, validation = [], required, onChange, disabled, rows = 3, isHTClassRequired = true, ...props }) {
  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

  return (
    <div className="form-group">
      <div className={_.uniq(["input-block", ...className.split(' ')]).join(' ')}>
        {/* <div className="input-block"> */}
        <label className="input-label">
          <span className='d-flex gap-1'>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
        </label>
        <textarea
          className={`form-control ${isHTClassRequired && 'textarea-ht'}`}
          name={name}
          type={type}
          label={label || placeholder || ''}
          value={value}
          // valid={!error}
          error={error}
          disabled={disabled}
          onChange={onChange}
          rows={rows}
          {...props}
        />
        {error && <small className="float-start text-danger">
          {error}
        </small>}
      </div>
    </div>
  )
}
