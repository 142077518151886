import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './scss/project-management.scss'
import { PROJECT_MANAGEMENT } from './../../constants/route-paths'
import {
  getProjectList,
  editProject,
  deleteProject,
  saveTemplate,
  duplicateProject
} from './../../actions/project-management.action'
import { getCustomerList } from '../../actions/customer-management.action'
import { format } from 'date-fns'
import DeleteDialog from './../../components/delete-dialog'
import { Menu, MenuItem } from '@material-ui/core'
import Modal from './../../components/modal/modal'
import AddProject from './add-project'
import AddTemplate from './add-template-modal'
import ChangeProjectStatus from './change-project-status'
import DuplicateProjectDialog from '../../components/duplicate-project-dialog'
import { getProjectType } from './utils/getProjectStatus'

function ProjectMenu(props) {

  const {
    anchorEl,
    projectId,
    type,
    menuType,
    projectType,
    writePermission,
    history,
    onClose,
    entitlements
  } = props

  const [deleteopen, setdeleteopen] = useState(false)
  const [deleteId, setdeleteId] = useState('')
  const [changeStatusDialog, setchangeStatusDialog] = useState(false)
  const [newStatus, setnewStatus] = useState('')
  const [showTemplateModal, setshowTemplateModal] = useState('')
  const [showDuplicateDialog, setshowDuplicateDialog] = useState(false)
  const [customersList, setcustomersList] = useState([])
  const [viewProjectOpen, setviewProjectOpen] = useState(false)
  const templateModalHandler = (status) => {
    setshowTemplateModal(status)
  }

  const handleClose = () => {
    onClose({ anchorEl: null, hasStages: null });
  }

  // const handleMenu = (event, projectId, hasStages, projectName) => {
  //   history({ anchorEl: event.currentTarget, projectId, hasStages, projectName });
  // };
  const assetDialogHandler = () => {
    history(`/projects/asset/${props.projectId}`)
  }
  // const addProject = () => {
  //   history(`/${PROJECT_MANAGEMENT}/${ADD_PROJECT}?project-type=${projectType}`)
  // }

  const editProject = (id = null) => {
    if (!id) {
      id = props.projectId
    }
    history(`/${PROJECT_MANAGEMENT}/edit/${id}`)
  }

  const reviseProject = (id = null) => {
    if (!id) {
      id = props.projectId
    }

    history(`/${PROJECT_MANAGEMENT}/edit/${id}?action=revise`)
    if (props.projectStepTwo) {
      window.location.reload(); // Refresh the page   
    }

  }

  const saveTemplate = (data) => {
    templateModalHandler(false)
    let hasStages = props.hasStages
    let projectId = props.projectId
    props.saveTemplate({
      ...data,
      projectId,
      hasStages
    }).then((res) => {
      if (menuType == 'View') {
        history(`/projects?project-type=${projectType}`)
      }
    }).catch(err => templateModalHandler(true))
    handleClose()
  }


  const deleteHandler = (id = '') => {
    markAsDeleted();
  }

  const formateObj = (project) => {
    return {
      id: project.id,
      tenderNumber: project.tenderNumber,
      projectName: project.name,
      customer: project.customer && project.customer.business_name,
      address: project.address,
      quotetype: project.projectQuoteType,
      status: project.status,
      reasonOfLost: project.reasonOfLost,
      rootProjectId: project.rootProjectId,
      hasStages: project.hasStages,
      createdAt: format(new Date(project.dateOfCreation), 'dd/MM/yyyy'),
      dateOfDLP: project.dateOfDLP ? format(new Date(project.dateOfDLP), 'dd/MM/yyyy') : ''
    }
  }

  const getProjetcList = () => {
    const projectList = []
    const tender = []
    const dlp = []
    const completed = []
    const archived = []
    props.getProjectList('', '', '', projectType).then((projects) => {
      projects.forEach(project => {
        let { isCurrent, isQuote, isDLP, isCompleted, isArchived } = getProjectType(project)
        if (isCurrent) {
          projectList.push(formateObj(project))
        } else if (isQuote) {
          tender.push(formateObj(project))
        } else if (isArchived) {
          archived.push(formateObj(project))
        } else if (isCompleted) {
          completed.push(formateObj(project))
        } else if (isDLP) {
          dlp.push(formateObj(project))
        }
      })
      const projectsCount = {
        totalProjects: projectList.length,
        totalTenders: tender.length,
        totalDLP: dlp.length,
        totalArchived: archived.length,
        totalCompleted: completed.length
      }
      let _state = {
        projectsCount,
        project: projectList,
        tender,
        dlp,
        completed,
        archived
      }
      history(_state)
    })
  }


  const deleteDialogHandler = (id = '') => {
    handleClose()
    setdeleteopen(!deleteopen)
    setdeleteId(id)
  }

  const handleViewProjectClose = () => {
    const dialogState = viewProjectOpen
    setviewProjectOpen(!dialogState)
    if (dialogState) {
      handleClose()
      getProjetcList()
    }
  }

  // const viewProject = () => {
  //   const id = props.projectId
  //   history(`/${PROJECT_MANAGEMENT}/view/${id}`)
  // }

  // const clostAsLost = () => {
  //   setnewStatus('LOST')
  //   handleClose()
  //   changeStatusHandler()
  //   getProjetcList()
  // }

  const changeStatus = (data) => {
    props.editProject(data, props.projectId).then(res => {
      changeStatusHandler()
      getProjetcList()
      if (menuType == 'List') {
        props.changeProjectType(newStatus)
      }
      if (menuType == 'View') {
        history(`/projects?project-type=${projectType}`)
      }
    })
  }

  const markAsDLP = () => {
    setnewStatus('DLP')
    handleClose()
    changeStatusHandler()
    getProjetcList()
  }

  const markAsCompleted = () => {
    handleClose()
    props.editProject({ status: 'COMPLETED' }, props.projectId).then(res => {
      if (menuType == 'List') {
        props.changeProjectType(newStatus)
      }
    })
  }

  // const markAsCancelled = () => {
  //   setnewStatus('CANCELLED')
  //   handleClose()
  //   changeStatusHandler()
  //   getProjetcList()
  // }

  const markAsArchived = () => {
    handleClose()
    setnewStatus('ARCHIVED')
    changeStatusHandler()
    getProjetcList()
  }

  const markAsInProgress = () => {
    handleClose()
    props.editProject({ status: 'IN_PROGRESS' }, props.projectId).then(res => {
      if (menuType == 'List') {
        getProjetcList()
        props.changeProjectType(newStatus)
      }
    })
  }

  const markAsSubmit = () => {
    handleClose()
    props.editProject({ status: 'TENDER_SUBMITTED' }, props.projectId).then(res => {
      if (menuType == 'List') {
        getProjetcList()
        props.changeProjectType(newStatus)
      }
    })
  }

  const markAsDeleted = () => {
    handleClose()
    props.deleteProject(props.projectId, props.type).then(res => {
      getProjetcList()
      history(`/projects?project-type=${projectType}`)
    })
    deleteDialogHandler()
  }
  const changeStatusHandler = () => {
    handleClose()
    if (changeStatusDialog) {
      getProjetcList()
    }
    setchangeStatusDialog(!changeStatusDialog)
  }

  const duplicateProject = async (customerId = null) => {

    const { projectId, projectName } = props
    handleClose()
    if (customerId) {
      await props.duplicateProject({ projectId, customerId }).then(res => {
        getProjetcList()
        if (menuType == 'View') {
          history.goBack();
        }
      })
    }
    if (!showDuplicateDialog) {

      let customersList = await props.getCustomerList()
      let projectCustomers = await props.getProjectList(null, null, null, null, projectName)

      customersList = (Array.isArray(customersList) && customersList.map(customer => {
        return {
          key: customer.businessName,
          value: customer.id
        }
      })) || []

      customersList = customersList.filter(({ value }) => {
        const customer = Array.isArray(projectCustomers) && projectCustomers.find(({ customer }) => customer.id === value)
        return !customer
      })

      setcustomersList(customersList)
    }
    setshowDuplicateDialog(!showDuplicateDialog)
  }

  return (
    <div>
      <Menu
        id="simple-menu"
        className={props.customstyle}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {type === 'PROJECT' &&
          <>
            <MenuItem key={'Project_Assets'} disabled={!writePermission} onClick={() => assetDialogHandler()}>Assets</MenuItem>
            <MenuItem key={'Project_Edit'} disabled={!writePermission} onClick={() => editProject()}>Edit</MenuItem>
            <MenuItem key={'Project_MarkInProgress'} disabled={entitlements.currentProject && writePermission ? false : true} onClick={() => markAsInProgress()}>Mark as In Progress</MenuItem>
            <MenuItem key={'Project_markAsDLP'} disabled={!writePermission} onClick={() => markAsDLP()}>Mark as DLP</MenuItem>
            <MenuItem key={'Project_markAsCompleted'} disabled={!writePermission} onClick={() => markAsCompleted()}>Mark as Completed</MenuItem>
            <MenuItem key={'Project_markAsArchived'} disabled={!writePermission} onClick={() => markAsArchived()}>Archive</MenuItem>
            <MenuItem key={'Project_deleteDialogHandler'} disabled={!writePermission} onClick={() => deleteDialogHandler()}>Delete</MenuItem>
          </>
        }
        {type === 'TENDER' &&
          <>
            <MenuItem key={'Tender_handleViewProjectClose'} disabled={entitlements.currentProject && writePermission ? false : true} onClick={handleViewProjectClose}>Convert to Project</MenuItem>
            <MenuItem key={'Tender_templateModalHandler'} disabled={!writePermission} onClick={() => templateModalHandler(true)}>Create as Template</MenuItem>
            <MenuItem key={'Tender_reviseProject'} disabled={!writePermission} onClick={() => reviseProject()}>New Revision</MenuItem>
            {(!props.projectStepTwo) && <MenuItem key={'Tender_viewRevisions'} onClick={() => props.viewRevisions(props.projectId)}>View Revisions</MenuItem>}
            <MenuItem key={'Tender_duplicateProject'} disabled={!writePermission} onClick={() => duplicateProject()}>Duplicate Project</MenuItem>
            <MenuItem key={'Tender_markAsSubmit'} disabled={!writePermission} onClick={() => markAsSubmit()}>Mark as Submit</MenuItem>
            <MenuItem key={'Tender_markAsArchived'} disabled={!writePermission} onClick={() => markAsArchived()}>Archive</MenuItem>
            <MenuItem key={'Tender_deleteDialogHandler'} disabled={!writePermission} onClick={() => deleteDialogHandler()}>Delete</MenuItem>
          </>
        }
        {type === 'DLP' &&
          <>
            <MenuItem key={'DLP_handleViewProjectClose'} disabled={entitlements.currentProject && writePermission ? false : true} onClick={handleViewProjectClose}>Mark as Current</MenuItem>
            <MenuItem key={'DLP_markAsCompleted'} disabled={!writePermission} onClick={() => markAsCompleted()}>Mark as Completed</MenuItem>
            <MenuItem key={'DLP_markAsArchived'} disabled={!writePermission} onClick={() => markAsArchived()}>Mark as Archived</MenuItem>
          </>
        }
        {type === 'COMPLETED' &&
          <>
            <MenuItem key={'COMPLETED_handleViewProjectClose'} disabled={entitlements.currentProject && writePermission ? false : true} onClick={handleViewProjectClose}>Mark as Current</MenuItem>
            <MenuItem key={'COMPLETED_markAsArchived'} disabled={!writePermission} onClick={() => markAsArchived()}>Mark as Archived</MenuItem>
            <MenuItem key={'COMPLETED_markAsDLP'} disabled={!writePermission} onClick={() => markAsDLP()}>Mark as DLP</MenuItem>
          </>
        }
      </Menu>

      {viewProjectOpen && <Modal
        open={viewProjectOpen}
        onClose={handleViewProjectClose}
        modalHeading={'Convert To Project'}
        modalSaveTxt='Convert'
        modalCloseTxt='Cancel'
        fullWidth={true}
        maxWidth={'md'}
        modalContent={
          <AddProject
            {...props}
            currentStep={0}
            tenderEditPage={true}
            projectId={projectId}
            convertTender={true}
            convertToProject={true}
            handleClose={handleViewProjectClose}
          />}
        primary
      />}
      <AddTemplate
        templateModalHandler={templateModalHandler}
        open={showTemplateModal}
        saveTemplate={saveTemplate}
      />
      <ChangeProjectStatus
        open={changeStatusDialog}
        dialogClose={changeStatusHandler}
        changeStatus={changeStatus}
        projectId={projectId}
        newStatus={newStatus}
      />
      <DeleteDialog
        open={deleteopen}
        id={deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName='Project'
      />
      <DuplicateProjectDialog
        open={showDuplicateDialog}
        dialogClose={duplicateProject}
        customersList={customersList}
      />
    </div>
  )

}

const mapStateToProps = state => ({
  entitlements: state.user.entitlements
})

const mapDispatchToProps = dispatch => ({
  getProjectList: bindActionCreators(getProjectList, dispatch),
  editProject: bindActionCreators(editProject, dispatch),
  deleteProject: bindActionCreators(deleteProject, dispatch),
  saveTemplate: bindActionCreators(saveTemplate, dispatch),
  duplicateProject: bindActionCreators(duplicateProject, dispatch),
  getCustomerList: bindActionCreators(getCustomerList, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMenu)
