import React, { Fragment, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import FileUploader from "../../../components/file-uploader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import idx from "idx";
import { PROJECT_MANAGEMENT } from "src/constants/route-paths";
import { updateProjectData } from "../utils/get-project";
import SelectInput from "src/components/inputs/select";
import { errorSnackBar } from "src/actions/common.action";
import _ from 'lodash'
import { getVendorList } from "src/actions/vendor-management.action";
import { numberFormat } from "src/util/currency-formatter";
import { getProject, savePurchaseOrder } from "src/actions/project-management.action";
import CustomInput from "src/components/inputs/new-input";
import { fileValidator } from "src/util/validators/file";

export default function CreateWorkOrder(props) {

  const { projectDetail = {} } = useSelector(state => state)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { projectId } = useParams();

  const [fields, setFields] = useState({
    quote_no: {
      name: 'quote_no',
      label: 'Quote No',
      type: 'text',
      touched: false,
      error: false,
      valid: true,
      value: '',
      validation: []
    },
    vendor: {
      name: 'vendor',
      label: 'Select Vendor',
      type: 'select',
      touched: false,
      error: false,
      valid: true,
      value: '',
      items: [],
      validation: ['required']
    }
  })
  const [createWOState, setCreateWOState] = useState({
    stages: [],
    fileToUpload: [],
    hasStages: false,
  })

  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  useEffect(() => {
    if (!fields.vendor.items.length) _getVendorList()
    if (projectId) {
      getStages()
    }

  }, [projectId])

  const getStages = async () => {
    const project = await dispatch(getProject(projectId)) //idx(projectDetail, _ => _.project.res)
    updateProjectData({ projectData: project, dispatch })
    const items = idx(props, _ => _.poData.items) || []
    setCreateWOState(prev => ({
      ...prev,
      stages: project?.stages || [],
      hasStages: project?.hasStages || false,
    }));

    const stages = project?.stages || []
    Array.isArray(items) && items.length && Array.isArray(stages) && stages.map((stage, index) => {
      const isSelectedStage = items.find(item => item.id === stage.id)
      if (isSelectedStage)
        stageHandler(isSelectedStage.quote, index, '', true)

      Array.isArray(stage.tasks) && stage.tasks.map((task, ti) => {
        const isSelectedTask = items.find(item => item.id === task.id)
        if (isSelectedTask) {
          taskHandler(isSelectedTask.quote, index, ti, '', true)
        }
        Array.isArray(task.subTasks) && task.subTasks.map((subTask, si) => {
          const isSelectedSubTask = items.find(item => item.id === subTask.id)
          if (isSelectedSubTask) {
            subTaskHandler(isSelectedSubTask.quote, index, ti, si, '', true)
          }
        })
      })
    })
  }

  const stageHandler = (value, stageIndex, type, preFilled = false) => {
    let stages = _.cloneDeep(createWOState.stages)
    if (type === 'checkbox') {
      stages[stageIndex].selected = value
      stages[stageIndex].tasks.forEach((task, taskIndex) => {
        task.selected = value
        task.subTasks.forEach((subTask, subTaskIndex) => { subTask.selected = value })
      })
    } else if (type === 'quote') {
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].quote = value
    } else if (preFilled) {
      stages[stageIndex].selected = true
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].quote = value
    }
    setCreateWOState(prev => ({
      ...prev,
      stages: stages
    }));
  }

  const taskHandler = (value, stageIndex, taskIndex, type, preFilled = false) => {
    let stages = _.cloneDeep(createWOState.stages)
    if (type === 'checkbox') {
      stages[stageIndex].tasks[taskIndex].selected = value
      stages[stageIndex].tasks[taskIndex].subTasks.forEach((subTask, subTaskIndex) => {
        subTask.selected = value
      })
    } else if (type === 'quote') {
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].quote = value
    } else if (preFilled) {
      stages[stageIndex].tasks[taskIndex].selected = true
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].quote = value
    }
    setCreateWOState(prev => ({
      ...prev,
      stages: stages
    }));
  }

  const subTaskHandler = (value, stageIndex, taskIndex, subTaskIndex, type, preFilled = false) => {
    let stages = _.cloneDeep(createWOState.stages)
    if (type === 'checkbox') {
      stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].selected = value
    }
    else if (type === 'quote') {
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].quote = value
    } else if (preFilled) {
      stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].selected = true
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].subTasks[subTaskIndex].quote = value
    }
    setCreateWOState(prev => ({
      ...prev,
      stages: stages
    }));
  }

  const _getVendorList = async () => {
    const vendorsList = await dispatch(getVendorList("sub-contractor"));
    const vendors = (vendorsList || []).map(({ id, businessName }) => ({
      key: businessName,
      value: id,
    })) || [];

    setFields(prev => ({
      ...prev,
      vendor: {
        ...prev.vendor,
        items: vendors,
        value: idx(props, (_) => _.poData.vendorId) || ""
      }
    }));
  };

  const inputChangeHandler = (e) => {
    fields[e.target.name].value = e.target.value
    fields[e.target.name].touched = true
    const { error, valid } = handleValidation(e.target, fields[e.target.name].validation)
    fields[e.target.name].error = error
    fields[e.target.name].valid = valid
    setFields(prev => ({
      ...prev,
      ...fields,
    }));
  };

  const handleValidation = ({ type, value }, validation) => {
    let error = false
    let valid = true
    if (validation.includes('required') && _.isEmpty(value)) {
      return { error: 'This Field is Required', valid: false }
    }
    if (type === 'number') {
      if (isNaN(value)) { return { error: 'Please Enter a valid value', valid: false } }
      if (value < 0) {
        return { error: 'Value should be greater than 0', valid: false }
      }
    }
    return { error, valid }
  }

  const handleFileUpload = (e) => {
    let { fileToUpload } = createWOState;
    let isValid = true
    if (e.target.files.length > 5) {
      dispatch(errorSnackBar('You can not upload more than 5 files at a time'))
    } else {
      const fileToUpload = e.target.files
      Array.isArray(fileToUpload) && fileToUpload.map(file => {
        const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
        const { validFileSize, validFileType, error } = fileValidation
        if (!validFileSize.valid && !error) {
          isValid = false
          dispatch(errorSnackBar(validFileSize.error))
        }
        else if (!validFileType.valid && !error) {
          isValid = false
          dispatch(errorSnackBar(validFileType.error))
        } else if (error) {
          isValid = false
          dispatch(errorSnackBar(error.error))
        }
      })
    }

    if (!isValid) return;

    setCreateWOState(prev => ({
      ...prev,
      fileToUpload: [...fileToUpload, ...(e.target.files)]
    }));
    e.target.value = ''
  }

  const handleRemoveFile = (index) => {
    let { fileToUpload } = createWOState
    fileToUpload.splice(index, 1)
    setCreateWOState(prev => ({
      ...prev,
      fileToUpload: fileToUpload
    }));
  }

  const formatItemObj = (data, type) => {
    return {
      taskName: data.name,
      taskDescription: data.description,
      taskId: data.id,
      total: parseFloat(data.quote) || 0,
      taskType: type
    }
  }

  const submitForm = async () => {
    const { stages, fileToUpload } = createWOState
    const rfqId = idx(props, _ => _.poData.rfqId) || ''
    const { poData } = props
    let isFormValid = true
    for (let key in fields) {
      const { error, valid } = handleValidation(fields[key], fields[key].validation)
      fields[key].touched = true
      fields[key].valid = valid
      fields[key].error = error
      isFormValid = isFormValid && valid
    }
    const selectedTasks = []
    stages.forEach(stage => {
      if (stage.selected) {
        selectedTasks.push(formatItemObj(stage, 'STAGE'))
      }
      stage.tasks.forEach(task => {
        if (task.selected) {
          selectedTasks.push(formatItemObj(task, 'TASK'))
        }
        /* task.subTasks.forEach(subTask => {
          if (subTask.selected) {
            selectedTasks.push(formatItemObj(subTask, 'SUBTASK'))
          }
        }) */
      })
    })
    if (!isFormValid) {
      setFields(prev => ({
        ...prev,
        ...fields
      }));
    } else {
      const data = new FormData()
      data.set('projectId', projectId)
      data.set('vendorId', fields.vendor.value)
      data.set('quoteNo', fields.quote_no.value)

      for (let i = 0; i < selectedTasks.length; i++) {
        data.append(`items[]`, JSON.stringify(selectedTasks[i]))
      }
      for (let i = 0; i < fileToUpload.length; i++) {
        data.append(`attachment`, fileToUpload[i])
      }

      if (poData && poData.quotationId) {
        data.set('quotationId', poData && poData.quotationId)
      }

      await dispatch(savePurchaseOrder(data, rfqId, 'Work Order Created Successfully')).catch(e => console.log(e))
      viewWoList()
    }
  }

  const viewWoList = () => navigate(`/${PROJECT_MANAGEMENT}/work-order/${projectId}`);
  const viewProject = () => navigate(`/projects/view/${projectId}`)
  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)

  return (
    <>
      <div className="purchase-order-block">
        <h2 className="content-heading text-uppercase">Create Work Order</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewWoList} >WO LIST</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Work Order</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-block d-flex purchase-id-block">
                <label>Work Order Id</label>
                <p className="mb-0 ms-3"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 mt-3">
            <CustomInput
              placeholder="Enter Quote No"
              {...fields.quote_no}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="col-lg-4 col-md-4 mt-3">
            <SelectInput
              {...fields.vendor}
              label={'Vendor'}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>

          <div className="col-lg-4 col-md-4 mt-3 fileupload-section">
            <div className="file-section-part position-relative h-auto px-0">
              {/* <label className="label-header">Attachment</label> */}
              <div className="file-part">
                <FileUploader
                  fileHandler={(files) =>
                    handleFileUpload({ target: { files, type: 'file', value: '' } })
                  }
                />
                <label htmlFor="#file-section"></label>
              </div>
              <input type="file" id="file-section"></input>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group text-left">
              <div className="input-block">
                <label className="input-label">Uploaded Documents</label>
                <p className="m-0 doc-info">
                  {Array.isArray(createWOState.fileToUpload) && createWOState.fileToUpload.map((file, index) => (
                    <span key={index}>
                      <span>{file.name}</span>
                      <span onClick={() => handleRemoveFile(index)}><DeleteIcon /></span>
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="material-list-block mt-3">
          <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch">
              <thead>
                <tr>
                  <th className="w-auto">Select Task</th>
                  <th className="w-auto">Description</th>
                  <th>Contract Amount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {createWOState.stages.map((stage, stageIndex) => {
                  return (
                    <Fragment key={stageIndex}>
                      {(createWOState.hasStages &&
                        <tr>
                          <td>
                            {/* <Form> */}
                            <Form.Check
                              type={'checkbox'}
                              id={stage.id}
                              value={stage.selected}
                              label={`Stage${stageIndex + 1}: ${stage.name}`}
                              onChange={(e) => this.stageHandler(e.target.checked, stageIndex, 'checkbox')}
                            />
                            {/* </Form> */}
                          </td>
                          <td>{stage.description}</td>
                          <td></td>
                          <td>
                            <CustomInput
                              className="input-block-sm w-auto"
                              value={stage.quote}
                              valid={!stage.error}
                              lable='Amount'
                              name='quote'
                              error={stage.error}
                              onChange={(e) => stageHandler(e.target.value, stageIndex, 'quote')}
                            />
                          </td>
                        </tr>
                      ) || ''}
                      {stage.tasks.map((task, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form>
                                <Form.Check
                                  type={'checkbox'}
                                  id={task.id}
                                  value={task.selected}
                                  onChange={(e) => taskHandler(e.target.checked, stageIndex, index, 'checkbox')}
                                  label={`Task${index + 1}: ${task.name}`}
                                />
                              </Form>

                            </td>
                            <td>{task.description}</td>
                            <td>{numberFormat(idx(task, _ => _.subcontractorTotal_SaleAmount) || 0)}</td>
                            <td>
                              <CustomInput
                                className="input-block-sm w-auto"
                                value={task.quote}
                                lable='Amount'
                                type='number'
                                name='quote'
                                valid={!task.error}
                                error={task.error}
                                onChange={(e) => taskHandler(e.target.value, stageIndex, index, 'quote')}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="col-12 d-flex mt-3">
          <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={submitForm}>DONE</Button>
          <Button type="submit" className="secondarybtn cancelbtn me-auto" onClick={viewWoList}>CANCEL</Button>
        </div>
      </div>

    </>
  );

}
