import React from 'react'
import { Card, CardContent, Grid, IconButton } from '@material-ui/core'
import './category-card.scss'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Typography } from '@mui/material';

export default function CategoryCard({ createLinkText, createLink, ...props }) {

  return (
    <>{!props.ProjectCard && <Grid item className='categoryCard'>
      <Card raised className={props.className} onClick={props.onClick}>
        {
          <CardContent>
            {props.icon}
            <p className='categoryName'>{props.name}</p>
            {props.totalHeading ? <p className='categoryTotalHeading'>{props.totalHeading}</p> : ''}
            <p className='categoryCount'>{props.count && <span>({props.count})</span>} {props.arrow && <IconButton>
              <ArrowForwardRoundedIcon />
            </IconButton>}
            </p>
          </CardContent>

        }
      </Card>
    </Grid>}
      {props.ProjectCard && <Grid item className='categoryCard ProjectCard'>
        <Card className='card' onClick={createLink ? () => { } : props.onClick} >
          <CardContent>
            <Grid container>
              <Grid item xs={3} onClick={createLink ? props.onClick : () => { }} >
                <div>{props.icon}</div>
              </Grid>
              <Grid item xs={9} className='task-details'>
                <div className='d-flex' onClick={createLink ? props.onClick : () => { }}>
                  <Typography variant='body1' className='task-name'>{props.name}</Typography>
                  <Typography variant='body2'>{props.count && <span>({props.count})</span>}</Typography>
                </div>
                {createLink && createLinkText && <p className="task-create-text mb-0 mt-1" onClick={createLink}>{createLinkText}</p>}
                {!createLink && <p className="task-create-text mb-0 mt-1" style={{ textDecoration: 'none' }}>&nbsp;</p>}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>}</>
  )
}
