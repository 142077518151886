import { numberFormat } from "src/util/currency-formatter";
import { Link } from "react-router-dom";
import { PURCHASE_ORDER, SUPPLIERS } from "src/constants/route-paths";

export const createPOFields = {
    workType: {
        name: "workType",
        value: "",
        error: false,
        items: [{ key: "Project", value: "project" }],
        type: "select",
        valid: true,
        validation: [],
    },
    project: {
        name: "project",
        value: "",
        error: false,
        items: [],
        type: "select",
        valid: true,
        validation: ["required"],
    },
    quote_no: {
        name: "quote_no",
        label: "Quote No",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [],
    },
    tasksList: {
        name: "tasksList",
        value: "",
        error: false,
        items: [],
        type: "select",
        valid: true,
        validation: ["optional"],
    },
    vendor: {
        name: "vendor",
        value: "",
        error: false,
        items: [],
        type: "select",
        valid: true,
        validation: ["required"],
    },
    deliveryOption: {
        name: "deliveryOption",
        value: "",
        error: false,
        items: [],
        type: "select",
        valid: true,
        validation: ["optional"],
    },
    deliveryDate: {
        name: "deliveryDate",
        value: "",
        error: false,
        items: [],
        type: "datepicker",
        valid: true,
        validation: ["optional"],
    },
    notes: {
        name: "notes",
        value: "",
        error: false,
        items: [],
        type: "textarea",
        valid: true,
        validation: ["optional"],
    },
    street_address: {
        name: "street_address",
        label: "Street Address",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    suburb: {
        name: "suburb",
        label: "Suburb",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    state: {
        name: "state",
        label: "State",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    postcode: {
        name: "postcode",
        label: "Postcode",
        type: "number",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    country: {
        name: "country",
        label: "Country",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
}

export const poListColumns = [
    {
        title: "PO Number",
        field: "id",
        render: (rowData) => (
            <Link to={`/${SUPPLIERS}/${PURCHASE_ORDER}/view/${rowData.poId}/project`}>
                {rowData.id}
            </Link>
        ),
    },
    { title: "Vendor", field: "vendorName" },
    // {
    //   title: "Vendor Type",
    //   field: "type",
    //   render: (rowData) =>
    //     rowData.type === "Supplier" ? "Supplier" : "Contractor",
    // },
    {
        title: "PO Address",
        field: "address",
    },
    {
        title: "Project",
        field: "project",
    },
    { title: "Issue Date", field: "issueDate" },
    {
        title: "Total",
        field: "total",
        class: 'text-right',
        render: (rowData) => `${numberFormat(rowData.total)}`,
    },
    {
        title: "Status",
        field: "status",
        render: (rowData) => <span className={rowData.status == 'Pending' ? 'text-warning' : rowData.status == 'Completed' ? 'text-success' : ''}>{rowData.status}</span>,
    },
]