import {
    getAssetList as getAssetListAPI,
    addAsset as addAssetAPI,
    getAsset as getAssetAPI,
    editAsset as editAssetAPI,
    deleteAsset as deleteAssetAPI,
    allocateAsset as allocateAssetAPI,
    assetMaintenance as assetMaintenanceAPI,
    assetRepair as assetRepairAPI,
    getAllocationHistory as getAllocationHistoryAPI,
    getMaintenanceHistory as getMaintenanceHistoryAPI,
    submitAsset as submitAssetAPI,
    discardAsset as discardAssetAPI
} from '../api/asset-management.api'

export const getAssetList = (category, projectId, serviceId) => {
    return (dispatch, getState) => {
        return getAssetListAPI(category, projectId, serviceId)
    }
}

export const getAsset = (assetId) => {
    return (dispatch, getState) => {
        return getAssetAPI(assetId)
    }
}

export const addAsset = (assetData) => {
    return (dispatch, getState) => {
        return addAssetAPI(assetData)
    }
}

export const editAsset = (assetData, assetId) => {
    return (dispatch, getState) => {
        return editAssetAPI(assetData, assetId)
    }
}

export const deleteAsset = (assetId) => {
    return (dispatch, getState) => {
        return deleteAssetAPI(assetId)
    }
}

export const allocateAsset = (assetData) => {
    return (dispatch, getState) => {
        return allocateAssetAPI(assetData)
    }
}


export const assetMaintenance = (assetData) => {
    return (dispatch, getState) => {
        return assetMaintenanceAPI(assetData)
    }
}

export const assetRepair = (assetData) => {
    return (dispatch, getState) => {
        return assetRepairAPI(assetData)
    }
}

export const getAllocationHistory = (assetId, projectId, serviceId) => {
    return (dispatch, getState) => {
        return getAllocationHistoryAPI(assetId, projectId, serviceId)
    }
}

export const getMaintenanceHistory = (assetId) => {
    return (dispatch, getState) => {
        return getMaintenanceHistoryAPI(assetId)
    }
}

export const submitAsset = (assetId, data) => {
    return (dispatch, getState) => {
        return submitAssetAPI(assetId, data)
    }
}

export const discardAsset = (assetId) => {
    return (dispatch, getState) => {
        return discardAssetAPI(assetId)
    }
}
