import { React } from 'react';
import { defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import '../project-management/scss/task.scss'
import idx from 'idx';
import { random_rgba } from 'src/util/color';
import DatePickerInput from 'src/components/inputs/date-picker';
import { noDataPlugin } from './charts/plugin';
import { useSelector } from 'react-redux';

defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = false;
defaults.plugins.title.align = "center";
defaults.plugins.title.font.size = 15;
defaults.plugins.title.color = "black";
defaults.plugins.legend.display = false;

function InsightsStrategyTab({ filterChange }) {

    const data = useSelector(state => state.dashboard.insights)
    const filter = useSelector(state => state.dashboard.filter) || {}

    return (
        <>
            <div className='row dashboard-datepicker'>
                <div className="col-lg-6 col-md-6">
                    <DatePickerInput
                        label='From Date'
                        required={true}
                        clearable={false}
                        value={filter.start}
                        onChange={(e) => {
                            if (filter.end && (new Date(filter.end).getTime() >= new Date(e).getTime())) {
                                filterChange({ start: e, end: filter.end })
                            }
                        }}
                    />
                </div>
                <div className="col-lg-6 col-md-6">
                    <DatePickerInput
                        label='To Date'
                        required={true}
                        clearable={false}
                        value={filter.end}
                        onChange={(e) => {
                            if (filter.start && (new Date(filter.start).getTime() <= new Date(e).getTime())) {
                                filterChange({ start: filter.start, end: e })
                            }
                        }}
                    />
                </div>
            </div>
            <div className="row row-padding">
                <div class="d-flex col-12 mb-3">
                    <div className="w-auto me-auto mt-auto chart-heading">
                        <h2>OPERATIONS INSIGHTS</h2>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3> Resource Usage</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Doughnut
                                data={{
                                    labels: (idx(data, _ => _.labor_usage) || []).filter(item => item.revenue).map(item => item.name),
                                    datasets: [{
                                        label: '',
                                        data: (idx(data, _ => _.labor_usage) || []).filter(item => item.revenue).map(item => item.revenue),
                                        backgroundColor: (idx(data, _ => _.labor_usage) || []).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Material Usage</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Doughnut
                                data={{
                                    labels: (idx(data, _ => _.material_usage) || []).filter(item => item.revenue).map(item => item.name),
                                    datasets: [{
                                        label: '',
                                        data: (idx(data, _ => _.material_usage) || []).filter(item => item.revenue).map(item => item.revenue),
                                        backgroundColor: (idx(data, _ => _.material_usage) || []).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Supplier Usage</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.supplier_usage) || []).map(item => item.name),
                                    datasets: [
                                        {
                                            label: "Total",
                                            backgroundColor: "rgba(32, 178, 170,0.2)",
                                            data: (idx(data, _ => _.supplier_usage) || []).map(item => item.total),
                                        }
                                    ]
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            position: "bottom",
                                            labels: {
                                                font: { size: 10, weight: "bold" }
                                            }
                                        }
                                    },
                                    indexAxis: "y",
                                    scales: {
                                        x: {
                                            ticks: { display: true },
                                            stacked: true,
                                        },
                                        y: {
                                            beginAtZero: true,
                                            position: "left",
                                            stacked: true,
                                            ticks: { font: { size: 9 }, mirror: true },
                                            grid: { display: false }
                                        },
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Contractor Usage</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.contractor_usage) || []).map(item => item.name),
                                    datasets: [
                                        {
                                            label: "Total",
                                            backgroundColor: "rgba(46, 84, 104,0.2)",
                                            data: (idx(data, _ => _.contractor_usage) || []).map(item => item.total),
                                        }
                                    ]
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            position: "bottom",
                                            labels: {
                                                font: { size: 10, weight: "bold" }
                                            }
                                        }
                                    },
                                    indexAxis: "y",
                                    scales: {
                                        x: {
                                            ticks: { display: true },
                                            stacked: true,
                                        },
                                        y: {
                                            beginAtZero: true,
                                            position: "left",
                                            stacked: true,
                                            ticks: { font: { size: 9 }, mirror: true },
                                            grid: { display: false }
                                        },
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* ToDo */}
                {/* <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative"> Invoices List
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width project-name-width-ch">Project Name</th>
                                                <th className="text-right">Invoice No</th>
                                                <th className="text-right">Total Invoice</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">59</td>
                                                <td className="text-right">12 </td>
                                                <td>
                                                    <div class="action-edit text-center">
                                                        <DescriptionOutlinedIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">59</td>
                                                <td className="text-right">12 </td>
                                                <td>
                                                    <div class="action-edit text-center">
                                                        <DescriptionOutlinedIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">59</td>
                                                <td className="text-right">12 </td>
                                                <td>
                                                    <div class="action-edit text-center">
                                                        <DescriptionOutlinedIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">59</td>
                                                <td className="text-right">12 </td>
                                                <td>
                                                    <div class="action-edit text-center">
                                                        <DescriptionOutlinedIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">59</td>
                                                <td className="text-right">12 </td>
                                                <td>
                                                    <div class="action-edit text-center">
                                                        <DescriptionOutlinedIcon />
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">Purchase Order List
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width project-name-width-ch">Project Name</th>
                                                <th className="text-right">Order No</th>
                                                <th className="text-right">Total</th>
                                                <th className="text-right">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Not Available</span></td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge tbl-danger-badge">Not Available</span></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative"> Work Order List
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width project-name-width-ch">Project Name</th>
                                                <th className="text-right">Order No</th>
                                                <th className="text-right">Total</th>
                                                <th className="text-right">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Not Available</span></td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge tbl-danger-badge">Not Available</span></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">88</td>
                                                <td className="text-right">56</td>
                                                <td className="text-right"><span className="tbl-success-badge">Available</span></td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
                {/* ToDo */}

                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12 chart-heading">
                    <h2>FINANCIAL INSIGHTS</h2>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>REVENUE Analysis  </h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.fa_revenue) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Actual',
                                        data: (idx(data, _ => _.fa_revenue) || []).map(item => item.actual),
                                        backgroundColor: "rgba(75, 192, 192, 0.4)",
                                        borderColor: "rgba(75, 192, 192, 1)"
                                    }, {
                                        label: 'Forecast',
                                        data: (idx(data, _ => _.fa_revenue) || []).map(item => item.forecast),
                                        backgroundColor: "rgba(153, 102, 255, 0.4)",
                                        borderColor: "rgba(153, 102, 255, 1)"
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>profit Analysis </h3>
                        <div className="donut-chart donut-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.fa_profit) || []).map(item => item.month),
                                    datasets: [
                                        {
                                            label: 'Actual',
                                            data: (idx(data, _ => _.fa_profit) || []).map(item => item.actual),
                                            backgroundColor: "rgba(153, 102, 255, 0.4)",
                                            borderColor: "rgba(153, 102, 255, 1)"
                                        },
                                        {
                                            label: 'Forecast',
                                            data: (idx(data, _ => _.fa_profit) || []).map(item => item.forecast),
                                            backgroundColor: "rgba(255, 99, 132, 0.4)",
                                            borderColor: "rgba(255, 99, 132, 1)"
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Average PROFIT OVER TIME</h3>
                        <div className="donut-chart donut-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.profit) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Material Profit',
                                        data: (idx(data, _ => _.profit) || []).map(item => item.material),
                                        fill: true,
                                        borderColor: '#2196f3',
                                        backgroundColor: 'rgba(33, 150, 243,0.5)',
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'Resource Profit',
                                        data: (idx(data, _ => _.profit) || []).map(item => item.labor),
                                        fill: true,
                                        borderColor: '#4CAF50',
                                        backgroundColor: 'rgba(76, 175, 80,0.5)',
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'Contractor Profit',
                                        data: (idx(data, _ => _.profit) || []).map(item => item.contractor),
                                        fill: true,
                                        borderColor: 'rgb(255, 99, 132)',
                                        backgroundColor: 'rgba(255, 99, 132,0.5)',
                                        borderWidth: 1
                                    }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* ToDo */}
                {/* <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">  Project List
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width">Project Name</th>
                                                <th className="text-right">Item Type</th>
                                                <th className="text-right">Total Revenue</th>
                                                <th className="text-right">Total Cost</th>
                                                <th className="text-right">Total Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">Material</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">  Project List
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width">Project Name</th>
                                                <th className="text-right">Item Type</th>
                                                <th className="text-right">Total Revenue</th>
                                                <th className="text-right">Total Cost</th>
                                                <th className="text-right">Total Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">Material</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
                {/* ToDo */}
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12 chart-heading">
                    <h2>PLATFORM INSIGHTS</h2>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>QUOTE / PROPOSAL STATS</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.quote.submitted) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Average Days',
                                        borderWidth: 1,
                                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                                        borderColor: "rgba(75, 192, 192, 1)",
                                        fill: false,
                                        data: (idx(data, _ => _.quote.submitted) || []).map(item => item.value),
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>PROPOSAL / Project STATS</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.quote.activated) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Average Days',
                                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                                        borderColor: "rgba(75, 192, 192, 1)",
                                        borderWidth: 1,
                                        fill: false,
                                        data: (idx(data, _ => _.quote.activated) || []).map(item => item.value),
                                    }]
                                }}
                            />
                        </div>

                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Invoice STATS</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.invoice_ta) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Average Days',
                                        backgroundColor: "rgba(33, 150, 243, 0.6)",
                                        borderColor: "rgba(33, 150, 243, 1)",
                                        borderWidth: 1,
                                        fill: false,
                                        data: (idx(data, _ => _.invoice_ta) || []).map(item => item.value),
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>DOCUMENTS PRODUCED - OVER TIME</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.documents) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Total Documents',
                                        data: (idx(data, _ => _.documents) || []).map(item => item.total),
                                        backgroundColor: "rgba(255,0,0,0.3)"
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        title: {
                                            text: "Active Projects",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>USER ACTIVITY OVERTIME</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.activities) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Project & Timesheet Activities',
                                        data: (idx(data, _ => _.activities) || []).map(item => item.count),
                                        fill: true,
                                        borderColor: '#2196f3',
                                        backgroundColor: 'rgba(33, 150, 243,0.5)',
                                        borderWidth: 1
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>USAGE V LICENSED ALLOCATION</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Doughnut
                                data={{
                                    labels: [
                                        'Total Tasks',
                                        'Template Used',
                                    ],
                                    datasets: [{
                                        label: '',
                                        data: [450, 100],
                                        backgroundColor: [
                                            'rgba(75, 192, 192,0.5)',
                                            'rgb(225, 225, 225)',
                                        ],
                                        hoverOffset: 4
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        title: {
                                            text: "Active Projects",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>USAGE V LICENSED ALLOCATION</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Doughnut
                                data={{
                                    labels: [
                                        'Most Used',
                                        'Purchased Item',
                                    ],
                                    datasets: [{
                                        label: '',
                                        data: [450, 100],
                                        backgroundColor: [
                                            'rgba(255, 205, 86,0.5)',
                                            'rgb(225, 225, 225)',
                                        ],
                                        hoverOffset: 4
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        title: {
                                            text: "Active Projects",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div> */}


                {/* <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">   Top Resource
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width">Project Name</th>
                                                <th className="text-right">Item Type</th>
                                                <th className="text-right">Total Revenue</th>
                                                <th className="text-right">Total Cost</th>
                                                <th className="text-right">Total Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">Material</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">85</td>
                                                <td className="text-right">90</td>
                                                <td className="text-right">99</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative"> Lowest Resource
                            <span className="tbl-expand-icon"><ZoomOutMapIcon /></span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <div className="table-responsive">

                                    <Table bordered className="table-create table-material table-material-ch">
                                        <thead>
                                            <tr>
                                                <th className="project-name-width">Project Name</th>
                                                <th className="text-right">Item Type</th>
                                                <th className="text-right">Total Revenue</th>
                                                <th className="text-right">Total Cost</th>
                                                <th className="text-right">Total Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">GPO For Dom</a>
                                                </td>
                                                <td className="text-right">Material</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 15 April - 01</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Quote Profit Test 0001</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">No Quote 001Test 13 April - 03</a>
                                                </td>
                                                <td className="text-right">Resource</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="table-text-hidden">Demoproject2_31_1 Testproject102</a>
                                                </td>
                                                <td className="text-right">Contractor</td>
                                                <td className="text-right">5</td>
                                                <td className="text-right">10</td>
                                                <td className="text-right">25</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
            </div>
        </>
    );

}

export default InsightsStrategyTab
