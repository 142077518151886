import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import "./scss/project-management.scss";
import { withRouter } from "../../util/with-router";
// import { PROJECT_MANAGEMENT } from "../../constants/route-paths";
import { Box, Stack, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { toUSD } from "src/util/currency-formatter";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#d6d8da",
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === "light" ? "#1976d2" : "#1976d2",
  },
}));

function ProjectSummary(props) {
  useEffect(() => { });

  const {
    // projectId,
    projectName,
    projectManagerName,
    timeSpentProgress,
    moduleType,
    customerName,
    contactData,
    totalVariationAmount,
    totalRevisedContractCost,
    totalClaimAmount,
    totalRetentionAmount,
    progressCostMaterial = 0,
    progressCostLabor = 0,
    progressCostSubcontractor = 0,
    totalContract,
    siteAddress,
  } = props;

  // const createInvoice = () => {
  //   if (projectId) {
  //     props.history(`/${PROJECT_MANAGEMENT}/invoice/${projectId}/create`);
  //     return false;
  //   }
  // };
  // const createPO = () => {
  //   props.history(
  //     `/${PROJECT_MANAGEMENT}/purchase-order/${projectId}/create-purchase-order`
  //   );
  // };
  // const createWO = () => {
  //   props.history(
  //     `/${PROJECT_MANAGEMENT}/purchase-order/${projectId}/create-work-order`
  //   );
  // };
  // const addVariation = () => {
  //   props.history(`/${PROJECT_MANAGEMENT}/variation/add/${projectId}`);
  // };

  const getProgressValue = (val) => {
    let temp = Number(val) || 0
    return temp > 100 ? 100 : temp;
  }

  return (
    <div className="projectOverviewContainer" style={{ margin: "0" }}>
      {/* -------------------------------------Progress cards------------------------------ */}
      <div className="progress-fin progress-cards">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <Stack className="card customer-details">
              <div className="card-header">
                <Typography variant="h6">Project Detail</Typography>
              </div>

              <Stack className="card-details">
                <Grid container className="financial-text">
                  <Grid item xs={4} md={5} xl={4}>
                    <Typography variant="body1">Project Name</Typography>
                  </Grid>
                  <Grid item xs={1} md={1} xl={1}>
                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item xs={7} md={6} xl={7}>
                    <Typography variant="body1" className="text-bold">
                      {projectName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="financial-text">
                  <Grid item xs={4} md={5} xl={4}>
                    <Typography variant="body1">Address </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} xl={1}>
                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item xs={7} md={6} xl={7}>
                    <Typography variant="body1" className="text-bold">
                      {siteAddress}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="financial-text">
                  <Grid item xs={4} md={5} xl={4}>
                    <Typography variant="body1">Customer </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} xl={1}>
                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item xs={7} md={6} xl={7}>
                    <Typography variant="body1" className="text-bold">
                      {customerName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className="financial-text">
                  <Grid item xs={4} md={5} xl={4}>
                    <Typography variant="body1">Contact </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} xl={1}>
                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item xs={7} md={6} xl={7}>
                    <Typography variant="body1" className="text-bold">
                      {contactData}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className="financial-text">
                  <Grid item xs={4} md={5} xl={4}>

                    <Typography variant="body1">Assigned To </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} xl={1}>

                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item xs={7} md={6} xl={7}>

                    <Typography variant="body1" className="text-bold">
                      {projectManagerName}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
          {!moduleType && (
            <>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={4}>
                <Stack className="card">
                  <div className="card-header">
                    <Typography variant="h6">Progress</Typography>
                  </div>
                  <Stack className="card-details">
                    <Stack>
                      <Typography variant="body1">Material</Typography>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '70%' }}>
                          <BorderLinearProgress variant="determinate" value={getProgressValue(progressCostMaterial)} />
                        </Box>
                        <Box>
                          <Typography variant="body1">
                            {parseFloat(progressCostMaterial || 0).toFixed(2)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                    <Stack mt={1.5}>
                      <Typography variant="body1">Resource</Typography>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '70%' }}>
                          <BorderLinearProgress variant="determinate" value={getProgressValue(progressCostLabor)} />
                        </Box>
                        <Box>
                          <Typography variant="body1">
                            {parseFloat(progressCostLabor || 0).toFixed(2)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                    <Stack mt={1.5}>
                      <Typography variant="body1">Contractor</Typography>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '70%' }}>
                          <BorderLinearProgress variant="determinate" value={getProgressValue(progressCostSubcontractor)} />
                        </Box>
                        <Box>
                          <Typography variant="body1">
                            {parseFloat(progressCostSubcontractor || 0).toFixed(2)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                    <Stack mt={1.5}>
                      <Stack direction="row" spacing={1} className="time-spend">
                        <Typography>Time Spend</Typography>
                        {/* <Typography>-</Typography> */}
                        <Typography variant="body1" className="text-bold">
                          {timeSpentProgress}{' '}Hours
                        </Typography>
                      </Stack>
                    </Stack>

                    {/* <div className='progress'>
           <Typography variant='body1'>Invoiced</Typography>
            <BorderLinearProgress variant="determinate" value={60} />
           </div> */}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Stack className="card">
                  <div className="card-header">
                    <Typography variant="h6">Financial</Typography>
                  </div>
                  <Stack className="card-details">
                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">Contract </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD(totalContract || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">Variations </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD(totalVariationAmount || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">
                          Revised Contract
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD(totalRevisedContractCost || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">Claimed</Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD(totalClaimAmount || 0)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">
                          Retention Held
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD(totalRetentionAmount || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="financial-text">
                      <Grid item xs={4} md={5} xl={7}>
                        <Typography variant="body1">Balance</Typography>
                      </Grid>
                      <Grid item xs={1} md={1} xl={1}>
                        <Typography variant="body1">-</Typography>
                      </Grid>
                      <Grid item xs={7} md={6} xl={4}>
                        <Typography variant="body1" className="text-bold text-right">
                          {toUSD((parseFloat(totalContract || 0) -
                            parseFloat(totalClaimAmount || 0)) || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <Stack className="card">
                  <div className="card-header">
                    <Typography variant="h6">Shortcuts</Typography>
                  </div>
                  <Stack className="card-details">
                    <Grid container className="createTask">
                      <span className="createStageLabel">
                        <AddCircleOutlineIcon
                          className="stageName plus-icon"
                          onClick={() => createPO()}
                          style={{ marginTop: "10px" }}
                        />
                        <span className="stageName" onClick={() => createPO()}>
                          Create Purchase Order
                        </span>
                      </span>
                    </Grid>
                    <Grid container className="createTask">
                      <span className="createStageLabel">
                        <AddCircleOutlineIcon
                          className="stageName plus-icon"
                          onClick={() => createWO()}
                          style={{ marginTop: "10px" }}
                        />
                        <span className="stageName" onClick={() => createWO()}>
                          Create Work Order
                        </span>
                      </span>
                    </Grid>
                    {quoteType !== "noquote" && (
                      <Grid container className="createTask">
                        <span className="createStageLabel">
                          <AddCircleOutlineIcon
                            className="stageName plus-icon"
                            onClick={() => addVariation()}
                            style={{ marginTop: "10px" }}
                          />
                          <span
                            className="stageName"
                            onClick={() => addVariation()}
                          >
                            Create Variation
                          </span>
                        </span>
                      </Grid>
                    )}
                  </Stack>
                </Stack>
              </Grid> */}

            </>
          )}
        </Grid>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(null, mapDispatchToProps)(ProjectSummary));
