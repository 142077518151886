import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_USERS_LIST = '/user/list'
const SAVE_USER_ROLE = '/role/add'
const ADD_USER = '/user/register'
const GET_ROLES_LIST = '/role/list'
const GET_USER_DATA = '/user/profile'
const GET_USER = '/user/detail'
const LOG_IN = '/login'
const UPDATE_PROFILE = '/profile/update'
const UPDATE_PASSWORD = '/profile/password'
const UPDATE_USER = '/user/update'
const DELETE_ROLE = '/role'
const GET_USER_NAMES = '/user/names/list'
const USE_REFRESH_TOKEN = '/login/refresh'
const FORGOT_PASSWORD = '/user/forgot-password'
const DELETE_USERS = '/user'
const GET_ACTIVE_USER = '/user/active/list'
const UPDATE_USER_ROLE = '/user/role'

// // ************API call Functions starts here **************

export const deleteRole = async (id) => {
  return await axios('delete', `${DELETE_ROLE}/${id}`, {}, { successMessage: 'User Role Deleted Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const editUserRole = async (data, id) => {
  return await axios('put', `${DELETE_ROLE}/${id}`, data, { successMessage: 'User Role Updated Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveUserRole = async (data) => {
  return await axios('post', SAVE_USER_ROLE, data, { successMessage: 'Role Created Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addUser = async (data) => {
  return await axios('post', ADD_USER, data, { successMessage: 'User Created Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUserList = async (params) => {
  return await axios('get', GET_USERS_LIST, {}, { server: microServices.DE, params }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getRolesList = async () => {
  return await axios('get', GET_ROLES_LIST, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const login = async (data) => {
  return await axios('post', LOG_IN, data, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUserData = async () => {
  return await axios('get', GET_USER_DATA, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const impersonateUserData = async (id) => {
  return await axios('post', `${GET_USER_DATA}/${id}`, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUser = async (id) => {
  return await axios('get', `${GET_USER}/${id}`, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateProfile = async (data) => {
  return await axios('put', UPDATE_PROFILE, data, { /* successMessage: 'Profile Updated Successfully', */ server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updatePassword = async (data) => {
  return await axios('put', UPDATE_PASSWORD, data, { successMessage: 'Password Updated Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editUser = async (data, id) => {
  return await axios('put', `${UPDATE_USER}/${id}`, data, { successMessage: 'User Updated Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUserNames = async () => {
  return await axios('get', GET_USER_NAMES, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const useRefreshToken = async (data) => {
  return await axios('post', USE_REFRESH_TOKEN, data, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const forgetPassword = async (data) => {
  return await axios('post', FORGOT_PASSWORD, data,
    {
      server: microServices.DE,
      loader: loaders.OVERLAY,
      successMessage: 'Check your email to proceed with the password reset'
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const deleteUser = async (id) => {
  return await axios('delete', `${DELETE_USERS}/${id}`, {}, { successMessage: 'User Deleted Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getActiveUser = async () => {
  return await axios('get', GET_ACTIVE_USER, {}, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateUserRoles = async (data, id) => {
  return await axios('put', `${UPDATE_USER_ROLE}/${id}`, data, { server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
