// A nice helper to tell us if we're on the server

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const isMobile = {
  Android: !isServer ? () => navigator.userAgent.match(/Android/i) : () => {
  },
  BlackBerry: !isServer ? () => navigator.userAgent.match(/BlackBerry/i) : () => {
  },
  iOS: !isServer ? () => (navigator.userAgent.match(/iPhone|iPad|iPod/i) && window.orientation === 90) : () => {
  },
  Opera: !isServer ? () => navigator.userAgent.match(/Opera Mini/i) : () => {
  },
  Windows: !isServer ? () => navigator.userAgent.match(/IEMobile/i) : () => {
  },
  Tablet: !isServer ? () => (navigator.userAgent.match(/Tablet/i) && window.orientation === 90) : () => {
  },
  WindowsTablet: !isServer ? () => (navigator.userAgent.match(/Tablet PC/i) && window.orientation === 90) : () => {
  },
  otherMobileDevice: !isServer ? () => navigator.userAgent.match(/Mobi/i) : () => {
  },
  any: !isServer ? () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() || isMobile.Tablet() || isMobile.WindowsTablet() || isMobile.otherMobileDevice()) : () => {
  }
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

export const checkVideoURL = url => url.match(/\.(mp4)$/) !== null;

export const isArray = value => (value && typeof value === 'object' && value.constructor === Array);

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
export const getUserIP = (onNewIP) => {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection = !isServer && (window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection);
  var pc = !isServer && new myPeerConnection({
    iceServers: []
  }),
    noop = function () {
    },
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    //eslint-disable-next-line
    key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer().then(function (sdp) {
    sdp.sdp.split('\n').forEach(function (line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });

    pc.setLocalDescription(sdp, noop, noop);
  }).catch(function (reason) {
    // An error occurred, so handle the failure to connect
  });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
};

// for google-analytics
export const pushInfoToGa = obj => {
  if (!isServer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
};

export const pushGaProductClick = {
  pushGa: (obj) => {
    const requestObj = { ...obj };
    pushInfoToGa(requestObj);
  },
  pushGaProductClick1: (obj, outerObj = {}) => {
    const requestObj = {
      ...outerObj,
      event: 'productView',
      ecommerce: {
        click: {
          products: [obj],
        },
      },
      eventCallback: function () {
      },
    };
    pushInfoToGa(requestObj);
  },
  pushGaProductClick2: (obj, outerObj = {}) => {
    const requestObj = {
      ...outerObj,
      ecommerce: {
        detail: {
          products: [obj],
        },
      },
    };
    pushInfoToGa(requestObj);
  },
  pushGaAddToCart: (obj, outerObj = {}) => {
    const requestObj = {
      ...outerObj,
      event: 'addToCart',
      ecommerce: {
        currency: (outerObj && outerObj.currencyCode) || '',
        add: {
          products: [obj],
        },
      },
    };
    pushInfoToGa(requestObj);
  },
  pushGaRemoveFromCart: (obj, outerObj = {}) => {
    const requestObj = {
      ...outerObj,
      event: 'removeFromCart',
      ecommerce: {
        currency: (outerObj && outerObj.currencyCode) || '',
        remove: {
          products: [obj],
        },
      },
    };
    pushInfoToGa(requestObj);
  },
  pushGaCheckout: (productArray, outerObj = {}, actionField = {}) => {
    const requestObj = {
      ...outerObj,
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: actionField,
          products: [...productArray],
        },
      },
    };
    pushInfoToGa(requestObj);
  },
  pushGaPurchase: (productArray, outerObj = {}, actionField = {}) => {
    const requestObj = {
      ...outerObj,
      event: 'productPurchase',
      ecommerce: {
        purchase: {
          actionField: actionField,
          products: [...productArray],
        },
      },
    };
    pushInfoToGa(requestObj);
  }
}

export const createObjForGA = {
  outerAndEcommerce: (item, authReducer, commonSettings, page, order) => {
    const ecommerce = {
      product_code: item.pId || item.productId || item.id || '',
      product_name: item.title || '',
      brandId: item.brandId || '',
      brand: item.brandName || '',
      description: item.productDetails || '',
      sub_category_name: item.subcatid || '',
      price_before_discount: item.oldPrice || 0,
      price_after_discount: item.currentPrice || item.transactionPrice || item.price || 0,
      category: item['en_parentCategory'] || item['ar_parentCategory'] || '',
      color: (item.colors && item.colors.colorSizeOptions) || [],
      size: (item.colors && item.colors.colorSizeOptions) || [],
      prqt: 1,
      prid: item.pId || item.productId || item.id || '',
      shipping: item.deliveryType || '',
      mode_of_payment: item.paymentMode || [],
      coupon_applied: item.voucherCode || [],
      total_amount: ((item.orderSubTotal || item.transactionAmount) + item.transactionShippingCharge + (item.transactionCodFee || 0)) || 0,
      total_discount: item.transactionDiscount || 0,
      country: (item.address && item.address.country) || '',
      city: (item.address && item.address.city) || '',
      area: (item.address && item.address.area) || '',
      address1: (item.address && item.address.addressLine1) || '',
      address2: (item.address && item.address.addressLine2) || ''
    };
    const outerObj = {
      page: page || '',
      email: (authReducer && authReducer.email) || '',
      currency: (commonSettings && commonSettings.currencyCode) || '',
      countryId: (commonSettings && commonSettings.countryId) || '',
      countryName: (commonSettings && commonSettings.countryName) || '',
    };
    const actionField = {
      id: (order && order.orderId) || '',
      transactionAmount: (order && order.transactionAmount) || '',
      totalAmount: (order && (order.transactionAmount + order.transactionShippingCharge + (order.transactionCodFee || 0))) || '',
    };
    return { ecommerce, outerObj, actionField };
  },
  multipleItems: (items = [], authReducer, commonSettings, page, order) => {
    const ecommerce = items.map((item) => {
      return {
        quantity: item.quantity || '',
        product_code: item.pId || item.productId || item.id || '',
        product_name: item.title || '',
        brandId: item.brandId || '',
        brand: item.brandName || '',
        description: item.productDetails || '',
        sub_category_name: item.subcatid || '',
        price_before_discount: item.oldPrice || 0,
        price_after_discount: item.currentPrice || item.transactionPrice || item.price || 0,
        category: item['en_parentCategory'] || item['ar_parentCategory'] || '',
        color: (item.colors && item.colors.colorSizeOptions) || [],
        size: (item.colors && item.colors.colorSizeOptions) || [],
      }
    });
    const outerObj = {
      page: page || '',
      email: (authReducer && authReducer.email) || '',
      currency: (commonSettings && commonSettings.currencyCode) || '',
      countryId: (commonSettings && commonSettings.countryId) || '',
      countryName: (commonSettings && commonSettings.countryName) || '',

    };
    const actionField = {
      id: (order && order.orderId) || '',
      transactionAmount: (order && order.transactionAmount) || '',
      totalAmount: (order && (order.transactionAmount + order.transactionShippingCharge + (order.transactionCodFee || 0))) || '',
    };
    return { ecommerce, outerObj, actionField };
  },
}

export const getProductUrlName = (name = '') => {
  let urlName = name && name.replace(/\s+/g, '-').toLowerCase();
  urlName = urlName && urlName.replace(/&/g, 'and');
  urlName = urlName && urlName.replace(/,/g, '');
  urlName = urlName && urlName.replace(/%/g, '');
  urlName = urlName && urlName.replace(/\//g, '');
  urlName = urlName && urlName.replace(/#/g, '');
  return urlName;
};

export const removeSpecialCharacters = (name = '') => {
  let urlName = name && name.replace(/-/g, ' ').toLowerCase();
  return urlName;
};

export const shuffleElements = (array) => {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const RoundOffPoints = (points) => {
  var with2Decimals = points.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals
};

export const globalScrollHandler = (ref, apply) => {
  if (apply) {
    ref.ownerDocument.body.setAttribute("style", "overflow:hidden; position:fixed; top:0; left:0; width:100%; height:100%");
  } else {
    ref.ownerDocument.body.removeAttribute("style");
  }
};

export const handleScrollTop = (directSmooth) => {
  !isServer && window.scroll({
    top: 0,
    left: 0,
    behavior: directSmooth
  });
};

export const addAnimate = (item, isClicked, wishList, addClass) => {
  let isPresent = wishList && wishList.length && wishList.some(wishItem => wishItem.productId === item.id && (item.size || '' === wishItem.size));
  if (!isPresent && isClicked) {
    addClass = 'addAnimation';
  } else if (isPresent) {
    addClass = 'removeAnimation';
  }
  return addClass;
};

export const validateProduct = (checkout, item) => {
  const OrderItems = checkout && checkout.orderItems && Object.keys(checkout.orderItems).length && [...checkout.orderItems];
  let _OutOfStock = false, _priceChange = false, _quantityChanged = false
  OrderItems && OrderItems.length && OrderItems.forEach(orderItem => {
    if (item.productId === orderItem.productId && item.size === orderItem.size) {
      if (orderItem.currentStock === 0) {
        _OutOfStock = true
      } else if (orderItem.currentStock < item.quantity) {
        _quantityChanged = true
      } else if (orderItem.transactionPrice !== orderItem.currentPrice) {
        _priceChange = true
      }
    }
  });
  return ({ "_OutOfStock": _OutOfStock, "_priceChange": _priceChange, "_quantityChanged": _quantityChanged });
}

export const itemsInCart = (cartItems) => {
  let cartCount = 0;
  cartItems && cartItems.forEach(item => cartCount = cartCount + (+item.quantity));
  return cartCount;
}

export const filterMap = {
  'hierarchicalCategories.lvl0': 'categories-0',
  'hierarchicalCategories.lvl1': 'categories-1',
  'hierarchicalCategories.lvl2': 'categories-2',
  'attributes.Ideal For': 'ideal_for',
  'attributes.Occasion': 'occasion',
  'brandName': 'brand',
  'overlayTagLabel': 'promotion',
  'sizeHierarchicalFilter.lvl2': 'size',
  'attributes.Sportswear Type': 'sportswear_type',
  'attributes.Top Style': 'top_style',
  'attributes.Dress Style': 'dress_style',
  'attributes.Jumpsuit Type': 'jumpsuit_style',
  'attributes.Jacket style': 'jacket_style',
  'attributes.Sweater Style': 'sweater_style',
  'attributes.Lingerie Type': 'lingerir_type',
  'attributes.Bottom Fit': 'bottom_fit',
  'attributes.Type of Sport': 'type_sport',
  'attributes.Type of Short': 'type_short',
  'attributes.Type of Skirt': 'type_skirt',
  'attributes.Type of Sleepwear': 'type_sleepwear',
  'attributes.Type of Swimwear': 'type_swimwear',
  'attributes.Types of Watch': 'type_watch',
  'attributes.Types of Kitchen': 'type_kitchen',
  'attributes.Shirt Fit': 'shirt_fit',
  'attributes.Short Style': 'short_style',
  'attributes.Sleepwear Type': 'type_sleepwear',
  'attributes.Suit Type': 'type_suit',
  'attributes.Suit Fit': 'suit_fit',
  'attributes.Style': 'style',
  'attributes.Toe Style': 'toe_style',
  'attributes.Underwear Style': 'underwear_style',
  'attributes.Bedding Type': 'bedding_type',
  'attributes.Bath Accessories': 'bath_accessories',
  'attributes.Type of Decoration': 'decoration_type',
  'attributes.Decoration': 'deocration',
  'attributes.Heel Style': 'heel_style',
  'attributes.Type of Flooring': 'flooring_type',
  'attributes.Sleeve Length': 'sleeve_length',
  'attributes.Types of Neckline & Collar': 'neckline_collar',
  'attributes.Age Recommended': 'recommended_age',
  'attributes.Type of Action Toys': 'type_actiontoys',
  'attributes.Type of Arts & Creative Play': 'type_arts_creativeplay',
  'attributes.Type of Baby & Pre-School Toys': 'type_baby_preschooltoys',
  'attributes.Type of Construction Toys': 'type_constructiontoys',
  'attributes.Type of Doll': 'type_doll',
  'attributes.Type of Electronic Toys': 'type_electronictoys',
  'attributes.Type of Games & Puzzles': 'type_gamespuzzles',
  'attributes.Type of Outdoor Play': 'type_outdoorplay',
  'attributes.Type of Pretend Play & Dressing Up': 'type_pretend_dressingup',
  'attributes.Type of Soft Toys': 'type_softtoys',
  'attributes.Type of Vehicles & Remote Control Toys': 'type_vehicleremotecontroltoys',
  'attributes.Storage': 'storage',
  'attributes.Shoe Width': 'shoe_width',
  'attributes.Shoe Closure': 'shoe_closure',
  'attributes.Bag Strap/Handle': 'bag_straphandle',
  'attributes.Bag Lining Material': 'bag_liningmaterial',
  'attributes.Concentration': 'concentration',
  'attributes.Dial Shape': 'dial_shape',
  'attributes.Watch Feature': 'watch_feature',
  'attributes.Type of Kitchen': 'kitchen_type',
  'attributes.Bed Size': 'bed_size',
  'attributes.Frame Type': 'frame_type',
  'attributes.Frame Shape': 'frame_shape',
  'attributes.Lens Feature': 'lens_feature',
  'attributes.Lens Material': 'lens_material',
  'attributes.For Gift': 'forgift'
};

export const urlFilterMap = (url) => {

  if (!(url && url.includes('?'))) {
    return url;
  }

  let _url = url.split('?');

  url = _url[1];

  url = url && url.replace('categories-0', 'hierarchicalCategories.lvl0');
  url = url && url.replace('categories-1', 'hierarchicalCategories.lvl1');
  url = url && url.replace('categories-2', 'hierarchicalCategories.lvl2');
  url = url && url.replace('occasion', 'attributes.Occasion');
  url = url && url.replace('brand=', 'brandName=');
  url = url && url.replace('promotion=', 'overlayTagLabel=');
  url = url && url.replace('ideal_for', encodeURIComponent('attributes.Ideal For'));
  url = url && url.replace('bottom_fit', encodeURIComponent('attributes.Bottom Fit'));
  url = url && url.replace('dress_style', encodeURIComponent('attributes.Dress Style'));
  url = url && url.replace('jacket_style', encodeURIComponent('attributes.Jacket style'));
  url = url && url.replace('jumpsuit_style', encodeURIComponent('attributes.Jumpsuit Type'));
  url = url && url.replace('sweater_style', encodeURIComponent('attributes.Sweater Style'));
  url = url && url.replace('lingerir_type', encodeURIComponent('attributes.Lingerie Type'));
  url = url && url.replace('sportswear_type', encodeURIComponent('attributes.Sportswear Type'));
  url = url && url.replace('type_doll', encodeURIComponent('attributes.Type of Doll'));
  url = url && url.replace('type_sport', encodeURIComponent('attributes.Type of Sport'));
  url = url && url.replace('type_short', encodeURIComponent('attributes.Type of Short'));
  url = url && url.replace('type_skirt', encodeURIComponent('attributes.Type of Skirt'));
  url = url && url.replace('flooring_type', encodeURIComponent('attributes.Type of Flooring'));
  url = url && url.replace('type_swimwear', encodeURIComponent('attributes.Type of Swimwear'));
  url = url && url.replace('type_softtoys', encodeURIComponent('attributes.Type of Soft Toys'));
  url = url && url.replace('type_sleepwear', encodeURIComponent('attributes.Type of Sleepwear'));
  url = url && url.replace('type_actiontoys', encodeURIComponent('attributes.Type of Action Toys'));
  url = url && url.replace('type_outdoorplay', encodeURIComponent('attributes.Type of Outdoor Play'));
  url = url && url.replace('type_gamespuzzles', encodeURIComponent('attributes.Type of Games & Puzzles'));
  url = url && url.replace('type_electronictoys', encodeURIComponent('attributes.Type of Electronic Toys'));
  url = url && url.replace('type_constructiontoys', encodeURIComponent('attributes.Type of Construction Toys'));
  url = url && url.replace('type_arts_creativeplay', encodeURIComponent('attributes.Type of Arts & Creative Play'));
  url = url && url.replace('type_baby_preschooltoys', encodeURIComponent('attributes.Type of Baby & Pre-School Toys'));
  url = url && url.replace('type_pretend_dressingup', encodeURIComponent('attributes.Type of Pretend Play & Dressing Up'));
  url = url && url.replace('type_watch', encodeURIComponent('attributes.Types of Watch'));
  url = url && url.replace('type_kitchen', encodeURIComponent('attributes.Types of Kitchen'));
  url = url && url.replace('neckline_collar', encodeURIComponent('attributes.Types of Neckline & Collar'));
  url = url && url.replace('suit_fit', encodeURIComponent('attributes.Suit Fit'));
  url = url && url.replace('type_suit', encodeURIComponent('attributes.Suit Type'));
  url = url && url.replace('shirt_fit', encodeURIComponent('attributes.Shirt Fit'));
  url = url && url.replace('toe_style', encodeURIComponent('attributes.Toe Style'));
  url = url && url.replace('short_style', encodeURIComponent('attributes.Short Style'));
  url = url && url.replace('bedding_type', encodeURIComponent('attributes.Bedding Type'));
  url = url && url.replace('type_sleepwear', encodeURIComponent('attributes.Sleepwear Type'));
  url = url && url.replace('underwear_style', encodeURIComponent('attributes.Underwear Style'));
  url = url && url.replace('bath_accessories', encodeURIComponent('attributes.Bath Accessories'));
  url = url && url.replace('decoration_type', encodeURIComponent('attributes.Type of Decoration'));
  url = url && url.replace('storage', 'attributes.Storage');
  url = url && url.replace('bed_size', encodeURIComponent('attributes.Bed Size'));
  url = url && url.replace('forgift', encodeURIComponent('attributes.For Gift'));
  url = url && url.replace('deocration', 'attributes.Decoration');
  url = url && url.replace('shoe_width', encodeURIComponent('attributes.Shoe Width'));
  url = url && url.replace('heel_style', encodeURIComponent('attributes.Heel Style'));
  url = url && url.replace('dial_shape', encodeURIComponent('attributes.Dial Shape'));
  url = url && url.replace('frame_type', encodeURIComponent('attributes.Frame Type'));
  url = url && url.replace('frame_shape', encodeURIComponent('attributes.Frame Shape'));
  url = url && url.replace('shoe_closure', encodeURIComponent('attributes.Shoe Closure'));
  url = url && url.replace('lens_feature', encodeURIComponent('attributes.Lens Feature'));
  url = url && url.replace('lens_material', encodeURIComponent('attributes.Lens Material'));
  url = url && url.replace('sleeve_length', encodeURIComponent('attributes.Sleeve Length'));
  url = url && url.replace('concentration', 'attributes.Concentration');
  url = url && url.replace('watch_feature', encodeURIComponent('attributes.Watch Feature'));
  url = url && url.replace('recommended_age', encodeURIComponent('attributes.Age Recommended'));
  url = url && url.replace('kitchen_type', encodeURIComponent('attributes.Type of Kitchen'));
  url = url && url.replace('bag_straphandle', encodeURIComponent('attributes.Bag Strap/Handle'));
  url = url && url.replace('bag_liningmaterial', encodeURIComponent('attributes.Bag Lining Material'));
  url = url && url.replace('type_vehicleremotecontroltoys', encodeURIComponent('attributes.Type of Vehicles & Remote Control Toys'));
  url = url && url.replace('top_style', encodeURIComponent('attributes.Top Style'));
  url = url && url.replace('style', 'attributes.Style');
  url = url && url.replace('size=', 'sizeHierarchicalFilter.lvl2=');

  _url[1] = url;
  _url = _url.join('?');

  return _url;
};

export const reverseUrlFilterMap = (url) => {

  if (!(url && url.includes('?'))) {
    return url;
  }

  let _url = url.split('?');

  url = _url[1];

  url = url && url.replace('hierarchicalCategories.lvl0', 'categories-0');
  url = url && url.replace('hierarchicalCategories.lvl1', 'categories-1');
  url = url && url.replace('hierarchicalCategories.lvl2', 'categories-2');
  url = url && url.replace('attributes.Style', 'style');
  url = url && url.replace(encodeURIComponent('attributes.Top Style'), 'top_style');
  url = url && url.replace('attributes.Occasion', 'occasion');
  url = url && url.replace('brandName=', 'brand=');
  url = url && url.replace('overlayTagLabel=', 'promotion=');
  url = url && url.replace('sizeHierarchicalFilter.lvl2=', 'size=');
  url = url && url.replace(encodeURIComponent('attributes.Ideal For'), 'ideal_for');
  url = url && url.replace(encodeURIComponent('attributes.Bottom Fit'), 'bottom_fit');
  url = url && url.replace(encodeURIComponent('attributes.Dress Style'), 'dress_style');
  url = url && url.replace(encodeURIComponent('attributes.Jacket style'), 'jacket_style');
  url = url && url.replace(encodeURIComponent('attributes.Jumpsuit Type'), 'jumpsuit_style');
  url = url && url.replace(encodeURIComponent('attributes.Sweater Style'), 'sweater_style');
  url = url && url.replace(encodeURIComponent('attributes.Lingerie Type'), 'lingerir_type');
  url = url && url.replace(encodeURIComponent('attributes.Sportswear Type'), 'sportswear_type');
  url = url && url.replace(encodeURIComponent('attributes.Type of Doll'), 'type_doll');
  url = url && url.replace(encodeURIComponent('attributes.Type of Sport'), 'type_sport');
  url = url && url.replace(encodeURIComponent('attributes.Type of Short'), 'type_short');
  url = url && url.replace(encodeURIComponent('attributes.Type of Skirt'), 'type_skirt');
  url = url && url.replace(encodeURIComponent('attributes.Type of Flooring'), 'flooring_type');
  url = url && url.replace(encodeURIComponent('attributes.Type of Swimwear'), 'type_swimwear');
  url = url && url.replace(encodeURIComponent('attributes.Type of Soft Toys'), 'type_softtoys');
  url = url && url.replace(encodeURIComponent('attributes.Type of Sleepwear'), 'type_sleepwear');
  url = url && url.replace(encodeURIComponent('attributes.Type of Action Toys'), 'type_actiontoys');
  url = url && url.replace(encodeURIComponent('attributes.Type of Outdoor Play'), 'type_outdoorplay');
  url = url && url.replace(encodeURIComponent('attributes.Type of Electronic Toys'), 'type_electronictoys');
  url = url && url.replace(encodeURIComponent('attributes.Type of Games & Puzzles'), 'type_gamespuzzles');
  url = url && url.replace(encodeURIComponent('attributes.Type of Construction Toys'), 'type_constructiontoys');
  url = url && url.replace(encodeURIComponent('attributes.Type of Arts & Creative Play'), 'type_arts_creativeplay');
  url = url && url.replace(encodeURIComponent('attributes.Type of Baby & Pre-School Toys'), 'type_baby_preschooltoys');
  url = url && url.replace(encodeURIComponent('attributes.Type of Pretend Play & Dressing Up'), 'type_pretend_dressingup');
  url = url && url.replace(encodeURIComponent('attributes.Types of Watch'), 'type_watch');
  url = url && url.replace(encodeURIComponent('attributes.Types of Kitchen'), 'type_kitchen');
  url = url && url.replace(encodeURIComponent('attributes.Types of Neckline & Collar'), 'neckline_collar');
  url = url && url.replace(encodeURIComponent('attributes.Suit Fit'), 'suit_fit');
  url = url && url.replace(encodeURIComponent('attributes.Suit Type'), 'type_suit');
  url = url && url.replace(encodeURIComponent('attributes.Shirt Fit'), 'shirt_fit');
  url = url && url.replace(encodeURIComponent('attributes.Toe Style'), 'toe_style');
  url = url && url.replace(encodeURIComponent('attributes.Short Style'), 'short_style');
  url = url && url.replace(encodeURIComponent('attributes.Bedding Type'), 'bedding_type');
  url = url && url.replace(encodeURIComponent('attributes.Sleepwear Type'), 'type_sleepwear');
  url = url && url.replace(encodeURIComponent('attributes.Underwear Style'), 'underwear_style');
  url = url && url.replace(encodeURIComponent('attributes.Bath Accessories'), 'bath_accessories');
  url = url && url.replace(encodeURIComponent('attributes.Type of Decoration'), 'decoration_type');
  url = url && url.replace('attributes.Storage', 'storage');
  url = url && url.replace(encodeURIComponent('attributes.Bed Size'), 'bed_size');
  url = url && url.replace(encodeURIComponent('attributes.For Gift'), 'forgift');
  url = url && url.replace('attributes.Decoration', 'deocration');
  url = url && url.replace(encodeURIComponent('attributes.Shoe Width'), 'shoe_width');
  url = url && url.replace(encodeURIComponent('attributes.Heel Style'), 'heel_style');
  url = url && url.replace(encodeURIComponent('attributes.Dial Shape'), 'dial_shape');
  url = url && url.replace(encodeURIComponent('attributes.Frame Type'), 'frame_type');
  url = url && url.replace(encodeURIComponent('attributes.Frame Shape'), 'frame_shape');
  url = url && url.replace(encodeURIComponent('attributes.Shoe Closure'), 'shoe_closure');
  url = url && url.replace(encodeURIComponent('attributes.Lens Feature'), 'lens_feature');
  url = url && url.replace(encodeURIComponent('attributes.Lens Material'), 'lens_material');
  url = url && url.replace(encodeURIComponent('attributes.Sleeve Length'), 'sleeve_length');
  url = url && url.replace('attributes.Concentration', 'concentration');
  url = url && url.replace(encodeURIComponent('attributes.Watch Feature'), 'watch_feature');
  url = url && url.replace(encodeURIComponent('attributes.Age Recommended'), 'recommended_age');
  url = url && url.replace(encodeURIComponent('attributes.Type of Kitchen'), 'kitchen_type');
  url = url && url.replace(encodeURIComponent('attributes.Bag Strap/Handle'), 'bag_straphandle');
  url = url && url.replace(encodeURIComponent('attributes.Bag Lining Material'), 'bag_liningmaterial');
  url = url && url.replace(encodeURIComponent('attributes.Type of Vehicles & Remote Control Toys'), 'type_vehicleremotecontroltoys');

  _url[1] = url;
  _url = _url.join('?');

  return _url;
};

export const setFilterOrder = (array) => {
  let categories = []
  let attributes = []
  try {
    var returnArray = array.filter((item) => {
      if (item.includes('hierarchicalCategories')) {
        categories.push(item)
        return false
      }
      if (item.includes('attributes')) {
        attributes.push(item)
        return false
      }
      return true
    })
    categories = categories.concat(returnArray);
    returnArray = categories.concat(attributes)
    return returnArray
  } catch (error) {

  }
}

class UserAgentService {
  #isDesktopView = true;

  isDesktop = () => {
    return !!this.#isDesktopView;
  };

  setView = (userAgent = '') => {
    this.#isDesktopView = !(
      userAgent.match(/Android/i)
      || userAgent.match(/webOS/i)
      || userAgent.match(/iPhone/i)
      || userAgent.match(/iPad/i)
      || userAgent.match(/iPod/i)
      || userAgent.match(/BlackBerry/i)
      || userAgent.match(/Windows Phone/i)
      || userAgent.match(/Tablet/i)
      || userAgent.match(/Tablet PC/i)
    );
  };
}

export const UserAgentManager = new UserAgentService();

//compare whether two set are equal or not
export const eqSet = (as, bs) => {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}

export const getFormatedUnit = (data = []) => {
  const allUnit = []
  data.map((unit) => {
    allUnit.push({ key: unit.name, value: unit.value })
  })
  return allUnit
}