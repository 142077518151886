import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { submitAsset } from '../../actions/asset-management.action'
import Modal from '../../components/modal/modal'
import Form from 'react-bootstrap/Form'
import TextAreaInput from 'src/components/inputs/textarea-input'

const reasonOptions = [{
    text: 'Done',
    value: 'DONE',
}, {
    text: 'Damaged',
    value: 'DAMAGED',
}]

class AssetSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                reason: {
                    name: 'reason',
                    label: 'Reason for Submit',
                    type: 'radio',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    options: reasonOptions,
                    required: true,
                },
                submitDescription: {
                    name: 'submitDescription',
                    label: 'Submit Description',
                    type: 'textarea',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                },
            },
            submitDialog: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.submitDialog, this.props.submitDialog)) {
            const { fields } = this.state

            this.setState({
                submitDialog: this.props.submitDialog,
                fields: { reason: { ...fields.reason, value: '' }, submitDescription: { ...fields.submitDescription, value: '' } }
            })
        }
        if (!_.isEqual(prevProps.assetId, this.props.assetId)) {
            this.setState({ assetId: this.props.assetId })
        }
    }

    handleSubmitClick = async () => {
        const { assetId, fields } = this.state

        let isFormValid = true
        if (_.isEmpty(fields.reason.value)) {
            fields.reason.touched = true
            fields.reason.valid = false
            fields.reason.error = 'This is Required!'
            isFormValid = false
        }

        if (!isFormValid) {
            this.setState({ fields })
        } else {
            const data = {
                reason: fields.reason.value,
                submitDescription: fields.submitDescription.value || ''
            }

            await this.props.submitAsset(assetId, data)
            this.props.getAssetList()
        }
    }

    inputChangeHandler = async (e, fieldName = '') => {
        let fields = this.state.fields
        let name, value
        if (_.isObject(e)) {
            name = e.target.name
            value = e.target.value
        }
        fields[name].value = value
        fields[name].touched = true

        this.setState({ fields })
    }

    render() {
        const { fields, submitDialog } = this.state

        return (
            <Modal
                open={submitDialog}
                onClose={this.props.handleClose}
                onClick={this.handleSubmitClick}
                modalHeading={'Submit Asset'}
                modalSaveTxt={'Submit'}
                modalCloseTxt='Cancel'
                showActionBtn={true}
                fullWidth={true}
                maxWidth={'sm'}
                cxlbtnClass='cancelBtn'
                savebtnClass='createBtn'
                primary
            >
                <div className="purchase-order-block">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group text-left">
                                <label className="label-default">Reason for Submit</label>

                                <div className="input-block mt-0 d-flex">
                                    <Form>
                                        <div className="d-flex">
                                            <Form.Check
                                                className="me-3"
                                                type={'radio'}
                                                label='Done'
                                                id={`done`}
                                                name="reason"
                                                value={'DONE'}
                                                checked={fields.reason.value == 'DONE' ? true : false}
                                                onChange={(e) => this.inputChangeHandler(e, fields.reason.name)}
                                            />
                                            <Form.Check
                                                type={'radio'}
                                                id={`damaged`}
                                                label='Damaged'
                                                name="reason"
                                                value={'DAMAGED'}
                                                checked={fields.reason.value == 'DAMAGED' ? true : false}
                                                onChange={(e) => this.inputChangeHandler(e, fields.reason.name)}
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="input-block ">
                                <label className="input-label">{fields.submitDescription.label}</label>
                                <TextAreaInput
                                    {...fields.submitDescription}
                                    onChange={(e) => this.inputChangeHandler(e, fields.submitDescription.name)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    submitAsset: bindActionCreators(submitAsset, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetSubmit);
