import React from 'react'
import { connect } from 'react-redux'
import Modal from '../../components/modal/modal'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@material-ui/core'
import idx from 'idx'
import { warningSnackBar } from '../../actions/common.action'
import { getResourceAllocations, updateTimesheetTask } from '../../actions/timesheet.action'
import { getUserList } from '../../actions/user-management.action'
import { v4 as uuidv4 } from 'uuid'
import './scss/timesheet.scss'
import SelectInput from 'src/components/inputs/select'
import TimePickerInput from 'src/components/inputs/time-picker'
import CustomTable from 'src/components/table/table'

class AllocateTimesheetTask extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allAllocations: [],
            data: [],
            tasks: [],
            project: {},
            users: [],
            newRecord: 0,
            filter_value: 'unallocated_tasks',
            filterAllocations: [
                { key: 'Unallocated Tasks Only', value: 'unallocated_tasks' },
                { key: 'Allocated Tasks Only', value: 'allocated_tasks' },
                { key: 'All Tasks', value: 'all_tasks' }
            ]
        }
    }

    async componentDidMount() {
        const { selectedTask } = this.props
        // await this.getUsersList()
        await this.getWorkAllocations()
        const tasks = selectedTask.map((item) => {
            return {
                key: item.name || item.description,
                value: item.id,
                type: item.type
            }
        })

        this.setState({ tasks })
    }

    getWorkAllocations = async () => {
        const { projectId, projectType } = this.props
        let params = {
            projectId,
            projectType,
            updateTaskAllocation: true
        }
        const resourceAllocations = await this.props.getResourceAllocations(params)
        await this.getUsersList()
        let project = idx(resourceAllocations, _ => _.length) ? resourceAllocations[0].project : {}
        const allAllocations = resourceAllocations.filter(allocation => allocation.status === 'APPROVED').map((allocation) => {
            allocation['userName'] = this.getUserName(allocation.userId)
            allocation['isAllocated'] = allocation.taskId ? true : false
            return allocation
        })
        this.setState({ project, allAllocations })
        this.handleFilter(this.state.filter_value)
    }

    getUsersList = async () => {
        const list = await this.props.getUserList()
        const users = (Array.isArray(list) && list.map(({ id, firstName, lastName }) => ({ key: (firstName + ' ' + lastName), value: id }))) || []
        this.setState({ users })
    }

    getColumns = () => {
        const { tasks } = this.state
        return ([
            {
                title: 'User',
                field: 'userName'
            },
            {
                title: 'Task',
                field: 'taskId',
                render: rowData => (
                    <SelectInput
                        label={'Select Task'}
                        value={rowData.taskId}
                        disabled={rowData.disable}
                        touched={rowData.touched}
                        valid={rowData.valid}
                        error={rowData.task_error}
                        items={tasks}
                        onChange={(e) => this.handleChange(rowData.id, rowData.parentId, 'taskId', e.target.value)}
                    />
                )
            },
            {
                title: 'Date',
                field: 'startTime',
                render: rowData => `${moment(rowData.startTime).format('ddd, DD-MM-YYYY')}`
            },
            {
                title: 'Start Time',
                field: 'startTime',
                render: rowData => (
                    <TimePickerInput
                        format='LT'
                        value={rowData.startTime}
                        touched={rowData.touched}
                        valid={rowData.valid}
                        error={!rowData.task_error && rowData.error}
                        disabled={!rowData.parentId}
                        onChange={(value) => this.handleChange(rowData.id, rowData.parentId, 'startTime', value)}
                    />
                )
            },
            {
                title: 'Finish Time',
                field: 'endTime',
                render: (rowData) => (
                    <TimePickerInput
                        format='LT'
                        value={rowData.endTime}
                        touched={rowData.touched}
                        valid={rowData.valid}
                        error={!rowData.task_error && rowData.error}
                        disabled={!rowData.parentId}
                        onChange={(value) => this.handleChange(rowData.id, rowData.parentId, 'endTime', value)}
                    />
                )
            },
            {
                title: 'Actions',
                field: '',
                render: rowData => (<IconButton sx={{ p: 0 }} aria-label="add" onClick={() => this.addTimesheetData(rowData)}>
                    {
                        rowData.parentId || (rowData.isAllocated && rowData.taskId) ? <DeleteIcon /> : <AddBoxIcon />
                    }
                </IconButton>)
            },
        ])
    }

    addTimesheetData = (inp) => {
        let { data, allAllocations } = this.state
        if (inp.isAllocated && inp.taskId) {
            let updatedData = data.map(item => {
                if (item.id === inp.id) {
                    item['taskId'] = null
                    item['taskType'] = null
                    item['taskName'] = null
                    item['disable'] = false
                    item['is_parent'] = false
                    item['valid'] = true
                    item['error'] = ''
                    item['task_error'] = ''
                }
                return item
            })
            this.setState({ data: updatedData })
        } else if (inp.parentId) {
            let updatedData = data.filter(item => item.id !== inp.id)
            let updatedAllAllocations = allAllocations.filter(item => item.id !== inp.id)
            let childCount = updatedData.filter(item => item.parentId === inp.parentId)
            if (childCount.length < 1) {
                updatedData = updatedData.map(item => {
                    if (item.id === inp.parentId) {
                        item['taskId'] = null
                        item['taskType'] = null
                        item['taskName'] = null
                        item['disable'] = false
                        item['is_parent'] = false
                        item['valid'] = true
                        item['error'] = ''
                        item['task_error'] = ''
                    }
                    return item
                })
            }
            this.setState({ data: updatedData, allAllocations: updatedAllAllocations })
        } else {
            let updatedData = data.map(item => {
                if (item.id === inp.id) {
                    item['taskId'] = null
                    item['taskType'] = null
                    item['taskName'] = null
                    item['disable'] = true
                    item['is_parent'] = true
                    item['valid'] = true
                    item['error'] = ''
                    item['task_error'] = ''
                }
                return item
            })
            inp['parentId'] = inp.id
            inp.id = uuidv4()
            inp.inline_item = true
            inp['disable'] = false
            inp['is_parent'] = false
            inp['valid'] = true
            inp['touched'] = false
            inp['error'] = ''
            inp['task_error'] = ''
            inp['tableData']['isTreeExpanded'] = true
            updatedData.push(inp)
            this.setState({ data: updatedData })
        }
    }

    addError = (id, status, message, type) => {
        let { data } = this.state
        let updatedData = data.map(item => {
            if (item.id === id) {
                item['touched'] = true
                item['valid'] = status
                item['error'] = !status ? message : ''
                if (type) item[`${type}_error`] = !status ? message : ''
            }
            return item
        })
        this.setState({ data: updatedData })
    }

    handleChange = (id, parentId = null, name, value) => {
        let { data, tasks } = this.state
        let updatedData = data.map(item => {
            if (item.id === id) {
                if (name === 'taskId') {
                    let task = tasks.find(task => task.value === value)
                    item[name] = value
                    item.taskType = task.type
                    item.taskName = task.key
                } else {
                    item[name] = moment.utc(value).format('YYYY-MM-DDTHH:mmZ').toString()
                }
            }
            if (parentId && name === 'taskId' && item.id === parentId) item[name] = ''
            return item
        })
        this.setState({ data: updatedData })
    }

    handleValidation = () => {
        const { data } = this.state
        let filterData = data.filter(timesheet => {
            if (!timesheet.taskId && !timesheet.is_parent && !timesheet.parentId) {
                this.addError(timesheet.id, true, ``, 'task')
            } else if (timesheet.parentId && !timesheet.taskId) {
                this.addError(timesheet.id, false, ``, 'task')
            } else if (!timesheet.valid) {
                this.addError(timesheet.id, true, ``, 'task')
            }
            return timesheet.taskId
        })

        let timesheets = {}
        for (let timesheet of filterData) {
            if (timesheet.parentId) {
                if (!timesheets[timesheet.parentId]) {
                    let parent = data.find(item => item.id === timesheet.parentId)
                    timesheets[timesheet.parentId] = {
                        id: parent.id,
                        startTime: parent.startTime,
                        endTime: parent.endTime,
                        allocations: filterData.filter(item => item.parentId === parent.id),
                    }
                }
            } else {
                timesheets[timesheet.id] = {
                    allocations: [timesheet]
                }
            }
        }
        for (let [index, time] of Object.entries(timesheets)) {
            console.info(index)
            if (time.id) {
                let valid = true
                let hrs = 0
                let tot_hrs = moment(time.endTime).diff(moment(time.startTime)) || 0
                let ids = []
                time.allocations.forEach(item => {
                    hrs += moment(item.endTime).diff(moment(item.startTime));
                    let status = this.checkTimeRangeSlot(time.startTime, time.endTime, item.startTime, item.endTime)
                    valid = valid && status ? true : false
                    item['valid'] = status
                    this.addError(item.id, status, `Match Parent Time`)
                    ids.push(item.id)
                })
                if (valid) {
                    for (let sheet of time.allocations) {
                        for (let sheet_1 of time.allocations) {
                            if (sheet.id !== sheet_1.id) {
                                let status = this.checkForAllocation(sheet_1.startTime, sheet_1.endTime, sheet.startTime, sheet.endTime)
                                valid = valid && !status ? true : false
                                this.addError(sheet.id, !status, `Time Conflict`)
                            }
                        }
                    }
                }
                if (valid && (tot_hrs !== hrs)) {
                    valid = false;
                    ids.map(item => {
                        this.addError(item, false, `Allocate full Time`)
                    })
                }

            }
        }
    }

    submitData = async () => {
        await this.handleFilter('all_tasks')
        this.handleValidation()
        const { data } = this.state
        let req = { allocations: [] }

        let valid = true
        for (let timesheet of data) {
            if (timesheet.hasOwnProperty('valid')) {
                valid = valid && timesheet.valid
            }
            if (timesheet.valid && timesheet.taskId)
                req.allocations.push({
                    id: timesheet.inline_item ? '' : timesheet.id,
                    userId: timesheet.userId,
                    projectId: timesheet.projectId,
                    projectType: timesheet.projectType,
                    taskId: timesheet.taskId,
                    taskType: timesheet.taskType,
                    taskName: timesheet.taskName,
                    startTime: timesheet.startTime,
                    endTime: timesheet.endTime,
                    parentId: timesheet.parentId ? timesheet.parentId : '',
                    allDayWithBreaks: timesheet.allDayWithBreaks,

                })
        }
        if (!valid || !req.allocations.length) return
        await this.props.updateTimesheetTask(req)
        this.props.submitData()
    }

    checkForAllocation = (currStartTime, currEndTime, startTime, endTime) => {
        return (moment(currStartTime).isBetween(startTime, endTime)
            || moment(currEndTime).isBetween(startTime, endTime) ||
            moment(startTime).isBetween(currStartTime, currEndTime) ||
            moment(endTime).isBetween(currStartTime, currEndTime) ||
            moment(currStartTime).isSame(startTime) ||
            moment(currEndTime).isSame(endTime)
        )
    }

    checkTimeRangeSlot = (startTime, endTime, currStartTime, currEndTime) => {
        return (
            (moment(currStartTime).isBetween(startTime, endTime, null, [])
                && moment(currEndTime).isBetween(startTime, endTime, null, []))
        )
    }

    getUserName = (id) => {
        const { users } = this.state
        if (!id || !users) return ''
        const user = users.find(user => user.value === id)
        return user?.key || ''
    }

    handleFilter = (value) => {

        let { allAllocations, filter_value, data } = this.state
        filter_value = value
        allAllocations = allAllocations.filter(all => !data.find(filter => filter.id === all.id))
        allAllocations = [...allAllocations, ...data]
        let _temp = allAllocations.filter(allocation => {
            if (filter_value === 'all_tasks') {
                return allocation
            } else if (filter_value === 'allocated_tasks') {
                return (allocation.taskId || allocation.is_parent) ? allocation : null
            } else {
                return !allocation.taskId ? allocation : null
            }
        })
        let _data = data.filter(allocation => {
            if (filter_value === 'all_tasks') {
                return allocation
            } else if (filter_value === 'allocated_tasks') {
                return (allocation.taskId || allocation.is_parent) ? allocation : null
            } else {
                return !allocation.taskId ? allocation : null
            }
        })
        _data = [..._data, ..._temp]
        data = [...new Set(_data)]
        this.setState({ data, filter_value, allAllocations })
    }

    render() {
        const { open, dialogClose } = this.props
        const { data, project, filterAllocations, filter_value } = this.state

        return (
            <Modal
                open={open}
                onClose={dialogClose}
                fullWidth
                maxWidth='md'
                modalHeading={`Confirm Allocations - ${idx(project, _ => _.name) || ''}`}
                modalSaveTxt={'Confirm'}
                modalCloseTxt='Cancel'
                onClick={this.submitData}
                savebtnClass='createBtn'
                showActionBtn={true}
                primary
            >
                <div className="purchase-order-block">

                    <div className="row justify-content-end">
                        <div className="col-6 d-flex mb-2">
                            <SelectInput
                                label={'Filter'}
                                value={filter_value}
                                items={filterAllocations}
                                onChange={e => this.handleFilter(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Grid container>

                            <Grid item sm={12}>
                                <div className='tableContainer'>
                                    <CustomTable
                                        columns={this.getColumns()}
                                        data={data}
                                        toolbar={false}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
    getUserList: bindActionCreators(getUserList, dispatch),
    getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
    updateTimesheetTask: bindActionCreators(updateTimesheetTask, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllocateTimesheetTask)
