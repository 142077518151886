import React from "react";
import { connect } from "react-redux";
import AddTask from "./add-task";
import { bindActionCreators } from "redux";
import { PROJECT_MANAGEMENT } from "../../constants/route-paths";
import {
  getProject,
  downloadProjectVariation,
} from "../../actions/project-management.action";
import {
  getCategories,
  getItems,
  getLevel,
} from "./../../actions/inventory.action";
import {
  getQuotationList,
  getOptions,
} from "./../../actions/project-management.action";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ProposalDialog from "./proposal-dialog";
import _ from "lodash";
import idx from "idx";
import { numberFormat } from "../../util/currency-formatter";
import DownloadDialog from "../../components/download-dialog";
import { getGlobalSettingTemplateTypes } from "./../../actions/global-setting-template.action";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { taskCalculation } from "src/util/calculations/calculate-task";
import { clearTaskAction } from "src/slice/add-task";

class AddVariation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      taskDialogState: false,
      action: "new",
      optionId: "",
      isOption: false,
      editOption: false,
      categories: [],
      items: [],
      labors: [],
      templateOptions: [],
      proposalDialogState: false,
      projectId: "",
      projectName: "",
      customerName: "",
      projectDescription: "",
      downloadData: {
        open: false,
        id: "",
        template: "",
      },
    };
  }

  componentDidMount() {
    this.setState({
      projectId: this.props.params.projectId,
    });
    this.props.getCategories().then((categories) => {
      this.setState({ categories });
    });
    this.props.getItems().then((items) => {
      this.setState({ items });
    });
    this.props.getProject(this.props.params.projectId).then((project) => {
      this.getLevels(project.level);
      this.setState({
        projectName: project.name,
        projectDescription: project.description,
        projectType: project.projectType,
        customerName: idx(project, (_project) => _project.customer.businessName) || "",
      });
      this.getOptions();
    });
  }

  proposalDialogHandler = () => {
    if (this.state.proposalDialogState) {
      this.getOptions();
    }
    this.setState((prevState) => ({
      proposalDialogState: !prevState.proposalDialogState,
    }));
  };

  optionHandler = (value, index) => {
    const options = this.state.options;
    options[index].selected = value;
    this.setState({ options });
  };

  createOptionRevision = (id) => {
    this.optionDialogHandler(id, true, true, "revise");
  };

  getOptions = () => {
    const projectId = this.props.params.projectId;
    if (projectId) {
      this.props.getOptions(projectId).then((res) => {
        let sno = 0;
        let tempArray = [];
        res.forEach((option) => {
          option.revisionNumber =
            option.taskRevision && option.taskRevision[0].RevisionNumber;
          option.revisionId =
            option.taskRevision && option.taskRevision[0].RevisionId;
          option.revisionName =
            option.taskRevision && option.taskRevision[0].revisionName;
          option.subTasks = option.subTasks ? option.subTasks : [];
          tempArray.push(option);
        });
        const sortedtempArray = _.chain(tempArray)
          .groupBy("revisionId")
          .map((revisions, revisionId) => {
            const sortedRevisions =
              _.sortBy(revisions, [(o) => new Date(o.createdAt || null)]) || [];
            return { revisionId, revisions: sortedRevisions };
          })
          .sortBy((o) => o.revisions[0].createdAt)
          .map((data) => data.revisions)
          .value()
          .flat(1);
        const uniqueIdArr = _.uniqBy(sortedtempArray, "revisionId");
        sortedtempArray.forEach((option, optionIndex) => {
          let index = _.findIndex(uniqueIdArr, function (item) {
            return item.id === option.id;
          });
          if (index !== -1) {
            option.revision = true;
            option.selected = true;
            sno += 1;
          } else {
            option.selected = false;
          }
          option.sno = sno;
        });

        this.setState({ options: sortedtempArray });
      });
    }
  };

  getLevels = (levelId) => {
    this.props.getLevel(levelId).then((level) => {
      const labors = [];
      level.hourlyCost &&
        level.hourlyCost.forEach((cost) => {
          labors.push({
            ...cost,
            calloutCost: level.calloutCost,
            travelCost: level.travelCost,
          });
        });
      this.setState({ labors });
    });
  };

  cancelHandler = () => {
    this.props.history(
      `/${PROJECT_MANAGEMENT}/variation/${this.props.params.projectId}`
    );
  };

  optionDialogHandler = (
    optionId = "",
    isOption = false,
    editOption = false,
    action = "new"
  ) => {
    this.props.clearTaskAction();
    this.setState((prevState) => ({
      action,
      optionId,
      isOption,
      editOption,
      taskDialogState: !prevState.taskDialogState,
    }));
  };
  gettemplate = async () => {
    let templateOptions = this.state;
    let newdata = [];
    let type = "ProjectVariation";
    const templateData = await this.props.getGlobalSettingTemplateTypes(type);
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    this.setState({ templateOptions });
  };
  handleDownloadDialog = (id = "", template = "") => {
    const { downloadData = {} } = this.state;
    downloadData.open = !downloadData.open;
    downloadData.id = id;
    downloadData.template = template;
    this.gettemplate();
    this.setState({ downloadData });
  };

  downloadFile = async (fileFormat = "pdf", tempId) => {
    const { options } = this.state;

    if (Array.isArray(options)) {
      if (fileFormat === "pdf" || fileFormat === "ALL") {
        await Promise.all(
          options.map(async (item) => {
            if (item.selected)
              await this.props.downloadProjectVariation(item.id, fileFormat, tempId).catch(e => console.error(e));
            return;
          })
        );
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        await Promise.all(
          options.map(async (item) => {
            if (item.selected)
              await this.props.downloadProjectVariation(item.id, fileFormat, tempId).catch(e => console.error(e));
            return;
          })
        );
      }
    }
    this.handleDownloadDialog();
  };

  viewProjectList = () => {
    const { projectType } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  viewProject = () => {
    const { projectId } = this.props.params;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      options,
      categories,
      items,
      labors,
      taskDialogState,
      optionId,
      action,
      editOption,
      projectName,
      customerName,
      templateOptions,
      projectDescription,
      proposalDialogState,
      downloadData,
    } = this.state;
    const { projectId } = this.props.params;
    return (
      <div className="variationContainer">
        <DownloadDialog
          {...downloadData}
          downloadFile={this.downloadFile}
          handleDownloadDialog={this.handleDownloadDialog}
          templateOptions={templateOptions}
          downloadModuleName="Variation"
        />
        {taskDialogState && <AddTask
          projectId={projectId}
          open={taskDialogState}
          dialogClose={() => this.optionDialogHandler()}
          categories={categories}
          items={items}
          isOption={true}
          optionId={optionId}
          editOption={editOption}
          action={action}
          labors={labors}
          isVariation={true}
          getOptions={this.getOptions}
        />}
        {proposalDialogState && <ProposalDialog
          projectName={projectName}
          customerName={customerName}
          projectId={projectId}
          projectDescription={projectDescription}
          open={proposalDialogState}
          dialogClose={() => this.proposalDialogHandler()}
          options={options}
          isVariation={true}
          handleDownloadDialog={this.handleDownloadDialog}
        />}
        <div className="w-auto">
          <h2 className="content-heading text-uppercase">Variations</h2>
          <div className="col-12 breadcrumb-block p-0">
            <Breadcrumb>
              <Breadcrumb.Item onClick={this.viewProjectList}>PROJECTS</Breadcrumb.Item>
              <Breadcrumb.Item onClick={this.viewProject}>{projectName}</Breadcrumb.Item>
              <Breadcrumb.Item active>Add Variation</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="material-list-block mt-3">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch">
                  <tbody>
                    {options.map((option, optionIndex) => {
                      let calculation = taskCalculation({ subtasks: option.subTasks, profitMargin: option.profitMargin, profitMarginType: option.profitMarginType })
                      return (
                        <tr key={option.id}>
                          <td>
                            {/* <Input
                                type="checkbox"
                                value={option.selected}
                                onChange={(e) =>
                                  this.optionHandler(e.target.checked, optionIndex)
                                }
                              /> */}
                          </td>
                          <td>
                            <div onClick={() => this.optionDialogHandler(option.id, true, true)}>
                              <span>{option.name}</span>
                              {option.revisionNumber !== 1 ? " - " + option.revisionName : ""}
                            </div>
                          </td>
                          <td className="text-right">
                            {numberFormat(calculation.total)}
                          </td>
                          <td className="text-right">
                            <Tooltip title="Create Revision">
                              <span
                                onClick={() => this.createOptionRevision(option.id)}
                              >
                                <EditIcon />
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

            </div>


          </div>
          <div className="col-12 d-flex mt-5">
            <Button
              type="submit"
              className="ms-auto me-3 secondarybtn"
              onClick={() => this.optionDialogHandler()}
            >
              Create Variation
            </Button>
            <Button
              type="submit"
              className="ms-auto me-3 secondarybtn"
              onClick={() => this.proposalDialogHandler()}
            >
              View Variation
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(
    getGlobalSettingTemplateTypes,
    dispatch
  ),
  getItems: bindActionCreators(getItems, dispatch),
  getOptions: bindActionCreators(getOptions, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getLevel: bindActionCreators(getLevel, dispatch),
  getQuotationList: bindActionCreators(getQuotationList, dispatch),
  downloadProjectVariation: bindActionCreators(
    downloadProjectVariation,
    dispatch
  ),
  clearTaskAction: bindActionCreators(clearTaskAction, dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddVariation)
);
