import React, { useEffect, useState } from 'react';
import './../modal/modal.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { getTaskTemplates } from 'src/actions/template-management.action';
import CustomTable from '../table/table';
import Form from 'react-bootstrap/Form'
import { toUSD } from 'src/util/currency-formatter';
import { addSubTask, addTask } from 'src/actions/project-management.action';
import { subTaskCalculation } from 'src/util/calculations/calculate-sub-task';
import { calculateSaleAmount } from 'src/util/calculations/sale-amount';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import idx from 'idx';
import { getLaborList } from 'src/actions/inventory.action';
import { addResourcesAction } from 'src/slice/resources';

function ListTaskTemplate({ open, dialogClose, saveHandler, stageId, profitMargin, profitMarginType = 'PERCENTAGE', ...props }) {
    const [state, setState] = useState({
        columns: [
            {
                title: "Name", field: "isSelected", class: "w-auto fw-bold",
                render: (rowData) => (
                    <div className="table-checkbox table-checkbox-ch text-left">
                        <Form.Check
                            id={rowData.id}
                            type={'checkbox'}
                            label={rowData.name}
                            value={rowData.isSelected}
                            checked={Boolean(rowData.isSelected)}
                            onChange={(e) => selectHandler(rowData.id, e.target.checked)}
                        />
                    </div>
                )
            },
            {
                title: "No of Items", field: "material_total_cost", class: 'w-auto fw-bold text-right',
                render: (rowData) => <>{(rowData.subTasks || []).length}</>
            },
            {
                title: "Material", field: "material_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.material_total_cost)}</>
            },
            {
                title: "Resource", field: "labor_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.labor_total_cost)}</>
            },
            {
                title: "Contractor", field: "subcontractor_total_cost", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(rowData.subcontractor_total_cost)}</>
            },
            {
                title: "Total", field: "total", class: 'fw-bold text-right',
                render: (rowData) => <>{toUSD(parseFloat(rowData.material_total_cost || 0) + parseFloat(rowData.labor_total_cost || 0) + parseFloat(rowData.subcontractor_total_cost || 0))}</>
            },
            {
                title: "Quantity", field: "", class: 'fw-bold',
                render: (rowData) => (
                    <div className="input-block input-block-sm mt-0">
                        <input
                            // disabled={true}
                            className="form-control mt-0" type="number" placeholder=""
                            name='selectedQty'
                            value={rowData.selectedQty}
                            onChange={(e) => {
                                if (e.target.value >= 0) changeHandler(rowData.id, e.target)
                            }}
                        />
                    </div>
                )
            },
        ],
        taskTemplates: [],
        error: ''
    })

    const dispatch = useDispatch()
    const { projectDetail = {}, resources } = useSelector(state => state)
    useEffect(() => {
        init();
    }, [])

    const init = () => {
        dispatch(getTaskTemplates()).then((res) => {
            let items = res.map(item => {
                return { ...item, selectedQty: '', isSelected: false }
            })
            setState(prev => ({
                ...prev,
                taskTemplates: items
            }));
        }).catch(e => console.error(e))
        if (!resources.list.length) _getLaborList();
    }

    const _getLaborList = () => {
        dispatch(getLaborList()).then((data) => {
            let labors = (data || []).map((labor, i) => {
                labor.sno = i + 1;
                return labor;
            })
            dispatch(addResourcesAction(labors));
        }).catch(e => console.error(e))
    };

    const selectHandler = (id, checked) => {
        setState(prev => ({
            ...prev,
            taskTemplates: prev.taskTemplates.map(item => {
                if (item.id == id) {
                    item.isSelected = checked;
                    item.selectedQty = checked ? (Number(item.selectedQty) > 0 ? item.selectedQty : 1) : ''
                }
                return item
            })
        }));
    }

    const changeHandler = (id, { name, value }) => {
        setState(prev => ({
            ...prev,
            taskTemplates: prev.taskTemplates.map(item => {
                if (item.id == id) item[name] = value;
                return item
            })
        }));
    }

    const closeDialog = () => {
        if (dialogClose) dialogClose()
    }

    const addHandler = async () => {
        let { taskTemplates = [] } = state;
        let selected = taskTemplates.filter(item => item.isSelected && parseInt(item.selectedQty) > 0)
        if (selected.length < 1) {
            setState(prev => ({
                ...prev,
                error: 'Please select one Task with quantity of minimum 1'
            }));
        } else { // Enable this flow for add task automatically
            if (!stageId) {
                setState(prev => ({
                    ...prev,
                    error: 'Unable to add task to your project. Please navigate from project and try again.'
                }));
                return;
            }
            for await (let _task of selected) {
                let { subTasks = [], selectedQty = 1, name } = _task || {};

                let taskId = '';
                let taskReq = {
                    name: name,
                    profitMargin: profitMargin,
                    marginType: profitMarginType,
                }
                await dispatch(addTask(taskReq, stageId)).then((res) => {
                    taskId = res.id;
                });
                if (!taskId) {
                    setState(prev => ({
                        ...prev,
                        error: 'Unable to add task to your project. Please try again.'
                    }));
                    return;
                }
                for await (let subtask of subTasks) {
                    await createSubTask(subtask, taskId, selectedQty)
                }
            }
            if (saveHandler) saveHandler()
        }
        // Enable this flow for add task through regular flow
        // else if (selected.length > 1) {
        //     setState(prev => ({
        //         ...prev,
        //         error: 'Please select only one Task with quantity of minimum 1'
        //     }));
        // } else if (selected.length == 1) {
        //     setState(prev => ({
        //         ...prev,
        //         error: ''
        //     }));
        //     let { subTasks = [], selectedQty = 1, ...rest } = selected[0] || {};
        //     subTasks = subTasks.map(subtask => {
        //         subtask['selectedQty'] = selectedQty;
        //         return subtask;
        //     })
        //     props.addHandler({ ...rest, subTasks, selectedQty })
        // }
    }

    const filterLabor = (id) => {
        let laborsList = idx(projectDetail, _ => _.labors) || [];
        let labor = laborsList.find((labor) => labor.id === id);
        if (!labor) {
            let allLabors = idx(resources, _ => _.list) || [];
            labor = allLabors.find((labor) => labor.id === id);
            if (!labor) return {};
            labor = _.cloneDeep(labor)
            labor['calloutCost'] = 0;
            labor['averageCost'] = labor.cost;
            labor['skillDescription'] = ''
        }
        return labor;
    };

    const createSubTask = async (subtask, taskId, selectedQty = 1) => {
        let { material = [], labor = [], subcontractor = [] } = subtask || {}
        material = material.map(item => {
            item = _.isString(item) ? JSON.parse(item) : item
            let qty = selectedQty * Number(item.qty);
            let sale = item.saleAmountLock ? calculateSaleAmount({ cost: item.cost, profitMargin, profitMarginType }) : Number(item.cost);
            return JSON.stringify({
                ...item,
                qty: qty,
                saleAmount: sale,
                total: qty * sale,
            })
        });
        labor = labor.map(item => {
            item = _.isString(item) ? JSON.parse(item) : item
            const selectedLabor = filterLabor(item.id);
            let cost = Number(item.cost || 0);
            let avgCost = parseFloat(selectedLabor.averageCost || 0);
            let qty = selectedQty * Number(item.qty);
            return JSON.stringify({
                ...item,
                averageCost: avgCost || 0,
                qty: qty,
                total: qty * cost,
            })
        });
        subcontractor = subcontractor.map(item => {
            item = _.isString(item) ? JSON.parse(item) : item
            let sale = item.saleAmountLock ? calculateSaleAmount({ cost: item.Cost, profitMargin, profitMarginType }) : Number(item.Cost);
            return JSON.stringify({
                ...item,
                Cost: Number(item.Cost) * selectedQty,
                saleAmount: selectedQty * sale,
                total: selectedQty * sale,
            })
        })
        let calculation = subTaskCalculation({
            subtask: { ...subtask, material, labor, subcontractor },
            profitMargin: profitMargin,
            profitMarginType: profitMarginType,
        })
        let req = {
            description: subtask.description,
            profitMargin: profitMargin,
            marginType: profitMarginType,
            labor_total_cost: calculation.labor.cost || 0,
            laborTotal_SaleAmount: calculation.labor.sale || 0,
            labor_profit: calculation.labor.profit || 0,
            materialTotal_Cost: parseFloat(calculation.material.cost || 0),
            materialTotal_SaleAmount: parseFloat(calculation.material.sale || 0),
            material_Profit: parseFloat(calculation.material.profit || 0),
            subcontractorTotal_Cost: calculation.subcontractor.cost || 0,
            subcontractorTotal_SaleAmount: calculation.subcontractor.sale || 0,
            subcontractor_Profit: calculation.subcontractor.profit || 0,
            profitMarginCost: calculation.total_profit || 0,
            totalProfit: parseFloat(calculation.total_profit || 0),
            material: material,
            labor: labor,
            subcontractor: subcontractor
        }

        await dispatch(addSubTask(req, taskId)).then((res) => { });
    }

    return (
        <Modal
            show={open}
            onHide={closeDialog}
            dialogClassName="modal-70w create-modal"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Select Task Template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="purchase-order-block mt-3">
                    <CustomTable
                        exportButton={false}
                        columns={state.columns}
                        data={state.taskTemplates}
                    />
                    {state.error && <div className="col-12 d-flex text-danger">
                        {state.error}
                    </div>}
                    <div className="col-12 d-flex mt-3">
                        <Button onClick={addHandler} className="ms-auto me-3 secondarybtn">Add</Button>
                        <Button onClick={closeDialog} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ListTaskTemplate;
