import React, { useEffect, useState } from "react";
import { getPurchaseOrder } from "src/actions/project-management.action";
import idx from "idx";
import { numberFormat } from "src/util/currency-formatter";
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { useDispatch } from "react-redux";

function ViewPurchaseOrderComponent({ poId }) {
    const [PO, setPO] = useState({});
    const [vendorType, setvendorType] = useState();
    const [total, setTotal] = useState(0);
    const [attachments, setAttachments] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        if (poId) getPO();
    }, [poId]);

    const getPO = async () => {
        const query = `?poId=${poId}`;
        let res = await dispatch(getPurchaseOrder(query));
        setPO(res);
        setvendorType(idx(res, (_) => _.vendor.type));
        let totalCost = 0;
        Array.isArray(res.items) && res.items.forEach(({ total }) => (totalCost += total));
        setTotal(totalCost);
        const attachment = res && res.attachment.map(item => {
            return (
                <div className="attachedFiles">
                    <p style={{ margin: '0 10px 0 0' }}>{item.name}</p>
                </div>
            )
        })
        setAttachments(attachment)
    };

    return (
        <>
            <div className="row">
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Purchase Order Number</label>
                        <p>{PO.id}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Vendor Type</label>
                        <p>{vendorType}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Vendor Name</label>
                        <p>{idx(PO, (_) => _.vendor.business_name) || "-"}</p>
                    </div>
                </div>
                {idx(PO, _ => _.rfq.description) && <div className="col-lg-4 mt-3">
                    <div className="view-block">
                        <label>Description</label>
                        <p>{idx(PO, _ => _.rfq.description)}</p>
                    </div>
                </div>}
                {idx(PO, _ => _.quotation.id) && <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Quote Request No</label>
                        <p>{idx(PO, _ => _.quotation.id)}</p>
                    </div>
                </div>}
                {PO.quoteNo && <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Quote No</label>
                        <p>{PO.quoteNo}</p>
                    </div>
                </div>}
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Delivery Option</label>
                        <p>{idx(PO, (_) => _.deliveryOption) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Delivery Date</label>
                        <p>{idx(PO, (_) => _.deliveryDate) ? moment(idx(PO, (_) => _.deliveryDate)).format('YYYY-MM-DD HH:mm') : "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Notes</label>
                        <p>{idx(PO, (_) => _.notes) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Street Address</label>
                        <p>{idx(PO, (_) => _.street_address) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Suburb</label>
                        <p>{idx(PO, (_) => _.suburb) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>State</label>
                        <p>{idx(PO, (_) => _.state) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Postcode</label>
                        <p>{idx(PO, (_) => _.postcode) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Country</label>
                        <p>{idx(PO, (_) => _.country) || "-"}</p>
                    </div>
                </div>
                <div className="col-4 mt-3">
                    <div className="view-block">
                        <label>Total</label>
                        <p>{numberFormat(total)}</p>
                    </div>
                </div>
                <div className="col-lg-4 mt-3">
                    <div className="view-block">
                        <label>Attachments</label>
                        <p>{attachments}</p>
                    </div>
                </div>
            </div>

            <div className="material-list-block mt-3">
                <div className="table-responsive">
                    <Table bordered className="table-create table-material table-material-ch">
                        <thead>
                            <tr>
                                <th className="w-auto">Item</th>
                                <th className="w-auto">Manufacturer</th>
                                <th>Part No</th>
                                <th>Qty</th>
                                <th className="text-right">Quotation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(PO.items || []).map((item) => (
                                <tr key={item.id}>
                                    <td>{item.item}</td>
                                    <td>{item.manufacturer}</td>
                                    <td>{item.partNo}</td>
                                    <td>{item.qty}</td>
                                    <td className="text-right">{numberFormat(item.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}

export default ViewPurchaseOrderComponent;
