import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/inventory.scss";
import {
  INVENTORY,
  ADD_STOCK,
  TRANSACTIONS,
  CREATE_RFQ,
} from "./../../constants/route-paths";
import {
  getItems,
  getTransactionsList,
} from "./../../actions/inventory.action";
import { getAssetList } from "./../../actions/asset-management.action";
import { withRouter } from "../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SelectInput from "src/components/inputs/select";
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: [],
      actions: [],
      itemId: null,
      filter: "total",
      vehicles: [],
      transactions: [],
      selectedVehicle: null,
    };
  }

  componentDidMount() {
    this.getItems();
    this.props.getAssetList("VEHICLE").then((assets) => {
      const data = [];
      Array.isArray(assets) &&
        assets
          .filter((asset) => asset.status !== "DISCARD")
          .forEach((asset) => {
            data.push({
              key: asset.assetNo,
              value: asset.id,
              stock: 0,
            });
          });
      this.setState({ vehicles: data });
    });
    const columns = [
      { title: "S No", field: "sno" },
      { title: "Item", field: "item" },
      { title: "Catalog", field: "categoryName" },
      { title: "Manufacturer", field: "manufacturer" },
      { title: "Part No.", field: "partNo" },
      { title: "Reserve Stock", field: "reservedStock" },
      { title: "Internal Stock", field: "deStock" },
    ];

    this.setState({
      columns: columns,
    });
  }

  handleMenu = (itemId) => {
    this.setState({ itemId });
  };

  getItems = () => {
    this.props.getItems().then((items) => {
      const data = [];
      items &&
        items.forEach((item, index) => {
          const categoryName = (item.category && item.category.name) || "";
          data.push({
            sno: index + 1,
            categoryName,
            ...item,
          });
        });
      this.setState({
        items: data,
      });
    });
  };

  addStock = () => {
    this.props.history(`/${INVENTORY}/${ADD_STOCK}`);
  };

  transactions = () => {
    this.props.history(`/${INVENTORY}/${TRANSACTIONS}`);
  };

  allocateItem = () => {
    this.props.history(`/${INVENTORY}/allocate-item/${this.state.itemId}`);
  };

  createRFQ = () => {
    this.props.history(`/${INVENTORY}/${CREATE_RFQ}`);
  };

  viewStock = () => {
    this.props.history(`/${INVENTORY}/po-stock/${this.state.itemId}`);
  };

  overAllStock = () => {
    this.props.history(`/${INVENTORY}/overall-stock/${this.state.itemId}`);
  };

  selectVehicleHandler = (e) => {
    this.setState({ selectedVehicle: e.target.value }, () =>
      this.calculateStock(this.state.transactions, "vehicle")
    );
  };

  calculateStock = (transactions, type) => {
    const { items, selectedVehicle } = this.state;
    items.forEach((item) => {
      let reservedStock = 0;
      let deStock = 0;
      const itemTransaction = transactions.filter(
        (transaction) => transaction.itemId === item.id
      );
      if (Array.isArray(itemTransaction)) {
        itemTransaction.forEach((transaction) => {
          if (type !== "vehicle") {
            if (transaction.transactionType === "RECEIVED") {
              if (transaction.poId === "NO_PO") {
                deStock += transaction.quantity;
              } else {
                reservedStock += transaction.quantity;
              }
            } else if (type === "warehouse") {
              if (transaction.receivedFrom === "WAREHOUSE") {
                if (transaction.poId === "NO_PO") {
                  deStock -= transaction.quantity;
                } else {
                  reservedStock -= transaction.quantity;
                }
              }
              if (transaction.location === "WAREHOUSE") {
                if (transaction.poId === "NO_PO") {
                  deStock += transaction.quantity;
                } else {
                  reservedStock += transaction.quantity;
                }
              }
            }
          } else {
            if (
              transaction.transactionType === "RECEIVED" &&
              selectedVehicle === transaction.assetId
            ) {
              if (transaction.poId === "NO_PO") {
                deStock += transaction.quantity;
              } else {
                reservedStock += transaction.quantity;
              }
            } else if (transaction.transactionType === "ALLOCATED") {
              if (
                transaction.receivedFrom === "VEHICLE" &&
                selectedVehicle === transaction.receivedFromAsset
              ) {
                if (transaction.poId === "NO_PO") {
                  deStock -= transaction.quantity;
                } else {
                  reservedStock -= transaction.quantity;
                }
              }
              if (
                transaction.location === "VEHICLE" &&
                selectedVehicle === transaction.assetId
              ) {
                if (transaction.poId === "NO_PO") {
                  deStock += transaction.quantity;
                } else {
                  reservedStock += transaction.quantity;
                }
              }
            }
          }
        });
      }
      item.reservedStock = reservedStock;
      item.deStock = deStock;
    });
    this.setState({
      items,
      filter: type,
    });
  };

  filterItems = (type) => {
    if (type === "total") {
      this.getItems();
      this.setState({
        filter: type,
      });
    } else {
      this.props
        .getTransactionsList(type.toUpperCase())
        .then((transactions) => {
          this.setState({ transactions }, () =>
            this.calculateStock(transactions, type)
          );
        });
    }
  };

  deleteDialogHandler = (id = "") => {
    this.setState((prevState) => ({
      open: !prevState.open,
      deleteId: id,
    }));
  };

  render() {
    const { columns, items, vehicles, selectedVehicle } = this.state;

    return (
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Inventory</h2>
          </div>

          {this.props.writePermission && (<div className="w-auto ms-auto mt-auto">
            <button onClick={this.transactions} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Inventory Transactions</button>
            {/* <button onClick={this.createRFQ} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Create RFQ</button> */}
            <button onClick={this.addStock} className="secondarybtn secondarybtn-outline btn-ch ms-2 btn btn-primary">Add Stock</button>
          </div>)}
        </div>

        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="total"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(k) => {
                switch (k) {
                  case 'total': this.filterItems('total'); break;
                  case 'warehouse': this.filterItems('warehouse'); break;
                  case 'vehicle': this.filterItems('vehicle'); break;
                  default: break;
                }
              }}
            >
              <Tab eventKey="total" title={`Total Items (${items.length})`}>
                <CustomTable
                  columns={columns}
                  data={items}
                  isAction={this.props.writePermission}
                  handleMenu={(data) => this.handleMenu(data.id)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={this.allocateItem}>Allocate Item</li>
                          <li onClick={this.viewStock}>View PO</li>
                          <li onClick={this.overAllStock}>View Stock Location</li>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Total Items List'
                />
              </Tab>
              <Tab eventKey="warehouse" title={`Ware House (${items.length})`}>
                <CustomTable
                  columns={columns}
                  data={items}
                  isAction={this.props.writePermission}
                  handleMenu={(data) => this.handleMenu(data.id)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={this.allocateItem}>Allocate Item</li>
                          <li onClick={this.viewStock}>View PO</li>
                          <li onClick={this.overAllStock}>View Stock Location</li>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Warehouse Items List'
                />
              </Tab>
              <Tab eventKey="vehicle" title={`Vehicle (${vehicles.length})`}>
                <div className="col-3">
                  <SelectInput
                    label='Select Vehicle'
                    items={vehicles}
                    onChange={this.selectVehicleHandler}
                    value={selectedVehicle}
                  />
                </div>
                <CustomTable
                  columns={columns}
                  data={items}
                  isAction={this.props.writePermission}
                  handleMenu={(data) => this.handleMenu(data.id)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          <li onClick={this.allocateItem}>Allocate Item</li>
                          <li onClick={this.viewStock}>View PO</li>
                          <li onClick={this.overAllStock}>View Stock Location</li>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='Vehicle List'
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getItems: bindActionCreators(getItems, dispatch),
  getTransactionsList: bindActionCreators(getTransactionsList, dispatch),
  getAssetList: bindActionCreators(getAssetList, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersList)
);
