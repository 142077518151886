import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import '../project-management/scss/task.scss'
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import SelectInput from "src/components/inputs/select";
import { bindActionCreators } from "redux";
import { getRolesList, getUserList } from "src/actions/user-management.action";
import idx from "idx";
import { allocateSchedule, getResourceAllocations, getUserLeaves } from "src/actions/timesheet.action";
import { getGlobalSettingReducer } from "src/actions/global-setting.action";
import { warningSnackBar } from "src/actions/common.action";
import { getProject, getProjectList } from "src/actions/project-management.action";
import _ from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { differenceInHours, format } from "date-fns";

function ScheduleAllocateResource({ show, handleClose, handleSubmit, userData = [], allocations = {}, ...props }) {

    const [fields, setFields] = useState({
        projectType: {
            name: 'projectType',
            label: 'Select Project Type',
            type: 'select',
            items: [],
            value: ''
        },
        projectId: {
            name: 'projectId',
            label: 'Select Project',
            type: 'select',
            items: [],
            value: ''
        },
        task: {
            name: 'task',
            type: 'select',
            label: 'Select Task',
            items: [],
            value: ''
        },
        items: {
            projects: [],
            tasks: {}
        }
    })
    const [data, setData] = useState([])
    const [showDetail, setShowDetail] = useState('');
    const [detail, setDetail] = useState({});
    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const { projectCategories, user } = props
        let projectType = [];
        let permissions = user && user.permissions;

        let list = await props.getProjectList(null, 'PROJECT', null)
        list = (Array.isArray(list) && list.map(project => ({ key: project.name, value: project.id, type: project.projectType }))) || []
        list = _.orderBy(list, [({ key }) => key.toLowerCase()], ['asc'])
        let projects = []

        for await (const item of projectCategories) {
            if (permissions[`projectManagement${item.type}`] > 0 || (user.global_user && user.selected_account.id)) {
                projectType.push({
                    key: item.name,
                    value: item.type
                })
                projects.push(...list.filter(_list => _list.type == item.type))
            }
        }


        setFields(prev => ({
            ...prev,
            projectType: { ...prev.projectType, items: projectType },
            items: {
                ...prev.items,
                projects: projects,
            }
        }));
        if (!data.length) {
            let _data = userData.map(user => {
                let _allocations = new Set();
                let days = new Set(), hours = 0, leave = new Set(), conflicts = new Set();
                (allocations[user.value] || []).map(allocation => {
                    if ((user.dates || []).includes(allocation.allocationId)) {
                        if (allocation.allocationId.startsWith('tmp-')) {
                            hours += Math.abs(differenceInHours(allocation.start, allocation.end))
                            allocation.type = 'WORK';
                        }
                        days.add(format(allocation.start, 'yyyy-MM-dd'))
                        if (allocation.type == 'LEAVE') {
                            leave.add(allocation)
                        } else if (allocation.type == 'WORK' && !allocation.allocationId.startsWith('tmp-')) {
                            conflicts.add(allocation)
                        }
                        _allocations.add(allocation)
                    }
                })
                return {
                    ...user,
                    allocations: [..._allocations],
                    days: [...days],
                    hours,
                    cost: Number(hours) * Number(user.rate),
                    leave: [...leave],
                    conflicts: [...conflicts]
                }
            })
            setData(_data)
        }
    }

    const updateData = (_temp = [], { projectId, taskId }, prevProject, prevTask) => {
        let _data = _temp.map(user => {
            let { allocations = [] } = user;
            let days = new Set(), hours = 0, leave = new Set(), conflicts = new Set();
            allocations = allocations.map(allocation => {
                days.add(format(allocation.start, 'yyyy-MM-dd'))
                if (allocation.type == 'LEAVE') {
                    leave.add(allocation)
                } else if (allocation.type == 'WORK') {
                    if (allocation.allocationId.startsWith('tmp-')) {
                        hours += Math.abs(differenceInHours(allocation.start, allocation.end))
                    } else {
                        conflicts.add(allocation)
                    }
                    if (projectId && (allocation.projectId == prevProject || !allocation.projectId)) {
                        let project = (idx(fields, _ => _.items.projects) || []).find(item => item.value == projectId) || {}

                        allocation.projectId = projectId;
                        allocation.projectType = project.type;
                        allocation.taskId = ''
                    }
                    if (taskId && (allocation.taskId == prevTask || !allocation.taskId)) allocation.taskId = taskId;
                }
                return allocation;
            })
            return {
                ...user,
                allocations: allocations,
                days: [...days],
                hours,
                cost: Number(hours) * Number(user.rate),
                leave: [...leave],
                conflicts: [...conflicts]
            }
        })
        setData(_data)
    }

    useEffect(() => {

    }, [data])

    // const getProjectList = async (value) => {
    //     let projects = idx(fields, _ => _.items.projects[value]) || [];
    //     if (projects.length) return;

    //     const { projectCategories } = props
    //     let list
    //     if (projectCategories.find(o => o.type === value)) {
    //         list = await props.getProjectList(null, 'PROJECT', null, value)
    //     }
    //     list = (Array.isArray(list) && list.map(project => ({ key: project.name, value: project.id }))) || []
    //     list = _.orderBy(list, [({ key }) => key.toLowerCase()], ['asc'])
    //     setFields(prev => ({
    //         ...prev,
    //         projectType: { ...prev.projectType, value: value },
    //         projectId: { ...prev.projectId, value: '' },
    //         task: { ...prev.task, value: '' },
    //         items: {
    //             ...prev.items,
    //             projects: { ...prev.items.projects, [value]: _.cloneDeep(list) },
    //         }
    //     }));
    // }

    const handleProjectChange = async (value, skip = false) => {
        let _tasks = idx(fields, _ => _.items.tasks[value]) || [];
        if (_tasks.length) return;

        let _data = await props.getProject(value)
        let tasks = await getStages(_data.stages, _data.hasStages)
        const variations = getOptions(_data.options)
        tasks = [...tasks, ...variations]
        setFields(prev => ({
            ...prev,
            ...(skip ? {} : { projectId: { ...prev.projectId, value: value } }),
            ...(skip ? {} : { task: { ...prev.task, value: '' } }),
            items: {
                ...prev.items,
                tasks: { ...prev.items.tasks, [value]: _.cloneDeep(tasks) },
            }
        }));
    }

    const getStages = async (stages, hasStages) => {
        let tasksList = []
        Array.isArray(stages) && stages.forEach(({ tasks, id, name, description }) => {
            hasStages && tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'STAGE', subHeader: true })
            Array.isArray(tasks) && tasks.forEach(({ id, name, subTasks, description, material }) => {
                tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'TASK' })
                /* Array.isArray(subTasks) && subTasks.forEach(({ id, name, description, material }) => {
                  tasksList.push({ key: name, value: id, taskDescription: description, taskType: 'SUBTASK' })
                }) */
            })
        })
        return tasksList
    }

    const getOptions = (options) => {
        const variations = []
        Array.isArray(options) && options.map(({ id, name, proposal_status }) => {
            if (proposal_status === 'APPROVED') {
                variations.push({ key: name, value: id, taskType: 'VARIATION' })
            }
        })
        if (variations.length) {
            variations.unshift({ key: 'Variations', subHeader: true })
        }
        return variations
    }

    const inputChangeHandler = (value, type) => {
        setFields(prev => ({
            ...prev,
            [type]: { ...prev[type], value: value },
        }));
    }

    const detailChangeHandler = (index, name, value) => {
        let _allocations = _.cloneDeep(detail.allocations)
        _allocations[index][name] = value;
        if (name == 'type' && value == 'LEAVE') {
            _allocations[index]['projectId'] = '';
            _allocations[index]['projectType'] = '';
            _allocations[index]['taskId'] = '';
        } else if (name == 'projectId') {
            handleProjectChange(value, true)
            let project = (idx(fields, _ => _.items.projects) || []).find(item => item.value == value) || {}
            _allocations[index]['projectType'] = project?.type || _allocations[index]['projectType'];
        }
        setDetail(prev => ({
            ...prev,
            allocations: _allocations
        }));
    }

    const submitData = () => {
        let user = []
        for (const item of data) {
            let works = item.allocations.filter(item => item.type == 'WORK')
            let leaves = item.allocations.filter(item => item.type == 'LEAVE')
            user.push({
                id: item.value,
                leaves: leaves.map(leave => {
                    return {
                        id: leave.allocationId.startsWith('tmp-') ? '' : leave.allocationId,
                        startTime: format(leave.start, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                        endTime: format(leave.end, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                        type: 'LONG_TERM',
                        previousType: leave.previousType || '',
                    }
                }),
                allocations: works.map(work => {
                    let tasks = idx(fields, _ => _.items.tasks[work.projectId]) || []
                    let task = tasks.find(item => item.value == work.taskId) || {}
                    return {
                        id: work.allocationId.startsWith('tmp-') ? '' : work.allocationId,
                        projectId: work.projectId,
                        projectType: work.projectType,
                        taskId: task.value,
                        taskType: task.taskType,
                        taskName: task.key,
                        startTime: format(work.start, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                        endTime: format(work.end, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                        allDayWithBreaks: true,
                        hourlyRate: Number(item.rate),
                        previousType: work.previousType || '',
                    }
                }),
            })
        }

        props.allocateSchedule({ users: user }).then(data => {
            if (handleSubmit) {
                handleSubmit()
            } else {
                handleClose()
            }
        })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <Modal.Header closeButton className="border-0 modal-top-header-info">
                    <div className="d-flex w-100 header-content">
                        <h2 className="my-auto">Confirm Allocations</h2>
                        {/* <button type="submit" class=" secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Project</button>
                         <button type="submit" class="secondarybtn secondarybtn-outline btn-ch ms-2 btn btn-primary">Task</button> */}
                    </div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="material-list-block mt-0">
                        <div className="table-responsive">
                            <Table bordered className="table-create table-material table-material-ch">
                                <thead>
                                    <tr>
                                        <th className="w-auto"></th>
                                        <th className="w-auto">Day</th>
                                        <th>Hours</th>
                                        <th>Cost</th>
                                        <th>Leave</th>
                                        <th>Conflicts</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(item => {
                                        return (
                                            <tr key={item.value}>
                                                <td>{item.key}</td>
                                                <td>{(item.days || []).length}</td>
                                                <td>{item.hours || 0}</td>
                                                <td>${item.cost || 0} </td>
                                                <td>
                                                    <span className="allocation-table-status leave">
                                                        {(item.leave || []).length}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="allocation-table-status conflicts">
                                                        {(item.conflicts || []).length}
                                                    </span>
                                                </td>
                                                <td className="text-center">
                                                    <VisibilityIcon onClick={() => {
                                                        setShowDetail(item.value)
                                                        setDetail(item)
                                                    }} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="project-task-dropdown">
                            <div className="row">
                                {/* <div className="col-lg-3">
                                    <SelectInput
                                        {...fields.projectType}
                                        onChange={(e) => getProjectList(e.target.value)}
                                    />
                                </div> */}
                                <div className="col-lg-4">
                                    <SelectInput
                                        {...fields.projectId}
                                        items={idx(fields, _ => _.items.projects) || []}
                                        onChange={(e) => {
                                            updateData(data, { projectId: e.target.value }, fields.projectId.value, '')
                                            handleProjectChange(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-5">
                                    <SelectInput
                                        {...fields.task}
                                        items={idx(fields, _ => _.items.tasks[fields.projectId.value]) || []}
                                        onChange={(e) => {
                                            updateData(data, { taskId: e.target.value }, '', fields.task.value)
                                            inputChangeHandler(e.target.value, e.target.name)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex w-auto mt-auto ms-auto">
                            {fields.projectId.value && <button onClick={submitData} class="ms-auto me-3 secondarybtn btn btn-primary">CONFIRM</button>}
                            <button onClick={handleClose} class="secondarybtn cancelbtn me-auto btn btn-primary">CANCEL</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {showDetail && <Modal show={showDetail} size="xl" onHide={() => setShowDetail('')}>
                <Modal.Header closeButton className="border-0 modal-top-header-info">
                    <div className="d-flex w-100 header-content">
                        <h2 className="my-auto">Details</h2>
                    </div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="material-list-block mt-0">
                        <div className="table-responsive">
                            <Table bordered className="table-create table-material w-100">
                                <thead>
                                    <tr>
                                        <th className="w-auto">Date</th>
                                        <th>Allocation Type</th>
                                        <th>Project</th>
                                        <th>Task</th>
                                        <th>Status</th>
                                        <th className="w-auto">Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(idx(detail, _ => _.allocations) || []).map((allocation, index) => {

                                        return (<tr>
                                            <td>{format(allocation.start, 'dd/MM/yyyy')} {format(allocation.start, 'hh:mm a')} - {format(allocation.end, 'hh:mm a')}</td>
                                            <td>
                                                <SelectInput
                                                    className="input-block-sm"
                                                    label={'Select Type'}
                                                    items={[
                                                        { key: 'Work', value: 'WORK' },
                                                        { key: 'Leave', value: 'LEAVE' }
                                                    ]}
                                                    value={allocation.type}
                                                    onChange={(e) => detailChangeHandler(index, 'type', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <SelectInput
                                                    className="input-block-sm"
                                                    label={'Select Project'}
                                                    value={allocation.projectId}
                                                    items={idx(fields, _ => _.items.projects) || []}
                                                    disabled={allocation.type == 'LEAVE' ? true : false}
                                                    onChange={(e) => detailChangeHandler(index, 'projectId', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <SelectInput
                                                    className="input-block-sm"
                                                    label={'Select Task'}
                                                    value={allocation.taskId}
                                                    items={idx(fields, _ => _.items.tasks[allocation.projectId]) || []}
                                                    disabled={allocation.type == 'LEAVE' ? true : false}
                                                    onChange={(e) => detailChangeHandler(index, 'taskId', e.target.value)}
                                                />
                                            </td>
                                            <td>{allocation.display}</td>
                                            <td>{allocation.allocationId.startsWith('tmp-') ? '' : allocation.previousType}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div class="d-flex w-auto mt-auto ms-auto">
                            <button
                                onClick={() => {
                                    let _data = data.map(user => {
                                        if (user.value == showDetail) user.allocations = idx(detail, _ => _.allocations) || []
                                        return user;
                                    })
                                    setData(_data)
                                    setShowDetail('')
                                    updateData(_data, {})
                                }}
                                class="ms-auto me-3 secondarybtn btn btn-primary"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>}
        </>
    );

}



const mapStateToProps = (state) => ({
    user: state.user.userData,
    globalSetting: state.globalSetting,
    projectCategories: state.project.projectCategories,
});

const mapDispatchToProps = (dispatch) => ({
    getUserList: bindActionCreators(getUserList, dispatch),
    getRolesList: bindActionCreators(getRolesList, dispatch),
    getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
    getResourceLeaves: bindActionCreators(getUserLeaves, dispatch),
    getGlobalSettingReducer: bindActionCreators(getGlobalSettingReducer, dispatch),
    allocateSchedule: bindActionCreators(allocateSchedule, dispatch),
    // allocateResource: bindActionCreators(allocateResource, dispatch),
    // deleteResource: bindActionCreators(deleteResource, dispatch),
    // editTimesheet: bindActionCreators(editTimesheet, dispatch),
    // applyLeave: bindActionCreators(applyLeave, dispatch),
    warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
    getProjectList: bindActionCreators(getProjectList, dispatch),
    getProject: bindActionCreators(getProject, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAllocateResource);