import { combineReducers } from 'redux'
import common from './common.reducer'
import user from './user.reducer'
import purchaseOrder from './purchase-order.reducer'
import navbarNotification from './notification.reducer'
import project from './project.reducer'
import globalSetting from './global-setting.reducer'
import { reducer as notificationsReducer } from 'reapop'
import taskReducer from '../slice/add-task';
import resourcesReducer from '../slice/resources';
import projectDetailReducer from '../slice/project-detail';
import customerReducer from '../slice/customer'
import dashboardReducer from '../slice/dashboard';
import subscriptionSlice from 'src/slice/subscription.slice';

const rootReducer = combineReducers({
  common,
  user,
  notifications: notificationsReducer(),
  navbarNotification,
  purchaseOrder,
  project,
  globalSetting,
  task: taskReducer,
  resources: resourcesReducer,
  projectDetail: projectDetailReducer,
  customerData: customerReducer,
  dashboard: dashboardReducer,
  subscription: subscriptionSlice
})

export default rootReducer
