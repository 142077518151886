import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './../user-management/scss/user-management.scss'
import { updatePassword } from './../../actions/user-management.action'
import _ from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { emailValidator } from '../../util/validators/email'
import CustomInput from 'src/components/inputs/new-input';
import Button from 'react-bootstrap/Button';
import { passwordValidator } from 'src/util/validators/password';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                password: {
                    name: 'password',
                    label: 'Current Password',
                    type: 'password',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    validation: ['required']
                },
                newPassword: {
                    name: 'newPassword',
                    label: 'New Password',
                    type: 'password',
                    touched: false,
                    error: false,
                    valid: true,
                    value: '',
                    validation: ['required']
                }
            }
        }
    }

    componentDidMount() {

    }

    inputChangeHandler = (e, fieldName = '') => {
        let fields = this.state.fields
        fields[e.target.name].value = e.target.value
        fields[e.target.name].touched = true
        const { error, valid } = this.handleValidation(e.target, fields[e.target.name].validation)
        fields[e.target.name].error = error
        fields[e.target.name].valid = valid
        let isFormValid = true
        for (let key of Object.keys(this.state.fields)) {
            isFormValid = isFormValid && !fields[key].error
        }
        this.setState({ fields, isFormValid })
    }

    handleValidation = ({ name, type, value }, validation = []) => {
        let error = false
        let valid = true
        if (validation.includes('required') && (value === undefined || (!_.isBoolean(value) && value.trim() === ''))) {
            return { error: 'This Field is Required', valid: false }
        }
        if (name === 'email') {
            let status = emailValidator(value)
            if (!status) {
                return { error: 'Please Enter Valid Email', valid: false }
            }
        }

        if (name === 'phoneNumber') {
            if (!isValidPhoneNumber(value) && value) {
                return { error: 'Please Enter Valid Mobile Number' }
            }
        }

        if (type === 'password' && value.trim() !== '' && name === 'newPassword') {
            let status = passwordValidator(value)
            return status;
        }
        return { error, valid }
    }

    submitForm = () => {
        const { fields } = this.state
        let isFormValid = true
        for (let key in fields) {
            const { error, valid } = this.handleValidation(fields[key], fields[key].validation)
            fields[key].touched = true
            fields[key].valid = valid
            fields[key].error = error
            isFormValid = isFormValid && valid
        }
        if (!isFormValid) {
            this.setState({
                fields
            })
        } else {
            const data = {
                current: fields.password.value,
                new: fields.newPassword.value
            }
            this.props.updatePassword(data).then(res => { }).catch(e => console.log(e))
        }
    }

    render() {
        const { fields } = this.state

        return (
            <div className="purchase-order-block">

                <h2 className="content-heading text-uppercase">Reset Password</h2>

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <CustomInput
                            {...fields.password}
                            onChange={(e) => this.inputChangeHandler(e, fields.password.name)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <CustomInput
                            {...fields.newPassword}
                            onChange={(e) => this.inputChangeHandler(e, fields.newPassword.name)}
                        />
                    </div>
                </div>

                <div className="col-12 d-flex mt-3">
                    <Button onClick={this.submitForm} className="ms-auto me-3 secondarybtn">DONE</Button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user: state.user.userData
});

const mapDispatchToProps = dispatch => ({
    updatePassword: bindActionCreators(updatePassword, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
