import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CATALOGS, RESOURCES } from "../../../constants/route-paths";
import {
  addLevel,
  getLevels,
  updateLevel,
  deleteLevel,
  getLaborList,
  getLevel,
} from "./../../../actions/inventory.action";
import { withRouter } from "../../../util/with-router";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import CustomInput, { AmountInput, LockInput } from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";
import { levelFields } from "./data/level-fields";
import _ from 'lodash';
import { toPercent, toUSD } from "src/util/currency-formatter";
import idx from 'idx';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

function AddLevel({ writePermission, ...props }) {
  const [fields, setFields] = useState(_.cloneDeep(levelFields))
  const [state, setState] = useState({
    levelId: null,
    edit: false,
    costObj: {
      laborId: "",
      skill: "",
      hourlyCost: "",
      averageCost: "",
      averageCostLock: true,
      type: "amount",
      selected: "",
      label: "Hourly Cost",
      touched: false,
      valid: true,
      error: false,
    },
    labors: [],
  })

  useEffect(() => {
    getLaborList();
  }, [])

  const getLevel = () => {
    if (props.match && props.params.levelId) {
      props.getLevel(props.params.levelId, true).then((labor) => {
        editLevel(labor);
      });
    }
  };

  const getLaborList = () => {
    props.getLaborList().then((laborsList) => {
      const labors = [];
      laborsList && laborsList.forEach((labor) => {
        const costObj = _.cloneDeep(state.costObj)
        if (labor.status == "ACTIVE") {
          costObj.skill = labor.skill;
          costObj.laborId = labor.id;
          costObj.selected = true;
          costObj.averageCost = labor.cost || '';
          costObj.default_cost = labor.cost;
          // costObj.averageCostLock = true;
          labors.push(costObj);
        }
      });
      setState(prev => ({
        ...prev,
        labors
      }));
      getLevel();
    });
  };

  const inputChangeHandler = (e) => {
    if (e.target.type === "number") {
      if (parseFloat(e.target.value || 0) < 0) {
        return;
      }
    }
    const { error, valid } = handleValidation(e.target, fields[e.target.name].validation);
    setFields(prev => ({
      ...prev,
      [e.target.name]: {
        ...prev[e.target.name],
        value: e.target.value,
        touched: true,
        error: error,
        valid: valid
      },
    }));
  };

  const costChangeHandler = (e, index) => {
    let labors = _.cloneDeep(state.labors);
    if (e.target.type === "number") {
      if (parseFloat(e.target.value || 0) < 0) {
        return;
      }
    }
    if (e.target.name == "averageCost") {
      labors[index].averageCost = e.target.value;
    }
    if (e.target.name == "averageCostLock") {
      labors[index].averageCostLock = e.target.value;
      if (e.target.value === true) {
        labors[index].averageCost = labors[index].default_cost
      }
    }
    if (e.target.name == "cost") {
      labors[index].cost = e.target.value;
    }
    if (e.target.type === "checkbox") {
      labors[index].selected = e.target.checked;
    } else {
      labors[index].value = e.target.value;
      labors[index].touched = true;
      labors[index].valid = true;
      if (labors[index].selected) {
        const { error, valid } = handleValidation(e.target, ["required"]);
        labors[index].error = error;
        labors[index].valid = valid;
      }
    }
    labors[index].profit = Number(labors[index].cost) > 0 ? (Number(labors[index].cost || 0) - Number(labors[index].averageCost || 0)) : ''
    setState(prev => ({
      ...prev,
      labors
    }));
  };

  const handleValidation = ({ name, type, value }, validation = []) => {
    let error = false;
    let valid = true;
    if (validation.includes("required") && (value === undefined || value === "")) {
      return { error: "This Field is Required", valid: false };
    } else if (name === "description" && value && value.length > 255) {
      return { error: "Max 255 Characters!", valid: false };
    }
    return { error, valid };
  };

  const editLevel = (rowData) => {
    setState(prev => ({
      ...prev,
      showList: false,
      levelId: rowData.id,
      labors: (prev.labors || []).map((labor) => {
        const data = rowData.hourlyCost && rowData.hourlyCost.find((laborCost) => labor.laborId === laborCost.id);
        if (data) {
          labor.value = data.cost;
          labor.cost = data.cost;
          labor.averageCost = data.averageCost ?? labor.averageCost;
          labor.averageCostLock = _.isBoolean(data.averageCostLock) ? data.averageCostLock : true;
        } else {
          labor.selected = false;
        }
        labor.profit = Number(labor.cost) > 0 ? (Number(labor.cost || 0) - Number(labor.averageCost || 0)) : ''
        return labor;
      }),
      edit: true,
    }));
    setFields(prev => ({
      ...prev,
      name: { ...prev.name, value: rowData.name },
      description: { ...prev.description, value: rowData.description },
      calloutCost: { ...prev.calloutCost, value: rowData.calloutCost },
      travelCost: { ...prev.travelCost, value: rowData.travelCost },
      calloutAverageCost: { ...prev.calloutAverageCost, value: rowData.calloutAverageCost },
      travelAverageCost: { ...prev.travelAverageCost, value: rowData.travelAverageCost },
    }));
  };

  const submitHandler = () => {
    const { labors, levelId } = state;
    let _fields = _.cloneDeep(fields)
    let isFormValid = true;
    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key], _fields[key].validation);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    labors.forEach((labor) => {
      if (labor.selected) {
        const { error, valid } = handleValidation(labor, ["required"]);
        labor.touched = true;
        labor.valid = valid;
        labor.error = error;
        isFormValid = isFormValid && valid;
      }
    });

    if (!isFormValid) {
      setFields(_fields)
      setState(prev => ({
        ...prev,
        labors
      }));
    } else {
      const data = {
        name: _fields.name.value,
        description: _fields.description.value,
        calloutCost: parseInt(_fields.calloutCost.value),
        travelCost: parseInt(_fields.travelCost.value),
        calloutAverageCost: parseInt(_fields.calloutAverageCost.value) || 0,
        travelAverageCost: parseInt(_fields.travelAverageCost.value) || 0,
      };
      if (isNaN(data.travelCost)) {
        data.travelCost = 0;
      }
      if (isNaN(data.calloutCost)) {
        data.calloutCost = 0;
      }
      const hourlyCost = [];
      labors.forEach((labor) => {
        if (labor.selected) {
          hourlyCost.push({
            id: labor.laborId,
            cost: parseInt(labor.cost),
            skill: labor.skill,
            averageCost: labor.averageCostLock === false ? parseInt(labor.averageCost) : null,
            averageCostLock: labor.averageCostLock,
          });
        }
      });
      data.hourlyCost = hourlyCost;
      if (!levelId) {
        props.addLevel(data).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      } else {
        props.updateLevel(data, levelId).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      }
    }
  };

  const cancelHandler = () => {
    props.history(`/${CATALOGS}/${RESOURCES}`);
  };

  return (
    <div className="purchase-order-block">
      <h2 className="content-heading text-uppercase">
        {state.edit ? "Edit Cost/Sales Level" : "Add a Cost/Sales Level"}
      </h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>Resource</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {state.edit ? "Edit Cost/Sales Level" : "Add a Cost/Sales Level"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">
        <div className="col-12 mt-3">
          <CustomInput
            {...fields.name}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>

        <div className="col-12 mt-3">
          <TextAreaInput
            {...fields.description}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>
      </div>

      <div className="material-list-block mt-3">
        <div className="table-responsive">
          <Table bordered className="table-create table-material table-material-ch">
            <thead>
              <tr>
                <th className="chk-width"></th>
                <th className="w-auto">Name</th>
                <th className="text-right">Default Cost / Hour</th>
                <th>Cost / Hour Override <span className="text-danger">*</span></th>
                <th>Charge Amount <span className="text-danger">*</span></th>
                <th className="text-right">Profit</th>
              </tr>
            </thead>
            <tbody>
              {(state.labors || []).map((labor, index) => {
                if (!labor) return;
                return (
                  <tr>
                    <td>
                      <Form>
                        <div className="table-checkbox">
                          <Form.Check
                            type={'checkbox'}
                            onChange={(e) => costChangeHandler(e, index)}
                            value={labor?.selected}
                            checked={labor?.selected}
                          />
                        </div>
                      </Form>
                    </td>
                    <td>{labor.skill}</td>
                    <td className="text-right">
                      {toUSD(labor.default_cost)}
                    </td>
                    <td>
                      <LockInput
                        className={'input-block-sm'}
                        lock={
                          <span className="lock-icon">
                            {idx(labor, _ => _.averageCostLock) && <LockIcon
                              onClick={(e) => costChangeHandler({ target: { name: 'averageCostLock', value: false } }, index)}
                            />}
                            {!idx(labor, _ => _.averageCostLock) && <LockOpenIcon
                              onClick={(e) => costChangeHandler({ target: { name: 'averageCostLock', value: true } }, index)}
                            />}
                          </span>
                        }
                        name="averageCost"
                        value={labor.averageCost}
                        required={true}
                        error={labor.error}
                        onChange={(e) => costChangeHandler(e, index)}
                        disabled={idx(labor, _ => _.averageCostLock) ? true : false}
                      />
                    </td>
                    <td>
                      <AmountInput
                        className="input-block-sm"
                        type="number"
                        name="cost"
                        value={labor.cost}
                        required={true}
                        error={labor.error}
                        onChange={(e) => costChangeHandler(e, index)}
                      />
                    </td>
                    <td className="d-flex gap-1 justify-content-end">
                      <span>{toUSD(labor.profit)}</span>
                      <span>({toPercent(labor.profit, labor.averageCost)})</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-end gap-3 mt-3">
        {writePermission && (<Button onClick={submitHandler} className="secondarybtn">DONE</Button>)}
        <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>
    </div>
  );

}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  addLevel: bindActionCreators(addLevel, dispatch),
  updateLevel: bindActionCreators(updateLevel, dispatch),
  deleteLevel: bindActionCreators(deleteLevel, dispatch),
  getLevels: bindActionCreators(getLevels, dispatch),
  getLaborList: bindActionCreators(getLaborList, dispatch),
  getLevel: bindActionCreators(getLevel, dispatch),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLevel)
);
