import {
  getVendorList as getVendorListAPI,
  addVendor as addVendorAPI,
  getVendor as getVendorAPI,
  addContact as addContactAPI,
  editVendor as editVendorAPI,
  changeVendorStatus as changeVendorStatusAPI,
  getVendorCount as getVendorCountAPI
} from '../api/vendor-management.api'

export const getVendorList = (type, status = '') => {
  return (dispatch, getState) => {
    return getVendorListAPI(type, status)
  }
}

export const getVendor = (id) => {
  return (dispatch, getState) => {
    return getVendorAPI(id)
  }
}

export const addVendor = (data) => {
  return (dispatch, getState) => {
    return addVendorAPI(data)
  }
}

export const addContact = (data, id) => {
  return (dispatch, getState) => {
    return addContactAPI(data, id)
  }
}

export const editVendor = (data, id) => {
  return (dispatch, getState) => {
    return editVendorAPI(data, id)
  }
}

export const changeVendorStatus = (id) => {
  return (dispatch, getState) => {
    return changeVendorStatusAPI(id)
  }
}

export const getVendorCount = () => {
  return (dispatch, getState) => {
    return getVendorCountAPI()
  }
}
