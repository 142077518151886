import React from 'react'
import Modal from './../../components/modal/modal'
import IconButton from '@material-ui/core/IconButton'
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';
import {
  Grid,
} from '@material-ui/core'
import { INVENTORY } from '../../constants/route-paths'
import './scss/inventory.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  getRfq,
  createQuotation,
  getQuotationList,
  getProject,
  savePurchaseOrder,
  getPurchaseOrder,
  editQuotation
} from './../../actions/project-management.action'
import SubmitQuote from './submit-quote'
import {
  errorSnackBar
} from './../../actions/common.action'
import ViewSingleRFQ from '../../components/view-rfq'
import EditIcon from '@material-ui/icons/Edit'
import { numberFormat } from '../../util/currency-formatter'
class ViewRFQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfqsList: [],
      projectData: [],
      options: [],
      projectDetails: {},
      rfqDialogState: false,
      step: 1,
      quotationData: {
        rfqId: null,
        items: [],
        submitQuote: false,
        reviewQuote: false,
        vendorType: null,
        vendorId: null
      },
      materialItems: [], // Items on which quotaion is added or they are selected or rejected. This field is being set from submit-quote file
      viewPurchaseOrder: false,
    }
  }

  componentDidMount() {
    this.getQuotationList()
  }

  getQuotationList = () => {
    const query = `?projectId=null`
    this.props.getQuotationList(query).then(quotationsList => {

      Array.isArray(quotationsList) && quotationsList.forEach((quotation, quoIndex) => {

        Array.isArray(quotation.items) && quotation.items.forEach((item, itemIndex) => {
          if (_.isString(item) && !(_.isError(_.attempt(JSON.parse, item)))) {
            item = JSON.parse(item)
          }
          quotationsList[quoIndex].items[itemIndex] = item
        })
        Array.isArray(quotation.optionItems) && quotation.optionItems.forEach((option, optionIndex) => {
          if (_.isString(option) && !(_.isError(_.attempt(JSON.parse, option)))) {
            option = JSON.parse(option)
          }
          quotationsList[quoIndex].optionItems[optionIndex] = option
        })
        Array.isArray(quotation.attachment) && quotation.attachment.forEach((attachment, attachIndex) => {
          if (_.isString(attachment) && !(_.isError(_.attempt(JSON.parse, attachment)))) {
            attachment = JSON.parse(attachment)
          }
          quotationsList[quoIndex].attachment[attachIndex] = attachment
        })
        Array.isArray(quotation.vendorList) && quotation.vendorList.forEach((vendor, vIndex) => {
          if (_.isString(vendor) && !(_.isError(_.attempt(JSON.parse, vendor)))) {
            vendor = JSON.parse(vendor)
          }
          quotationsList[quoIndex].vendorList[vIndex] = vendor
        })
      })
      const formatedRfq = []
      Array.isArray(quotationsList) && quotationsList.forEach(rfq => {
        const vendorData = []
        Array.isArray(rfq.vendorList) && rfq.vendorList.forEach(vendor => {
          let total = 0
          let data = {}
          const isVendorQuoteSubmitted = rfq.quotation.find(_quotation => _quotation.vendorId === vendor.vendorId)
          if (isVendorQuoteSubmitted) {
            Array.isArray(isVendorQuoteSubmitted.items) && isVendorQuoteSubmitted.items.forEach(_item => {
              total = parseFloat(total) + parseFloat(_item.quote)
            })
            let quotationAttachments = []
            Array.isArray(isVendorQuoteSubmitted.quotationAttachments) &&
              isVendorQuoteSubmitted.quotationAttachments.map(qa => {
                if (_.isString(qa) && !(_.isError(_.attempt(JSON.parse, qa)))) {
                  qa = JSON.parse(qa)
                }
                quotationAttachments.push(qa)
              })
            data = {
              ...vendor, items: isVendorQuoteSubmitted.items,
              isPoGenerated: isVendorQuoteSubmitted.poGenerated, quotationAttachments,
              quotationId: isVendorQuoteSubmitted.id, isSubmitted: true, total: total,
            }
          } else {
            data = { ...vendor, items: rfq.items, isSubmitted: false, }
          }
          vendorData.push(data)
        })
        formatedRfq.push({
          vendorData, rfqId: rfq.id,
          attachment: rfq.attachment,
          description: rfq.description,
          vendorType: rfq.vendorType,
          draft: rfq.draft,
          items: rfq.items,
          optionItems: rfq.optionItems,
          quotationId: rfq.id
        })
      })
      this.setState({ rfqsList: formatedRfq })
    })
  }

  submitQuote = (rfq, vendor) => {
    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: true,
      reviewQuote: false,
      editQuote: false,
      vendorType: rfq.vendorType,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      attachment: rfq.attachment,
      description: rfq.description,
      quotationId: rfq.quotationId,
    }
    this.setState({ step: 2, quotationData })
  }

  editQuote = (rfq, vendor) => {

    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: false,
      reviewQuote: false,
      editQuote: true,
      vendorType: rfq.vendorType,
      attachment: vendor.quotationAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    }
    this.setState({ step: 2, quotationData })
  }

  reviewQuote = (rfq, vendor) => {
    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: false,
      reviewQuote: true,
      vendorType: rfq.vendorType,
      attachment: vendor.quotationAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    }
    this.setState({ step: 2, quotationData })
  }

  dialogClose = () => {
    if (this.state.step === 2) {
      this.setState({ step: 1, viewPurchaseOrder: false })
      return
    }
    this.props.dialogClose()
  }

  // This function takes value from submit-quote file and set items which will be used for quote or PO creation
  setMaterialItems = (materialItems) => {
    this.setState({ materialItems })
  }

  // crete new Quotation using materialItems
  createQuote = () => {
    const { quotationData = {} } = this.state
    let valid = true
    const formatedItems = (this.state.materialItems && this.state.materialItems.map(item => {
      if (!item.quote.value) {
        valid = false
      }
      return {
        ...item,
        quote: parseFloat(item.quote.value)
      }
    })) || []

    if (!valid) {
      this.props.warningSnackBar('Enter Quote Value!')
      return
    }

    const formData = new FormData()
    Array.isArray(quotationData.attachment) && quotationData.attachment.map(file => {
      formData.append('attachment[]', JSON.stringify(file))
    })
    Array.isArray(quotationData.attachmentArr) && quotationData.attachmentArr.map(newFile => {
      formData.append('attachment', newFile)
    })

    Array.isArray(formatedItems) && formatedItems.map(item => {
      formData.append('items[]', JSON.stringify(item))
    })
    formData.set('vendorId', quotationData.vendorId)

    this.props.createQuotation(formData, quotationData.rfqId).then(res => {
      this.getQuotationList()
      this.setState({ step: 1 })
    }).catch(e => console.log(e))
  }

  editQuotation = () => {
    const { quotationData = {} } = this.state
    let valid = true
    const formatedItems = (this.state.materialItems && this.state.materialItems.map(item => {
      if (!item.quote.value) {
        valid = false
      }
      return {
        ...item,
        quote: parseFloat(item.quote.value)
      }
    })) || []

    if (!valid) {
      this.props.warningSnackBar('Enter Quote Value!')
      return
    }

    this.props.editQuotation({ items: formatedItems }, quotationData.quotationId).then(res => {
      this.getQuotationList()
      this.setState({ step: 1 })
    })
  }

  generatePO = async () => {
    const { materialItems, quotationData } = this.state
    let poItems = Array.isArray(materialItems) && materialItems.filter(item => {
      if (item && item.status && item.status.value) {
        item.total = item.quote
        return item
      }
    }).map(({ status, ...poItem }) => poItem);

    if (Array.isArray(poItems) && poItems.length < 1) {
      this.props.errorSnackBar('Approve an Item to generate Purchase Order!')
      return
    }

    const formData = new FormData()
    Array.isArray(quotationData.attachment) && quotationData.attachment.map(file => {
      formData.append('attachment[]', JSON.stringify(file))
    })
    Array.isArray(quotationData.attachmentArr) && quotationData.attachmentArr.map(newFile => {
      formData.append('attachment', newFile)
    })

    Array.isArray(poItems) && poItems.map(item => {
      formData.append('items[]', JSON.stringify(item))
    })
    formData.set('vendorId', quotationData.vendorId)
    formData.set('quotationId', quotationData.quotationId)
    formData.set('is_de_po', true)

    await this.props.savePurchaseOrder(formData, quotationData.rfqId, 'Purchase Order Generated!').catch(e => console.log(e))
    this.getQuotationList()
    this.setState({ step: 1 })
  }

  viewPurchaseOrder = async (rfq, vendor) => {
    const query = `?id=${vendor.quotationId}`
    const { items, id, attachment } = await this.props.getPurchaseOrder(query)
    let poItems = (Array.isArray(items) && items) || []
    let poAttachments = []
    Array.isArray(attachment) &&
      attachment.map(attach => poAttachments.push(JSON.parse(attach)))

    const quotationData = {
      rfqId: rfq.rfqId,
      items: poItems,
      submitQuote: false,
      reviewQuote: true,
      vendorType: rfq.vendorType,
      attachment: poAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    }
    this.setState({ step: 2, quotationData, viewPurchaseOrder: true, purchaseOrderId: id })
  }

  rfqFileAttached = (quotationData) => {
    this.setState({ quotationData })
  }

  editRFQ = (id) => {
    this.props.dialogClose()
    this.props.history(`/${INVENTORY}/edit-rfq/${id}`)
  }

  viewRFQ = async (id) => {
    const rfq = await this.props.getRfq(id)
    this.setState({ rfqData: rfq, step: 3 })
  }

  render() {
    const { open } = this.props
    const {
      rfqsList,
      step,
      quotationData,
      projectDetails,
      viewPurchaseOrder,
      purchaseOrderId,
      rfqData
    } = this.state
    return (
      <Modal
        open={open}
        onClose={this.dialogClose}
        fullWidth={true}
        maxWidth="lg"
        modalHeading={`DE Proposals`}
        modalSaveTxt={step === 2 && quotationData.editQuote ? 'Edit Quote' : (step === 2 && !viewPurchaseOrder && (quotationData.submitQuote ? 'Save' : 'Generate PO'))}
        modalCloseTxt={step === 2 && !viewPurchaseOrder && 'Cancel'}
        onClick={quotationData.editQuote ? this.editQuotation : quotationData.submitQuote ? this.createQuote : this.generatePO}
        showActionBtn
        savebtnClass='createBtn'
        primary >
        {step === 1 && <div className='viewRfqContainer'>
          {
            rfqsList.map((rfq, index) => (
              <>
                <div className='rfqNameLabel'>
                  <p>{index + 1}.RFQ: {rfq.description} </p>
                  {rfq.draft ?
                    <p className='openDraft' onClick={() => this.editRFQ(rfq.rfqId)}><IconButton aria-label="add" disabled="true" className="AddNewCustomerIcon">
                      <InsertDriveFileRoundedIcon />
                    </IconButton>Open Draft</p> :
                    <p className='openDraft' onClick={() => this.viewRFQ(rfq.rfqId)}><IconButton aria-label="add" disabled="true" className="AddNewCustomerIcon">
                      <VisibilityRoundedIcon />
                    </IconButton>View
                    </p>}
                </div>
                {!rfq.draft && <div style={{}}>
                  <Grid container style={{ background: '#F4F4F4', borderRadius: '4px', padding: '0 15px' }}>
                    <Grid xs={3} item>
                      <p><b>Vendor Name</b></p>
                    </Grid>
                    <Grid xs={2} item>
                      <p><b>Status</b></p>
                    </Grid>
                    <Grid xs={2} item>
                      <p><b>Total</b></p>
                    </Grid>
                    <Grid xs={2} item>
                      <p><b>Accepted</b></p>
                    </Grid>
                    <Grid xs={2} item>
                      <p><b>Orders</b></p>
                    </Grid>
                  </Grid>
                  {rfq.vendorData && rfq.vendorData.map((vendor, index) => {
                    return (
                      <div>
                        <Grid container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px', alignItems: 'center' }}>
                          <Grid xs={3} item>
                            <p>{vendor.name}</p>
                          </Grid>
                          <Grid xs={2} item>
                            {vendor.isSubmitted ? <p style={{ color: '#3FC620' }}>Submitted
                              <span style={{ cursor: 'pointer' }} onClick={() => this.editQuote({ ...rfq }, { ...vendor })}> <EditIcon /></span></p>
                              : <p onClick={() => this.submitQuote({ ...rfq }, { ...vendor })} className='submitQuote'>Submit Quote</p>}
                          </Grid>
                          <Grid xs={2} item>
                            {vendor.isSubmitted ?
                              <p ><b>{numberFormat(vendor.total)}</b></p>
                              : '-'
                            }
                          </Grid>
                          <Grid xs={2} item>
                            {vendor.isSubmitted ?
                              <p >{numberFormat(vendor.total)}</p>
                              : '-'
                            }
                          </Grid>
                          <Grid xs={2} item>
                            {vendor.isSubmitted ?
                              vendor.isPoGenerated ?
                                <p onClick={() => this.viewPurchaseOrder({ ...rfq }, { ...vendor })} className='viewPO'>View Purchase Order</p>
                                : <p onClick={() => this.reviewQuote({ ...rfq }, { ...vendor })} className='reviewQuote'>Review</p>
                              : ''}
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>}
              </>
            ))
          }
        </div>}
        {step === 2 &&
          <SubmitQuote
            quotationData={quotationData}
            setMaterialItems={this.setMaterialItems}
            projectDetails={projectDetails}
            viewPurchaseOrder={viewPurchaseOrder}
            purchaseOrderId={purchaseOrderId}
            rfqFileAttached={this.rfqFileAttached}
            errorSnackBar={this.props.errorSnackBar}
          />
        }
        {step === 3 &&
          <ViewSingleRFQ
            rfqData={rfqData}
          />
        }
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getRfq: bindActionCreators(getRfq, dispatch),
  createQuotation: bindActionCreators(createQuotation, dispatch),
  getQuotationList: bindActionCreators(getQuotationList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
  savePurchaseOrder: bindActionCreators(savePurchaseOrder, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  editQuotation: bindActionCreators(editQuotation, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewRFQ);