import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import { useLocation, useNavigate, useParams } from "react-router";
import { PROJECT_MANAGEMENT } from "src/constants/route-paths";
import idx from "idx";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPOList, getPurchaseOrder, updatePOStock } from "src/actions/project-management.action";
import { useSelector } from "react-redux";
import { getProjectData } from "../utils/get-project";
import _ from "lodash";
import SelectInput from "src/components/inputs/select";
import { getAssetList } from "src/actions/asset-management.action";
import { warningSnackBar } from "src/actions/common.action";
import CustomInput from "src/components/inputs/new-input";

export default function ReceiceStock({ writePermission, ...props }) {

  const [state, setState] = useState({
    selectedPO: false,
    items: [],
    vendorName: '',
    PoNO: 0,
    selectedStock: [],
  })
  const [fields, setFields] = useState({
    vendor: {
      name: "vendor",
      label: "Select Vendor",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    po: {
      name: "po",
      label: "Select PO",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    location: {
      name: "location",
      label: "Select Location",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "WareHouse", value: "WAREHOUSE" },
        { key: "Site", value: "SITE" },
        { key: "Vehicle", value: "VEHICLE" },
      ],
      validation: [""],
    },
    vehicleNo: {
      name: "vehicleNo",
      label: "Select Vehicle No",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
  })
  const { vendorId, poId, projectId } = useParams();
  let [searchParams] = useSearchParams();
  let location = useLocation();

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { projectDetail = {}, user } = useSelector(state => state)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)
  const isReturnStock = location.pathname.includes("return-stock") ? true : false;

  useEffect(() => {
    if (!idx(projectDetail, _ => _.project.res))
      getProjectData({ projectId, dispatch })

    if (vendorId && poId) {
      setState(prev => ({
        ...prev,
        selectedPO: true
      }));
      vendorChangeHandler(
        { target: { name: "vendor", value: vendorId } },
        poId
      );
    }
    _getAssetsList()
    initLoad();
  }, []);

  const initLoad = () => {
    dispatch(getPOList({ projectId, vendorType: "Supplier" }))
      .then((poList) => {
        if (poId) {
          poList.forEach((po) => {
            if (po.u_id == poId) {
              setState(prev => ({
                ...prev,
                PoNO: po.id
              }));
            }
          });
        }

        const vendors = [];
        Array.isArray(poList) &&
          poList.forEach(({ vendor }) => {
            const business_name = idx(vendor, (_) => _.business_name) || "";
            const id = idx(vendor, (_) => _.id) || null;
            const isSelected = vendors.find(({ value }) => value === id);
            if (!isSelected && id) {
              vendors.push({
                key: business_name,
                value: id,
              });
            }
          });
        let vendorName = idx(vendors.find((v) => v.value === vendorId), (_) => _.key) || "";
        setFields(prev => ({
          ...prev,
          vendor: {
            ...prev.vendor,
            items: vendors
          },
        }));
        setState(prev => ({
          ...prev,
          vendorName,
        }));
      });
    setState(prev => ({
      ...prev,
      userName: [idx(user, _ => _.userData.firstName), idx(user, _ => _.userData.lastName)].filter(item => item).join(' '),
    }));
  }

  const vendorChangeHandler = async (e, poId) => {
    inputChangeHandler(e);
    dispatch(getPOList({ vendorId: e.target.value, projectId }))
      .then((poList) => {
        // const fields = _.cloneDeep(fields);
        const POs = [];
        poList.forEach((po) => {
          POs.push({
            key: po.id,
            value: po.id,
          });
        });
        setFields(prev => ({
          ...prev,
          po: {
            ...prev.po,
            items: POs
          },
          vendor: {
            ...prev.vendor,
            value: e.target.value
          },
        }));
        if (poId) {
          changePOType({ target: { name: "po", value: poId } });
        }
      });
  };

  const changePOType = async (e) => {
    const value = e.target.value;
    inputChangeHandler(e);
    const query = `?poId=${value}`;
    const po = await dispatch(getPurchaseOrder(query));
    const items = idx(po, (_) => _.items) || [];
    Array.isArray(items) &&
      items.forEach((item) => {
        const invoices = [{ key: "None", value: null }];
        Array.isArray(item.invoices) &&
          item.invoices.forEach((invoice) => {
            invoices.push({
              key: invoice.invoiceNo,
              value: invoice.invoiceId,
              ...invoice,
            });
          });
        item.invoices = invoices;
        item.selectedInvoice = null;
        item.invoicedQty = 0;
      });
    setState(prev => ({
      ...prev,
      items
    }));
    // this.setState({
    //   items,
    //   poId: value,
    // });
  };

  const inputChangeHandler = (e) => {
    fields[e.target.name].value = e.target.value;
    fields[e.target.name].touched = true;
    const { error, valid } = handleValidation(e.target, fields[e.target.name].validation);
    fields[e.target.name].error = error;
    fields[e.target.name].valid = valid;
    setFields(prev => ({
      ...prev,
      ...fields,
    }));
  };

  const handleValidation = ({ name, type, value }, validation) => {
    let error = false;
    let valid = true;
    if (
      validation.includes("required") && (value === undefined || (!_.isBoolean(value) && !_.isNumber(value) && value.trim() === ""))
    ) {
      return { error: "This Field is Required", valid: false };
    }
    return { error, valid };
  };

  const _getAssetsList = () => {
    dispatch(getAssetList("VEHICLE")).then((assets) => {
      const data = [];
      assets && assets.filter((asset) => asset.status !== "DISCARD")
        .forEach((asset) => {
          if (asset) {
            data.push({
              key: asset.assetNo,
              value: asset.id,
              ...asset,
            });
          }
        });

      setFields(prev => ({
        ...prev,
        vehicleNo: {
          ...prev.vehicleNo,
          items: data
        },
      }));
    });
  };

  const invoiceChangeHandler = (value, index) => {
    const items = _.cloneDeep(state.items);
    const item = _.cloneDeep(items[index]);
    if (value != null) {
      const invoice = item.invoices.find((inv) => inv.value === value);
      if (invoice) {
        item.invoicedQty = invoice.invoicedQty;
      }
    }
    item.selectedInvoice = value;
    items[index] = _.cloneDeep(item);
    setState(prev => ({
      ...prev,
      items
    }));
  };

  const stockChangeHandler = (e, index) => {
    const items = state.items;
    const item = items[index];
    let enteredValue = parseFloat(e.target.value) || 0;
    if (enteredValue < 0) {
      return;
    }
    let allowedQty = parseFloat(item.qty) - parseFloat(item.receivedQty);
    if (enteredValue > allowedQty) {
      items[index].error = "You can not enter more than remaining qty";
    } else {
      items[index].error = false;
    }
    items[index].newStock = e.target.value;
    setState(prev => ({
      ...prev,
      items
    }));
  };

  const returnStockChangeHandler = (e, index) => {
    const items = state.items;
    const item = _.cloneDeep(items[index]);
    let enteredValue = parseFloat(e.target.value) || 0;
    if (enteredValue < 0) {
      return;
    }
    if (item.selectedInvoice === null) {
      let receivedQty = parseFloat(item.receivedQty || 0);
      if (enteredValue > receivedQty) {
        item.error = "You can not enter more than received qty";
      } else {
        item.error = false;
      }
    } else {
      // if a invoice is selected then invoiced qty will be considered for validation
      let invoicedQty = parseFloat(item.invoicedQty || 0);
      if (enteredValue > invoicedQty) {
        item.error = "You can not enter more than invoiced qty";
      } else {
        item.error = false;
      }
    }

    item.returnStock = e.target.value;
    items[index] = _.cloneDeep(item);
    setState(prev => ({
      ...prev,
      items
    }));
  };

  const handleCheckboxClick = (qty, checked, id) => {
    let { selectedStock = [] } = state;
    if (checked) {
      selectedStock.push({ qty, id });
    } else {
      const index = selectedStock.findIndex((t) => t.id === id);
      selectedStock.splice(index, 1);
    }
    setState(prev => ({
      ...prev,
      selectedStock
    }));
  };

  const submitForm = () => {
    const {
      userName,
      items,
      selectedStock,
    } = state;
    let isFormValid = true;

    for (let key in fields) {
      const { error, valid } = handleValidation(fields[key], fields[key].validation);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    items && items.forEach((item) => {
      if (item.error) {
        isFormValid = false;
      }
    });

    if (!fields.location.value && !isReturnStock) {
      dispatch(warningSnackBar("Please Select Delivery Location"));
      return;
    }

    if (fields.location.value === "VEHICLE" && !fields.vehicleNo.value && !isReturnStock) {
      dispatch(warningSnackBar("Please Select Vehicle"));
      return;
    }

    if (!isFormValid) {
      setFields(prev => ({
        ...prev,
        ...fields
      }));
    } else {
      const data = {
        items,
        // allocationLocation: fields.location.value,
        poId: "" + poId,
        transactionType: isReturnStock ? "RETURNED" : "RECEIVED",
      };
      if (fields.location.value) data.allocationLocation = fields.location.value
      if (fields.location.value === "VEHICLE") {
        const assetData = fields.vehicleNo.items.find(
          (vehicle) => vehicle.id === fields.vehicleNo.value
        ) || {};
        data.allocateToName = idx(assetData, _ => _.name) || idx(assetData, _ => _.assetAllocation.allocationName);
        data.allocateToId = idx(assetData, _ => _.id) || idx(assetData, _ => _.value) || idx(assetData, _ => _.assetAllocation.id);
        data.assetId = assetData.id;
      } else if (fields.location.value === "SITE") {
        data.allocateToName = idx(projectDetail, _ => _.project.projectName);
        data.allocateToId = projectId;
      } else {
        data.allocateToName = userName;
        data.allocateToId = idx(user, _ => _.userData.id);
      }

      items.forEach((item) => {
        const newStock = parseFloat(item.newStock || 0);
        const receivedQty = parseFloat(item.receivedQty || 0);
        if (isReturnStock) {
          const returnStock = parseFloat(item.returnStock || 0);
          item.receivedQty = receivedQty - returnStock;
          if (item.selectedInvoice !== null) {
            item.containInvoicedReturn = true;
            item.returnedQty = returnStock;
            const invoice = item.invoices.find(
              (inv) => inv.value === item.selectedInvoice
            );
            item.taskId = invoice.taskId;
            item.invoiceId = invoice.invoiceId;

            item.amountPaid =
              parseFloat(invoice.amountPaid || 0) -
              parseFloat(item.returnStock || 0) * parseFloat(item.cost || 0);
          }
        } else {
          item.receivedQty = newStock + receivedQty;
        }
        item.taskType = "TASK";
        item.name = item.item;
        item.costPerItem = item.cost;
        delete item.invoices;

        if (selectedStock.findIndex((t) => t.id === item.id) !== -1) {
          item.receivedQty +=
            (parseFloat(item.qty) || 0) - (parseFloat(item.receivedQty) || 0);
        }
      });
      data.items = items;

      dispatch(updatePOStock(data, poId)).then((res) => {
        viewProject();
      });
    }
  };

  const viewProject = () => {
    if (vendorId && poId) {
      navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}`);
    } else {
      navigate(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
    }
  }
  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  // const viewPO = () => navigate(`view/${poId}`);
  // const editPO = () => navigate(`edit/${poId}`);
  // const createPO = () => navigate(`create-purchase-order`);
  // const receiveStock = () => navigate(`/${PROJECT_MANAGEMENT}/receive-stock/${projectId}/${vendorId}/${poId}`);
  // const returnStock = () => navigate(`/${PROJECT_MANAGEMENT}/return-stock/${projectId}/${vendorId}/${poId}`);
  // const viewInvoices = () => navigate(`/${VENDOR_PAYMENT}?project=${projectId}&poId=${poId}`);

  return (
    <>
      <div className="purchase-order-block">

        <h2 className="content-heading text-uppercase">{isReturnStock ? "Return" : "Receive"} Stock</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
            <Breadcrumb.Item active>{isReturnStock ? "Return" : "Receive"} Stock</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="row">
          <div className="col-lg-4 mt-3">
            {state.selectedPO ? (
              <div className="view-block">
                <label className="input-label">{state.selectedPO ? "Vendor" : "Select Vendor"}</label>
                <p>{state.vendorName}</p>
              </div>
            ) : (
              <SelectInput
                {...fields.vendor}
                label={'Select Vendor'}
                disable={vendorId}
                onChange={(e) => vendorChangeHandler(e)}
              />
            )}
          </div>
          <div className="col-lg-4 mt-3">
            {state.selectedPO ? (
              <div className="view-block">
                <label className="input-label">{state.selectedPO ? "PO" : "Select PO"}</label>
                <p>
                  <b>{state.PoNO}</b>
                </p>
              </div>
            ) : (
              <SelectInput
                onChange={(e) => changePOType(e)}
                {...fields.po}
                label={'Select PO'}
                disable={poId}
              />
            )}
          </div>
          {!isReturnStock && <div className="col-lg-4 mt-3">
            <SelectInput
              onChange={(e) => inputChangeHandler(e)}
              {...fields.location}
              label={'Location'}
            />
          </div>}
          {!isReturnStock && fields.location.value === "VEHICLE" && <div className="col-lg-4 mt-3">
            <SelectInput
              onChange={(e) => inputChangeHandler(e)}
              {...fields.vehicleNo}
              label={'Vehicle No'}
            />
          </div>}
          <div className="col-12">
            <div className="material-list-block mt-4">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch">
                  <thead>
                    <tr>
                      <th className="w-auto">Task Name</th>
                      <th className="w-auto">Item</th>
                      <th>Catalog</th>
                      <th>Ordered Quantity</th>
                      <th>Already Received</th>
                      <th>Quantity Left</th>
                      {isReturnStock && (<th>Select Invoice</th>)}
                      <th>{isReturnStock ? "Enter Return Stock" : "Stock Qty Received"}</th>
                      {!isReturnStock && <th>Received All</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {state.items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.taskName}</td>
                          <td>{item.item}</td>
                          <td>{item.category}</td>
                          <td>{parseFloat(item.qty) || 0}</td>
                          <td>{parseFloat(item.receivedQty) || 0}</td>
                          <td> {(parseFloat(item.qty) || 0) - (parseFloat(item.receivedQty) || 0)}</td>
                          {isReturnStock && (<td>
                            <SelectInput
                              className="input-block-sm"
                              items={item.invoices}
                              value={item.selectedInvoice}
                              onChange={(e) => invoiceChangeHandler(e.target.value, index)}
                            />
                          </td>)}
                          <td>
                            <CustomInput
                              className="input-block-sm"
                              value={isReturnStock ? item.returnStock : item.newStock}
                              lable="Stock"
                              type="number"
                              valid={!item.error}
                              error={item.error}
                              onChange={(e) => {
                                if (isReturnStock) {
                                  returnStockChangeHandler(e, index)
                                } else {
                                  stockChangeHandler(e, index)
                                }
                              }}
                            />
                          </td>
                          {!isReturnStock && <td>
                            {
                              (parseFloat(item.qty) || 0) - (parseFloat(item.receivedQty) || 0) != 0 ?
                                (
                                  <div className="table-checkbox">
                                    <Form.Check
                                      type={'checkbox'}
                                      checked={
                                        state.selectedStock.findIndex((t) => t.id === item.id) !== -1 ? true : false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxClick(
                                          (parseFloat(item.qty) || 0) - (parseFloat(item.receivedQty) || 0),
                                          e.target.checked,
                                          item.id
                                        )
                                      }
                                    />
                                  </div>
                                )
                                : 'Received'
                            }
                          </td>}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>

            </div>
          </div>
        </div>

        <div className="col-12 d-flex mt-3">
          <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => submitForm()}>SAVE</Button>
          <Button type="submit" className="secondarybtn cancelbtn me-auto" onClick={() => navigate(-1)}>CANCEL</Button>
        </div>

      </div>
    </>
  )

}
