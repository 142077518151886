export const phoneValidator = (value = '') => {
    let _temp = value
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace(/\+/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
    if ((/^[0-9]{7,20}$/).test(_temp)) {
        return true
    } else { return false }
}