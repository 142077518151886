import idx from "idx";
import { getOptions } from "src/actions/project-management.action";
import { updateProjectAction } from "src/slice/project-detail";
import _ from "lodash";
import { optionCalculation } from "src/util/calculations";

export const getOptionsData = async ({ projectId, dispatch }) => {

    if (!projectId) return;

    dispatch(getOptions(projectId)).then((res) => {
        let sno = 0;
        let tempArray = [];
        let totalVariations = 0;
        let totalVariationAmount = 0;

        res.forEach((option) => {
            let totalProfit = 0; let totalPercentage = 0;
            option.subTasks.forEach((subTask) => {
                totalProfit += subTask.totalProfit || 0;
            });

            option.revisionNumber = idx(option, _ => _.taskRevision[0].RevisionNumber) || '';
            option.revisionId = idx(option, _ => _.taskRevision[0].RevisionId) || '';
            option.revisionName = idx(option, _ => _.taskRevision[0].revisionName) || '';
            option.subTasks = option.subTasks ? option.subTasks : [];
            option.totalProfit = totalProfit;
            tempArray.push(option);

            option.subTasks.forEach((subTask) => {
                totalPercentage += parseFloat(subTask.percentageComplete);
            });

            option.percentageComplete = parseFloat(totalPercentage) / parseFloat(option.subTasks.length);
        });

        const sortedtempArray = _.chain(tempArray)
            .groupBy("revisionId")
            .map((revisions, revisionId) => {
                const sortedRevisions = _.sortBy(revisions, [(o) => new Date(o.createdAt || null)]) || [];
                return { revisionId, revisions: sortedRevisions };
            })
            .sortBy((o) => o.revisions[0].createdAt)
            .map((data) => data.revisions)
            .value()
            .flat(1);

        const uniqueIdArr = _.uniqBy(sortedtempArray, "revisionId");
        sortedtempArray.forEach((option, optionIndex) => {
            let index = _.findIndex(uniqueIdArr, function (item) {
                return item.id === option.id;
            });
            if (index !== -1) {
                option.revision = true;
                option.selected = true;
                sno += 1;
            } else {
                option.selected = false;
            }

            if (option.proposal_selected && option.proposal_status === "APPROVED") {
                let variationSubTotal =
                    parseFloat(option.materialTotal_SaleAmount || 0) +
                    parseFloat(option.laborTotal_SaleAmount || 0) +
                    parseFloat(option.subcontractorTotal_SaleAmount || 0);
                totalVariationAmount += variationSubTotal;
                totalVariations++;
                option.variationNo = generateVariationNumber(optionIndex + 1, 3);
            }

            option.sno = sno;
        });

        let calculations = optionCalculation({ options: sortedtempArray });

        dispatch(updateProjectAction({ options: sortedtempArray, optionsTotal: calculations, totalVariations, totalVariationAmount }))

    });

};

const generateVariationNumber = (n, width, z) => {
    z = z || "0";
    n = n + "";
    return n.length >= width
        ? `V-${n}`
        : `V-${new Array(width - n.length + 1).join(z) + n}`;
};