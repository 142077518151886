import {
  SAVE_PROJECT_TYPE,
  REMOVE_PROJECT_TYPE,
  SAVE_PROJECT_CATEGORIES
} from '../constants/action.constant'

const initialState = {
  projectType: '',
  projectCategories: [],
  allowedModules: [],
  projectModule: false,
}

const saveProjectType = (state, action) => {
  const projectType = action.data || ''
  return ({
    ...state,
    projectType
  })
}

const saveProjectCategories = (state, action) => {
  let allowedModules = [];
  const { projectCategory, user } = action.data;
  const permissions = user.userData && user.userData.permissions;
  const projectCategories = projectCategory ? projectCategory.data : [] || [];
  projectCategories.forEach(item => {
    if ((permissions && permissions[`project${item.type}`] > 0) || (user.userData.global_user && user.userData.selected_account.id)) {
      allowedModules.push(item.type)
    }
  });
  let projectModule = allowedModules.length > 0 ? true : false;
  return ({
    ...state,
    projectCategories,
    allowedModules,
    projectModule,
  })
}

const removeProjectType = (state, data) => {
  return { ...state, projectType: '' }
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROJECT_TYPE: return saveProjectType(state, action)
    case REMOVE_PROJECT_TYPE: return removeProjectType(state, action)
    case SAVE_PROJECT_CATEGORIES: return saveProjectCategories(state, action)
    default: return state
  }
}

export default projectReducer
