import React from "react";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './date-picker.scss'
import _ from 'lodash'

export default function DatePickerInput({ label, placeholder, maxDate, disabled, className = '', required, error, clearable = true, validation = [], minDate, ...props }) {
    let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

    return (
        <div className="form-group">
            <div className={_.uniq(["input-block", "datepicker-block", ...className.split(' ')]).join(' ')}>
                <span className="input-icon"><CalendarMonthIcon /></span>
                <label className="input-label">
                    <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                        id={props.name}
                        margin='dense'
                        inputVariant='outlined'
                        value={moment(props.value)}
                        format="DD/MM/YYYY"
                        // onChange={(value) => props.onChange(moment(value).toISOString())}
                        onAccept={(value) => props.onChange(moment(value).toISOString())}
                        maxDate={maxDate ? moment(maxDate) : null}
                        disabled={disabled}
                        slotProps={{
                            actionBar: {
                                actions: [...(clearable ? ['clear'] : []), 'cancel', 'accept']
                            },
                            textField: {
                                helperText: error ? <small className="float-start text-danger">{error}</small> : "",
                                error: error,
                            },
                        }}

                    />
                </LocalizationProvider>
            </div>
        </div >
    );
}


export function ScheduleDatePickerInput({ label, placeholder, maxDate, disabled, className = '', required, error, validation = [], ...props }) {
    let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

    return (
        <div className="form-group">
            <div className={_.uniq(["input-block", "datepicker-block", ...className.split(' ')]).join(' ')}>
                <span className="input-icon"><CalendarMonthIcon /></span>
                <label className="input-label">
                    <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                        id={props.name}
                        margin='dense'
                        inputVariant='outlined'
                        value={moment(props.value)}
                        format="DD/MM/YYYY"
                        onChange={(value) => props.onChange(moment(value).toISOString())}
                        maxDate={maxDate ? moment(maxDate) : null}
                        disabled={disabled}
                        slotProps={{
                            actionBar: {
                                actions: ['clear', 'cancel', 'accept']
                            },
                            textField: {
                                helperText: error ? <small className="float-start text-danger">{error}</small> : "",
                                error: error,
                            },
                        }}

                    />
                </LocalizationProvider>
            </div>
        </div >
    );
}