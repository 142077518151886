import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { categoriesFields, itemFields } from "src/constants/fields.constant";
import { updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getCategories, getItems, getUnits } from "src/actions/inventory.action";
import { warningSnackBar } from "src/actions/common.action";
import { filterItems, filterManufacturer } from "src/util/common";
import { getFormatedUnit } from "src/util";
import { v4 as uuidv4 } from "uuid";
import ProfitMarginLock from "src/components/profitMarginLock-dialog";
import CustomInput from "src/components/inputs/new-input";

const filter = createFilterOptions();

export default function Material({ material = [], total = {}, profitMargin, profitMarginType, isDisabled, ...props }) {

    const dispatch = useDispatch()
    const [materialState, setMaterialState] = useState({
        categories: JSON.parse(JSON.stringify(categoriesFields)),
        categoriesList: [],
        itemsList: [],
        items: [],
        filterValue: {},
        item: itemFields,
        profitMarginDialogState: false,
        lockArrayValue: null,
        confirmLock: false,
        showMaterialLockIcon: true,
    })

    const [expandAddMaterial, setExpandAddMaterial] = useState(false);

    useEffect(() => {
        if (!materialState.categories.items.length)
            _getCategories()
        initLoad()
    }, [])

    const initLoad = () => {
        dispatch(getItems()).then((items) => {
            const activeItems = (Array.isArray(items) && items.filter(({ status }) => status === "ACTIVE")) || [];
            setMaterialState(prev => ({
                ...prev,
                itemsList: activeItems
            }));
        });

        dispatch(getUnits()).then((res) => {
            const allUnits = (res && Array.isArray(res) && res.length > 0 && res[0].units) || [];
            const units = getFormatedUnit(allUnits);
            let { item } = materialState;
            item.unit.items = units;
            setMaterialState(prev => ({
                ...prev,
                item
            }));
        });
    }

    const _getCategories = () => {
        dispatch(getCategories()).then((res) => {
            const categoriesList = res.map((category) => ({
                key: category.name,
                name: category.name,
                value: category.id,
            }));
            let { categories } = materialState;
            categories.items = categoriesList;
            setMaterialState(prev => ({
                ...prev,
                categories,
                categoriesList,
            }));
        }).catch(e => console.error(e));
    }
    const profitMarginDialogHandler = (inputValue, index) => {
        setMaterialState(prev => ({
            ...prev,
            lockArrayValue: index,
            profitMarginDialogState: !prev.profitMarginDialogState,
        }));
    };

    const itemChangeHandler = (e, index) => {
        let _temp = _.cloneDeep(material)
        switch (e) {
            case "saleAmountLock":
                profitMarginDialogHandler(e, index);
                if (materialState.profitMarginDialogState && materialState.confirmLock) {
                    _temp[index]["saleAmountLock"].value = !_temp[index]["saleAmountLock"].value;
                }
                break;
            default:
                if (e.target.name === "costPerItem" || e.target.name === "qty") {
                    if (e.target.value < 0)
                        break;
                }

                _temp[index][e.target.name].value = e.target.value;

                const is_locked = _temp[index]["saleAmountLock"].value || false;
                let saleAmount = _temp[index]["saleAmount"].value;

                if (e.target.name === "saleAmountLock") {
                    _temp[index]["saleAmountLock"].value = !_temp[index]["saleAmountLock"].value;
                }

                if (e.target.name === "costPerItem" || e.target.name === "qty") {
                    if (saleAmount <= 0 || is_locked) {
                        _temp[index]["saleAmount"].value = _temp[index]["costPerItem"].value
                    }
                }
                saleAmount = _temp[index]["saleAmount"].value;

                _temp[index]["total"].value = (parseInt(_temp[index]["qty"].value) || 0) * parseFloat(saleAmount);
        }

        updateMaterials(_temp);
    };

    const removeItem = (index) => {
        let _temp = _.cloneDeep(material)
        _temp.splice(index, 1)
        updateMaterials(_temp);
    };

    const updateMaterials = (materials) => {
        if (!Array.isArray(materials)) return;
        const { subTaskArray, subTaskArrayIndex } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["material"] = materials;
        dispatch(updateSubTaskAction(_temp));
    };

    const filterItem = (id) => {
        const item = (materialState.itemsList || []).find((item) => item.id === id);
        return item;
    };

    const addItem = (value, category, itemData = null, skip = false) => {
        let selectedMaterials = _.cloneDeep(material);
        const itemExist = selectedMaterials.find((item) => item.id === value && item.categoryId === category);

        if (!itemExist) {
            const { filterValue } = materialState;
            const selectedItem = itemData ? itemData : filterItem(value);
            if (!category) category = selectedItem.categoryId;
            // if (_.filter(categoryName, { id: category }).map((v) => v.id).length === 0)
            //     this.addCategory(category);

            const item = JSON.parse(JSON.stringify(materialState.item));
            item.name.value = selectedItem.item;
            item.taskId.value = selectedItem.taskId || null;
            item.id = selectedItem.id || uuidv4();
            item.manufacturer = selectedItem.manufacturer;
            item.partNo = selectedItem.partNo || selectedItem.part_no;
            item.categoryId = selectedItem.categoryId;
            item.costPerItem.value = filterValue.costPerItem || selectedItem.cost;
            item.saleAmount.value = (filterValue.costPerItem || filterValue["saleAmountLock"] === false) ?
                (filterValue["saleAmount"] || selectedItem.saleAmount || selectedItem.cost) :
                (selectedItem.saleAmountLock ? selectedItem.cost : selectedItem.saleAmount || selectedItem.cost) || 0;
            item.saleAmountLock.value = filterValue["saleAmountLock"] || selectedItem.saleAmountLock;
            item.unit.value = selectedItem.unit || null;
            item.qty.value = filterValue["qty"] || selectedItem.qty || 1;
            item.total.value = item.saleAmount.value * item.qty.value;

            selectedMaterials.push(item);

            updateMaterials(selectedMaterials);
            hideAddItem()
        } else {
            if (!skip) {
                dispatch(warningSnackBar("This Item is Already Selected"));
            }
        }
    };

    const hideAddItem = () => {
        setExpandAddMaterial(!expandAddMaterial)
        setMaterialState(prev => ({
            ...prev,
            filterValue: {}
        }));
    };

    const itemsDropDownOption = () => {
        const { filterValue } = materialState;
        let itemsList = _.cloneDeep(materialState.itemsList)
        let filterOptions = filterItems(itemsList, filterValue);
        if (filterOptions.length === 0) {
            setFilterResult(true, "");
        } else {
            setFilterResult(false, "");
        }
        return filterOptions;
    };
    const setFilterResult = (value, msg) => {
        const { filterValue } = materialState
        let prevValue = idx(filterValue, (_) => _.noresult);
        if (prevValue !== value) {
            filterValue["noresult"] = value;
            filterValue["noresult_msg"] = msg;
            setMaterialState(prev => ({
                ...prev,
                filterValue
            }));
        }
    };

    const addFilter = (value, type) => {
        let { filterValue, showMaterialLockIcon } = materialState;
        if (!filterValue) filterValue = {};
        if (filterValue && filterValue[type] !== value) {
            filterValue[type] = value;
            if (type === "saleAmount" && !showMaterialLockIcon) {
                filterValue["saleAmount"] = value;
                filterValue["saleAmountLock"] = !filterValue["saleAmountLock"];
            }
            if (type === "costPerItem" || type === "qty" || type === "saleAmount") {
                if (showMaterialLockIcon || filterValue["saleAmountLock"])
                    filterValue["saleAmount"] = parseFloat(filterValue["costPerItem"] || 0)

                const totalvalue = (parseInt(filterValue["qty"]) || 1) * (parseFloat(filterValue["saleAmount"]) || 1);

                setMaterialState(prev => ({
                    ...prev,
                    totalValue: parseFloat(totalvalue)
                }));
            }
            setMaterialState(prev => ({
                ...prev,
                filterValue
            }));
        }
    };

    const manufacturerDropDown = () => {
        const { filterValue } = materialState;
        let itemsList = _.cloneDeep(materialState.itemsList)
        let filterOptions = filterManufacturer(itemsList, filterValue);
        if (filterOptions.length === 0) {
            setFilterResult(true, "");
        } else {
            setFilterResult(false, "");
        }
        return filterOptions;
    };

    const setConfirmed = () => {
        const { lockArrayValue } = materialState;
        let _selectedItemsList = _.cloneDeep(material);
        _selectedItemsList[lockArrayValue]["saleAmountLock"].value = !_selectedItemsList[lockArrayValue]["saleAmountLock"].value;
        _selectedItemsList.forEach((item, index) => {
            if (item["saleAmountLock"].value) {
                _selectedItemsList[index]["saleAmount"].value = item["costPerItem"].value
                _selectedItemsList[index]["total"].value = (parseInt(item["qty"].value) || 0) * parseFloat(_selectedItemsList[index]["saleAmount"].value);
            }
        });
        setMaterialState(prev => ({
            ...prev,
            profitMarginDialogState: !prev.profitMarginDialogState
        }));
        updateMaterials(_selectedItemsList);
    };

    return (
        <>
            <h2>Material List</h2>
            <div className="table-responsive">
                <Table bordered className="table-create table-material tbl-fixed">
                    <thead>
                        <tr>
                            <th>Catalog</th>
                            <th>Manufacturer</th>
                            <th>Part No</th>
                            <th className="item-width">Items</th>
                            <th>Unit Cost($)</th>
                            <th>Sale Amount</th>
                            <th>Quantity</th>
                            <th className="text-right">Total ($)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {material.map((_material, materialIndex) => {
                            if (!_material) return;
                            let category = materialState.categoriesList.find((item) => item.value === _material.categoryId);
                            return (
                                <tr key={materialIndex}>
                                    <td>{_material.isInlineItem ? "NON CATALOG" : category?.name}</td>
                                    <td>{_material.manufacturer}</td>
                                    <td>{_material.partNo}</td>
                                    <td>{idx(_material, _ => _.name.value) || ""}</td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Unit Cost"
                                            {..._material.costPerItem}
                                            onChange={(e) => itemChangeHandler(e, materialIndex)}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={true}
                                            placeholder="Sale Amount"
                                            {..._material.saleAmount}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className={'input-block-sm'}
                                            placeholder="Quantity"
                                            {..._material.qty}
                                            disabled={isDisabled}
                                            onChange={(e) => itemChangeHandler(e, materialIndex)}
                                        />
                                    </td>
                                    <td className="text-right">
                                        ${parseFloat(_material.total.value).toFixed(2) || parseFloat(_material.saleAmount.value).toFixed(2) || 0}
                                    </td>
                                    <td>
                                        {!isDisabled && <DeleteIcon
                                            className="deleteicon"
                                            onClick={() => removeItem(materialIndex)}
                                        />}
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td>
                                {!isDisabled && <span
                                    className="add-material"
                                    onClick={() => setExpandAddMaterial(!expandAddMaterial)}
                                    aria-controls="example-collapse-texts"
                                    aria-expanded={expandAddMaterial}
                                >
                                    <AddIcon /> Add Material
                                </span>}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-right table-text-sm">Sub Total</td>
                            <td className="text-right table-text-sm">
                                ${parseFloat(idx(total, (_) => _.material.sale) || 0).toFixed(2)}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <Collapse in={expandAddMaterial}>
                <div id="example-collapse-texts">
                    <div className="row m-0 mb-2">
                        <div className="col-lg-5 col-md-6 mt-3">
                            <div className="form-group">
                                <div className="input-block autocomplete-block">
                                    <label className="input-label">Catalog</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === "string") {
                                            } else if (newValue && newValue.inputValue) {
                                            } else if (newValue) {
                                                addFilter(newValue.key, "catalog");
                                                addFilter(newValue.value, "catalogid");
                                            } else {
                                                addFilter("", "catalog");
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            return filter(options, params);
                                        }}
                                        options={materialState.categories.items}
                                        getOptionLabel={(option) => {
                                            // e.g value selected with enter, right from the input
                                            if (typeof option === "string") {
                                                return option;
                                            }
                                            return option.key;
                                        }}
                                        onInputChange={(event, newValue) => {
                                            addFilter(newValue.key, "catalog");
                                            addFilter(newValue.value, "catalogid");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Catalog" />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="form-group">
                                <div className="input-block autocomplete-block">
                                    <label className="input-label">Manufacturer</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === "string") {
                                            } else if (newValue && newValue.inputValue) {
                                            } else if (newValue) {
                                                addFilter(newValue.value, "manufacturer");
                                            } else {
                                                addFilter("", "manufacturer");
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            return filter(options, params);
                                        }}
                                        options={manufacturerDropDown()}
                                        getOptionLabel={(option) =>
                                            option.key ? option.key : ""
                                        }
                                        getOptionSelected={(option, value) => {
                                            //nothing that is put in here will cause the warning to go away
                                            if (value === "") {
                                                return true;
                                            } else if (value === option.key) {
                                                return true;
                                            }
                                        }}
                                        onInputChange={(event, newValue) => {
                                            addFilter(newValue, "manufacturer");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Manufacturer" />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-3">
                            <CustomInput
                                placeholder="Enter part no"
                                onChange={(e) => {
                                    if (e.target.value && e.target.value.length > 2) {
                                        addFilter(e.target.value, "partNo");
                                    } else {
                                        addFilter("", "partNo");
                                    }
                                }}
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 mt-3">
                                    <div className="form-group">
                                        <div className="input-block autocomplete-block">
                                            <label className="input-label">Items</label>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                onChange={(event, newValue) => {
                                                    if (typeof newValue === "string") {
                                                    } else if (newValue && newValue.inputValue) {
                                                        // this.AddItemDialogHandler();
                                                    } else if (newValue) {
                                                        addItem(newValue.value);
                                                    }
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    addFilter(newValue, "item");
                                                }}
                                                options={itemsDropDownOption()}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === "string")
                                                        return option;
                                                    if (option.inputValue)
                                                        return option.inputValue;
                                                    return option.key;
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    if (params.inputValue !== "") {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            key: `Add "${params.inputValue}"`,
                                                        });
                                                    }
                                                    return filtered;
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Item" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-8 mt-3">
                                    <CustomInput
                                        type="number"
                                        placeholder="Enter unit cost"
                                        onChange={(e) => {
                                            if (e.target.value >= 0)
                                                addFilter(e.target.value, "costPerItem");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mt-3">
                                    <CustomInput
                                        type="number"
                                        placeholder="Enter quantity"
                                        onChange={(e) => {
                                            if (e.target.value >= 0) addFilter(e.target.value, "qty");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-3">
                            <CustomInput
                                label={'Total'}
                                value={(materialState.totalValue || 0).toFixed(2)}
                                disabled={true}
                            />
                        </div>

                        {/* <div className="col-12 d-flex my-3">
                            <Button type="submit" className="secondarybtn secondarybtn-outline btn-sm ms-auto me-3">RESET</Button>
                            <Button type="submit" className="primarybtn btn-sm">ADD</Button>
                        </div> */}
                    </div>


                </div>

            </Collapse>

            <ProfitMarginLock
                open={materialState.profitMarginDialogState}
                lockIconChange={() => setConfirmed()}
                dialogClose={profitMarginDialogHandler}
                moduleName="Task"
                modalHeading={"Sale Amount Lock changes"}
                text={"sale Amount"}
                message={'Are you sure you want to change the Project profit margin? - Any items using default profit margins will also be updated to this value'}
            />
        </>
    );

}
