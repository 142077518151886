import {
  SAVE_PO_DATA,
  RESET_PO_DATA
} from '../constants/action.constant'

const initialState = {
  rfqId: null,
  items: [],
  vendorType: null,
  vendorId: null,
  quotationId: null
}

const savePOData = (state, action) => {
  const { rfqId, items, vendorType, vendorId, quotationId } = action.data

  return ({
    ...state,
    rfqId,
    items,
    vendorType,
    vendorId,
    quotationId
  })
}

const resetPOData = (state) => {
  return {
    ...state,
    ...initialState
  }
}

const poReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PO_DATA: return savePOData(state, action)
    case RESET_PO_DATA: return resetPOData(state, action)
    default: return state
  }
}

export default poReducer
