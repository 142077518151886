import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CATALOGS,
  MATERIAL,
  SUPPLIERS,
  SUPPLIERS_LIST,
} from "src/constants/route-paths";
import {
  addVendor,
  getVendor,
  editVendor,
  addContact,
} from "src/actions/vendor-management.action";
import _ from "lodash";
import idx from "idx";
import { getVendorCategories } from "src/actions/inventory.action";
import { Link } from "react-router-dom";
import { withRouter } from "src/util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import Collapse from 'react-bootstrap/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'react-bootstrap/Button';
import CustomInput from "src/components/inputs/new-input";
import { useState } from "react";
import { supplierInputFields } from "./data/inputs";
import { supplierContactFields } from "./data/contact";
import { useEffect } from "react";
import { ValidateField } from "src/util/validators";

function AddSupplier({ writePermission, ...props }) {
  const [fields, setFields] = useState(supplierInputFields);
  const [state, setState] = useState({
    categories: [],
    contacts: [],
    disable: false,
    isDialog: false,
    showContacts: true,
    showCatalogs: true,
  })
  const open = idx(props, (_props) => _props.open);

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    if (idx(props, (_props) => _props.open)) {
      setState(prev => ({
        ...prev,
        isDialog: props.isDialog || true
      }))
    } else {
      addContact();
      if (idx(props, (_props) => _props.params.supplierId)) {
        props.getVendor(props.params.supplierId).then((vendor) => {
          editVendor(vendor);
        });
      }
    }
  }

  const fetchVendorCategories = (id) => {
    props.getVendorCategories(id).then((categories) => {
      categories.map((category) => ({
        value: category.id,
        key: category.name,
      }));
      setState(prev => ({
        ...prev,
        categories,
      }))
    });
  };


  const editVendor = (vendor) => {
    const contacts = vendor.contacts.map((contact) => {
      const ConFields = JSON.parse(JSON.stringify(supplierContactFields));
      ConFields.firstName.value = contact.firstName;
      ConFields.lastName.value = contact.lastName;
      ConFields.designation.value = contact.designation;
      ConFields.email.value = contact.email;
      ConFields.phoneNumber.value = contact.phoneNumber;
      ConFields.isPrimary.value = contact.primary ? contact.primary : false;
      ConFields.id = contact.id;
      return ConFields;
    });

    fetchVendorCategories(vendor.id);
    setState(prev => ({
      ...prev,
      contacts,
      edit: true,
      vendorId: vendor.id,
      disable: true,
    }))

    setFields(prev => ({
      ...prev,
      vendorType: { ...prev.vendorType, value: vendor.type ? vendor.type : "Supplier" },
      businessName: { ...prev.businessName, value: vendor.businessName },
      abn: { ...prev.abn, value: vendor.abn },
      street_address: { ...prev.street_address, value: vendor.street_address },
      suburb: { ...prev.suburb, value: vendor.suburb },
      state: { ...prev.state, value: vendor.state },
      postcode: { ...prev.postcode, value: vendor.postcode },
      country: { ...prev.country, value: vendor.country },
      phoneNumber: { ...prev.phoneNumber, value: vendor.phoneNumber },
      email: { ...prev.email, value: vendor.email },
    }));
  };

  const inputChangeHandler = (e, fieldName = "") => {
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "phoneNumber") { name = fieldName; }
    const { error, valid } = ValidateField({ value, validation: fields[name].validation });
    setFields(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
        touched: true,
        error: error,
        valid: valid
      }
    }))
  };

  const contactChangeHandler = (e, index, fieldName = "") => {
    const contacts = state.contacts;
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "phoneNumber") { name = fieldName; }
    if (fieldName === "isPrimary") {
      value = e.target.checked;
      if (e.target.checked) {
        contacts.forEach((contact, i) => {
          if (i !== index) { contacts[i][name].value = false; }
        });
      }
    }
    if (fieldName === "firstName") { name = fieldName; }
    if (fieldName === "lastName") { name = fieldName; }
    contacts[index][name].value = value;
    const { error, valid } = ValidateField({ name, value, validation: contacts[index][name].validation });
    contacts[index][name].error = error;
    contacts[index][name].valid = valid;
    contacts[index][name].touched = true;
    setState(prev => ({ ...prev, contacts }))
  };

  const formatContactData = (contact) => {
    return {
      isPrimary: contact.isPrimary.value,
      firstName: contact.firstName.value,
      lastName: contact.lastName.value,
      email: contact.email.value,
      phoneNumber: contact.phoneNumber.value,
      designation: contact.designation.value,
      id: contact.id ? contact.id : undefined,
    };
  };

  const submitForm = () => {
    const { contacts, vendorId, edit, isDialog } = state;
    let _fields = _.cloneDeep(fields)
    let isFormValid = true;
    for (let key in _fields) {
      const { error, valid } = ValidateField(_fields[key]);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    contacts.forEach((contact, i) => {
      for (let key in contact) {
        if (key !== "id") {
          const { error, valid } = ValidateField(contact[key]);
          contacts[i][key].touched = true;
          contacts[i][key].valid = valid;
          contacts[i][key].error = error;
          isFormValid = isFormValid && valid;
        }
      }
    });
    if (!isFormValid) {
      setState(prev => ({ ...prev, contacts }))
      setFields(_fields)
    } else {
      const data = {
        businessName: _fields.businessName.value,
        abn: _fields.abn.value,
        email: _fields.email.value,
        phoneNumber: _fields.phoneNumber.value,
        street_address: _fields.street_address.value,
        suburb: _fields.suburb.value,
        state: _fields.state.value,
        postcode: _fields.postcode.value,
        country: _fields.country.value,
        type: _fields.vendorType.value,
      };
      if (edit) {
        const NewContact = contacts.filter((contact) => {
          if (!contact.id) { return contact; }
        });
        const oldContact = contacts.filter((contact) => {
          if (contact.id) { return contact; }
        });
        const oldContactsData = oldContact.map((contact) => formatContactData(contact));
        const newContactsData = NewContact.map((contact) => formatContactData(contact));
        data.contacts = oldContactsData;
        props.editVendor(data, vendorId).then((res) => {
          props.history(`/${SUPPLIERS}/${SUPPLIERS_LIST}`);
        }).catch(e => console.error(e));
        if (newContactsData.length > 0) {
          props.addContact(newContactsData, vendorId);
        }
      } else {
        const contactsData = contacts.map((contact) => formatContactData(contact));
        data.contacts = contactsData;
        props.addVendor(data).then((res) => {
          if (isDialog) {
            props.handleClose();
          } else {
            props.history(`/${SUPPLIERS}/${SUPPLIERS_LIST}`);
          }
        }).catch(e => console.error(e));
      }
    }
  };

  const cancelHandler = () => {
    const { isDialog } = state;
    if (isDialog) {
      props.handleClose();
    } else {
      props.history(`/${SUPPLIERS}/${SUPPLIERS_LIST}`);
    }
  };

  const addContact = () => {
    const contacts = state.contacts;
    contacts.push(JSON.parse(JSON.stringify(supplierContactFields)));
    setState(prev => ({ ...prev, contacts }))
  };

  const removeContact = (index) => {
    const contacts = state.contacts;
    contacts.splice(index, 1);
    setState(prev => ({ ...prev, contacts }))
  };

  const enableEdit = () => {
    setState(prev => ({ ...prev, disable: false }))
  };

  return (
    <div className="purchase-order-block">
      <div className="row">
        <div className="w-auto">
          {!open && (<>
            <h2 className="content-heading text-uppercase">
              {state.edit ? "Edit Supplier" : "Add a Supplier"}
            </h2>
            <div className="col-12 breadcrumb-block p-0">
              <Breadcrumb>
                <Breadcrumb.Item onClick={cancelHandler}>Suppliers</Breadcrumb.Item>
                <Breadcrumb.Item active>{state.edit ? "Edit Supplier" : "Add a Supplier"}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </>)}
        </div>
        {state.edit && state.disable && writePermission && (<div className="w-auto ms-auto text-right">
          <button
            type="submit"
            className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary mb-2"
            onClick={enableEdit}>
            Edit
          </button>
        </div>)}
      </div>

      <div className="col-12 customer-form-block">
        <div className="row m-0">
          <div className="col-12 customer-form-heading mb-3">
            <h2 className="content-heading text-uppercase mb-0">Business Information</h2>
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.businessName}
              onChange={(e) => inputChangeHandler(e, fields.businessName.name)}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.abn}
              onChange={(e) => inputChangeHandler(e, fields.abn.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.street_address}
              onChange={(e) => inputChangeHandler(e, fields.street_address.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.suburb}
              onChange={(e) => inputChangeHandler(e, fields.suburb.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.state}
              onChange={(e) => inputChangeHandler(e, fields.state.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.postcode}
              onChange={(e) => inputChangeHandler(e, fields.postcode.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.country}
              onChange={(e) => inputChangeHandler(e, fields.country.name)}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.phoneNumber}
              onChange={(e) => inputChangeHandler(e, fields.phoneNumber.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <CustomInput
              disabled={state.disable}
              {...fields.email}
              onChange={(e) => inputChangeHandler(e, fields.email.name)}
            />
          </div>

        </div>
      </div>

      <div className="col-12 customer-form-block mt-3">
        <div className="col-12 customer-form-heading mb-3">
          <div className="d-flex">
            <h2 className="content-heading text-uppercase my-auto me-auto">Contacts</h2>
            <div className="expand-icon my-auto">
              <ExpandMoreIcon
                onClick={() => setState(prev => ({ ...prev, showContacts: !prev.showContacts }))}
                aria-controls="example-collapse-text"
                aria-expanded={state.showContacts}
              />
            </div>
          </div>

        </div>
        <Collapse in={state.showContacts}>
          <div id="example-collapse-text2">
            {state.contacts.map((contact, index) => {
              return (
                <div key={`contact-${index}`} className="row m-0">
                  <div className="col-12">
                    <hr></hr>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <Form>
                        <div className="table-checkbox table-checkbox-ch text-left">
                          <Form.Check
                            type={'checkbox'}
                            label={`Set as primary`}
                            name={contact.isPrimary.name}
                            id={`${index}-isprimary`}
                            disabled={state.disable}
                            checked={contact.isPrimary.value}
                            onChange={(e) => contactChangeHandler(e, index, contact.isPrimary.name)}
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <CustomInput
                      {...contact.firstName}
                      disabled={state.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.firstName.name)}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mb-3">
                    <CustomInput
                      {...contact.lastName}
                      disabled={state.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.lastName.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <CustomInput
                      {...contact.email}
                      disabled={state.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.email.name)}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mb-3">
                    <CustomInput
                      {...contact.phoneNumber}
                      disabled={state.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.phoneNumber.name)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <CustomInput
                      {...contact.designation}
                      disabled={state.disable}
                      onChange={(e) => contactChangeHandler(e, index, contact.designation.name)}
                    />
                  </div>
                  {!state.disable && <div className="col-lg-4 col-md-6 d-flex mb-3 mt-auto">
                    <button type="button" onClick={() => removeContact(index)} className="ms-auto primarybtn cancelbtn btn btn-primary text-uppercase">
                      Remove Contact
                    </button>
                  </div>}
                  <div className="col-12">
                    <hr></hr>
                  </div>
                </div>
              )
            })}

            {!state.disable && <div className="row m-0">
              <div className="col-12 d-flex mb-3">
                <button type="button" onClick={addContact} className="ms-auto primarybtn btn btn-primary text-uppercase">
                  Add Contact
                </button>
              </div>
            </div>}
          </div>
        </Collapse>
      </div>

      {state.categories.length > 0 && <div className="col-12 customer-form-block mt-3">
        <div className="col-12 customer-form-heading mb-3">
          <div className="d-flex">
            <h2 className="content-heading text-uppercase my-auto me-auto">Catalogs</h2>
            <div className="expand-icon my-auto">
              <ExpandMoreIcon
                onClick={() => setState(prev => ({ ...prev, showCatalogs: !prev.showCatalogs }))}
                aria-controls="example-collapse-text"
                aria-expanded={state.showCatalogs}
              />
            </div>
          </div>

        </div>
        <Collapse in={state.showCatalogs}>
          <div id="example-collapse-text2">
            <ul>
              {state.categories.map((category, index) => {
                return (<li key={category.value || `category-${index}`}>
                  <Link to={`/${CATALOGS}/${MATERIAL}?id=${category.id}`}>
                    {category.name}
                  </Link>
                </li>)
              })}
            </ul>
          </div>
        </Collapse>
      </div>}

      <div className="col-12 mt-3">
        {!state.disable && writePermission && (
          <Button
            type="submit"
            className="ms-auto me-3 secondarybtn"
            onClick={submitForm}>
            SAVE
          </Button>)}
        <Button
          type="submit"
          className="secondarybtn cancelbtn me-auto"
          onClick={cancelHandler}>
          CANCEL
        </Button>
      </div>
    </div>
  );

}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getVendorCategories: bindActionCreators(getVendorCategories, dispatch),
  addVendor: bindActionCreators(addVendor, dispatch),
  getVendor: bindActionCreators(getVendor, dispatch),
  editVendor: bindActionCreators(editVendor, dispatch),
  addContact: bindActionCreators(addContact, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSupplier));
