import React from 'react'
import Input from './../../components/inputs/input-simple'
import { Grid, InputAdornment } from '@material-ui/core'
import './scss/project-management.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'date-fns'
import { createQuotation, uploadFileRfq } from './../../actions/project-management.action'
import { getCategories } from './../../actions/inventory.action'
import FileUploader from './../../components/file-uploader'
import DeleteIcon from '@material-ui/icons/Delete'
import { numberFormat } from '../../util/currency-formatter'
import { fileValidator } from 'src/util/validators/file'

class SubmitQuote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			materialColumns: ['Category', 'Items', 'Quantity', 'Manufacturer', 'Part No', 'Unit', 'Quote Submitted'],
			laborColumns: ['Tasks', 'Description', 'Quote Submitted'],
			itemInput: {
				name: 'quoteAmount',
				type: 'number',
				value: 0,
			},
			statusInput: {
				name: 'status',
				type: 'switch',
				value: true
			},
			total: 0,
			materialItems: [],
			quotationData: {},
			categories: []
		}
	}

	async componentDidMount() {
		let categoryResult = await this.props.getCategories()
		let categories = {}
		Array.isArray(categoryResult) && categoryResult.map(category => categories[category.id] = category.name)
		this.setState({ categories })

		const data = this.props.quotationData.items && this.props.quotationData.items.map(item => {
			const _data = {
				...item,
			}
			if (this.props.quotationData.reviewQuote) {
				_data.status = JSON.parse(JSON.stringify(this.state.statusInput))
				_data.quote = item.quote
			} else if (this.props.quotationData.editQuote) {
				_data.quote = JSON.parse(JSON.stringify(this.state.itemInput))
				_data.quote.value = item.quote
			} else {
				_data.quote = JSON.parse(JSON.stringify(this.state.itemInput))
			}
			return _data
		})
		this.props.setMaterialItems(data ? data : [])
		this.setState({ materialItems: data ? data : [], quotationData: this.props.quotationData })
	}

	editRFQHandler = (rfq, id) => {
		this.setState({ projectData: rfq.items, rfqId: id })
		this.rfqDialogHandler()
	}

	changeQuoteHandler = (e, index) => {
		const materialItems = this.state.materialItems
		if (e.target.type === 'checkbox') {
			materialItems[index].status.value = e.target.checked
		} else {
			if (e.target.value < 0) {
				return
			}
			materialItems[index].quote.value = e.target.value
		}
		this.props.setMaterialItems(materialItems)
		this.setState({ materialItems })
	}

	calculateTotal = () => {
		const materialItems = this.state.materialItems
		let total = 0
		materialItems.forEach(item => {
			if (item.status.value) {
				total = total + parseFloat(item.quote || item.total || 0)
			}
		})
		return total
	}

	seletectFile = () => {
		document.getElementById("fileInput").click();
	}

	addFileAttachment = async (e) => {
		let { quotationData = {} } = this.state

		if (e.target.files.length < 1) {
			this.props.errorSnackBar('Please select a file!')
			return
		} else if (e.target.files.length > 5) {
			this.props.errorSnackBar('Select only 5 files at a time!')
			return
		} else {
			let attachmentArr = [], isValid = true
			Array.from(e.target.files).map(file => {
				const fileValidation = fileValidator({ file, acceptedFileSize: 10 });
				const { validFileSize, validFileType, error } = fileValidation
				if (!validFileSize.valid && !error) {
					isValid = false
					this.props.errorSnackBar(validFileSize.error)
				}
				else if (!validFileType.valid && !error) {
					isValid = false
					this.props.errorSnackBar(validFileType.error)
				} else if (error) {
					isValid = false
					this.props.errorSnackBar(error.error)
				} else attachmentArr.push(file)
			})
			if (isValid) {
				if (Array.isArray(quotationData.attachmentArr))
					quotationData.attachmentArr.push(...attachmentArr)
				else
					quotationData.attachmentArr = attachmentArr

				this.setState({ quotationData }, () => this.props.rfqFileAttached(quotationData))
				e.target.value = ''
			}
		}
	}

	removeFile = (index, type) => {
		let { quotationData = {} } = this.state
		if (type === 'new') {
			if (Array.isArray(quotationData.attachmentArr))
				quotationData.attachmentArr.splice(index, 1)
		}
		if (type === 'old') {
			if (Array.isArray(quotationData.attachment))
				quotationData.attachment.splice(index, 1)
		}
		this.setState({ quotationData }, () => this.props.rfqFileAttached(quotationData))
	}

	render() {
		const { reviewQuote, vendorType, vendorName } = this.state.quotationData
		const { projectDetails, viewPurchaseOrder = false, purchaseOrderId } = this.props
		const {
			materialColumns,
			materialItems,
			laborColumns,
			quotationData,
			categories
		} = this.state

		const attachments = (quotationData.attachment && quotationData.attachment.map((attach, i) => (
			<div key={i} className="attachedFiles">
				<p style={{ margin: '0 10px 0 0' }}><a href={attach.url} rel="noopener noreferrer" target="_blank">{attach.name}</a></p>
				{!viewPurchaseOrder && <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'old')}><DeleteIcon /></p>}
			</div>
		))) || []


		Array.isArray(quotationData.attachmentArr) && quotationData.attachmentArr.map((doc, i) => {
			attachments.push(
				<div key={i} className="attachedFiles">
					<p style={{ margin: '0 10px 0 0' }}>{doc.name}</p>
					{!viewPurchaseOrder && <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'new')}><DeleteIcon /></p>}
				</div>
			)
		})

		return (
			<div className='submitQuoteContainer'>
				<Grid container className='descriptionContainer'>
					<Grid sm={8} item >
						<Grid container>
							<Grid md={2} xs={5} item>
								<p>Description :</p>
							</Grid>
							<Grid md={9} xs={7} item>
								<p> <b>{quotationData.description}</b></p>
							</Grid>
						</Grid>
						<Grid container>
							<Grid md={2} xs={5} item>
								<p>Vendor Type: </p>
							</Grid>
							<Grid md={3} xs={7} item>
								<p> <b>{vendorType}</b></p>
							</Grid>
							{viewPurchaseOrder && <>
								<Grid md={2} xs={5} item>
									<p>Purchase Order No: </p>
								</Grid>
								<Grid md={4} xs={7} item>
									<p> <b>{purchaseOrderId}</b></p>
								</Grid></>
							}
							<Grid md={3} xs={5} item>
								<p>{reviewQuote ? 'Quote Request No:' : 'RFQ No:'}</p>
							</Grid>
							<Grid md={3} xs={7} item>
								<p> <b>{quotationData.quotationId}</b></p>
							</Grid>
							<Grid md={2} xs={5} item>
								<p>Due Date: </p>
							</Grid>
							<Grid md={3} xs={7} item>
								<p> <b>{format(new Date(projectDetails.dateOfCreation), 'do MMMMMM yyyy')}</b></p>
							</Grid>
							<Grid md={3} xs={5} item>
								<p>Vendor: </p>
							</Grid>
							<Grid md={4} xs={7} item>
								<p> <b>{vendorName}</b></p>
							</Grid>
						</Grid>
					</Grid>
					<Grid sm={4} item className='stageLabel attachFile '>
						<Grid xs={12} item style={{ alignItems: 'unset' }}>
							{viewPurchaseOrder ? <p>
								<span className="attachmentText" style={{ marginRight: '15px' }}>Attachments</span>
							</p> :
								<FileUploader
									fileHandler={(files) => this.addFileAttachment({ target: { files, type: 'file', name: 'attachments' } })}
								/>
							}
							{attachments}
						</Grid>
					</Grid>

				</Grid>
				<div className="mobileQuoteTable"><div className="mobileQuoteTableCol"><div className="mobileQuoteTableresponsive">
					{vendorType === 'Supplier' ? <Grid container style={{ background: '#f4f4f4' }}>
						{materialColumns.map((column, index) => (
							<Grid key={index}
								xs={column === 'Unit' || column === 'Quantity' || column === 'Part No' ? 1 : 2}
								item style={{ padding: "0 15px" }}><p><b>{column}</b></p>
							</Grid>
						))}
						{reviewQuote && !viewPurchaseOrder && projectDetails.type !== 'TENDER' && <Grid xs={1} item style={{ padding: "0 15px" }}><p><b>Approve</b></p></Grid>}
					</Grid> :
						<Grid container style={{ background: '#f4f4f4' }}>
							{laborColumns.map((column, index) => (
								<Grid key={index} xs={3} item style={{ padding: "0 15px" }}><p><b>{column}</b></p></Grid>
							))}
							{reviewQuote && !viewPurchaseOrder && projectDetails.type !== 'TENDER' && <Grid xs={2} item style={{ padding: "0 15px" }}><p><b>Approve</b></p></Grid>}
						</Grid>
					}
					{vendorType === 'Supplier' ?
						<Grid container>
							{materialItems.map((item, index) => {
								return (
									<Grid key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', alignItems: 'center' }} >
										<Grid xs={2} item style={{ padding: "0 15px" }}><p>{categories[item.categoryId]}</p></Grid>
										<Grid xs={2} item style={{ padding: "0 15px" }}><p>{item.item}</p></Grid>
										<Grid xs={1} item style={{ padding: "0 15px" }}><p>{item.qty}</p></Grid>
										<Grid xs={2} item style={{ padding: "0 15px" }}><p>{item.manufacturer}</p></Grid>
										<Grid xs={1} item style={{ padding: "0 15px" }}><p>{item.partNo}</p></Grid>
										<Grid xs={1} item style={{ padding: "0 15px" }}><p>{item.unit}</p></Grid>
										<Grid xs={2} item style={{ padding: "0 15px" }}>
											{reviewQuote
												?
												<p>{numberFormat(item.quote)}</p>
												: <Input {...item.quote} simple onChange={(e) => this.changeQuoteHandler(e, index)}
													inputProps={{
														startAdornment: <InputAdornment position='start'>$</InputAdornment>,
													}} />}
										</Grid>
										{projectDetails.type !== 'TENDER' && <Grid xs={1} style={{ padding: "0 15px" }} item className='switchContainer'>{reviewQuote && !viewPurchaseOrder ? <Input {...item.status} simple onChange={(e) => this.changeQuoteHandler(e, index)} /> : ''}</Grid>}
									</Grid>
								)
							})}
						</Grid>
						:
						<Grid container>
							{materialItems.map((item, index) => (
								<Grid key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px', alignItems: 'center' }} >
									<Grid xs={3} item><p>{item.name || item.taskName}</p></Grid>
									<Grid xs={3} item style={{ padding: "0 10px" }}><p>{item.description || item.taskDescription}</p></Grid>
									<Grid xs={3} item style={{ padding: "0 15px", textAlign: "left" }}>
										{reviewQuote
											?
											<p>{numberFormat(item.quote || item.total)}</p>
											: <Input {...item.quote} simple onChange={(e) => this.changeQuoteHandler(e, index)}
												inputProps={{
													startAdornment: <InputAdornment position='start'>$</InputAdornment>,
												}} />}
									</Grid>
									{projectDetails.type !== 'TENDER' && <Grid xs={3} style={{ padding: "0 15px" }} item className='switchContainer'>{reviewQuote && !viewPurchaseOrder ? <Input {...item.status} simple onChange={(e) => this.changeQuoteHandler(e, index)} /> : ''}</Grid>}
								</Grid>
							))}
						</Grid>
					}
					{reviewQuote && <div>
						<p className='totalLabel'><b>Total: </b>{numberFormat(this.calculateTotal())}</p>
					</div>}
				</div></div></div></div>
		)
	}
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	createQuotation: bindActionCreators(createQuotation, dispatch),
	uploadFileRfq: bindActionCreators(uploadFileRfq, dispatch),
	getCategories: bindActionCreators(getCategories, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitQuote);