import './style.css'

const css = {
  'notifications-container': 'notifications-container',
  'notifications-container--tc': 'notifications-container--tc',
  'notifications-container--tr': 'notifications-container--tr',
  'notifications-container--tl': 'notifications-container--tl',
  'notifications-container--bc': 'notifications-container--bc',
  'notifications-container--br': 'notifications-container--br',
  'notifications-container--bl': 'notifications-container--bl',
  'notification--buttons-1': 'notification--buttons-1',
  'notification-button': 'notification-button',
  'notification--buttons-2': 'notification--buttons-2',
  'notification-buttons': 'notification-buttons',
  'notifications-container--b': 'notifications-container--b',
  'notifications-container--t': 'notifications-container--t',
  'notification-wrapper': 'notification-wrapper',
  'notification-wrapper-enter': 'notification-wrapper-enter',
  'top20px-enter': 'top20px-enter',
  'notification-wrapper-leave': 'notification-wrapper-leave-',
  'top20px-leave': 'top20px-leave',
  'right20px-enter': 'right20px-enter',
  'right20px-leave': 'right20px-leave',
  'left20px-enter': 'left20px-enter',
  'left20px-leave': 'left20px-leave',
  'bottom20px-enter': 'bottom20px-enter-We',
  'bottom20px-leave': 'bottom20px-leave',
  'top0-enter': 'top0-enter',
  'top0-leave': 'top0-leave',
  notification: 'notification',
  'notification-image-container': 'notification-image-container',
  'notification-image': 'notification-image',
  'notification-icon': 'notification-icon',
  'notification-meta': 'notification-meta',
  'notification-close-button-container': 'notification-close-button-container',
  'notification-close-button': 'notification-close-button',
  'notification-button-text': 'notification-button-text',
  'notification-title': 'notification-title',
  'notification-message': 'notification-message',
  'notification--dismissible': 'notification--dismissible',
  'notification--default': 'notification--default',
  'notification--success': 'notification--success',
  'notification--info': 'notification--info',
  'notification--warning': 'notification--warning',
  'notification--error': 'notification--error',
  'notification--loading': 'notification--loading',
  rotating: 'rotating'
}

// media breakpoint - small screen min width
var smallScreenMin = 768

// default className for NotificationsSystem component
var notificationsSystemClassName = css['notifications-system']

// default className for NotificationsContainer component
var notificationsContainerClassName = {
  main: css['notifications-container'],
  position: function position(_position) {
    return css['notifications-container--' + _position]
  }
}

// default transition for Notification component
var notificationsContainerTransition = {
  enterTimeout: 500,
  leaveTimeout: 900,
  name: {
    enter: css['notification-wrapper-enter'],
    leave: css['notification-wrapper-leave']
  }
}

// default className for Notification component
var notificationClassName = {
  main: css.notification,
  wrapper: css['notification-wrapper'],
  meta: css['notification-meta'],
  title: css['notification-title'],
  message: css['notification-message'],
  // `fa` corresponds to font-awesome's class name
  icon: 'fa ' + css['notification-icon'],
  imageContainer: css['notification-image-container'],
  image: css['notification-image'],
  status: function status(_status) {
    return css['notification--' + _status]
  },
  dismissible: css['notification--dismissible'],
  buttons: function buttons(count) {
    if (count === 0) {
      return ''
    } else if (count === 1) {
      return css['notification--buttons-1']
    } else if (count === 2) {
      return css['notification--buttons-2']
    }
    return css['notification-buttons']
  },
  closeButtonContainer: css['notification-close-button-container'],
  closeButton: 'fa ' + css['notification-close-button'],
  button: css['notification-button'],
  buttonText: css['notification-button-text']
}

const notificationTheme = {
  smallScreenMin: smallScreenMin,
  notificationsSystem: {
    className: notificationsSystemClassName
  },
  notificationsContainer: {
    className: notificationsContainerClassName,
    transition: notificationsContainerTransition
  },
  notification: {
    className: notificationClassName
  }
}

export default notificationTheme;
