import React, { Fragment, useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import Loader from "./components/loader";
import AuthRoutes from "./routes/route-management/auth-routes";
import { getUserData } from "./actions/user-management.action";
import Login from "./routes/login";
import { getAccessToken, removeAccessToken } from "./util/auth";
// import { getProjectCategory } from "./actions/global-setting.action";
import { saveProjectType } from "./actions/project-management.action";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { history } from "./util/history";

TopBarProgress.config({
  barColors: {
    0: "#ffff00",
    "1.0": "#ffff00",
  },
  shadowColor: "#fff",
});

const Gatekeeper = () => {
  const user = useSelector(state => state.user) || {}
  const common = useSelector(state => state.common) || {}

  let { showLoader, overlay } = common
  let { isLoggedIn } = user;

  const [localLoader, setLocalLoader] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  history.navigate = useNavigate();

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    if (!isLoggedIn) {
      if (!getAccessToken()) {
        setLocalLoader(false);
        removeAccessToken();
        navigate(`/`);
      } else {
        let projectType = Cookies.get("projectType");
        await dispatch(getUserData())
        // await dispatch(getProjectCategory("PROJECTCATEGORIES"))
        if (projectType) await dispatch(saveProjectType(projectType))
        setLocalLoader(false);
      }
    } else {
      setLocalLoader(false);
    }
  }

  return (
    <Fragment>
      {localLoader ?
        <Loader isOpen={true} />
        :
        <>
          <Loader isOpen={overlay} />
          {showLoader && <TopBarProgress />}
          {isLoggedIn ? <AuthRoutes /> : <Login />}
        </>
      }
    </Fragment>
  )
}


export default Gatekeeper;
