import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "src/util/with-router";
import { TransitionGroup } from "react-transition-group";
import ProjectTempalteList from './project'
import TaskTempalteList from './task'
import ItemTempalteList from './item'
import RBACApplicationAuth from "src/layout/rbac";
import * as paths from "src/constants/route-paths";

function CustomRoutes({ location }) {
    let element = useRoutes([
        {
            path: paths.PROJECT_TEMPLATE,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={ProjectTempalteList} />,
        },
        {
            path: paths.TASK_TEMPLATE,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={TaskTempalteList} />,
        },
        {
            path: paths.ITEM_TEMPLATE,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={ItemTempalteList} />,
        },
    ]);

    return element;
}

class Root extends React.PureComponent {
    render() {
        const { location } = this.props;
        return (
            <div className="clearfix" style={{ width: "100%" }}>
                <TransitionGroup>
                    <CustomRoutes location={location} />
                </TransitionGroup>
            </div>
        );
    }
}
export default withRouter(Root);
