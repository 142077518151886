import React, { useEffect, useState } from 'react';
import './../modal/modal.scss';
import CustomInput from '../inputs/new-input';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatTaskData } from './formatters/task';
import { formatSubTaskData } from './formatters/subtask';
import { formatProjectData } from './formatters/project';
import { useDispatch } from 'react-redux';
import { addItemTemplate, addTaskTemplate } from 'src/actions/template-management.action';

function SaveTemplate({ open, data = {}, type, dialogClose }) {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch()

  useEffect(() => {
    if (!name) setName(data.name || data.description)
  }, [data])

  const saveHandler = () => {
    if (!name) {
      setError('Please fill the name to save the template');
      return;
    }
    let req = {}
    switch (type) {
      case 'project': {
        req = formatProjectData(data, name);
        break;
      }
      case 'task': {
        req = formatTaskData(data, name);
        dispatch(addTaskTemplate(req)).then((res) => {
          closeDialog()
        }).catch(e => console.error(e))
        break;
      }
      case 'subtask': {
        req = formatSubTaskData(data, name);
        dispatch(addItemTemplate(req)).then((res) => {
          closeDialog()
        }).catch(e => console.error(e))
        break;
      }
      default: break;
    }
  }

  const closeDialog = () => {
    setName('')
    setError('')
    if (dialogClose) dialogClose()
  }
  return (
    <Modal
      show={open}
      onHide={closeDialog}
      dialogClassName="modal-70w create-modal"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {type == 'task' ? 'Save as Task Template' : type == 'subtask' ? 'Save as Item Template' : type == 'project' ? 'Save as Project Template' : 'Save Template'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 mt-3">
            <CustomInput
              type='text'
              label={'Template Name'}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              required={true}
              error={error}
            />
          </div>
          <div className="col-12 d-flex mt-3">
            <Button onClick={saveHandler} className="ms-auto me-3 secondarybtn">SAVE</Button>
            <Button onClick={closeDialog} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SaveTemplate;
