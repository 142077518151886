import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { getRfq } from '../../actions/project-management.action'
import _ from 'lodash'

export function ViewRFQ(props) {
  const { rfqData } = props
  let items, allAttachments, description, vendorType

  if (_.isObject(rfqData)) {
    ({ items, attachment: allAttachments, description, vendorType } = rfqData)
  }

  items = (Array.isArray(items) && items.map(item => {
    if (_.isString(item) && !(_.isError(_.attempt(JSON.parse, item)))) {
      return JSON.parse(item)
    } else {
      return item
    }
  })) || []

  allAttachments = (Array.isArray(allAttachments) && allAttachments.map(attachment => {
    if (_.isString(attachment) && !(_.isError(_.attempt(JSON.parse, attachment)))) {
      return JSON.parse(attachment)
    } else {
      return attachment
    }
  })) || []

  return (
    <div>
      <Grid xs={12} container>
        <Grid xs={12} container style={{ flexDirection: 'row', textAlign: 'start' }}>
          <Grid sm={8} xs={12} container className="viewRFQpara">
            <Grid xs={12} container className='stageLabel' style={{ paddingRight: '20px' }}>
              <Grid md={2} xs={5} item >
                <p> Description: </p>
              </Grid>
              <Grid md={4} xs={7} item >
                <p><b>{description}</b></p>
              </Grid>
            </Grid>
            <Grid xs={12} container style={{ paddingRight: '20px' }}>
              <Grid md={2} xs={5} item >
                <p>Vendor Type: </p>
              </Grid>
              <Grid md={4} xs={7} item >
                <p><b>{vendorType}</b></p>
              </Grid>
            </Grid>
          </Grid>
          <Grid sm={4} xs={12} item className='stageLabel attachFile '>
            <p><b>Attached Documents</b></p>
            {(Array.isArray(allAttachments) && allAttachments.map(attachment =>
            (
              attachment.url ?
                <p className="attachedFiles"><a href={attachment?.url} rel="noopener noreferrer" target="_blank">{attachment.name}</a></p>
                :
                <p className="attachedFiles">{attachment.name}</p>
            )
            )) || ''}
          </Grid>
        </Grid>
      </Grid>
      {vendorType === 'Supplier' ?
        <Grid xs={12} item className="mobileCreateRfqCol" style={{ marginTop: "20px" }}>
          <Grid xs={12} className="mobileViewRFQ" >
            <Grid xs={12} className="mobileCreateRfq" >
              <Grid xs={12} container style={{ background: '#f4f4f4', margin: '0px', padding: '0px 15px' }}>
                <Grid xs={3} item>
                  <p><b>Items</b></p>
                </Grid>
                <Grid xs={3} item style={{ padding: "0px 15px" }}>
                  <p><b>Quantity</b></p>
                </Grid>
                <Grid xs={3} item style={{ padding: "0px 15px" }}>
                  <p><b>Manufacturer</b></p>
                </Grid>
                <Grid xs={3} item style={{ padding: "0px 15px" }}>
                  <p><b>Part No</b></p>
                </Grid>
              </Grid>
              <Grid xs={12} container>
                {(Array.isArray(items) && items.map((material, index) => {
                  return (
                    <Grid xs={12} key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px' }}>
                      <Grid xs={3} item><p>{material.item}</p></Grid>
                      <Grid xs={3} item style={{ padding: "0px 15px" }}><p>{material.qty} {material.unit}</p></Grid>
                      <Grid xs={3} item style={{ padding: "0px 15px" }}><p>{material.manufacturer}</p></Grid>
                      <Grid xs={3} item style={{ padding: "0px 15px" }}><p>{material.partNo}</p></Grid>
                    </Grid>
                  )
                })) || ''}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        : <Grid xs={12} item className="mobileCreateRfqCol" style={{ marginTop: "20px" }}>
          <Grid xs={12} className="mobileViewRFQ" >
            <Grid xs={12} className="mobileCreateRfq" >
              <Grid xs={12} container style={{ background: '#f4f4f4', margin: '0px', padding: '0px 15px' }}>
                <Grid xs={4} item>
                  <p><b>Task</b></p>
                </Grid>
                <Grid xs={8} item>
                  <p><b>Description</b></p>
                </Grid>
              </Grid>
              <Grid xs={12} contaier>
                {
                  (Array.isArray(items) && items.map((task, index) => {
                    return (
                      <Grid xs={12} container key={index} style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px' }}>
                        <Grid xs={4} item><p>{task.name}</p></Grid>
                        <Grid xs={8} item><p>{task.description}</p></Grid>
                      </Grid>
                    )
                  })) || ''
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
    </div>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
  getRfq: bindActionCreators(getRfq, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewRFQ)
