export const getProjectStatus = (status) => {
    switch (status) {
        case 'NOT_STARTED': return <span style={{ color: "#333" }}>Not Started</span>;
        case 'PENDING': return <span className="pending-color">Pending</span>;
        case 'IN_PROGRESS': return <span className="inprogress-color">In Progress</span>;
        case 'COMPLETED': return <span className="status-active">Completed</span>;
        case 'DLP': return <span style={{ color: "#3a6cb3" }}>DLP</span>;
        case 'LOST': return <span style={{ color: "#800080" }}>Lost</span>;
        case 'TENDER_SUBMITTED': return <span style={{ color: "#2BAA0E" }}>Submitted</span>;
        case 'ARCHIVED': return <span style={{ color: "#ac2a2a" }}>Archived</span>;
        case 'CANCELLED': return <span style={{ color: "#ac2a2a" }}>Cancelled</span>;
        default: return status;
    }
}