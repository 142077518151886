import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { categoriesFields, itemFields } from "src/constants/fields.constant";
import { updateItemAction, updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _, { isBoolean } from "lodash";
import { getCategories, getItems, getUnits } from "src/actions/inventory.action";
import { calculateSaleAmount } from "src/util/calculations/sale-amount";
import { warningSnackBar } from "src/actions/common.action";
import { filterItems, filterManufacturer } from "src/util/common";
import { getFormatedUnit } from "src/util";
import { v4 as uuidv4 } from "uuid";
import ProfitMarginLock from "src/components/profitMarginLock-dialog";
import CustomInput, { LockInput } from "src/components/inputs/new-input";
import AddItemDialog from "../add-item-dialog";
import { toUSD } from "src/util/currency-formatter";

const filter = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'any'
});

export default function Material({ material = [], newMaterial = [], total = {}, profitMargin, profitMarginType, isDisabled, handleAddMaterial, ...props }) {

    const dispatch = useDispatch()
    const [materialState, setMaterialState] = useState({
        categories: JSON.parse(JSON.stringify(categoriesFields)),
        categoriesList: [],
        itemsList: [],
        items: [],
        filterValue: {},
        item: itemFields,
        profitMarginDialogState: false,
        lockArrayValue: null,
        confirmLock: false,
        addItemDialogState: false,
    })

    useEffect(() => {
        if (!materialState.categories.items.length)
            _getCategories()
        initLoad()
    }, [])

    const initLoad = () => {
        fetchItems();
        dispatch(getUnits()).then((res) => {
            const allUnits = (res && Array.isArray(res) && res.length > 0 && res[0].units) || [];
            const units = getFormatedUnit(allUnits);
            let { item } = materialState;
            item.unit.items = units;
            setMaterialState(prev => ({
                ...prev,
                item
            }));
        });
    }

    const fetchItems = () => {
        dispatch(getItems()).then((items) => {
            const activeItems = (Array.isArray(items) && items.filter(({ status }) => status === "ACTIVE")) || [];
            setMaterialState(prev => ({
                ...prev,
                itemsList: activeItems
            }));
        });
    }

    const _getCategories = () => {
        dispatch(getCategories()).then((res) => {
            const categoriesList = res.map((category) => ({
                key: category.name,
                name: category.name,
                value: category.id,
            }));
            setMaterialState(prev => ({
                ...prev,
                categories: { ...prev.categories, items: categoriesList },
                categoriesList,
            }));
        }).catch(e => console.error(e));
    }
    const profitMarginDialogHandler = (inputValue, index) => {
        setMaterialState(prev => ({
            ...prev,
            lockArrayValue: index,
            profitMarginDialogState: !prev.profitMarginDialogState,
        }));
    };

    const itemChangeHandler = (e, index) => {
        let _temp = _.cloneDeep(material)
        switch (e) {
            case "saleAmountLock":
                profitMarginDialogHandler(e, index);
                if (materialState.profitMarginDialogState && materialState.confirmLock) {
                    _temp[index]["saleAmountLock"].value = !_temp[index]["saleAmountLock"].value;
                }
                break;
            default:
                if (e.target.name === "costPerItem" || e.target.name === "qty") {
                    if (e.target.value < 0)
                        break;
                }

                _temp[index][e.target.name].value = e.target.value;

                const is_locked = _temp[index]["saleAmountLock"].value || false;
                let saleAmount = _temp[index]["saleAmount"].value;

                if (e.target.name === "saleAmountLock") {
                    _temp[index]["saleAmountLock"].value = !_temp[index]["saleAmountLock"].value;
                }

                if (e.target.name === "costPerItem" || e.target.name === "qty") {
                    if (saleAmount <= 0 || is_locked) {
                        _temp[index]["saleAmount"].value = calculateSaleAmount({
                            cost: _temp[index]["costPerItem"].value,
                            profitMargin,
                            profitMarginType,
                        }).toFixed(2);
                    }
                }
                saleAmount = _temp[index]["saleAmount"].value;

                _temp[index]["total"].value = (parseInt(_temp[index]["qty"].value) || 0) * parseFloat(saleAmount);
        }

        updateMaterials(_temp);
    };

    const removeItem = (index) => {
        let _temp = _.cloneDeep(material)
        _temp.splice(index, 1)
        updateMaterials(_temp);
    };

    const updateMaterials = (materials, newMaterials = null) => {
        if (!Array.isArray(materials)) return;
        const { subTaskArray, subTaskArrayIndex } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["material"] = materials;
        if (newMaterials) _temp[subTaskArrayIndex]["newMaterial"] = newMaterials
        dispatch(updateSubTaskAction(_temp));
    };

    const filterItem = (id) => {
        const item = (materialState.itemsList || []).find((item) => item.id === id);
        return item;
    };

    const addItem = (value, category, tmpId) => {
        let selectedMaterials = _.cloneDeep(material);
        const itemExist = selectedMaterials.find((item) => item.id === value && item.categoryId === category);

        if (!itemExist) {
            let filters = idx(materialState, _ => _.filterValue[tmpId]) || {}
            const selectedItem = filterItem(value);
            if (!category) category = selectedItem.categoryId;

            const item = JSON.parse(JSON.stringify(materialState.item));
            item.name.value = selectedItem.item;
            item.taskId.value = selectedItem.taskId || null;
            item.id = selectedItem.id || uuidv4();
            item.manufacturer = selectedItem.manufacturer;
            item.partNo = selectedItem.partNo || selectedItem.part_no;
            item.categoryId = category;
            item.costPerItem.value = filters.costPerItem || selectedItem.cost;
            item.saleAmount.value = (filters.costPerItem || filters["saleAmountLock"] === false) ?
                (filters["saleAmount"] || selectedItem.saleAmount || selectedItem.cost) :
                (selectedItem.saleAmountLock
                    ? calculateSaleAmount({
                        cost: selectedItem.cost,
                        profitMargin,
                        profitMarginType,
                    }).toFixed(2) : selectedItem.saleAmount || selectedItem.cost) || 0;
            item.saleAmountLock.value = filters["saleAmountLock"] || selectedItem.saleAmountLock;
            item.unit.value = selectedItem.unit || null;
            item.qty.value = filters["qty"] || selectedItem.qty || 1;
            item.total.value = item.saleAmount.value * item.qty.value;

            selectedMaterials.push(item);

            let materials = getNewMaterials()
            materials = materials.filter(item => item.id != tmpId)
            updateMaterials(selectedMaterials, materials);
        } else {
            dispatch(warningSnackBar("This Item is Already Selected"));
        }
    };

    const itemsDropDownOption = (tmpId) => {
        let filters = idx(materialState, _ => _.filterValue[tmpId]) || {}
        let itemsList = _.cloneDeep(materialState.itemsList)
        let filterOptions = filterItems(itemsList, filters);
        return filterOptions;
    };

    const addFilter = (value, type, tmpId) => {
        let filters = idx(materialState, _ => _.filterValue[tmpId]) || {}
        let showMaterialLockIcon = getLockStatus(tmpId)
        if (!filters) filters = {};
        if (filters && filters[type] !== value) {
            filters[type] = value;
            if (type === "saleAmount" && !showMaterialLockIcon) {
                filters["saleAmount"] = value;
                // filters["saleAmountLock"] = !filters["saleAmountLock"];
            }
            if (type === "costPerItem" || type === "qty" || type === "saleAmount") {
                if (showMaterialLockIcon)
                    filters["saleAmount"] = calculateSaleAmount({
                        cost: parseFloat(filters["costPerItem"]),
                        profitMargin,
                        profitMarginType
                    }).toFixed(2)

                const totalvalue = (parseInt(filters["qty"]) || 1) * (parseFloat(filters["saleAmount"]) || 1);
                filters['totalValue'] = parseFloat(totalvalue)
            }
            let filterValue = materialState.filterValue;
            filterValue[tmpId] = {
                ...filters, showMaterialLockIcon,
                manufacturerOptions: manufacturerDropDown(tmpId),
                itemOptions: itemsDropDownOption(tmpId)
            }
            setMaterialState(prev => ({
                ...prev,
                filterValue: filterValue
            }));
        }
    };

    const lockMaterialIconChange = (tmpId) => {
        let filters = idx(materialState, _ => _.filterValue[tmpId]) || {}
        let filterValue = materialState.filterValue;
        let status = getLockStatus(tmpId);

        if (!status)
            filters["saleAmount"] = calculateSaleAmount({
                cost: parseFloat(filters["costPerItem"]),
                profitMargin,
                profitMarginType
            }).toFixed(2)

        let totalvalue = (parseInt(filters["qty"]) || 1) * (parseFloat(filters["saleAmount"]) || 1);
        filters['totalValue'] = parseFloat(totalvalue)

        filterValue[tmpId] = { ...filters, showMaterialLockIcon: !status }
        setMaterialState(prev => ({
            ...prev,
            filterValue: filterValue
        }));
    };

    const manufacturerDropDown = (tmpId) => {
        let filters = idx(materialState, _ => _.filterValue[tmpId]) || {}
        let itemsList = _.cloneDeep(materialState.itemsList)
        let filterOptions = filterManufacturer(itemsList, filters);
        return filterOptions;
    };

    const setConfirmed = () => {
        const { lockArrayValue } = materialState;
        let _selectedItemsList = _.cloneDeep(material);
        _selectedItemsList[lockArrayValue]["saleAmountLock"].value = !_selectedItemsList[lockArrayValue]["saleAmountLock"].value;
        _selectedItemsList.forEach((item, index) => {
            if (item["saleAmountLock"].value) {
                _selectedItemsList[index]["saleAmount"].value = calculateSaleAmount({
                    cost: item["costPerItem"].value,
                    profitMargin,
                    profitMarginType,
                });
                _selectedItemsList[index]["total"].value = (parseInt(item["qty"].value) || 0) * parseFloat(_selectedItemsList[index]["saleAmount"].value);
            }
        });
        setMaterialState(prev => ({
            ...prev,
            profitMarginDialogState: !prev.profitMarginDialogState
        }));
        updateMaterials(_selectedItemsList);
    };

    const AddItemDialogHandler = (tmpId) => {
        setMaterialState(prev => ({
            ...prev,
            addItemDialogState: !prev.addItemDialogState,
            tmpId
        }));
    };

    const AddItemDialogClose = (res = {}) => {
        if (!_.isEmpty(res)) {
            fetchItems();
            addItemFromResponse(res);
            _getCategories()
        }
        setMaterialState(prev => ({ ...prev, addItemDialogState: false }))
    }

    const addItemFromResponse = (res) => {
        let selectedMaterials = _.cloneDeep(material);

        const item = JSON.parse(JSON.stringify(materialState.item));
        item.name.value = res.item;
        item.taskId.value = null;
        item.id = res.id;
        item.manufacturer = res.manufacturer;
        item.partNo = res.part_no;
        item.categoryId = res.category_id;
        item.costPerItem.value = res.cost;
        item.saleAmount.value = (res.sale_amount_lock
            ? calculateSaleAmount({
                cost: res.cost,
                profitMargin,
                profitMarginType,
            }).toFixed(2) : res.sale_amount || res.cost) || 0;
        item.saleAmountLock.value = res.sale_amount_lock;
        item.unit.value = res.unit || null;
        item.qty.value = res.qty || 1;
        item.total.value = item.saleAmount.value * item.qty.value;

        selectedMaterials.push(item);
        let materials = getNewMaterials()
        materials = materials.filter(item => item.id != materialState.tmpId)
        updateMaterials(selectedMaterials, materials);
    }

    const getNewMaterials = () => {
        const { subTaskArray, subTaskArrayIndex } = props;
        let _temp = _.cloneDeep(subTaskArray)
        return _temp[subTaskArrayIndex]["newMaterial"] || []
    }
    const updateNewMaterials = (materials) => {
        const { subTaskArrayIndex } = props;
        dispatch(updateItemAction({ material: materials, index: subTaskArrayIndex }));
    }
    const handleRemoveMaterial = (tmpId) => {
        let materials = getNewMaterials()
        materials = materials.filter(item => item.id != tmpId)
        updateNewMaterials(materials)
    }

    const getLockStatus = (tmpId) => {
        return isBoolean(idx(materialState, _ => _.filterValue[tmpId].showMaterialLockIcon)) ? idx(materialState, _ => _.filterValue[tmpId].showMaterialLockIcon) : true
    }

    return (
        <>
            <AddItemDialog
                open={materialState.addItemDialogState}
                submitHandler={(res) => AddItemDialogClose(res)}
                dialogClose={() => setMaterialState(prev => ({ ...prev, addItemDialogState: false }))}
                categoryId={idx(materialState, _ => _.filterValue[materialState.tmpId].catalogid) || ''}
                itemName={idx(materialState, _ => _.filterValue[materialState.tmpId].item) || ''}
                manufacturer={idx(materialState, _ => _.filterValue[materialState.tmpId].manufacturer) || ''}
                partNo={idx(materialState, _ => _.filterValue[materialState.tmpId].partNo) || ''}
                unitCost={idx(materialState, _ => _.filterValue[materialState.tmpId].costPerItem) || ''}
            />

            {[...material, ...newMaterial].length > 0 && <div className="mb-2">
                <div className="border border-bottom-0 d-flex px-2 py-1 gap-3">
                    <h2 className="py-1 tbl-heading">Material List</h2>
                </div>

                <div className="material-list-block">
                    <div className="table-responsive">
                        <Table bordered className="table-create table-material tbl-fixed">
                            <thead>
                                <tr>
                                    <th>Catalog</th>
                                    <th>Manufacturer</th>
                                    <th>Part No</th>
                                    <th className="item-width">Items</th>
                                    <th className="cost-width">Cost($)</th>
                                    <th className="cost-width">Sale Amount($)</th>
                                    <th className="qty-width">Quantity</th>
                                    <th className="text-right total-width">Total ($)</th>
                                    <th className="text-right qty-width">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {material.map((_material, materialIndex) => {
                                    if (!_material) return;
                                    let category = materialState.categoriesList.find((item) => item.value === _material.categoryId);
                                    return (
                                        <tr key={materialIndex}>
                                            <td>
                                                <p title={_material.isInlineItem ? "NON CATALOG" : category?.name} className="text-truncate m-0 table-text-sm">
                                                    {_material.isInlineItem ? "NON CATALOG" : category?.name}
                                                </p>
                                            </td>
                                            <td>
                                                <p title={_material.manufacturer} className="text-truncate m-0 table-text-sm">
                                                    {_material.manufacturer}
                                                </p>
                                            </td>
                                            <td>
                                                <p title={_material.partNo} className="text-truncate m-0 table-text-sm">
                                                    {_material.partNo}
                                                </p>
                                            </td>
                                            <td>
                                                <p title={idx(_material, _ => _.name.value) || ""} className="text-truncate m-0 table-text-sm">
                                                    {idx(_material, _ => _.name.value) || ""}
                                                </p>
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={isDisabled}
                                                    {..._material.costPerItem}
                                                    onChange={(e) => itemChangeHandler(e, materialIndex)}
                                                />
                                            </td>
                                            <td>
                                                <LockInput
                                                    className={'input-block-sm'}
                                                    lock={
                                                        <span className="lock-icon">
                                                            {idx(_material, _ => _.saleAmountLock.value) && <LockIcon
                                                                onClick={(e) => {
                                                                    if (!isDisabled) itemChangeHandler("saleAmountLock", materialIndex)
                                                                }}
                                                            />}
                                                            {!idx(_material, _ => _.saleAmountLock.value) && <LockOpenIcon
                                                                onClick={(e) => {
                                                                    if (!isDisabled) itemChangeHandler("saleAmountLock", materialIndex)
                                                                }}
                                                            />}
                                                        </span>
                                                    }
                                                    {..._material.saleAmount}
                                                    disabled={isDisabled || idx(_material, _ => _.saleAmountLock.value) ? true : false}
                                                    onChange={(e) => itemChangeHandler(e, materialIndex)}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className={'input-block-sm'}
                                                    {..._material.qty}
                                                    disabled={isDisabled}
                                                    onChange={(e) => itemChangeHandler(e, materialIndex)}
                                                />
                                            </td>
                                            <td className="text-right table-text-sm">
                                                {toUSD(_material.total.value || _material.saleAmount.value)}
                                            </td>
                                            <td className="text-right">
                                                {!isDisabled && <DeleteIcon
                                                    className="deleteicon"
                                                    onClick={() => removeItem(materialIndex)}
                                                />}
                                            </td>
                                        </tr>
                                    )
                                })}

                                {newMaterial.map((_material) => (
                                    <tr key={_material.id}>
                                        <td>
                                            <div className="input-block-sm autocomplete-block autocomplete-block-ch">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === "string") {
                                                        } else if (newValue && newValue.inputValue) {
                                                        } else if (newValue) {
                                                            addFilter(newValue.key, "catalog", _material.id);
                                                            addFilter(newValue.value, "catalogid", _material.id);
                                                        } else {
                                                            addFilter("", "catalog", _material.id);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => filter(options, params)}
                                                    selectOnFocus
                                                    handleHomeEndKeys
                                                    clearOnEscape={false}
                                                    clearOnBlur={false}
                                                    clearText={true}
                                                    options={materialState.categories.items}
                                                    getOptionLabel={(option) => typeof option === "string" ? option : option.key}
                                                    // renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                    renderInput={(params) => (<TextField {...params} variant='standard' label='Catalog' />)}
                                                    disablePortal={true}
                                                    id="combo-box-demo"
                                                    onInputChange={(event, newValue) => {
                                                        addFilter(newValue.key || newValue, "catalog", _material.id);
                                                        addFilter(newValue.value, "catalogid", _material.id);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-block-sm autocomplete-block autocomplete-block-ch">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === "string") {
                                                        } else if (newValue && newValue.inputValue) {
                                                        } else if (newValue) {
                                                            addFilter(newValue.value, "manufacturer", _material.id);
                                                        } else {
                                                            addFilter("", "manufacturer", _material.id);
                                                        }
                                                    }}
                                                    // filterOptions={(options, params) => filter(options, params)}
                                                    selectOnFocus
                                                    handleHomeEndKeys
                                                    options={idx(materialState, _ => _.filterValue[_material.id].manufacturerOptions) || manufacturerDropDown(_material.id) || []}
                                                    // options={manufacturerDropDown(_material.id)}
                                                    getOptionLabel={(option) => typeof option === "string" ? option : option.key}
                                                    // renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                    renderInput={(params) => (<TextField {...params} variant='standard' label='Manufacturer' />)}
                                                    disablePortal={true}
                                                    clearOnEscape={false}
                                                    clearOnBlur={false}
                                                    clearText={true}
                                                    id="combo-box-demo"
                                                    onInputChange={(event, newValue) => {
                                                        addFilter(newValue, "manufacturer", _material.id);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <CustomInput
                                                className="input-block-sm"
                                                onChange={(e) => {
                                                    if (e.target.value && e.target.value.length > 2) {
                                                        addFilter(e.target.value, "partNo", _material.id);
                                                    } else {
                                                        addFilter("", "partNo", _material.id);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="w-auto">
                                            <div className="input-block-sm autocomplete-block autocomplete-block-ch">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === "string") {
                                                        } else if (newValue && newValue.inputValue) {
                                                            AddItemDialogHandler(_material.id);
                                                        } else if (newValue) {
                                                            addItem(newValue.value, '', _material.id);
                                                        }
                                                    }}
                                                    // filterOptions={x => x}
                                                    filterOptions={(options, params) => filter(options, params)}
                                                    selectOnFocus
                                                    handleHomeEndKeys
                                                    options={idx(materialState, _ => _.filterValue[_material.id].itemOptions) || itemsDropDownOption(_material.id) || []}
                                                    // options={itemsDropDownOption(_material.id)}
                                                    getOptionLabel={(option) => typeof option === "string" ? option : option.key}
                                                    renderOption={(props, option) => <li {...props} key={option.id}>{option.key}</li>}
                                                    renderInput={(params) => (<TextField {...params} variant='standard' label='Item Description' />)}
                                                    disablePortal={true}
                                                    clearOnEscape={false}
                                                    clearOnBlur={false}
                                                    clearText={true}
                                                    id="combo-box-demo"
                                                    onInputChange={(event, newValue) => {
                                                        addFilter(newValue, "item", _material.id);
                                                    }}
                                                    noOptionsText={
                                                        <span onClick={() => AddItemDialogHandler(_material.id)} style={{ cursor: 'pointer' }}>
                                                            {idx(materialState, _ => _.filterValue[_material.id].item) ? `Add "${idx(materialState, _ => _.filterValue[_material.id].item)}"` : 'No Option'}
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <CustomInput
                                                type="number"
                                                className="input-block-sm"
                                                onChange={(e) => {
                                                    if (e.target.value >= 0)
                                                        addFilter(e.target.value, "costPerItem", _material.id);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LockInput
                                                lock={
                                                    <span className="lock-icon">
                                                        {getLockStatus(_material.id) ?
                                                            <LockIcon onClick={() => lockMaterialIconChange(_material.id)} />
                                                            : <LockOpenIcon onClick={() => lockMaterialIconChange(_material.id)} />
                                                        }
                                                    </span>
                                                }
                                                type="number"
                                                className="input-block-sm"
                                                value={idx(materialState, _ => _.filterValue[_material.id].saleAmount)}
                                                disabled={getLockStatus(_material.id)}
                                                onChange={(e) => {
                                                    if (e.target.value >= 0)
                                                        addFilter(e.target.value, "saleAmount", _material.id);
                                                }}
                                                min={0}
                                            />
                                        </td>
                                        <td className="text-right table-text-sm">
                                            <CustomInput
                                                type="number"
                                                className="input-block-sm"
                                                onChange={(e) => {
                                                    if (e.target.value >= 0)
                                                        addFilter(e.target.value, "qty", _material.id);
                                                }}
                                            />
                                        </td>
                                        <td className="text-right table-text-sm">
                                            {toUSD(idx(materialState, _ => _.filterValue[_material.id].totalValue))}
                                        </td>
                                        <td className="text-right">
                                            {!isDisabled && <DeleteIcon
                                                className="deleteicon"
                                                onClick={() => handleRemoveMaterial(_material.id)}
                                            />}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right fs-6 py-1">
                                        <span>
                                            {toUSD(idx(total, _ => _.material.sale))}
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>}

            <ProfitMarginLock
                open={materialState.profitMarginDialogState}
                lockIconChange={() => setConfirmed()}
                dialogClose={profitMarginDialogHandler}
                moduleName="Task"
                modalHeading={"Sale Amount Lock changes"}
                text={"sale Amount"}
                message={'Are you sure you want to change the Project profit margin? - Any items using default profit margins will also be updated to this value'}
            />
        </>
    );

}
