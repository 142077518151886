import axios, { microServices } from './axios'
import { loaders } from './../constants'
// // ********************API url *******************
const GET_PROJECT_LIST = '/project/list'
const ADD_PROJECT = '/project'
const DELETE_PROJECT = '/project/delete'
const GET_PROJECT = '/project'
const STAGE = '/stage'
const TASK = '/task'
const OPTION = '/option'
const OPTION_UPDATE = '/option/update'
const SUB_TASK = '/sub-task'
const SAVE_RFQ = '/rfq/project'
const RFQ = '/rfq'
const CREATE_QUOTATION = '/quotation/rfq'
const GET_QUOTATION_LIST = '/quotation/project/list'
const SAVE_PURCHASE_ORDER = '/purchase-order/create'
const EDIT_PURCHASE_ORDER = '/purchase-order/edit'
const GET_PURCHASE_ORDER = '/purchase-order'
const GET_PURCHASE_ORDER_LIST = '/purchase-order/list'
const UPDATE_PURCHASE_ORDER_STOCK = '/purchase-order/stock'
const UPLOAD_RFQ_FILE = '/rfq/upload'
const GET_RFQ = '/rfq'
const RESOURCES = '/timesheet'
const GET_TEMPLATES_LIST = '/project/templates'
const SAVE_TEMPLATE = '/project/template'
const SAVE_TEMPLATE_CONTENT = '/project/save/content'
const GET_USER_ALLOCATIONS = '/timesheet/allocation'
const TIMESHEET_ATTENDANCE_LEAVE = '/timesheet/attendance/leave'
const TIMESHEET_HOUR = '/timesheet/attendance/hour'
const TIMESHEET_SUBCONTRACTOR = '/timesheet/sub-contractor/date'
const GET_USER_ALLOCATION_REPORT = '/timesheet/allocation/report'
const ADD_PROGRESS_CLAIM = '/progress-claim/'
const GET_PROGRESS_CLAIM_BY_ID = '/progress-claim'
const EDIT_PROGRESS_CLAIM = '/progress-claim'
const GET_PROGRESS_CLAIM = '/progress-claim/list'
const GET_VENDOR_PO_LIST = '/purchase-order/vendors'
const ADD_PROGRESS_CLAIM_RETENTION = '/progress-claim/retention'
const SET_VENDOR_PAYMENT = '/purchase-order/vendor/payment'
const GET_VENDOR_PO_INVOICE_COUNT = '/purchase-order/vendor/payment-invoice-count'
const GET_DE_PURCHASE_ORDER_LIST = '/purchase-order/de/list'
const GET_PO_COUNT = '/purchase-order/count'
const GET_WORK_ORDER_COUNT = '/purchase-order/wo-count'
const GET_PO_VENDORS = '/purchase-order/distinct-vendors'
const GET_PO_INVOICES = '/purchase-order/invoices'
const GET_PO_INVOICE = '/purchase-order/invoice'
const DUPLICATE_PROJECT_TASK = '/project/duplicate/task'
const DELETE_TEMPLATE = '/project/template'
const GET_PROJECT_BUDGET = '/project/budget'
const DUPLICATE_PROJECT = '/project/duplicate-project'
const RECEIVE_DE_STOCK = '/project/receive-de-stock'
const RECEIVE_RESERVE_STOCK = '/project/receive-reserve-stock'
const EDIT_QUOTATION = '/quotation'
const DOWNLOAD_PROJECT_QUOTE = '/project/download'
const DOWNLOAD_PROJECT_PO = '/purchase-order/download'
const DOWNLOAD_PROJECT_RFQ = '/rfq/download'
const DOWNLOAD_PROJECT_VARIATION = '/option/download'
const GET_ALL_PROGRESS_CLAIM = '/progress-claim/all/list'
const TASK_WITHOUT_STAGE = '/task/add'
const GET_ALL_TASKS_WITHOUT_STAGE = '/withoutstages'
// // ************API call Functions starts here **************

export const getProjectList = async (rootProjectId, type, customerId, projectType, projectName, managerId, allowedModules) => {
  return await axios('get', `${GET_PROJECT_LIST}`, {},
    {
      loader: loaders.OVERLAY,
      server: microServices.DE_CORE,
      params: {
        ...(rootProjectId ? { rootProjectId } : {}),
        ...(type ? { type } : {}),
        ...(customerId ? { customerId } : {}),
        ...(projectType ? { projectType } : {}),
        ...(projectName ? { projectName } : {}),
        ...(managerId ? { managerId } : {}),
        ...(allowedModules ? { allowedModules } : {})
      }
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const fetchTemplates = async () => {
  return await axios('get', `${GET_TEMPLATES_LIST}`,
    {},
    { server: microServices.DE_CORE }
  ).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProject = async (id) => {
  return await axios('get', `${GET_PROJECT}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: { id } }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addProject = async (data) => {
  return await axios('post', ADD_PROJECT, data, { loader: loaders.OVERLAY, successMessage: 'Project Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteProject = async (id, type) => {
  return await axios('delete', `${DELETE_PROJECT}/${id}/${type}`, {}, { successMessage: 'Project Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addStage = async (data, projectId, message = '') => {
  return await axios('post', `${ADD_PROJECT}/${projectId}${STAGE}`, data, { loader: loaders.OVERLAY, successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addTask = async (data, stageId) => {
  return await axios('post', `${STAGE}/${stageId}${TASK}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const addTaskWithoutStage = async (data, projectId) => {
  return await axios('post', `${TASK_WITHOUT_STAGE}/${projectId}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addOption = async (data, taskType) => {
  return await axios('post', `${OPTION}`, data, { loader: loaders.OVERLAY, successMessage: ((taskType === "isOption") ? 'Option Created Successfully' : 'Variation Created Successfully'), server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getStages = async (projectId) => {
  return await axios('get', `${ADD_PROJECT}/${projectId}/stages`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getOptions = async (projectId) => {
  return await axios('get', `${ADD_PROJECT}/${projectId}/options`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getStage = async (stageId) => {
  return await axios('get', `${STAGE}/${stageId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateStage = async (data, stageId) => {
  return await axios('put', `${STAGE}/${stageId}`, data, { loader: loaders.OVERLAY, successMessage: 'Stage Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateProposalOptions = async (data) => {
  return await axios('post', `${OPTION_UPDATE}`, data, { loader: loaders.OVERLAY, successMessage: 'Proposal Saved Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTask = async (taskId) => {
  return await axios('get', `${TASK}/${taskId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getAllTasks = async (projectId) => {
  return await axios('get', `${TASK}/${GET_ALL_TASKS_WITHOUT_STAGE}/${projectId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const getOption = async (optionId) => {
  return await axios('get', `${OPTION}/${optionId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateTask = async (data, taskId) => {
  return await axios('put', `${TASK}/${taskId}`, data, { loader: loaders.OVERLAY, successMessage: 'Task Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateTaskOrderingId = async (data, taskId, destinationid) => {
  return await axios('put', `${TASK}/ordering/${taskId}/${destinationid}`, data, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const updateOption = async (data, optionId, taskType) => {
  return await axios('put', `${OPTION}/${optionId}`, data, { loader: loaders.OVERLAY, successMessage: ((taskType === "isOption") ? 'Option Updated Successfully' : 'Variation Updated Successfully'), server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addSubTask = async (data, taskId) => {
  return await axios('post', `${TASK}/${taskId}${SUB_TASK}`, data, {
    loader: loaders.OVERLAY,
    successMessage: '',
    server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateSubTask = async (data, subTaskId) => {
  return await axios('put', `${SUB_TASK}/${subTaskId}`, data, { loader: loaders.OVERLAY, successMessage: '', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editProject = async (data, projectId) => {
  return await axios('put', `${ADD_PROJECT}/${projectId}`, data, { loader: loaders.OVERLAY, successMessage: 'Project Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveRFQ = async (data, projectId, message) => {
  return await axios('post', `${SAVE_RFQ}?projectId=${projectId}`, data, { loader: loaders.OVERLAY, successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteStage = async (id) => {
  return await axios('delete', `${STAGE}/${id}`, {}, { successMessage: 'Stage Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteTask = async (id, isOption) => {
  const successMessage = isOption ? 'Option Deleted Successfully' : 'Task Deleted Successfully';
  return await axios('delete', `${TASK}/${id}`, {}, { successMessage, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const deleteOption = async (id) => {
  return await axios('delete', `${OPTION}/${id}`, {}, { successMessage: 'Option Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const convertTask = async (data) => {
  return await axios('post', `${OPTION}/convertTask`, data, { successMessage: 'Option Converted to Task Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteSubTask = async (id, isOption) => {
  const successMsg = isOption ? 'Option Subtask Deleted Successfully' : 'SubTask Deleted Successfully';
  return await axios('delete', `${SUB_TASK}/${id}`, {}, { successMessage: successMsg, server: microServices.DE_CORE }).then(data => {
    return data;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    });
}


export const getrfqList = async (id) => {
  return await axios('get', `${SAVE_RFQ}/${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editRFQ = async (data, id, message) => {
  return await axios('put', `${RFQ}/${id}`, data, { successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const createQuotation = async (data, id) => {
  return await axios('post', `${CREATE_QUOTATION}/${id}`, data, { successMessage: 'Quotation Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getQuotationList = async (query) => {
  return await axios('get', `${GET_QUOTATION_LIST}${query && query}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const savePurchaseOrder = async (poItems, rfqId, message) => {
  return await axios('post', `${SAVE_PURCHASE_ORDER}`, poItems,
    {
      successMessage: message,
      server: microServices.DE_CORE,
      params: { ...(rfqId ? { rfqId } : {}) }
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const editPurchaseOrder = async (poItems, poId, message) => {
  return await axios('post', `${EDIT_PURCHASE_ORDER}/${poId}`, poItems,
    {
      successMessage: message,
      server: microServices.DE_CORE
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const deletePurchaseOrder = async (poId, message) => {
  return await axios('delete', `${GET_PURCHASE_ORDER}/${poId}`, {},
    {
      successMessage: message,
      server: microServices.DE_CORE
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const getPurchaseOrder = async (query) => {
  return await axios('get', `${GET_PURCHASE_ORDER}${query ? query : ''}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const uploadFileRfq = async (formData, id, message) => {
  return await axios('put', `${UPLOAD_RFQ_FILE}/${id}`, formData, { loader: loaders.OVERLAY, successMessage: message, server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getRfq = async (id) => {
  return await axios('get', `${GET_RFQ}/${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTimesheet = async (id, allocationId) => {
  return await axios('get', RESOURCES, {}, {
    server: microServices.DE_CORE, loader: loaders.OVERLAY, params: {
      ...(id ? { id } : {}),
      ...(allocationId ? { allocationId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addResource = async (data) => {
  return await axios('post', RESOURCES, data, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateResource = async (id, data) => {
  return await axios('put', `${RESOURCES}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Resource Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOList = async ({ vendorId: vendor, projectId, vendorType, isDePo, itemId, allowedModules }) => {
  return await axios('get', GET_PURCHASE_ORDER_LIST, {}, {
    server: microServices.DE_CORE,
    params: {
      ...(vendor ? { vendor } : {}),
      ...(projectId ? { projectId } : {}),
      ...(vendorType ? { vendorType } : {}),
      ...(isDePo !== null ? { isDePo } : {}),
      ...(itemId ? { itemId } : {}),
      allowedModules
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updatePOStock = async (data, id) => {
  return await axios('put', `${UPDATE_PURCHASE_ORDER_STOCK}`, data, { loader: loaders.OVERLAY, successMessage: 'PO Stock Updated Successfully', server: microServices.DE_CORE, params: { id } }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveContentFromTemplate = async (data) => {
  return await axios('post', SAVE_TEMPLATE_CONTENT, data, { loader: loaders.OVERLAY, successMessage: 'Template Saved Successfully!', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
// save template from project content
export const saveTemplate = async (data) => {
  return await axios('post', SAVE_TEMPLATE, data, { loader: loaders.OVERLAY, successMessage: 'Template Saved Successfully!', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


//get subtask api
export const getSubTask = async (subTaskId) => {
  return await axios('get', `${SUB_TASK}/${subTaskId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUserAllocations = async (userIds = [], projectId) => {
  return await axios('post', GET_USER_ALLOCATIONS, { userIds }, {
    server: microServices.DE_CORE, params: {
      ...(projectId ? { projectId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateTimesheetAttendance = async (allocationId, data, absentId) => {
  return await axios('put', `${TIMESHEET_ATTENDANCE_LEAVE}/${allocationId}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Leaves Applied Successfully',
    server: microServices.DE_CORE, params: {
      ...(absentId ? { absentId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateTimesheetHour = async (data) => {
  return await axios('put', TIMESHEET_HOUR, data, { loader: loaders.OVERLAY, successMessage: 'Added Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addSubContractorDate = async (allocationId, data) => {
  return await axios('put', TIMESHEET_SUBCONTRACTOR, data, {
    loader: loaders.OVERLAY, successMessage: 'Added Successfully', server: microServices.DE_CORE,
    params: {
      ...(allocationId ? { allocationId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUserAllocationReport = async (userIds = []) => {
  return await axios('post', GET_USER_ALLOCATION_REPORT, { userIds }, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addProgressClaim = async (data) => {
  return await axios('post', ADD_PROGRESS_CLAIM, data, {
    loader: loaders.OVERLAY, successMessage: 'Progress Claim Generated Successfully', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editProgressClaim = async (data, id) => {
  return await axios('put', `${EDIT_PROGRESS_CLAIM}/${id}`, data, { successMessage: 'Progress Claim Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProgressClaimList = async (projectId) => {
  return await axios('get', GET_PROGRESS_CLAIM, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(projectId ? { projectId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getvendorsPOList = async (params) => {
  return await axios('get', GET_VENDOR_PO_LIST, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const addProgressClaimRetention = async (data) => {
  return await axios('post', ADD_PROGRESS_CLAIM_RETENTION, data, {
    loader: loaders.OVERLAY, successMessage: 'Progress Claim Retention Generated Successfully', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const setVendorPayment = async (data, poNumber) => {
  return await axios('post', `${SET_VENDOR_PAYMENT}/${poNumber}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Invoice details Updated', server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getvendorsPOInvoiceCount = async (projectId) => {
  return await axios('get', `${GET_VENDOR_PO_INVOICE_COUNT}/${projectId}`, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getDePOList = async (params) => {
  return await axios('get', `${GET_DE_PURCHASE_ORDER_LIST}`, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE, params
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPoCount = async (projectId) => {
  return await axios('get', `${GET_PO_COUNT}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(projectId ? { projectId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const getWorkOrderCount = async (projectId) => {
  return await axios('get', `${GET_WORK_ORDER_COUNT}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(projectId ? { projectId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
export const getPoVendors = async (projectId, type) => {
  return await axios('get', `${GET_PO_VENDORS}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(projectId ? { projectId } : {}), ...(type ? { type } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOInvoices = async (poId) => {
  return await axios('get', `${GET_PO_INVOICES}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(poId ? { poId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getPOInvoice = async (invoiceId) => {
  return await axios('get', `${GET_PO_INVOICE}`, {}, {
    loader: loaders.OVERLAY,
    server: microServices.DE_CORE,
    params: { ...(invoiceId ? { invoiceId } : {}) }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const duplicateProjectTask = async (taskType, stageId, taskId, subTaskId) => {
  return await axios('put', `${DUPLICATE_PROJECT_TASK}`, {}, {
    loader: loaders.OVERLAY, successMessage: 'Duplication Successfull!', server: microServices.DE_CORE,
    params: {
      ...(taskType ? { taskType } : {}),
      ...(stageId ? { stageId } : {}),
      ...(taskId ? { taskId } : {}),
      ...(subTaskId ? { subTaskId } : {})
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteTemplate = async (templateId) => {
  return await axios('delete', `${DELETE_TEMPLATE}/${templateId}`, {}, { successMessage: 'Template Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProjectBudget = async (projectId) => {
  return await axios('get', GET_PROJECT_BUDGET, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {
      ...(projectId ? { projectId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const duplicateProject = async (data) => {
  return await axios('post', `${DUPLICATE_PROJECT}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Project Duplicated Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const receiveDEStock = async (data) => {
  return await axios('post', `${RECEIVE_DE_STOCK}`, data, {
    loader: loaders.OVERLAY, successMessage: 'DE Stock Received Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const receiveReserveStock = async (data) => {
  return await axios('post', `${RECEIVE_RESERVE_STOCK}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Reserve Stock Received Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editQuotation = async (data, id) => {
  return await axios('put', `${EDIT_QUOTATION}/${id}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Quotation updated Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectQuote = async (id, format, tempId) => {
  return await axios('get', DOWNLOAD_PROJECT_QUOTE, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? { tempId } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectPO = async (id, format, tempId, template) => {
  return await axios('get', DOWNLOAD_PROJECT_PO, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? { tempId } : {}),
      ...(format ? { format } : {}),
      ...(template ? { template } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectRFQ = async (id, format, tempId, vendorId) => {
  return await axios('get', DOWNLOAD_PROJECT_RFQ, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? { tempId } : {}),
      ...(format ? { format } : {}),
      ...(vendorId ? { vendorId } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const downloadProjectVariation = async (id, format, tempId) => {
  return await axios('get', DOWNLOAD_PROJECT_VARIATION, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(tempId ? { tempId } : {}),
      ...(format ? { format } : {}),
    },
    responseType: 'blob'
  }).then(({ file, headers = {} }) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.download = headers["content-disposition"] && headers["content-disposition"].split("filename=")[1];
    link.click();
    return file;
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getProgressClaimById = async (query) => {
  return await axios('get', `${GET_PROGRESS_CLAIM_BY_ID}${query && query}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editProgressClaimRetention = async (data, id) => {
  return await axios('put', `${ADD_PROGRESS_CLAIM_RETENTION}/${id}`, data, {
    loader: loaders.OVERLAY, successMessage: 'Progress Claim Retention updated Successfully', server: microServices.DE_CORE,
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getAllProgressClaimList = async (projectId) => {
  return await axios('get', GET_ALL_PROGRESS_CLAIM, {}, {
    loader: loaders.OVERLAY, server: microServices.DE_CORE,
    params: {
      ...(projectId ? { projectId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
