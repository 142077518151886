import React, { Component } from "react";
import { connect } from "react-redux";
import {
  allocateSchedule,
  getResourceAllocations,
  approveTimesheet,
  editTimesheet,
  applyLeave,
  deleteResource,
} from "../../actions/timesheet.action";
import { bindActionCreators } from "redux";
import { Menu, MenuItem } from "@material-ui/core";
import { getUser } from "../../actions/user-management.action";
import moment from "moment";
import idx from "idx";
import AllocateResource from "./allocate-resource-dialog";
import ConfirmAllocateResource from "./confirm-allocate-resource-dialog";
import DeleteDialog from "./../../components/delete-dialog";
import { getProjectList } from "../../actions/project-management.action";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Grid } from "@mui/material";
import { withRouter } from "../../util/with-router";
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';
import { warningSnackBar } from "../../actions/common.action";

export class viewTimeSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocations: [],
      columns: [],
      userId: this.props.params.userId,
      showAllocateResource: false,
      timesheetStatus: "",
      confirmDialogState: false,
      confirmData: [],
      deleteDialogState: false,
    };
  }

  componentDidMount = () => {
    this.getColumns();
    this.getAllocations();
  };

  getColumns = () => {
    const columns = [
      { title: "Emp Name", field: "empName" },
      { title: "Project Name", field: "projectName" },
      { title: "Task", field: "taskName" },
      { title: "Status", field: "status" },
      {
        title: "Start & End time",
        field: "allocationTime",
        defaultSort: "desc",
      },
    ];
    this.setState({ columns });
  };

  getAllocations = async () => {
    const { userId } = this.state;
    const { permissions, id: managerId } = this.props.user;
    const writePermission =
      permissions.timesheetApprover === 2 ? true : false;

    if (!permissions.timesheetApprover) {
      this.props.warningSnackBar(`Sorry, you don't have permissions to do this action`)
      return;
    }

    const [projects, services] = await Promise.all([
      this.props.getProjectList(null, null, null, null, null, managerId),
    ]);

    let projectIds = [managerId];
    Array.isArray(projects) &&
      projects.map((project) => projectIds.push(project.id));
    Array.isArray(services) &&
      services.map((service) => projectIds.push(service.id));

    if (writePermission) {
      projectIds = null;
    }

    const [list, user] = await Promise.all([
      this.props.getResourceAllocations({
        userId,
        showRejected: true,
        projectIds,
      }),
      this.props.getUser(userId),
    ]);

    const empName =
      idx(
        user,
        (_) =>
          (user.firstName && user.firstName != null ? user.firstName : "") +
          " " +
          (user.lastName && user.lastName != null ? user.lastName : "")
      ) || "";
    const resourceAllocations = [];
    Array.isArray(list) &&
      list.map((allocation) => {
        let projectName;
        if (allocation.projectType === "SERVICE") {
          projectName = idx(allocation, (_) => _.service.name) || "";
        } else {
          projectName = idx(allocation, (_) => _.project.name) || "";
        }

        const date = moment(allocation.startTime).format("DD-MM-YYYY");
        const start = moment(allocation.startTime).format("LT");
        const end = moment(allocation.endTime).format("LT");

        const allocationTime = `${date} ${start} to ${end}`;

        resourceAllocations.push({
          id: allocation.id,
          allocationTime,
          projectName,
          taskName: allocation.taskName,
          empName,
          allocationResourceId: allocation.id,
          status: allocation.status,
        });
      });
    this.setState({ allocations: resourceAllocations });
  };

  handleMenu = (rowData, event) => {
    this.setState({
      anchorEl: event?.currentTarget,
      allocationId: rowData.id,
      allocationResourceId: rowData.allocationResourceId,
      timesheetStatus: rowData.status,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, vendorData: {} });
  };

  modify = () => {
    this.setState({
      showAllocationDialog: true,
      anchorEl: null,
      vendorData: {},
    });
  };

  updateTimesheetStatus = async (status) => {
    this.handleClose();
    const { allocationId } = this.state;
    const data = { status };
    await this.props.approveTimesheet(allocationId, data);
    this.getAllocations();
  };

  allocateResource = async (data) => {
    let { allocationId } = this.state;
    let start = new Date(data.startTime);
    let end = new Date(data.endTime);
    let startTime = new Date(data.startDate);
    let endTime = new Date(data.endDate);
    startTime.setHours(start.getHours(), start.getMinutes(), 0, 0);
    endTime.setHours(end.getHours(), end.getMinutes(), 0, 0);
    startTime = moment.utc(startTime).format("YYYY-MM-DDTHH:mmZ").toString();
    endTime = moment.utc(endTime).format("YYYY-MM-DDTHH:mmZ").toString();
    data["startTime"] = startTime;
    data["endTime"] = endTime;
    delete data.startDate;
    delete data.endDate;
    if (allocationId) data.allocationType = "WORK";
    await this.handleAllocation(data, "WORK");
  };

  editTimesheet = async (data) => {
    const { allocationResourceId, allocationId } = this.state;
    data.allocationResourceId = allocationResourceId;
    data.previousType = "WORK";
    data.startTime = moment
      .utc(data.startTime)
      .format("YYYY-MM-DDTHH:mmZ")
      .toString();
    data.endTime = moment
      .utc(data.endTime)
      .format("YYYY-MM-DDTHH:mmZ")
      .toString();
    return await this.props.editTimesheet(allocationId, data);
  };

  cancelHandler = () => {
    this.props.history("/timesheet/allocations");
  };

  allocateSchedule = async (data) => {
    try {
      await this.props.allocateSchedule(data);
      this.getAllocations();
      this.setState({ confirmDialogState: false });
    } catch (e) {
      this.setState({ confirmDialogState: true });
    }
  };

  applyLeave = async (data) => {
    const { allocationId } = this.state;
    if (allocationId) data.allocationType = "LEAVE";
    await this.handleAllocation(data, "LEAVE");
  };

  handleAllocation = async (data, type) => {
    try {
      const { allocationId } = this.state;
      let { confirmDialogState, confirmData } = this.state;
      let allocationsList = [];
      if (allocationId) {
        allocationsList = await this.editTimesheet(data);
      } else {
        if (type === "LEAVE") {
          allocationsList = await this.props.applyLeave(data);
        } else if (type === "WORK") {
          allocationsList = await this.props.allocateResource(data);
        }
      }
      confirmDialogState = true;
      confirmData = allocationsList;
      this.setState({
        showAllocationDialog: false,
        confirmData,
        confirmDialogState,
      });
    } catch (e) {
      this.setState({ showAllocationDialog: true });
    }
  };

  deleteHandler = async () => {
    const { allocationId } = this.state;
    await this.props.deleteResource("WORK", allocationId);
    this.handleClose();
    this.getAllocations();
    this.deleteDialogHandler();
  };

  deleteDialogHandler = (event = {}) => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
    }));
  };

  render() {
    const {
      columns,
      allocations,
      anchorEl,
      showAllocationDialog,
      allocationId,
      timesheetStatus,
      confirmData,
      confirmDialogState,
      deleteDialogState,
    } = this.state;
    return (
      <>
        <DeleteDialog
          open={deleteDialogState}
          id={allocationId}
          deleteHandler={this.deleteHandler}
          dialogClose={this.deleteDialogHandler}
          moduleName="Allocation"
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {timesheetStatus === "PENDING" && (
            <MenuItem onClick={this.modify}>Modify</MenuItem>
          )}
          {timesheetStatus === "PENDING" && (
            <MenuItem onClick={() => this.updateTimesheetStatus("APPROVED")}>
              Accept/Approve
            </MenuItem>
          )}
          {timesheetStatus === "PENDING" && (
            <MenuItem onClick={() => this.updateTimesheetStatus("REJECTED")}>
              Reject
            </MenuItem>
          )}
          {timesheetStatus === "PENDING" && (
            <MenuItem onClick={() => this.deleteDialogHandler()}>
              Delete
            </MenuItem>
          )}
          {timesheetStatus !== "PENDING" && (
            <MenuItem onClick={() => this.updateTimesheetStatus("PENDING")}>
              Set as Pending
            </MenuItem>
          )}
        </Menu>
        {showAllocationDialog && (
          <AllocateResource
            open={showAllocationDialog}
            dialogClose={() => this.setState({ showAllocationDialog: false })}
            allocateResource={this.allocateResource}
            allocationType={"WORK"}
            allocationId={allocationId}
            applyLeave={this.applyLeave}
          />
        )}
        {confirmDialogState && (
          <ConfirmAllocateResource
            open={confirmDialogState}
            data={confirmData}
            dialogClose={() => this.setState({ confirmDialogState: false })}
            allocateSchedule={this.allocateSchedule}
          />
        )}
        <Grid container sx={{ m: 0, mb: 1 }}>
          <Grid item xs={12} sx={{ p: 0 }} textAlign={"start"}>
            <ArrowCircleLeftOutlinedIcon
              className="arrow-circle-icon"
              onClick={this.cancelHandler}
            />
            <span className="link" onClick={this.cancelHandler}>
              Timesheet
            </span>
            <span className="arrow"> {"/"} </span>
            {"View Timesheet"}
          </Grid>
        </Grid>
        <div className="tableContainer timesheetScrollMobile">
          <div className="timesheetScroll">
            <div className="mobileTimesheet">
              <CustomTable
                columns={columns}
                data={allocations}
                isAction={true}
                handleMenu={(data) => this.handleMenu(data)}
                menu={(data) => (
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <ul className="action-block-list">
                        {data.status === "PENDING" && (
                          <li onClick={this.modify}>Modify</li>
                        )}
                        {data.status === "PENDING" && (
                          <li onClick={() => this.updateTimesheetStatus("APPROVED")}>Accept/Approve</li>
                        )}
                        {data.status === "PENDING" && (
                          <li onClick={() => this.updateTimesheetStatus("REJECTED")}>Reject</li>
                        )}
                        {data.status === "PENDING" && (
                          <li onClick={() => this.deleteDialogHandler()}>Delete</li>
                        )}
                        {data.status !== "PENDING" && (
                          <li onClick={() => this.updateTimesheetStatus("PENDING")}>Set as Pending</li>
                        )}
                      </ul>
                    </Popover.Body>
                  </Popover>
                )}
                fileName="Allocation List"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  allocateSchedule: bindActionCreators(allocateSchedule, dispatch),
  applyLeave: bindActionCreators(applyLeave, dispatch),
  deleteResource: bindActionCreators(deleteResource, dispatch),
  editTimesheet: bindActionCreators(editTimesheet, dispatch),
  getResourceAllocations: bindActionCreators(getResourceAllocations, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
  approveTimesheet: bindActionCreators(approveTimesheet, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(viewTimeSheet)
);
