import { itemFields } from "../../constants/fields.constant";
import { cloneDeep, isString } from "lodash";

export const convertToMaterialForm = (materials = []) => {
    const _materials = materials.map((iterator) => {
        if (isString(iterator)) {
            iterator = JSON.parse(iterator);
        }
        let item = cloneDeep(itemFields);

        item.name.value = iterator.item;
        item.id = iterator.id;
        item.categoryId = iterator.categoryId;
        item.manufacturer = iterator.manufacturer;
        item.partNo = iterator.partNo;
        item.costPerItem.value = iterator.cost;
        item['saleAmount'].value = iterator.saleAmount;
        item.saleAmountLock.value = iterator.saleAmountLock;
        item.qty.value = iterator.qty;
        item.unit.value = iterator.unit;
        item.total.value = parseFloat(iterator.saleAmount || 0) * parseFloat(iterator.qty || 1);
        return item;
    }) || [];
    return _materials;
}