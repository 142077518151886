import React, { useEffect, useState } from 'react'
import { withRouter } from "../../../util/with-router";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PROJECT_MANAGEMENT } from './../../../constants/route-paths'
import { getPurchaseOrder } from './../../../actions/project-management.action'
import idx from 'idx'
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';

function ViewWO(props) {
  const [poData, setPoData] = useState([])
  const [materialItems, setMaterialItems] = useState([])
  const [description, setDescription] = useState()
  const [quotationId, setQuotationId] = useState([])
  const [quoteNo, setQuoteNo] = useState('')
  const [vendorName, setVendorName] = useState([])
  const [attachments, setAttachments] = useState([])
  const laborColumns = ['Tasks', 'Quote Submitted']

  const { projectId, woId } = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { projectDetail = {} } = useSelector(state => state)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  useEffect(() => {
    getPurchaseOrder(woId)
  }, [woId])


  const getPurchaseOrder = async (id) => {
    const query = `?woId=${id}`
    const poData = await props.getPurchaseOrder(query)
    const { rfq, items, vendor, quotation, quoteNo } = poData
    setDescription(idx(rfq, _ => _.description))
    setMaterialItems(idx(items, _ => _) || [])
    setPoData(poData)
    setQuotationId(idx(quotation, _ => _.id))
    setQuoteNo(quoteNo)
    setVendorName(idx(vendor, _ => _.business_name))
    const attachment = poData && poData.attachment.map(item => {
      return (
        <div className="attachedFiles">
          <p style={{ margin: '0 10px 0 0' }}>{item.name}</p>
        </div>
      )
    })
    setAttachments(attachment)
  }

  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  const viewProject = () => navigate(`/${PROJECT_MANAGEMENT}/view/${projectId}`)
  const viewWoList = () => navigate(`/${PROJECT_MANAGEMENT}/work-order/${projectId}`);

  return (
    <div className="purchase-order-block">

      <h2 className="content-heading text-uppercase">View Work Order</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
          <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
          <Breadcrumb.Item onClick={viewWoList} >Work Order LIST </Breadcrumb.Item>
          <Breadcrumb.Item active>Work Order</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">
        {description && <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Description</label>
            <p>{description}</p>
          </div>
        </div>}
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Vendor Type</label>
            <p>Contractor</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Work Order No</label>
            <p>{idx(poData, _ => _.id)}</p>
          </div>
        </div>
        {quotationId && <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Quote Request No</label>
            <p>{quotationId}</p>
          </div>
        </div>}
        {quoteNo && <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Quote No</label>
            <p>{quoteNo}</p>
          </div>
        </div>}
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Vendor</label>
            <p>{vendorName}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Delivery Option</label>
            <p>{idx(poData, _ => _.deliveryOption) || '-'}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Attachments</label>
            <p>{attachments}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Delivery Date</label>
            <p>{idx(poData, _ => _.deliveryDate) || '-'}</p>
          </div>
        </div>
        <div className="col-lg-12 mt-3">
          <div className="view-block">
            <label>Notes</label>
            <p>{idx(poData, _ => _.notes) || '-'}</p>
          </div>
        </div>
        <div className="col-12">
          <div className="material-list-block mt-4">
            <div className="table-responsive">
              <Table bordered className="table-create table-material table-material-ch">
                <thead>
                  <tr>
                    {laborColumns.map((column, i) => (
                      <th>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {materialItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.taskName}</td>
                      {/* <td>{item.taskDescription}</td> */}
                      <td>${item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </div>

      <div className="col-12 d-flex mt-3 justify-content-end">
        <Button type="submit" onClick={viewWoList} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewWO))
