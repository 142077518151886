import React from 'react'
import { connect } from 'react-redux'
import Modal from '../../components/modal/modal'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Switch } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import idx from 'idx'
import { warningSnackBar } from '../../actions/common.action'
import CustomTable from 'src/components/table/table'

class ConfirmAllocateResource extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            data: {},
            type: '',
            prevType: '',
            startDate: '',
            finishDate: '',
        }
    }

    componentDidMount() {
        const { data } = this.props
        let allocationData = []
        const allocationList = idx(data, _ => _.list) || []
        for (let [index, allocation] of allocationList.entries()) {
            allocationData.push({
                id: index,
                ...allocation,
                status: allocation['allocation_status'] ? `Not Available (${allocation['allocation_status']})` : 'Available',
                selected: allocation['allocation_status'] ? false : true,
                disabled: allocation['allocation_status'] ? true : false,
            })
        }
        const allocateData = data.allocateData
        this.setState({
            list: allocationData,
            type: idx(allocateData, _ => _.allocationType) || '',
            prevType: idx(allocateData, _ => _.previousType) || '',
            startDate: idx(allocateData, _ => _.startTime) || '',
            finishDate: idx(allocateData, _ => _.endTime) || '',
            data: allocateData
        })
    }

    getColumns = () => {
        return ([
            {
                title: 'S No',
                field: 'id',
                render: rowData => (rowData.id + 1)
            },
            {
                title: 'Start Time',
                field: 'start_time',
                render: rowData => `${moment(rowData.start_time).format('DD-MM-YYYY')} ${moment(rowData.start_time).format('LT')}`
            },
            {
                title: 'Finish Time',
                field: 'end_time',
                render: rowData => `${moment(rowData.end_time).format('DD-MM-YYYY')} ${moment(rowData.end_time).format('LT')}`
            },
            { title: 'Status', field: 'status' },
            {
                title: 'Allocate',
                field: 'selected',
                render: rowData => (<Switch
                    checked={rowData.selected}
                    color='primary'
                    disabled={rowData.disabled}
                    onChange={(e) => this.handleSelection(e.target.checked, rowData.id)}
                />)
            },
        ])
    }

    handleSelection = (value, id) => {
        const { list } = this.state
        list[id]['selected'] = value
        this.setState({ list })
    }

    submitData = () => {
        const { list, data } = this.state
        const req = { allocationList: [] }
        for (let item of list) {
            if (!item.disabled && item.selected) req.allocationList.push(item)
        }
        if (req.allocationList.length === 0) {
            this.props.warningSnackBar('Please Select at least a selection to proceed')
            return
        }
        req.userId = data.userId
        req.allocationType = data.allocationType
        if (idx(data, _ => _.allocationId)) req.allocationId = idx(data, _ => _.allocationId)
        if (idx(data, _ => _.previousType)) req.previousType = idx(data, _ => _.previousType)
        if (idx(data, _ => _.allDayWithBreaks)) req.allDayWithBreaks = idx(data, _ => _.allDayWithBreaks)
        if (idx(data, _ => _.projectType)) req.projectType = idx(data, _ => _.projectType)
        if (idx(data, _ => _.projectId)) req.projectId = idx(data, _ => _.projectId)
        if (idx(data, _ => _.taskId)) req.taskId = idx(data, _ => _.taskId)
        if (idx(data, _ => _.taskType)) req.taskType = idx(data, _ => _.taskType)
        if (idx(data, _ => _.taskName)) req.taskName = idx(data, _ => _.taskName)
        this.props.allocateSchedule(req)
    }
    render() {
        const { open, dialogClose } = this.props
        const { list, type, prevType, startDate, finishDate } = this.state
        return (
            <Modal
                open={open}
                onClose={dialogClose}
                fullWidth
                maxWidth='md'
                modalHeading={'Confirm Allocations'}
                modalSaveTxt={'Confirm'}
                modalCloseTxt='Cancel'
                onClick={this.submitData}
                savebtnClass='createBtn'
                showActionBtn={true}
                primary
            >
                <div>
                    <Grid container style={{ marginBottom: "10px" }}>
                        <Grid sm={3} xs={6} item><p style={{ margin: "8px 20px" }}>Allocation Type</p></Grid>
                        <Grid sm={5} xs={6} item><p style={{ margin: "8px 0" }}>: {type}</p></Grid>
                    </Grid>
                    {prevType && type !== prevType && <Grid container style={{ marginBottom: "10px" }}>
                        <Grid sm={3} xs={6} item><p style={{ margin: "8px 20px" }}>Previous Allocation Type</p></Grid>
                        <Grid sm={5} xs={6} item><p style={{ margin: "8px 0" }}>: {prevType}</p></Grid>
                    </Grid>}
                    {startDate && <Grid container style={{ marginBottom: "10px" }}>
                        <Grid sm={3} xs={6} item><p style={{ margin: "8px 20px" }}>Start Time</p></Grid>
                        <Grid sm={5} xs={6} item><p style={{ margin: "8px 0" }}>: {`${moment(startDate).format('DD-MM-YYYY')} ${moment(startDate).format('LT')}`}</p></Grid>
                    </Grid>}
                    {finishDate && <Grid container style={{ marginBottom: "10px" }}>
                        <Grid sm={3} xs={6} item><p style={{ margin: "8px 20px" }}>End Time</p></Grid>
                        <Grid sm={5} xs={6} item><p style={{ margin: "8px 0" }}>: {`${moment(finishDate).format('DD-MM-YYYY')} ${moment(finishDate).format('LT')}`}</p></Grid>
                    </Grid>}
                    <div className='tableContainer'>
                        <CustomTable
                            columns={this.getColumns()}
                            data={list}
                            toolbar={false}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAllocateResource)
