import _ from "lodash";
import { itemFields } from "src/constants/fields.constant";
import { calculateSaleAmount } from "src/util/calculations/sale-amount";

export const materialToForm = (_material, { qty = 1, profitMargin = 0, profitMarginType = 'PERCENTAGE' }) => {
    if (!_.isObject(_material)) _material = JSON.parse(_material);

    const item = _.cloneDeep(itemFields)
    item.name.value = _material.item;
    item.taskId.value = _material.taskId || null;
    item.id = _material.id;
    item.manufacturer = _material.manufacturer;
    item.partNo = _material.partNo || _material.part_no;
    item.categoryId = _material.categoryId;
    item.costPerItem.value = _material.cost;
    item.saleAmount.value = calculateSaleAmount({
        cost: _material.cost,
        profitMargin,
        profitMarginType,
    }).toFixed(2);
    item.saleAmountLock.value = true;
    item.unit.value = _material.unit || null;
    item.qty.value = (_material.qty * qty) || 1;
    item.total.value = item.saleAmount.value * item.qty.value;

    return item;
}


