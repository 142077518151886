import React, { useState } from 'react'
import Modal from '../../components/modal/modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import { addMonths } from 'date-fns'
import { errorSnackBar } from './../../actions/common.action'
import Input from './../../components/inputs/input-simple'

function ChangeProjectStatus(props) {
  const { open, dialogClose, newStatus, } = props
  const [reason, setReason] = useState('')
  const [dlpMonths, setDLPMonths] = useState('')
  const [dlpDate, setDLPDate] = useState('')

  const changeDLPMonth = (e) => {
    const dlpDate = addMonths(new Date(), parseFloat(e.target.value))
    setDLPMonths(e.target.value)
    setDLPDate(dlpDate)
  }

  const submitForm = () => {
    const data = {
      status: newStatus
    }
    let isFormValid = true
    if (newStatus === 'DLP') {
      if (dlpDate === '') {
        props.errorSnackBar('Please Select Date')
        isFormValid = isFormValid && false
        return
      }
      data.dateOfDLP = dlpDate
    } else {
      if (reason.trim() === '') {
        props.errorSnackBar('Please Enter Reason')
        isFormValid = isFormValid && false
        return
      }
      data.reasonOfLost = reason
    }
    if (isFormValid) {
      setReason('')
      setDLPMonths('')
      setDLPDate('')
      props.changeStatus(data)
    }
  }

  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='sm'
      modalHeading='Change Project Status'
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      onClick={() => submitForm()}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <>
        {newStatus === 'DLP'
          ?
          <>
            <Grid container>
              <Grid xs={3} item><p>Enter DLP Months</p></Grid>
              <Grid xs={6} item>
                <Input
                  type='text'
                  value={dlpMonths}
                  label='Enter DLP Months'
                  onChange={changeDLPMonth}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item><p>DLP Date</p></Grid>
              <Grid xs={6} item>
                <Input
                  type='datepicker'
                  value={dlpDate}
                  label='DLP Date'
                  onChange={(e) => setDLPDate(e)}
                />
              </Grid>
            </Grid>
          </>
          :
          <Grid container className="subtaskrow-1">
            <Grid xs={3} item><p>Reason</p></Grid>
            <Grid xs={9} item className="reasonField">
              <Input
                type='textarea'
                value={reason}
                label='Reason'
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
          </Grid>}
      </>
    </Modal>
  )
}
const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProjectStatus)
