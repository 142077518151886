import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from '../modal/modal'
import './../modal/modal.scss'
import { Grid } from '@material-ui/core'
import Button from 'react-bootstrap/Button';
import SelectInput from '../inputs/select'

function DownloadDialog(props) {
  const { open, handleDownloadDialog, downloadFile, templateOptions = [] } = props
  const [templatevalue, setTemplateValue] = useState('');

  useEffect(() => {
    if (Array.isArray(templateOptions) && templateOptions.length == 1)
      setTemplateValue(templateOptions[0].value)
  }, [templateOptions])

  const submitForm = (fileFormat = 'pdf') => {
    if (!templatevalue) return;
    downloadFile(fileFormat, templatevalue)
  }
  let changeHandler = (e) => {
    setTemplateValue(e.target.value)
  }

  return (
    <Modal
      open={open}
      onClose={handleDownloadDialog}
      fullWidth={true}
      maxWidth='xs'
      modalHeading='Download Dialog'
      primary
    >
      {(templateOptions.length > 0) && <><div className="col-12 mt-3">
        <SelectInput
          name={'templateSelect'}
          label={'Select Template'}
          items={templateOptions}
          value={templatevalue}
          onChange={(e) => changeHandler(e)}
        />
      </div>
        {/* {templatevalue == '' && <h2 className="content-heading text-uppercase">Select File Format to Download</h2>} */}
        <div className="col-12 d-flex mt-3">
          <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => submitForm('pdf')}>PDF</Button>
          <Button type="submit" className="secondarybtn me-auto" onClick={() => submitForm('docx')}>WORD</Button>
        </div>
      </>}
      {(templateOptions.length == 0) && <Grid container spacing={2} className="downloadVariation" alignItems={'center'} ng-else>
        <Grid item xs={12} >
          <p>There is no document template uploaded for {props.downloadModuleName ? props.downloadModuleName : 'this Download'}. This can be added in Global Settings by an administrator.</p>
        </Grid>
      </Grid>}
    </Modal>
  )
}

DownloadDialog.defaultProps = {
  open: false
}

DownloadDialog.propTypes = {
  open: PropTypes.bool,
  handleDownloadDialog: PropTypes.func,
  downloadFile: PropTypes.func,
  handleTemplateChange: PropTypes.func
}

export default DownloadDialog;
