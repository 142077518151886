import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const ASSET = '/asset'
const ADD_ASSET = '/asset/add'
const EDIT_ASSET = '/asset/edit'
const ALLOCATE_ASSET = '/asset/allocate'
const ASSET_MAINTENANCE = '/asset/maintenance'
const ASSET_REPAIR = '/asset/repair'
const ASSET_ALLOCATION_HISTORY = '/asset/allocation/history'
const ASSET_MAINTENANCE_HISTORY = '/asset/maintenance/history'
const ASSET_SUBMIT = '/asset/submit'
const ASSET_DISCARD = '/asset/discard'

// // ************API call Functions starts here **************

export const getAssetList = async (category, projectId, serviceId) => {
    return await axios('get', `${ASSET}`, {},
        {
            loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {
                ...(category ? { category } : {}),
                ...(projectId ? { projectId } : {}),
                ...(serviceId ? { serviceId } : {}),
            }
        })
        .then(data => {
            return data
        },
            error => { // request level error
                return new Promise((resolve, reject) => {
                    reject(error)
                })
            })
}

export const getAsset = async (id) => {
    return await axios('get', `${ASSET}/${id}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const addAsset = async (data) => {
    return await axios('post', `${ADD_ASSET}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset Created Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const editAsset = async (data, id) => {
    return await axios('put', `${EDIT_ASSET}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset Updated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const deleteAsset = async (id) => {
    return await axios('delete', `${ASSET}/${id}`, {}, { loader: loaders.OVERLAY, successMessage: 'Asset Deleted Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const allocateAsset = async (data) => {
    return await axios('post', `${ALLOCATE_ASSET}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset Allocated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const assetMaintenance = async (data) => {
    return await axios('post', `${ASSET_MAINTENANCE}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset status changed to Maintenance', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const assetRepair = async (data) => {
    return await axios('post', `${ASSET_REPAIR}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset status changed to Repair', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const getAllocationHistory = async (assetId, projectId, serviceId) => {
    return await axios('get', `${ASSET_ALLOCATION_HISTORY}`, {},
        {
            loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {
                ...(assetId ? { assetId } : {}),
                ...(projectId ? { projectId } : {}),
                ...(serviceId ? { serviceId } : {})
            }
        })
        .then(data => {
            return data
        },
            error => { // request level error
                return new Promise((resolve, reject) => {
                    reject(error)
                })
            })
}

export const getMaintenanceHistory = async (assetId) => {
    return await axios('get', `${ASSET_MAINTENANCE_HISTORY}`, {},
        {
            loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {
                ...(assetId ? { assetId } : {})
            }
        })
        .then(data => {
            return data
        },
            error => { // request level error
                return new Promise((resolve, reject) => {
                    reject(error)
                })
            })
}

export const submitAsset = async (id, data) => {
    return await axios('put', `${ASSET_SUBMIT}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Asset Submitted!', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const discardAsset = async (id) => {
    return await axios('put', `${ASSET_DISCARD}/${id}`, {}, { loader: loaders.OVERLAY, successMessage: 'Asset Discarded!', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}
