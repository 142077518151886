import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import { getTimesheet, updateResource } from './../../actions/project-management.action'
import Modal from '../../components/modal/modal'
import Input from '../../components/inputs/input-simple'
import Grid from '@material-ui/core/Grid'
import './scss/user-management.scss'

class EditSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            allocationId: '',
            attendanceData: {},
            fromDate: {
                name: 'fromDate',
                label: 'From Date',
                type: 'datepicker',
                touched: false,
                error: false,
                valid: true,
                value: null,
                format: 'EEEE dd/MM/yyyy',
                required: true,
            },
            toDate: {
                name: 'toDate',
                label: 'To Date',
                type: 'datepicker',
                touched: false,
                error: false,
                valid: true,
                value: null,
                format: 'EEEE dd/MM/yyyy',
                required: true,
                minDate: '',
                minDateMessage: 'Due Date should be after Start Date',
            },
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.userData, this.props.userData)) {
            this.setState({ userData: this.props.userData })
        }
        if (!_.isEqual(prevProps.open, this.props.open) && this.props.open) {
            this.getAllocationData(this.props.userData)
        }
    }

    getAllocationData = async (userData) => {
        const { fromDate, toDate } = this.state
        const allocationId = userData && userData.allocationId
        const timesheetArray = await this.props.getTimesheet('', allocationId)
        const timesheetData = timesheetArray[0]

        this.setState({
            timesheetData,
            fromDate: { ...fromDate, value: timesheetData.fromDate },
            toDate: { ...toDate, value: timesheetData.toDate, minDate: timesheetData.fromDate }
        })
    }

    inputChangeHandler = (e, fieldName = '') => {
        const { fromDate, toDate } = this.state
        if (fieldName === 'fromDate') {
            this.setState({ fromDate: { ...fromDate, value: e }, toDate: { ...toDate, minDate: e } })
        } else if (fieldName === 'toDate') {
            this.setState({ toDate: { ...toDate, value: e } })
        }
    }

    submitForm = async () => {
        const { fromDate, toDate, timesheetData } = this.state
        let isFormValid = true

        if (!fromDate.value) {
            fromDate.touched = true
            fromDate.valid = false
            fromDate.error = 'This is Required!'
            isFormValid = false
        }
        if (!toDate.value) {
            toDate.touched = true
            toDate.valid = false
            toDate.error = 'This is Required!'
            isFormValid = false
        }

        if (!isFormValid) {
            this.setState({ fromDate, toDate })
        } else {
            const data = {
                fromDate: fromDate.value,
                toDate: toDate.value,
            }

            await this.props.updateResource(timesheetData.id, data)
            this.handleClose()
            this.props.getAllocations()
        }
    }

    handleClose = () => {
        this.props.handleClose()
    }

    renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
        const { timesheetData } = this.state
        const attendanceData = timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.find(x => moment(x.reportingDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))

        if (attendanceData) {
            if (attendanceData.onLeave) {
                return (<div className='timesheet-filled-leave'>
                    {dayComponent}
                </div>)
            } else if (attendanceData.inTime && !attendanceData.onLeave) {
                return (<div className='timesheet-filled-present'>
                    {dayComponent}
                </div>)
            }
        }
        return dayComponent
    }

    shouldDisableDate = (date) => {
        const { timesheetData } = this.state
        const attendanceData = timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.find(x => moment(x.reportingDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))

        if (attendanceData) {
            if (attendanceData.onLeave || attendanceData.inTime) {
                return true
            }
        }
        return false
    }

    render() {
        const { open } = this.props
        const { fromDate, toDate } = this.state

        return (
            <Modal
                open={open}
                onClose={this.handleClose}
                onClick={this.submitForm}
                modalHeading={'Edit User Schedule'}
                modalSaveTxt={'Edit'}
                modalCloseTxt='Cancel'
                showActionBtn={true}
                fullWidth={true}
                maxWidth={'sm'}
                cxlbtnClass='cancelBtn'
                savebtnClass='createBtn'
                primary
            >
                <Grid container xs={12} className="userleave">
                    <Grid item xs={5}>
                        <Input
                            {...fromDate}
                            variant={'inline'}
                            renderDay={this.renderDayInPicker}
                            shouldDisableDate={this.shouldDisableDate}
                            onChange={(e) => this.inputChangeHandler(e, 'fromDate')}
                        />
                    </Grid>
                    <Grid item xs={1} style={{ margin: 'auto', textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            {...toDate}
                            variant={'inline'}
                            renderDay={this.renderDayInPicker}
                            shouldDisableDate={this.shouldDisableDate}
                            onChange={(e) => this.inputChangeHandler(e, 'toDate')}
                        />
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}

EditSchedule.defaultProps = {
    open: false,
}

EditSchedule.propTypes = {
    open: PropTypes.bool,
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    updateResource: bindActionCreators(updateResource, dispatch),
    getTimesheet: bindActionCreators(getTimesheet, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);
