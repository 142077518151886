import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./invoice.scss";
import {
  CREATE_INVOICE,
  RECEIVABLES,
} from "./../../../constants/route-paths";
import { getProjectList } from "./../../../actions/project-management.action";
import {
  getInvoiceList,
  downloadProjectInvoice,
} from "./../../../actions/invoice.action";
import ViewInvoice from "./../../project-management/project-execution/invoices/view-invoice";
import idx from "idx";
import { format } from "date-fns";
import InvoiceFilter from "./invoice-list-filter";
import { numberFormat } from "../../../util/currency-formatter";
import _ from "lodash";
import DownloadDialog from "../../../components/download-dialog";
import { warningSnackBar } from "./../../../actions/common.action";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getGlobalSettingTemplateTypes } from "./../../../actions/global-setting-template.action";
import { withRouter } from "../../../util/with-router";
import Popover from 'react-bootstrap/Popover';
import CustomTable from "src/components/table/table";

function InvoiceList({ projectModules = [], projectModule, writePermission, ...props }) {

  const [state, setState] = useState({
    columns: [
      { title: "Invoice No", field: "id" },
      { title: "Bill To", field: "customer" },
      { title: "Progress Claim No", field: "progressClaimNo" },
      { title: "Project Name", field: "projectName" },
      {
        title: "Amount",
        field: "claim_amount",
        render: (rowData) => `${numberFormat(rowData.claim_amount)}`,
      },
      {
        title: "Issue Date",
        field: "issueDate",
        render: (rowData) => rowData.issueDate ? format(new Date(rowData.issueDate), "dd/MM/yyyy") : "",
      },
      {
        title: "Due Date",
        field: "dueDate",
        render: (rowData) => rowData.dueDate ? format(new Date(rowData.dueDate), "dd/MM/yyyy") : "",
      },
      // { title: 'Invoice Status', field: 'status', }
    ],
    invoices: [],
    projectsList: [],
    viewInvoiceState: false,
    showInvoiceFilter: false,
    workType: "project",
    projectId: "",
    templateOptions: [],
    invoiceData: {},
  })

  useEffect(() => {
    getInvoiceList();
    getProjectList();
  }, [])

  const getProjectList = async () => {
    let projects = await props.getProjectList(null, '', '', projectModules);
    let projectsList = [];

    projects.forEach((project) => projectsList.push({ key: project.name, value: project.id }));
    projectsList = _.orderBy(projectsList, [({ key }) => key.toLowerCase()], ["asc"]);

    projectsList.unshift({ key: "All Projects", value: "all" });
    setState(prev => ({
      ...prev,
      projectsList
    }))
  };

  const getInvoiceList = async (projectId, startDate, endDate) => {
    let list = await props.getInvoiceList(projectId, startDate, endDate);
    const invoices = [];
    list.forEach((invoice) => {
      invoices.push({
        ...invoice,
        id: invoice.id,
        customer: idx(invoice, (_) => _.progressClaim.project.customer.businessName) || "",
        progressClaimNo: idx(invoice, (_) => _.progressClaim.id) || "",
        progressClaimId: idx(invoice, (_) => _.progressClaim.u_id) || "",
        projectName: idx(invoice, (_) => _.progressClaim.project.name) || "",
        projectId: idx(invoice, (_) => _.progressClaim.projectId) || "",
        claim_amount: idx(invoice, (_) => _.progressClaim.claimAmount.toFixed(2)) || 0,
        issueDate: idx(invoice, (_) => _.invoiceDate) || "",
        dueDate: idx(invoice, (_) => _.dueDate) || "",
      });
    });
    setState(prev => ({
      ...prev,
      invoices: invoices
    }))
  };

  const createInvoice = () => {
    props.history(`/${RECEIVABLES}/${CREATE_INVOICE}`);
  };

  const viewInvoiceHandler = (data) => {
    setState(prev => ({
      ...prev,
      viewInvoiceState: !prev.viewInvoiceState,
      projectId: data.projectId,
      invoiceData: data,
    }))
  };

  const invoiceFilterHandler = () => {
    setState(prev => ({
      ...prev,
      showInvoiceFilter: !prev.showInvoiceFilter,
    }))
  };

  const changeFilter = (projectId, startDate, endDate) => {
    if (projectId === "all") { projectId = ""; }
    getInvoiceList(projectId, startDate, endDate);
    invoiceFilterHandler();
  };

  const changeWorkType = (workType) => {
    let projectsList = [];
    setState(prev => ({
      ...prev,
      workType,
      projectsList
    }))
    if (props[`${workType}Module`] === false) {
      props.warningSnackBar(`This user does not have access for ${workType === "service" ? "Services" : "any Projects"}`);
      return false;
    }
    getProjectList();
    changeFilter("all");
  };

  const gettemplate = async (type) => {
    let { templateOptions, projectId } = state;
    let newdata = [];
    const templateData = await props.getGlobalSettingTemplateTypes(type, projectId);
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    setState(prev => ({
      ...prev,
      templateOptions
    }))
  };

  const handleDownloadDialog = (id = "", template = "") => {
    const { downloadData = {}, invoiceData } = state;
    downloadData.open = !downloadData.open;
    downloadData.id = invoiceData.progressClaimId;
    downloadData.template = template;
    if (downloadData.template) {
      gettemplate(downloadData.template);
    }
    setState(prev => ({
      ...prev,
      downloadData
    }))
  };

  const downloadFile = async (fileFormat = "pdf", tempId) => {
    const { downloadData } = state;
    if (fileFormat === "pdf" || fileFormat === "ALL") {
      await props.downloadProjectInvoice(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    if (fileFormat === "docx" || fileFormat === "ALL") {
      await props.downloadProjectInvoice(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    handleDownloadDialog();
  };

  return (
    <>
      <DownloadDialog
        {...(state.downloadData || {})}
        downloadFile={downloadFile}
        templateOptions={state.templateOptions}
        handleDownloadDialog={handleDownloadDialog}
        downloadModuleName="Invoice"
      />
      {state.viewInvoiceState && (
        <ViewInvoice
          open={state.viewInvoiceState}
          filter="INVOICES"
          invoiceData={state.invoiceData}
          dialogClose={() => {
            setState(prev => ({
              ...prev,
              viewInvoiceState: !prev.viewInvoiceState,
            }))
          }}
          projectId={state.projectId}
          workType={state.workType}
          handleDownloadDialog={handleDownloadDialog}
        />
      )}
      {state.showInvoiceFilter && <InvoiceFilter
        open={state.showInvoiceFilter}
        changeFilter={changeFilter}
        projects={state.projectsList}
        changeWorkType={changeWorkType}
        dialogClose={invoiceFilterHandler}
        workType={state.workType}
      />}

      {!projectModule ? (
        <p>
          You do not currently have access to any Projects Finances. Please
          contact your Administrator if this is required
        </p>
      ) : (
        <div className="purchase-order-block">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">Invoices</h2>

            </div>
            {writePermission && (<div className="w-auto ms-auto mt-auto">
              <button
                onClick={createInvoice}
                className="secondarybtn secondarybtn-outline btn-ch ms-auto me-2 btn btn-primary">
                Create Invoice
              </button>
              <span onClick={invoiceFilterHandler} className="filter-icon"><FilterAltIcon /></span>
            </div>)}
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <CustomTable
                columns={state.columns}
                data={state.invoices}
                isAction={true}
                menu={(data) => (
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <ul className="action-block-list">
                        <li onClick={() => viewInvoiceHandler(data)}>
                          View Invoice
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                )}
                fileName='Total Invoice List'
              />
            </div>
          </div>

        </div>
      )}

    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceList: bindActionCreators(getInvoiceList, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(getGlobalSettingTemplateTypes, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  downloadProjectInvoice: bindActionCreators(downloadProjectInvoice, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
);
