import React, { useState } from 'react'
import Modal from '../modal/modal'
import './../modal/modal.scss'
import CustomInput from '../inputs/new-input'

export default function CompletionPercentage(props) {
  const { open, dialogClose, setCompletionPercentage, percentageCompleted } = props
  const [percentage, setPercentage] = useState(percentageCompleted)

  const submitForm = () => {
    setCompletionPercentage(percentage)
  }
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='sm'
      modalHeading='Completion Percentage'
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      onClick={submitForm}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <div className="row m-0">
        <div className="col-lg-12 pb-2">
          <CustomInput
            label={'Completion Percentage'}
            type='number'
            value={percentage}
            onChange={(e) => {
              let value = parseFloat(e.target.value || 0)
              value = value < 0 ? 0 : value
              value = value > 100 ? 100 : value
              setPercentage(value)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
