// Snackbar
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// Overlay
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const HIDE_OVERLAY = 'HIDE_OVERLAY'

// Notification
export const NAVBAR_SET_NOTIFICATION = 'NAVBAR_SET_NOTIFICATION'
export const NAVBAR_ADD_NOTIFICATION = 'NAVBAR_ADD_NOTIFICATION'
export const NAVBAR_REMOVE_NOTIFICATION = 'NAVBAR_REMOVE_NOTIFICATION'
export const NAVBAR_SET_NOTIFICATION_READ = 'NAVBAR_SET_NOTIFICATION_READ'

// User Reducer
export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

// Common Reducer
export const SET_MODULE_NAME = 'SET_MODULE_NAME'

// Purchase Order Reducer
export const SAVE_PO_DATA = 'SAVE_PO_DATA'
export const RESET_PO_DATA = 'RESET_PO_DATA'

// Project Reducer
export const SAVE_PROJECT_TYPE = 'SAVE_PROJECT_TYPE'
export const REMOVE_PROJECT_TYPE = 'REMOVE_PROJECT_TYPE'
export const SAVE_PROJECT_CATEGORIES = 'SAVE_PROJECT_CATEGORIES'

// Global Setting Reducer
export const FINANCE_TAX = 'FINANCE_TAX'

// Mobile Drawer
export const MOBILE_DRAWER = 'MOBILE_DRAWER'

// App Version
export const APP_VERSION = 'APP_VERSION'
