//import axios from 'axios/index'
import axios from 'axios';// import idx from 'idx'
import { loaders } from './../constants'
import { store } from './../store'
import {
  persistantSnackbar,
  successSnackBar,
  //  warningSnackBar,
  showLoadingIndicator,
  hideLoadingIndicator,
  showOverlay,
  hideOverlay
} from './../actions/common.action'
import { getAccessToken } from './../util/auth'
import {
  removeUserData
} from './../actions/user-management.action'
import idx from 'idx';
// import { validateSchema } from './schemas'

const axiosClient = (baseUrl, config) => (
  axios.create({
    baseURL: baseUrl,
    ...config
  })
)

const microServicesURLs = {
  DE: `${process.env.REACT_APP_API_GATEWAY}`,
  DE_CORE: `${process.env.REACT_APP_CORE_API_GATEWAY}`
}

const clients = {}; const microServices = {}

for (const key in microServicesURLs) {
  clients[key] = axiosClient(microServicesURLs[key], {
    headers: {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json'
    }
  })
  microServices[key] = key
}

const getLoaderActions = (loader) => {
  switch (loader) {
    case loaders.OVERLAY: return {
      show: showOverlay(),
      hide: hideOverlay()
    }
    case loaders.TOPBAR: return {
      show: showLoadingIndicator(),
      hide: hideLoadingIndicator()
    }
    default: return false
  }
}

const axiosConf = (method, uri, data = {}, configs) => {
  const {
    loader = loaders.TOPBAR,
    // schema = null,
    successMessage = null,
    server = microServices.COMMON,
    headers = {},
    params = {},
    onUploadProgress = {},
    responseType = null
  } = configs || {}

  const axiosConfig = {
    headers,
    params,
    onUploadProgress
  }

  if (responseType) {
    axiosConfig.responseType = responseType
  }

  const authToken = getAccessToken()
  if (authToken) {
    axiosConfig.headers.Authorization = `Bearer ${authToken}`
  }

  // global loader showing
  const loaderAction = getLoaderActions(loader)
  loader && store.dispatch(loaderAction.show)
  return clients[server][method](uri, method === 'get' || method === 'delete' ? axiosConfig : data, axiosConfig).then(response => {
    // If ajax is for downloading file
    if (responseType === "blob") {
      return { file: response.data, headers: response.headers }
    }
    successMessage && store.dispatch(successSnackBar(successMessage))
    return response.data.data
    // if (idx(response, _response => _response.data.success)) {
    //   if (idx(response, _response => _response.data.data)) {
    //     const data = idx(response, _response => _response.data.data)
    //     // if (schema) {
    //     //   const result = validateSchema(schema, data)
    //     //   if (result !== true) {
    //     //     store.dispatch(persistantSnackbar(uri + 'giving invalid Response format. '))
    //     //     return new Promise((resolve, reject) => reject(uri + ' giving invalid response format. ' + result))
    //     //   }
    //     // }
    //     successMessage && store.dispatch(successSnackBar(successMessage))
    //     return data
    //   } else {
    //     store.dispatch(persistantSnackbar('Data not sent from server'))
    //     return new Promise((resolve, reject) => reject('Data not sent from server'))
    //   }
    // }
  }, error => {
    // request level error
    let errorMessage = 'Unknown Error'
    try {
      if (responseType === "blob") {
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        return new Promise((resolve, reject) => {
          reader.onload = function () {
            const errorData = JSON.parse(reader.result);
            const extractedErrorMessage = errorData.error || error.response.statusText;
            reject(extractedErrorMessage);
            store.dispatch(persistantSnackbar(extractedErrorMessage, 'error'))
          };
        });
      } else errorMessage = error.response.data.message || error.response.data.error || error.response.statusText
    } catch (e) {
      if (error.request.status === 0) { errorMessage = 'Server not Reachable' }
    }
    
    if (error.request.status === 401) {
      store.dispatch(removeUserData())

      if (!errorMessage) {
        //store.dispatch(warningSnackBar('Session Expired'))
      } else {
        store.dispatch(persistantSnackbar(errorMessage, 'error'))
      }
    } else if (error.request.status === 400) {
      let message = idx(error, _ => _.response.data.validation.body.message) || errorMessage
      store.dispatch(persistantSnackbar(message, 'error'))
    } else {
      store.dispatch(persistantSnackbar(errorMessage, 'error'))
    }

    return new Promise((resolve, reject) => {
      // remain to write logic for givig message according to error status code
      reject(errorMessage)
    })
  }).finally(() => {
    loader && store.dispatch(loaderAction.hide)
  })
}

export default axiosConf;

export {
  microServices,
  microServicesURLs
}
