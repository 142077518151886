import React, { useEffect, useState } from "react";
import { getService } from "../../actions/service.action";
import { useNavigate, useSearchParams } from "react-router-dom";
import idx from "idx";
import "./breadcumb.scss";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function Breadcumb(props) {
  const { children, projectId, serviceId } = props;
  const { projectDetail = {} } = useSelector(state => state)
  // const [projectName, setProject] = useState("");
  const [serviceName, setService] = useState("");
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    // if (projectId) {
    //   props.getProject(projectId).then((project) => {
    //     const name = idx(project, (_) => _.name) || "";
    //     setProject(name);
    //   });
    // }

    if (serviceId) {
      dispatch(getService(serviceId)).then((service) => {
        const description = idx(service, (_) => _.description) || "";
        setService(description);
      });
    }
  }, [serviceId]);

  const projectList = () => {
    let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType) || ''
    navigate(`/projects?project-type=${projectType}`);
  };

  const viewProject = () => {
    navigate(`/projects/view/${projectId}`);
  };

  const serviceList = () => {
    navigate("/services");
  };

  const viewService = () => {
    navigate(`/services/view/${serviceId}`);
  };

  return (
    <>
      {projectId && (
        <>
          <ArrowCircleLeftOutlinedIcon
            className="arrow-circle-icon"
            onClick={() => navigate(-1)}
          />
          <span className="link" onClick={projectList}>
            Projects{" "}
          </span>
          <span className="arrow"> {"/"} </span>
          <span className={children ? "link" : ""} onClick={viewProject}>
            {idx(projectDetail, _ => _.project.projectName) || ''}
          </span>
          {children && <span className="arrow"> {"/"} </span>}
          {children}
        </>
      )}
      {serviceId && (
        <>
          <ArrowCircleLeftOutlinedIcon
            className="arrow-circle-icon"
            onClick={() => navigate(-1)}
          />
          <span className="link" onClick={serviceList}>
            Services
          </span>
          <span className="arrow"> {"/"} </span>
          <span className={children ? "link" : ""} onClick={viewService}>
            {serviceName}
          </span>
          {children && <span className="arrow"> {"/"} </span>}
          {children}
        </>
      )}
    </>
  );
};

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => ({
//   getProject: bindActionCreators(getProject, dispatch),
//   getService: bindActionCreators(getService, dispatch),
// });
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Breadcumb)
// );
