import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "src/util/with-router";
import { TransitionGroup } from "react-transition-group";
import LabourList from './labour/list'
import AddLabor from "./labour/add-labor";
import AddLevel from './labour/add-level'
import MaterialList from './material/list'
import AddMaterial from './material/add-material'
import RBACApplicationAuth from "src/layout/rbac";
import * as paths from "src/constants/route-paths";

function CustomRoutes({ location }) {
    let element = useRoutes([
        {
            path: paths.RESOURCES,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={LabourList} />,
        },
        {
            path: paths.ADD_RESOURCE,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddLabor} />,
        },
        {
            path: paths.EDIT_RESOURCE,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddLabor} />,
        },
        {
            path: paths.ADD_RESOURCE_LEVEL,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddLevel} />,
        },
        {
            path: paths.EDIT_RESOURCE_LEVEL,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddLevel} />,
        },
        {
            path: paths.MATERIAL,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={MaterialList} />,
        },
        {
            path: paths.ADD_MATERIAL,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddMaterial} />,
        },
        {
            path: paths.EDIT_MATERIAL,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddMaterial} />,
        },
    ]);

    return element;
}

class Root extends React.PureComponent {
    render() {
        const { location } = this.props;
        return (
            <div className="clearfix" style={{ width: "100%" }}>
                <TransitionGroup>
                    <CustomRoutes location={location} />
                </TransitionGroup>
            </div>
        );
    }
}
export default withRouter(Root);
