import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/user-management.scss";
import { Link } from "react-router-dom";
import UserRole from "./user-role";
import { USER_MANAGEMENT, ADD_USER } from "./../../constants/route-paths";
import { getUserList } from "./../../actions/user-management.action";
import { loginToImpersonateAccount } from "./../../actions/global-account.action";
import { editUser, deleteUser } from "./../../actions/user-management.action";
import DeleteDialog from "./../../components/delete-dialog";
import { withRouter } from "../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import { errorSnackBar } from "src/actions/common.action";
import LoginIcon from '@mui/icons-material/Login';

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      users: [],
      ActiveUsers: [],
      InactiveUsers: [],
      deleteDialogState: false,
      data: [],
      type: "active",
      userId: null,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  getUserList = () => {
    this.props.getUserList().then((users) => {
      const data = users
        .filter((user) => !user.isDeleted)
        .map((user) => {
          return {
            userName:
              (user.firstName && user.firstName != null ? user.firstName : "") +
              " " +
              (user.lastName && user.lastName != null ? user.lastName : ""),
            status: user.status,
            role: (user.roles && user.roles.name) || "",
            email: user.email,
            phone: user.phone_number,
            id: user.id,
          };
        });
      const ActiveUsers = data.filter((user) => {
        if (user.status === "ACTIVE") {
          return user;
        } else {
          return undefined;
        }
      });
      const InactiveUsers = data.filter((user) => {
        if (user.status === "INACTIVE") {
          return user;
        } else {
          return undefined;
        }
      });
      this.setState({
        users: data,
        data: ActiveUsers,
        ActiveUsers,
        InactiveUsers,
      });
    });
  };

  getColumns = () => {
    return [
      {
        title: "User Name",
        field: "userName",
        render: (rowData) => (
          <Link to={`/${USER_MANAGEMENT}/edit/${rowData.id}`}>
            {rowData.userName}
          </Link>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: (rowData) =>
          rowData.status === "ACTIVE" ? (
            <span className="status-badge active">Active</span>
          ) : (
            <span className="status-badge inactive">Inactive</span>
          ),
      },
      { title: "User Role", field: "role" },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phone" },
      {
        title: "Active",
        field: "status",
        lookup: { true: "ACTIVE", false: "INACTIVE" },
        render: (rowData) => (
          <div className="form-check form-switch">
            <input
              className={`form-check-input switch-checkbox ${rowData.status === "ACTIVE" ? '' : 'inactive'}`}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              disabled={this.props.writePermission ? false : true}
              value={rowData.status === "ACTIVE" ? true : false}
              checked={rowData.status === "ACTIVE" ? true : false}
              onChange={(e) =>
                this.handleStatusChange(e.target.checked, rowData.id)
              }
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
          </div>
        ),
      },
    ];
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  addUser = () => {
    if ((this.props.entitlements.totalUsers <= this.state.ActiveUsers.length) && this.props.entitlements.totalUsers >= 0) {
      this.props.errorSnackBar('You have reached the limit for User Creation. Please contact our Support Team');
      return;
    }
    this.props.history(`/${USER_MANAGEMENT}/${ADD_USER}`);
  };

  editUser = (userId) => {
    this.props.history(`/${USER_MANAGEMENT}/edit/${userId}`);
  };

  handleLoginToImpersonateAccount = (id = "") => {
    if (!this.props.globalUser) return;
    const { users } = this.state;
    let user = users.find((user) => user.id === id);
    if (!user) return;
    this.props.loginToImpersonateAccount(id, user.userName || '');
  };

  deleteHandler = (id) => {
    this.handleCloseAnchorEl();
    this.props.deleteUser(this.state.deleteId).then((res) => {
      this.deleteDialogHandler();
      this.getUserList();
    });
  };

  handleStatusChange = (status, id = "") => {
    status = status ? "ACTIVE" : "INACTIVE";
    this.props.editUser({ status }, id).then((res) => {
      this.getUserList();
    });
  };

  deleteDialogHandler = (id = "") => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteId: id,
    }));
  };

  filter = (status) => {
    if (status === "all") {
      this.setState((prevState) => ({
        data: prevState.users,
        type: status,
      }));
    } else if (status === "active") {
      this.setState((prevState) => ({
        data: prevState.ActiveUsers,
        type: status,
      }));
    } else if (status === "inactive") {
      this.setState((prevState) => ({
        data: prevState.InactiveUsers,
        type: status,
      }));
    }
  };

  handleCloseAnchorEl = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = (event, userId) => {
    this.setState({ anchorEl: event.currentTarget, userId });
  };

  render() {
    const {
      open,
      ActiveUsers,
      deleteDialogState,
      deleteId,
      InactiveUsers
    } = this.state;
    let { writePermission,aUserPermission } = this.props;

    return (
      <>
        <DeleteDialog
          open={deleteDialogState}
          id={deleteId}
          deleteHandler={this.deleteHandler}
          dialogClose={this.deleteDialogHandler}
          moduleName="User"
        />
        {open && (
          <UserRole
            open={open}
            handleClose={this.handleClose}
            getUserList={this.getUserList}
          />
        )}

        <div className="purchase-order-block">
          <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">Users</h2>
            </div>
            {aUserPermission && (<div className="w-auto ms-auto mt-auto">
              <button onClick={this.handleOpen} className="secondarybtn secondarybtn-outline btn-ch ms-auto me-2 btn btn-primary">Define User Role</button>
              <button onClick={this.addUser} className="secondarybtn secondarybtn-outline btn-ch btn btn-primary">Add User</button>
            </div>)}
          </div>

          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="activeuser"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="activeuser" title={`Active Users (${ActiveUsers.length})`}>
                  <CustomTable
                    columns={this.getColumns()}
                    data={ActiveUsers}
                    isMore={this.props.globalUser}
                    isMoreIcon={<LoginIcon />}
                    isMoreHandler={(item) => this.handleLoginToImpersonateAccount(item.id)}
                    isEdit={writePermission ? true : false}
                    editHandler={(data) => this.editUser(data.id)}
                    isDelete={writePermission ? true : false}
                    deleteHandler={(data) => this.deleteDialogHandler(data.id)}
                    fileName='Active Users List'
                  />
                </Tab>
                <Tab eventKey="inactiveuser" title={`Inactive Users (${InactiveUsers.length})`}>
                  <CustomTable
                    columns={this.getColumns()}
                    data={InactiveUsers}
                    isMore={this.props.globalUser}
                    isMoreIcon={<LoginIcon />}
                    isMoreHandler={(item) => this.handleLoginToImpersonateAccount(item.id)}
                    isEdit={writePermission ? true : false}
                    editHandler={(data) => this.editUser(data.id)}
                    isDelete={writePermission ? true : false}
                    deleteHandler={(data) => this.deleteDialogHandler(data.id)}
                    fileName='Inactive Users List'
                  />
                </Tab>
              </Tabs>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  entitlements: state.user.entitlements
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: bindActionCreators(getUserList, dispatch),
  loginToImpersonateAccount: bindActionCreators(loginToImpersonateAccount, dispatch),
  editUser: bindActionCreators(editUser, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersList)
);
