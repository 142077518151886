import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/inventory.scss";
import { INVENTORY } from "./../../constants/route-paths";
import { getTransactionsList } from "./../../actions/inventory.action";
import { format } from "date-fns";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CustomTable from "src/components/table/table";

class AddStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      columns: [],
    };
  }

  componentDidMount() {
    this.props.getTransactionsList().then((transactionsList) => {
      const transactions = [];
      transactionsList.forEach((transaction, index) => {
        const itemName = transaction.items && transaction.items.item;
        transactions.push({
          sno: index + 1,
          itemName,
          ...transaction,
          createdAtTime: format(new Date(transaction.createdAt), "hh:mm a"),
          createdAt: format(new Date(transaction.createdAt), "dd/MM/yyyy"),
        });
      });
      this.setState({
        transactions,
      });
    });
    const columns = [
      { title: "S No", field: "sno" },
      {
        title: "PO No.",
        field: "poId",
        render: (rowData) =>
          rowData.poId === "NO_PO" || rowData.poId === "No_PO"
            ? "NO PO"
            : rowData.poId,
      },
      { title: "Transaction Type", field: "transactionType" },
      { title: "Transaction Location", field: "location" },
      { title: "Item Name", field: "itemName" },
      { title: "Quantity", field: "quantity" },
      { title: "Allocated To", field: "allocateToName" },
      { title: "Time", field: "createdAtTime" },
      { title: "Date", field: "createdAt" },
    ];
    this.setState({
      columns,
    });
  }

  cancelHandler = () => {
    this.props.history(`/${INVENTORY}`);
  };

  render() {
    const { transactions, columns } = this.state;
    return (
      <div className="purchase-order-block">

        <h2 className="content-heading text-uppercase">Inventory Transactions</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={this.cancelHandler}>INVENTORY</Breadcrumb.Item>
            <Breadcrumb.Item active>Inventory Transactions</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <CustomTable
          columns={columns}
          data={transactions}
          fileName="Inventory Transactions List"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionsList: bindActionCreators(getTransactionsList, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddStock)
);
