import React, { useState } from 'react'
import Modal from 'src/components/modal/modal'
import { format } from 'date-fns'
import DatePickerInput from "src/components/inputs/date-picker";
import SelectInput from 'src/components/inputs/select'

export default function Filter(props) {
  const { changeFilter, projects, dialogClose, open } = props

  const [projectId, setProjectId] = useState('all')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='md'
      modalHeading='Select Filter'
      modalSaveTxt='Apply Filter'
      modalCloseTxt='Cancel'
      onClick={() => changeFilter(projectId, startDate, endDate)}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <div className="purchase-order-block mb-3">
        <div className="row">

          <div className="col-lg-4 col-md-4 mt-3">
            <SelectInput
              items={projects}
              label={'Project Filter'}
              onChange={(e) => setProjectId(e.target.value)}
              value={projectId}
            />
          </div>

          <div className="col-lg-4 col-md-4 mt-3">
            <DatePickerInput
              label={'Start Date'}
              maxDate={endDate || null}
              value={startDate}
              onChange={(e) => setStartDate((e && format(new Date(e), 'MM/dd/yyyy')) || null)}
            />
          </div>

          <div className="col-lg-4 col-md-4 mt-3">
            <DatePickerInput
              label={'End Date'}
              value={endDate}
              minDate={startDate || null}
              onChange={(e) => setEndDate((e && format(new Date(e), 'MM/dd/yyyy')) || null)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
