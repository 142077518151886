import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import AssetList from "./asset-list";
import AddAsset from "./add-asset";
import AllocateAsset from "./allocate-asset";
import EditAsset from "./edit-asset";
import AssetMaintenance from "./asset-maintenance";
import AssetRepair from "./asset-repair";
import * as paths from "../../constants/route-paths";
import RBACApplicationAuth from "src/layout/rbac";

function CustomRoutes({ location }) {
  let element = useRoutes([
    {
      path: "",
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AssetList} />,
    },
    {
      path: paths.ADD_ASSET,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AddAsset} />,
    },
    {
      path: paths.EDIT_ASSET,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={EditAsset} />,
    },
    {
      path: paths.ALLOCATE_ASSET,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AllocateAsset} />,
    },
    {
      path: paths.ASSET_MAINTENANCE,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AssetMaintenance} />,
    },
    {
      path: paths.ASSET_REPAIR,
      element: <RBACApplicationAuth moduleURL={location.pathname} component={AssetRepair} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
