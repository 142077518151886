import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPOInvoice } from "src/actions/project-management.action";
import { SUPPLIERS, VENDOR_PAYMENT } from "src/constants/route-paths";
import "./scss/style.scss";
import idx from "idx";
import { format } from "date-fns";
import { numberFormat } from "src/util/currency-formatter";
import { withRouter } from "src/util/with-router";
import 'src/routes/project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { getOnlyProjectData } from "src/routes/project-management/utils/get-project";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

function ViewInvoicePurchaseOrder(props) {
  const [invoiceData, setInvoiceData] = useState([]);
  const [items, setItems] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [projectId, setProjectId] = useState(null);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { projectDetail = {} } = useSelector(state => state)

  useEffect(() => {
    const invoiceId = props.params.invoiceId;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    setProjectId(id);
    getPOInvoice(invoiceId);

    if (!idx(projectDetail, _ => _.project.res) && id)
      getOnlyProjectData({ projectId: id, dispatch })
  }, []);

  const getPOInvoice = async (invoiceId) => {
    let invoice;
    invoice = await props.getPOInvoice(invoiceId).catch(e => console.log(e));

    const data = {
      poId: idx(invoice, (_) => _.purchaseOrder.id),
      invoiceNo: invoice.invoiceNo,
      amountPaid: invoice.amountPaid,
      retentionAmount: invoice.retentionAmount,
      invoiceURL: invoice.invoiceURL,
      invoiceClaim: invoice.invoiceClaim,
      invoiceDate: invoice.createdAt,
      approvedAmount: invoice.approvedAmount,
    };

    const items = idx(invoice, (_) => _.items) || [];

    const vendorType = idx(invoice, (_) => _.purchaseOrder.vendor.type);
    setItems(items);
    setVendorType(vendorType);
    setInvoiceData(data);
  };

  const cancelHandler = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    const id = urlParams.get("id");
    const poId = urlParams.get("poId");

    if (id && type === "project") {
      if (poId) {
        props.history(`/${SUPPLIERS}/${VENDOR_PAYMENT}?project=${id}&poId=${poId}`);
      } else {
        props.history(`/${SUPPLIERS}/${VENDOR_PAYMENT}?project=${id}`);
      }
    } else if (type && poId) {
      props.history(`/${SUPPLIERS}/${VENDOR_PAYMENT}?type=${type}&poId=${poId}`);
    } else {
      props.history(`/${SUPPLIERS}/${VENDOR_PAYMENT}`);
    }
  };

  const viewProject = () => navigate(`/projects/view/${projectId}`)
  const viewProjectList = () => navigate(`/projects?project-type=${idx(projectDetail, _ => _.project.res.projectType)}`)

  return (
    <div className="purchase-order-block">
      <h2 className="content-heading text-uppercase">VENDOR PAYMENT VIEW</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          {projectId ? (
            <>
              <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
              <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.res.name)}</Breadcrumb.Item>
              <Breadcrumb.Item onClick={cancelHandler}>VENDOR PAYMENT</Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </>
          ) : (
            <>
              <Breadcrumb.Item onClick={cancelHandler}>VENDOR PAYMENT</Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
      </div>

      <div className="row">
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>PO</label>
            <p>{invoiceData.poId}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Invoice No</label>
            <p>{invoiceData.invoiceNo}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Invoice Claim</label>
            <p>{numberFormat(invoiceData.invoiceClaim)}</p>
          </div>
        </div>
        {vendorType === "sub-contractor" && (<div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Approved Amount</label>
            <p>{numberFormat(invoiceData.approvedAmount)}</p>
          </div>
        </div>)}
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Amount Paid</label>
            <p>{numberFormat(invoiceData.amountPaid)}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Retention Amount</label>
            <p>{numberFormat(invoiceData.retentionAmount)}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block">
            <label>Invoice Date</label>
            <p>{invoiceData && invoiceData.invoiceDate && format(new Date(invoiceData.invoiceDate), "dd/MM/yyyy")}</p>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          <div className="view-block text-left">
            <label>Invoice URL</label>
            <a
              href={invoiceData.invoiceURL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Invoice URL
            </a>
          </div>
        </div>
        <div className="col-12">
          <div className="material-list-block mt-4">
            <div className="table-responsive">
              <Table bordered className="table-create table-material table-material-ch">
                <thead>
                  <tr>
                    {vendorType === "Supplier" ? (
                      <>
                        <th>Task</th>
                        <th>Catalog</th>
                        <th>Items</th>
                        <th>Manufacturer</th>
                        <th>Part No</th>
                        <th>Unit Cost</th>
                        <th>Qty Ordered</th>
                        <th>Qty Received</th>
                        <th>Previous Inv</th>
                        <th>This Inv</th>
                        <th>Unit</th>
                        <th>Total</th>
                      </>
                    ) : (
                      <>
                        <th className="w-auto">Task</th>
                        <th className="w-auto text-right">Total Cost</th>
                        <th className="text-right">Previous Inv</th>
                        <th className="text-right">Invoice Claim</th>
                        <th className="text-right">This Inv</th>
                        <th className="text-right">Retained</th>
                        <th className="text-right">Total</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {vendorType === "Supplier" ? (
                    <>
                      {(Array.isArray(items) && items.length && items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.taskName}</td>
                          <td>{item.category}</td>
                          <td>{item.item}</td>
                          <td>{item.manufacturer}</td>
                          <td>{item.partNo}</td>
                          <td>{numberFormat(item.cost)}</td>
                          <td>{item.qty}</td>
                          <td>{item.receivedQty}</td>
                          <td>{item.previousInv}</td>
                          <td>{item.invoicedQty}</td>
                          <td>{item.unit}</td>
                          <td>{numberFormat(item.amountPaid)}</td>
                        </tr>
                      ))) || (
                          <tr>
                            <td colSpan={12} className="text-center"> No Items to Show</td>
                          </tr>
                        )}
                    </>
                  ) : (
                    <>
                      {(Array.isArray(items) && items.length && items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.taskName}</td>
                          <td className="text-right">{numberFormat(item.total)}</td>
                          <td className="text-right">{numberFormat(item.previousInv)}</td>
                          <td className="text-right">{numberFormat(item.thisInv)}</td>
                          <td className="text-right">{numberFormat(item.amountPaid)}</td>
                          <td className="text-right">
                            {numberFormat(parseFloat(item.thisInv || 0) - parseFloat(item.amountPaid || 0))}
                          </td>
                          <td className="text-right">{numberFormat(item.amountPaid)}</td>
                        </tr>
                      ))) || (
                          <tr>
                            <td colSpan={7} className="text-center"> No Items to Show</td>
                          </tr>
                        )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPOInvoice: bindActionCreators(getPOInvoice, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewInvoicePurchaseOrder));
