import React from 'react'
// import { render, hydrate } from 'react-dom'
import { hydrateRoot } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom'
import { store, history } from './store'
import App from './App'
import { SnackbarProvider } from 'notistack'
import Notifier from './components/snackbar'

import notificationTheme from './components/notificationsTheme'
import NotificationsSystem from 'reapop'

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "tomato",
          fontWeight: "bold"
        }
      },

      focused: {}
    }
  },
  palette: {
    primary: {
      light: 'red',
      main: '#3f51b5',
      dark: '#000'
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto'
  }
})

// Create a store and get back itself and its history object
// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <NotificationsSystem theme={notificationTheme} />
    <BrowserRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={10}>
          <Notifier />
          <App userAgent={navigator.userAgent} />
        </SnackbarProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>
)

const container = document.getElementById('root');
// const root = document.querySelector('#root')
// const root = createRoot(container); 
const root = createRoot(container);

if (container.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  // hydrate(Application, root)
  hydrateRoot(container, Application);
} else {
  // If we're not running on the server, just render like normal
  // render(Application, root)
  root.render(Application);
}

export {
  store,
  history
}
