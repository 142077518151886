import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************/project-type/:id
// const GLOBAL_SETTING_TEMPLATE  = '/global-setting-template'
const ADD_GLOBAL_SETTING_TEMPLATE = '/global-setting-template/add'
const ALL_GLOBAL_SETTING_TEMPLATES = '/global-setting-template/list'
const GLOBAL_SETTING_TEMPLATES_TYPES = '/global-setting-template/list-types'
const EDIT_GLOBAL_SETTING_TEMPLATE = '/global-setting-template/edit'
const DELETE_GLOBAL_SETTING_TEMPLATE = '/global-setting-template'
const EDIT_PROJECT_TYPE_SETTING = '/global-setting-template/edit/project-type'
const UPDATE_PROJECT_TYPE = '/project/project-type'

// // ************API call Functions starts here **************


export const addGlobalSettingTemplate = async (data) => {

    return await axios('post', `${ADD_GLOBAL_SETTING_TEMPLATE}`, data, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const getGlobalSettingTemplate = async (id) => {

    return await axios('get', `${ALL_GLOBAL_SETTING_TEMPLATES}/${id}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE, params: { id } }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}
export const getGlobalSettingTemplateTypes = async (type, projectId) => {

    return await axios('get', `${GLOBAL_SETTING_TEMPLATES_TYPES}/${type}`, {}, {
        loader: loaders.OVERLAY, server: microServices.DE_CORE, params: {
            ...(projectId ? { projectId } : {})
        }
    }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}
export const getAllGlobalSettingTemplate = async () => {
    return await axios('get', `${ALL_GLOBAL_SETTING_TEMPLATES}`, {}, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}
export const editGlobalSettingTemplate = async (data, id) => {
    return await axios('put', `${EDIT_GLOBAL_SETTING_TEMPLATE}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Global Setting Template Updated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const deleteGlobalSettingTemplate = async (id) => {
    return await axios('delete', `${DELETE_GLOBAL_SETTING_TEMPLATE}/${id}`, {}, { loader: loaders.OVERLAY, successMessage: 'Global Setting Deleted Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const editProjectTypeSetting = async (data, id) => {
    return await axios('put', `${EDIT_PROJECT_TYPE_SETTING}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Global Settings Updated Successfully', server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}

export const UpdateProjectType = async (data, id) => {
    return await axios('put', `${UPDATE_PROJECT_TYPE}/${id}`, data, { loader: loaders.OVERLAY, server: microServices.DE_CORE }).then(data => {
        return data
    },
        error => { // request level error
            return new Promise((resolve, reject) => {
                reject(error)
            })
        })
}
