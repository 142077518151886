import React from 'react'
import Form from 'react-bootstrap/Form'
import _ from 'lodash'

export default function SelectInput({ label, placeholder, validation = [], required, className = '', ...props }) {
  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));
  return (
    <div className="form-group w-100">
      <div className={_.uniq(["input-block", ...className.split(' ')]).join(' ')}>
        {(label || placeholder) && <label className="input-label">
          <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
        </label>}
        <Form.Select
          aria-label="Default select example"
          className="form-control"
          value={props.value || ''}
          disabled={props.disabled || false}
          onChange={props.onChange}
          name={props.name}
        >
          <option className='my-2' value={''} disabled>{placeholder || label}</option>
          {(props.items || []).map((option) => {
            return <option value={option.value} key={option.key}>{option.key}</option>
          })}
        </Form.Select>
        {props.error && props.touched && <small className="float-start text-danger">
          {props.error}
        </small>}
      </div>
    </div>
  )
}
