const { default: idx } = require("idx");
const { subTaskCalculation } = require('./calculate-sub-task')
const { isString } = require("lodash");

export const stageCalculation = ({ project = {}, stages = [], isForm = false }) => {

    let total = {
        stage: {},
        task: {},
        subtask: {},
        material: { cost: 0, sale: 0, profit: 0 },
        subcontractor: { cost: 0, sale: 0, profit: 0 },
        labor: { cost: 0, sale: 0, profit: 0 },
        total_profit: 0,
        forecast_profit: 0,
        revised_cost: 0,
        subtotal: 0,
        forecast: 0,
        total: 0
    };

    try {

        let _stages = project.stages || stages || [];
        for (const stage of _stages) {

            let stage_total = {
                material: { cost: 0, sale: 0, profit: 0 },
                subcontractor: { cost: 0, sale: 0, profit: 0 },
                labor: { cost: 0, sale: 0, profit: 0 },
                total_profit: 0,
                subtotal: 0,
                forecast: 0,
                total: 0
            };

            let tasks = stage.tasks || [];
            for (const task of tasks) {

                let task_total = {
                    material: { cost: 0, sale: 0, profit: 0 },
                    subcontractor: { cost: 0, sale: 0, profit: 0 },
                    labor: { cost: 0, sale: 0, profit: 0 },
                    total_profit: 0,
                    subtotal: 0,
                    forecast: 0,
                    total: 0
                };

                let subTasks = task.subTasks || [];
                // let is_override_profit = idx(task, _ => _.overrideProfitMargin) || false

                for (const subTask of subTasks) {

                    let subtask_total = subTaskCalculation({
                        subtask: subTask,
                        profitMargin: idx(task, _ => _.profitMargin),
                        profitMarginType: idx(task, _ => _.profitMarginType),
                        isForm
                    })

                    task_total.material.cost += subtask_total.material.cost;
                    task_total.material.sale += subtask_total.material.sale;
                    task_total.material.profit += subtask_total.material.profit;

                    task_total.subcontractor.cost += subtask_total.subcontractor.cost;
                    task_total.subcontractor.sale += subtask_total.subcontractor.sale;
                    task_total.subcontractor.profit += subtask_total.subcontractor.profit;

                    task_total.labor.cost += subtask_total.labor.cost;
                    task_total.labor.sale += subtask_total.labor.sale;
                    task_total.labor.profit += subtask_total.labor.profit;

                    task_total.total_profit += subtask_total.total_profit;
                    task_total.subtotal += subtask_total.subtotal;
                    task_total.forecast += subtask_total.forecast
                    task_total.total += subtask_total.total;

                    total.subtask[subTask.id] = subtask_total;
                }

                let materials = task.material || [];

                materials = materials.map((material) => {
                    if (!material) return undefined;
                    let _material = isString(material) ? JSON.parse(material) : material;

                    if (_material["saleAmountLock"] === true) {

                        if (idx(task, _ => _.profitMarginType) === "PERCENTAGE") {
                            _material["saleAmount"] = Number(_material["cost"]) * ((parseFloat(idx(task, _ => _.profitMargin) || 0) / 100) + 1);
                        } else if (idx(task, _ => _.profitMarginType) === "FIXED") {
                            _material["saleAmount"] = Number(_material["cost"]) + parseFloat(idx(task, _ => _.profitMargin) || 0);
                        }
                    }

                    task_total.material.cost +=
                        Number(_material["cost"]) * Number(_material["qty"]);
                    task_total.material.sale +=
                        Number(_material["saleAmount"]) * Number(_material["qty"]);
                    task_total.subtotal += Number(_material["cost"]) * Number(_material["qty"]);

                    return JSON.stringify(_material);
                }).filter(item => item);

                stage_total.material.cost += task_total.material.cost;
                stage_total.material.sale += task_total.material.sale;
                stage_total.material.profit += task_total.material.profit;

                stage_total.subcontractor.cost += task_total.subcontractor.cost;
                stage_total.subcontractor.sale += task_total.subcontractor.sale;
                stage_total.subcontractor.profit += task_total.subcontractor.profit;

                stage_total.labor.cost += task_total.labor.cost;
                stage_total.labor.sale += task_total.labor.sale;
                stage_total.labor.profit += task_total.labor.profit;

                stage_total.total_profit += task_total.total_profit;
                stage_total.subtotal += task_total.subtotal;
                stage_total.forecast += task_total.forecast
                stage_total.total += task_total.total;

                total.forecast_profit = parseFloat(task.materialForecastCost || 0) + parseFloat(task.subContractorForecastCost || 0) + parseFloat(task.laborForecastCost || 0);
                total.revised_cost += task_total.material.sale + task_total.subcontractor.sale + task_total.labor.sale;

                total.task[task.id] = task_total;
            }

            total.material.cost += stage_total.material.cost;
            total.material.sale += stage_total.material.sale;
            total.material.profit += stage_total.material.profit;

            total.subcontractor.cost += stage_total.subcontractor.cost;
            total.subcontractor.sale += stage_total.subcontractor.sale;
            total.subcontractor.profit += stage_total.subcontractor.profit;

            total.labor.cost += stage_total.labor.cost;
            total.labor.sale += stage_total.labor.sale;
            total.labor.profit += stage_total.labor.profit;

            total.total_profit += stage_total.total_profit
            total.subtotal += stage_total.subtotal;
            total.forecast += stage_total.forecast
            total.total += stage_total.total;

            total.stage[stage.id] = stage_total;
        }

        return total;
    } catch (e) {
        console.error(e)
        return total;
    }
};


export const optionCalculation = ({ options = [], isForm = false }) => {

    let total = {
        option: {},
        subtask: {},
        material: { cost: 0, sale: 0, profit: 0 },
        subcontractor: { cost: 0, sale: 0, profit: 0 },
        labor: { cost: 0, sale: 0, profit: 0 },
        total_profit: 0,
        forecast_profit: 0,
        revised_cost: 0,
        subtotal: 0,
        total: 0
    };

    try {

        for (const task of options) {

            let task_total = {
                material: { cost: 0, sale: 0, profit: 0 },
                subcontractor: { cost: 0, sale: 0, profit: 0 },
                labor: { cost: 0, sale: 0, profit: 0 },
                total_profit: 0,
                subtotal: 0,
                total: 0
            };

            let subTasks = task.subTasks || [];
            // let is_override_profit = idx(task, _ => _.overrideProfitMargin) || false

            for (const subTask of subTasks) {

                let subtask_total = subTaskCalculation({
                    subtask: subTask,
                    profitMargin: idx(task, _ => _.profitMargin),
                    profitMarginType: idx(task, _ => _.profitMarginType),
                    isForm
                })

                task_total.material.cost += subtask_total.material.cost;
                task_total.material.sale += subtask_total.material.sale;
                task_total.material.profit += subtask_total.material.profit;

                task_total.subcontractor.cost += subtask_total.subcontractor.cost;
                task_total.subcontractor.sale += subtask_total.subcontractor.sale;
                task_total.subcontractor.profit += subtask_total.subcontractor.profit;

                task_total.labor.cost += subtask_total.labor.cost;
                task_total.labor.sale += subtask_total.labor.sale;
                task_total.labor.profit += subtask_total.labor.profit;

                task_total.total_profit += subtask_total.total_profit;
                task_total.subtotal += subtask_total.subtotal;
                task_total.total += subtask_total.total;

                total.subtask[subTask.id] = subtask_total;
            }

            let materials = task.material || [];

            materials = materials.map((material) => {
                if (!material) return undefined;
                let _material = isString(material) ? JSON.parse(material) : material;

                if (_material["saleAmountLock"] === true) {

                    if (idx(task, _ => _.profitMarginType) === "PERCENTAGE") {
                        _material["saleAmount"] = Number(_material["cost"]) * ((parseFloat(idx(task, _ => _.profitMargin) || 0) / 100) + 1);
                    } else if (idx(task, _ => _.profitMarginType) === "FIXED") {
                        _material["saleAmount"] = Number(_material["cost"]) + parseFloat(idx(task, _ => _.profitMargin) || 0);
                    }
                }

                task_total.material.cost +=
                    Number(_material["cost"]) * Number(_material["qty"]);
                task_total.material.sale +=
                    Number(_material["saleAmount"]) * Number(_material["qty"]);
                task_total.subtotal += Number(_material["cost"]) * Number(_material["qty"]);

                return JSON.stringify(_material);
            }).filter(item => item);


            total.material.cost += task_total.material.cost;
            total.material.sale += task_total.material.sale;
            total.material.profit += task_total.material.profit;

            total.subcontractor.cost += task_total.subcontractor.cost;
            total.subcontractor.sale += task_total.subcontractor.sale;
            total.subcontractor.profit += task_total.subcontractor.profit;

            total.labor.cost += task_total.labor.cost;
            total.labor.sale += task_total.labor.sale;
            total.labor.profit += task_total.labor.profit;

            total.total_profit += task_total.total_profit
            total.subtotal += task_total.subtotal;
            total.total += task_total.total;

            total.forecast_profit = parseFloat(task.materialForecastCost || 0) + parseFloat(task.subContractorForecastCost || 0) + parseFloat(task.laborForecastCost || 0);
            total.revised_cost += task_total.material.sale + task_total.subcontractor.sale + task_total.labor.sale;

            total.option[task.id] = task_total;
        }

        return total;
    } catch (e) {
        console.error(e)
        return total;
    }
};


export const findTotal = (item = {}) => {
    return parseFloat(item.materialTotal_SaleAmount || 0) + parseFloat(item.laborTotal_SaleAmount || 0) + parseFloat(item.subcontractorTotal_SaleAmount || 0);
}

// Calculation for history values
// totalHistoryCost.materialCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) => _task.taskHistory[0].materialTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.laborCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) => _task.taskHistory[0].laborTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.profitMarginCost +=
//     parseFloat(
//         idx(task, (_task) => _task.taskHistory[0].profitMarginCost)
//     ) || 0;
// totalHistoryCost.subContractorCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) =>
//                 _task.taskHistory[0].subcontractorTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.total =
//     totalHistoryCost.materialCost +
//     totalHistoryCost.laborCost +
//     totalHistoryCost.subContractorCost;