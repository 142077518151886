import { createSlice } from '@reduxjs/toolkit'

// Slice
const initial = {
    list: []
}

const slice = createSlice({
    name: 'subscriptions',
    initialState: {
        ...initial,
    },
    reducers: {
        addSubscriptions: (state, action) => {
            let { subscriptions = [] } = action.payload;
            state.list = subscriptions;
        }
    },
});

export default slice.reducer

// Actions
const { addSubscriptions } = slice.actions

export const addSubscriptionsAction = (data) => async dispatch => {
    try {
        dispatch(addSubscriptions({ subscriptions: data }));
    } catch (e) {
        return console.error(e);
    }
}