import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { subcontractorObjFields } from "src/constants/fields.constant";
import { updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import CustomInput from "src/components/inputs/new-input";
import SelectInput from "src/components/inputs/select";

const filter = createFilterOptions();

export default function Subcontractor({ subcontractor = [], total = {}, subTaskArrayIndex, profitMargin, profitMarginType, subcontractorOptions = [], isDisabled, ...props }) {

    const dispatch = useDispatch()
    const [subcontractorState, setSubcontractorState] = useState({
        subcontractorObj: subcontractorObjFields,
        confirmLock: false,
        lockArrayValue: null,
    })
    const [expandAddSubcontractor, setExpandAddSubcontractor] = useState(false);

    const filterSubcontractor = (id) => {
        return subcontractorOptions.find((_) => _.value === id) || {};
    };
    const updateSubcontractor = (e, index) => {
        const { subTaskArray, } = props;
        let _temp = _.cloneDeep(subTaskArray);

        const selectedSubcontractor = filterSubcontractor(e.value);

        const _subcontractor = JSON.parse(JSON.stringify(subcontractorState.subcontractorObj));

        _temp[subTaskArrayIndex]["subcontractor"][index]["name"].value = selectedSubcontractor.key;
        _temp[subTaskArrayIndex]["subcontractor"][index]["id"].value = selectedSubcontractor.value;
        _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value = _subcontractor.Cost.value;
        _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value = _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value;
        _temp[subTaskArrayIndex]["subcontractor"][index]["description"].value = _subcontractor.description.value;
        _temp[subTaskArrayIndex]["subcontractor"][index]["total"].value = parseInt(_subcontractor.Cost.value) || 0;
        dispatch(updateSubTaskAction(_temp))
    };

    const subcontractorChangeHandler = (e, index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray);

        const value = e.target.value.replace("-", "");
        if (_temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].name === "saleAmount") {
            _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value = e.target.value;
            _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmountLock"].value = false;
        } else if (_temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].name === "Cost") {
            _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value = e.target.value;

            _temp[subTaskArrayIndex]["subcontractor"][index]["saleAmount"].value = _temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value
        } else {
            _temp[subTaskArrayIndex]["subcontractor"][index][e.target.name].value = value;
        }
        _temp[subTaskArrayIndex]["subcontractor"][index]["total"].value =
            parseFloat(_temp[subTaskArrayIndex]["subcontractor"][index]["Cost"].value) || 0;

        dispatch(updateSubTaskAction(_temp))
    };

    const removeSubcontractor = (index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        const subcontractor = _temp[subTaskArrayIndex]["subcontractor"][index];

        subcontractor.Cost.value = "0";
        _temp[subTaskArrayIndex]["subcontractor"].splice(index, 1);
        dispatch(updateSubTaskAction(_temp))
    };

    const addSubcontractor = (e, itemData = null) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)

        const subcontractor = JSON.parse(JSON.stringify(subcontractorState.subcontractorObj));
        subcontractor.name.value = e.key;
        subcontractor.id.value = e.value;
        _temp[subTaskArrayIndex]["subcontractor"].push(subcontractor);
        dispatch(updateSubTaskAction(_temp))
        setSubcontractorState(prev => ({
            ...prev,
            subcontractorObj: subcontractorObjFields,
        }));
    };


    return (
        <>
            <h2>Contractor List</h2>
            <div className="table-responsive">
                <Table bordered className="table-create table-material tbl-fixed">
                    <thead>
                        <tr>
                            <th className="autocomplete-width">Contractor(s)</th>
                            <th>Description</th>
                            <th>Cost</th>
                            <th>Sale Amount($)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subcontractor.map((_subcontractor, subcontractorIndex) => {
                            if (!_subcontractor) return;
                            return (
                                <tr key={subcontractorIndex}>
                                    <td>
                                        <SelectInput
                                            className="input-block-sm"
                                            label={'Select Contractor'}
                                            items={subcontractorOptions}
                                            value={idx(_subcontractor, _ => _.id.value) || ''}
                                            onChange={(e) => updateSubcontractor({ value: e.target.value }, subcontractorIndex)}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Description"
                                            {..._subcontractor.description}
                                            onChange={(e) => subcontractorChangeHandler(e, subcontractorIndex)}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Cost"
                                            {..._subcontractor.Cost}
                                            onChange={(e) => subcontractorChangeHandler(e, subcontractorIndex)}
                                            min={1}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={true}
                                            placeholder="Sale Amount"
                                            {..._subcontractor.saleAmount}
                                        />
                                    </td>
                                    <td>
                                        {!isDisabled && <DeleteIcon
                                            className="deleteicon"
                                            onClick={() => removeSubcontractor(subcontractorIndex)}
                                        />}
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td>
                                {!isDisabled && <span
                                    className="add-material"
                                    onClick={() => setExpandAddSubcontractor(!expandAddSubcontractor)}
                                    aria-controls="example-collapse-texts"
                                    aria-expanded={expandAddSubcontractor}
                                >
                                    <AddIcon /> Add Contractor
                                </span>}
                            </td>
                            <td></td>
                            <td className="text-right table-text-sm">Sub Total</td>
                            <td className="text-right table-text-sm">
                                ${parseFloat(idx(total, (_) => _.subcontractor.sale) || 0).toFixed(2)}
                            </td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
            </div>

            <Collapse in={expandAddSubcontractor}>
                <div id="example-collapse-texts">
                    <div className="row m-0">
                        <div className="col-lg-5 col-md-6 my-3">
                            <div className="form-group">
                                <div className="input-block autocomplete-block">
                                    <label className="input-label">Contractor</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === "string") {
                                                newValue = { inputValue: newValue, };
                                            } else if (newValue && newValue.inputValue) {
                                                //   console.info(newValue.inputValue);
                                            } else if (newValue) {
                                                addSubcontractor(newValue, null);
                                                setExpandAddSubcontractor(false)
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            return filter(options, params);
                                        }}
                                        options={subcontractorOptions}
                                        getOptionLabel={(option) => {
                                            // e.g value selected with enter, right from the input
                                            if (typeof option === "string") {
                                                return option;
                                            }
                                            return option.key;
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Contractor" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </>
    );

}
