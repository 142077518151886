import { createSlice } from '@reduxjs/toolkit'

// Slice
const initialProject = {
    project: {},
    labors: [],
    userNames: [],
    subcontractors: []
}

const slice = createSlice({
    name: 'project',
    initialState: {
        ...initialProject,
    },
    reducers: {
        addProject: (state, action) => {
            state.project = {
                ...state.project,
                ...action.payload
            }
        },
        updateProject: (state, action) => {
            state.project = {
                ...state.project,
                ...action.payload
            }
        },
        addLabor: (state, action) => {
            state.labors = action.payload
        },
        addUserNames: (state, action) => {
            state.userNames = action.payload
        },
        addSubcontractors: (state, action) => {
            state.subcontractors = action.payload
        },
        clearProject: (state) => {
            state.project = {};
            state.labors = [];
            state.userNames = [];
            state.subcontractors = [];
        }
    },
});

export default slice.reducer

// Actions
const { addProject, updateProject, addLabor, addUserNames, addSubcontractors, clearProject } = slice.actions

export const addProjectAction = (data) => async dispatch => {
    try {
        dispatch(addProject({ ...data }));
    } catch (e) {
        return console.error(e);
    }
}

export const updateProjectAction = (data) => async dispatch => {
    try {
        dispatch(updateProject({ ...data }));
    } catch (e) {
        return console.error(e);
    }
}

export const addLevelAction = (data) => async dispatch => {
    try {
        dispatch(addLabor(data));
    } catch (e) {
        return console.error(e);
    }
}

export const addUserNamesAction = (data) => async dispatch => {
    try {
        dispatch(addUserNames(data));
    } catch (e) {
        return console.error(e);
    }
}

export const addSubcontractorsAction = (data) => async dispatch => {
    try {
        dispatch(addSubcontractors(data));
    } catch (e) {
        return console.error(e);
    }
}

export const clearProjectAction = () => async dispatch => {
    try {
        dispatch(clearProject());
    } catch (e) {
        return console.error(e);
    }
}