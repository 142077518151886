import _ from "lodash";
import { subcontractorObjFields } from "src/constants/fields.constant";
import { calculateSaleAmount } from "src/util/calculations/sale-amount";

export const subcontractorToForm = (_subcontractor, { qty = 1, profitMargin = 0, profitMarginType = 'PERCENTAGE' }) => {
    if (!_.isObject(_subcontractor)) _subcontractor = JSON.parse(_subcontractor);

    const subcontractor = _.cloneDeep(subcontractorObjFields)
    subcontractor.name.value = _subcontractor.name;
    subcontractor.id.value = _subcontractor.id;
    subcontractor["Cost"].value = (Number(_subcontractor.Cost) * Number(qty)) || 0;
    subcontractor["saleAmount"].value = calculateSaleAmount({
        cost: subcontractor["Cost"].value,
        profitMargin,
        profitMarginType
    }).toFixed(2);
    subcontractor['saleAmountLock'].value = true
    subcontractor["description"].value = _subcontractor.description || '';
    subcontractor["total"].value = subcontractor["saleAmount"].value;

    return subcontractor
}