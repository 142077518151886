import {
  getAccountsList as getAccountsListApi,
  getAccount as getAccountApi,
  addAccount as addAccountApi,
  updateAccount as updateAccountApi,
  deleteAccount as deleteAccountApi,
  loginToAccount as loginToAccountAPI,
  loginToImpersonateAccount as loginToImpersonateAccountAPI
} from '../api/global-account.api'
import { getUserData as getUserDataAPI } from '../api/user-management.api'
import { saveUserData, useRefreshToken } from '../actions/user-management.action'
import { getProjectCategory } from '../actions/global-setting.action'
import { saveAccessToken, removeAccessToken, removeRefreshToken, saveRefreshToken, getAccessToken } from './../util/auth'
import idx from 'idx'
import { clearResourcesAction } from 'src/slice/resources'
import { clearDashboardAction } from 'src/slice/dashboard'
import { clearCustomersAction } from 'src/slice/customer'

export const getAccountsList = (params) => {
  return (dispatch, getState) => {
    return getAccountsListApi(params)
  }
}

export const getAccount = (id) => {
  return (dispatch, getState) => {
    return getAccountApi(id)
  }
}

export const addAccount = (params) => {
  return (dispatch, getState) => {
    return addAccountApi(params)
  }
}

export const updateAccount = (params, id) => {
  return (dispatch, getState) => {
    return updateAccountApi(params, id)
  }
}

export const deleteAccount = (params, id) => {
  return (dispatch, getState) => {
    return deleteAccountApi(params, id)
  }
}

export const loginToAccount = (data, name) => {
  return (dispatch, getState) => {
    loginToAccountAPI(data, name).then(async (response) => {
      await saveAccessToken(response.token)
      dispatch(getUserData())
      dispatch(clearResourcesAction())
      dispatch(clearCustomersAction())
      dispatch(clearDashboardAction())
    }).catch(e => {
      return e
    })
  }
}

export const loginToImpersonateAccount = (data, name) => {
  return (dispatch, getState) => {
    loginToImpersonateAccountAPI(data, name).then(async (response) => {
      saveRefreshToken(response.refreshToken)
      dispatch(clearResourcesAction())
      dispatch(clearCustomersAction())
      dispatch(clearDashboardAction())
      dispatch(useRefreshToken())
    }).catch(e => {
      return e
    })
  }
}

export const getUserData = () => {
  return (dispatch, getState) => {
    return getUserDataAPI().then(async (response) => {
      dispatch(saveUserData(idx(response, _response => _response)))
      await dispatch(getProjectCategory('PROJECTCATEGORIES'))
    }).catch((e) => {
      removeAccessToken()
      removeRefreshToken()
    })
  }
}
