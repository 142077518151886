import React, { Component } from 'react'
import { connect } from 'react-redux'
import Input from '../../components/inputs/input-simple'
import Button from '../../components/buttons/buttons'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getProjectList } from '../../actions/project-management.action'
import { getServicesList } from '../../actions/service.action'
import { bindActionCreators } from 'redux'
import { warningSnackBar } from '../../actions/common.action'
import _ from 'lodash'
import moment from 'moment'

export class Filter extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fields: {
        projectType: {
          type: 'select',
          items: [],
          value: 'all'
        },
        project: {
          type: 'select',
          items: [],
          value: 'all'
        },
        startDate: {
          type: 'datepicker',
          value: ''
        },
        endDate: {
          type: 'datepicker',
          value: ''
        }
      }
    }
  }

  async componentDidMount() {

    const { projectCategories } = this.props;
    let fields = _.cloneDeep(this.state.fields)
    let user = this.props.user
    let permissions = user && user.permissions;

    let endDate = null;
    let startDate = null;

    const { dashboardProject } = permissions;

    const projectTypeItems = []
    let value = ''
    if ((dashboardProject === 2) || (user.global_user)) {
      projectCategories.forEach(item => {
        if (permissions[`projectManagement${item.type}`] > 0 || (user.global_user && user.selected_account.id)) {
          projectTypeItems.push({
            key: item.name,
            value: item.type
          })
        }
      });
    }


    if (projectTypeItems.length > 1) {
      projectTypeItems.unshift({ key: 'All', value: 'all' })
      value = 'all'
    }

    fields.projectType.value = value
    fields.projectType.items = projectTypeItems

    if (this.props.dashboardFilter) {
      endDate = new Date()
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1)
    }

    if (this.props.timesheetFilter) {
      endDate = new Date()
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - (endDate.getDay() === 0 ? 6 : endDate.getDay() - 1))
    }

    endDate.setHours(23, 59, 59, 999)
    startDate.setHours(0, 0, 0, 0)

    fields.startDate.value = startDate
    fields.endDate.value = endDate

    this.setState({ fields })
    this.props.applyFilter(fields)
  }

  projectTypeChangeHandler = async (value) => {
    const { projectCategories } = this.props;
    // const { managerId } = this.props // this is added for managerId filter for listing projects/services
    let list
    if (projectCategories.find(o => o.type === value)) {
      list = await this.props.getProjectList(null, 'PROJECT', null, value, null, null)
    } else if (value === 'service') {
      list = await this.props.getServicesList(null, 'SERVICE', null, null)
    } else {
      list = []
    }

    const listItems = (Array.isArray(list) && list.map(({ id, name, description }) => ({
      key: name || description,
      value: id
    }))) || []

    listItems.unshift({ key: 'All', value: 'all' })

    const fields = _.cloneDeep(this.state.fields)
    fields.project.items = listItems
    fields.project.value = 'all'
    fields.projectType.value = value

    this.setState({
      fields,
      value
    })
  }

  projectChangeHandler = (value) => {

    const fields = _.cloneDeep(this.state.fields)
    fields.project.value = value

    this.setState({ fields })
  }

  dateChangeHandler = (value, type) => {

    const fields = _.cloneDeep(this.state.fields)
    let date = new Date(value)

    if (type === 'startDate') {
      date.setHours(0, 0, 0, 0)
      fields.startDate.value = value
    } else if (type === 'endDate') {
      date.setHours(23, 59, 59, 999)
      fields.endDate.value = value
    }

    this.setState({ fields })
  }

  applyFilter = () => {

    const fields = _.cloneDeep(this.state.fields)

    if (fields.startDate.value) {
      fields.startDate.value = moment(fields.startDate.value).utc().toString()
    }
    if (fields.endDate.value) {
      fields.endDate.value = moment(fields.endDate.value).utc().toString()
    }

    if (fields.startDate.value && fields.endDate.value && moment(fields.startDate.value).isAfter(fields.endDate.value)) {
      this.props.warningSnackBar('End date can not be before start date')
      return
    }
    this.props.applyFilter(this.state.fields)
  }

  applyPreviousWeak = () => {
    const fields = _.cloneDeep(this.state.fields)

    const date = fields.startDate.value || new Date()

    var day = date.getDay();
    var prevMonday = new Date(date);
    if (date.getDay() === 0) {
      prevMonday.setDate(date.getDate() - 6);
    } else if (date.getDay() === 1) {
      prevMonday.setDate(date.getDate() - 7);
    } else {
      prevMonday.setDate(date.getDate() - (day - 1) - 7);
    }

    const startDate = new Date(prevMonday)
    const endDate = new Date(prevMonday.setDate(prevMonday.getDate() + 6))
    endDate.setHours(23, 59, 59, 999)
    startDate.setHours(0, 0, 0, 0)
    fields.startDate.value = startDate
    fields.endDate.value = endDate

    this.setState({ fields }, () => this.applyFilter())
  }

  render() {
    const { fields } = this.state
    const { showReportButton, timesheetFilter } = this.props
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <b>Apply Filter</b>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Grid container style={{ textAlign: "left" }} className="subtaskrow-1">
            <Grid md={6} xs={12} item>
              <Grid container>
                <Grid md={4} sm={3} xs={12} item> <p>Project Type </p></Grid>
                <Grid md={6} sm={9} xs={12} item>
                  <Input
                    {...fields.projectType}
                    onChange={(e) => this.projectTypeChangeHandler(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} xs={12} item>
              <Grid container>
                <Grid md={4} sm={3} xs={12} item> <p>Select Project</p></Grid>
                <Grid md={6} sm={9} xs={12} item>
                  <Input
                    {...fields.project}
                    onChange={(e) => this.projectChangeHandler(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} xs={12} item>
              <Grid container>
                <Grid md={4} sm={3} xs={12} item> <p>Start Date</p></Grid>
                <Grid md={6} sm={9} xs={12} item>
                  <Input
                    {...fields.startDate}
                    onChange={(value) => this.dateChangeHandler(value, 'startDate')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} xs={12} item>
              <Grid container>
                <Grid md={4} sm={3} xs={12} item> <p>End Date</p></Grid>
                <Grid md={6} sm={9} xs={12} item>
                  <Input
                    {...fields.endDate}
                    onChange={(value) => this.dateChangeHandler(value, 'endDate')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-end" }} className="timesheetFilters">
            {showReportButton && <Button className="btnPrimary" title={'Generate Payroll Report'} onClick={this.props.generateReport} />}
            <Button className="btnPrimary" title={'Apply Filter'} onClick={() => this.applyFilter(fields)} />
            {timesheetFilter && <Button className="btnPrimary" title={'Previous Week'} onClick={this.applyPreviousWeak} />}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  projectCategories: state.project.projectCategories,
})

const mapDispatchToProps = dispatch => ({
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
