import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_INVENTORY_LIST = '/inventory/items'
const GET_ITEMS = '/inventory/items'
const UPDATE_STOCK = '/inventory/items/stock'
const SAVE_ITEM = '/inventory/item'
const GET_CATEGORIES = '/inventory/categories'
const SAVE_CATEGORY = '/inventory/category'
const GET_VENDOR_CATEGORIES='/inventory/vendorcategory'
const ADD_LABOR_SKILL = 'inventory/labor'
const DEL_LABOR_SKILL = 'inventory/labor'
const GET_LABORS = 'inventory/labors'
const SAVE_LEVEL = 'level/'
const GET_LEVELS_LIST = 'level/list'
const UNITS = '/unit'
const GET_TRANSACTIONS_LIST = '/inventory/transactions'
const ADD_NO_PO_STOCK = '/inventory/add-no-po-stock'
// // ************API call Functions starts here **************

export const getInventoryList = async () => {
  return await axios('get', GET_INVENTORY_LIST, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteCategory = async (itemId) => {
  return await axios('delete', `${SAVE_CATEGORY}/${itemId}`, {}, { successMessage: 'Category Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteLevel = async (itemId) => {
  return await axios('delete', `${SAVE_LEVEL}/${itemId}`, {}, { successMessage: 'Level Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getLevels = async () => {
  return await axios('get', GET_LEVELS_LIST, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getLevel = async (id, allLabors) => {
  return await axios('get', `${SAVE_LEVEL}`, {}, {
    server: microServices.DE_CORE,
    params: {
      ...(id ? { id } : {}),
      ...(allLabors ? { allLabors: true } : {})
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getItems = async (id) => {
  return await axios('get', `${GET_ITEMS}?categoryId=${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getCategories = async (categoryFilter = []) => {
  return await axios('get', GET_CATEGORIES, {}, {
    server: microServices.DE_CORE, loader: loaders.OVERLAY, params: {
      ...(categoryFilter ? { categoryFilter } : {})
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}


export const getVendorCategories = async (id) => {
  return await axios('get', `${GET_VENDOR_CATEGORIES}/${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addItem = async (data) => {
  return await axios('post', SAVE_ITEM, data, { successMessage: 'Item Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editItem = async (data, itemId) => {
  return await axios('put', `${SAVE_ITEM}/${itemId}`, data, { loader: loaders.OVERLAY, successMessage: 'Item Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateLevel = async (data, itemId) => {
  return await axios('put', `${SAVE_LEVEL}/${itemId}`, data, { loader: loaders.OVERLAY, successMessage: 'Level Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateCategory = async (data, itemId) => {
  return await axios('put', `${SAVE_CATEGORY}/${itemId}`, data, { successMessage: 'Category Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getItem = async (itemId) => {
  return await axios('get', `${SAVE_ITEM}/${itemId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteItem = async (itemId) => {
  return await axios('delete', `${SAVE_ITEM}/${itemId}`, {}, { successMessage: 'Item Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addCategory = async (data) => {
  return await axios('post', SAVE_CATEGORY, data, { successMessage: 'Category Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addLevel = async (data) => {
  return await axios('post', SAVE_LEVEL, data, { successMessage: 'Level Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getLaborList = async () => {
  return await axios('get', GET_LABORS, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addLabor = async (data) => {
  return await axios('post', ADD_LABOR_SKILL, data, { successMessage: 'Skill Added Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editLabor = async (data, itemId) => {
  return await axios('put', `${ADD_LABOR_SKILL}/${itemId}`, data, { loader: loaders.OVERLAY, successMessage: 'Skill Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getLabor = async (itemId) => {
  return await axios('get', `${ADD_LABOR_SKILL}/${itemId}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteLabor = async (itemId) => {
  return await axios('delete', `${DEL_LABOR_SKILL}/${itemId}`, {}, { successMessage: 'Skill Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getLabors = async (id) => {
  return await axios('get', GET_LABORS, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const saveUnits = async (data) => {
  return await axios('post', UNITS, data, { successMessage: 'Units Saved Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getUnits = async () => {
  return await axios('get', UNITS, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateItemStock = async (data) => {
  return await axios('put', UPDATE_STOCK, data, { loader: loaders.OVERLAY, successMessage: 'DE Stock Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getTransactionsList = async (location, poId, itemId) => {
  return await axios('get', `${GET_TRANSACTIONS_LIST}`,
    {}, {
    server: microServices.DE_CORE,
    params: {
      ...(location ? { location } : {}),
      ...(poId ? { poId } : {}),
      ...(itemId ? { itemId } : {}),
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addNoPoStock = async (data) => {
  return await axios('put', ADD_NO_PO_STOCK, data,
    {
      loader: loaders.OVERLAY,
      successMessage: ' Stock Updated Successfully',
      server: microServices.DE_CORE
    }).then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}
