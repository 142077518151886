import { getResourceAllocations } from "../../../actions/timesheet.action";
import moment from "moment";
import { updateProjectAction } from "src/slice/project-detail";

export const getWorkAllocationsData = async ({ projectId, dispatch }) => {

    let timeSpentProgress = 0;
    let params = { projectId, updateTaskAllocation: true, };
    const resourceAllocations = await dispatch(getResourceAllocations(params));
    resourceAllocations.forEach((item) => {
        timeSpentProgress += moment(item.endTime).diff(moment(item.startTime));
    });
    timeSpentProgress = Math.floor((timeSpentProgress / 1000 / 60 / 60) % 24);

    dispatch(updateProjectAction({ timeSpentProgress }))

};