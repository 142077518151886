import React, { useState } from 'react'
import Modal from '../../components/modal/modal'
import { Grid } from '@material-ui/core'
import Input from '../../components/inputs/input-simple'
import { format } from 'date-fns'

export default function Filter(props) {
  const { changeFilter, workType, dialogClose, open } = props

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  React.useEffect(() => {
    changeFilter(startDate, endDate)
  }, [startDate, endDate, workType, changeFilter])
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='md'
      modalHeading='Select Filter'
      modalSaveTxt='Apply Filter'
      modalCloseTxt='Cancel'
      onClick={dialogClose}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <Grid container>
        <Grid xs={6} item className="subtaskrow-1" style={{ margin: "10px 0" }}>
          <Grid container>
            <Grid xs={3} item style={{ display: "flex", alignItems: "center" }}><p style={{ margin: "0px" }}>Start Date: </p></Grid>
            <Grid xs={6} item>
              <Input
                name={'startDate'}
                label={'Start Date'}
                type={'datepicker'}
                value={startDate}
                clearable={true}
                format={'dd/MM/yyyy'}
                maxDate={endDate || null}
                maxDateMessage={'Start Date should be before End Date!'}
                onChange={(e) => setStartDate((e && format(new Date(e), 'MM/dd/yyyy')) || null)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item className="subtaskrow-1" style={{ margin: "10px 0" }}>
          <Grid container>
            <Grid xs={3} item style={{ display: "flex", alignItems: "center" }}><p style={{ margin: "0px" }}>End Date: </p></Grid>
            <Grid xs={6} item>
              <Input
                name={'endDate'}
                label={'End Date'}
                type={'datepicker'}
                value={endDate}
                clearable={true}
                format={'dd/MM/yyyy'}
                minDate={startDate || null}
                minDateMessage={'End Date should be after Start Date!'}
                onChange={(e) => setEndDate((e && format(new Date(e), 'MM/dd/yyyy')) || null)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
