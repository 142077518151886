export const noDataPlugin = {
    afterDraw: chart => {
        if (chart.data.datasets[0].data.length === 0) {
            var ctx = chart?.ctx;
            if (ctx) {
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = "22px Arial";
                ctx.fillStyle = "gray";
                ctx.fillText('No data available', chart.width / 2, chart.height / 2);
                ctx.restore();
            }
        }
    }
}
