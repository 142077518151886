import React from "react";
import "./invoices.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Buttons from "./../../../../components/buttons/buttons";
import { PROJECT_MANAGEMENT } from "./../../../../constants/route-paths";
import ViewInvoice from "./view-invoice";
import { getGlobalSettingTemplateTypes } from "./../../../../actions/global-setting-template.action";
import {
  getInvoiceList,
  getProgressClaimList,
  getInvoiceAndProgressClaimList,
  downloadProjectInvoice,
  downloadProjectProgressBreakup,
  deleteProgressClaim,
  getProjectCustomer,
} from "./../../../../actions/invoice.action";
import { format } from "date-fns/esm";
import { numberFormat } from "../../../../util/currency-formatter";
import DownloadDialog from "../../../../components/download-dialog";
import DeleteDialog from "../../../../components/delete-dialog";
import Modal from "../../../../components/modal/modal";
import {
  addProgressClaimRetention,
  editProgressClaimRetention,
  getProject,
} from "../../../../actions/project-management.action";
import idx from "idx";
import { withRouter } from "../../../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Popover from 'react-bootstrap/Popover';
import '../../../project-management/scss/task.scss'
import CustomTable from "src/components/table/table";
import { AmountInput } from "src/components/inputs/new-input";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      columns: [],
      filter: "",
      invoiceData: null,
      progressClaims: [],
      invoices: [],
      projectId: null,
      invoiceDialogState: false,
      downloadData: {
        open: false,
        id: "",
        template: "",
      },
      deleteDialogState: false,
      deleteId: null,
      deleteType: "",
      anchorEl: null,
      customerPaymentTerms: "",
      invoiceAndProgressClaim: [],
      progressClaimId: "",
      addRetentionDialog: false,
      retentionField: {
        name: "retentionInput",
        label: "",
        type: "number",
        touched: true,
        error: false,
        valid: true,
        value: "",
      },
      projectQuoteType: "",
      totalRetention: "",
      templateOptions: [],
      templateValue: "",
      invoiceTotalAmount: "",
      editRetention: false,
      projectData: null
    };


  }

  componentDidMount = () => {
    const projectId = this.props.params.projectId;

    this.setState({ projectId }, async () => {
      await this.getData();
    });
    if (!this.state.projectData && projectId) {
      this.props.getProject(projectId).then((projectData) => {
        this.setState({
          projectData: projectData
        });
      })
    }
  };

  handleTemplateChange = (e) => {
    this.setState({ templateValue: e.target.value });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenu = (tableData) => {
    // this.setState({ tableData });
  };

  getData = async () => {
    const { projectId } = this.state;
    let projectQuoteType = this.state;
    await this.props
      .getInvoiceAndProgressClaimList(`?projectId=${projectId}&type=list`)
      .then((res) => {
        let columns = [
          {
            title: "Invoice Number",
            field: "claimNumber",
            render: (rowData) => <span
              className="link"
              onClick={() => {
                if (rowData.progressClaimId) {
                  this.invoiceDialogHandler(rowData)
                } else {
                  this.handleGeneratePC(rowData.u_id)
                }
              }}
            >
              {rowData.claimNumber}
            </span>,
          },
          {
            title: "Invoice Status",
            field: "progressClaimStatus",
            render: (rowData) =>
              rowData.progressClaimStatus ? rowData.progressClaimStatus : "DRAFT",
          },
          {
            title: "Invoice No.",
            field: "id",
            render: (rowData) => (rowData.progressClaimId ? rowData.id : ""),
          },
          {
            title: "Claim Amount",
            field: "claimAmount",
            render: (rowData) =>
              rowData.progressClaimId ? `${numberFormat(rowData.claimAmount)}` : `${numberFormat(rowData.claimAmount)}`,
          },
          {
            title: "Total Amount",
            field: "totalValue",
            render: (rowData) =>
              rowData.progressClaimId ? `${numberFormat(rowData.totalValue)}` : `${numberFormat(rowData.totalValue)}`,
          },
          {
            title: "Review/Retention Amount",
            field: "retentionAmount",
            render: (rowData) =>
              numberFormat(
                projectQuoteType == "noquote" ? 0 : rowData.progressClaimId ? rowData.retentionAmount || 0 : rowData.retentionAmount || 0
              ),
          },
          {
            title: "Issue Date",
            field: "invoiceDate",
            render: (rowData) =>
              rowData.progressClaimId && (rowData.invoiceDate ? format(new Date(rowData.invoiceDate), "dd/MM/yyyy") : ""),
          },
          {
            title: "Due Date",
            field: "dueDate",
            render: (rowData) =>
              rowData.progressClaimId && (rowData.dueDate ? format(new Date(rowData.dueDate), "dd/MM/yyyy") : ""),
          },
        ];

        const invoiceAndProgressClaim = JSON.parse(JSON.stringify(res));
        let totalRetention = 0;
        let tableData = invoiceAndProgressClaim.filter((item) => {
          projectQuoteType = item.project.quote_type;
          if (item.progressClaimId) {
            totalRetention += item.retentionAmount || 0;
          } else {
            totalRetention += item.retentionAmount || 0;
          }
          return item;
        });

        this.setState({
          tableData: tableData,
          columns,
          invoiceAndProgressClaim,
          totalRetention,
          projectQuoteType,
        });
      });
  };

  invoiceDialogHandler = async (data, type) => {
    const { invoiceDialogState, projectId, invoiceAndProgressClaim } =
      this.state;
    let res = {},
      claimsRetainedTotal = 0,
      claimsRetentionClaimTotal = 0;
    if (!invoiceDialogState && type === "PROGRESS_CLAIM") {
      res = await this.props.getProjectCustomer(projectId);
      Array.isArray(invoiceAndProgressClaim) &&
        invoiceAndProgressClaim.filter((claim) => {
          const progressClaimId = claim.progressClaimId
            ? claim.progressClaimId
            : claim.id;
          if (progressClaimId !== data.id) {
            claimsRetainedTotal += claim.progressClaimId
              ? claim.retentionAmount
              : claim.retentionAmount;
            claimsRetentionClaimTotal += claim.progressClaimId
              ? claim.retentionClaimAmount
              : claim.retentionClaimAmount;
          }
        });
    }
    this.setState((prevState) => ({
      invoiceData: data,
      filter:
        data &&
        (data.isInvoiceGenerated == true
          ? "INVOICES"
          : type || "PROGRESSCLAIM"),
      invoiceDialogState: !prevState.invoiceDialogState,
      customerPaymentTerms: !prevState.invoiceDialogState
        ? res?.customer?.paymentTerms
        : "",
      claimsRetainedTotal,
      claimsRetentionClaimTotal,
    }));
    this.handleClose();
  };

  cancelHandler = () => {
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${this.props.params.projectId}`);
  };

  gettemplate = async (type) => {
    let templateOptions = this.state;
    let newdata = [];
    let projectId = this.props.params.projectId;
    const templateData = await this.props.getGlobalSettingTemplateTypes(
      type,
      projectId
    );
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    this.setState({ templateOptions });
  };
  handleDownloadDialog = (id = "", template = "", state) => {
    const { downloadData = {} } = this.state;
    if (id) {
      downloadData.open = !downloadData.open;
      downloadData.id = id;
      downloadData.template = template;
      if (downloadData.template) {
        this.gettemplate(downloadData.template);
      }
      this.setState({ downloadData });
      if (state === "PROGRESS_CLAIM") {
        this.getData();
      }
    }
  };
  downloadFile = async (fileFormat = "pdf", tempId) => {
    const { downloadData = {} } = this.state;

    if (downloadData.template === "PROGRESSCLAIM") {
      if (fileFormat === "pdf" || fileFormat === "ALL") {
        await this.props.downloadProjectProgressBreakup(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        await this.props.downloadProjectProgressBreakup(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
      }
    } else if (downloadData.template === "INVOICE") {
      if (fileFormat === "pdf" || fileFormat === "ALL") {
        await this.props.downloadProjectInvoice(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        await this.props.downloadProjectInvoice(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
      }
    }
    this.handleDownloadDialog();
    this.handleClose();
  };

  changeFilter = (type) => {
    const writePermission = this.props.wFinancePermission;

    let columns;
    let tableData;
    if (type === "INVOICES") {
      columns = [
        { title: "Invoice No.", field: "id", render: (rowData) => rowData?.id || "", },
        {
          title: "Progress Claim No.",
          field: "id",
          render: (rowData) => (rowData ? rowData.id : ""),
        },
        {
          title: "Amount",
          field: "claimAmount",
          render: (rowData) => rowData ? `${numberFormat(rowData.claimAmount)}` : 0,
        },
        {
          title: "Issue Date",
          field: "invoiceDate",
          render: (rowData) => rowData.invoiceDate ? format(new Date(rowData.invoiceDate), "dd/MM/yyyy") : "",
        },
        {
          title: "Due Date",
          field: "dueDate",
          render: (rowData) => rowData.dueDate ? format(new Date(rowData.dueDate), "dd/MM/yyyy") : "",
        },
        {
          title: "Action",
          field: "id",
          render: (rowData) => (
            <p
              className="cancel-btn"
              title="View"
              onClick={() => this.invoiceDialogHandler(rowData)}
            >
              View
            </p>
          ),
        },
      ];
      tableData = JSON.parse(JSON.stringify(this.state.invoices));
    } else if (type === "PROGRESS_CLAIM") {
      columns = [
        { title: "Progress Claim No.", field: "id", render: (rowData) => rowData.id },
        { title: "Progress Description", field: "description", render: (rowData) => rowData.description },
        {
          title: "Creation Date",
          field: "createdAt",
          render: (rowData) => format(new Date(rowData.createdAt), "dd/MM/yyyy"),
        },
        {
          title: "Total Budget",
          field: "claimAmount",
          render: (rowData) => `${rowData.claimAmount ? numberFormat(rowData.claimAmount) : 0}`,
        },
        {
          title: "Action",
          field: "id",
          render: (rowData) => (
            <>
              {writePermission && (
                <p
                  className="cancel-btn"
                  onClick={() => this.invoiceDialogHandler(rowData)}
                >
                  Mark as Approved
                </p>
              )}
              <Buttons
                className="cancel-btn download-button icon"
                icon={"download"}
                onClick={() =>
                  this.handleDownloadDialog(rowData.id, "PROGRESSCLAIM")
                }
              />
            </>
          ),
          headerStyle: { minWidth: "250px", textAlign: "center" },
        },
      ];
      tableData = JSON.parse(JSON.stringify(this.state.progressClaims));
    }
    this.setState({ tableData, columns, filter: type });
  };

  handleGeneratePC = (progressClaimId) => {
    let { projectId } = this.state;
    if (progressClaimId) {
      this.props.history(
        `/${PROJECT_MANAGEMENT}/invoice/${projectId}/edit/${progressClaimId}`
      );
    } else {
      this.props.history(`/${PROJECT_MANAGEMENT}/invoice/${projectId}/create`);
    }
    this.handleClose();
  };

  deleteProgressClaim = (id, type) => {
    this.props.deleteProgressClaim(id, type).then((res) => {
      this.getData();
      this.setState({
        deleteDialogState: false,
      });
    });
    this.handleClose();
  };

  deleteDialogHandler = (id = "", type) => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteType: type,
      deleteId: id,
    }));
    this.handleClose();
  };

  handleRetentionDialog = () => {
    const { retentionField } = this.state;
    this.setState({
      addRetentionDialog: false,
      retentionField: {
        ...retentionField,
        value: "",
        valid: true,
        error: false,
      },
      progressClaimId: "",
      invoiceTotalAmount: "",
      editRetention: false,
    });
    this.handleClose();
  };

  submitForm = async () => {
    const {
      retentionField,
      invoiceAndProgressClaim,
      progressClaimId,
      editRetention,
    } = this.state;

    const index = invoiceAndProgressClaim.findIndex(
      (c) => c.progressClaimId === progressClaimId
    );
    if (index !== -1) {
      const invoice = invoiceAndProgressClaim[index];
      if (
        !retentionField.value ||
        invoice.claimAmount <= retentionField.value
      ) {
        retentionField.touched = true;
        retentionField.valid = false;
        retentionField.error = "Less than Claim Amount!";
        this.setState({ retentionField });
      } else {
        if (editRetention) {
          await this.props.editProgressClaimRetention(
            {
              retentionAmount: parseFloat(retentionField.value),
            },
            progressClaimId
          );
        } else {
          await this.props.addProgressClaimRetention({
            progressClaimId: progressClaimId,
            retentionAmount: parseFloat(retentionField.value),
            type: "RETENTION",
          });
        }

        this.getData();
        this.handleRetentionDialog();
      }
    }
  };

  handleRetentionAmount = (e) => {
    let { retentionField, invoiceAndProgressClaim, progressClaimId } =
      this.state;

    const amount = parseFloat(e.target.value || 0);

    const index = invoiceAndProgressClaim.findIndex(
      (c) => c.progressClaimId === progressClaimId
    );
    if (index !== -1) {
      const invoice = invoiceAndProgressClaim[index];
      if (!amount || invoice.claimAmount <= amount) {
        retentionField.touched = true;
        retentionField.valid = false;
        retentionField.error = "Less than Claim Amount!";
        retentionField.value = amount;
      } else {
        retentionField.touched = true;
        retentionField.valid = true;
        retentionField.error = "";
        retentionField.value = amount;
      }
      this.setState({ retentionField });
    }
  };

  openAddRetentionDialog(progressClaim) {
    const { retentionAmount, u_id } = progressClaim;
    let { retentionField } = this.state;
    retentionField.value = retentionAmount;
    let editRetention = retentionField.value ? true : false;
    let invoiceTotalAmount = progressClaim.totalValue;
    this.setState({
      addRetentionDialog: true,
      progressClaimId: u_id,
      retentionField,
      invoiceTotalAmount,
      editRetention,
    });
    this.handleClose();
  }

  ViewProjectList = () => {
    let projectType = idx(this.state, _ => _.projectData.projectType)
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      filter,
      columns,
      tableData,
      invoiceDialogState,
      invoiceData,
      invoices,
      projectId,
      downloadData,
      deleteDialogState,
      deleteId,
      deleteType,
      customerPaymentTerms,
      addRetentionDialog,
      retentionField,
      totalRetention,
      invoiceTotalAmount,
      claimsRetainedTotal,
      templateOptions,
      projectQuoteType,
      claimsRetentionClaimTotal,
    } = this.state;
    const writePermission = this.props.wFinancePermission;

    return (
      <>
        <Modal
          open={addRetentionDialog}
          onClose={this.handleRetentionDialog}
          onClick={this.submitForm}
          modalHeading={"Finalise Invoice"}
          modalSaveTxt={"Finalise"}
          modalCloseTxt="Cancel"
          showActionBtn={true}
          fullWidth={true}
          maxWidth={"sm"}
          cxlbtnClass="cancelBtn"
          primary
        >
          <div className="row mb-3">
            <div className="col-lg-4 mt-3">
              <div className="view-block">
                <label>Invoice Total</label>
                <p className="mt-2">${invoiceTotalAmount}</p>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="view-block">
                <label>Total Retained To Date</label>
                <p className="mt-2">${totalRetention}</p>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <AmountInput
                {...retentionField}
                label={'Retention Amount'}
                onChange={(e) => this.handleRetentionAmount(e)}
              />
            </div>
          </div>
        </Modal>
        <div className="invoiceContainer">

          <DeleteDialog
            open={deleteDialogState}
            id={deleteId}
            deleteHandler={this.deleteProgressClaim}
            deleteType={deleteType}
            dialogClose={this.deleteDialogHandler}
            moduleName="Progress Claim"
          />
          <DownloadDialog
            {...downloadData}
            downloadFile={this.downloadFile}
            templateOptions={templateOptions}
            handleDownloadDialog={this.handleDownloadDialog}
            downloadModuleName="Invoice"
          />



          {/* Already Commented */}
          {/* <InvoicesOverview
                onClick={this.changeFilter}
                filter={filter}
                progressClaims={progressClaims}
                invoices={invoices}
              /> */}
          {/* Already Commented */}

          <div className="purchase-order-block">
            <div className="row">
              <div className="w-auto">
                <h2 className="content-heading text-uppercase">Invoices</h2>
                <div className="col-12 breadcrumb-block p-0">
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={this.ViewProjectList}>PROJECTS</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={this.ViewProject}>{idx(this.state, _ => _.projectData.name)}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Invoices</Breadcrumb.Item>
                  </Breadcrumb>

                </div>
              </div>
              <div className="ms-auto mt-auto mb-2 d-flex w-auto">
                {writePermission && <button
                  onClick={() => this.handleGeneratePC()}
                  className="secondarybtn secondarybtn-outline ms-2 btn-ch mt-auto btn btn-primary">
                  Generate Invoice
                </button>}
              </div>
            </div>
            <hr className="mt-2"></hr>
            <CustomTable
              columns={columns}
              data={tableData}
              isAction={writePermission}
              handleMenu={(data) => this.handleMenu(data)}
              menu={(data) => (
                <Popover id="popover-basic">
                  <Popover.Body>
                    <ul className="action-block-list">
                      {data && data.progressClaimStatus == "Finalised" &&
                        <>
                          {/* <li>Retract</li> */}
                          <li onClick={() => this.invoiceDialogHandler(data)}>
                            View
                          </li>
                          <li onClick={() => this.handleDownloadDialog(data.u_id, "INVOICE")}>
                            Download
                          </li>
                        </>
                      }
                      {data && data.progressClaimStatus !== "Finalised" && data.progressClaimStatus == "Approved" && (
                        <>
                          <li onClick={() => this.openAddRetentionDialog(data)}>
                            Finalise
                          </li>
                          <li onClick={() => this.invoiceDialogHandler(data)}>
                            View
                          </li>
                          <li onClick={() => this.handleDownloadDialog(data.u_id, "INVOICE")}>
                            Download
                          </li>
                          <li onClick={() => this.deleteDialogHandler(data.u_id, "INVOICED")}>
                            Delete
                          </li>
                        </>
                      )}
                      {data && (data.progressClaimStatus == "Draft" || data.progressClaimStatus == null) && (
                        <>
                          <li onClick={() => this.invoiceDialogHandler(data, "PROGRESS_CLAIM")}>
                            Mark as Approved
                          </li>
                          <li onClick={() => this.handleGeneratePC(data.u_id)}>
                            Edit
                          </li>
                          <li onClick={() => this.invoiceDialogHandler(data)}>
                            View
                          </li>
                          <li onClick={() => this.handleDownloadDialog(data.u_id, "PROGRESSCLAIM")}>
                            Download
                          </li>
                          <li onClick={() => this.deleteDialogHandler(data.u_id, "DRAFT")}>
                            Delete
                          </li>
                        </>
                      )}
                    </ul>
                  </Popover.Body>
                </Popover>
              )}
              fileName='Invoices List'
            />

            {invoiceDialogState && (
              <ViewInvoice
                open={invoiceDialogState}
                filter={filter}
                projectQuoteType={projectQuoteType}
                invoiceData={invoiceData}
                dialogClose={this.invoiceDialogHandler}
                invoicesCount={invoices.length}
                projectId={projectId}
                getData={this.getData}
                handleDownloadDialog={this.handleDownloadDialog}
                handleGeneratePC={this.handleGeneratePC}
                customerPaymentTerms={customerPaymentTerms}
                claimsRetainedTotal={claimsRetainedTotal}
                claimsRetentionClaimTotal={claimsRetentionClaimTotal}
              />
            )}

          </div>

        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    user: state.user.userData,
    project: state.project,
  });
}

const mapDispatchToProps = (dispatch) => ({
  getInvoiceList: bindActionCreators(getInvoiceList, dispatch),
  getProgressClaimList: bindActionCreators(getProgressClaimList, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(getGlobalSettingTemplateTypes, dispatch),
  getInvoiceAndProgressClaimList: bindActionCreators(getInvoiceAndProgressClaimList, dispatch),
  downloadProjectInvoice: bindActionCreators(downloadProjectInvoice, dispatch),
  downloadProjectProgressBreakup: bindActionCreators(downloadProjectProgressBreakup, dispatch),
  deleteProgressClaim: bindActionCreators(deleteProgressClaim, dispatch),
  getProjectCustomer: bindActionCreators(getProjectCustomer, dispatch),
  addProgressClaimRetention: bindActionCreators(addProgressClaimRetention, dispatch),
  editProgressClaimRetention: bindActionCreators(editProgressClaimRetention, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
);
