import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./scss/customer-management.scss";
import {
  CUSTOMER_MANAGEMENT,
  ADD_CUSTOMER,
} from "./../../constants/route-paths";
import {
  getCustomerList,
  deleteCustomer,
} from "./../../actions/customer-management.action";
import DeleteDialog from "./../../components/delete-dialog";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import { useDispatch } from "react-redux";
import idx from "idx";
import { useSelector } from "react-redux";

export default function UsersList({ writePermission, ...props }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [listState, setListState] = useState({
    open: false,
    customers: [],
    ActiveUsers: [],
    customerId: null,
    actions: [],
    open2: true,
    open3: true,
  })
  const customers = useSelector(state => state.customerData.customers)

  useEffect(() => {
    initLoad()
  }, [customers])

  const initLoad = () => {
    if (customers.length) {
      processData()
    } else {
      fetchCustomers()
    }
  }

  const fetchCustomers = () => {
    dispatch(getCustomerList()).catch(e => console.error(e))
  }

  const processData = () => {
    const data = customers.map((customer) => {
      let primaryContact = (customer.contacts || []).find(item => item.primary) || {}
      let primaryAddress = (customer.siteAddress || []).find(item => item.primary) || {}
      let contacts = (idx(primaryContact, _ => _.firstName) || '') + ' ' + (idx(primaryContact, _ => _.lastName) || '')
      return {
        customerNumber: customer.customerNumber,
        businessName: customer.businessName,
        id: customer.id,
        address: idx(primaryAddress, _ => _.f_address) || '',
        phoneNumber: customer.phoneNumber || idx(primaryContact, _ => _.phoneNumber) || '',
        contacts: contacts,
        email: customer.email || idx(primaryContact, _ => _.email) || '',
        type: customer.type,
      };
    });

    const columns = [
      { title: "Customer Number", field: "customerNumber" },
      {
        title: "Company Name",
        field: "businessName",
        render: (rowData) => (
          <Link to={`/${CUSTOMER_MANAGEMENT}/edit/${rowData.id}`}>
            {rowData.businessName}
          </Link>
        ),
      },
      { title: "Address", field: "address" },
      { title: "Phone Number", field: "phoneNumber" },
      { title: "Contact", field: "contacts" },
      { title: "Email", field: "email" },
      { title: "Type", field: "type" },
    ];

    setListState(prev => ({
      ...prev,
      customers: data,
      columns: columns,
    }));
  };

  const addCustomer = () => {
    navigate(`/${CUSTOMER_MANAGEMENT}/${ADD_CUSTOMER}`);
  };

  const editCustomer = (customerId) => {
    navigate(`/${CUSTOMER_MANAGEMENT}/edit/${customerId}`);
  };

  const deleteHandler = () => {
    dispatch(deleteCustomer(listState.deleteId)).then((res) => {
      setListState(prev => ({
        ...prev,
        open: false,
      }));
      fetchCustomers();
    });
  };

  const deleteDialogHandler = (id = "") => {
    setListState(prev => ({
      ...prev,
      open: !prev.open,
      deleteId: id,
    }));
  };

  return (
    <>
      <DeleteDialog
        open={listState.open}
        id={listState.deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName="Customer"
      />
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Customers</h2>
          </div>
          <div className="w-auto ms-auto mt-auto">
            {writePermission && (<button
              onClick={addCustomer}
              className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">
              Add Customer
            </button>)}
          </div>
        </div>


        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="all"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="all" title={`Total Customers (${listState.customers.length})`}>
                <CustomTable
                  columns={listState.columns}
                  data={listState.customers}
                  isEdit={writePermission}
                  editHandler={(data) => editCustomer(data.id)}
                  isDelete={writePermission}
                  deleteHandler={(data) => deleteDialogHandler(data.id)}
                  fileName='Customers List'
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

    </>
  );

}
