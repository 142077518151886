import React, { Fragment, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploader from "../../../components/file-uploader";
import { useNavigate } from "react-router";
import { PROJECT_MANAGEMENT } from "src/constants/route-paths";
import { useSelector } from "react-redux";
import idx from 'idx'
import { taskFields } from "src/constants/fields.constant";
import DatePickerInput from "src/components/inputs/date-picker";
import { addTaskAction, updateProfitMarginAction, updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import ProfitMarginLock from "src/components/profitMarginLock-dialog";
import { addOption, addStage, addSubTask, addTask, getOption, getTask, updateOption, updateSubTask, updateTask } from "src/actions/project-management.action";
import SubTask from "./subtask";
import { toPercent, toUSD } from "src/util/currency-formatter";
import { errorSnackBar, warningSnackBar } from "src/actions/common.action";
import Cookies from "js-cookie";
import { subTaskCalculation } from "src/util/calculations/calculate-sub-task";
import CustomInput, { LockInput } from "src/components/inputs/new-input";
import AllocateResource from "../../timesheet/allocate-resource-dialog";
import ConfirmAllocateResource from "../../timesheet/confirm-allocate-resource-dialog";
import DeleteDialog from "../../../components/delete-dialog";
import moment from "moment";
import { allocateResource, allocateSchedule, deleteResource, editTimesheet, getResourceAllocations } from "src/actions/timesheet.action";
import { getUserList } from "src/actions/user-management.action";
import { format } from "date-fns";
import SaveTemplate from "src/components/save-template";
import ListItemTemplate from "src/components/list-template/item";
import { materialToForm } from "./binders/material";
import { subcontractorToForm } from "./binders/subcontractor";
import { laborToForm } from "./binders/labor";
import { getLaborList } from "src/actions/inventory.action";
import { addResourcesAction } from "src/slice/resources";
import { fileValidator } from "src/util/validators/file";

export default function AddTask({ updatePermission, open, dialogClose, isSubTask, isOption, isVariation, taskId, optionId, profitMargin, taskTemplateData, ...props }) {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const projectDetail = useSelector(state => state.projectDetail) || {}
  const entitlements = useSelector(state => state.user.entitlements)
  const user = useSelector(state => state.user)
  const task = useSelector(state => state.task)
  const resources = useSelector(state => state.resources)
  const [editMode, setEditMode] = useState(!(props.editOption || props.editSubTask || props.editTask) || false);
  const [fields, setFields] = useState(JSON.parse(JSON.stringify(taskFields)))
  const [taskState, setTaskState] = useState({
    showlockIcon: true,
    profitMarginDialogState: false,
    showItemTemplateDialog: false,
    saveTemplateDialog: false,
    saveTemplateData: {
      type: '',
      data: {}
    }
  })

  const [allocationState, setAllocationState] = useState({
    allocates: [],
    allocationId: null,
    showlockIcon: true,
    profitMarginDialogState: false,
    allocationDate: null,
    editTimesheet: false,
    allocationType: "",
    userId: user.userData.global_user ? "" : user.userData.id,
    usersList: [],
  })

  useEffect(() => {
    initLoad();
  }, [])

  const initLoad = async () => {
    if (!resources.list.length) _getLaborList();
    const { editTask, editSubTask, editOption } = props;
    setFields(prev => ({
      ...prev,
      profitMargin: {
        ...prev.profitMargin,
        value: profitMargin
      }
    }))
    dispatch(updateProfitMarginAction({
      profitMargin: profitMargin,
      profitMarginType: 'PERCENTAGE',
    }))
    if (taskId) {
      if (editTask || editSubTask) {
        dispatch(getTask(taskId)).then((data) => {
          preFill(data);
          setTaskState(prev => ({
            ...prev,
            showlockIcon: data.overrideProfitMargin == true ? false : true
          }));
        });
      } else {
        dispatch(addTaskAction({}))
      }
    } else if (optionId) {
      if (editOption) {
        dispatch(getOption(optionId)).then((data) => {
          preFill(data);
          setTaskState(prev => ({
            ...prev,
            showlockIcon: data.overrideProfitMargin == true ? false : true
          }));
        });
      } else {
        dispatch(addTaskAction({}))
      }
    }
    if (taskTemplateData) {
      let { subTasks = [], name = '' } = taskTemplateData;
      setFields(prev => ({
        ...prev,
        taskName: {
          ...prev.taskName,
          value: name
        }
      }))
      loadItemFromTemplate(subTasks, true, { profitMargin: profitMargin })
    }
    await _getUsersList();
    getAllocationList();
  }

  const _getLaborList = () => {
    dispatch(getLaborList()).then((data) => {
      let labors = (data || []).map((labor, i) => {
        labor.sno = i + 1;
        return labor;
      })
      dispatch(addResourcesAction(labors));
    }).catch(e => console.error(e))
  };

  const _getUsersList = async () => {
    const status = "ACTIVE";
    const list = await dispatch(getUserList({ status }));
    const usersList = (Array.isArray(list) && list.map(({ id, firstName, lastName }) => ({
      key: (firstName === null ? "" : firstName) + " " + (lastName === null ? "" : lastName),
      value: id,
    }))) || [];
    setAllocationState(prev => ({
      ...prev,
      usersList
    }));
  };

  const preFill = (task) => {
    dispatch(addTaskAction(task, idx(projectDetail, _ => _.subcontractors) || []))
    dispatch(updateProfitMarginAction({
      profitMargin: parseInt(task.profitMargin || profitMargin || 0),
      profitMarginType: task.profitMarginType
    }))
    setFields(prev => ({
      ...prev,
      taskName: {
        ...prev.taskName,
        value: task.name
      },
      description: {
        ...prev.description,
        value: task.description || ""
      },
      date: {
        ...prev.date,
        value: new Date(task.date)
      },
      user: {
        ...prev.user,
        value: task.user || ""
      },
      is_actual_materialcost: {
        ...prev.is_actual_materialcost,
        value: task.isActualMaterialcost || false
      },
      profitMarginType: {
        ...prev.profitMarginType,
        value: task.profitMarginType
      },
      profitMargin: {
        ...prev.profitMargin,
        value: parseInt(task.profitMargin || profitMargin || 0)
      },
      attachments: {
        ...prev.attachments,
        attachmentArr: (task.attachment || []).map((attach) => {
          if (_.isString(attach)) {
            return JSON.parse(attach);
          } else {
            return attach
          }
        })
      }
    }))
  };

  const generateTaskPO = () => {
    const { projectId, subTaskId } = props;
    navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}/create-purchase-order?taskId=${isSubTask ? subTaskId : taskId}`);
  }

  const getModalHeader = () => {
    const { editTask, editSubTask, editOption, action } = props;

    let modalHeading = "Create Task";

    if (!editMode) {
      modalHeading = isSubTask ? "SubTask Details" : isOption ? "Option Details" : isVariation ? "Variation Details" : "Task Details"
    } else {
      if (isSubTask) modalHeading = "Create SubTask";

      if (isOption) {
        modalHeading = "Create Option";
        if (action === "revise") modalHeading = "Revise Option";
        if (isVariation) modalHeading = "Create Variation";
      }
      if (editTask) modalHeading = "Edit Task";
      if (editSubTask) modalHeading = "Edit SubTask";
      if (editOption) {
        modalHeading = "Edit Option";
        if (action === "revise") modalHeading = "Revise Option";
        if (isVariation) modalHeading = "Edit Variation";
      }
    }
    return modalHeading;
  }

  const inputChangeHandler = (e, fieldName = "", index = "") => {
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)

    let description;
    let descriptionLength;
    if (e.target.value) {
      description = e?.target?.value.trim();
      descriptionLength = description.length;
    }

    if (_temp[index]) {
      _temp[index].descriptionError = !description;
      _temp[index].descriptionLength = description && descriptionLength < 2;
    } else {
      _temp[index] = {
        descriptionError: !description,
        descriptionLength: description && descriptionLength < 2,
      };
    }

    if (e.target.type === "file") {
      const fileToUpload = e.target.files
      Array.isArray(fileToUpload) && fileToUpload.forEach(file => {
        const fileValidation = fileValidator({ file })
        const { validFileSize, validFileType, error } = fileValidation
        if (!validFileSize.valid && !error) dispatch(errorSnackBar(validFileSize.error))
        else if (!validFileType.valid && !error) dispatch(errorSnackBar(validFileType.error))
        else if (error) dispatch(errorSnackBar(error.error))
        else fields[e.target.name].value.push(file);
      })
    } else {
      if (e.target.name === "profitMarginType") {
        fields["profitMargin"].value = "";
      }
      fields[e.target.name].value = e.target.value;
    }

    fields[e.target.name].touched = true;
    fields[e.target.name].error = false;
    fields[e.target.name].valid = true;
    setFields(prev => ({
      ...prev,
      ...fields
    }))
    recalcualteProfitMargin(e.target.name);
  };

  const recalcualteProfitMargin = (name) => {
    if (name === "profitMarginType" || name === "profitMargin") {
      updateProfitChanges();
    }
  };

  const updateProfitChanges = () => {
    dispatch(updateProfitMarginAction({
      profitMargin: fields.profitMargin.value,
      profitMarginType: fields.profitMarginType.value,
    }))
  }

  const lockIconChange = () => {
    const { showlockIcon } = taskState;
    const formData = new FormData();
    formData.set("overrideProfitMargin", showlockIcon);
    if (taskId)
      dispatch(updateTask(formData, taskId)).then((res) => { });

    setTaskState(prev => ({
      ...prev,
      showlockIcon: !prev.showlockIcon
    }));

    if (!showlockIcon)
      fields.profitMargin.value = props.projectMarginValue;

    updateProfitChanges();
    setFields(prev => ({
      ...prev,
      ...fields
    }))
    profitMarginDialogHandler();
  };

  const profitMarginDialogHandler = () => {
    setTaskState(prev => ({
      ...prev,
      profitMarginDialogState: !prev.profitMarginDialogState
    }));
  };

  const handleAddTask = () => {
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)
    const clonedAccordionFields = {
      labor: [],
      subcontractor: [],
      material: [],
      taskName: "",
      is_actual_materialcost: false,
      descriptionError: true,
      descriptionLength: true,
    };
    _temp.push(clonedAccordionFields);
    dispatch(updateSubTaskAction(_temp))
  };

  const getFiles = () => {
    const files = (fields.attachments.value || []).map((doc, i) => (
      <Fragment key={i}>
        <span> {doc.name}</span><span onClick={() => removeFile(i, "new")}><DeleteIcon /></span>
      </Fragment>
    ));
    const attachments = (fields.attachments.attachmentArr || []).map((doc, i) => (
      <Fragment key={i}>
        <span> {doc.name}</span><span onClick={() => removeFile(i, "old")}><DeleteIcon /></span>
      </Fragment>
    ));
    if (!files.length && !attachments.length) files.push(<span key={'space'}>&nbsp;</span>)
    return (<>{files}{attachments}</>)
  }
  const removeFile = (index, type) => {
    if (type === "new") {
      const data = fields.attachments.value.filter((doc, i) => i !== index);
      fields.attachments.value = data;
    }
    if (type === "old") {
      const data = fields.attachments.attachmentArr.filter((doc, i) => i !== index);
      fields.attachments.attachmentArr = data;
    }
    setFields(prev => ({
      ...prev,
      ...fields
    }))
  };

  const submitHandler = async (type = "") => {
    const { projectId, projectType, editOption, action } = props;
    let { stageId } = props;
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)

    const allDescriptionsError = _temp.every((subtask) => subtask.descriptionError === false);
    if (_temp.length > 0 && !allDescriptionsError) {
      dispatch(errorSnackBar("A description is needed for all Items"));
      return;
    }
    let _taskId = taskId;
    const formData = new FormData();
    formData.set("name", fields.taskName.value);
    formData.set("profitMargin", fields.profitMargin.value || 0);
    formData.set("marginType", fields.profitMarginType.value);
    formData.set("date", fields.date.value);
    if (Array.isArray(fields.attachments.attachmentArr) && Array.isArray(fields.attachments.value)) {
      for (let i = 0; i < fields.attachments.attachmentArr.length; i++) {
        formData.append("attachment[]", JSON.stringify(fields.attachments.attachmentArr[i]));
      }
      for (let x = 0; x < fields.attachments.value.length; x++) {
        formData.append("attachment", fields.attachments.value[x]);
      }
    }

    if (!isOption && !isVariation) {
      if (_taskId) {
        dispatch(updateTask(formData, _taskId)).then((res) => { }).catch(e => console.log(e));
      } else {
        if (stageId === "") {
          const formDatanew = new FormData();
          formDatanew.set("name", "DummyStage");
          formDatanew.set("isDummyStage", true);
          await dispatch(addStage(formDatanew, projectId, true))
            .then((res) => {
              stageId = res.id;
              dispatch(addTask(formData, stageId)).then((res) => {
                _taskId = res.id;
              });
            }).catch((e) => console.log(e));
        } else {
          await dispatch(addTask(formData, stageId)).then((res) => {
            _taskId = res.id;
          }).catch((e) => console.log(e));
        }
      }
    } else if (isOption || isVariation) {
      formData.set("project_id", projectId || Cookies.get("projectId"));
      if (isVariation) formData.set("isVariation", true);
      if (isOption && projectType === "TENDER") formData.set("isOption", true);

      if (!editOption || (editOption && action === "revise")) {
        if (optionId && action === "revise") {
          formData.set("isRevised", true);
          formData.set("optionId", optionId);
        }
        await dispatch(addOption(formData, isVariation === false ? "isOption" : "isVariation"))
          .then((res) => {
            // if (this.props.getStages) this.props.getStages();
            // this.props.getOptions();
            // if (this.props.getVariations) this.props.getVariations();
            _taskId = res.id;
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        await dispatch(updateOption(formData, optionId, isVariation === false ? "isOption" : "isVariation"))
          .then((res) => {
            // if (this.props.getStages) this.props.getStages();
            // this.props.getOptions();
            // if (this.props.getVariations) this.props.getVariations();
            _taskId = res.id;
          }).catch((e) => console.log(e));
      }
    }

    if (type === "EntireTask" && _taskId) {
      let index = 1;
      for await (let subtask of _temp) {
        if (action === "revise") {
          await saveSubTask(subtask, _taskId, "addsubtask", index++)
        } else {
          await saveSubTask(subtask, subtask.id ? subtask.id : _taskId, subtask.id ? "updateSubTask" : "addsubtask", index++)
        }
      }

      dialogClose();
      if (props.getStages) props.getStages();
      if (props.getOptions) props.getOptions();
      if (props.getVariations) props.getVariations();
      if (props.handleConvertOptiontoTaskClose) props.handleConvertOptiontoTaskClose();
    }
  };

  const formatNewMaterials = (materialsarray) => {
    const materialData = materialsarray.map((item) => ({
      item: item.name.value,
      id: item.id,
      categoryId: item.categoryId,
      manufacturer: item.manufacturer,
      partNo: item.partNo,
      cost: item.costPerItem.value,
      saleAmount: item.saleAmount.value,
      saleAmountLock: item.saleAmountLock.value,
      qty: item.qty.value,
      unit: item.unit.value || null,
      total: item.total.value,
      isInlineItem: item.isInlineItem || false,
    }));

    return materialData;
  };

  const filterLabor = (id) => {
    let laborsList = idx(projectDetail, _ => _.labors) || [];
    let labor = laborsList.find((labor) => labor.id === id);
    if (!labor) {
      let allLabors = idx(resources, _ => _.list) || [];
      labor = allLabors.find((labor) => labor.id === id);
      if (!labor) return {};
      labor = _.cloneDeep(labor)
      labor['calloutCost'] = 0;
      labor['averageCost'] = labor.cost;
      labor['skillDescription'] = ''
    }
    return labor || {};
  };

  const formatLaborData = (laborarray) => {
    const laborData = laborarray.map((_labor) => {
      let _laborItem = filterLabor(_labor.id.value)
      return {
        name: _labor.name.value,
        level: _labor.level.value,
        id: _labor.id.value,
        cost: _labor.costPerItem.value,
        qty: _labor.qty.value,
        calloutCost: _labor.calloutCost.value || 0,
        averageCost: _laborItem ? _laborItem.averageCost ?? (_labor.labourCost.value || 0) : (_laborItem.cost || 0),
        total: _labor.total.value,
      }
    });
    return laborData;
  };
  const formatSubcontractorData = (subcontractorarray) => {
    const subcontractorData = subcontractorarray.map((_subcontractor) => ({
      name: _subcontractor.name.value,
      id: _subcontractor.id.value,
      Cost: _subcontractor.Cost.value,
      saleAmount: _subcontractor.saleAmount.value,
      saleAmountLock: _subcontractor.saleAmountLock.value,
      description: _subcontractor.description.value,
    }));
    return subcontractorData;
  };

  const handleAllocationDialog = () => {
    setAllocationState(prev => ({
      ...prev,
      showAllocationDialog: true,
      allocationDate: null,
      allocationId: null,
      editTimesheet: false,
    }));
  };

  const _allocateResource = async (data) => {
    let { editTimesheet } = allocationState;
    let start = new Date(data.startTime);
    let end = new Date(data.endTime);
    let startTime = new Date(data.startDate);
    let endTime = new Date(data.endDate);
    startTime.setHours(start.getHours(), start.getMinutes(), 0, 0);
    endTime.setHours(end.getHours(), end.getMinutes(), 0, 0);
    startTime = moment.utc(startTime).format("YYYY-MM-DDTHH:mmZ").toString();
    endTime = moment.utc(endTime).format("YYYY-MM-DDTHH:mmZ").toString();
    data["startTime"] = startTime;
    data["endTime"] = endTime;
    delete data.startDate;
    delete data.endDate;
    if (editTimesheet) data.allocationType = "WORK";
    await handleAllocation(data, "WORK");
  };

  const handleAllocation = async (data, type) => {
    try {
      const { editTimesheet, allocationId } = allocationState;
      let { confirmDialogState, confirmData } = allocationState;
      let allocationsList = [];
      if (editTimesheet || allocationId) {
        allocationsList = await _editTimesheet(data);
      } else {
        allocationsList = await dispatch(allocateResource(data));
      }

      confirmDialogState = true;
      confirmData = allocationsList;

      setAllocationState(prev => ({
        ...prev,
        showAllocationDialog: false,
        confirmData,
        confirmDialogState,
      }));
    } catch (e) {
      setAllocationState(prev => ({
        ...prev,
        showAllocationDialog: true
      }));
    }
  };

  const _allocateSchedule = async (data) => {
    try {
      await dispatch(allocateSchedule(data));
      // if (data.allocationType === "WORK") await dispatch(getResourceAllocations());
      // if (data.previousType === "WORK" && data.allocationType !== "WORK")
      //   await dispatch(getResourceAllocations());
      getAllocationList()
      setAllocationState(prev => ({
        ...prev,
        confirmDialogState: false,
        showAllocationDialog: false,
        taskDialogState: false,
      }));
      if (props.getStages) props.getStages();
      if (dialogClose) dialogClose();
    } catch (e) {
      console.error(e)
      setAllocationState(prev => ({
        ...prev,
        confirmDialogState: false,
        showAllocationDialog: false,
        taskDialogState: false,
      }));
      getAllocationList()
      // setAllocationState(prev => ({
      //   ...prev,
      //   confirmDialogState: true,
      // }));
    }
  };

  const _editTimesheet = async (data) => {
    const { allocationId } = allocationState;
    data.previousType = "WORK";
    data.allocationResourceId = allocationId;
    data.startTime = moment
      .utc(data.startTime)
      .format("YYYY-MM-DDTHH:mmZ")
      .toString();
    data.endTime = moment
      .utc(data.endTime)
      .format("YYYY-MM-DDTHH:mmZ")
      .toString();
    return await dispatch(editTimesheet(allocationId, data));
  };

  const deleteHandler = async (allocationId) => {
    await dispatch(deleteResource("WORK", allocationId));
    deleteDialogHandler();
    dialogClose();
  };

  const deleteDialogHandler = (event = {}) => {
    const eventStatus = idx(event, (_) => _.event.status) || "";
    const isDelete = eventStatus ? eventStatus !== "PENDING" ? false : true : true;
    if (isDelete) {
      setAllocationState(prev => ({
        ...prev,
        deleteDialogState: !prev.deleteDialogState,
        deleteEvent: event,
        showAllocationDialog: false,
        allocationId: event.id,
      }));
    } else {
      dispatch(warningSnackBar(`This allocation is already ${eventStatus}`));
    }
  };
  const mapUserName = (userId) => {
    let { usersList } = allocationState;
    let name = '';
    usersList.map((user) => {
      if (user.value === userId) {
        name = user.key
        return user.key;
      }
    });
    return name;//data;
  };
  const getAllocationList = () => {
    const allocates = [];
    dispatch(getResourceAllocations({
      taskId: taskId,
      projectId: props.projectId,
    }))
      .then((res) => {
        const all = JSON.parse(JSON.stringify(res));
        all.forEach((allocate, i) => {
          allocates.push({
            id: allocate.id,
            userId: allocate.userId,
            user: mapUserName(allocate.userId),
            date: format(new Date(allocate.startTime), "dd/MM/yyyy") + " - " + format(new Date(allocate.endTime), "dd/MM/yyyy"),
            starttime: format(new Date(allocate.startTime), "hh:mm a"),
            endtime: format(new Date(allocate.endTime), "hh:mm a"),
            status: allocate.status,
          });
        });

        setAllocationState(prev => ({
          ...prev,
          allocates,
        }));
        const columns = [
          { title: "Assigned User", field: "user" },
          { title: "Date", field: "date" },
          { title: "Start Time", field: "starttime" },
          { title: "End Time", field: "endtime" },
          { title: "Status", field: "status" },
        ];
        setAllocationState(prev => ({
          ...prev,
          columns: columns,
        }));
      });
  };
  const handleEditTimesheet = (allocationId) => {
    const { allocationType } = allocationState;
    setAllocationState(prev => ({
      ...prev,
      allocationType: allocationType,
      allocationId: allocationId,
      allocationResourceId: allocationId,
      showAllocationDialog: true,
      editTimesheet: true,
    }));
  };

  const saveSubTask = async (subtask, id = "", type = "", order = 1) => {
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    const formData = new FormData();
    const material = formatNewMaterials(subtask["material"]);
    const laborData = formatLaborData(subtask["labor"]);
    const subcontractorData = formatSubcontractorData(subtask["subcontractor"]);

    let calculation = subTaskCalculation({
      subtask: subtask,
      profitMargin: fields.profitMargin.value,
      profitMarginType: fields.profitMarginType.value,
      isForm: true
    })

    formData.set("description", subtask.description);
    formData.set("order", order);
    formData.set("is_actual_materialcost", subtask.is_actual_materialcost);

    formData.set("labor_total_cost", calculation.labor.cost || 0);
    formData.set("laborTotal_SaleAmount", calculation.labor.sale || 0);
    formData.set("labor_profit", calculation.labor.profit || 0);

    formData.set("materialTotal_Cost", parseFloat(calculation.material.cost || 0));
    formData.set("materialTotal_SaleAmount", parseFloat(calculation.material.sale || 0));
    formData.set("material_Profit", parseFloat(calculation.material.profit || 0));

    formData.set("subcontractorTotal_Cost", calculation.subcontractor.cost || 0);
    formData.set("subcontractorTotal_SaleAmount", calculation.subcontractor.sale || 0);
    formData.set("subcontractor_Profit", calculation.subcontractor.profit || 0);

    formData.set("profitMargin", fields.profitMargin.value || 0);
    formData.set("marginType", fields.profitMarginType.value);
    formData.set("profitMarginCost", calculation.total_profit || 0);
    formData.set("totalProfit", calculation.total_profit || 0);

    for (let i = 0; i < material.length; i++) {
      formData.append("material[]", JSON.stringify(material[i]));
    }

    for (let i = 0; i < laborData.length; i++) {
      formData.append("labor[]", JSON.stringify(laborData[i]));
    }
    for (let i = 0; i < subcontractorData.length; i++) {
      formData.append("subcontractor[]", JSON.stringify(subcontractorData[i], getCircularReplacer()));
    }

    if (material.length === 0) {
      formData.append("material[]", null);
    }
    if (type === "addsubtask") {
      await dispatch(addSubTask(formData, id)).then((res) => {
        // if (type != "EntireTask") {
        //   // this.dialogClose();
        //   if (this.props.getStages) this.props.getStages();
        // }
        // this.props.getOptions();
        // if (lastIndex === 1) {
        //   this.dialogClose();
        // }
      });
    } else if (type == "updateSubTask") {
      await dispatch(updateSubTask(formData, id)).then((res) => {
        // if (type != "EntireTask") {
        //   // this.dialogClose();
        //   if (this.props.getStages) this.props.getStages();
        // }
        // this.props.getOptions();
        // if (lastIndex === 1) {
        //   this.dialogClose();
        // }
      });
    }
  };

  const handleSaveTemplateDialog = (type = '', data = {}) => {
    setTaskState(prev => ({
      ...prev,
      saveTemplateDialog: !prev.saveTemplateDialog,
      saveTemplateData: {
        type: type,
        data: data
      }
    }));
  }

  const handleItemTemplateDialog = () => {
    setTaskState(prev => ({
      ...prev,
      showItemTemplateDialog: !prev.showItemTemplateDialog,
    }));
  }

  const loadItemFromTemplate = (items = [], skipClose = false, data = {}) => {
    let { profitMargin } = data;
    if (!profitMargin) profitMargin = fields.profitMargin.value
    const subTaskArray = idx(task, _ => _.task.subTaskArray) || []
    let _temp = _.cloneDeep(subTaskArray)

    for (const iterator of items) {
      const { material = [], labor = [], subcontractor = [], description } = iterator;

      const cloneSubTask = {
        description: description,
        labor: labor.map(_labor => laborToForm(_labor, { qty: iterator.selectedQty, filterLabor })),
        subcontractor: subcontractor.map(_subcontractor => subcontractorToForm(_subcontractor, { qty: iterator.selectedQty, profitMargin: profitMargin, profitMarginType: fields.profitMarginType.value })),
        material: material.map(_material => materialToForm(_material, { qty: iterator.selectedQty, profitMargin: profitMargin, profitMarginType: fields.profitMarginType.value })),
        is_actual_materialcost: false,
        descriptionError: !description ? true : false,
        descriptionLength: !description ? true : false,
      };
      _temp.push(cloneSubTask);
    }
    dispatch(updateSubTaskAction(_temp))
    if (!skipClose) handleItemTemplateDialog()
  };

  return (
    <>
      {allocationState.deleteDialogState && (<DeleteDialog
        open={allocationState.deleteDialogState}
        id={allocationState.allocationId}
        deleteHandler={() => deleteHandler(allocationState.allocationId)}
        dialogClose={deleteDialogHandler}
        moduleName="Allocation"
      />)}
      {!allocationState.deleteDialogState && allocationState.showAllocationDialog && (
        <AllocateResource
          open={allocationState.showAllocationDialog}
          dialogClose={() => {
            setAllocationState(prev => ({
              ...prev,
              showAllocationDialog: false,
            }));
          }}
          usersList={allocationState.usersList}
          allocateResource={_allocateResource}
          allocationId={allocationState.allocationId}
          allocationType={allocationState.allocationType}
          moduleName={"TaskDetails"}
          editTimesheet={allocationState.editTimesheet}
          allocationDate={allocationState.allocationDate}
          taskId={taskId}
          projectId={props.projectId}
          userId={allocationState.userId}
        />
      )}
      {allocationState.confirmDialogState && (
        <ConfirmAllocateResource
          open={allocationState.confirmDialogState}
          data={allocationState.confirmData}
          dialogClose={() => {
            setAllocationState(prev => ({
              ...prev,
              confirmDialogState: false,
            }));
          }}
          allocationType={allocationState.allocationType}
          allocateSchedule={_allocateSchedule}
        />
      )}
      <Modal
        show={open}
        onHide={() => dialogClose()}
        dialogClassName="modal-90w create-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {getModalHeader()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfitMarginLock
            open={taskState.profitMarginDialogState}
            lockIconChange={() => lockIconChange()}
            dialogClose={profitMarginDialogHandler}
            moduleName="Task"
            modalHeading={"Profit Margin Lock Changes"}
            text={"profit margin"}
            message={taskState.showlockIcon ? 'Are you sure you want to change the Project default profit margin? - Any items using default profit margins will also be updated to this value' : ''}
          />
          <SaveTemplate
            open={taskState.saveTemplateDialog}
            dialogClose={() => handleSaveTemplateDialog()}
            data={taskState.saveTemplateData.data}
            type={taskState.saveTemplateData.type}
          />
          {taskState.showItemTemplateDialog && <ListItemTemplate
            open={taskState.showItemTemplateDialog}
            dialogClose={() => handleItemTemplateDialog()}
            addHandler={loadItemFromTemplate}
          />}
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-3">
              <CustomInput
                placeholder="Enter task name"
                label={isSubTask ? "SubTask Name" : isOption ? "Option Name" : isVariation ? "Variation Name" : "Task Name"}
                {...fields.taskName}
                disabled={!editMode}
                onChange={(e) => inputChangeHandler(e)}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <DatePickerInput
                disabled={!editMode}
                {...fields.date}
                label={'Date'}
                onChange={(e) => inputChangeHandler({ target: { value: e, name: fields.date.name }, }, "date")}
              />
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <LockInput
                lock={
                  <span className="lock-icon">
                    {taskState.showlockIcon ?
                      <LockIcon onClick={() => { if (editMode) profitMarginDialogHandler() }} />
                      : <LockOpenIcon onClick={() => { if (editMode) profitMarginDialogHandler() }} />
                    }
                  </span>
                }
                placeholder="Enter profit margin"
                {...fields.profitMargin}
                label={'Profit Margin %'}
                disabled={!editMode || taskState.showlockIcon}
                onChange={(e) => {
                  if (taskState.showlockIcon) return;
                  if (e.target.value < 0) return;
                  if (
                    fields &&
                    fields.profitMarginType &&
                    fields.profitMarginType.value === "PERCENTAGE" &&
                    e.target.value > 100
                  )
                    return;
                  inputChangeHandler(e);
                }}
              />
            </div>
            <div className="col-lg-6 col-md-6 mt-3 fileupload-section">
              <div className="file-section-part position-relative h-auto px-0">
                <div className="file-part">
                  <FileUploader
                    fileHandler={(files) => {
                      if (!editMode) return;
                      inputChangeHandler({
                        target: {
                          files,
                          type: "file",
                          name: "attachments",
                        },
                      })
                    }}
                  />
                  <label htmlFor="#file-section"></label>
                </div>
                <input type="file" id="file-section"></input>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-3">
              <div className="form-group">
                <div className="input-block">
                  <label className="input-label">Uploaded Documents</label>
                  <p className="m-0 doc-info">
                    {getFiles()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Tabs defaultActiveKey="items" id="uncontrolled-tab-example" className="mb-2" >
            <Tab eventKey="items" title="Items">
              <SubTask
                subcontractors={idx(projectDetail, _ => _.subcontractors) || []}
                profitMargin={fields.profitMargin.value}
                profitMarginType={fields.profitMarginType.value}
                subTaskArray={idx(task, _ => _.task.subTaskArray) || []}
                isDisabled={!editMode}
                handleSaveTemplateDialog={handleSaveTemplateDialog}
                wTemplatePermission={props.wTemplatePermission}
              />

              {editMode && <div className="col-12 justify-content-end gap-2 d-flex btn-res">
                <Button onClick={handleItemTemplateDialog} className="primarybtn">ADD TEMPLATE</Button>
                <Button onClick={handleAddTask} className="primarybtn">ADD ITEM</Button>
              </div>}
              <div className="col-12 total-table mt-2 table-responsive">
                <Table bordered className="table-create mb-0 tbl-fixed tbl-fixed-width">
                  <tbody>
                    <tr>
                      <th className="desc-width"></th>
                      <th>Material</th>
                      <th>Resource</th>
                      <th>Contractor</th>
                      <th>Total</th>
                      <th className="qty-width"></th>
                    </tr>
                    <tr>
                      <td className="desc-width">Profilt</td>
                      <td>
                        <span className="tableinfo">
                          {toUSD(idx(task, _ => _.task.total.material.sale))}
                        </span>
                        <span>
                          {toUSD(idx(task, _ => _.task.total.material.profit))}&nbsp;
                          ({toPercent(idx(task, _ => _.task.total.material.profit), idx(task, _ => _.task.total.material.cost))})
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {toUSD(idx(task, _ => _.task.total.labor.sale))}
                        </span>
                        <span>
                          {toUSD((idx(task, _ => _.task.total.labor.profit)))}&nbsp;
                          ({toPercent(idx(task, _ => _.task.total.labor.profit), idx(task, _ => _.task.total.labor.cost))})
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {toUSD(idx(task, _ => _.task.total.subcontractor.sale))}
                        </span>
                        <span>
                          {toUSD(idx(task, _ => _.task.total.subcontractor.profit))}&nbsp;
                          ({toPercent(idx(task, _ => _.task.total.subcontractor.profit), idx(task, _ => _.task.total.subcontractor.cost))})
                        </span>
                      </td>
                      <td>
                        <span className="tableinfo">
                          {toUSD(idx(task, _ => _.task.total.total))}
                        </span>
                        <span>
                          {toUSD(idx(task, _ => _.task.total.total_profit))}&nbsp;
                          ({toPercent(idx(task, _ => _.task.total.total_profit), idx(task, _ => _.task.total.subtotal))})
                        </span>
                      </td>
                      <td className="qty-width ps-2 pe-3"></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
            {entitlements.scheduleMenu && isVariation == false && isOption == false && props.projectType !== "TENDER" && (<Tab eventKey="user" title="Timesheets">
              <>
                <div className="list-title">
                  <h6>Allocated Users</h6>
                  <div className="table-responsive">
                    <Table bordered className="table-create table-material tbl-fixed">
                      <thead>
                        <tr>
                          <th>Assigned User</th>
                          <th>Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allocationState.allocates.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td>{mapUserName(e.userId)}</td>
                              <td>{e.date}</td>
                              <td>{e.starttime}</td>
                              <td>{e.endtime}</td>
                              <td className="status">{e.status}</td>
                              <td>
                                {editMode && <div className="d-flex justify-content-center">
                                  <div className="action-edit me-2">
                                    <EditIcon onClick={() => handleEditTimesheet(e.id)} />
                                  </div>
                                  <div className="action-edit me-2">
                                    <DeleteIcon onClick={() => deleteDialogHandler(e)} />
                                  </div>
                                </div>}
                              </td>
                            </tr>
                          );
                        })}
                        {
                          allocationState.allocates.length < 1 && (
                            <tr>
                              <td className="text-center" colSpan={6}>No Data</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>

                {taskId !== "" && editMode && (<div className="col-12 mt-2 d-flex btn-res">
                  <Button type="submit" className="ms-auto primarybtn" onClick={handleAllocationDialog}>+ Assign User</Button>
                </div>)}
              </>
            </Tab>)}

          </Tabs>

          <div className="col-12 d-flex mt-3 gap-2">
            {props.wTemplatePermission && <Button onClick={() => handleSaveTemplateDialog('task', idx(task, _ => _.task))} className="primarybtn">
              SAVE AS TASK TEMPLATE
            </Button>}

            {props.wSupplierPermission && idx(projectDetail, _ => _.project.projectType) !== "TENDER" && !isOption && !editMode &&
              <Button type="submit" className="primarybtn" onClick={generateTaskPO}>GENERATE PO</Button>}
            {updatePermission && editMode && <>
              <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => submitHandler("EntireTask")}>SAVE</Button>
              <Button type="submit" className="secondarybtn cancelbtn" onClick={dialogClose}>CANCEL</Button>
            </>}
            {updatePermission && !editMode && <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={() => setEditMode(true)}>
              {isSubTask ? "Edit SubTask" : isOption ? "Edit Option" : isVariation ? "Edit Variation" : "Edit Task"}
            </Button>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

}
