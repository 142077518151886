import { updateProjectAction } from "src/slice/project-detail";
import { getQuotationList } from "../../../actions/project-management.action";

export const getQuotationListData = async ({ projectId, dispatch }) => {

    const query = `?projectId=${projectId}`;
    dispatch(getQuotationList(query)).then((quotations) => {
        let totalRFQSend = 0;
        quotations.forEach((quotation) => {
            if (!quotation.draft) {
                totalRFQSend += 1;
            }
        });

        dispatch(updateProjectAction({ totalRFQSend }))
    });

}