import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "../../util/with-router";
import { TransitionGroup } from "react-transition-group";
import ProjectList from "./project-list";
import AddProject from "./add-project";
import ViewProject from "./view-project";
import AssignTask from "./project-execution/assign-task";
import ProjectAsset from "./project-execution/asset-project";
import AddProjectAsset from "./project-execution/add-asset-project";
import * as paths from "../../constants/route-paths";
import ViewVariation from "./view-variation";
import AddVariation from "./add-variation";
import ProjectInvoices from "./project-execution/invoices/invoices-list";
import ProjectBudget from "./project-execution/budget/create-budget";
import PreviewBudget from "./project-execution/budget/preview-budget";
import ViewRFQ from "./view-rfq";
import POList from "./project-execution/po-list";
import WOList from "./project-execution/wo-list";
import ReceiveStock from "./project-execution/receive-stock";
import ReceiveDEStock from "./project-execution/receive-de-stock";
import RBAC from './rbac-project';

function CustomRoutes({ location }) {

  let element = useRoutes([
    {
      path: "",
      element: <RBAC moduleURL={location.pathname} component={ProjectList} />,
    },
    {
      path: paths.ADD_PROJECT,
      element: <RBAC moduleURL={location.pathname} component={AddProject} />,
    },
    {
      path: `${paths.EDIT_PROJECT}/*`,
      element: <RBAC moduleURL={location.pathname} component={AddProject} />,
    },
    {
      path: `${paths.EDIT_PROJECT_STEP_TWO}/*`,
      element: <RBAC moduleURL={location.pathname} component={AddProject} />,
    },
    {
      path: `${paths.VIEW_PROJECT}/*`,
      element: <RBAC moduleURL={location.pathname} component={ViewProject} />,
    },
    {
      path: paths.ASSIGN_TASK,
      element: <RBAC moduleURL={location.pathname} component={AssignTask} />,
    },
    {
      path: paths.PROJECT_ASSET,
      element: <RBAC moduleURL={location.pathname} component={ProjectAsset} />,
    },
    {
      path: paths.PROJECT_VARIATION,
      element: <RBAC moduleURL={location.pathname} component={ViewVariation} />,
    },
    {
      path: paths.ADD_PROJECT_VARIATION,
      element: <RBAC moduleURL={location.pathname} component={AddVariation} />,
    },
    {
      path: paths.ADD_PROJECT_ASSET,
      element: <RBAC moduleURL={location.pathname} component={AddProjectAsset} />,
    },
    {
      path: paths.PROJECT_INVOICES,
      element: <RBAC moduleURL={location.pathname} component={ProjectInvoices} />,
    },
    {
      path: paths.PROJECT_RFQ,
      element: <RBAC moduleURL={location.pathname} component={ViewRFQ} />,
    },
    {
      path: `${paths.PROJECT_PURCHASE_ORDER}/*`,
      element: <RBAC moduleURL={location.pathname} component={POList} />,
    },
    {
      path: `${paths.PROJECT_WORK_ORDER}/*`,
      element: <RBAC moduleURL={location.pathname} component={WOList} />,
    },
    {
      path: paths.PROJECT_BUDGET,
      element: <RBAC moduleURL={location.pathname} component={ProjectBudget} />,
    },
    {
      path: `${paths.PROJECT_BUDGET}/*`,
      element: <RBAC moduleURL={location.pathname} component={ProjectBudget} />,
    },
    {
      path: paths.PROJECT_INVOICES_CREATE,
      element: <RBAC moduleURL={location.pathname} component={PreviewBudget} />,
    },
    {
      path: paths.PROJECT_INVOICES_EDIT,
      element: <RBAC moduleURL={location.pathname} component={PreviewBudget} />,
    },
    {
      path: paths.RECEIVE_STOCK,
      element: <RBAC moduleURL={location.pathname} component={ReceiveStock} />,
    },
    {
      path: paths.RECEIVE_STOCK_VENDOR,
      element: <RBAC moduleURL={location.pathname} component={ReceiveStock} />,
    },
    {
      path: paths.RETURN_STOCK_VENDOR,
      element: <RBAC moduleURL={location.pathname} component={ReceiveStock} />,
    },
    {
      path: paths.RECEIVE_DE_STOCK,
      element: <RBAC moduleURL={location.pathname} component={ReceiveDEStock} />,
    },
    {
      path: paths.RECEIVE_RESERVED_STOCK,
      element: <RBAC moduleURL={location.pathname} component={ReceiveDEStock} />,
    },
  ]);

  return element;
}

class Root extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div className="clearfix" style={{ width: "100%" }}>
        <TransitionGroup>
          <CustomRoutes location={location} />
        </TransitionGroup>
      </div>
    );
  }
}
export default withRouter(Root);
