import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import _ from "lodash";
import AssetSubmit from "../../asset-management/asset-submit";
import { getAllocationHistory } from "../../../actions/asset-management.action";
import "../scss/project-management.scss";
import { withRouter } from "../../../util/with-router";
import { PROJECT_MANAGEMENT } from "../../../constants/route-paths";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { getProject } from "src/actions/project-management.action";
import idx from "idx";
import CustomTable from "src/components/table/table";
import Popover from 'react-bootstrap/Popover';

class AssetProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      columns: [],
      assets: [],
      assetId: "",
      menuItems: [
        {
          name: "Submit Asset",
          isDisabled: true,
        },
      ],
      projectId: "",
      submitDialog: false,
      projectData: null
    };
  }

  componentDidMount() {
    if (this.props.params.projectId) {
      this.getProjectAssets();
    }
    if (!this.state.projectData && this.props.params.projectId) {
      this.props.getProject(this.props.params.projectId).then((projectData) => {
        this.setState({
          projectData: projectData
        });
      })
    }
  }

  getProjectAssets = async () => {
    const projectId = this.props.params.projectId;

    const assets = (await this.props.getAllocationHistory("", projectId)) || [];

    const data = assets.map((asset) => {
      let assetNo = "",
        name = "",
        status = "",
        category = "",
        assetId = "";

      if (_.isObject(asset.assetData)) {
        assetNo = asset.assetData.assetNo;
        name = asset.assetData.name;
        category = asset.assetData.category;
        assetId = asset.assetData.id;
        if (asset.assetData && asset.assetData.allocationId === asset.id) {
          status = "In-Use";
        } else {
          status = "Returned";
        }
      }

      return {
        id: assetId,
        assetNo: assetNo,
        name: name,
        category: category,
        status: status,
        notes: asset.submitDescription || "-",
        allocationDate:
          ((asset.allocationDate &&
            format(new Date(asset.allocationDate), "dd/MM/yyyy") + " to ") ||
            "-") +
          ((asset.returnDate &&
            format(new Date(asset.returnDate), "dd/MM/yyyy")) ||
            "-"),
      };
    });

    const columns = [
      { title: "ID No", field: "assetNo", render: (rowData) => rowData.assetNo },
      { title: "Asset Name", field: "name", render: (rowData) => rowData.name },
      {
        title: "Asset Category",
        field: "category",
        lookup: {
          VEHICLE: "Vehicle",
          PLANT_AND_EQUIPMENT: "Plant & Equipment",
        },
        render: (rowData) => rowData.category == 'VEHICLE' ? 'Vehicle' : rowData.category == 'PLANT_AND_EQUIPMENT' ? 'Plant & Equipment' : rowData.category
      },
      {
        title: "Asset Status",
        field: "status",
        render: (rowData) => rowData.status
      },
      { title: "Notes", field: "notes", render: (rowData) => rowData.notes },
      {
        title: "Assigned-Returned Date",
        field: "allocationDate",
        render: (rowData) => rowData.allocationDate
      },
    ];
    this.handleMenuClose();
    this.setState({ assets: data, columns: columns, projectId });
  };

  handleMenu = (rowData) => {
    let menuItems = this.state.menuItems;

    if (rowData.status === "In-Use") {
      menuItems[0].isDisabled = false;
    }

    this.setState({
      assetId: rowData.id,
      menuItems,
    });
  };

  handleAddAsset = () => {
    const { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/asset/add/${projectId}`);
  };

  handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        this.setState({ submitDialog: true });
        break;
      default:
        break;
    }
  };

  handleMenuClose = () => {
    let menuItems = this.state.menuItems;
    for (var i = 0; i < menuItems.length; i++) {
      menuItems[i].isDisabled = true;
    }

    this.setState({ anchorEl: null, menuItems, submitDialog: false });
  };

  cancelHandler = () => {
    const { projectId } = this.state;
    this.props.history(`/projects/view/${projectId}`);
  };

  ViewProjectList = () => {
    let projectType = idx(this.state, _ => _.projectData.projectType)
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      columns,
      assets,
      menuItems,
      submitDialog,
      assetId,
    } = this.state;
    const writePermission = this.props.userAccessControl === 2 ? true : false;

    return (
      <>
        <AssetSubmit
          submitDialog={submitDialog}
          assetId={assetId}
          getAssetList={this.getProjectAssets}
          handleClose={this.handleMenuClose}
        />
        <div className="asset-management">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Assets</h2>
            <div className="col-12 breadcrumb-block p-0">
              <Breadcrumb>
                <Breadcrumb.Item onClick={this.ViewProjectList}>PROJECTS</Breadcrumb.Item>
                <Breadcrumb.Item onClick={this.ViewProject}>{idx(this.state, _ => _.projectData.name)}</Breadcrumb.Item>
                <Breadcrumb.Item active>Assets</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={assets}
            isAction={true}
            handleMenu={(data) => this.handleMenu(data)}
            menu={(data) => (
              <Popover id="popover-basic">
                <Popover.Body>
                  <ul className="action-block-list">
                    {Array.isArray(menuItems) && menuItems.map((menuItem, index) => {
                      if (menuItem.isDisabled) return;
                      return (
                        <li
                          key={index}
                          disabled={menuItem.isDisabled}
                          onClick={() => this.handleMenuItemClick(index)}
                        >
                          {menuItem.name}
                        </li>
                      )
                    })}
                  </ul>
                </Popover.Body>
              </Popover>
            )}
            fileName='Assets List'
          />

          <div className="col-12 d-flex mt-5">
            {writePermission && (<Button
              onClick={this.handleAddAsset}
              className="ms-auto me-3 secondarybtn"
            >
              Add Asset to Project
            </Button>)}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllocationHistory: bindActionCreators(getAllocationHistory, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetProjectList)
);
