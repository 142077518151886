import { isServer } from '../util'
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_MODULE_NAME,
  MOBILE_DRAWER,
  APP_VERSION
} from '../constants/action.constant'

const navigator = !isServer && window.navigator
const initialState = {
  showLoader: false,
  overlay: false,
  apiCount: 0,
  oCount: 0,
  loyalityPoints: 0,
  showMobileDrawer: false,
  generalInfo: {
    deviceInfo: navigator.platform,
    appVersion: '1.0'
  },
  redirectionData: {},
  subscribeNow: {
    email: ''
  },
  settings: {},
  countryData: [],
  ipCountryData: {},
  errorPage: {
    is404: false,
    is500: false,
    is503: false
  },
  notifications: [],
  moduleName: ''
}

const showLoader = state => {
  return ({
    ...state,
    showLoader: true,
    apiCount: state.apiCount + 1
  })
}

const hideLoader = state => {
  const data = {
    ...state,
    apiCount: state.apiCount - 1
  }
  if ((state.apiCount - 1) < 1) {
    data.showLoader = false
    data.apiCount = 0
  }
  return data
}

const showOverlay = state => {
  return ({
    ...state,
    overlay: true,
    oCount: state.oCount + 1
  })
}

const hideOverlay = state => {
  const data = {
    ...state,
    oCount: state.oCount - 1
  }
  if ((state.oCount - 1) < 1) {
    data.overlay = false
    data.oCount = 0
  }
  return data
}

const enqSnackbar = (state, action) => {
  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        key: action.key || action.notification.key,
        ...action.notification
      }
    ]
  }
}

const clseSnackbar = (state, action) => {
  return {
    ...state,
    notifications: state.notifications.map(notification => (
      (action.dismissAll || notification.key === action.key)
        ? { ...notification, dismissed: true }
        : { ...notification }
    ))
  }
}

const remSnackbar = (state, action) => {
  return {
    ...state,
    notifications: state.notifications.filter(
      notification => notification.key !== action.key
    )
  }
}

const setModuleName = (state, action) => {
  return {
    ...state,
    moduleName: action.name
  }
}

const showMobileDrawer = (state, action) => {
  return {
    ...state,
    showMobileDrawer: action.showMobileDrawer
  }
}

const appVersion = (initialState) =>{
  return  initialState.generalInfo.appVersion
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER: return showLoader(state)
    case HIDE_LOADER: return hideLoader(state)
    case SHOW_OVERLAY: return showOverlay(state)
    case HIDE_OVERLAY: return hideOverlay(state)
    case ENQUEUE_SNACKBAR: return enqSnackbar(state, action)
    case CLOSE_SNACKBAR: return clseSnackbar(state, action)
    case REMOVE_SNACKBAR: return remSnackbar(state, action)
    case SET_MODULE_NAME: return setModuleName(state, action)
    case MOBILE_DRAWER: return showMobileDrawer(state, action)
    case APP_VERSION: return appVersion(initialState)

    default: return state
  }
}

export default commonReducer
