import { cloneDeep, isString } from "lodash";
const { default: idx } = require("idx");

export const subTaskCalculation = ({ subtask, profitMargin, profitMarginType, isForm = false }) => {
    let subtask_total = {
        material: { cost: 0, sale: 0, profit: 0 },
        subcontractor: { cost: 0, sale: 0, profit: 0 },
        labor: {
            cost: 0,// Number(idx(subtask, _ => _.laborTotalCost) || 0),
            sale: 0,//Number(idx(subtask, _ => _.laborTotal_SaleAmount) || 0),
            profit: 0,// Number(idx(subtask, _ => _.laborProfit) || 0),
        },
        total_profit: 0,
        subtotal: 0,
        forecast: 0,
        total: 0
    };
    try {

        let materials = subtask.material || [];
        let subcontractors = subtask.subcontractor || [];
        let labors = subtask.labor || [];

        materials.map((material) => {
            let _material = isString(material) ? JSON.parse(material) : material;
            _material = cloneDeep(_material)
            let isLocked = _material["saleAmountLock"] === true || idx(_material, _ => _["saleAmountLock"].value) === true;
            let cost = (idx(_material, _ => _['costPerItem'].value) ?? idx(_material, _ => _["cost"])) || 0
            let qty = (idx(_material, _ => _['qty'].value) ?? idx(_material, _ => _["qty"])) || 1
            let saleAmount = (idx(_material, _ => _['saleAmount'].value) ?? idx(_material, _ => _["saleAmount"])) || 0;
            if (isLocked) {
                if (profitMarginType === "PERCENTAGE") {
                    saleAmount = Number(cost) * ((parseFloat(profitMargin || 0) / 100) + 1);
                } else if (profitMarginType === "FIXED") {
                    saleAmount = Number(cost) + parseFloat(profitMargin || 0);
                }
            }
            subtask_total.material.cost += Number(cost) * Number(qty);
            subtask_total.material.sale += Number(saleAmount) * Number(qty);
            return JSON.stringify(_material);
        });

        subcontractors.map((subcontractor) => {
            let _subcontractor = isString(subcontractor) ? JSON.parse(subcontractor) : subcontractor;
            _subcontractor = cloneDeep(_subcontractor)
            let isLocked = _subcontractor["saleAmountLock"] === true || idx(_subcontractor, _ => _["saleAmountLock"].value) === true;
            let cost = (idx(_subcontractor, _ => _['Cost'].value) ?? idx(_subcontractor, _ => _["Cost"])) || 0
            let saleAmount = (idx(_subcontractor, _ => _['saleAmount'].value) ?? idx(_subcontractor, _ => _["saleAmount"])) || 0;
            if (isLocked) {
                if (profitMarginType === "PERCENTAGE") {
                    saleAmount = Number(cost) * ((parseFloat(profitMargin || 0) / 100) + 1);
                } else if (profitMarginType === "FIXED") {
                    saleAmount = Number(cost) + parseFloat(profitMargin || 0);
                }
            }
            subtask_total.subcontractor.cost += Number(cost);
            subtask_total.subcontractor.sale += Number(saleAmount);
            return JSON.stringify(_subcontractor);
        });

        labors.map((labor) => {
            let _labor = isString(labor) ? JSON.parse(labor) : labor;
            _labor = cloneDeep(_labor)
            subtask_total.labor.cost += Number((isForm ? _labor['labourCost'].value : (_labor["averageCost"] /* ?? _labor["cost"] */)) || 0) * Number(isForm ? _labor['qty'].value : _labor["qty"]);
            subtask_total.labor.sale += Number((isForm ? _labor['costPerItem'].value : _labor["cost"]) || 0) * Number(isForm ? _labor['qty'].value : _labor["qty"]);
            return JSON.stringify(_labor);
        });

        subtask_total.material.profit = subtask_total.material.sale - subtask_total.material.cost;
        subtask_total.subcontractor.profit = subtask_total.subcontractor.sale - subtask_total.subcontractor.cost;
        subtask_total.labor.profit = subtask_total.labor.sale - subtask_total.labor.cost;
        subtask_total.total_profit = subtask_total.material.profit + subtask_total.subcontractor.profit + subtask_total.labor.profit;
        subtask_total.subtotal = subtask_total.material.cost + subtask_total.subcontractor.cost + subtask_total.labor.cost;
        subtask_total.total = subtask_total.material.sale + subtask_total.subcontractor.sale + subtask_total.labor.sale;

        subtask_total.forecast = parseFloat(subtask.materialForecastCost || 0) + parseFloat(subtask.laborForecastCost || 0) + parseFloat(subtask.subContractorForecastCost || 0);

        return subtask_total;
    } catch (e) {
        console.error(e)
        return subtask_total;
    }
};


// Calculation for history values
// totalHistoryCost.materialCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) => _task.taskHistory[0].materialTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.laborCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) => _task.taskHistory[0].laborTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.profitMarginCost +=
//     parseFloat(
//         idx(task, (_task) => _task.taskHistory[0].profitMarginCost)
//     ) || 0;
// totalHistoryCost.subContractorCost +=
//     parseFloat(
//         idx(
//             task,
//             (_task) =>
//                 _task.taskHistory[0].subcontractorTotal_SaleAmount
//         )
//     ) || 0;
// totalHistoryCost.total =
//     totalHistoryCost.materialCost +
//     totalHistoryCost.laborCost +
//     totalHistoryCost.subContractorCost;