import React from 'react'
import Modal from '../modal/modal'
import './../modal/modal.scss'

export default function DeleteModal(props) {
  const { deleteHandler, id, open, dialogClose, moduleName,deleteType } = props
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='sm'
      modalHeading={`Delete ${moduleName}`}
      modalSaveTxt='Yes'
      modalCloseTxt='No'
      onClick={() => deleteHandler(id,deleteType)}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <p>Are You Sure You Want to Delete {`${moduleName}`}? {(moduleName=='Task')?'The parent task and all of its related subtasks will be deleted':''} </p>
    </Modal>
  )
}
