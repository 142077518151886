import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    editGlobalSetting, deleteGlobalSetting, editProjectTypeSetting,
    getProjectCategory, UpdateProjectType
} from '../../actions/global-setting.action'
import _ from 'lodash'
import { errorSnackBar } from '../../actions/common.action'
import './scss/templates.scss'
import Modal from '../../components/modal/modal'
import {
    projectCategoryFields
} from '../../constants/fields.constant'
import { warningSnackBar } from '../../actions/common.action'
import CustomTable from '../../components/table/table'
import SelectInput from '../../components/inputs/select'
import CustomInput from '../../components/inputs/new-input'

class ProjectSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            fields: {
                ...JSON.parse(JSON.stringify(projectCategoryFields))
            },
            columns: [],
            typeId: null,
            anchorEl: null,
            selectedProjectType: null,
            projectCategories: [],
            viewType: '',
            deleteDialogopen: false,
            type: {
                name: 'type',
                label: 'Code',
                type: 'select',
                touched: false,
                error: false,
                valid: true,
                value: '',
                items: []
            }
        }
    }

    componentDidMount() {
        this.getProjectTypes()
    }

    handleValidation = ({ name, type, value }) => {
        let error = false, valid = true;
        const namePattern = /^[a-zA-Z0-9-_ ]+$/
        const codePattern = /^[a-zA-Z0-9-_]+$/
        if ((name === 'name' || name === 'type') && !value.split(' ').join('')) return { error: 'This Field is Required', valid: false }
        else if (name === 'name' && !namePattern.test(value)) return { error: `This Field accept only alphanumeric values.`, valid: false }
        else if (name === 'code' && !codePattern.test(value)) return { error: `This Field accept only alphanumeric values.`, valid: false }
        return { error, valid }
    }

    inputChangeHandler(e) {
        let fields = this.state.fields
        var name
        var value
        if (_.isObject(e)) {
            name = e.target.name
            value = e.target.value
        }
        fields[name].value = value
        fields[name].touched = true
        // const { error, valid } = this.handleValidation({ name, value })
        // fields[name].error = error
        // fields[name].valid = valid
        // let isFormValid = true
        // for (let key of Object.keys(this.state.fields)) {
        // isFormValid = isFormValid && !fields[key].error
        // }
        this.setState({ fields })
    }

    openDialog(id = '') {
        let viewType = 'Add'
        if (id) {
            viewType = 'Edit'
            this.setValuetoEditModal(id)
        }
        this.setState({ open: true, viewType: viewType, selectedProjectType: id })
    }
    handleAddDialog() {
        let viewType = 'Add'
        this.setState({ open: true, viewType: viewType })
    }

    dialogClose = () => {
        let { open } = this.state
        this.setState({ open: !open, selectedProjectType: null, fields: { ...JSON.parse(JSON.stringify(projectCategoryFields)) } })
        this.handleCloseAnchorEl()
    }

    submitForm = async () => {
        let { fields, projectCategories, viewType, selectedProjectType } = this.state
        let isFormValid = true
        for (let key in fields) {
            const { error, valid } = this.handleValidation(fields[key])
            fields[key].touched = true
            fields[key].valid = valid
            fields[key].error = error
            isFormValid = isFormValid && valid
        }

        if (!isFormValid) {
            this.setState({ fields })
        } else {
            let projectTypes = projectCategories.map((item) => {
                let { name, type } = item;
                let obj = {};
                if (viewType === 'Edit' && type === selectedProjectType) {
                    name = fields.name.value;
                    type = fields.type.value;
                }
                obj['name'] = name;
                obj['type'] = type;
                return obj;
            }) || [];
            if (viewType === 'Add') {
                let typeExits = projectCategories.find(item => item.type === fields.type.value)
                if (typeExits) {
                    this.props.warningSnackBar('The Project Type code already exits, please use a different code')
                    return false;
                }
                let data = {};
                data['name'] = fields.name.value;
                data['type'] = fields.type.value;
                projectTypes.push(data);
            }
            await this.props.editProjectTypeSetting(projectTypes, 'PROJECTCATEGORIES')
            this.getProjectTypes()
            this.dialogClose()
        }
    }

    handleMenu = (event, row) => {
        this.setState({ anchorEl: event.currentTarget, selectedProjectType: row.type });
    };

    handleCloseAnchorEl = () => {
        this.setState({ anchorEl: null, selectedProjectType: null })
    }

    getProjectTypes = async () => {
        await this.props.getProjectCategory('PROJECTCATEGORIES');
        let list = (Array.isArray(this.props.projectCategories) && this.props.projectCategories.map((item, index) => {
            item['id'] = index;
            return item;
        })) || [];
        let columns = [
            { title: 'Name', field: 'name' },
            { title: 'Code', field: 'type' }
        ]

        this.setState({ columns, projectCategories: list })
    }

    setValuetoEditModal(id) {
        let { fields, projectCategories } = this.state;
        let type = projectCategories.find(item => item.type === id)
        fields['name'].value = type.name;
        fields['type'].value = type.type;
        this.setState({ fields })
    }

    deleteDialogHandler(id = '') {
        let { type, projectCategories } = this.state
        type.items = projectCategories
            .filter((item) => item.type !== id)
            .map(v => ({
                key: v.name,
                value: v.type
            })) || [];
        this.setState({ deleteDialogopen: true, type, selectedProjectType: id })
    }

    deleteDialogClose = () => {
        let { deleteDialogopen, type } = this.state
        type.valid = true;
        type.value = '';
        this.setState({ deleteDialogopen: !deleteDialogopen, selectedProjectType: null, type })
        this.handleCloseAnchorEl()
    }

    deleteProjectType = async () => {
        let { projectCategories, type, selectedProjectType } = this.state
        if (!type.value) {
            type.touched = true
            type.valid = false
            type.error = 'This Field is Required'
            this.setState({ type })
        } else {
            let projectTypes = projectCategories
                .filter((item) => item.type !== selectedProjectType)
                .map((v) =>
                ({
                    name: v.name,
                    type: v.type
                })) || [];
            await this.props.UpdateProjectType({ projectType: type.value }, selectedProjectType)
            await this.props.editProjectTypeSetting(projectTypes, 'PROJECTCATEGORIES')
            this.getProjectTypes()
            this.deleteDialogClose()
        }
    }

    inputTypeChangeHandler(e) {
        let type = this.state.type
        var value
        if (_.isObject(e)) {
            value = e.target.value
        }
        type.value = value
        type.touched = true
        this.setState({ type })
    }


    render() {
        const { open, fields, columns, projectCategories, viewType, deleteDialogopen, type } = this.state

        return (
            <>
                <Modal
                    open={open}
                    onClose={this.dialogClose}
                    onClick={this.submitForm}
                    modalHeading={`${viewType} Project type`}
                    modalSaveTxt={'Save'}
                    modalCloseTxt='Close'
                    showActionBtn={true}
                    fullWidth={true}
                    maxWidth={'sm'}
                    cxlbtnClass='cancelBtn'
                    primary
                >
                    <div className='row mb-4'>
                        <div className="col-lg-6 mt-3">
                            <CustomInput
                                {...fields.name}
                                label={'Name'}
                                onChange={(e) => this.inputChangeHandler(e)}
                            />
                        </div>
                        <div className="col-lg-6 mt-3">
                            <CustomInput
                                {...fields.type}
                                label={'Code'}
                                disabled={viewType === 'Edit' ? true : false}
                                onChange={(e) => this.inputChangeHandler(e)}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={deleteDialogopen}
                    onClose={this.deleteDialogClose}
                    onClick={this.deleteProjectType}
                    modalHeading={`Delete Project type`}
                    modalSaveTxt={'Save'}
                    modalCloseTxt='Cancel'
                    showActionBtn={true}
                    fullWidth={true}
                    maxWidth={'sm'}
                    cxlbtnClass='cancelBtn'
                    primary
                >
                    <p>Are You Sure You Want to Delete this Project Type?</p>
                    <p>To do so please select the project type you would like to move Projects within this Type to. Then select Save. Otherwise select Cancel to exit.</p>
                    <div className="col-12 mt-3 mb-4">
                        <SelectInput
                            label={'Project Type'}
                            {...type}
                            onChange={(e) => this.inputTypeChangeHandler(e)}
                        />
                    </div>
                </Modal>
                <div className="purchase-order-block">
                    <div className="row">
                        <CustomTable
                            columns={columns}
                            data={projectCategories}
                            addButton={this.props.writePermission}
                            addHandler={() => this.handleAddDialog()}
                            isEdit={this.props.writePermission}
                            editHandler={(item) => this.openDialog(item.type)}
                            isDelete={this.props.writePermission}
                            deleteHandler={(item) => this.deleteDialogHandler(item.type)}
                            fileName='Project Categories List'
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    projectCategories: state.project.projectCategories
});

const mapDispatchToProps = dispatch => ({
    getProjectCategory: bindActionCreators(getProjectCategory, dispatch),
    errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
    editGlobalSetting: bindActionCreators(editGlobalSetting, dispatch),
    deleteGlobalSetting: bindActionCreators(deleteGlobalSetting, dispatch),
    editProjectTypeSetting: bindActionCreators(editProjectTypeSetting, dispatch),
    UpdateProjectType: bindActionCreators(UpdateProjectType, dispatch),
    warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ProjectSetting);
