import * as TemplateAPI from '../api/template-management.api'

export const getProjectTemplates = () => {
  return (dispatch, getState) => {
    return TemplateAPI.getProjectTemplatesAPI()
  }
}

export const addProjectTemplate = (data, id) => {
  return (dispatch, getState) => {
    return TemplateAPI.addProjectTemplateAPI(data, id)
  }
}

export const getTaskTemplates = (id) => {
  return (dispatch, getState) => {
    return TemplateAPI.getTaskTemplatesAPI(id)
  }
}

export const addTaskTemplate = (data) => {
  return (dispatch, getState) => {
    return TemplateAPI.addTaskTemplateAPI(data)
  }
}

export const editTaskTemplate = (id, data) => {
  return (dispatch, getState) => {
    return TemplateAPI.editTaskTemplateAPI(id, data)
  }
}

export const deleteTaskTemplate = (id) => {
  return (dispatch, getState) => {
    return TemplateAPI.deleteTaskTemplateAPI(id)
  }
}

export const getItemTemplates = (id) => {
  return (dispatch, getState) => {
    return TemplateAPI.getItemTemplatesAPI(id)
  }
}

export const addItemTemplate = (data) => {
  return (dispatch, getState) => {
    return TemplateAPI.addItemTemplateAPI(data)
  }
}

export const editItemTemplate = (id, data) => {
  return (dispatch, getState) => {
    return TemplateAPI.editItemTemplateAPI(id, data)
  }
}

export const deleteItemTemplate = (id) => {
  return (dispatch, getState) => {
    return TemplateAPI.deleteItemTemplateAPI(id)
  }
}