import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CONTRACTORS, ADD_CONTRACTOR } from "src/constants/route-paths";
import {
  getVendorList,
  changeVendorStatus,
  getVendorCount,
} from "src/actions/vendor-management.action";
import { Link } from "react-router-dom";
import { withRouter } from "src/util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import { useState } from "react";
import { useEffect } from "react";

function SubContractorsList({ writePermission, ...props }) {
  const [state, setState] = useState({
    active: [],
    inactive: [],
    statusType: "",
    subContractorCount: null,
    totalInactiveSubContractor: null,
    vendorData: {},
  })

  useEffect(() => {
    getSubContractorList();
    getVendorCount();
  }, [])

  const formatTableData = (vendors = []) => {
    const data = vendors.map((vendor) => {
      let primary = (vendor.contacts || []).find(item => item.primary == true) || {};
      return {
        vendorNumber: vendor.vendorNumber,
        businessName: vendor.businessName,
        id: vendor.id,
        address: vendor.address,
        contacts: [primary.firstName, primary.lastName].filter(item => item).join(' ') || '',
        email: vendor.email,
        phoneNumber: vendor.phoneNumber,
        type: vendor.type,
        isActive: vendor.isActive,
      };
    });
    return data;
  };

  const getVendorCount = async () => {
    const vendorCounts = await props.getVendorCount();
    setState(prev => ({
      ...prev,
      ...vendorCounts
    }));
  };

  const getSubContractorList = (statusType = "ACTIVE") => {
    props.getVendorList('sub-contractor', statusType).then((vendors) => {
      const data = formatTableData(vendors);

      const columns = [
        { title: "Vendor Number", field: "vendorNumber" },
        {
          title: "Company Name",
          field: "businessName",
          render: (rowData) => (
            <Link to={`/${CONTRACTORS}/edit/${rowData.id}`}>
              {rowData.businessName}
            </Link>
          ),
        },
        { title: "Address", field: "address" },
        { title: "Phone Number", field: "phoneNumber" },
        { title: "Contact", field: "contacts" },
        { title: "Email", field: "email" },
        {
          title: "Active",
          field: "isActive",
          lookup: { true: "ACTIVE", false: "INACTIVE" },
          render: (rowData) => (
            <div className="form-check form-switch">
              <input
                className={`form-check-input switch-checkbox ${rowData.isActive ? '' : 'inactive'}`}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                disabled={writePermission}
                value={rowData.isActive ? true : false}
                checked={rowData.isActive ? true : false}
                onChange={(e) => handleStatusChange(rowData.id)}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
            </div>
          ),
        },
      ];
      setState(prev => ({
        ...prev,
        columns: columns,
        [statusType.toLowerCase()]: data,
        statusType,
      }));
    });
  };

  const addVendor = () => {
    props.history(`/${CONTRACTORS}/${ADD_CONTRACTOR}`);
  };

  const editVendor = (id) => {
    props.history(`/${CONTRACTORS}/edit/${id}`);
  };

  const handleStatusChange = (id) => {
    props.changeVendorStatus(id).then((res) => {
      getSubContractorList();
      getSubContractorList('INACTIVE');
      getVendorCount();
    });
  };

  return (
    <>
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Contractors</h2>
          </div>
          {writePermission && (<div className="w-auto ms-auto mt-auto">
            <button onClick={addVendor} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Add Contractor</button>
          </div>)}
        </div>

        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="active"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(k) => {
                switch (k) {
                  case 'active': { if (!state.active.length) getSubContractorList(); break; }
                  case 'inactive': { if (!state.inactive.length) getSubContractorList('INACTIVE'); break; }
                  default: break;
                }
              }}
            >
              <Tab eventKey="active" title={`Active (${state.subContractorCount || 0})`}>
                <CustomTable
                  columns={state.columns}
                  data={state.active}
                  isEdit={writePermission}
                  editHandler={(item) => editVendor(item.id)}
                  fileName='Contractors List'
                />
              </Tab>
              <Tab eventKey="inactive" title={`Inactive (${state.totalInactiveSubContractor || 0})`}>
                <CustomTable
                  columns={state.columns}
                  data={state.inactive}
                  isEdit={writePermission}
                  editHandler={(item) => editVendor(item.id)}
                  fileName='Inactive Contractors List'
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );

}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getVendorList: bindActionCreators(getVendorList, dispatch),
  changeVendorStatus: bindActionCreators(changeVendorStatus, dispatch),
  getVendorCount: bindActionCreators(getVendorCount, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubContractorsList)
);
