import React from 'react'
import { PROJECT_MANAGEMENT } from './../../../constants/route-paths'
import idx from 'idx'
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ViewPurchaseOrderComponent from 'src/components/po/view';
import { Button } from 'react-bootstrap';

function ViewPurchaseOrder(props) {

  const { projectId, poId } = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { projectDetail = {} } = useSelector(state => state)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)


  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  const viewProject = () => navigate(`/${PROJECT_MANAGEMENT}/view/${projectId}`)
  const viewPoList = () => navigate(`/${PROJECT_MANAGEMENT}/purchase-order/${projectId}`);

  return (
    <div className="purchase-order-block">

      <div className="purchase-order-block">
        <h2 className="content-heading text-uppercase">View Purchase Order</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewProject}>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={viewPoList} >Purchase Order LIST</Breadcrumb.Item>
            <Breadcrumb.Item active>Purchase Order</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <ViewPurchaseOrderComponent
          poId={poId}
        />

        <div className="col-12 d-flex mt-3 justify-content-end">
          <Button type="submit" onClick={viewPoList} className="secondarybtn cancelbtn">CANCEL</Button>
        </div>
      </div>
    </div>
  )
}

export default ViewPurchaseOrder
