import _ from "lodash";
import { laborObjFields } from "src/constants/fields.constant";

export const laborToForm = (_labor, { qty = 1, filterLabor }) => {
    if (!_.isObject(_labor)) _labor = JSON.parse(_labor);
    const selectedLabor = filterLabor ? filterLabor(_labor.id) : {};
    const labor = _.cloneDeep(laborObjFields)
    labor.name.value = _labor.name;
    labor.id.value = _labor.id;
    labor.skillDescription.value = _labor.skillDescription || '';
    labor.costPerItem.value = _labor.cost || 0;
    labor.calloutCost.value = parseFloat(_labor.calloutCost || 0);
    labor.labourCost.value = parseFloat(selectedLabor.averageCost || _labor.averageCost || 0);
    labor.travelCost.value = 0;
    labor.labor_cost = selectedLabor.averageCost || _labor.averageCost;
    labor.labor_profit = 0;
    labor.qty.value = Number(_labor.qty || 1) * qty;
    labor.total.value = parseFloat(labor.qty.value) * parseFloat(labor.costPerItem.value);

    return labor
}
