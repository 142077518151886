import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CATALOGS, RESOURCES } from "src/constants/route-paths";
import {
  getCategories,
  addLabor,
  editLabor,
  getLabor,
  getLevels,
  getLaborList,
} from "src/actions/inventory.action";
import { withRouter } from "src/util/with-router";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import _ from 'lodash';
import TextAreaInput from "src/components/inputs/textarea-input";
import { AmountInput } from "src/components/inputs/new-input";
import { addResourcesAction } from "src/slice/resources";

function AddLabor({ writePermission, ...props }) {

  const [fields, setFields] = useState({
    skill: {
      name: "skill",
      label: "Resource",
      type: "textarea",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
    cost: {
      name: "cost",
      label: "Average Cost / Hour",
      type: "number",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
  })
  const [state, setState] = useState({
    levels: [],
    hourlyCost: [],
    levelsError: "",
  })

  useEffect(() => {
    if (props.match && props.params.laborId) {
      props.getLabor(props.params.laborId).then((labor) => {
        editLabor(labor);
      });
    }
  }, [props.params?.laborId])

  const editLabor = (item) => {
    setState(prev => ({
      ...prev,
      edit: true,
      itemId: item && item.id,
    }));
    setFields(prev => ({
      ...prev,
      skill: { ...prev.skill, value: item?.skill || '' },
      cost: { ...prev.cost, value: item?.cost || '' },
    }));
  };

  const inputChangeHandler = (e) => {
    const { error, valid } = handleValidation(e.target, fields[e.target.name].validation);
    setFields(prev => ({
      ...prev,
      [e.target.name]: {
        ...prev[e.target.name],
        value: e.target.value,
        touched: true,
        error: error,
        valid: valid
      },
    }));
  };

  const handleValidation = ({ name, type, value }, validation = []) => {
    let error = false;
    let valid = true;
    if (validation.includes("required") && (value === undefined || value === "")) {
      return { error: "This Field is Required", valid: false };
    }
    return { error, valid };
  };

  const cancelHandler = () => {
    props.history(`/${CATALOGS}/${RESOURCES}`);
  };

  const submitForm = () => {
    const { itemId } = state;
    let _fields = _.cloneDeep(fields)
    let isFormValid = true;
    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key], _fields[key].validation);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    if (!isFormValid) {
      setFields(_fields)
    } else {
      const data = {
        skill: _fields.skill.value,
        cost: _fields.cost.value,
      };
      if (!itemId) {
        props.addLabor(data).then((res) => {
          getLaborList()
          cancelHandler()
        }).catch(e => console.error(e));
      } else {
        props.editLabor(data, itemId).then((res) => {
          getLaborList()
          cancelHandler()
        }).catch(e => console.error(e));
      }
    }
  };

  const getLaborList = () => {
    props.getLaborList().then((data) => {
      let labors = (data || []).map((labor, i) => {
        labor.sno = i + 1;
        return labor;
      })
      props.addResourcesAction(labors);
    }).catch(e => console.error(e))
  };

  return (
    <div className="purchase-order-block">

      <h2 className="content-heading text-uppercase">{state.edit ? "Edit Resource" : "Add a Resource"}</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>Resources</Breadcrumb.Item>
          <Breadcrumb.Item active>{state.edit ? "Edit Resource" : "Add a Resource"}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">

        <div className="col-lg-12">
          <div className="mt-3">
            <TextAreaInput
              {...fields.skill}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mt-3">
            <AmountInput
              {...fields.cost}
              onChange={(e) => {
                if (e.target.value >= 0)
                  inputChangeHandler(e)
              }}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end gap-3 mt-3">
          {writePermission && (<Button onClick={submitForm} className="secondarybtn">DONE</Button>)}
          <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLabor: bindActionCreators(addLabor, dispatch),
  getLabor: bindActionCreators(getLabor, dispatch),
  editLabor: bindActionCreators(editLabor, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  getLevels: bindActionCreators(getLevels, dispatch),
  getLaborList: bindActionCreators(getLaborList, dispatch),
  addResourcesAction: bindActionCreators(addResourcesAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLabor));
