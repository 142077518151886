import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/inventory.scss";
import { INVENTORY } from "./../../constants/route-paths";
import {
  getPOList,
  updatePOStock,
  getDePOList,
  getPoVendors,
} from "./../../actions/project-management.action";
import {
  getServicesList,
  getPoVendors as getServicePOVendors,
  getPOList as getServicePOList,
} from "./../../actions/service.action";
import {
  getCategories,
  getItem,
  addNoPoStock,
  getTransactionsList,
} from "./../../actions/inventory.action";
import { getAssetList } from "./../../actions/asset-management.action";
import { getProjectList } from "./../../actions/project-management.action";
import { warningSnackBar } from "./../../actions/common.action";
import _ from "lodash";
import idx from "idx";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import SelectInput from "src/components/inputs/select";
import CustomInput from "src/components/inputs/new-input";

function AllocateItem(props) {

  const [state, setState] = useState({
    POList: [],
    items: [],
    categories: [],
    poId: "",
    itemId: "",
    vehicleStock: 0,
    warehouseStock: 0,
    siteStock: 0,
    vehicles: [],
    transactions: [],
    is_de_po: false,
  })
  const [fields, setFields] = useState({
    type: {
      name: "type",
      label: "Project",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "project",
      items: [
        { key: "Project", value: "project" },
        { key: "Service", value: "service" },
      ],
      disabled: true,
      validation: [],
    },
    dePurchaseOrder: {
      name: "dePurchaseOrder",
      label: "Select Internal PO",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
    project: {
      name: "project",
      label: "Select Project",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
    vendor: {
      name: "vendor",
      label: "Select Vendor",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
    location: {
      name: "location",
      label: "Allocate To",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "Warehouse", value: "WAREHOUSE" },
        { key: "Vehicle", value: "VEHICLE" },
        { key: "Site", value: "SITE" },
      ],
      validation: ["required"],
    },
    storedLocation: {
      name: "storedLocation",
      label: "Stored Location",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    vehicleNo: {
      name: "vehicleNo",
      label: "Vehicle No",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    reveivedFromVehicle: {
      name: "reveivedFromVehicle",
      label: "Vehicle No",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    quantity: {
      name: "quantity",
      label: "Quantity",
      type: "number",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required", "number"],
    },
    po: {
      name: "po",
      label: "Select PO",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "No PO", value: "NO_PO" },
        { key: "Internal PO", value: "DE_PO" },
      ],
      validation: ["required"],
    },
  })

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    const itemId = props.params.itemId;
    setState(prev => ({
      ...prev,
      itemId,
    }));
    getItem(itemId);

    props.getAssetList("VEHICLE").then((assets) => {
      const data = [];
      assets && assets.filter((asset) => asset.status !== "DISCARD").forEach((asset) => {
        if (asset.assetAllocation) {
          data.push({
            key: asset.assetNo,
            value: asset.id,
            stock: 0,
            ...asset,
          });
        }
      });
      setFields(prev => ({
        ...prev,
        vehicleNo: { ...prev.vehicleNo, items: data },
      }));
      setState(prev => ({
        ...prev,
        vehicles: data
      }));
    });
    typeChangeHandler({ target: { value: 'project' } })
  }

  const getItem = (itemId) => {
    props.getItem(itemId).then((item) => {
      setState(prev => ({
        ...prev,
        itemName: item.item,
        itemStock: item.no_po_stock,
      }));
    });
  };

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const { error, valid } = handleValidation(e.target, fields[name].validation);

    let vNoValidation = []
    if (name === "location" && value === "VEHICLE") {
      vNoValidation = ["required"];
    } else if (name === "po") {
      const selectedPO = fields.po.items.find((po) => po.value === value);
      if (selectedPO) {
        setState(prev => ({
          ...prev,
          items: selectedPO.items,
        }));
      }
    } else if (name === "dePurchaseOrder") {
      const selectedPO = fields.dePurchaseOrder.items.find((po) => po.value === value);
      if (selectedPO) {
        setState(prev => ({
          ...prev,
          items: selectedPO.items,
        }));
      }
    }

    setFields(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
        touched: true,
        error: error,
        valid: valid,
      },
      ...(name === "location" ? { vehicleNo: { ...prev.vehicleNo, validation: vNoValidation } } : {}),
    }));
  };

  const handleValidation = ({ name, type, value }, validation) => {
    let error = false;
    let valid = true;

    if (
      validation.includes("required") && (value === undefined ||
        (!_.isBoolean(value) && !_.isNumber(value) && value.trim() === ""))
    ) {
      return { error: "This Field is Required", valid: false };
    }

    if (validation.includes("number")) {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0)
        return { error: "Please Enter correct value", valid: false };
    }

    if (name === "quantity") {
      const { warehouseStock, siteStock } = state;
      if (fields.storedLocation.value === "WAREHOUSE") {
        if (warehouseStock < parseFloat(value))
          return { error: "Quantity can not be greater than available stock", valid: false, };
      }
      if (fields.storedLocation.value === "VEHICLE") {
        const vehicle = fields.reveivedFromVehicle.items.find((v) => v.value === fields.reveivedFromVehicle.value);
        let stock = 0;
        if (vehicle && vehicle.stock)
          stock = vehicle.stock;

        if (stock < parseFloat(value))
          return { error: "Quantity can not be greater than available stock", valid: false, };

      }
      if (fields.storedLocation.value === "SITE") {
        if (siteStock < parseFloat(value))
          return { error: "Quantity can not be greater than available stock", valid: false, };
      }
    }

    return { error, valid };
  };

  const projectChangeHandler = async (e) => {
    inputChangeHandler(e);
    let vendorsList = await props.getPoVendors(e.target.value, "Supplier");

    const vendors = [];
    Array.isArray(vendorsList) && vendorsList.forEach((vendor) => {
      const isVendorSelected = vendors.find(({ value }) => value === vendor.vendorId);
      if (!isVendorSelected) {
        vendors.push({
          key: idx(vendor, (_) => _.vendor.business_name) || "",
          value: idx(vendor, (_) => _.vendor.id) || "",
        });
      }
    });

    setFields(prev => ({
      ...prev,
      vendor: { ...prev.vendor, items: vendors, value: '' },
    }));
  };

  const vendorChangeHandler = async (e) => {
    inputChangeHandler(e);
    let poList = await props.getPOList({
      vendorId: e.target.value,
      projectId: idx(fields, (_fields) => _fields.project.value),
      itemId: state.itemId
    });

    const POs = [
      { key: "No PO", value: "NO_PO" },
      { key: "Internal PO", value: "DE_PO" },
    ];

    Array.isArray(poList) && poList.forEach((po) => {
      POs.push({
        key: po.id,
        value: po.u_id,
        items: po.items,
      });
    });

    setFields(prev => ({
      ...prev,
      po: { ...prev.po, items: POs, value: '' },
      location: { ...prev.location, value: '' },
    }));
  };

  const cancelHandler = () => {
    props.history(`/${INVENTORY}`);
  };

  const getDePOList = async () => {
    const dePOList = await props.getDePOList({ itemId: state.itemId });
    setFields(prev => ({
      ...prev,
      dePurchaseOrder: {
        ...prev.dePurchaseOrder,
        items: dePOList.map(({ id, items }) => ({
          key: id,
          value: id,
          items,
        })),
        validation: []
      },
    }));
  };

  const poChangeHandler = async (e, dePoChange = false) => {
    inputChangeHandler(e);

    let value = e.target.value
    let { itemId } = state;
    if (value === "DE_PO") {
      setState(prev => ({
        ...prev,
        is_de_po: true
      }));
      getDePOList();
      return;
    } else if (!dePoChange) {
      setState(prev => ({
        ...prev,
        is_de_po: false
      }));
    }

    let vehicleStock = 0;
    let warehouseStock = 0;
    let siteStock = 0;
    let vehicleStockData = {};

    if (value === "NO_PO") {
      const { itemStock } = state;
      const stock = itemStock || {};
      warehouseStock += parseFloat(stock.warehouse || 0);
      siteStock += parseFloat(stock.site || 0);
      if (_.isObject(stock.vehicle)) {
        vehicleStock = Object.keys(stock.vehicle).reduce((sum, key) => {
          sum += stock.vehicle[key];
          vehicleStockData[key] = parseFloat(vehicleStockData[key] || 0) + parseFloat(stock.vehicle[key] || 0);
          return sum;
        }, 0);
      }
    } else {
      let selectedPo;
      if (fields.po.value === "DE_PO") {
        selectedPo = Array.isArray(fields.dePurchaseOrder.items) &&
          fields.dePurchaseOrder.items.find((po) => po.value === e.target.value);
      } else {
        selectedPo = Array.isArray(fields.po.items) &&
          fields.po.items.find((po) => po.value === e.target.value);
      }

      if (!selectedPo) {
        props.warningSnackBar("Not Selected PO Item");
        return;
      }

      const filteredItems = (Array.isArray(selectedPo.items) &&
        selectedPo.items.filter(({ id }) => id === itemId)) || [];

      Array.isArray(filteredItems) && filteredItems.map((item) => {
        const stock = idx(item, (_) => _.stock) || {};
        warehouseStock += parseFloat(stock.warehouse || 0);
        siteStock += parseFloat(stock.site || 0);

        if (_.isObject(stock.vehicle)) {
          vehicleStock = Object.keys(stock.vehicle).reduce((sum, key) => {
            sum += stock.vehicle[key];
            vehicleStockData[key] = parseFloat(vehicleStockData[key] || 0) + parseFloat(stock.vehicle[key] || 0);
            return sum;
          }, 0);
        }
      });
    }

    const storedLocationOptions = [];
    if (siteStock > 0) {
      storedLocationOptions.push({
        key: `Site (${siteStock})`,
        value: "SITE",
      });
    }
    if (warehouseStock > 0) {
      storedLocationOptions.push({
        key: `WareHouse (${warehouseStock})`,
        value: "WAREHOUSE",
      });
    }
    if (vehicleStock > 0) {
      storedLocationOptions.push({
        key: `Vehicle (${vehicleStock})`,
        value: "VEHICLE",
      });
    }

    setState(prev => ({
      ...prev,
      siteStock,
      vehicleStock,
      warehouseStock,
      vehicleStockData,
    }));

    setFields(prev => ({
      ...prev,
      storedLocation: { ...prev.storedLocation, items: storedLocationOptions },
    }));
  };

  const storedLocationChange = (e) => {
    inputChangeHandler(e);

    let value = e.target.value
    const { vehicles, vehicleStockData } = state;
    if (value === "VEHICLE") {
      Array.isArray(vehicles) && vehicles.forEach((v) => {
        v.stock = (vehicleStockData && parseFloat(vehicleStockData[v.id] || 0)) || 0;
      });
      const _vehicles = [];
      Array.isArray(vehicles) && vehicles.forEach((vehicle) => {
        if (vehicle.stock > 0) {
          _vehicles.push({
            ...vehicle,
            key: `${vehicle.key} (${vehicle.stock})`,
          });
        }
      });
      setFields(prev => ({
        ...prev,
        reveivedFromVehicle: { ...prev.reveivedFromVehicle, items: _vehicles },
      }));
    } else {
      setFields(prev => ({
        ...prev,
        reveivedFromVehicle: { ...prev.reveivedFromVehicle, validation: [] },
      }));
    }
  };

  const typeChangeHandler = async (e) => {
    const value = e.target.value;
    setState(prev => ({
      ...prev,
      type: value
    }));
    let projectsList = await props.getProjectList(null, "PROJECT");

    let projects = (Array.isArray(projectsList) && projectsList.map((project) => {
      return {
        key: project.name,
        value: project.id,
      };
    })) || [];

    projects = _.orderBy(projects, [({ key }) => key.toLowerCase()], ["asc"]);

    setFields(prev => ({
      ...prev,
      project: { ...prev.project, items: projects, value: '' },
      vendor: { ...prev.vendor, value: '' },
    }));
  };

  const submitForm = () => {
    const { items, itemId } = state;
    let _fields = _.cloneDeep(fields)
    let isFormValid = true;
    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key], _fields[key].validation);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;

      isFormValid = isFormValid && valid;
    }
    if (!isFormValid) {
      setFields(_fields)
    } else {
      let poId = _fields.po.value;
      const { user } = props;
      const data = {
        allocationLocation: _fields.location.value,
        poId: "" + poId,
        transactionType: "ALLOCATED",
        receivedFrom: _fields.storedLocation.value,
      };

      if (_fields.storedLocation.value === "VEHICLE") {
        data.receivedFromAsset = _fields.reveivedFromVehicle.value;
      }

      if (_fields.location.value === "VEHICLE") {
        const assetData = _fields.vehicleNo.items.find((vehicle) => vehicle.id === _fields.vehicleNo.value);
        data.allocateToName = assetData.assetAllocation.allocationName;
        data.allocateToId = assetData.assetAllocation.id;
        data.assetId = assetData.id;
      } else if (_fields.location.value === "SITE") {
        const selectedProject = _fields.project.items.find((site) => site.value === _fields.project.value);
        if (!selectedProject) {
          props.warningSnackBar("Please Select Project for Delivery Location");
          return;
        }
        data.allocateToName = selectedProject.key;
        data.allocateToId = selectedProject.value;
      } else {
        data.allocateToName = user.name;
        data.allocateToId = user.id;
      }

      if (poId === "NO_PO") {
        const items = [
          {
            id: itemId,
            newStock: -Math.abs(parseFloat(_fields.quantity.value)),
          },
        ];
        data.items = items;
        props.addNoPoStock(data).then((res) => {
          cancelHandler();
        });
      } else {
        Array.isArray(items) && items.forEach((item) => {
          if (item.id === itemId) {
            let allocatedQty = parseFloat(_fields.quantity.value);
            if (!isNaN(parseFloat(item.allocatedQty))) {
              allocatedQty += item.allocatedQty;
            }
            item.allocatedQty = allocatedQty;
            item.newStock = -Math.abs(parseFloat(_fields.quantity.value));
          }
        });

        if (poId === "DE_PO") {
          poId = "" + idx(_fields, (_) => _fields.dePurchaseOrder.value) || "";
          data.poId = poId;
        }
        data.items = items;
        props.updatePOStock(data, poId).then((res) => {
          cancelHandler();
        });
      }
    }
  };

  return (
    <div className="purchase-order-block">

      <h2 className="content-heading text-uppercase">{state.itemName}</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>INVENTORY</Breadcrumb.Item>
          <Breadcrumb.Item active>Allocate Item</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">

        {/* <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.type}
            label={'Type'}
          />
        </div> */}
        {state.type && (<>
          <div className="col-lg-4 mt-3">
            <SelectInput
              {...fields.project}
              label={'Select Project'}
              onChange={(e) => projectChangeHandler(e)}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <SelectInput
              {...fields.vendor}
              label={'Select Vendor'}
              onChange={(e) => vendorChangeHandler(e)}
            />
          </div>
        </>)}
        <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.po}
            label={'Select PO'}
            onChange={(e) => poChangeHandler(e)}
          />
        </div>
        {state.is_de_po && (<div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.dePurchaseOrder}
            label={'Select Internal PO'}
            onChange={(e) => poChangeHandler(e, true)}
          />
        </div>)}
        <div className="col-lg-4 mt-3">
          <SelectInput
            onChange={(e) => storedLocationChange(e)}
            {...fields.storedLocation}
            label={'Select Stored Location'}
          />
        </div>
        {fields.storedLocation.value === "VEHICLE" && (<div className="col-lg-4 mt-3">
          <SelectInput
            onChange={(e) => inputChangeHandler(e)}
            {...fields.reveivedFromVehicle}
          />
        </div>)}
        <div className="col-lg-4 mt-3">
          <SelectInput
            onChange={(e) => inputChangeHandler(e)}
            {...fields.location}
            label={'Allocate To'}
          />
        </div>
        {fields.location.value === "VEHICLE" && (<div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.vehicleNo}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>)}
        <div className="col-lg-4 mt-3">
          <CustomInput
            {...fields.quantity}
            label={'Quantity'}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>
      </div>
      {props.writePermission && (<div className="col-12 d-flex justify-content-end gap-3 mt-3">
        <Button onClick={submitForm} className="secondarybtn">DONE</Button>
        <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>)}
    </div>
  );

}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getPOList: bindActionCreators(getPOList, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  getItem: bindActionCreators(getItem, dispatch),
  addNoPoStock: bindActionCreators(addNoPoStock, dispatch),
  updatePOStock: bindActionCreators(updatePOStock, dispatch),
  getAssetList: bindActionCreators(getAssetList, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getTransactionsList: bindActionCreators(getTransactionsList, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
  getPoVendors: bindActionCreators(getPoVendors, dispatch),
  getServicePOVendors: bindActionCreators(getServicePOVendors, dispatch),
  getDePOList: bindActionCreators(getDePOList, dispatch),
  getServicePOList: bindActionCreators(getServicePOList, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllocateItem)
);
