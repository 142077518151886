export const AddProjectFields = {
    profitMarginValue: {
        name: "profitMarginValue",
        label: "Profit Margin %",
        type: "number",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    projectName: {
        name: "projectName",
        label: "Project Name",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["required"],
    },
    address: {
        name: "address",
        label: "Address",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["required"],
    },
    manager: {
        name: "manager",
        label: "Project Manager",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["skip", "required"],
    },
    customer: {
        name: "customer",
        label: "Customer",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["required"],
    },
    contact: {
        name: "contact",
        label: "Customer Contact",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["required"],
    },
    date: {
        name: "date",
        label: "Created(Date)",
        type: "datepicker",
        touched: false,
        error: false,
        valid: true,
        value: new Date(),
        validation: ["required"],
    },
    startDate: {
        name: "startDate",
        label: "Project Start Date",
        type: "datepicker",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: ["skip", "required"],
    },
    budget: {
        name: "budget",
        label: "Budget",
        type: "number",
        touched: false,
        error: false,
        valid: true,
        disable: false,
        value: "",
        validation: ["skip", "required"],
    },
    level: {
        name: "level",
        label: "Cost/Sales Level",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["required"],
    },
    category: {
        name: "category",
        label: "Project Category",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "",
        items: [],
        validation: ["required"],
    },
    projectQuoteType: {
        name: "projectQuoteType",
        label: "Project Type",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        items: [
            { value: "quote", key: "Quote" },
            { value: "noquote", key: "No Quote" },
        ],
        validation: ["required"],
    },
    projectType: {
        name: "projectType",
        label: "Project Type",
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "PROJECT",
        items: [
            { value: "PROJECT", key: "Project" },
            { value: "Services", key: "Services" },
        ],
        validation: ["required"],
    },
    description: {
        name: "description",
        label: "Description(optional)",
        type: "textarea",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [],
    },
    exclusions: {
        name: "exclusions",
        label: "Exclusions(optional)",
        type: "textarea",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [],
    },
    attachments: {
        name: "attachments",
        label: "Attachments",
        type: "file",
        touched: false,
        error: false,
        valid: true,
        multiple: true,
        value: [],
        attachmentArr: [],
        validation: [],
    }
}