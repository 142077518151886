import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/po.scss";
import { warningSnackBar } from "src/actions/common.action";
import { PURCHASE_ORDER, SUPPLIERS } from "src/constants/route-paths";
import {
  getProjectList,
  savePurchaseOrder,
  editPurchaseOrder,
  getProject,
  getPurchaseOrder,
} from "src/actions/project-management.action";
import { getVendorList } from "src/actions/vendor-management.action";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from "react-router";
import CreatePOComponent from 'src/components/po/create';
import { history } from "src/util/history";

function CreatePurchaseOrder(props) {

  const { poId } = useParams();

  const cancelHandler = () => {
    history.navigate(`/${SUPPLIERS}/${PURCHASE_ORDER}`);
  };

  return (
    <>
      <CreatePOComponent
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item onClick={cancelHandler}>PO</Breadcrumb.Item>
            <Breadcrumb.Item active>{poId ? "Edit PO" : "Create PO"}</Breadcrumb.Item>
          </Breadcrumb>
        }
        cancelHandler={cancelHandler}
        warningSnackBar={props.warningSnackBar}
        getProjectList={props.getProjectList}
        savePurchaseOrder={props.savePurchaseOrder}
        editPurchaseOrder={props.editPurchaseOrder}
        getVendorList={props.getVendorList}
        getProject={props.getProject}
        getPurchaseOrder={props.getPurchaseOrder}
      />
    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  savePurchaseOrder: bindActionCreators(savePurchaseOrder, dispatch),
  editPurchaseOrder: bindActionCreators(editPurchaseOrder, dispatch),
  getVendorList: bindActionCreators(getVendorList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrder);
