import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { format } from 'date-fns'
import _ from 'lodash'
import idx from 'idx'
import moment from 'moment'
import { getTimesheet } from '../../actions/project-management.action'
import Modal from '../../components/modal/modal'
import Grid from '@material-ui/core/Grid'
import './scss/user-management.scss'
import CustomTable from 'src/components/table/table'

class UserTimesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            allocationId: '',
            allocations: [],
            columns: []
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.userData, this.props.userData)) {
            this.setState({ userData: this.props.userData })
        }
        if (!_.isEqual(prevProps.open, this.props.open) && this.props.open) {
            this.getAllocationData(this.props.userData)
        }
    }

    getAllocationData = async (userData) => {
        const allocationId = idx(userData, _userData => _userData.allocationId)
        const timesheetArray = allocationId && await this.props.getTimesheet('', allocationId)
        const timesheetData = timesheetArray[0]

        const data = (timesheetData && Array.isArray(timesheetData.timesheetAttendance) &&
            timesheetData.timesheetAttendance.map(allocation => {
                return ({
                    id: timesheetData.id,
                    date: format(new Date(allocation.reportingDate), 'EEEE dd/MM/yyyy') || '-',
                    project: (timesheetData && timesheetData.project && timesheetData.project.name) || '-',
                    billing: '-',
                    arrivalTime: allocation.inTime,
                    departureTime: allocation.outTime,
                    onLeave: allocation.onLeave,
                    breakTime: allocation.breakTime,
                })
            })) || []

        const columns = [
            { title: 'Date', field: 'date' },
            { title: 'Allocated Project', field: 'project' },
            {
                title: 'Hourly Billing', field: 'billing',
                render: rowData => {
                    const { arrivalTime, departureTime, breakTime, onLeave } = rowData

                    if (onLeave) {
                        return 'On-Leave'
                    } else if (arrivalTime && departureTime) {
                        let arrival = moment(arrivalTime), out = moment(departureTime)
                        let diff = moment.duration(out.diff(arrival)).subtract(breakTime, 'minutes')
                        return diff.asHours() && diff.asHours().toFixed(2)
                    }
                    return 0
                }
            },
        ]
        this.setState({ allocations: data, columns: columns })
    }

    handleClose = () => {
        this.props.handleClose()
    }

    render() {
        const { open } = this.props
        const { columns, allocations, userData } = this.state

        return (
            <Modal
                open={open}
                onClose={this.handleClose}
                onClick={this.submitForm}
                modalHeading={'User Allocation History'}
                modalCloseTxt='Done'
                showActionBtn={true}
                fullWidth={true}
                maxWidth={'md'}
                cxlbtnClass='cancelBtn'
                primary
            >
                <div className='tableContainer'>
                    <Grid container>
                        <Grid container>
                            <Grid xs={2} item>
                                <p><b>User Name: </b></p>
                            </Grid>
                            <Grid xs={7} item>
                                <p>{userData.name}</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={2} item>
                                <p><b>User Role: </b></p>
                            </Grid>
                            <Grid xs={7} item>
                                <p>{userData.role}</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={2} item>
                                <p><b>Project: </b></p>
                            </Grid>
                            <Grid xs={7} item>
                                <p>{userData.project}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CustomTable
                        columns={columns}
                        data={allocations}
                    />
                </div>
            </Modal>
        )
    }
}

UserTimesheet.defaultProps = {
    open: false,
}

UserTimesheet.propTypes = {
    open: PropTypes.bool,
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    getTimesheet: bindActionCreators(getTimesheet, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserTimesheet);
