import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  SHOW_LOADER,
  HIDE_LOADER,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_MODULE_NAME,
  MOBILE_DRAWER
} from '../constants/action.constant'
import React from 'react'

export const showOverlay = () => ({ type: SHOW_OVERLAY })

export const hideOverlay = () => ({ type: HIDE_OVERLAY })

export const showLoadingIndicator = () => ({ type: SHOW_LOADER })

export const hideLoadingIndicator = () => ({ type: HIDE_LOADER })

export const setModuleName = (name) => ({ type: SET_MODULE_NAME, name })

export const showMobileDrawer = (showMobileDrawer) => ({ type: MOBILE_DRAWER, showMobileDrawer })

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
})

export const successSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        autoHideDuration: 8000
      }
    }))
  }
}

export const errorSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
        autoHideDuration: 5000
      }
    }))
  }
}

export const warningSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        autoHideDuration: 8000
      }
    }))
  }
}

export const infoSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'info',
        autoHideDuration: 8000
      }
    }))
  }
}

export const defaultSnackbar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        autoHideDuration: 8000
      }
    }))
  }
}

export const persistantSnackbar = (msg, type) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg || '',
      options: {
        key: myKey,
        // persist: true,
        variant: type,
        autoHideDuration: 8000,
        // action: key => (
        //   <Button onClick={() => dispatch(closeSnackbar(myKey))}><Clear style={{ color: 'white' }} /></Button>
        // )
      }

    }))
  }
}

export const simulateOnSnacker = (msg) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: myKey,
        persist: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      }

    }))
  }
}

export const customContent = (msg) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: myKey,
        persist: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        content: () => (<h1>Hello</h1>)
      }

    }))
  }
}

export const priorities = [
  {
    value: 1,
    key: 'Urgent'
  },
  {
    value: 2,
    key: 'High'
  },
  {
    value: 3,
    key: 'Standard'
  },
  {
    value: 4,
    key: 'Low'
  }
]