const { subTaskCalculation } = require('./calculate-sub-task')
const { isString } = require("lodash");

export const taskCalculation = ({ material = [], subtasks = [], overrideProfitMargin = false, profitMarginType, profitMargin, isForm = false }) => {

    let total = {
        subtask: {},
        material: { cost: 0, sale: 0, profit: 0 },
        subcontractor: { cost: 0, sale: 0, profit: 0 },
        labor: { cost: 0, sale: 0, profit: 0 },
        total_profit: 0,
        subtotal: 0,
        forecast: 0,
        total: 0
    };

    try {

        for (const subTask of subtasks) {

            let subtask_total = subTaskCalculation({ subtask: subTask, profitMargin, profitMarginType, isForm })


            total.material.cost += subtask_total.material.cost;
            total.material.sale += subtask_total.material.sale;
            total.material.profit += subtask_total.material.profit;

            total.subcontractor.cost += subtask_total.subcontractor.cost;
            total.subcontractor.sale += subtask_total.subcontractor.sale;
            total.subcontractor.profit += subtask_total.subcontractor.profit;

            total.labor.cost += subtask_total.labor.cost;
            total.labor.sale += subtask_total.labor.sale;
            total.labor.profit += subtask_total.labor.profit;

            total.total_profit += subtask_total.total_profit;
            total.subtotal += subtask_total.subtotal;
            total.forecast += subtask_total.forecast
            total.total += subtask_total.total;

            total.subtask[subTask.id] = subtask_total;
        }

        let materials = material || [];

        materials.map((material) => {
            if (!material) return undefined;
            let _material = isString(material) ? JSON.parse(material) : material;

            if (_material["saleAmountLock"] === true) {

                if (profitMarginType === "PERCENTAGE") {
                    _material["saleAmount"] = Number(_material["cost"]) * ((parseFloat(profitMargin || 0) / 100) + 1);
                } else if (profitMarginType === "FIXED") {
                    _material["saleAmount"] = Number(_material["cost"]) + parseFloat(profitMargin || 0);
                }
            }
            let cost = Number(_material["cost"] || 0) * Number(_material["qty"] || 0);
            let sale = Number(_material["saleAmount"] || 0) * Number(_material["qty"] || 0);
            let profit = (sale) - (cost);
            total.material.cost += cost;
            total.material.sale += sale;
            total.material.profit += profit;

            total.total_profit += profit;
            total.subtotal += cost;
            total.total += sale;

            return JSON.stringify(_material);
        }).filter(item => item);

        // total.material.profit = total.material.profit.toFixed(2)
        // total.labor.profit = total.labor.profit.toFixed(2)
        // total.subcontractor.profit = total.subcontractor.profit.toFixed(2)
        // total.total_profit = total.total_profit.toFixed(2);

        return total;
    } catch (e) {
        console.error(e)
        return total;
    }
};
