import React from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import _ from 'lodash'

export default function CustomInput({
  name,
  type = 'text',
  label,
  touched,
  valid,
  placeholder,
  value,
  error,
  required,
  onChange,
  disabled,
  className = '',
  validation = [],
  ...props
}) {

  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

  return (
    <div className="form-group">
      <div className={_.uniq(["input-block", ...className.split(' ')]).join(' ')}>
        {(label || placeholder) && <label className="input-label">
          <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>&nbsp;*</span> : null}</span>
        </label>}
        <input
          className="form-control"
          name={name}
          type={type}
          value={value}
          touched={(touched || '').toString()}
          error={(error || '').toString()}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
        {!disabled && error && (touched || typeof touched == 'undefined') && <small className="float-start text-danger">
          {error}
        </small>}
      </div>
    </div >
  )
}

export function AmountInput({
  name,
  type = 'text',
  label,
  touched,
  valid,
  placeholder,
  value,
  error,
  required,
  onChange,
  disabled,
  className = '',
  validation = [],
  ...props
}) {

  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

  return (
    <div className="form-group">
      <div className={_.uniq(["input-block", 'amount-block', ...className.split(' ')]).join(' ')}>
        {(label || placeholder) && <label className="input-label d-flex">
          <>{label || placeholder || ''} {isRequired ? <span className='text-danger'>&nbsp;*</span> : null}</>
        </label>}
        <span className="input-icon"><AttachMoneyIcon /></span>
        <input
          className={`form-control ${(label || placeholder) ? '' : (!disabled && error) ? 'border-danger' : ''}`}
          name={name}
          type={type}
          value={value}
          touched={(touched || '').toString()}
          error={(error || '').toString()}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
        {!disabled && error && <small className="float-start text-danger">
          {error}
        </small>}
      </div>
    </div>
  )
}

export function LockInput({
  name,
  lock,
  type = 'text',
  label,
  touched,
  valid,
  placeholder,
  value,
  error,
  required,
  onChange,
  disabled,
  className = '',
  validation = [],
  ...props
}) {

  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')));

  return (
    <div className="form-group">
      <div className={_.uniq(["input-block", 'lock-input', ...className.split(' ')]).join(' ')}>
        {lock}
        {(label || placeholder) && <label className="input-label">
          <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
        </label>}
        <input
          className={`form-control ${(label || placeholder) ? '' : (!disabled && error && (touched || typeof touched == 'undefined')) ? 'border-danger' : ''}`}
          name={name}
          type={type}
          value={value}
          touched={(touched || '').toString()}
          error={(error || '').toString()}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
        {!disabled && error && (touched || typeof touched == 'undefined') && <small className="float-start text-danger">
          {error}
        </small>}
      </div>
    </div>
  )
}