import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import Login from './login'

class Root extends React.PureComponent {
  render () {
    return (
      <div className='clearfix' style={{ width: '100%' }}>
        <TransitionGroup>
         <Login {...this.props} />
        </TransitionGroup>
      </div>
    )
  }
}
export default Root
