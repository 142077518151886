import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const ALLOCATE = '/timesheet/allocate'
const UPDATE_TIMESHEET_TASK = '/timesheet/update-task'
const TIMESHEET_ALLOCATION = '/timesheet/allocation'
const DELETE_ALLOCATION = '/timesheet'
const GET_TIME_ALLOCATION = '/timesheet/resource-allocations'
const APPROVE_TIMESHEET = '/timesheet/approve'
const EDIT_TIMESHEET = '/timesheet'
const APPLY_LEAVE = '/timesheet/leave'
const GET_USER_LEAVES = '/timesheet/user-leaves'

// // ************API call Functions starts here **************

export const allocateSchedule = async (data) => {
  return await axios('post', `${ALLOCATE}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, successMessage: 'Allocation created succesfully'
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const updateTimesheetTask = async (data) => {
  return await axios('post', `${UPDATE_TIMESHEET_TASK}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, successMessage: 'Allocation updated succesfully'
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const allocateResource = async (data) => {
  return await axios('post', `${TIMESHEET_ALLOCATION}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const deleteResource = async (type, allocationId) => {
  return await axios('delete', `${DELETE_ALLOCATION}/${type}/${allocationId}`, {}, { successMessage: 'Allocation Deleted Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getResourceAllocations = async (params) => {
  return await axios('get', `${GET_TIME_ALLOCATION}`, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const approveTimesheet = async (id, data) => {
  return await axios('post', `${APPROVE_TIMESHEET}/${id}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, successMessage: 'Timesheet Updated'
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const editTimesheet = async (id, data) => {
  return await axios('put', `${EDIT_TIMESHEET}/${id}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const applyLeave = async (data) => {
  return await axios('post', `${APPLY_LEAVE}`, data,
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const getUserLeaves = async (params) => {
  return await axios('get', `${GET_USER_LEAVES}`, {},
    {
      loader: loaders.OVERLAY,
      server: microServices.DE_CORE,
      params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}
