import {
  getInvoiceList as getInvoiceListAPI,
  getProgressClaimList as getProgressClaimListAPI,
  saveInvoice as saveInvoiceAPI,
  getServiceInvoiceList as getServiceInvoiceListAPI,
  getServiceProgressClaimList as getServiceProgressClaimListAPI,
  saveServiceInvoice as saveServiceInvoiceAPI,
  downloadProjectProgressBreakup as downloadProjectProgressBreakupAPI,
  downloadProjectInvoice as downloadProjectInvoiceAPI,
  downloadServiceProgressBreakup as downloadServiceProgressBreakupAPI,
  downloadServiceInvoice as downloadServiceInvoiceAPI,
  getInvoiceAndProgressClaimList as getInvoiceAndProgressClaimListAPI,
  deleteProgressClaim as deleteProgressClaimAPI,
  getProjectCustomer as getProjectCustomerAPI
} from '../api/invoice.api'

export const getInvoiceList = (projectId, startDate, endDate) => {
  return (dispatch, getState) => {
    const allowedModules = getState().project.allowedModules || [];
    return getInvoiceListAPI(projectId, startDate, endDate, allowedModules)
  }
}

export const getProgressClaimList = (query) => {
  return (dispatch, getState) => {
    return getProgressClaimListAPI(query)
  }
}

export const saveInvoice = (query) => {
  return (dispatch, getState) => {
    return saveInvoiceAPI(query)
  }
}

export const getServiceInvoiceList = (serviceId, startDate, endDate) => {
  return (dispatch, getState) => {
    return getServiceInvoiceListAPI(serviceId, startDate, endDate)
  }
}

export const getServiceProgressClaimList = (query) => {
  return (dispatch, getState) => {
    return getServiceProgressClaimListAPI(query)
  }
}

export const saveServiceInvoice = (query) => {
  return (dispatch, getState) => {
    return saveServiceInvoiceAPI(query)
  }
}

export const downloadProjectProgressBreakup = (id, format,tempId) => {
  return (dispatch, getState) => {
    return downloadProjectProgressBreakupAPI(id, format,tempId)
  }
}

export const downloadProjectInvoice = (id, format,tempId) => {
  return (dispatch, getState) => {
    return downloadProjectInvoiceAPI(id, format,tempId)
  }
}

export const downloadServiceProgressBreakup = (id, format) => {
  return (dispatch, getState) => {
    return downloadServiceProgressBreakupAPI(id, format)
  }
}

export const downloadServiceInvoice = (id, format) => {
  return (dispatch, getState) => {
    return downloadServiceInvoiceAPI(id, format)
  }
}

export const getInvoiceAndProgressClaimList = (projectId) => {
  return (dispatch, getState) => {
    return getInvoiceAndProgressClaimListAPI(projectId)
  }
}

export const deleteProgressClaim = (optionId,type) => {
  return (dispatch, getState) => {
    return deleteProgressClaimAPI(optionId,type)
  }
}

export const getProjectCustomer = (id) => {
  return (dispatch, getState) => {
    return getProjectCustomerAPI(id)
  }
}
