import axios, { microServices } from './axios'

// // ********************API url *******************
const GET_VENDOR_LIST = '/vendor/list'
const GET_VENDORS_COUNT = '/vendor/count'
const ADD_VENDOR = '/vendor'
const EDIT_VENDOR = '/vendor'
const GET_VENDOR = '/vendor'
const VENDOR_STATUS_CHANGE = '/vendor/status'

// // ************API call Functions starts here **************

export const getVendorList = async (type, active = '') => {
  return await axios('get', GET_VENDOR_LIST, {}, {
    server: microServices.DE_CORE, params: {
      ...(type ? { type } : {}),
      ...(active ? { active } : {})
    }
  }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getVendor = async (id) => {
  return await axios('get', `${GET_VENDOR}?id=${id}`, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addVendor = async (data) => {
  return await axios('post', ADD_VENDOR, data, { successMessage: 'Vendor Created Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const addContact = async (data, id) => {
  return await axios('post', `${EDIT_VENDOR}/${id}/contact`, data, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const editVendor = async (data, id) => {
  return await axios('put', `${EDIT_VENDOR}/${id}`, data, { successMessage: 'Vendor Updated Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const changeVendorStatus = async (id) => {
  return await axios('put', `${VENDOR_STATUS_CHANGE}/${id}`, {}, { successMessage: 'Vendor Status Changed Successfully', server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getVendorCount = async () => {
  return await axios('get', GET_VENDORS_COUNT, {}, { server: microServices.DE_CORE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
