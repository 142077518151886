import { React, useState } from 'react';
import { defaults } from "chart.js/auto";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import '../project-management/scss/task.scss'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import _ from 'lodash';
import idx from 'idx';
import { random_rgba } from 'src/util/color';
import DatePickerInput from 'src/components/inputs/date-picker';
import { noDataPlugin } from './charts/plugin';
import { invoicesList, puprchaseOrderList, topProfitItems, topResourceUtilization, workOrderList } from './data/admin-columns';
import { DashboardTableModal } from 'src/components/modal/dsahboard-table';
import { useSelector } from 'react-redux';
import DashboardTable from 'src/components/table/dashboard-table';
import './dashboard.scss'

defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = false;
defaults.plugins.title.align = "center";
defaults.plugins.title.font.size = 15;
defaults.plugins.title.color = "black";
defaults.plugins.legend.display = false;

function AdminFinanceTab({ filterChange }) {

    const [view, setView] = useState({});
    const handleClose = () => setView({ show: false });
    const handleShow = (data) => setView({ show: true, ...data });
    const data = useSelector(state => state.dashboard.admin)
    const filter = useSelector(state => state.dashboard.filter) || {}

    return (
        <>
            <div className='row dashboard-datepicker'>
                <div className="col-lg-6 col-md-6">
                    <DatePickerInput
                        label='From Date'
                        required={true}
                        clearable={false}
                        value={filter.start}
                        onChange={(e) => {
                            if (filter.end && (new Date(filter.end).getTime() >= new Date(e).getTime())) {
                                filterChange({ start: e, end: filter.end })
                            }
                        }}
                    />
                </div>
                <div className="col-lg-6 col-md-6">
                    <DatePickerInput
                        label='To Date'
                        required={true}
                        clearable={false}
                        value={filter.end}
                        onChange={(e) => {
                            if (filter.start && (new Date(filter.start).getTime() <= new Date(e).getTime())) {
                                filterChange({ start: filter.start, end: e })
                            }
                        }}
                    />
                </div>
            </div>

            <div className="row row-padding">
                <div class="d-flex col-12 mb-3">
                    <div className="w-auto me-auto mt-auto chart-heading">
                        <h2>MONEY IN/OUT</h2>
                    </div>

                </div>

                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>MONEY IN BY PROJECT CATEGORY</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.money_in) || []).map(item => item.project_type),
                                    datasets: [{
                                        label: 'Pending',
                                        data: (idx(data, _ => _.money_in) || []).map(item => item.invoice?.pending),
                                        backgroundColor: "rgba(75, 192, 192, 0.4)",
                                        borderColor: "rgba(75, 192, 192, 1)"
                                    }, {
                                        label: 'Finalised',
                                        data: (idx(data, _ => _.money_in) || []).map(item => item.invoice?.finalised),
                                        backgroundColor: "rgba(153, 102, 255, 0.4)",
                                        borderColor: "rgba(153, 102, 255, 1)"
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>MONEY OUT BY PROJECT CATEGORY</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.money_out) || []).map(item => item.project_type),
                                    datasets: [
                                        {
                                            label: 'Finalised',
                                            data: (idx(data, _ => _.money_out) || []).map(item => item.finalised),
                                            backgroundColor: "rgba(153, 102, 255, 0.4)",
                                            borderColor: "rgba(153, 102, 255, 1)"
                                        },
                                        {
                                            label: 'Pending',
                                            data: (idx(data, _ => _.money_out) || []).map(item => item.pending),
                                            backgroundColor: "rgba(255, 99, 132, 0.4)",
                                            borderColor: "rgba(255, 99, 132, 1)"
                                        }
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>CASH FLOW GRAPH OVER TIME</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Line
                                data={{
                                    labels: (idx(data, _ => _.cash_flow) || []).map(item => item.week_no),
                                    datasets: [{
                                        label: 'Revenue',
                                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                                        borderColor: "rgba(75, 192, 192, 1)",
                                        fill: false,
                                        data: (idx(data, _ => _.cash_flow) || []).map(item => item.revenue),
                                    }, {
                                        label: 'Expenses',
                                        backgroundColor: "rgba(255, 205, 86, 0.6)",
                                        borderColor: "rgba(255, 205, 86, 1)",
                                        fill: false,
                                        data: (idx(data, _ => _.cash_flow) || []).map(item => item.expense),
                                    }]
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">Invoices List
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Invoices List', columns: invoicesList, data: idx(data, _ => _.invoice_list) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={invoicesList}
                                    data={_.slice((idx(data, _ => _.invoice_list) || []), 0, 5)}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">Purchase Order List
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Purchase Order List', columns: puprchaseOrderList, data: idx(data, _ => _.po_list) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={puprchaseOrderList}
                                    data={_.slice((idx(data, _ => _.po_list) || []), 0, 5)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative"> Work Order List
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Work Order List', columns: workOrderList, data: idx(data, _ => _.wo_list) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={workOrderList}
                                    data={_.slice((idx(data, _ => _.wo_list) || []), 0, 5)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12 chart-heading">
                    <h2>PROFIT</h2>
                </div>
                <div className="col-lg-3 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>PROFIT MARGIN</h3>
                        <div className="bar-chart bar-chart-ch">
                            <Bar
                                data={{
                                    labels: ['Material', 'Resources', 'Contractor'],
                                    datasets: [{
                                        label: 'Total Profit',
                                        data: [
                                            idx(data, _ => _.overall_profit.material) || 0,
                                            idx(data, _ => _.overall_profit.labor) || 0,
                                            idx(data, _ => _.overall_profit.contractor) || 0,
                                        ],
                                        backgroundColor: [
                                            'rgba(54, 162, 235, 0.4)',
                                        ],
                                        borderColor: [
                                            'rgba(54, 162, 235, 1)',
                                        ],
                                        borderWidth: 1,
                                    }]
                                }}
                                options={{
                                    indexAxis: 'y',
                                }} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>MATERIALS PROFIT MARGIN </h3>
                        <div className="donut-chart donut-chart-ch">
                            <Pie
                                data={{
                                    labels: (idx(data, _ => _.material_profit_pc) || []).filter(item => item.profit).map(item => item.project_type),
                                    datasets: [{
                                        label: 'Profit',
                                        data: (idx(data, _ => _.material_profit_pc) || []).filter(item => item.profit).map(item => item.profit),
                                        backgroundColor: (idx(data, _ => _.material_profit_pc) || []).filter(item => item.profit).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>RESOURCES PROFIT MARGIN </h3>
                        <div className="donut-chart donut-chart-ch">
                            <Pie
                                data={{
                                    labels: (idx(data, _ => _.labor_profit_pc) || []).filter(item => item.profit).map(item => item.project_type),
                                    datasets: [{
                                        label: 'Profit',
                                        data: (idx(data, _ => _.labor_profit_pc) || []).filter(item => item.profit).map(item => item.profit),
                                        backgroundColor: (idx(data, _ => _.labor_profit_pc) || []).filter(item => item.profit).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>CONTRACTORS PROFIT MARGIN </h3>
                        <div className="donut-chart donut-chart-ch">
                            <Pie
                                data={{
                                    labels: (idx(data, _ => _.contractor_profit_pc) || []).filter(item => item.profit).map(item => item.project_type),
                                    datasets: [{
                                        label: 'Profit',
                                        data: (idx(data, _ => _.contractor_profit_pc) || []).filter(item => item.profit).map(item => item.profit),
                                        backgroundColor: (idx(data, _ => _.contractor_profit_pc) || []).filter(item => item.profit).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">  Top Profit Items
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Top Profit Items', columns: topProfitItems, data: idx(data, _ => _.top_profit_items) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={topProfitItems}
                                    data={_.slice((idx(data, _ => _.top_profit_items) || []), 0, 5)}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative"> Least Profit Items
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Least Profit Items', columns: topProfitItems, data: idx(data, _ => _.least_profit_items) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={topProfitItems}
                                    data={_.slice((idx(data, _ => _.least_profit_items) || []), 0, 5)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12 chart-heading">
                    <h2>RESOURCES / COST SALE LEVELS</h2>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>REVENUE BY COST LEVELS</h3>
                        <div className="donut-chart">
                            <Doughnut
                                data={{
                                    labels: (idx(data, _ => _.level_revenue) || []).filter(item => item.revenue).map(item => item.name),
                                    datasets: [{
                                        label: 'Sale Amount for all Resources',
                                        data: (idx(data, _ => _.level_revenue) || []).filter(item => item.revenue).map(item => item.revenue),
                                        backgroundColor: (idx(data, _ => _.level_revenue) || []).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>PROFIT BY COST LEVELS</h3>
                        <div className="donut-chart">
                            <Doughnut
                                data={{
                                    labels: (idx(data, _ => _.level_profit) || []).filter(item => item.profit).map(item => item.name),
                                    datasets: [{
                                        label: ' Profit for all Resources',
                                        data: (idx(data, _ => _.level_profit) || []).filter(item => item.profit).map(item => item.profit),
                                        backgroundColor: (idx(data, _ => _.level_revenue) || []).map(() => random_rgba()),
                                        hoverOffset: 4
                                    }]
                                }}
                                plugins={[noDataPlugin]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3>Resource Utilisation</h3>
                        <div className="bar-chart">
                            <Bar
                                data={{
                                    labels: (idx(data, _ => _.resource.utilization) || []).map(item => item.month),
                                    datasets: [{
                                        label: 'Available',
                                        data: (idx(data, _ => _.resource.utilization) || []).map(item => item.available),
                                        backgroundColor: "rgba(255,0,0,0.3)"
                                    }, {
                                        label: 'Billed',
                                        data: (idx(data, _ => _.resource.utilization) || []).map(item => item.billed),
                                        backgroundColor: "rgba(0,0,255,0.3)"
                                    }]
                                }} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">  Top Resource utilisation
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Top Resource utilisation', columns: topResourceUtilization, data: idx(data, _ => _.resource.top) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={topResourceUtilization}
                                    data={_.slice((idx(data, _ => _.resource.top) || []), 0, 5)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-2 mb-1">
                    <div className="card chart-block">
                        <h3 className="position-relative">Lowest Resource utilisation
                            <span
                                className="tbl-expand-icon"
                                onClick={() => handleShow({ title: 'Lowest Resource utilisation', columns: topResourceUtilization, data: idx(data, _ => _.resource.least) || [] })}
                            >
                                <ZoomOutMapIcon />
                            </span>
                        </h3>
                        <div className="p-2 h-100">
                            <div className="material-list-block h-100">
                                <DashboardTable
                                    columns={topResourceUtilization}
                                    data={_.slice((idx(data, _ => _.resource.least) || []), 0, 5)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DashboardTableModal
                show={view.show}
                columns={view?.columns}
                data={view?.data}
                title={view?.title}
                handleClose={handleClose}
            />
        </>
    );

}

export default AdminFinanceTab
