import React from 'react'
import PropTypes from 'prop-types'
import Input from '../../../components/inputs/input-simple'
import Buttons from '../../../components/buttons/buttons'
import { Grid, Divider, Button } from '@material-ui/core'
import './../scss/project-management.scss'
import { connect } from 'react-redux'
import { withRouter } from "../../../util/with-router";
import _ from 'lodash'
import { errorSnackBar } from './../../../actions/common.action'
import { getStage, getTask, getSubTask } from './../../../actions/project-management.action'
import { bindActionCreators } from 'redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import FileUploader from './../../../components/file-uploader'
import { assignTaskFields } from './../../../constants/fields.constant'

class AssignTask extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assignTaskFields: JSON.parse(JSON.stringify(assignTaskFields)),
      id: null,
      taskType: '',
      taskData: props.taskData
    }
  }

  componentDidMount() {
    this.setState({ assignTaskFields: this.props.assignTaskFields, taskData: this.props.taskData })
    const { id, taskType } = this.props.params
    if (taskType === 'stage') {
      this.props.getStage(id).then(res => {
        this.editTask(res)
      })
    } else if (taskType === 'task') {
      this.props.getTask(id).then(res => {
        this.editTask(res)
      })
    } else if (taskType === 'subTask') {
      this.props.getSubTask(id).then(res => {
        this.editTask(res)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.assignTaskFields, this.props.assignTaskFields)) {
      this.setState({ assignTaskFields: this.props.assignTaskFields })
    }
    if (!_.isEqual(prevProps.taskData, this.props.taskData)) {
      this.setState({ taskData: this.props.taskData })
    }
  }

  editTask = (data) => {
    const fields = JSON.parse(JSON.stringify(this.state.assignTaskFields))
    fields.status.value = data.status !== '' ? data.status : 'OPEN'
    fields.percentageComplete.value = data.percentageComplete
    fields.reminder.value = data.reminder
    const attachments = []
    Array.isArray(data.attachment) && data.attachment.forEach(doc => {
      if (_.isString(doc) && !(_.isError(_.attempt(JSON.parse, doc)))) {
        attachments.push(JSON.parse(doc))
      } else {
        attachments.push(doc)
      }
    })
    fields.attachments.attachmentArr = attachments
    fields.attachments.value = []
    this.setState({ assignTaskFields: fields })
  }

  handleBackButton = () => {
    this.props.handleBackButton(true);
  }

  handleAssignTaskChange = (value, inputName) => {
    let fields = this.state.assignTaskFields
    if (inputName === 'attachments') {
      if (value > 10) {
        this.props.errorSnackBar('You Can not Upload more than 10 files')
      } else {
        for (var i = 0; i < value.length; i++) {
          fields[inputName].value.push(value[i])
        }
      }
    } else {
      fields[inputName].value = value
    }
    fields[inputName].touched = true
    const { error, valid } = this.handleValidation({ inputName, value })
    fields[inputName].error = error
    fields[inputName].valid = valid
    this.props.setAssignTaskFields(fields)
    this.setState({ assignTaskFields: { ...this.state.assignTaskFields, [inputName]: fields[inputName] } })
  }

  handleValidation = ({ inputName, value }) => {
    let error = false
    let valid = true
    if (inputName === 'managerId' && Array.isArray(value) && value.length <= 0) {
      return { error: 'This Field is Required', valid: false }
    }
    return { error, valid }
  }

  seletectFile = () => {
    document.getElementById("fileInput").click();
  }

  removeFile = (index, type) => {
    const fields = this.state.assignTaskFields
    if (type === 'new') {
      const data = []
      for (var x = 0; x < fields.attachments.value.length; x++) {
        if (x !== index) {
          data.push(fields.attachments.value[x])
        }
      }
      fields.attachments.value = data
    }
    if (type === 'old') {
      const data = fields.attachments.attachmentArr.filter((doc, i) => i !== index)
      fields.attachments.attachmentArr = data
    }
    this.props.setAssignTaskFields(fields)
    this.setState({ assignTaskFields: fields })
  }

  render() {
    const { assignTaskFields, taskData } = this.state
    const files = []
    for (var x = 0; x < assignTaskFields.attachments.value.length; x++) {
      const index = x
      files.push(
        <div className="attachedFilesAssign">
          <p style={{ margin: '0 10px 0 0' }}>{assignTaskFields.attachments.value[x].name}</p>
          <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(index, 'new')}><DeleteIcon /></p>
        </div>
      )
    }
    const attachments = assignTaskFields.attachments.attachmentArr.map((doc, i) => (
      <div key={i} className="attachedFilesAssign">
        <p style={{ margin: '0 10px 0 0' }}><a href={doc.url} rel="noopener noreferrer" target="_blank">{doc.name}</a></p>
        <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'old')}><DeleteIcon /></p>
      </div>
    ))
    return (
      <div className='assign-task-container assignContainer'>
        <Button
          variant="contained"
          color="primary"
          className="back-button-execution"
          startIcon={<ArrowBackIcon />}
          onClick={this.handleBackButton}
        >
          Back
        </Button>
        <Grid container >
          <Grid xs={12} item className='stage'>
            <Grid xs={12} item className='stage-name-title'>
              Stage/Task/Subtask Name: <b>{taskData.name}</b>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid xs={8} item>
              <Grid container className='task-progress'>
                <Grid xs={4} item >
                  <span>Task Status: </span>
                </Grid>
                <Grid xs={5} item>
                  <Input
                    {...assignTaskFields['status']}
                    className='assign-task-select'
                    type='select'
                    items={[{
                      value: 'OPEN',
                      key: 'Open'
                    }, {
                      value: 'INPROGRESS',
                      key: 'In-Progress'
                    }, {
                      value: 'ONHOLD',
                      key: 'On-Hold'
                    }, {
                      value: 'COMPLETED',
                      key: 'Completed'
                    }, {
                      value: 'CANCELLED',
                      key: 'Cancelled'
                    }]}
                    onChange={e => this.handleAssignTaskChange(e.target.value, 'status')}
                    variant={'standard'}
                    disableUnderline={true}
                  />
                </Grid>
              </Grid>
              <Grid container className='assign-task-form'>
                <Grid xs={4} item >
                  <span>Completion Percentage: </span>
                </Grid>
                <Grid xs={5} item>
                  <Input
                    {...assignTaskFields['percentageComplete']}
                    className='assign-task-select'
                    type='number'
                    onChange={e => this.handleAssignTaskChange(e.target.value, 'percentageComplete')}
                  />
                </Grid>
              </Grid>
              <Grid container className='start-date-container'>
                <Grid xs={4} item className='start-date'>
                  <span>Reminder </span>
                </Grid>
                <Grid xs={5} item>
                  <Input
                    {...assignTaskFields['reminder']}
                    className='assign-task-input'
                    type='text'
                    onChange={e => this.handleAssignTaskChange(e.target.value, 'reminder')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={4} item>
              <Grid xs={4} style={{ alignItems: 'unset' }}>
                <FileUploader
                  fileHandler={(files) => this.handleAssignTaskChange(files, 'attachments')}
                />
                {files}
                {attachments}
              </Grid>
            </Grid>
          </Grid>

          <Divider className='comments-divider' />
          <Grid container className='start-date-container'>
            <Grid xs={5} item className='start-date'>
              <Input
                className='assign-task-input'
                type='textarea'
                label='Enter Your Comment'
                onChange={e => { }}
              />
            </Grid>
            <Grid xs={2} item>
              <Buttons title='Post' />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

AssignTask.defaultProps = {
  userNames: [],
  assignTaskFields: {},
  taskData: {},
}

AssignTask.propTypes = {
  userNames: PropTypes.array,
  assignTaskFields: PropTypes.object,
  taskData: PropTypes.object,
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  getStage: bindActionCreators(getStage, dispatch),
  getTask: bindActionCreators(getTask, dispatch),
  getSubTask: bindActionCreators(getSubTask, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignTask));