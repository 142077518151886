import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addStage } from './../../actions/project-management.action'
import Modal from './../../components/modal/modal'
import './scss/project-management.scss'
import { useSelector } from 'react-redux'
import idx from 'idx'

function StageSelectDialog({ open, projectId, ...props }) {

  const { projectDetail = {} } = useSelector(state => state)
  useEffect(() => {
    if (projectId) {
      handleStageSelectClose()
    }
  }, [projectId])

  const handleStageClick = () => {
    props.dialogClose(true);
  }

  const handleStageSelectClose = async () => {
    if ((idx(projectDetail, _ => _.project.stagesData) || []).length == 0 && idx(projectDetail, _ => _.project.projectId) == projectId) {
      const formData = new FormData()
      formData.set('name', 'DummyStage')
      formData.set('isDummyStage', true)

      await props.addStage(formData, projectId, true)
      // getProjectData({ projectId, dispatch }).then(() => {
      props.dialogClose(false);
      // }).catch((e) => {
      //   props.dialogClose(false);
      // })
    } else {
      props.dialogClose(false);
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleStageSelectClose}
      fullWidth={true}
      maxWidth='xs'
      hideCloseIcon={true}
      modalHeading={'Stages in Project'}
      modalSaveTxt={'Yes'}
      modalCloseTxt={'No'}
      onClick={handleStageClick}
      showActionBtn
      savebtnClass='createBtn'
      primary >
      Do you want stages in this Project?
    </Modal>
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  addStage: bindActionCreators(addStage, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StageSelectDialog)
