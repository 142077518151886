import idx from "idx";
import { getUserNames } from "src/actions/user-management.action";
import { addUserNamesAction } from "src/slice/project-detail";

export const getUserNamesData = async ({ dispatch }) => {

    const result = await dispatch(getUserNames());

    const userNames = Array.isArray(result) && result.map((user) => ({
        key: (idx(user, _ => _.firstName) || '') + " " + (idx(user, _ => _.lastName) || ''),
        value: user.id,
    }));

    dispatch(addUserNamesAction(userNames))
}