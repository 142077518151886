import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./../user-management/scss/user-management.scss";
import {
  updateProfile,
  getUserData,
} from "./../../actions/user-management.action";
import { emailValidator } from "../../util/validators/email";
import _ from "lodash";
import { withRouter } from "../../util/with-router";
import CustomInput from "src/components/inputs/new-input";
import Button from "react-bootstrap/Button";
import { phoneValidator } from "src/util/validators/phone";
import { passwordValidator } from "src/util/validators/password";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        fullName: {
          name: "fullName",
          label: "Full Name",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        email: {
          name: "email",
          label: "Email",
          type: "email",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        phoneNumber: {
          name: "phoneNumber",
          label: "Phone Number",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
      },
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const fields = this.state.fields;
    fields.fullName.value = user.fullName || user.firstName;
    fields.email.value = user.email;
    fields.phoneNumber.value = user.phoneNumber;
    this.setState({ fields });
  }
  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    const fields = this.state.fields;
    fields.fullName.value = user.fullName || user.firstName;
    fields.email.value = user.email;
    fields.phoneNumber.value = user.phoneNumber;
    this.setState({ fields });
  }

  inputChangeHandler = (e, fieldName = "") => {
    let fields = this.state.fields;
    var name;
    var value;
    if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    if (fieldName === "phoneNumber") {
      name = fieldName;
      value = e.target.value;
    }
    fields[name].value = value;
    fields[name].touched = true;
    const { error, valid } = this.handleValidation(
      { name, value },
      fields[name].validation
    );
    fields[name].error = error;
    fields[name].valid = valid;
    let isFormValid = true;
    for (let key of Object.keys(this.state.fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }
    this.setState({ fields, isFormValid });
  };

  handleValidation = ({ name, type, value }, validation = []) => {
    let error = false;
    let valid = true;
    if (validation.includes("required") && value === undefined) {
      return { error: "This Field is Required", valid: false };
    }
    if (name === "email") {
      let status = emailValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Email", valid: false };
      }
    }

    if (name === "phoneNumber") {
      let status = phoneValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Mobile Number" };
      }
    }

    if (name === "password" && value.trim() !== "") {
      let status = passwordValidator(value)
      return status;
    }
    return { error, valid };
  };

  submitForm = () => {
    const { fields } = this.state;
    let isFormValid = true;

    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = {
        fullName: fields.fullName.value,
        email: fields.email.value,
        phoneNumber: fields.phoneNumber.value,
      };

      this.props.updateProfile(data).then((res) => {
        // Extract updated user data from response and update the state
        const updatedUser = res.data;
        this.setState({ user: updatedUser });

        // Call getUserData() to update user data in the App component
        this.props.getUserData();

        // Navigate to the home page
        this.props.history("/dashboard");
      });
    }
  };

  render() {
    const { fields } = this.state;

    return (
      <div className="purchase-order-block">

        <h2 className="content-heading text-uppercase">Profile</h2>

        <div className="row">
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.fullName}
              onChange={(e) => this.inputChangeHandler(e, fields.fullName.name)}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.email}
              onChange={(e) => this.inputChangeHandler(e, fields.email.name)}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.phoneNumber}
              onChange={(e) => this.inputChangeHandler(e, fields.phoneNumber.name)}
            />
          </div>
        </div>

        <div className="col-12 d-flex mt-3">
          <Button onClick={this.submitForm} className="ms-auto me-3 secondarybtn">DONE</Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: bindActionCreators(updateProfile, dispatch),
  getUserData: bindActionCreators(getUserData, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
