import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const OPERATIONS = '/dashboard/operations'

const COST_CALCULATIONS = '/cost-calculations'
const GET_LABOR_COST = '/cost-calculations/labor-cost'
const GET_PROJECT_CATEGORY = '/cost-calculations/project-category'
const GET_DASHBOARD_CALCULATIONS = '/cost-calculations/dashboard-calculations'
// // ************API call Functions starts here **************

export const getOperationDetailsAPI = async (params) => {
  return await axios('get', OPERATIONS, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const getFinanceDetails = async (params) => {
  return await axios('get', `${COST_CALCULATIONS}`, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}

export const getLaborCost = async (params) => {
  return await axios('get', `${GET_LABOR_COST}`, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}
export const getProjectCategory = async (params) => {
  return await axios('get', `${GET_PROJECT_CATEGORY}`, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}
export const getDashboardDetails = async (params) => {
  return await axios('get', `${GET_DASHBOARD_CALCULATIONS}`, {},
    {
      loader: loaders.OVERLAY, server: microServices.DE_CORE, params
    })
    .then(data => {
      return data
    },
      error => { // request level error
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
}