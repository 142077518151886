// Routes for project managment
export const PROJECT_MANAGEMENT = 'projects'
export const ADD_PROJECT = 'add'
export const EDIT_PROJECT = 'edit/:projectId'
export const EDIT_PROJECT_STEP_TWO = 'edit/:projectId/:step'
export const VIEW_PROJECT = 'view/:projectId'
export const ASSIGN_TASK = 'assign/:type/:id'
export const PROJECT_ASSET = 'asset/:projectId'
export const PROJECT_VARIATION = 'variation/:projectId'
export const ADD_PROJECT_VARIATION = 'variation/add/:projectId'
export const PROJECT_INVOICES = 'invoices/:projectId'
export const PROJECT_INVOICES_CREATE = 'invoice/:projectId/create'
export const PROJECT_INVOICES_EDIT = 'invoice/:projectId/edit/:progressClaimId'
export const PROJECT_BUDGET = 'budget/:projectId'
export const ADD_PROJECT_ASSET = 'asset/add/:projectId'
export const PROJECT_RFQ = 'rfq/:projectId'
export const PROJECT_PURCHASE_ORDER = 'purchase-order/:projectId'
export const PROJECT_WORK_ORDER = 'work-order/:projectId'
export const RECEIVE_STOCK = 'receive-stock/:projectId'
export const RECEIVE_STOCK_VENDOR = 'receive-stock/:projectId/:vendorId/:poId'
export const RETURN_STOCK_VENDOR = 'return-stock/:projectId/:vendorId/:poId'
export const RECEIVE_DE_STOCK = 'receive-de-stock/:projectId'
export const RECEIVE_RESERVED_STOCK = 'receive-reserved-stock/:projectId'

// Routes for user managment
export const USER_MANAGEMENT = 'user-management'
export const ADD_USER = 'add'
export const EDIT_USER = 'edit/:userId'
export const USER_ALLOCATIONS = 'allocations'

// Routes for login
export const LOGIN = '/login'

// Routes for customer management
export const CUSTOMER_MANAGEMENT = 'customers'
export const ADD_CUSTOMER = 'add'
export const EDIT_CUSTOMER = 'edit/:customerId'

// Routes for Profile
export const PROFILE = 'profile'
export const RESET_PASSWORD = 'reset-password'

// Routes for Cost Heads
export const ADD_ITEM = 'material/add'
export const EDIT_ITEM = 'material/edit/:itemId'
export const LABOR = 'skill-set'
export const LEVEL = 'skill-set/level'
export const ADD_LABOR = 'skill-set/add'
export const EDIT_LABOR = 'skill-set/edit/:laborId'
export const ADD_LEVEL = 'skill-set/level/add'
export const EDIT_LEVEL = 'skill-set/level/edit/:levelId'

// Routes for Inventory Management
export const INVENTORY = 'inventory'
export const ADD_STOCK = 'add-stock'
export const TRANSACTIONS = 'transactions'
export const ALLOCATE_ITEM = 'allocate-item/:itemId'
export const PO_STOCK = 'po-stock/:itemId'
export const CREATE_RFQ = 'create-rfq'
export const EDIT_RFQ = 'edit-rfq/:rfqId'
export const OVERALL_STOCK = 'overall-stock/:itemId'

// Routes for asset management
export const ASSET_MANAGEMENT = 'asset-management'
export const ADD_ASSET = 'add'
export const EDIT_ASSET = 'edit/:assetId'
export const ALLOCATE_ASSET = 'allocate/:assetId'
export const ASSET_MAINTENANCE = 'maintenance/:assetId'
export const ASSET_REPAIR = 'repair/:assetId'

// Routes for Finance Managment
export const PURCHASE_ORDER = 'purchase-order'
export const INVOICES = 'invoices'
export const CREATE_INVOICE = 'invoices/create'
export const TIMESHEET_REPORT = 'timesheet-report'
export const VENDOR_PAYMENT = 'vendor-payment'
export const VIEW_PO = 'view/:invoiceId'

// Routes for Services
export const SERVICES = 'services'
export const SERVICES_LIST = ''
export const ADD_SERVICE = 'add'
export const EDIT_SERVICE = 'edit'
export const VIEW_SERVICE = 'view/:serviceId'
export const SERVICE_VARIATION = 'variation/:serviceId'
export const ADD_SERVICE_VARIATION = 'variation/add/:serviceId'
export const VIEW_SERVICE_INVOICES = 'invoices/:serviceId'
export const SERVICE_BUDGET = 'budget/:serviceId'
export const SERVICE_ASSET = 'asset/:serviceId'
export const ADD_SERVICE_ASSET = 'asset/add/:serviceId'
export const SERVICE_RFQ = 'rfq/:serviceId'
export const SERVICE_PURCHASE_ORDER = 'purchase-order/:serviceId'
export const SERVICE_RECEIVE_STOCK = 'receive-stock/:serviceId'
export const SERVICE_RECEIVE_STOCK_VENDOR = 'receive-stock/:serviceId/:vendorId/:poId'
export const SERVICE_RETURN_STOCK_VENDOR = 'return-stock/:serviceId/:vendorId/:poId'
export const SERVICE_RECEIVE_DE_STOCK = 'receive-de-stock/:serviceId'
export const SERVICE_RECEIVE_RESERVED_STOCK = 'receive-reserved-stock/:serviceId'

// Routes for Dashboard
export const DASHBOARD = 'dashboard'

// Routes for templates
export const GLOBAL_SETTING = 'global-setting'
export const GLOBAL_FINANCE = 'finance-tax'
export const EDIT_LETTERHEAD = 'letterhead'
export const EDIT_TEMPLATES = 'edit-template'
export const PROJECT_CATEGORIES = 'project-categories'

// Routes for timesheet
export const TIMESHEET = 'timesheet'
export const SCHEDULE = 'schedule'
export const ALLOCATIONS = 'allocations'
export const VIEW_TIMESHEET = 'view-timesheet/:userId'

// Routes for Accounts Admin
export const GLOBAL_ACCOUNT = 'global-accounts'
export const ADD_ACCOUNT = 'add'
export const EDIT_ACCOUNT = 'edit/:accountId'


// Suppliers Section
export const SUPPLIERS = 'suppliers'
export const SUPPLIERS_LIST = 'list'
export const ADD_SUPPLIER = 'add'
export const EDIT_SUPPLIER = 'edit/:supplierId'
export const VIEW_INVOICE_PO = 'vendor-payment/view/:invoiceId'
export const CREATE_PURCHASE_ORDER = 'purchase-order/create'
export const VIEW_PURCHASE_ORDER = 'purchase-order/view/:poId/:workType'
export const EDIT_PURCHASE_ORDER = 'purchase-order/edit/:poId/:workType'

// Contractors Section
export const CONTRACTORS = 'contractors'
export const CONTRACTORS_LIST = 'list'
export const ADD_CONTRACTOR = 'add'
export const EDIT_CONTRACTOR = 'edit/:supplierId'
export const WORK_ORDER = 'work-order'
export const RETENTION = 'retention'
export const CREATE_WORK_ORDER = 'work-order/create'
export const VIEW_WORK_ORDER = 'work-order/view/:poId/:workType'
export const EDIT_WORK_ORDER = 'work-order/edit/:poId/:workType'
// export const VIEW_INVOICE_PO = 'vendor-payment/view/:invoiceId'

// Receivables Section
export const RECEIVABLES = 'receivables'

// Receivables Section
export const CATALOGS = 'catalogs'
export const RESOURCES = 'resources'
export const RESOURCE_LEVEL = 'resources/level'
export const ADD_RESOURCE = 'resources/add'
export const EDIT_RESOURCE = 'resources/edit/:laborId'
export const ADD_RESOURCE_LEVEL = 'resources/level/add'
export const EDIT_RESOURCE_LEVEL = 'resources/level/edit/:levelId'
export const MATERIAL = 'material'
export const ADD_MATERIAL = 'material/add'
export const EDIT_MATERIAL = 'material/edit/:itemId'

// Receivables Section
export const TEMPLATES = 'templates'
export const PROJECT_TEMPLATE = 'project'
export const TASK_TEMPLATE = 'task'
export const ITEM_TEMPLATE = 'item'
export const ADD_PROJECT_TEMPLATE = 'templates/project/add'
export const EDIT_PROJECT_TEMPLATE = 'templates/project/edit/:templateId'
export const ADD_TASK_TEMPLATE = 'templates/task/add'
export const EDIT_TASK_TEMPLATE = 'templates/task/edit/:templateId'
export const ADD_ITEM_TEMPLATE = 'templates/item/add'
export const EDIT_ITEM_TEMPLATE = 'templates/item/edit/:templateId'