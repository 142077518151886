import React from "react";
import { useRoutes } from "react-router-dom";
import { withRouter } from "src/util/with-router";
import { TransitionGroup } from "react-transition-group";
import AddSubContractor from "./add-sub-contractor";
import SubContractorsList from './list'
import SubContractorsWorkOrderList from './work-order/wo-list'
import ViewWorkOrder from './work-order/view-wo'
import CreateWorkOrder from './work-order/create-wo'
import SubContractorInvoiceList from './vendor-payment/list'
import ViewInvoiceWorkOrder from './vendor-payment/view-invoice'
import RBACApplicationAuth from "src/layout/rbac";
import * as paths from "src/constants/route-paths";

function CustomRoutes({ location }) {
    let element = useRoutes([
        {
            path: paths.CONTRACTORS_LIST,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={SubContractorsList} />,
        },
        {
            path: paths.ADD_CONTRACTOR,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddSubContractor} />,
        },
        {
            path: paths.EDIT_CONTRACTOR,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={AddSubContractor} />,
        },
        {
            path: paths.WORK_ORDER,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={SubContractorsWorkOrderList} />,
        },
        {
            path: paths.VIEW_WORK_ORDER,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={ViewWorkOrder} />,
        },
        {
            path: paths.CREATE_WORK_ORDER,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={CreateWorkOrder} />,
        },
        {
            path: paths.EDIT_WORK_ORDER,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={CreateWorkOrder} />,
        },
        {
            path: paths.VENDOR_PAYMENT,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={SubContractorInvoiceList} />,
        },
        {
            path: paths.VIEW_INVOICE_PO,
            element: <RBACApplicationAuth moduleURL={location.pathname} component={ViewInvoiceWorkOrder} />,
        },
    ]);

    return element;
}

class Root extends React.PureComponent {
    render() {
        const { location } = this.props;
        return (
            <div className="clearfix" style={{ width: "100%" }}>
                <TransitionGroup>
                    <CustomRoutes location={location} />
                </TransitionGroup>
            </div>
        );
    }
}
export default withRouter(Root);
