import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router";
import {
  GLOBAL_SETTING,
  GLOBAL_FINANCE,
  EDIT_LETTERHEAD,
  EDIT_TEMPLATES,
  PROJECT_CATEGORIES,
} from "../../constants/route-paths";
import Finance from "./finance";
import LetterHead from "./letterhead";
import EditTemplates from "./edit-templates";
import ProjectCategories from "./project_categories";
import "./scss/templates.scss";
import { withRouter } from "../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: "LETTERHEAD",
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    const urlPath = window.location.pathname;
    let selectedCard = "";
    if (urlPath === `/${GLOBAL_SETTING}/${EDIT_LETTERHEAD}`) {
      selectedCard = "LETTERHEAD";
    } else if (urlPath === `/${GLOBAL_SETTING}/${GLOBAL_FINANCE}`) {
      selectedCard = "FINANCE";
    } else if (urlPath === `/${GLOBAL_SETTING}/${EDIT_TEMPLATES}`) {
      selectedCard = "TEMPLATES";
    } else if (urlPath === `/${GLOBAL_SETTING}/${PROJECT_CATEGORIES}`) {
      selectedCard = "PROJECT";
    }
    if (selectedCard) this.setState({ selectedCard });
  }

  handleCardClick = (selectedCard = "LETTERHEAD") => {
    this.setState({ selectedCard }, () => {
      if (selectedCard === "LETTERHEAD") {
        this.props.history(`/${GLOBAL_SETTING}/${EDIT_LETTERHEAD}`);
      } else if (selectedCard === "FINANCE") {
        this.props.history(`/${GLOBAL_SETTING}/${GLOBAL_FINANCE}`);
      } else if (selectedCard === "TEMPLATES") {
        this.props.history(`/${GLOBAL_SETTING}/${EDIT_TEMPLATES}`);
      } else if (selectedCard === "PROJECT") {
        this.props.history(`/${GLOBAL_SETTING}/${PROJECT_CATEGORIES}`);
      }
    });
  };
  addProjectType = () => {
    if (this.child && this.child.current) this.child.current.handleAddDialog();
  };
  render() {
    const { selectedCard } = this.state;
    return (
      <>
        <div className="purchase-order-block">
          {/* <div className="row">
            <div className="w-auto">
              <h2 className="content-heading text-uppercase">Global Setting</h2>
            </div> */}

          {/* {selectedCard === "PROJECT" && writePermission && (<div className="w-auto ms-auto mt-auto">
              <button onClick={this.addProjectType} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">
                Add Project Type
              </button>
            </div>)} */}
          {/* </div> */}

          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="LETTERHEAD"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(k) => { this.handleCardClick(k) }}
                activeKey={selectedCard}
              >
                <Tab eventKey="LETTERHEAD" title={`Company Setting`}></Tab>
                <Tab eventKey="FINANCE" title={`Finance and Tax`}></Tab>
                <Tab eventKey="TEMPLATES" title={`Template Setting`}></Tab>
                <Tab eventKey="PROJECT" title={`Project Categories`}></Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Routes>
            <Route path={EDIT_LETTERHEAD} element={<LetterHead {...this.props} />} />
            <Route path={""} element={<LetterHead {...this.props} />} />
            <Route path={GLOBAL_FINANCE} element={<Finance {...this.props} />} />
            <Route path={EDIT_TEMPLATES} element={<EditTemplates {...this.props} />} />
            <Route path={PROJECT_CATEGORIES} element={<ProjectCategories {...this.props} ref={this.child} />} />
          </Routes>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
