import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { addAsset } from "../../actions/asset-management.action";
import Modal from "../../components/modal/modal";
import "./scss/asset-management.scss";
import { withRouter } from "../../util/with-router";
import { ASSET_MANAGEMENT } from "../../constants/route-paths";
import DatePickerInput from "src/components/inputs/date-picker";
import SelectInput from "src/components/inputs/select";
import CustomInput from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";

const assetType = [
  {
    key: "Leased",
    value: "LEASED",
  },
  {
    key: "Purchased",
    value: "PURCHASED",
  },
];

const assetCategory = [
  {
    key: "Vehicle",
    value: "VEHICLE",
  },
  {
    key: "Plant & Equipment",
    value: "PLANT_AND_EQUIPMENT",
  },
];

class AddAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        category: {
          name: "category",
          label: "Catalog",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "",
          items: assetCategory,
          required: true,
        },
        name: {
          name: "name",
          label: "Asset Name",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        assetNo: {
          name: "assetNo",
          label: "Asset Registration No/ID",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        assetType: {
          name: "assetType",
          label: "Asset Type",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "",
          items: assetType,
          required: true,
        },
        purchasingDate: {
          name: "purchasingDate",
          label: "Purchasing Date",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: "",
          clearable: true,
        },
        expiryDate: {
          name: "expiryDate",
          label: "Insurance/Registration Date",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: "",
          clearable: true,
        },
        description: {
          name: "description",
          label: "Notes/Description",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
      },
    };
  }

  inputChangeHandler = (e, fieldName = "") => {
    let fields = this.state.fields;
    var name;
    var value;
    if (fieldName === "purchasingDate" || fieldName === "expiryDate") {
      name = fieldName;
      value = e;
    } else if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    fields[name].value = value;
    fields[name].touched = true;

    this.setState({ fields });
  };

  handleValidation = ({ name, value, required }) => {
    let error = false;
    let valid = true;

    if (required && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }

    return { error, valid };
  };

  submitForm = async () => {
    const { fields } = this.state;
    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = {
        name: fields.name.value,
        assetNo: fields.assetNo.value,
        category: fields.category.value,
        assetType: fields.assetType.value,
        purchasingDate: fields.purchasingDate.value || "",
        expiryDate: fields.expiryDate.value || "",
        description: fields.description.value,
      };

      await this.props.addAsset(data).then(() => {
        this.handleClose();
      }).catch(e => console.error(e));
    }
  };

  handleClose = () => {
    this.props.history(`/${ASSET_MANAGEMENT}`);
  };

  render() {
    const { fields } = this.state;

    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        onClick={this.submitForm}
        modalHeading={"Add Asset"}
        modalSaveTxt={"Save"}
        modalCloseTxt="Cancel"
        showActionBtn={true}
        fullWidth={true}
        maxWidth={"md"}
        cxlbtnClass="cancelBtn"
        savebtnClass="createBtn"
        primary
      >
        {this.props.writePermission && (
          <div className="purchase-order-block">
            <div className="row">
              <div className="col-lg-4 mt-3">
                <SelectInput
                  {...fields.category}
                  onChange={(e) => this.inputChangeHandler(e, fields.category.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <CustomInput
                  {...fields.name}
                  onChange={(e) => this.inputChangeHandler(e, fields.name.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <CustomInput
                  {...fields.assetNo}
                  onChange={(e) => this.inputChangeHandler(e, fields.assetNo.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <SelectInput
                  {...fields.assetType}
                  onChange={(e) => this.inputChangeHandler(e, fields.assetType.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <DatePickerInput
                  {...fields.purchasingDate}
                  onChange={(e) => this.inputChangeHandler(e, fields.purchasingDate.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <DatePickerInput
                  {...fields.expiryDate}
                  onChange={(e) => this.inputChangeHandler(e, fields.expiryDate.name)}
                />
              </div>
              <div className="col-12 mt-3">
                <TextAreaInput
                  {...fields.description}
                  onChange={(e) => this.inputChangeHandler(e, fields.description.name)}
                />
              </div>
            </div>

          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addAsset: bindActionCreators(addAsset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAsset)
);
