import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ADD_MATERIAL, CATALOGS, MATERIAL } from "src/constants/route-paths";
import {
  deleteItem,
  getCategories,
  editItem,
} from "src/actions/inventory.action";
import DeleteDialog from "src/components/delete-dialog";
import AddCategory from "./add-category";
import { Link } from "react-router-dom";
import { numberFormat } from "src/util/currency-formatter";
import { withRouter } from "src/util/with-router";
import { Input } from "@mui/material";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CustomTable from "src/components/table/table";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

function MaterialList({ writePermission, ...props }) {

  const [state, setState] = useState({
    open: false,
    internal: [],
    external: [],
    deleteDialogState: false,
    categoryDialogState: false,
    dynamiclookup: {},
    categories: {},
    filter: []
  })

  useEffect(() => {
    let id = props.searchParams.get('id');
    getInventoryList().then(categories => {
      setState(prev => ({
        ...prev,
        categories,
        ...(id ? { filter: [id] } : {}),
      }))
      if (id) getInventoryList(id)
    });
  }, [])

  const getInventoryList = (categoryFilter) => {
    return props.getCategories(categoryFilter).then((categories) => {
      let internal = [], external = [];
      const data = {};
      const allCategories = JSON.parse(JSON.stringify(categories));
      let sno = 0;
      allCategories.forEach((category, i) => {
        category.items.forEach((item, index) => {
          sno = sno + 1;
          item.sno = sno;
          item.categoryId = category.id;
          item.category = category.name;
          item.cost = `${numberFormat(item.cost)}/${item.unit}`;
          /*item.recommendedCost = `${numberFormat(item.recommendedCost)}/${item.unit}`*/
          if (category.vendor_id) {
            external.push(item)
          } else {
            internal.push(item)
          }
        });
        data[category.id] = category.name;
      });
      setState(prev => ({
        ...prev,
        internal,
        external,
        dynamiclookup: data,
      }));
      return data;
    });
  };

  const categoryDialogHandler = (id = "") => {
    getInventoryList();
    setState(prev => ({
      ...prev,
      categoryDialogState: !prev.categoryDialogState,
    }));
  };

  const deleteDialogHandler = (id = "") => {
    setState(prev => ({
      ...prev,
      deleteDialogState: !prev.deleteDialogState,
      deleteId: id,
    }));
  };

  const deleteHandler = (id) => {
    props.deleteItem(id).then((res) => {
      setState(prev => ({
        ...prev,
        deleteDialogState: false,
      }));
      getInventoryList();
    });
  };

  const addItem = () => {
    props.history(`/${CATALOGS}/${ADD_MATERIAL}`);
  };

  const editItem = (rowData) => {
    props.history(`/${CATALOGS}/${MATERIAL}/edit/${rowData.id}`);
  };

  const changeFilter = (categoryFilter = []) => {
    setState(prev => ({
      ...prev,
      filter: categoryFilter
    }));
    getInventoryList(encodeURI(categoryFilter.join(",")));
  };

  const columns = [
    { title: "S No", field: "sno", filtering: false, class: 'w-auto' },
    {
      title: "Item",
      field: "item",
      filtering: false,
      class: 'w-auto',
      render: (rowData) => (
        <Link to={`/${CATALOGS}/${MATERIAL}/edit/${rowData.id}`}>
          {rowData.item}
        </Link>
      ),
    },
    {
      title: "Catalog",
      field: "categoryId",
      lookup: state.dynamiclookup,
      render: (rowData) => state.dynamiclookup[rowData.categoryId]
    },
    { title: "Manufacturer", field: "manufacturer", filtering: false },
    { title: "Part No.", field: "partNo", filtering: false },
    { title: "Std. cost", field: "cost", filtering: false },
    /* {
      title: 'Rec. cost', field: 'recommendedCost', filtering: false,
      render: rowData => {
        return (
          <span>
            {rowData.recommendedCost}<Tooltip title='Recommended cost - Latest cost received for an item through a RFQ'>
              <InfoIcon fontSize='small' className="tableInfoIcon" />
            </Tooltip>
          </span>
        )
      }
    },*/
    {
      title: "Sale Lock",
      field: "saleAmountLock",
      render: (rowData) => rowData.saleAmountLock ? <span className="Lock-Icon-style">
        <LockIcon color="success"></LockIcon>
      </span> : <span className="Lock-Icon-style">
        <LockOpenIcon color="warning"></LockOpenIcon>
      </span>
    },
    {
      title: "Status",
      field: "status",
      filtering: false,
      render: (rowData) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input switch-checkbox"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={rowData.status === "ACTIVE" ? true : false}
            disabled={writePermission}
            onChange={(e) => {
              props.editItem({ status: e.target.checked ? "ACTIVE" : "INACTIVE" }, rowData.id)
                .then((data) => {
                  getInventoryList();
                });
            }}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
      ),
    },
  ];

  return (
    <>
      <AddCategory
        open={state.categoryDialogState}
        showList={true}
        dialogClose={categoryDialogHandler}
      />

      <DeleteDialog
        open={state.deleteDialogState}
        id={state.deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName="Item"
      />
      <div className="purchase-order-block">
        <div className="row catalog-select">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Materials</h2>
          </div>
          {writePermission && (<div className="w-auto ms-auto mt-auto w-res-100">
            <FormControl sx={{ width: 200 }} className="me-2">
              <InputLabel size="small" focused={false} id="demo-multiple-checkbox-label" >Catalog Filter</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={state.filter}
                onChange={(e) => changeFilter(e.target.value)}
                input={<Input className="mt-1" label={'Catalog Filter'} />}
                renderValue={(selected) => selected.map(val => state.categories[val]).join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 200,
                    },
                  },
                }}
              >
                {Object.entries(state.categories).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={state.filter.indexOf(key) > -1} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <button onClick={addItem} className="secondarybtn secondarybtn-outline btn-ch me-2 btn btn-primary">Add Item</button>
            <button onClick={categoryDialogHandler} className="secondarybtn secondarybtn-outline btn-ch btn btn-primary">View Catalogs</button>
          </div>)}
        </div>

        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="external"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="external" title={`External Materials (${state.external.length})`}>
                <CustomTable
                  columns={columns}
                  data={state.external}
                  isEdit={writePermission}
                  editHandler={editItem}
                  fileName='External Material List'
                />
              </Tab>
              <Tab eventKey="internal" title={`Internal Materials (${state.internal.length})`}>
                <CustomTable
                  columns={columns}
                  data={state.internal}
                  isEdit={writePermission}
                  editHandler={editItem}
                  fileName='Internal Material List'
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionCreators(deleteItem, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  editItem: bindActionCreators(editItem, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialList));
