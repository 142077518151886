import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { getAsset, assetRepair } from "../../actions/asset-management.action";
import { errorSnackBar } from "./../../actions/common.action";
import Modal from "../../components/modal/modal";
import DeleteIcon from '@mui/icons-material/Delete';
import "./scss/asset-management.scss";
import FileUploader from "../../components/file-uploader";
import { withRouter } from "../../util/with-router";
import { ASSET_MANAGEMENT } from "../../constants/route-paths";
import DatePickerInput from "src/components/inputs/date-picker";
import CustomInput, { AmountInput } from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";
import { fileValidator } from "src/util/validators/file";

const assetCategory = {
  VEHICLE: "Vehicle",
  PLANT_AND_EQUIPMENT: "Plant & Equipment",
};

class AssetRepair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        serviceDetail: {
          name: "serviceDetail",
          label: "Service Detail",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        repairExpense: {
          name: "repairExpense",
          label: "Repair Expense",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
        vehicleKilometre: {
          name: "vehicleKilometre",
          label: "Vehicle Kilometre",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
        repairDate: {
          name: "repairDate",
          label: "Repair Date:",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: new Date(),
          clearable: true,
        },
      },
      assetId: "",
      assetData: {},
      fileToUpload: [],
    };
  }

  async componentDidMount() {
    if (this.props.params.assetId) {
      const assetId = this.props.params.assetId;
      const assetData = await this.props.getAsset(assetId);

      this.setState({
        assetData,
        assetId,
        maintenanceDescription:
          assetData.assetMaintenance && assetData.assetMaintenance.description,
      });
    }
  }

  inputChangeHandler = async (e, fieldName = "") => {
    let fields = this.state.fields,
      typeName = "";
    var name;
    var value;
    if (fieldName === "repairDate") {
      name = fieldName;
      value = e;
    } else if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    fields[name].value = value;
    fields[name].touched = true;

    this.setState({ fields, ...(typeName && { typeName: typeName }) });
  };

  handleValidation = ({ name, value, required }) => {
    let error = false;
    let valid = true;

    if (required && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }
    if (name === "repairExpense" && _.isNumber(value)) {
      return { error: "This Field should be a valid Number!", valid: false };
    }

    return { error, valid };
  };

  submitForm = async () => {
    const { fields, assetData, fileToUpload } = this.state;
    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    const maintenanceId =
      _.isObject(assetData.assetMaintenance) && assetData.assetMaintenance.id;

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = new FormData();
      data.set("serviceDetail", fields.serviceDetail.value);
      data.set("repairExpense", parseFloat(fields.repairExpense.value) || "");
      data.set("maintenanceId", maintenanceId);
      data.set(
        "vehicleKilometre",
        parseFloat(fields.vehicleKilometre.value) || ""
      );
      data.set("repairDate", fields.repairDate.value || "");

      for (let i = 0; i < fileToUpload.length; i++) {
        data.append(`attachment`, fileToUpload[i]);
      }

      await this.props.assetRepair(data).catch(e => console.log(e));
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history(`/${ASSET_MANAGEMENT}`);
  };

  handleSelectFile = () => {
    document.getElementById("fileInput").click();
  };

  handleFileUpload = (e) => {
    let isValid = true;
    let [files, acceptedFiles, { fileToUpload }] = [e.target.files, [], this.state]
    if (fileToUpload.length + 1 > 5 || files.length > 5) this.props.errorSnackBar("You Can not upload more than 5 files at a time!");
    else {
      Array.isArray(files) && files.map((file) => {
        const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
        const { validFileSize, validFileType, error } = fileValidation
        if (!validFileSize.valid && !error) {
          isValid = false
          this.props.errorSnackBar(validFileSize.error)
        }
        else if (!validFileType.valid && !error) {
          isValid = false
          this.props.errorSnackBar(validFileType.error)
        } else if (error) {
          isValid = false
          this.props.errorSnackBar(error.error)
        }
        else acceptedFiles.push(file);
      });
    }
    if (!isValid) return

    this.setState({ fileToUpload: [...fileToUpload, ...acceptedFiles] });
    e.target.value = "";
  };

  handleRemoveFile = (index) => {
    let { fileToUpload } = this.state;
    fileToUpload.splice(index, 1);
    this.setState({ fileToUpload });
  };

  render() {
    const { fields, assetData, fileToUpload, maintenanceDescription } = this.state;

    const files = Array.isArray(fileToUpload) && fileToUpload.map((file, index) => (
      <span key={index}>
        <span>{file.name}</span>
        <span onClick={() => this.handleRemoveFile(index)}>
          <DeleteIcon />
        </span>
      </span>
    ));

    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        onClick={this.submitForm}
        modalHeading={"Asset Repaired"}
        modalSaveTxt={"Save"}
        modalCloseTxt="Cancel"
        showActionBtn={true}
        fullWidth={true}
        maxWidth={"md"}
        cxlbtnClass="cancelBtn"
        savebtnClass="createBtn"
        primary
      >
        {this.props.writePermission && (
          <div className="purchase-order-block">

            <div className="row">

              <div className="col-lg-3 mt-3">
                <div className="view-block">
                  <label>Asset Category</label>
                  <p>{assetCategory[assetData.category]}</p>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="view-block">
                  <label>Asset Name</label>
                  <p>{assetData.name}</p>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="view-block">
                  <label>Asset No</label>
                  <p>{assetData.assetNo}</p>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="view-block">
                  <label>Asset Description</label>
                  <p>{maintenanceDescription}</p>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <AmountInput
                  {...fields.repairExpense}
                  onChange={(e) => this.inputChangeHandler(e, fields.repairExpense.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <CustomInput
                  {...fields.vehicleKilometre}
                  onChange={(e) => this.inputChangeHandler(e, fields.vehicleKilometre.name)}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <DatePickerInput
                  {...fields.repairDate}
                  onChange={(e) => this.inputChangeHandler(e, fields.repairDate.name)}
                />
              </div>
              <div className="col-lg-6 col-md-6 mt-3 fileupload-section">
                <div className="file-section-part position-relative h-auto px-0">
                  <div className="file-part">
                    <FileUploader
                      fileHandler={(files) =>
                        this.handleFileUpload({
                          target: { files, type: "file", value: "" },
                        })
                      }
                    />
                    <label htmlFor="#file-section"></label>
                  </div>
                  <input type="file" id="file-section"></input>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-3">
                <div className="form-group">
                  <div className="input-block">
                    <label className="input-label">Uploaded Documents</label>
                    <p className="m-0 doc-info text-left">
                      {files}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <TextAreaInput
                  {...fields.serviceDetail}
                  onChange={(e) => this.inputChangeHandler(e, fields.serviceDetail.name)}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAsset: bindActionCreators(getAsset, dispatch),
  assetRepair: bindActionCreators(assetRepair, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetRepair)
);
