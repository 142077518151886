import idx from "idx";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../scss/project-management.scss";
import { Paper } from "@material-ui/core";
import OverviewDetails from "../overview-details";
import ProjectSummary from "../project-summary";
import ProjectMenu from "../project-menu";
import { Button } from "@mui/material";
import TaskList from "../task-list";
import { getProjectData } from "../utils/get-project";
import { getUserNamesData } from "../utils/get-user-name";
// import { getOptionsData } from "../utils/get-options";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function ViewProject(props) {

    const [viewProjectState, setViewProjectState] = useState({
        anchorEl: null,
        open: false,
        customstyle: "",
    })

    const { projectId } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const projectDetail = useSelector(state => state.projectDetail) || {}

    useEffect(() => {
        if (!projectId) return;
        initLoad()
    }, [projectId])

    const initLoad = async () => {
        getProjectData({ projectId, dispatch })
        getUserNamesData({ dispatch })

        if (projectId) {
            // getQuotationListData({ projectId, dispatch });
            // getWorkAllocationsData({ projectId, dispatch });
        }

    }


    const handleMenu = () => {
        setViewProjectState(prev => ({
            ...prev,
            anchorEl: true
        }));
    };

    // const ViewrfqDialogHandler = () => {
    //     navigate(`${props.moduleURL}/rfqview/${viewProjectState.projectId}`);
    // };

    const handleClose = () => {
        setViewProjectState(prev => ({
            ...prev,
            anchorEl: null, hasStages: null
        }));
    };
    const viewProjectList = () => navigate(`/projects?project-type=${idx(projectDetail, _ => _.project.projectDataType)}`)

    return (
        <div className="viewProject viewProject-ch">
            {Boolean(viewProjectState.anchorEl) && <ProjectMenu
                open={Boolean(viewProjectState.anchorEl)}
                anchorEl={viewProjectState.anchorEl}
                projectId={projectId}
                type={idx(projectDetail, _ => _.project.projectType)}
                dlp={idx(projectDetail, _ => _.project.dlp)}
                completed={idx(projectDetail, _ => _.project.completed)}
                lost={idx(projectDetail, _ => _.project.lost)}
                archived={idx(projectDetail, _ => _.project.archived)}
                projectType={idx(projectDetail, _ => _.project.projectDataType)}
                writePermission={props.updatePermission}
                history={navigate}
                onClose={handleClose}
                hasStages={idx(projectDetail, _ => _.project.hasStages)}
                menuType={"View"}
                customstyle={"project-view-menuStyle"}
            />}

            <div className="row">
                <div className="w-auto">
                    <div className="col-12 breadcrumb-block p-0">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
                            <Breadcrumb.Item active>{idx(projectDetail, _ => _.project.projectName)}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="w-auto ms-auto mt-auto">
                    <Button
                        variant="outlined"
                        onClick={handleMenu}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                    >
                        ...
                    </Button>
                </div>
            </div>

            <div className="view-project-page">
                {/* ---------------------progress financial---------------------------- */}
                <div
                    className="mobileViewProject"
                    style={{ background: "transparent" }}
                >
                    <Paper>
                        <ProjectSummary
                            projectId={projectId}
                            projectName={idx(projectDetail, _ => _.project.projectName)}
                            projectManagerName={idx(projectDetail, _ => _.project.projectManagerName)}
                            timeSpentProgress={idx(projectDetail, _ => _.project.timeSpentProgress)}
                            totalVariationAmount={idx(projectDetail, _ => _.project.totalVariationAmount)}
                            customerName={idx(projectDetail, _ => _.project.customerName) || ""}
                            siteAddress={idx(projectDetail, _ => _.project.siteAddress) || ""}
                            contactData={[idx(projectDetail, _ => _.project.contactData.firstName), idx(projectDetail, _ => _.project.contactData.lastName)].filter(item => item).join(' ')}
                            totalRevisedContractCost={idx(projectDetail, _ => _.project.calculations.revised_cost)}
                            totalClaimAmount={idx(projectDetail, _ => _.project.totalClaimAmount)}
                            totalRetentionAmount={idx(projectDetail, _ => _.project.totalRetentionAmount)}
                            progressCostMaterial={parseInt(idx(projectDetail, _ => _.project.progressCostMaterial))}
                            progressCostLabor={parseInt(idx(projectDetail, _ => _.project.progressCostLabor))}
                            progressCostSubcontractor={parseInt(idx(projectDetail, _ => _.project.progressCostSubcontractor))}
                            quoteType={idx(projectDetail, _ => _.project.quoteType)}
                            totalContract={parseFloat(idx(projectDetail, _ => _.project.calculations.total))}
                        />
                    </Paper>
                </div>
                {/* ----------------------task cards-------------------------- */}
                <div className="mobileViewProject" style={{ background: "transparent" }}>
                    <Paper className="main" style={{ boxShadow: "none", background: "transparent" }}>
                        <OverviewDetails
                            // handleRFQClick={ViewrfqDialogHandler}
                            totalRFQSend={idx(projectDetail, _ => _.project.totalRFQSend)}
                            totalVariation={idx(projectDetail, _ => _.project.totalVariations)}
                            // handlePoClick={poDialogHandler}
                            projectId={projectId}
                            quoteType={idx(projectDetail, _ => _.project.quoteType)}
                            progressClaimCount={idx(projectDetail, _ => _.project.progressClaimCount)}
                            purchaseOrdersCount={idx(projectDetail, _ => _.project.purchaseOrdersCount)}
                            workOrdersCount={idx(projectDetail, _ => _.project.workOrdersCount)}
                            invoicesCount={idx(projectDetail, _ => _.project.invoicesCount)}
                            financePermission={props.financePermission}
                            rFinancePermission={props.rFinancePermission}
                            rSupplierPermission={props.rSupplierPermission}
                            wSupplierPermission={props.wSupplierPermission}
                            rContractorPermission={props.rContractorPermission}
                            wContractorPermission={props.wContractorPermission}
                        />
                    </Paper>
                </div>

                {/* ---------------------table--------------------------- */}
                <div className="table-responsive tender-table">
                    <TaskList
                        {...props}
                        projectId={projectId}
                        callStepOne={() => {
                            // stepOne(viewProjectState.projectId)
                        }}
                    ></TaskList>
                </div>
            </div>
        </div>
    );
}
