import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { INVENTORY } from "./../../constants/route-paths";
import {
  getCategories,
  getItems,
  getUnits,
} from "./../../actions/inventory.action";
import Input from "./../../components/inputs/input-simple";
import {
  itemFields,
  categoriesFields,
  categoryItemFields,
} from "./../../constants/fields.constant";
import "./scss/inventory.scss";
import Buttons from "./../../components/buttons/buttons";
import { getVendorList } from "./../../actions/vendor-management.action";
import {
  saveRFQ,
  getRfq,
  editRFQ,
} from "./../../actions/project-management.action";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import ViewRFQ from "./view-rfq";
import { Paper } from "@material-ui/core";
import { warningSnackBar } from "./../../actions/common.action";
import AddMaterial from "../../util/add-material";
import FileUploader from "../../components/file-uploader";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Grid } from "@mui/material";
import { withRouter } from "../../util/with-router";
import { fileValidator } from "src/util/validators/file";

class CreateRFQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        description: {
          name: "description",
          value: "",
          type: "textarea",
          label: "Description",
        },
        attachments: {
          name: "attachments",
          label: "Attachments",
          type: "file",
          touched: false,
          error: false,
          valid: true,
          multiple: true,
          value: [],
          attachmentArr: [],
          validation: [],
        },
      },
      rfqId: null,
      categoriesList: [],
      itemsList: [],
      item: itemFields,
      categoryName: [],
      selectedItems: [],
      categoryItems: [],
      vendorContacts: [],
      selectedVendors: [],
      ViewRFQDialogState: false,
      items: [],
      categories: JSON.parse(JSON.stringify(categoriesFields)),
      categoryItem: JSON.parse(JSON.stringify(categoryItemFields)),
    };
  }

  async componentDidMount() {
    await this.props.getVendorList().then((vendors) => {
      const contacts = [];
      vendors &&
        vendors.forEach((vendor) => {
          vendor.contacts &&
            vendor.contacts.forEach((contact) => {
              if (contact.primary && vendor.type === "Supplier") {
                const title = contact.email + "(" + vendor.businessName + ")";
                const value = contact.email;
                const name = vendor.businessName;
                const vendorId = vendor.id;
                contacts.push({ title, value, name, vendorId });
              }
            });
        });
      this.setState({
        vendorContacts: contacts,
      });
    });
    if (this.props.params.rfqId) {
      this.props.getRfq(this.props.params.rfqId).then((rfq) => {
        this.editRFQ(rfq);
      });
    }
  }

  editRFQ = (rfq) => {
    const { fields, selectedVendors } = this.state;
    fields.description.value = rfq.description;
    fields.attachments.attachmentArr = [];
    rfq.attachment &&
      rfq.attachment.forEach((attach) => {
        if (_.isString(attach)) {
          fields.attachments.attachmentArr.push(JSON.parse(attach));
        } else {
          fields.attachments.attachmentArr.push(attach);
        }
      });

    const vendorValue = [];
    rfq.vendorList &&
      rfq.vendorList.forEach((vendor) => {
        if (_.isString(vendor)) {
          vendor = JSON.parse(vendor);
        }

        const title = vendor.title;
        const value = vendor.email;
        const name = vendor.name;
        const vendorId = vendor.vendorId;
        vendorValue.push({ title, value, name, vendorId });
        selectedVendors.push(vendor);
      });

    this.setState({
      fields,
      selectedVendors,
      rfqId: rfq.id,
      items: rfq.items,
    });
  };

  inputChangeHandler = (e, fieldName = "") => {
    let fields = this.state.fields;
    if (e.target.type === "file") {
      if (e.target.files.length > 10) {
        this.props.warningSnackBar("You Can not Upload more than 10 files");
      } else {
        const fileToUpload = e.target.files
        Array.isArray(fileToUpload) && fileToUpload.forEach(file => {
          const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
          const { validFileSize, validFileType, error } = fileValidation
          if (!validFileSize.valid && !error) this.props.warningSnackBar(validFileSize.error)
          else if (!validFileType.valid && !error) this.props.warningSnackBar(validFileType.error)
          else if (error) this.props.warningSnackBar(error.error)
          else fields[e.target.name].value.push(file);
        })
      }
    } else if (e.target.type === "checkbox") {
      fields[e.target.name].value = e.target.checked;
    } else {
      fields[e.target.name].value = e.target.value;
    }
    fields[e.target.name].touched = true;
    fields[e.target.name].error = false;
    fields[e.target.name].valid = true;
    this.setState({ fields });
  };

  removeFile = (index, type) => {
    const fields = this.state.fields;
    if (type === "new") {
      const data = [];
      for (var x = 0; x < fields.attachments.value.length; x++) {
        if (x !== index) {
          data.push(fields.attachments.value[x]);
        }
      }
      fields.attachments.value = data;
    }
    if (type === "old") {
      const data = fields.attachments.attachmentArr.filter(
        (doc, i) => i !== index
      );
      fields.attachments.attachmentArr = data;
    }
    this.setState({ fields });
  };

  seletectFile = () => {
    document.getElementById("fileInput").click();
  };

  getVendorEmail = (emailsData) => {
    const emails = [];
    emailsData &&
      emailsData.forEach((email) =>
        emails.push({
          email: email.value,
          name: email.name,
          vendorId: email.vendorId,
          title: email.title,
        })
      );
    this.setState({ selectedVendors: emails });
  };

  setCategoryName = (categoryName) => {
    this.setState({ categoryName });
  };

  setSelectedItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  submitHandler = (isDraft = false) => {
    const { fields, categoryName, selectedItems, selectedVendors, rfqId } =
      this.state;
    let isFormValid = true;
    let material = [];

    categoryName.map((category) => {
      const data = selectedItems[category.id].map((item) => ({
        item: item.name.value,
        id: item.id,
        categoryId: item.categoryId,
        manufacturer: item.manufacturer,
        partNo: item.partNo,
        cost: item.costPerItem.value,
        qty: item.qty.value,
        unit: item.unit.value,
        total: item.total.value,
      }));
      material = [...material, ...data];
    });

    if (!isDraft) {
      if (fields.description.value === "") {
        isFormValid = isFormValid && false;
        this.props.warningSnackBar("Descriprion is Required");
        return;
      }
      if (selectedVendors.length === 0) {
        isFormValid = isFormValid && false;
        this.props.warningSnackBar("Please Select Vendor");
        return;
      }
      const attachmentsLength =
        Array.isArray(fields.attachments.attachmentArr) &&
        fields.attachments.attachmentArr.length;
      const projectAttachmentsLength =
        Array.isArray(fields.attachments.value) &&
        fields.attachments.value.length;
      if (!material.length && !attachmentsLength && !projectAttachmentsLength) {
        isFormValid = isFormValid && false;
        this.props.warningSnackBar(
          "Please Select Atleast one Item or attachment"
        );
        return;
      }
    }
    if (isFormValid) {
      const formData = new FormData();
      formData.set("vendorType", "Supplier");
      formData.set("description", fields.description.value);
      formData.set("draft", isDraft);

      for (let i = 0; i < this.state.selectedVendors.length; i++) {
        formData.append(
          "vendorList[]",
          JSON.stringify(this.state.selectedVendors[i])
        );
      }

      for (let i = 0; i < material.length; i++) {
        formData.append("items[]", JSON.stringify(material[i]));
      }

      for (let i = 0; i < fields.attachments.attachmentArr.length; i++) {
        formData.append(
          "attachment[]",
          JSON.stringify(fields.attachments.attachmentArr[i])
        );
      }

      for (let x = 0; x < fields.attachments.value.length; x++) {
        formData.append("attachment", fields.attachments.value[x]);
      }

      if (rfqId) {
        this.props.editRFQ(formData, rfqId, isDraft ? "RFQ Updated Successfully" : "RFQ Send Successfully"
        ).then((res) => {
          if (!isDraft) this.cancelHandler();
        }).catch(e => console.log(e));
      } else {
        this.props
          .saveRFQ(formData, "", isDraft ? "RFQ Saved Successfully" : "RFQ Send Successfully").then((res) => {
            if (!isDraft) this.cancelHandler();
          }).catch(e => console.log(e));
      }
    }
  };

  showRFQList = () => {
    this.setState((prevState) => ({
      ViewRFQDialogState: !prevState.ViewRFQDialogState,
    }));
  };

  cancelHandler = () => {
    this.props.history(`/${INVENTORY}`);
  };

  render() {
    const { fields, vendorContacts, ViewRFQDialogState, vendorValue, items } = this.state;
    const files = [];
    const allAttachments = [];
    for (var x = 0; x < fields.attachments.value.length; x++) {
      const index = x;
      allAttachments.push({
        name: fields.attachments.value[x].name,
        url: null,
      });
      files.push(
        <div className="attachedFiles">
          <p style={{ margin: "0 10px 0 0" }}>
            {fields.attachments.value[x].name}
          </p>
          <p
            style={{ margin: "0", float: "right", cursor: "pointer" }}
            onClick={() => this.removeFile(index, "new")}
          >
            <DeleteIcon />
          </p>
        </div>
      );
    }

    const attachments = fields.attachments.attachmentArr.map((doc, i) => (
      <div className="attachedFiles">
        <p style={{ margin: "0 10px 0 0" }}>
          <a
            href={doc?.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {doc.name}
          </a>
        </p>
        <p
          style={{ margin: "0", cursor: "pointer", float: "right" }}
          onClick={() => this.removeFile(i, "old")}
        >
          <DeleteIcon />
        </p>
      </div>
    ));
    return (
      <div className="addCustomer createRfqContainer">
        {ViewRFQDialogState && (
          <ViewRFQ
            open={ViewRFQDialogState}
            {...this.props}
            dialogClose={this.showRFQList}
            isModal
          />
        )}
        <Grid container sx={{ m: 0, mb: 1 }}>
          <Grid item xs={12} sx={{ p: 0 }} textAlign={"start"}>
            <ArrowCircleLeftOutlinedIcon
              className="arrow-circle-icon"
              onClick={this.cancelHandler}
            />
            <span className="link" onClick={this.cancelHandler}>
              Inventory
            </span>
            <span className="arrow"> {"/"} </span>
            {"Create RFQ"}
          </Grid>
        </Grid>
        <Paper className="paperContainer">
          <Grid
            container
            className="subtaskrow-1"
            style={{ margin: "0px 0px 10px" }}
          >
            <Grid xs={8} item style={{ flexDirection: "row" }}>
              <Grid container>
                <Grid
                  xs={3}
                  item
                  className="stageLabel"
                  style={{ margin: "12px 0px 0px 0px" }}
                >
                  Description:
                </Grid>
                <Grid
                  xs={8}
                  item
                  className="stageLabel rfqdesc addStageDesc"
                  style={{
                    marginTop: "10px",
                    maxWidth: "100%",
                    width: "500px",
                  }}
                >
                  <Input
                    {...fields.description}
                    onChange={(e) => this.inputChangeHandler(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={4} item className="stageLabel attachFile ">
              <Grid container>
                <Grid xs={12} item style={{ alignItems: "unset" }}>
                  <FileUploader
                    fileHandler={(files) =>
                      this.inputChangeHandler({
                        target: { files, type: "file", name: "attachments" },
                      })
                    }
                  />
                  {files}
                  {attachments}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="vendorAutocomplete subtaskrow-1" container>
            <Grid xs={2} item>
              <p>Select Vendor</p>
            </Grid>
            <Grid xs={8} item>
              <Input
                label=""
                variant="outlined"
                margin="dense"
                value={vendorValue}
                type="autoComplete"
                style={{ width: "400px" }}
                options={vendorContacts}
                onChange={(event, values) => {
                  this.getVendorEmail(values);
                }}
              />
            </Grid>
          </Grid>
          <AddMaterial
            items={items}
            setCategoryName={this.setCategoryName}
            setSelectedItems={this.setSelectedItems}
          />
          {this.props.writePermission && (
            <Grid container className="buttonContainer">
              <Grid xs={2} item>
                <p className="cancel-btn-white" onClick={this.showRFQList}>
                  RFQ List
                </p>
              </Grid>
              <Grid
                xs={10}
                item
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  className="cancel-btn"
                  onClick={() => this.submitHandler(true)}
                >
                  Save as Draft
                </p>
                <p className="cancel-btn" onClick={this.cancelHandler}>
                  Cancel
                </p>
                <Buttons
                  className="cancel-btn"
                  title="Send"
                  color="secondary"
                  onClick={() => this.submitHandler()}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getItems: bindActionCreators(getItems, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getUnits: bindActionCreators(getUnits, dispatch),
  getVendorList: bindActionCreators(getVendorList, dispatch),
  saveRFQ: bindActionCreators(saveRFQ, dispatch),
  editRFQ: bindActionCreators(editRFQ, dispatch),
  getRfq: bindActionCreators(getRfq, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateRFQ)
);
