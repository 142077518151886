import { ValidatorTypes } from "src/util/validators";

export const subContractorInputFields = {
    vendorType: {
        name: "vendorType",
        label: "Select Vendor Type",
        items: [],
        type: "select",
        touched: false,
        error: false,
        valid: true,
        value: "sub-contractor",
        validation: [ValidatorTypes.REQUIRED],
    },
    businessName: {
        name: "businessName",
        label: "Business Name",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    abn: {
        name: "abn",
        label: "Enter 11 digit ABN Number",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.OPTIONAL, ValidatorTypes.ABN],
    },
    street_address: {
        name: "street_address",
        label: "Street Address",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    suburb: {
        name: "suburb",
        label: "Suburb",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    state: {
        name: "state",
        label: "State",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    postcode: {
        name: "postcode",
        label: "Postcode",
        type: "number",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    country: {
        name: "country",
        label: "Country",
        type: "address",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED],
    },
    phoneNumber: {
        name: "phoneNumber",
        label: "Phone Number",
        type: "text",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED, ValidatorTypes.PHONE_NUMBER],
    },
    email: {
        name: "email",
        label: "Email",
        type: "email",
        touched: false,
        error: false,
        valid: true,
        value: "",
        validation: [ValidatorTypes.REQUIRED, ValidatorTypes.EMAIL],
    },
}
