import {
  allocateSchedule as allocateScheduleAPI,
  updateTimesheetTask as updateTimesheetTaskAPI,
  allocateResource as allocateResourceAPI,
  deleteResource as deleteResourceAPI,
  getResourceAllocations as getResourceAllocationsAPI,
  approveTimesheet as approveTimesheetAPI,
  editTimesheet as editTimesheetAPI,
  applyLeave as applyLeaveAPI,
  getUserLeaves as getUserLeavesAPI
} from '../api/timesheet.api'

export const allocateResource = (data) => {
  return (dispatch, getState) => {
    return allocateResourceAPI(data)
  }
}
export const allocateSchedule = (data) => {
  return (dispatch, getState) => {
    return allocateScheduleAPI(data)
  }
}
export const updateTimesheetTask = (data) => {
  return (dispatch, getState) => {
    return updateTimesheetTaskAPI(data)
  }
}
export const deleteResource = (type, allocationId) => {
  return (dispatch, getState) => {
    return deleteResourceAPI(type, allocationId)
  }
}

export const getResourceAllocations = (params) => {
  return (dispatch, getState) => {
    params['allowedModules'] = getState().project.allowedModules || [];
    return getResourceAllocationsAPI(params)
  }
}

export const approveTimesheet = (id, data) => {
  return (dispatch, getState) => {
    return approveTimesheetAPI(id, data)
  }
}

export const editTimesheet = (id, data) => {
  return (dispatch, getState) => {
    return editTimesheetAPI(id, data)
  }
}

export const applyLeave = (data) => {
  return (dispatch, getState) => {
    return applyLeaveAPI(data)
  }
}

export const getUserLeaves = (params) => {
  return (dispatch, getState) => {
    return getUserLeavesAPI(params)
  }
}
