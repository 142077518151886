import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import idx from 'idx'
import { v4 as uuidv4 } from 'uuid'
import { getCategories, getItems, getUnits, addItem } from '../actions/inventory.action'
import { warningSnackBar, errorSnackBar, successSnackBar } from '../actions/common.action'
import { getFormatedUnit } from './index'
import {
    itemFields,
    categoriesFields,
    categoryItemFields,
} from '../constants/fields.constant'
import AddItemDialog from '../routes/project-management/add-item-dialog'
import DeleteIcon from '@mui/icons-material/Delete';
import Table from 'react-bootstrap/Table';
import { filterManufacturer, filterItems } from './common'
import '../routes/project-management/scss/project-management.scss'
import '../routes/project-management/scss/task.scss'
import SaveIcon from '@mui/icons-material/Save';
import SelectInput from 'src/components/inputs/select'
import Button from 'react-bootstrap/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form'
import AddIcon from '@mui/icons-material/Add';
import CustomInput from 'src/components/inputs/new-input'
import { toUSD } from './currency-formatter'

const filter = createFilterOptions();

class AddMaterialPO extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            categoriesList: [],
            itemsList: [],
            item: itemFields,
            newunitList: [],
            newunitvalue: '',
            newtotalvalue: 0,
            newitemid: '',
            newItem: false,
            checked: true,
            categoryName: [],
            selectedItems: [],
            categoryItems: [],
            showAddItem: [],
            addItemDialogState: false,
            categories: JSON.parse(JSON.stringify(categoriesFields)),
            categoryItem: JSON.parse(JSON.stringify(categoryItemFields)),
            categoryId: '',
            poData: {},
            isInlineItem: false,
            inlineItemIndex: -1,
            itemValue: {},
            filterValue: {}
        }
    }

    async componentDidMount() {
        let { categories, item } = this.state
        let { poData } = this.props
        const categoriesResult = await this.props.getCategories()
        const categoriesList = categoriesResult.map(category => (
            {
                key: category.name,
                name: category.name,
                value: category.id
            }
        ))
        categories.items = categoriesList

        const itemsList = await this.props.getItems()

        const unitsResult = await this.props.getUnits()
        const allUnits = idx(unitsResult, _unitsRes => _unitsRes[0].units) || []
        const units = getFormatedUnit(allUnits)
        item.unit.items = units

        this.handleRFQPurchaseOrder(poData)
        const newunitList = units
        this.setState({ categories, categoriesList, itemsList, item, allUnits, newunitList })
    }

    setChecked = (checked) => {
        this.setState({ checked: checked })
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.taskItems, this.props.taskItems)) {
            this.handleTaskPO(this.props.taskItems)
        }
    }

    handleTaskPO = (taskItems = []) => {
        let { selectedItems, item, itemValue } = this.state

        if (Array.isArray(taskItems) && taskItems.length) {
            taskItems.map(_item => {
                if (selectedItems.some(item => item.taskId == _item.taskId && item.id == _item.id)) {
                    this.props.warningSnackBar("This Item is Already Selected");
                    return;
                }
                itemValue[_item.id] = {
                    key: _item.item,
                    value: _item.id
                }
                const newItem = JSON.parse(JSON.stringify(item))
                newItem.taskName = _item.taskName
                newItem.taskDescription = _item.taskDescription
                newItem.taskType = _item.taskType
                newItem.taskId = _item.taskId
                newItem.task = {
                    error: '',
                    valid: true
                }
                newItem.name.value = _item.item
                newItem.id = _item.id
                newItem.manufacturer = _item.manufacturer
                newItem.partNo = _item.partNo || _item.part_no
                newItem.categoryId = _item.categoryId
                newItem.costPerItem.value = parseFloat(_item.cost) || 0
                newItem.costPerItem.valid = true
                newItem.costPerItem.error = ''
                newItem.unit.value = _item.unit
                newItem.qty.value = parseInt(_item.qty) || 0
                newItem.qty.valid = true
                newItem.qty.error = ''
                newItem.total.value = newItem.qty.value * newItem.costPerItem.value;
                newItem.isInlineItem = _item.isInlineItem || false
                selectedItems.push(newItem)
            })
        }
        this.setState({ selectedItems, itemValue })
    }

    handleRFQPurchaseOrder = (poData = {}) => {
        let { selectedItems, item, itemValue } = this.state

        const rfqItems = idx(poData, _poData => _poData.items) || []

        if (Array.isArray(rfqItems) && rfqItems.length) {
            rfqItems.map(_item => {
                itemValue[_item.id] = {
                    key: _item.item,
                    value: _item.id
                }
                const newItem = JSON.parse(JSON.stringify(item))
                newItem.taskName = ''
                newItem.taskDescription = ''
                newItem.taskType = ''
                newItem.taskId = ''
                newItem.task = {
                    error: '',
                    valid: true
                }
                newItem.name.value = _item.item
                newItem.id = _item.id
                newItem.manufacturer = _item.manufacturer
                newItem.partNo = _item.partNo || _item.part_no
                newItem.categoryId = _item.categoryId
                newItem.costPerItem.value = parseFloat(_item.cost)
                newItem.costPerItem.valid = true
                newItem.costPerItem.error = ''
                newItem.unit.value = _item.unit
                newItem.qty.value = parseInt(_item.qty) || 0
                newItem.qty.valid = true
                newItem.qty.error = ''
                newItem.total.value = newItem.qty.value * newItem.costPerItem.value;
                newItem.isInlineItem = _item.isInlineItem || false
                selectedItems.push(newItem)
            })
        }

        this.setState({ selectedItems, itemValue })
    }

    changeTaskHandler = (index = -1, id = '') => {
        let { selectedItems, item } = this.state
        const { tasksList = [] } = this.props
        const _task = tasksList.find(_task => _task.value === id) || {}

        if (!id)
            return

        if (index === -1) {
            const newItem = JSON.parse(JSON.stringify(item))
            newItem.taskName = _task.key
            newItem.taskDescription = _task.taskDescription
            newItem.taskType = _task.taskType
            newItem.taskId = id
            newItem.task = {
                error: '',
                valid: true
            }
            newItem.categoryId = ''
            newItem.name.value = ''
            newItem.manufacturer = ''
            newItem.partNo = ''
            newItem.costPerItem.value = ''
            newItem.unit.value = ''
            newItem.qty.value = ''
            newItem.total.value = ''
            selectedItems.push(newItem)
        } else if (index > -1) {
            if (Array.isArray(selectedItems) && selectedItems.length > index) {
                selectedItems[index].taskName = _task.key
                selectedItems[index].taskDescription = _task.taskDescription
                selectedItems[index].taskType = _task.taskType
                selectedItems[index].taskId = id
                selectedItems[index].task = {
                    error: '',
                    valid: true
                }
            }
        }
        this.setState({ selectedItems })
    }

    handleCategoryChange = (index = -1, categoryId = '', itemId = '', key = '') => {
        let { selectedItems, itemsList, categoryItems, itemValue, filterValue } = this.state
        if (index > -1 && Array.isArray(selectedItems) && selectedItems.length > index) {
            selectedItems[index].categoryId = categoryId
            selectedItems[index].catalog = key
            selectedItems[index].id = ''
            selectedItems[index].qty.value = ''
            selectedItems[index].costPerItem.value = ''
            const categoryItemList = Array.isArray(itemsList) && itemsList.filter(item => item.categoryId === categoryId).map(categoryItem => ({
                key: categoryItem.item,
                value: categoryItem.id
            }))
            categoryItems[categoryId] = categoryItemList
            filterValue.categoryId = categoryId;

        }
        if (itemId) {
            delete itemValue[itemId]
        }
        this.setState({ selectedItems, categoryItems, itemValue, filterValue })
    }

    AddItemDialogHandler = (index, item = '', categoryId = '') => {
        this.setState(prevState => ({
            addItemDialogState: !prevState.addItemDialogState,
            categoryId: categoryId,
            itemName: item,
            inlineItemIndex: index
        }))
    }

    AddItemDialogClose = (res = {}, inlineItem = false) => {
        if (!_.isEmpty(res) && !inlineItem) {
            const { itemsList, inlineItemIndex } = this.state
            res.categoryId = res.category_id
            itemsList.push(res)
            this.setState({ itemsList, filterValue: {} })
            this.handleAddItem(inlineItemIndex, { value: res.id })
        } else if (!_.isEmpty(res) && inlineItem) {
            this.AddInlineItem(res)
        }
        this.setState({
            addItemDialogState: false,
            isInlineItem: false,
            inlineItemIndex: -1
        })
    }

    addFilter = (value, type) => {
        let { filterValue } = this.state;
        if (!filterValue) filterValue = {};
        if (filterValue && filterValue[type] !== value) {
            filterValue[type] = value;
            if (filterValue.noresult) {
                if (type == "unit") {
                    const newunitvalue = value;
                    this.setState({ newunitvalue })
                }
                if (type === "costPerItem" || type === "qty") {
                    const newtotalvalue = parseInt(filterValue['qty']) * parseFloat(filterValue['costPerItem'])
                    this.setState({ newtotalvalue })
                }
            }
            this.setState({ filterValue });
            this.itemsDropDownOption(true);
        }
    }
    manufacturerDropDown = () => {
        const { filterValue, itemsList } = this.state;
        let filterOptions = filterManufacturer(itemsList, filterValue)
        if (filterOptions.length === 0) {
            this.setFilterResult(true, '');
        } else {
            this.setFilterResult(false, '');
        }
        return filterOptions;
    }

    itemsDropDownOption = (skip) => {
        const { filterValue, itemsList } = this.state;
        let filterOptions = filterItems(itemsList, filterValue)
        if (filterOptions.length === 0) {
            this.setFilterResult(true, 'There are no items for your filter');
        } else {
            this.setFilterResult(false, '');
        }
        return filterOptions;
    }
    setFilterResult = (value, msg) => {
        const { filterValue } = this.state;
        let prevValue = idx(filterValue, _filterValue => _filterValue['noresult']);
        if (prevValue !== value) {
            filterValue['noresult'] = value;
            filterValue['noresult_msg'] = msg;
            this.setState({ filterValue })
        }
    }

    handleAddItem = (index = -1, newValue = {}) => {
        let { selectedItems, itemsList, itemValue } = this.state
        const itemId = newValue.value
        if (index > -1 && Array.isArray(selectedItems) && selectedItems.length > index) {
            if (idx(selectedItems, _selectedItems => _selectedItems[index].id)) {
                delete itemValue[idx(selectedItems, _selectedItems => _selectedItems[index].id)]
            }
            if (itemValue[itemId]) {
                this.props.warningSnackBar('Item Already Selected!')
                return
            }
            itemValue[itemId] = newValue
            const item = (Array.isArray(itemsList) && itemsList.find(_item => _item.id === itemId)) || {}

            selectedItems[index].name.value = item.item
            selectedItems[index].id = item.id
            selectedItems[index].manufacturer = item.manufacturer
            selectedItems[index].partNo = item.partNo || item.part_no
            selectedItems[index].categoryId = item.categoryId
            selectedItems[index].costPerItem.value = item.cost
            selectedItems[index].costPerItem.valid = true
            selectedItems[index].costPerItem.error = ''
            selectedItems[index].unit.value = item.unit
            selectedItems[index].qty.value = item.qty || 0
            selectedItems[index].qty.valid = true
            selectedItems[index].qty.error = ''
            selectedItems[index].total.value = item.total || 0
            selectedItems[index].isInlineItem = item.isInlineItem || false
        }

        this.setState({ selectedItems, itemValue, filterValue: {} })
    }

    removeItem = (index = -1) => {
        let { selectedItems, itemValue } = this.state

        if (index > -1 && Array.isArray(selectedItems) && selectedItems.length > index) {
            delete itemValue[selectedItems[index].id]
            selectedItems.splice(index, 1)
        }

        this.setState({ selectedItems: [...selectedItems] || [], itemValue })
    }

    itemChangeHandler = (index, e) => {
        const { selectedItems, filterValue } = this.state

        if (index > -1 && Array.isArray(selectedItems) && selectedItems.length > index) {
            if (e.target.name === 'costPerItem' || e.target.name === 'qty') {
                if (e.target.value < 0)
                    return
            }
            if (e.target.name === "unit") {
                const newunitvalue = e.target.value;
                filterValue.unit = e.target.value;
                this.setState({ newunitvalue })
            }

            selectedItems[index][e.target.name].value = e.target.value
            selectedItems[index][e.target.name].error = ''
            selectedItems[index][e.target.name].valid = true
            selectedItems[index]['total'].value = parseInt(selectedItems[index]['qty'].value) * parseFloat(selectedItems[index]['costPerItem'].value)
        }
        this.setState({ selectedItems, filterValue })
    }
    showAddItem = () => {
        if (this.state.filterValue.noresult) {
            if (!this.state.checked && this.state.filterValue.categoryId && !this.state.filterValue.id) {
                return this.props.errorSnackBar('Please select an Item')
            }
            if (this.state.checked && (!this.state.filterValue.categoryId)) {
                return this.props.errorSnackBar('Please select a Catalogue')
            }
            if (this.state.checked && this.state.filterValue.categoryId) {
                this.submitItemForm()
            }
            if ((!this.state.checked && !this.state.filterValue.categoryId)) {
                this.AddInlineItem(this.state.filterValue)
                this.props.successSnackBar('Task Item Added Successfully')
            }
        }
        const filterValue = {};
        this.setState({ showAddItem: true, filterValue, newtotalvalue: 0, newunitvalue: '' })
    }

    submitForm = () => {
        const { selectedItems } = this.state
        const { isSkippable = false } = this.props

        if (!isSkippable && Array.isArray(selectedItems) && !selectedItems.length) {
            this.props.warningSnackBar('Select task and item to Generate PO!')
        } else if (Array.isArray(selectedItems)) {
            let isFormValid = true
            selectedItems.map(_item => {
                if (!_item.taskId) {
                    _item.task.valid = false
                    _item.task.error = 'Error!'
                    isFormValid = isFormValid && false
                }

                const { error: cpiError, valid: cpiValid } = this.handleValidation(_item.costPerItem)
                _item.costPerItem['error'] = cpiError
                _item.costPerItem['valid'] = cpiValid
                _item.costPerItem.touched = true
                isFormValid = isFormValid && cpiValid

                const { error: qtyError, valid: qtyValid } = this.handleValidation(_item.qty)
                _item.qty.error = qtyError
                _item.qty.valid = qtyValid
                _item.qty.touched = true
                isFormValid = isFormValid && qtyValid
            })
            if (!isFormValid) {
                this.setState({ selectedItems })
            } else {
                this.props.submitForm(selectedItems)
            }
        }
    }

    handleValidation = ({ type, value }) => {
        let error = false
        let valid = true

        if (type === 'number') {
            if (isNaN(value) || value <= 0) {
                return { error: 'Error!', valid: false }
            }
        }
        return { error, valid }
    }

    viewPoList = () => {
        this.props.viewPoList()
    }

    handleInlineItem = (index) => {
        this.setState(prevState => ({
            addItemDialogState: !prevState.addItemDialogState,
            isInlineItem: true,
            inlineItemIndex: index
        }))
    }

    AddInlineItem = (data) => {
        if (data.noresult) {
            const { selectedItems } = this.state
            const indexitem = selectedItems.findIndex(obj => obj.id === '')
            selectedItems[indexitem].name.value = data.item
            selectedItems[indexitem].id = uuidv4()
            selectedItems[indexitem].manufacturer = data.manufacturer || ''
            selectedItems[indexitem].partNo = data.partNo
            selectedItems[indexitem].categoryId = 'INLINE_ITEM'
            selectedItems[indexitem].catalog = 'INLINE_ITEM'
            selectedItems[indexitem].unit.value = data.unit
            selectedItems[indexitem].isInlineItem = true
            selectedItems[indexitem].newItem = false
            this.setState({ selectedItems, showAddItem: false })
        }
        this.setState({ checked: true })
    }

    submitItemForm = () => {
        const { filterValue } = this.state
        const data = {
            categoryId: this.state.filterValue['categoryId'],
            item: this.state.filterValue['item'],
            cost: this.state.filterValue['costPerItem'],
            manufacturer: this.state.filterValue['manufacturer'],
            partNo: this.state.filterValue['partNo'],
            unit: this.state.filterValue['unit'],
        }

        this.props.addItem(data).then(res => {
            const newitemid = res.id;
            this.setState({ newitemid })
            if (res.id) {
                if (filterValue.noresult) {
                    const { selectedItems } = this.state
                    const indexitem = selectedItems.findIndex(obj => obj.id === '')
                    if (indexitem) {
                        selectedItems[indexitem].name.value = filterValue.item
                        selectedItems[indexitem].id = newitemid || uuidv4()
                        selectedItems[indexitem].manufacturer = filterValue.manufacturer
                        selectedItems[indexitem].partNo = filterValue.partNo
                        selectedItems[indexitem].categoryId = filterValue.categoryId
                        selectedItems[indexitem].unit.value = filterValue.unit
                        selectedItems[indexitem].newItem = true
                        selectedItems[indexitem].isInlineItem = false
                        this.setState({ selectedItems, showAddItem: false, filterValue: { ...filterValue, noresult: true } })
                    }


                }
            }
        })
    }

    render() {
        const {
            categories,
            selectedItems,
            addItemDialogState,
            categoryId,
            isInlineItem,
            filterValue
        } = this.state
        const { tasksList, permissions } = this.props

        return (
            <>
                <AddItemDialog
                    open={addItemDialogState}
                    submitHandler={this.AddItemDialogClose}
                    dialogClose={() => this.setState({ addItemDialogState: false, inlineItemIndex: -1 })}
                    categoryId={categoryId}
                    itemName={filterValue}
                    isInlineItem={isInlineItem}
                    writePermission={idx(permissions, _ => _.catalog) === 2 || false}
                />
                <div className="material-list-block mt-3">
                    <div className="table-responsive">
                        <Table bordered className="tbl-fixed table-create table-material table-material-ch">
                            <thead>
                                <tr>
                                    <th className="w-auto">Select Task</th>
                                    <th className="w-auto">Catalog</th>
                                    <th>Manufacturer</th>
                                    <th>Part No</th>
                                    <th>Items</th>
                                    <th>Unit Cost($)</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th className="text-right">Total ($)</th>
                                    <th className='text-right'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedItems && Array.isArray(selectedItems) && selectedItems.map((_item, index) => {
                                    return (
                                        <tr key={_item.id}>
                                            <td>
                                                <SelectInput
                                                    className="input-block-sm"
                                                    label={'Select Task'}
                                                    value={_item.taskId}
                                                    items={tasksList}
                                                    onChange={(e) => this.changeTaskHandler(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                {_item.id && (categories.items.find(item => item.value === _item.categoryId)?.key || '')}
                                                {!_item.isInlineItem && !_item.id &&
                                                    <div className="form-group">
                                                        <div className="input-block input-block-sm autocomplete-block autocomplete-block-ch mt-1">
                                                            <Autocomplete
                                                                value={_item.catalog}
                                                                onChange={(event, newValue) => {
                                                                    if (typeof newValue === 'string') {
                                                                    } else if (newValue && newValue.inputValue) {
                                                                    } else if (newValue) {
                                                                        this.handleCategoryChange(index, newValue.value, _item.id, newValue.key);
                                                                        this.addFilter(newValue.key, 'catalog')
                                                                        this.addFilter(newValue.value, 'categoryId')
                                                                    } else {
                                                                        this.addFilter('', 'catalog')
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => { return filter(options, params); }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                id="free-solo-with-text-demo"
                                                                options={categories.items}
                                                                getOptionLabel={(option) => {
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    return option.key;
                                                                }}
                                                                renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                                freeSolo
                                                                renderInput={(params) => (<TextField {...params} variant='standard' label='Catalog' />)}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {_item.isInlineItem && <span>NON CATALOG</span>}
                                            </td>
                                            <td>
                                                {_item.id && _item.manufacturer}
                                                {!_item.isInlineItem && !_item.id && !_item.manufacturer &&
                                                    <div className="form-group">
                                                        <div className="input-block input-block-sm autocomplete-block autocomplete-block-ch mt-1">
                                                            <Autocomplete
                                                                value={_item.catalog}
                                                                onChange={(event, newValue) => {
                                                                    if (typeof newValue === 'string') {
                                                                    } else if (newValue && newValue.inputValue) {
                                                                    } else if (newValue) {
                                                                        this.addFilter(newValue.value, 'manufacturer')
                                                                    } else {
                                                                        this.addFilter('', 'manufacturer')
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => { return filter(options, params); }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                id="free-solo-with-text-demo"
                                                                options={this.manufacturerDropDown()}
                                                                getOptionLabel={(option) => {
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    return option.key;
                                                                }}
                                                                renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                                freeSolo
                                                                renderInput={(params) => (<TextField {...params} variant='standard' label='Manufacturer' />)}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                {_item.id && _item.partNo}
                                                {!_item.isInlineItem && !_item.id && !_item.partNo &&
                                                    <CustomInput
                                                        className="input-block-sm"
                                                        onChange={(e) => {
                                                            if (e.target.value && e.target.value.length > 2) {
                                                                this.addFilter(e.target.value, 'partNo')
                                                            } else {
                                                                this.addFilter('', 'partNo')
                                                            }
                                                        }}
                                                    />
                                                }
                                            </td>
                                            <td>
                                                {_item.isInlineItem && _item.name.value}
                                                {!_item.isInlineItem && _item.name.value}
                                                {!_item.isInlineItem && !_item.id && (
                                                    <div className="form-group">
                                                        <div className="input-block input-block-sm autocomplete-block autocomplete-block-ch mt-1">
                                                            <Autocomplete
                                                                value={_item.catalog}
                                                                onChange={(event, newValue) => {
                                                                    if (typeof newValue === 'string') {
                                                                    } else if (newValue && newValue.inputValue) {
                                                                        this.AddItemDialogHandler(index, newValue.inputValue, _item.categoryId)
                                                                    } else if (newValue) {
                                                                        this.handleAddItem(index, newValue)
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    if (params.inputValue !== '') {
                                                                        filtered.push({
                                                                            inputValue: params.inputValue,
                                                                            key: `Add "${params.inputValue}"`,
                                                                        });
                                                                    }
                                                                    return filtered;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                id="free-solo-with-text-demo"
                                                                options={this.itemsDropDownOption(false)}
                                                                getOptionLabel={(option) => {
                                                                    if (typeof option === 'string') { return option; }
                                                                    if (option.inputValue) { return option.inputValue; }
                                                                    return option.key;
                                                                }}
                                                                renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                                freeSolo
                                                                renderInput={(params) => (<TextField {...params} variant='standard' label='Items' />)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    type="number"
                                                    placeholder="Unit Cost"
                                                    {..._item.costPerItem}
                                                    onChange={(e) => this.itemChangeHandler(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    type="number"
                                                    {..._item.qty}
                                                    placeholder="Quantity"
                                                    onChange={(e) => this.itemChangeHandler(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <SelectInput
                                                    className="input-block-sm"
                                                    label="Select unit"
                                                    {..._item.unit}
                                                    items={this.state.newunitList}
                                                    onChange={(e) => this.itemChangeHandler(index, e)}
                                                />
                                            </td>
                                            <td className="text-right">{toUSD(_item.total.value)}</td>
                                            <td className="text-right">
                                                {this.state.filterValue?.noresult && !_item.id && <div className='d-flex justify-content-end'>
                                                    <span className='action-edit mt-1' onClick={() => this.submitItemForm()}>
                                                        <SaveIcon />
                                                    </span>
                                                    <span className='action-edit mt-1 mx-2' onClick={() => this.showAddItem()}>
                                                        <AddIcon />
                                                    </span>
                                                    <div className="table-checkbox">
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            value={this.state.checked}
                                                            defaultChecked={true}
                                                            onChange={() => this.setChecked(!this.state.checked)}
                                                        />
                                                    </div>
                                                </div>}

                                                {!this.state.filterValue?.noresult && <>
                                                    <DeleteIcon onClick={() => this.removeItem(index)} className="deleteicon" />
                                                </>}

                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        {!this.state.filterValue?.noresult && <SelectInput
                                            className="input-block-sm"
                                            label={'Select Task'}
                                            items={tasksList}
                                            onChange={(e) => this.changeTaskHandler(-1, e.target.value)}
                                        />}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="col-12 d-flex mt-3">
                    <Button type="submit" onClick={this.submitForm} className="ms-auto me-3 secondarybtn">SAVE</Button>
                    <Button type="submit" onClick={this.viewPoList} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
                </div>
            </>
        )
    }
}

AddMaterialPO.defaultProps = {
    poData: {},
    taskItems: []
}

AddMaterialPO.propTypes = {
    poData: PropTypes.object,
    taskItems: PropTypes.array
}

const mapStateToProps = state => ({
    permissions: state.user.userData.permissions
})

const mapDispatchToProps = dispatch => ({
    getCategories: bindActionCreators(getCategories, dispatch),
    addItem: bindActionCreators(addItem, dispatch),
    getItems: bindActionCreators(getItems, dispatch),
    warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
    successSnackBar: bindActionCreators(successSnackBar, dispatch),
    errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
    getUnits: bindActionCreators(getUnits, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMaterialPO)
