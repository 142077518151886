import React from 'react'
import Table from "react-bootstrap/Table";
import './table.scss'

const DashboardTable = ({ columns = [], data = [], }) => {

    return (
        <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={`hcol-${index}`} className={column.class}>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, itemIndex) => (
                        <tr key={`row-${itemIndex}`}>
                            {columns.map((column, index) => (
                                <td key={`dcol-${itemIndex}-${index}`} className={column.class + ' ' + (column.rowClass || '')}>
                                    {column.render ? column.render(item) : item[column.field]}
                                </td>
                            ))}
                        </tr>
                    ))}
                    {data.length < 1 && (<tr>
                        <td colSpan={columns.length} className='text-center'>
                            No Data
                        </td>
                    </tr>)}
                </tbody>
            </Table>
        </div>
    )

}

export default DashboardTable
