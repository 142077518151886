import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/inventory.scss";
import { INVENTORY } from "./../../constants/route-paths";
import {
  getPOList,
  updatePOStock,
  getProjectList,
  getPoVendors,
  getDePOList,
} from "./../../actions/project-management.action";
import {
  getServicesList,
  getPoVendors as getServicePOVendors,
  getPOList as getServicePOList,
  updatePOStock as updateServicePOStock,
} from "./../../actions/service.action";
import {
  getCategories,
  getItems,
  updateItemStock,
  addNoPoStock,
} from "./../../actions/inventory.action";
import { getAssetList } from "./../../actions/asset-management.action";
import { warningSnackBar } from "./../../actions/common.action";
import _ from "lodash";
import idx from "idx";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import SelectInput from "src/components/inputs/select";
import CustomInput from "src/components/inputs/new-input";

function AddStock(props) {

  const [state, setState] = useState({
    POList: [],
    items: [],
    categories: [],
    poId: "",
  })
  const [fields, setFields] = useState({
    type: {
      name: "type",
      label: "Project",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "project",
      items: [{ key: "Project", value: "project" }],
      validation: [],
    },
    project: {
      name: "project",
      label: "Select Project",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
    vendor: {
      name: "vendor",
      label: "Select Vendor",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: [],
    },
    location: {
      name: "location",
      label: "Store location",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "WareHouse", value: "WAREHOUSE" },
        { key: "Vehicle", value: "VEHICLE" },
        { key: "Site", value: "SITE" },
      ],
      validation: ["required"],
    },
    category: {
      name: "category",
      label: "Select Catalog",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    po: {
      name: "po",
      label: "Select PO",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [
        { key: "No PO", value: "NO_PO" },
        { key: "Internal PO", value: "DE_PO" },
      ],
      validation: ["required"],
    },
    dePurchaseOrder: {
      name: "dePurchaseOrder",
      label: "Select Internal PO",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    vehicleNo: {
      name: "vehicleNo",
      label: "Vehicle No",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
  })

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    props.getAssetList("VEHICLE").then((assets) => {
      const data = [];
      assets && assets.filter((asset) => asset.status !== "DISCARD").forEach((asset) => {
        if (asset.assetAllocation) {
          data.push({
            key: asset.assetNo,
            value: asset.id,
            ...asset,
          });
        }
      });
      setFields(prev => ({
        ...prev,
        vehicleNo: { ...prev.vehicleNo, items: data },
      }));
    });
    props.getCategories().then((categoriesList) => {
      const categories = [];
      Array.isArray(categoriesList) && categoriesList.forEach((category) =>
        categories.push({ key: category.name, value: category.id })
      );
      setFields(prev => ({
        ...prev,
        category: { ...prev.category, items: categories },
      }));
    });
    typeChangeHandler({ target: { name: 'type', value: "project" } });
  }

  const inputChangeHandler = (e) => {
    if (!_.isObject(e)) return;
    let name = e.target.name;
    let value = e.target.value

    const { error, valid } = handleValidation(e.target, fields[name].validation);

    let validation = []
    if (name === "location" && value === "VEHICLE") {
      validation = ["required"];
    }

    setFields(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
        touched: true,
        error: error,
        valid: valid,
      },
      ...(name === "location" ? { vehicleNo: { ...prev.vehicleNo, validation: validation } } : {})
    }));
  };

  const handleValidation = ({ name, type, value }, validation) => {
    let error = false;
    let valid = true;
    if (
      validation.includes("required") && (value === undefined ||
        (!_.isBoolean(value) && !_.isNumber(value) && value.trim() === ""))
    ) {
      return { error: "This Field is Required", valid: false };
    }
    return { error, valid };
  };

  const typeChangeHandler = async (e) => {
    if (!_.isObject(e)) return;
    inputChangeHandler(e);
    let projectsList = await props.getProjectList();

    let projects = (Array.isArray(projectsList) && projectsList.map((project) => {
      return {
        key: project.name,
        value: project.id,
      };
    })) || [];

    projects = _.orderBy(projects, [({ key }) => key.toLowerCase()], ["asc"]);

    setFields(prev => ({
      ...prev,
      project: { ...prev.project, items: projects, value: '' },
      vendor: { ...prev.vendor, value: '' },
    }));
  };

  const changePOType = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let categoryVaidation = [], dePOValidation = [], dePOItems = [], items = [];
    if (value === "NO_PO") {
      categoryVaidation = ["required"];
    } else if (value === "DE_PO") {
      dePOValidation = ["required"];
      const dePOList = await props.getDePOList();
      let list = dePOList.map(({ id, items }) => ({
        key: id,
        value: id,
        items,
      }));
      dePOItems = _.orderBy(list, ["key"], ["desc"]);
    } else {
      const POData = fields.po.items.find((po) => po.value === value);
      if (POData) {
        Array.isArray(POData.items) && POData.items.forEach((item) => {
          items.push({
            ...item,
            receivedQty: item.receivedQty ? item.receivedQty : 0,
          });
        });
      }
    }

    setFields(prev => ({
      ...prev,
      category: { ...prev.category, validation: categoryVaidation },
      dePurchaseOrder: {
        ...prev.dePurchaseOrder,
        validation: dePOValidation,
        ...(value === "DE_PO" ? { items: dePOItems } : {})
      },
    }));
    setState(prev => ({
      ...prev,
      items,
      poId: value,
    }));
    inputChangeHandler({ target: { name, value } })
  };

  const changeDePo = (e) => {
    const value = e.target.value;
    inputChangeHandler(e);
    const POData = fields.dePurchaseOrder.items.find((po) => po.value === value);
    const items = [];
    if (POData) {
      Array.isArray(POData.items) && POData.items.forEach((item) => {
        items.push({
          ...item,
          receivedQty: item.receivedQty ? item.receivedQty : 0,
        });
      });
    }
    setState(prev => ({
      ...prev,
      items,
    }));
  };

  const changeCategory = (e) => {
    const categoryId = e.target.value;
    inputChangeHandler(e);
    props.getItems(categoryId).then((items) => {
      setState(prev => ({
        ...prev,
        items,
        selectedCategory: categoryId,
      }));
    });
  };

  const stockChangeHandler = (e, index) => {
    const items = _.cloneDeep(state.items);
    if (state.poId !== "NO_PO") {
      const item = items[index];
      let enteredValue = !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : 0;
      let allowedQty = parseFloat(item.qty) - parseFloat(item.receivedQty);
      if (enteredValue < 0) {
        items[index].error = "Stock should be greater then 0!";
      } else if (enteredValue > allowedQty) {
        items[index].error = "You can not enter more than remaining qty";
      } else {
        items[index].error = false;
      }
    }
    items[index].newStock = e.target.value;
    setState(prev => ({
      ...prev,
      items,
    }));
  };

  const projectChangeHandler = async (e) => {
    inputChangeHandler(e);
    let vendorsList = await props.getPoVendors(e.target.value, "Supplier");
    const vendors = [];
    Array.isArray(vendorsList) && vendorsList.forEach((vendor) => {
      const isVendorSelected = vendors.find(({ value }) => value === vendor.vendorId);
      if (!isVendorSelected) {
        vendors.push({
          key: idx(vendor, (_) => _.vendor.business_name) || "",
          value: idx(vendor, (_) => _.vendor.id) || "",
        });
      }
    });

    setFields(prev => ({
      ...prev,
      vendor: { ...prev.vendor, items: vendors, value: '' },
    }));
  };

  const vendorChangeHandler = async (e) => {
    inputChangeHandler(e);
    let poList = await props.getPOList({
      vendorId: e.target.value,
      projectId: idx(fields, (_fields) => _fields.project.value),
    });

    let POs = [];
    Array.isArray(poList) && poList.forEach((po) => {
      POs.push({
        key: po.id,
        value: po.u_id,
        items: po.items,
      });
    });

    POs = _.orderBy(POs, ["key"], ["desc"]);
    POs.unshift({ key: "No PO", value: "NO_PO" });
    POs.unshift({ key: "Internal PO", value: "DE_PO" });

    setFields(prev => ({
      ...prev,
      po: { ...prev.po, items: POs, value: '' },
      category: { ...prev.category, value: '' },
      dePurchaseOrder: { ...prev.dePurchaseOrder, value: '' },
      location: { ...prev.location, value: '' },
    }));
  };

  const cancelHandler = () => {
    props.history(`/${INVENTORY}`);
  };

  const submitForm = () => {
    const { poId, items } = state;
    const { user } = props;
    let isFormValid = true;
    let _fields = _.cloneDeep(fields)
    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key], _fields[key].validation);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    Array.isArray(items) && items.forEach((item) => {
      if (item.error) {
        isFormValid = false;
      }
    });

    if (!isFormValid) {
      setFields(_fields)
    } else {
      const data = {
        items,
        allocationLocation: _fields.location.value,
        poId: "" + poId,
        transactionType: "RECEIVED",
      };
      if (_fields.location.value === "VEHICLE") {
        const assetData = _fields.vehicleNo.items.find((vehicle) => vehicle.id === _fields.vehicleNo.value);
        data.allocateToName = assetData.assetAllocation.allocationName;
        data.allocateToId = assetData.assetAllocation.id;
        data.assetId = assetData.id;
      } else if (_fields.location.value === "SITE") {
        const projectData = _fields.project.items.find((project) => project.value === _fields.project.value);
        if (!projectData) {
          props.warningSnackBar("Please Select Project for Delivery Location");
          return;
        }
        data.allocateToName = projectData.key;
        data.allocateToId = projectData.value;
      } else {
        data.allocateToName = user.name;
        data.allocateToId = user.id;
      }

      if (poId === "NO_PO") {
        props.addNoPoStock(data).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      } else if (poId === "DE_PO") {
        data.items = formatItems(items);
        data.poId = "" + idx(_fields, (_) => _.dePurchaseOrder.value);
        const DE_PO_Id = _fields.dePurchaseOrder.value;
        props.updatePOStock(data, DE_PO_Id).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      } else {
        data.items = formatItems(items);
        props.updatePOStock(data, poId).then((res) => {
          cancelHandler();
        }).catch(e => console.error(e));
      }
    }
  };

  const formatItems = (items) => {
    Array.isArray(items) && items.forEach((item) => {
      const newStock = !isNaN(parseFloat(item.newStock)) ? parseFloat(item.newStock) : 0;
      const receivedQty = !isNaN(parseFloat(item.receivedQty)) ? parseFloat(item.receivedQty) : 0;
      item.receivedQty = newStock + receivedQty;
    });
    return items;
  };

  return (
    <div className="purchase-order-block">

      <h2 className="content-heading text-uppercase">Add Stock</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>INVENTORY</Breadcrumb.Item>
          <Breadcrumb.Item active>Add Stock</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="row">

        <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.project}
            label={"Project"}
            onChange={(e) => projectChangeHandler(e)}
          />
        </div>
        <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.vendor}
            label={'Select Vendor'}
            onChange={(e) => vendorChangeHandler(e)}
          />
        </div>

        <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.po}
            label={'Select PO'}
            onChange={(e) => changePOType(e)}
          />
        </div>
        {state.poId === "NO_PO" && (<div className="col-lg-4 mt-3">
          <SelectInput
            onChange={(e) => changeCategory(e)}
            {...fields.category}
            label={'Select Catalog'}
          />
        </div>)}
        {state.poId === "DE_PO" && (<div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.dePurchaseOrder}
            label={'Select DE Purchase Order'}
            onChange={(e) => changeDePo(e)}
          />
        </div>)}
        <div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.location}
            label={'Store Location'}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>
        {fields.location.value === "VEHICLE" && (<div className="col-lg-4 mt-3">
          <SelectInput
            {...fields.vehicleNo}
            label={'Vehicle No'}
            onChange={(e) => inputChangeHandler(e)}
          />
        </div>)}
      </div>
      <div className="material-list-block mt-3">
        <div className="table-responsive">
          <Table bordered className="table-create table-material table-material-ch w-100">
            <thead>
              <tr>
                <th className="w-auto">Item</th>
                {state.poId !== "NO_PO" && (<>
                  <th className="w-auto">Ordered Quantity</th>
                  <th className="w-auto">Received Quantity</th>
                  <th className="w-auto">Quantity Left</th>
                </>)}
                <th className="w-auto">Enter New Stock</th>
              </tr>
            </thead>
            <tbody>
              {state.items.map((item, index) => (
                <tr>
                  <td className="w-auto">{item.item}</td>
                  {state.poId !== "NO_PO" && (
                    <>
                      <td>{item.qty}</td>
                      <td>{item.receivedQty}</td>
                      <td>{parseFloat(item.qty) - parseFloat(item.receivedQty)}</td>
                    </>
                  )}
                  <td>
                    <CustomInput
                      className="input-block-sm"
                      value={item.newStock}
                      lable="Stock"
                      type="number"
                      valid={!item.error}
                      touched
                      error={item.error}
                      onChange={(e) => stockChangeHandler(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

      </div>


      <div className="col-12 d-flex justify-content-end gap-3 mt-3">
        {props.writePermission && (<Button onClick={submitForm} className="secondarybtn">DONE</Button>)}
        <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>
    </div>
  );

}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getPOList: bindActionCreators(getPOList, dispatch),
  getServicePOList: bindActionCreators(getServicePOList, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  getItems: bindActionCreators(getItems, dispatch),
  updateItemStock: bindActionCreators(updateItemStock, dispatch),
  updatePOStock: bindActionCreators(updatePOStock, dispatch),
  updateServicePOStock: bindActionCreators(updateServicePOStock, dispatch),
  getAssetList: bindActionCreators(getAssetList, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
  getPoVendors: bindActionCreators(getPoVendors, dispatch),
  getServicePOVendors: bindActionCreators(getServicePOVendors, dispatch),
  getDePOList: bindActionCreators(getDePOList, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  addNoPoStock: bindActionCreators(addNoPoStock, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddStock));
