import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Icons from '../icons/icons'
import './modal.scss'

export default function Modal(props) {
  const handleClose = (event, reason) => {
    if (!props.disableBackdropClick && reason === "backdropClick") {
      return false;
    }
    if (typeof onClose === "function") {
      props.onClose();
    }
  };
  return (
    <div className='modalContainer'>
      <Dialog
        className={props.primary ? 'primary' : ''}
        onClose={handleClose}
        open={props.open || false}
        aria-labelledby='form-dialog-title'
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        disableEnforceFocus
      >
        <DialogTitle id='form-dialog-title'>
          <Grid container className='titlebg'>
            <Grid item sm={11} xs={10}>{props.modalHeading}</Grid>
            {!props.hideCloseIcon && <Grid item sm={1} xs={2} align='right'><Icons icon='cancel' onClick={props.onClose} /></Grid>}
          </Grid>
        </DialogTitle>
        <DialogContent onScroll={props.onScroll} className='modal-body'>
          <div className='title'>{props.modalTitle}</div>
          <div className='content'>{props.modalContent || props.children}</div>
        </DialogContent>
        {props.showActionBtn &&
          <DialogActions className='actionbtn'>
            {props.additionalbtnTxt && <Button variant='contained' className={props.savebtnClass + ' listbtn'} onClick={props.additionalbtn} color='primary'>{props.additionalbtnTxt}</Button>}
            <div className='actionbtn'>
              {props.additionalTxt && <small>{props.additionalTxt}</small>}
              {props.modalSaveTxt && <Button variant='contained' className='cancel-btn' onClick={props.onClick} color='primary'>{props.modalSaveTxt}</Button>}
              {props.modalEditTxt && <Button variant='contained' className='cancel-btn' onClick={props.onEdit} >{props.modalEditTxt}</Button>}
              {props.modalCloseTxt && <Button variant='contained' className='cancel-btn' onClick={props.modalCloseBtn || props.onClose}>{props.modalCloseTxt}</Button>}
            </div>
          </DialogActions>}
          {props.footerBtns &&
          <DialogActions className='actionbtn'>
            {props.additionalbtnTxt && <Button variant='contained' className={props.savebtnClass + ' listbtn'} onClick={props.additionalbtn} color='primary'>{props.additionalbtnTxt}</Button>}
            <div className='actionbtn'>
              {props.additionalTxt && <small>{props.additionalTxt}</small>}
              {props.modalSaveTxt && <Button variant='contained' className='cancel-btn' onClick={props.onClick} color='primary'>{props.modalSaveTxt}</Button>}
              {props.modalEditTxt && <Button variant='contained' className='cancel-btn' onClick={props.onEdit} >{props.modalEditTxt}</Button>}
              {props.modalCloseTxt && <Button variant='contained' className='cancel-btn' onClick={props.modalCloseBtn || props.onClose}>{props.modalCloseTxt}</Button>}
            </div>
          </DialogActions>}
       
          <DialogActions className='actionbtn'>
          
            <div className='footer-btns'>
              {props.cancel && <Button variant='contained' className='cancel-btn' onClick={props.onClick} color='primary'>{props.cancel}</Button>}
              {props.save && <Button variant='contained' className='cancel-btn' onClick={props.onEdit} >{props.save}</Button>}
    
            </div>
          </DialogActions>
      </Dialog>
    </div>
  )
}
