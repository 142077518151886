import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { laborObjFields } from "src/constants/fields.constant";
import { updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import CustomInput from "src/components/inputs/new-input";
import SelectInput from "src/components/inputs/select";

const filter = createFilterOptions();

export default function Labor({ labor = [], total = {}, subTaskArrayIndex, laborOptions = [], isDisabled, ...props }) {

    const dispatch = useDispatch()
    const [laborState, setLaborState] = useState({
        laborObj: laborObjFields,
        laborAutoComplete: {}
    })
    const [expandAddLabor, setExpandAddLabor] = useState(false);

    const filterLabor = (id) => {
        const { laborsList = [] } = props;
        const labor = laborsList.find((_labor) => _labor.id === id);
        return labor;
    };

    const updateLabor = (value, index) => {
        const selectedLabor = filterLabor(value.value);
        const labor = JSON.parse(JSON.stringify(laborState.laborObj));
        labor.name.value = selectedLabor.skill;
        labor.id.value = selectedLabor.id;
        labor.skillDescription.value = selectedLabor.skillDescription;
        labor.costPerItem.value = selectedLabor.cost;
        labor.calloutCost.value = parseFloat(selectedLabor.calloutCost || 0);
        labor.labourCost.value = parseFloat(selectedLabor.averageCost || 0);
        labor.travelCost.value = 0;

        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["labor"][index] = labor;
        dispatch(updateSubTaskAction(_temp))
    };

    const laborChangeHandler = (e, index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        const labor = _temp[subTaskArrayIndex]["labor"][index];
        let costPerItem = parseFloat(labor.costPerItem.value) || 0;
        let laborCost = parseFloat(labor.labourCost.value) || 0;
        let quantity = parseFloat(labor.labourCost.value) || 0;

        if (e.target.name === "qty") {
            let qty = parseFloat(e.target.value) || 0;
            labor.qty.value = qty;
            labor.total.value = qty * costPerItem;
            labor.labor_profit = (costPerItem - laborCost) * qty;
        }

        if (e.target.name === "costPerItem" || e.target.name === 'labourCost') {
            let cost = parseFloat(e.target.value) || 0;
            let laborCost = cost;// modified for template
            labor.costPerItem.value = cost;
            labor.labourCost.value = cost;
            labor.total.value = quantity * cost;
            labor.labor_profit = (cost - laborCost) * quantity;
        }

        const value = e.target.value.replace("-", "");
        _temp[subTaskArrayIndex]["labor"][index][e.target.name].value = value;
        _temp[subTaskArrayIndex]["labor"][index]["total"].value =
            (parseFloat(_temp[subTaskArrayIndex]["labor"][index]["qty"].value) || 0) *
            (parseFloat(_temp[subTaskArrayIndex]["labor"][index]["costPerItem"].value) || 0);
        dispatch(updateSubTaskAction(_temp))
    };

    const removeLabor = (index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["labor"].splice(index, 1);
        dispatch(updateSubTaskAction(_temp))
    };

    const addLabor = (value, itemData = null) => {
        const selectedLabor = itemData ? itemData :filterLabor( value[0].id);
        const labor = JSON.parse(JSON.stringify(laborState.laborObj));
        labor.name.value = selectedLabor.skill;
        labor.id.value = selectedLabor.id;
        labor.skillDescription.value = selectedLabor.skillDescription|| '';
        labor.costPerItem.value = selectedLabor.cost || 0;
        labor.calloutCost.value = parseFloat(selectedLabor.calloutCost || 0);
        labor.labourCost.value = selectedLabor.cost || 0;
        labor.travelCost.value = 0;
        labor.labor_cost = selectedLabor.averageCost || 0;
        labor.labor_profit = 0;
        labor.qty.value = 1;
        labor.total.value = parseFloat(labor.qty.value) * parseFloat(labor.costPerItem.value);

        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["labor"].push(labor);
        if (value?.key === laborState.laborAutoComplete?.key) value = Math.random();
        dispatch(updateSubTaskAction(_temp))
        setLaborState(prev => ({
            ...prev,
            laborAutoComplete: value,
        }));
    };

    return (
        <>
            <h2>Resource List</h2>
            <div className="table-responsive">
                <Table bordered className="table-create table-material tbl-fixed">
                    <thead>
                        <tr>
                            <th className="autocomplete-width">Resource(s)</th>
                            <th>Cost($)</th>
                            <th>Sale Amount($)</th>
                            <th>Quantity</th>
                            <th className="text-right">Total ($)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {labor.map((_labor, laborIndex) => {
                            if (!_labor) return;
                            return (
                                <tr key={laborIndex}>
                                    <td>
                                        <p>{idx(_labor, _ => _.name.value)}</p>
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Amount"
                                            {..._labor.labourCost}
                                            onChange={(e) => laborChangeHandler(e, laborIndex)}
                                            min={1}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Sale Amount"
                                            {..._labor.costPerItem}
                                            onChange={(e) => laborChangeHandler(e, laborIndex)}
                                            min={1}
                                        />
                                    </td>
                                    <td>
                                        <CustomInput
                                            className="input-block-sm"
                                            disabled={isDisabled}
                                            placeholder="Quantity"
                                            {..._labor.qty}
                                            onChange={(e) => laborChangeHandler(e, laborIndex)}
                                            min={1}
                                        />
                                    </td>
                                    <td className="text-right">
                                        ${parseFloat(idx(_labor, _ => _.total.value) || 0).toFixed(2)}
                                    </td>
                                    <td>
                                        {!isDisabled && <DeleteIcon
                                            className="deleteicon"
                                            onClick={() => removeLabor(laborIndex)}
                                        />}
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td>
                                {!isDisabled && <span
                                    className="add-material"
                                    onClick={() => setExpandAddLabor(!expandAddLabor)}
                                    aria-controls="example-collapse-texts"
                                    aria-expanded={expandAddLabor}
                                >
                                    <AddIcon /> Add Resource
                                </span>}
                            </td>
                            <td></td>
                            <td className="text-right table-text-sm">Sub Total</td>
                            <td className="text-right table-text-sm">
                                ${parseFloat(idx(total, (_) => _.labor.sale) || 0).toFixed(2)}
                            </td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
            </div>

            <Collapse in={expandAddLabor}>
                <div id="example-collapse-texts">
                    <div className="row m-0">
                        <div className="col-lg-5 col-md-6 my-3">
                            <div className="form-group">
                                <div className="input-block autocomplete-block">
                                    <label className="input-label">Resource</label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === "string") {
                                                newValue = { inputValue: newValue, };
                                            } else if (newValue && newValue.inputValue) {
                                                //   console.info(newValue.inputValue);
                                            } 
                                            if (newValue) {
                                                addLabor(laborOptions.filter(_labor=>_labor.skill === newValue.inputValue), null);
                                                setExpandAddLabor(false)
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            return filter(options, params);
                                        }}
                                        options={laborOptions.map((_labor, laborIndex)=>idx(_labor, _ => _.skill))}
                                        getOptionLabel={(option) => {
                                            // e.g value selected with enter, right from the input
                                            if (typeof option === "string") {
                                                return option;
                                            }
                                            return option.key;
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Resource" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </>
    );

}
