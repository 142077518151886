import _ from "lodash";
import { emailValidator } from "./email";
import { phoneValidator } from "./phone";

export const ValidateField = ({ value, validation = [] }) => {
    let error = false;
    let valid = true;
    for (const iterator of validation) {
        let type = iterator;
        // let type_value = '';
        if (typeof iterator != 'string') {
            type = iterator['type'];
            // type_value = iterator['value'];
        }
        switch (type) {
            case ValidatorTypes.REQUIRED:
                if (!_.isBoolean(value) && !value) return { error: "This Field is Required", valid: false };
                break;
            case ValidatorTypes.EMAIL:
                if (value) {
                    let status = emailValidator(value);
                    if (!status) return { error: "Please Enter Valid Email", valid: false };
                }
                break;
            case ValidatorTypes.PHONE_NUMBER:
                if (value) {
                    let status = phoneValidator(value);
                    if (!status) return { error: "Please Enter Valid Mobile Number" };
                }
                break;
            case ValidatorTypes.ABN:
                if (value) {
                    value = value.trim();
                    if (value.replace(/ /g, '').length != 11) return { error: "ABN value should be 11 characters", valid: false };
                }
                break;
            default: break;
        }
    }
    return { error, valid };
};

export const ValidatorTypes = {
    EMAIL: 'email',
    PHONE_NUMBER: 'phone_number',
    REQUIRED: "required",
    OPTIONAL: 'optional',
    ABN: 'abn'
}