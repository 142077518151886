import { createSlice } from '@reduxjs/toolkit'
import { convertToMaterialForm } from '../util/form-binders/material'
import { convertToLaborForm } from '../util/form-binders/labor'
import { convertToSubcontractorForm } from '../util/form-binders/subcontractor'
import { taskCalculation } from '../util/calculations/calculate-task'
import idx from "idx";
import { cloneDeep } from "lodash";
import { calculateSaleAmount } from '../util/calculations/sale-amount'

// Slice
const initialTask = {
    task: {
        subTaskArray: [],
    },
}

const slice = createSlice({
    name: 'task',
    initialState: {
        ...initialTask,
    },
    reducers: {
        addTask: (state, action) => {
            let { subTaskArray = [], profitMargin, profitMarginType, ...rest } = action.payload;
            state.task = {
                ...rest,
                subTaskArray
            }
        },
        updateSubtask: (state, action) => {
            const { subtasks = [], data = {} } = action.payload;
            state.task = {
                ...state.task,
                subTaskArray: subtasks,
                ...data
            }
        },
        updateProfitMargin: (state, action) => {
            let { subTaskArray = [], profitMargin, profitMarginType, data = {} } = action.payload
            state.task = {
                ...state.task,
                subTaskArray: subTaskArray,
                profitMargin: profitMargin,
                profitMarginType: profitMarginType,
                ...data
            }
        },
        clearTask: (state, action) => {
            state.task = {
                subTaskArray: [],
            };
        },
        updateMaterial: (state, action) => {
            let { material = [], index } = action.payload
            state.task = {
                ...state.task,
                subTaskArray: state.task.subTaskArray.map((subTask, subTaskIndex) => {
                    if (subTaskIndex == index) subTask['newMaterial'] = material;
                    return subTask;
                }),
            }
        },
        updateLabor: (state, action) => {
            let { labor = [], index } = action.payload
            state.task = {
                ...state.task,
                subTaskArray: state.task.subTaskArray.map((subTask, subTaskIndex) => {
                    if (subTaskIndex == index) subTask['newLabor'] = labor
                    return subTask;
                }),
            }
        },
        updateContractor: (state, action) => {
            let { contractor = [], index } = action.payload
            state.task = {
                ...state.task,
                subTaskArray: state.task.subTaskArray.map((subTask, subTaskIndex) => {
                    if (subTaskIndex == index) subTask['newContractor'] = contractor
                    return subTask;
                }),
            }
        }
    },
});

export default slice.reducer

// Actions
const { addTask, updateSubtask, updateProfitMargin, updateMaterial, updateLabor, updateContractor, clearTask } = slice.actions

export const addTaskAction = (data, subcontractors = []) => async dispatch => {
    try {
        let { subTasks = [] } = data;
        let subTaskArray = []

        const total = taskCalculation({
            material: idx(data, _ => _.material),
            subtasks: subTasks,
            profitMargin: idx(data, _ => _.profitMargin),
            profitMarginType: idx(data, _ => _.profitMarginType),
            // isForm:
        })

        data = { ...data, total }
        subTasks.map((subtask, i) => {

            subTaskArray.push({
                id: subtask.id,
                taskName: subtask.name,
                notes: subtask.notes,
                description: subtask.description,
                material: convertToMaterialForm(subtask.material),
                is_actual_materialcost: false,
                labor: convertToLaborForm(subtask.labor),
                subcontractor: convertToSubcontractorForm(subtask.subcontractor, subcontractors),
                materialTotal_Cost: subtask.materialTotal_Cost,
                materialTotal_SaleAmount: subtask.materialTotal_SaleAmount,
                material_Profit: subtask.material_Profit,
                laborTotal_SaleAmount: subtask.laborTotal_SaleAmount,
                labor_profit: subtask.laborProfit,
                subcontractorTotal_Cost: subtask.subcontractorTotal_Cost,
                subcontractorTotal_SaleAmount: subtask.subcontractorTotal_SaleAmount,
                subcontractor_Profit: subtask.subcontractor_Profit,
                subtaskTotal:
                    parseFloat(subtask.materialTotal_SaleAmount) +
                    parseFloat(subtask.laborTotal_SaleAmount) +
                    parseFloat(subtask.subcontractorTotal_SaleAmount),
                profitMarginCost: subtask.profitMarginCost,
                descriptionError: false,
                descriptionLength: false,
            });
        });

        dispatch(addTask({ ...data, subTaskArray }));
    } catch (e) {
        return console.error(e);
    }
}

export const updateSubTaskAction = (data = []) => (dispatch, getState) => {
    try {
        const state = getState()
        const total = taskCalculation({
            material: idx(state, _ => _.task.task.material),
            subtasks: data,
            profitMargin: idx(state, _ => _.task.task.profitMargin),
            profitMarginType: idx(state, _ => _.task.task.profitMarginType),
            isForm: true
        })

        let _final = data.map(_data => {
            return {
                ..._data,
                material_Profit: total.subtask[_data.id].material.profit,
                materialTotal_Cost: total.subtask[_data.id].material.cost,
                materialTotal_SaleAmount: total.subtask[_data.id].material.sale,

                subcontractor_Profit: total.subtask[_data.id].subcontractor.profit,
                subcontractorTotal_Cost: total.subtask[_data.id].subcontractor.cost,
                subcontractorTotal_SaleAmount: total.subtask[_data.id].subcontractor.sale,

                laborTotal_SaleAmount: total.subtask[_data.id].labor.sale,
                labor_total_cost: total.subtask[_data.id].labor.cost,
                labor_profit: total.subtask[_data.id].labor.profit,

                profitMarginCost: total.subtask[_data.id].total_profit,
                total_Profit: total.subtask[_data.id].total_profit,
                subtaskTotal: total.subtask[_data.id].total,
            }
        })
        let _data = {
            materialTotal_Cost: total.material.cost,
            materialTotal_SaleAmount: total.material.sale,
            material_Profit: total.material.profit,

            labor_total_cost: total.labor.cost,
            laborTotal_SaleAmount: total.labor.sale,
            labor_profit: total.labor.profit,

            subcontractorTotal_Cost: total.subcontractor.cost,
            subcontractorTotal_SaleAmount: total.subcontractor.sale,
            subcontractor_Profit: total.subcontractor.profit,

            totalProfit: total.total_profit,
            profitMarginCost: total.total_profit,

            total
        }
        dispatch(updateSubtask({ subtasks: _final, data: _data }));
    } catch (e) {
        console.error(e)
    }
}

export const updateItemAction = (data = {}) => (dispatch, getState) => {
    try {
        let { material = null, labor = null, contractor = null, index } = data
        if (material) {
            dispatch(updateMaterial({ material, index }));
        } else if (labor) {
            dispatch(updateLabor({ labor, index }));
        } else if (contractor) {
            dispatch(updateContractor({ contractor, index }));
        }
    } catch (e) {
        console.error(e)
    }
}

export const updateProfitMarginAction = (data) => (dispatch, getState) => {
    try {
        const state = getState()
        let subtasks = idx(state, _ => _.task.task.subTaskArray) || [];
        const total = taskCalculation({
            material: idx(state, _ => _.task.task.material),
            subtasks: subtasks,
            profitMargin: idx(data, _ => _.profitMargin),
            profitMarginType: idx(data, _ => _.profitMarginType),
            isForm: true
        })

        let _final = subtasks.map(_data => {
            let materials = cloneDeep(_data.material) || [];
            let subcontractors = cloneDeep(_data.subcontractor) || [];
            materials = materials.map(material => {
                if (idx(material, _ => _.saleAmountLock.value)) {
                    material['saleAmount'].value = calculateSaleAmount({
                        cost: material.costPerItem.value,
                        profitMargin: idx(data, _ => _.profitMargin),
                        profitMarginType: idx(data, _ => _.profitMarginType),
                    })
                    material.total.value = parseFloat(material.saleAmount.value || 0) * parseFloat(material.qty.value || 1);
                }
                return material;
            })
            subcontractors = subcontractors.map(subcontractor => {
                if (subcontractor.saleAmountLock.value) {
                    subcontractor['saleAmount'].value = calculateSaleAmount({
                        cost: subcontractor.Cost.value,
                        profitMargin: idx(data, _ => _.profitMargin),
                        profitMarginType: idx(data, _ => _.profitMarginType),
                    })
                }
                return subcontractor;
            })
            return {
                ..._data,
                material: materials,
                subcontractor: subcontractors,
                material_Profit: total.subtask[_data.id].material.profit,
                materialTotal_Cost: total.subtask[_data.id].material.cost,
                materialTotal_SaleAmount: total.subtask[_data.id].material.sale,

                subcontractor_Profit: total.subtask[_data.id].subcontractor.profit,
                subcontractorTotal_Cost: total.subtask[_data.id].subcontractor.cost,
                subcontractorTotal_SaleAmount: total.subtask[_data.id].subcontractor.sale,

                laborTotal_SaleAmount: total.subtask[_data.id].labor.sale,
                labor_total_cost: total.subtask[_data.id].labor.cost,
                labor_profit: total.subtask[_data.id].labor.profit,

                profitMarginCost: total.subtask[_data.id].total_profit,
                total_Profit: total.subtask[_data.id].total_profit,
                subtaskTotal: total.subtask[_data.id].total,
            }
        })

        let _data = {
            materialTotal_Cost: total.material.cost,
            materialTotal_SaleAmount: total.material.sale,
            material_Profit: total.material.profit,

            labor_total_cost: total.labor.cost,
            laborTotal_SaleAmount: total.labor.sale,
            labor_profit: total.labor.profit,

            subcontractorTotal_Cost: total.subcontractor.cost,
            subcontractorTotal_SaleAmount: total.subcontractor.sale,
            subcontractor_Profit: total.subcontractor.profit,

            totalProfit: total.total_profit,
            profitMarginCost: total.total_profit,

            total
        }

        dispatch(updateProfitMargin({
            subTaskArray: _final,
            profitMargin: idx(data, _ => _.profitMargin),
            profitMarginType: idx(data, _ => _.profitMarginType),
            data: _data
        }));
    } catch (e) {
        console.error(e)
    }
}

export const clearTaskAction = (data) => async dispatch => {
    dispatch(clearTask());
}