import React from 'react'
import Input from './../../components/inputs/input-simple'
import {
	Grid,
	InputAdornment
} from '@material-ui/core'
import './scss/inventory.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	createQuotation,
	uploadFileRfq
} from './../../actions/project-management.action'
import FileUploader from '../../components/file-uploader'
import DeleteIcon from '@material-ui/icons/Delete'
import { numberFormat } from '../../util/currency-formatter'
import { fileValidator } from 'src/util/validators/file'

class SubmitQuote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			materialColumns: ['Items', 'Quantity', 'Manufacturer', 'Part No', 'Quote Submitted'],
			laborColumns: ['Tasks', 'Description', 'Quote Submitted'],
			itemInput: {
				name: 'quoteAmount',
				type: 'number',
				value: 0,
			},
			statusInput: {
				name: 'status',
				type: 'switch',
				value: true
			},
			total: 0,
			materialItems: [],
			quotationData: {},
			attachment: []
		}
	}

	componentDidMount() {
		const data = this.props.quotationData.items && this.props.quotationData.items.map(item => {
			const _data = {
				...item,
			}
			if (this.props.quotationData.reviewQuote) {
				_data.status = JSON.parse(JSON.stringify(this.state.statusInput))
				_data.quote = item.quote
			} else if (this.props.quotationData.editQuote) {
				_data.quote = JSON.parse(JSON.stringify(this.state.itemInput))
				_data.quote.value = item.quote
			} else {
				_data.quote = JSON.parse(JSON.stringify(this.state.itemInput))
			}
			return _data
		})
		this.props.setMaterialItems(data ? data : [])
		this.setState({ materialItems: data ? data : [], quotationData: this.props.quotationData })
	}

	editRFQHandler = (rfq, id) => {
		this.setState({ projectData: rfq.items, rfqId: id })
		this.rfqDialogHandler()
	}

	changeQuoteHandler = (e, index) => {
		const materialItems = this.state.materialItems
		if (e.target.type === 'checkbox') {
			materialItems[index].status.value = e.target.checked
		} else {
			materialItems[index].quote.value = e.target.value
		}
		this.props.setMaterialItems(materialItems)
		this.setState({ materialItems })
	}

	calculateTotal = () => {
		const materialItems = this.state.materialItems
		let total = 0
		materialItems.forEach(item => {
			if (item.status.value) {
				total = parseFloat(total) + parseFloat(item.quote)
			}
		})
		return total
	}

	seletectFile = () => {
		document.getElementById("fileInput").click();
	}

	addFileAttachment = async (e) => {
		let { quotationData = {} } = this.state

		if (e.target.files.length < 1) {
			this.props.errorSnackBar('Please select a file!')
			return
		} else if (e.target.files.length > 5) {
			this.props.errorSnackBar('Select only 5 files at a time!')
			return
		} else {
			let attachmentArr = [], isValid = true
			Array.from(e.target.files).map(file => {
				const fileValidation = fileValidator({ file, acceptedFileSize: 10 });
				const { validFileSize, validFileType, error } = fileValidation
				if (!validFileSize.valid && !error) {
					isValid = false
					this.props.errorSnackBar(validFileSize.error)
				}
				else if (!validFileType.valid && !error) {
					isValid = false
					this.props.errorSnackBar(validFileType.error)
				} else if (error) {
					isValid = false
					this.props.errorSnackBar(error.error)
				} else attachmentArr.push(file)
			})
			if (isValid) {
				if (Array.isArray(quotationData.attachmentArr)) quotationData.attachmentArr.push(...attachmentArr)
				else quotationData.attachmentArr = attachmentArr

				this.setState({ quotationData }, () => this.props.rfqFileAttached(quotationData))
				e.target.value = ''
			}
		}
	}

	removeFile = (index, type) => {
		let { quotationData = {} } = this.state
		if (type === 'new') {
			if (Array.isArray(quotationData.attachmentArr))
				quotationData.attachmentArr.splice(index, 1)
		}
		if (type === 'old') {
			if (Array.isArray(quotationData.attachment))
				quotationData.attachment.splice(index, 1)
		}
		this.setState({ quotationData }, () => this.props.rfqFileAttached(quotationData))
	}

	render() {
		const { reviewQuote, vendorType, vendorName } = this.props.quotationData
		const { viewPurchaseOrder = false, purchaseOrderId } = this.props
		const {
			materialColumns,
			materialItems,
			laborColumns,
			quotationData
		} = this.state

		const attachments = (quotationData.attachment && quotationData.attachment.map((attach, i) => (
			<div className="attachedFiles">
				<p style={{ margin: '0 10px 0 0' }}><a href={attach?.url} rel="noopener noreferrer" target="_blank">{attach.name}</a></p>
				{!viewPurchaseOrder && <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'old')}><DeleteIcon /></p>}
			</div>
		))) || []

		Array.isArray(quotationData.attachmentArr) && quotationData.attachmentArr.map((doc, i) => {
			attachments.push(
				<div className="attachedFiles">
					<p style={{ margin: '0 10px 0 0' }}>{doc.name}</p>
					{!viewPurchaseOrder && <p style={{ margin: '0', float: 'right', cursor: "pointer" }} onClick={() => this.removeFile(i, 'new')}><DeleteIcon /></p>}
				</div>
			)
		})
		return (
			<div className='submitQuoteContainer'>
				<Grid container className='descriptionContainer'>
					<Grid xs={8} item >
						<Grid container>
							<Grid xs={12} item><p>Description : {quotationData.description}</p></Grid>
							<Grid xs={12} item><p>Vendor Type: <b>{vendorType}</b></p></Grid>
							{viewPurchaseOrder && <Grid xs={12} item><p>Purchase Order No: <b>{purchaseOrderId}</b></p></Grid>}
							<Grid xs={12} item><p>Quote Request No: <b>{quotationData.quotationId}</b></p></Grid>
							<Grid xs={12} item><p>Vendor: <b>{vendorName}</b></p></Grid>
						</Grid>
					</Grid>
					<Grid xs={4} item className='stageLabel attachFile '>
						<Grid container>
							<Grid xs={12} item style={{ alignItems: 'unset' }}>
								{viewPurchaseOrder ?
									<p>
										<span className="attachmentText" style={{ marginRight: '15px' }}>Attachments</span>
									</p> :
									<FileUploader
										fileHandler={(files) => this.addFileAttachment({ target: { files, type: 'file', name: 'attachments' } })}
									/>
								}
								{attachments}
							</Grid>
						</Grid>
					</Grid>

				</Grid>
				{vendorType === 'Supplier' ? <Grid container style={{ background: '#f4f4f4', padding: '0 15px' }}>
					{materialColumns.map(column => (
						<Grid xs={2} item><p><b>{column}</b></p></Grid>
					))}
					{reviewQuote && !viewPurchaseOrder && <Grid xs={2} item><p><b>Approve</b></p></Grid>}
				</Grid> :
					<Grid container style={{ background: '#f4f4f4', padding: '0 15px' }}>
						{laborColumns.map(column => (
							<Grid xs={3} item><p>{column}</p></Grid>
						))}
						{reviewQuote && !viewPurchaseOrder && <Grid xs={2} item><p><b>Approve</b></p></Grid>}
					</Grid>
				}
				{vendorType === 'Supplier' ?
					<Grid container>
						{materialItems.map((item, index) => (
							<Grid container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px', alignItems: 'center' }} >
								<Grid xs={2} item><p>{item.item}</p></Grid>
								<Grid xs={2} item><p>{item.qty}</p></Grid>
								<Grid xs={2} item><p>{item.manufacturer}</p></Grid>
								<Grid xs={2} item><p>{item.partNo}</p></Grid>
								<Grid xs={2} item>
									{reviewQuote
										?
										<p>{numberFormat(item.quote)}</p>
										: <Input {...item.quote} simple onChange={(e) => this.changeQuoteHandler(e, index)}
											inputProps={{
												startAdornment: <InputAdornment position='start'>$</InputAdornment>,
											}} />}
								</Grid>
								<Grid xs={2} item className='switchContainer'>{reviewQuote && !viewPurchaseOrder ? <Input {...item.status} simple onChange={(e) => this.changeQuoteHandler(e, index)} /> : ''}</Grid>
							</Grid>
						))}
					</Grid>
					:
					<Grid container>
						{materialItems.map((item, index) => (
							<Grid container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px', alignItems: 'center' }} >
								<Grid xs={3} item><p>{item.name}</p></Grid>
								<Grid xs={3} item><p>{item.description}</p></Grid>
								<Grid xs={3} item>
									{reviewQuote
										?
										<p>{numberFormat(item.quote)}</p>
										: <Input {...item.quote} simple onChange={(e) => this.changeQuoteHandler(e, index)} />}
								</Grid>
								<Grid xs={3} item className='switchContainer'>{reviewQuote ? <Input {...item.status} simple onChange={(e) => this.changeQuoteHandler(e, index)} /> : ''}</Grid>
							</Grid>
						))}
					</Grid>
				}
				{reviewQuote && <div>
					<p className='totalLabel'><b>Total: </b>${this.calculateTotal()}</p>
				</div>}
			</div>
		)
	}
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	createQuotation: bindActionCreators(createQuotation, dispatch),
	uploadFileRfq: bindActionCreators(uploadFileRfq, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitQuote);