import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const GET_ACCOUNTS_LIST = '/account/list'
const GET_ACCOUNT = '/account/detail'
const ADD_ACCOUNT = '/account/register'
const UPDATE_ACCOUNT = '/account/update'
const DELETE_ACCOUNT = '/account'
const LOGIN_IN_TO_ACCOUNT = '/account/login'
const IMPERSONATE_ACCOUNT = '/account/impersonate'

// // ************API call Functions starts here **************

export const addAccount = async (data) => {
  return await axios('post', ADD_ACCOUNT, data, { loader: loaders.OVERLAY, successMessage: 'Account Created Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getAccountsList = async (params) => {
  return await axios('get', GET_ACCOUNTS_LIST, {}, { loader: loaders.OVERLAY, server: microServices.DE, params }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getAccount = async (id) => {
  return await axios('get', `${GET_ACCOUNT}/${id}`, {}, { loader: loaders.OVERLAY, server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const loginToAccount = async (data, name) => {
  return await axios('post', `${LOGIN_IN_TO_ACCOUNT}/${data}`, {}, { loader: loaders.OVERLAY, successMessage: `Logged in to Account '${name}' Successfully`, server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const loginToImpersonateAccount = async (data, name) => {
  return await axios('post', `${IMPERSONATE_ACCOUNT}/${data}`, {}, { loader: loaders.OVERLAY, successMessage: `Logged in to User Account '${name}' Successfully`, server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateAccount = async (data, id) => {
  return await axios('put', `${UPDATE_ACCOUNT}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Account Updated Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteAccount = async (id) => {
  return await axios('delete', `${DELETE_ACCOUNT}/${id}`, {}, { successMessage: 'Account Deleted Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
