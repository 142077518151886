import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  ListSubheader,
  Checkbox,
  FormControlLabel,
  Grid,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Input,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import "./inputs.scss";
import DateFnsUtils from "@date-io/date-fns";
import "react-phone-number-input/style.css";
import MuiPhoneNumber from "material-ui-phone-number";
import MUIPlacesAutocomplete from "mui-places-autocomplete";
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radioFormControl: {
    marginLeft: theme.spacing(1),
  },
  radioLabel: {
    padding: "5px",
  },
  radio: {
    flexDirection: "row",
  },
}));
export default function InputCommon(props) {
  const classes = useStyles();
  let input = null;
  if (props.type === "text") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          className={props.className}
          type={props.type}
          name={props.name}
          margin="dense"
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disable}
        />
      </Grid>
    );
  }
  if (props.type === "textarea") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          className={props.className + " textField "}
          type={props.type}
          name={props.name}
          margin="dense"
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          multiline
          rows="4"
          value={props.value}
          disabled={props.disable}
        />
      </Grid>
    );
  }
  if (props.type === "password") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          margin="dense"
          name={props.name}
          type={props.type}
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disable}
        />
      </Grid>
    );
  }

  if (props.type === "email") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          type={props.type}
          margin="dense"
          name={props.name}
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disable}
        />
      </Grid>
    );
  }

  if (props.type === "amount") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        className="dollarField"
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        {/* <div className="dollarSign"> </div> */}
        <span className="dollar-symbol">$</span>
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          margin="dense"
          name={props.name}
          className={props.className}
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          type="number"
          value={props.value || ""}
          disabled={props.disable}
          InputProps={props.inputProps}
          inputProps={props.input}
        />
      </Grid>
    );
  }

  if (props.type === "number") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <TextField
          id={"outlined-basic"}
          variant={"outlined"}
          label={props.label}
          margin="dense"
          name={props.name}
          type={props.type}
          className={props.className}
          error={!props.valid && props.touched}
          helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disable}
          InputProps={props.inputProps}
          inputProps={props.input}
        />
      </Grid>
    );
  }

  if (props.type === "switch") {
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <Switch
          name={props.name}
          className={props.class}
          color="primary"
          disabled={props.disabled}
          onChange={props.onChange}
          checked={props.value ? true : false}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Grid>
    );
  }

  if (props.type === "select") {
    const menuItem =
      Array.isArray(props.items) &&
      props.items.map((res) => {
        if (res.subHeader)
          return <ListSubheader key={res.key}>{res.key}</ListSubheader>;

        return (
          <MenuItem key={res.key} value={res.value}>
            {res.key}
          </MenuItem>
        );
      });
    input = (
      <Grid
        xs={props.grid ? props.grid : 12}
        className="selectWidth"
        item
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <FormControl
          variant={!props.simple ? "standard" : "filled"}
          margin="dense"
          error={!props.valid && props.touched}
          fullWidth
        >
          {props.label && (
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
          )}
          <Select
            labelId="demo-simple-select-label"
            // id="demo-simple-select-outlined"
            onChange={props.onChange}
            name={props.name}
            className={props.className}
            placeholder={props.label}
            value={props.value || ""}
            disabled={props.disable}
          // variant={props.variant || 'outlined'}
          >
            {menuItem}
          </Select>
          {!props.valid && props.touched ? (
            <FormHelperText>
              {!props.valid && props.touched ? props.error : null}
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
    );
  }

  if (props.type === "radio") {
    input = (
      <FormControl
        className="cs_radio"
        error={!props.valid && props.touched}
        disabled={props.disabled || false}
      >
        {props.showLabel && (
          <FormLabel component="legend">{props.label}</FormLabel>
        )}
        <RadioGroup
          aria-label={props.arialabel}
          name={props.name}
          value={props.value}
          color="primary"
          row={props.row}
          onChange={props.onChange}
          className={classes.radio}
        >
          {props.options.map((option) => {
            return (
              <FormControlLabel
                className="cs_radio"
                key={option.key || option.value}
                value={option.value}
                disabled={props.disable}
                control={
                  <Radio key={option.key || option.value} color="primary" />
                }
                label={option.text}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
  if (props.type === "checkbox") {
    input = (
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value ? true : false}
            onChange={props.onChange}
            name={props.name}
            type={props.type}
            color="primary"
            disabled={props.disable}
            key={props.keyIndex}
          />
        }
        label={props.label}
      />
    );
  }

  if (props.type === "phoneNumber") {
    input = (
      <div
        className="phoneNumberContainer"
        style={{ marginBottom: "5px", marginTop: "5px" }}
      >
        <FormControlLabel
          control={
            <MuiPhoneNumber
              variant="outlined"
              margin="dense"
              inputVariant="outlined"
              placeholder={props.label || props.data.label}
              id={props.name}
              name={props.name}
              autoFormat={props.autoFormat}
              inputClass="phoneNumber"
              disabled={props.disable}
              disableDropdown={props.disable}
              defaultCountry="au"
              value={props.value || "+61"}
              onChange={props.onChange}
              inputProps={{ value: props.value || "+61" }}
            />
          }
          className="phoneNumberContainer"
        />
        <span className="phoneNumberError">{props.error}</span>
      </div>
    );
  }

  if (props.type === "address") {
    input = (
      <div
        style={{
          zIndex: 2,
          width: "100%",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        <FormControl
          variant="outlined"
          margin="dense"
          className="address-contaier"
        >
          <MUIPlacesAutocomplete
            onSuggestionSelected={props.onSuggestionSelected}
            textFieldProps={{
              variant: "outlined",
              margin: "dense",
              id: "outlined-basic",
              disabled: props.disable,
              type: "text",
              name: props.name,
              label: props.label,
              inputLabel: "Street",
              className: "txtfield",
              value: props.value,
              onChange: props.onChange,
              error: !props.valid && props.touched,
              multiline: true,
            }}
            types={["(regions)"]}
            renderTarget={() => <div />}
          />
          <span className="addressError">{props.error}</span>
        </FormControl>
      </div>
    );
  }

  if (props.type === "permission") {
    let activePermissions = [];
    props.permissions
      .filter((permission) => permission.value && permission.default)
      .forEach((permission) => {
        activePermissions = activePermissions.concat(permission.default);
      });
    input = (
      <Grid xs={props.grid ? props.grid : 12} item spacing={2}>
        <Grid xs={props.grid ? props.grid : 4} item>
          {props.label}
        </Grid>
        <Grid xs={props.grid ? props.grid : 8} item>
          {props.permissions.map((permission) => (
            <FormControlLabel
              key={permission.name}
              control={
                <Checkbox
                  key={permission.name}
                  checked={
                    permission.value ||
                      activePermissions.includes(permission.name)
                      ? true
                      : false
                  }
                  name={permission.name}
                  onClick={props.onSelection}
                  disabled={
                    permission.disabled ||
                    activePermissions.includes(permission.name)
                  }
                  color="primary"
                />
              }
              label={permission.label}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
  if (props.type === "datepicker") {
    const dateElement = (
      <DatePicker
        {...props}
        className={props.className}
        inputVariant="outlined"
        margin="dense"
        placeholder={props.label}
        disabled={props.disable}
        error={!props.valid && props.touched}
        helperText={!props.valid && props.touched ? props.error : null}
        name={props.name}
        format={props.format || "dd/MM/yyyy"}
        onChange={props.onChange}
        value={props.value || null}
      />
    );
    input = (
      <Grid xs={props.grid ? props.grid : 12} className="selectWidth" item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {dateElement}
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  if (props.type === "datetime") {
    const dateElement = (
      <DateTimePicker
        {...props}
        className={props.className}
        inputVariant="outlined"
        margin="dense"
        placeholder={props.label}
        disabled={props.disable}
        error={!props.valid && props.touched}
        helperText={!props.valid && props.touched ? props.error : null}
        name={props.name}
        format={props.format || "dd/MM/yyyy hh:mm a"}
        onChange={props.onChange}
        value={props.value || null}
      />
    );
    input = (
      <Grid xs={props.grid ? props.grid : 12} className="selectWidth" item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {dateElement}
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  if (props.type === "autoComplete") {
    input = (
      <Grid xs={props.grid ? props.grid : 12} className="selectWidth" item>
        <Autocomplete
          {...props}
          id="combo-box-demo"
          multiple
          size={props.size || "medium"}
          disabled={props.disable}
          options={props.options}
          getOptionLabel={(option) => option.title}
          className={props.className}
          onChange={props.onChange}
          value={props.value}
          getOptionSelected={(option, value) => {
            return value.vendorId === option.vendorId;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={props.name}
              label={props.label}
              variant="outlined"
              fullWidth
              placeholder={props.placeholder || ""}
              error={!props.valid && props.touched}
              helperText={!props.valid && props.touched ? props.error : null}
            // inputProps={props.inputProps}
            />
          )}
        />
      </Grid>
    );
  }

  if (props.type === "multiSelect") {
    input = (
      <Grid xs={props.grid ? props.grid : 12} className="selectWidth" item>
        <FormControl
          variant="outlined"
          margin="dense"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            {props.label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            multiple
            value={props.value}
            onChange={props.onChange}
            input={<Input />}
            name={props.name}
            onClose={props.onClose}
          >
            {props.items.map(({ key, value }) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  if (props.type === "timepicker") {
    const timeElement = (
      <TimePicker
        {...props}
        inputVariant="outlined"
        margin={props.margin ? props.margin : "dense"}
        label={props.inputLabel}
        id={props.name}
        ampm={props.ampm}
        readOnly={props.readOnly}
        onChange={props.onChange}
        error={!props.valid && props.touched}
        helperText={!props.valid && props.touched ? props.error : null}
        disabled={props.disable}
        inputProps={{
          value: props.value ? moment(props.value).format(props.format) : "",
        }}
        {...props}
      />
    );
    input = (
      <Grid xs={props.grid ? props.grid : 12} className="selectWidth" item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {timeElement}
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  return <>{input}</>;
}
