import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCategories,
  addItem,
  editItem,
  getItem,
  getUnits,
} from "src/actions/inventory.action";
import { withRouter } from "src/util/with-router";
import AddCategory from "./add-category";
import AddUnit from "./add-unit-dialog";
import { getFormatedUnit } from "src/util/index";
import convert from "convert-units";
import { emailValidator } from "src/util/validators/email";
import idx from "idx";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import SelectInput from "src/components/inputs/select";
import CustomInput, { AmountInput } from "src/components/inputs/new-input";
import { phoneValidator } from "src/util/validators/phone";
import { CATALOGS, MATERIAL } from "src/constants/route-paths";
import _ from "lodash";
import { passwordValidator } from "src/util/validators/password";

function AddMaterial({ writePermission, ...props }) {

  const [fields, setFields] = useState({
    categoryId: {
      name: "categoryId",
      label: "Catalog",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
    item: {
      name: "item",
      label: "Item",
      type: "textarea",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
    cost: {
      name: "cost",
      label: "Unit Cost",
      type: "amount",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required", "minimum"],
    },
    manufacturer: {
      name: "manufacturer",
      label: "Manufacturer",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
    partNo: {
      name: "partNo",
      label: "Part No",
      type: "text",
      touched: false,
      error: false,
      valid: true,
      value: "",
      validation: ["required"],
    },
    unit: {
      name: "unit",
      label: "Select Unit",
      type: "select",
      touched: false,
      error: false,
      valid: true,
      value: "",
      items: [],
      validation: ["required"],
    },
  })
  const [state, setState] = useState({
    openAddCategoryDialog: false,
    addUnitDialogState: false,
    allUnits: [],
    unitList: [],
  })

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    const units = convert().list();
    const unitList = [{ key: "Unit", value: "unit" }];
    units.map((unit) => {
      unitList.push({
        key: `${unit.singular} (${unit.abbr})`,
        value: unit.abbr,
      });
    });
    fetchUnitList();
    setFields(prev => ({
      ...prev,
      ...(props.categoryId ? { categoryId: { ...prev.categoryId, value: props.categoryId } } : {}),
      ...(props.partNo ? { partNo: { ...prev.partNo, value: props.partNo } } : {}),
      ...(props.manufacturer ? { manufacturer: { ...prev.manufacturer, value: props.manufacturer } } : {}),
      ...(props.itemName ? { item: { ...prev.item, value: props.itemName } } : {}),
      ...(props.unitCost ? { cost: { ...prev.cost, value: props.unitCost } } : {}),
    }));
    setState(prev => ({
      ...prev,
      unitList
    }));
    fetchCategories();
    if (props.match && props.params.itemId) {
      props.getItem(props.params.itemId).then((item) => {
        editItem(item);
      });
    }
  }

  const fetchUnitList = () => {
    props.getUnits().then((res) => {
      const allUnits = (res && Array.isArray(res) && res.length > 0 && res[0].units) || [];
      const units = getFormatedUnit(allUnits);
      setFields(prev => ({
        ...prev,
        unit: { ...prev.unit, items: units },
      }));
      setState(prev => ({
        ...prev,
        allUnits
      }));
    });
  };

  const addUnitDialogHandler = () => {
    let { addUnitDialogState } = state;
    setState(prev => ({
      ...prev,
      addUnitDialogState: !prev.addUnitDialogState
    }));
    if (addUnitDialogState) {
      fetchUnitList();
    }
  };

  const fetchCategories = () => {
    props.getCategories().then((categories) => {
      const data = categories.map((category) => ({
        value: category.id,
        key: category.name,
      }));
      let catalog = idx(props.itemName, (_) => _.catalog) || "";
      let filter
      if (catalog) {
        filter = data.find((item) => item.key === catalog);
      }
      setFields(prev => ({
        ...prev,
        categoryId: {
          ...prev.categoryId,
          items: data,
          ...(catalog ? { value: idx(filter, (_) => _.value) || "" } : {})
        },
      }));
    });
  };

  const editItem = (item) => {
    setFields(prev => ({
      ...prev,
      categoryId: { ...prev.categoryId, value: item.categoryId },
      item: { ...prev.item, value: item.item },
      cost: { ...prev.cost, value: item.cost },
      manufacturer: { ...prev.manufacturer, value: item.manufacturer },
      partNo: { ...prev.partNo, value: item.partNo },
      unit: { ...prev.unit, value: item.unit },
    }));
    setState(prev => ({
      ...prev,
      edit: true,
      itemId: item.id,
    }));
  };

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const { error, valid } = handleValidation(e.target, fields[name].validation);
    setFields(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
        touched: true,
        error: error,
        valid: valid
      },
    }));
  };

  const handleValidation = ({ name, type, value }, validation = []) => {
    let error = false;
    let valid = true;
    if (validation.includes("required") && (value === undefined || value === "")) {
      return { error: "This Field is Required", valid: false };
    }

    if (validation.includes("minimum") && value < 0) {
      return { error: "Should be greater then 0!", valid: false };
    }

    if (name === "email") {
      let status = emailValidator(value);
      if (!status) {
        return { error: "Please Enter Valid Email", valid: false };
      }
    }

    if (name === "phoneNumber") {
      let status = phoneValidator(value)
      if (!status) {
        return { error: "Please Enter Valid Mobile Number", valid: false };
      }
    }

    if (name === "password") {
      let status = passwordValidator(value)
      return status;
    }
    return { error, valid };
  };

  const cancelHandler = () => {
    if (typeof props.dialogClose === "function") {
      props.dialogClose();
    } else {
      props.history(`/${CATALOGS}/${MATERIAL}`);
    }
  };

  const addCategoryDialogHandler = () => {
    let { openAddCategoryDialog } = state;
    setState(prev => ({
      ...prev,
      openAddCategoryDialog: !prev.openAddCategoryDialog
    }));
    if (openAddCategoryDialog) {
      fetchCategories();
    }
  };

  const submitForm = () => {
    const { itemId } = state;
    let _fields = _.cloneDeep(fields)
    let isFormValid = true;
    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key], _fields[key].validation);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }
    if (!isFormValid) {
      setFields(_fields)
    } else {
      const data = {
        categoryId: _fields.categoryId.value,
        item: _fields.item.value,
        cost: _fields.cost.value,
        manufacturer: _fields.manufacturer.value,
        partNo: _fields.partNo.value,
        unit: _fields.unit.value,
      };
      if (!itemId) {
        if (props.isInlineItem) {
          props.addItem(data).then((res) => { });
          props.submitHandler(data, true);
        } else {
          props.addItem(data).then((res) => {
            if (typeof props.submitHandler === "function") {
              props.submitHandler(res);
            } else {
              props.history(`/${CATALOGS}/${MATERIAL}`);
            }
          });
        }
      } else {
        props.editItem(data, itemId).then((res) => {
          props.history(`/${CATALOGS}/${MATERIAL}`);
        });
      }
    }
  };

  return (
    <>
      {state.openAddCategoryDialog && (
        <AddCategory
          open={state.openAddCategoryDialog}
          dialogClose={addCategoryDialogHandler}
        />
      )}
      <AddUnit
        dialogClose={addUnitDialogHandler}
        open={state.addUnitDialogState}
        allUnits={state.allUnits}
        unitList={state.unitList || []}
      />
      <div className="purchase-order-block">
        {typeof props.dialogClose !== "function" ? (<>
          <h2 className="content-heading text-uppercase">
            {state.edit ? "Edit Material" : "Add Material"}
          </h2>
          <div className="col-12 breadcrumb-block p-0">
            <Breadcrumb>
              <Breadcrumb.Item onClick={cancelHandler}>MATERIAL</Breadcrumb.Item>
              <Breadcrumb.Item active>
                {state.edit ? "Edit Material" : "Add Material"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </>) : ''}

        <div className="row mt-2 mb-1">
          <div className="col-lg-6 col-md-7 mt-3">
            <SelectInput
              {...fields.categoryId}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="col-lg-4 col-md-3 mt-3">
            <p onClick={addCategoryDialogHandler} className="add-item-text">
              Add Catalog
            </p>
          </div>
          <div className="col-12">
            <hr className="mb-0"></hr>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 mt-3">
            <CustomInput
              {...fields.item}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>

          <div className="col-lg-6 col-md-6 mt-3">
            <AmountInput
              {...fields.cost}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
        </div>

        <div className="row mt-2 mb-1">
          <div className="col-lg-6 col-md-7 mt-3">
            <SelectInput
              {...fields.unit}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="col-lg-4 col-md-3 mt-3">
            <p onClick={addUnitDialogHandler} className="add-item-text">Add Unit</p>
          </div>
          <div className="col-12">
            <hr className="mb-0"></hr>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 mt-3">
            <CustomInput
              {...fields.manufacturer}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="col-lg-6 col-md-6 mt-3">
            <CustomInput
              {...fields.partNo}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
        </div>


        <div className="col-12 d-flex justify-content-end gap-3 mt-3">
          {writePermission && (<Button onClick={submitForm} className="secondarybtn">SAVE</Button>)}
          <Button onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
        </div>
      </div >
    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  addItem: bindActionCreators(addItem, dispatch),
  getItem: bindActionCreators(getItem, dispatch),
  editItem: bindActionCreators(editItem, dispatch),
  getUnits: bindActionCreators(getUnits, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMaterial));
