import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@mui/material/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import { logout } from './../actions/user-management.action'
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import './home.scss'
import * as paths from '../constants/route-paths'
import idx from 'idx'


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'flex',
  },
}))

function TopBar(props) {
  const classes = useStyles()
  const { name, role } = props.user
  const [anchorEl, setAnchorEl] = React.useState(null)
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl)
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const { user } = props

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null)
  // }

  const handleMenuClose = () => {
    setAnchorEl(null)
    // handleMobileMenuClose()
  }

  const logout = () => {
    handleMenuClose()
    props.logout()
    navigate('/login')
  }

  const editProfile = () => {
    handleMenuClose()
    navigate('/profile')
  }

  const changeAccount = () => {
    handleMenuClose()
    navigate(`/${paths.GLOBAL_ACCOUNT}`)
  }

  const resetPassword = () => {
    handleMenuClose()
    navigate('/profile/reset-password')
  }

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget)
  // }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div className='personDetail'>
        <Avatar alt={name} src='/static/images/avatar/1.jpg' />
        <div className="user-profile-list">
          <p className='name'>{name}</p>
          <p className='designation'>{role}</p>
          {idx(user, _ => _.selected_account.name) && <p className='designation'>{idx(user, _ => _.selected_account.name)} {user.global_user && <span className="link" onClick={changeAccount}>Change</span>} </p>}
        </div>
      </div>
      <MenuItem key={'editProfile'} onClick={editProfile}>Profile</MenuItem>
      <MenuItem key={'resetPassword'} onClick={resetPassword}>Reset Password</MenuItem>
      <MenuItem key={'logout'} onClick={logout}>Logout</MenuItem>

    </Menu>
  )

  return (
    <div className={classes.grow + ' mainTopbar Topbar desktop-View'}>
      <AppBar position='static'>
        <Toolbar>
          <div className="header-caption-section">
            {/* <h2>dashboard</h2> */}
            {/* ToDo */}
          </div>
          <div className="mobileHeader">
            <IconButton color='inherit' onClick={props.openHandler}>
              <MenuIcon />
            </IconButton>
            <div className='sidebarLogo'>
              <img src={require('./../assets/logo.svg').default} alt='Logo' />
            </div>
          </div>
          <div className={classes.grow} />
          {/* <div className={classes.sectionDesktop + ' iconsContainer'}>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <SearchIcon />
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <ForumIcon />
            </IconButton>
            <IconButton aria-label='show 17 new notifications' color='inherit'>
              <Badge badgeContent={0} color='secondary'>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>
          <div className="avatarBorderLeft"></div> */}
          <div aria-label='account of current user' aria-haspopup='true' aria-controls={menuId} onClick={handleProfileMenuOpen} className={classes.sectionDesktop + ' avatarLogoContainer'}>

            {<IconButton
              edge='end'
              color='inherit'
            >
              <Avatar alt={name} src='/static/images/avatar/1.jpg' />
            </IconButton>}

          </div>

        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  )
}
const mapStateToProps = state => ({
  user: state.user.userData,
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
