import React from 'react'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();

export default function AutocompleteInput({ label, value, placeholder, validation = [], addFilter, onChange, required, items, className = '', ...props }) {
  let isRequired = required || (validation.includes('required') && !(validation.includes('skip') || validation.includes('optional')) );
  return (
    <div className="form-group w-100">
      <div className={_.uniq(["input-block", "autocomplete-block", ...className.split(' ')]).join(' ')}>
        {(label || placeholder) && <label className="input-label">
          <span>{label || placeholder || ''} {isRequired ? <span className='text-danger'>*</span> : null}</span>
        </label>}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
            } else if (newValue && newValue.inputValue) {
            } else if (newValue) {
              if (addFilter) addFilter(newValue.key)
              if (onChange) onChange(newValue?.value || '')
            } else {
              if (addFilter) addFilter('')
            }
          }}
          onInputChange={(event, newValue) => {
            if (addFilter) addFilter(newValue)
          }}
          value={value}
          filterOptions={(options, params) => filter(options, params)}
          options={items}
          getOptionLabel={(option) => typeof option === "string" ? option : option.key}
          renderInput={(params) => <TextField {...params} variant="standard" label={label || placeholder} />}
        />
        {props.error && props.touched && <small className="float-start text-danger">
          {props.error}
        </small>}
      </div>
    </div>
  )
}
