import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'

export default function PreviewRFQ(props) {
  const { vendorType, description, projectDueDate, allAttachments, vendorEmails } = props
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    const data = []
    const materialId = []
    const stages = JSON.parse(JSON.stringify(props.stages))
    const options = JSON.parse(JSON.stringify(props.options))
    if (vendorType === 'Supplier') {
      stages.forEach(stage => {
        stage.tasks.forEach(task => {
          if (task.selected) {
            task.material && task.material.forEach(material => {
              if (material.selected) {
                if (materialId.includes(material.id)) {
                  data.forEach((item, index) => {
                    if (item.id === material.id) {
                      data[index].qty = parseFloat(data[index].qty) + parseFloat(material.qty)
                    }
                  })
                } else {
                  materialId.push(material.id)
                  data.push(material)
                }
              }
            })
          }
          task.subTasks.forEach(subTask => {
            if (subTask.selected) {
              subTask.material && subTask.material.forEach(material => {
                if (material.selected) {
                  if (materialId.includes(material.id)) {
                    data.forEach((item, index) => {
                      if (item.id === material.id) {
                        data[index].qty = parseFloat(data[index].qty) + parseFloat(material.qty)
                      }
                    })
                  } else {
                    materialId.push(material.id)
                    data.push(material)
                  }
                }
              })
            }
          })
        })
      })
      options.forEach(option => {
        if (option.selected) {
          option.material && option.material.forEach(material => {
            if (material.selected) {
              if (materialId.includes(material.id)) {
                data.forEach((item, index) => {
                  if (item.id === material.id) {
                    data[index].qty = parseFloat(data[index].qty) + parseFloat(material.qty)
                  }
                })
              } else {
                materialId.push(material.id)
                data.push(material)
              }
            }
          })
        }
      })
    } else {
      stages.forEach(stage => {
        if (stage.selected) {
          data.push({ name: stage.name, description: stage.description, type: 'stage', id: stage.id })
        }
        stage.tasks.map(task => {
          if (task.selected) {
            data.push({ name: task.name, description: task.description, type: 'task', id: task.id })
          }
          task.subTasks.forEach(subTask => {
            if (subTask.selected) {
              data.push({ name: subTask.name, description: subTask.description, type: 'subTask', id: subTask.id })
            }
          })
        })
      })
      options.forEach(option => {
        if (option.selected) {
          data.push({ name: option.name, description: option.description, type: 'option', id: option.id })
        }
      })
    }
    setFilteredData(data)
    props.getfilteredData(data)
  }, [])
  return (
    <div>
      <Grid container>
        <Grid container className='previewRFQCol' style={{ flexDirection: 'row' }}>
          <Grid sm={8} xs={12} item className='stageLabel' style={{ paddingRight: '20px' }}>
            Description: <b>{description}</b>
          </Grid>
          <Grid sm={4} xs={12} item className='stageLabel attachFile '>
            <p><b>Attached Documents</b></p>
            {allAttachments.map((attachment, i) =>
            (
              attachment.url ?
                <p key={i} className="attachedFiles"><a href={attachment?.url} rel="noopener noreferrer" target="_blank">{attachment.name}</a></p>
                :
                <p key={i} className="attachedFiles">{attachment.name}</p>
            )
            )}
          </Grid>
        </Grid>
        <Grid container className="previewRFQCol" style={{ margin: '20px 0 0' }}>
          <Grid md={3} sm={6} xs={12} item>
            <p>VendorType: <b>{vendorType}</b></p>
          </Grid>
          <Grid md={3} sm={6} xs={12} item>
            <p>Due Date: <b>{format(new Date(projectDueDate), 'do MMMMMM yyyy')}</b></p>
          </Grid>
        </Grid>
        <Grid className="previewRFQCol" container style={{ margin: '20px 0 0' }}>
          <Grid xs={12} item>
            <p>Vendor to Send: <b>{Array.isArray(vendorEmails) && vendorEmails.map(vendor => vendor.title).join(', ')}</b></p>
          </Grid>
        </Grid>
      </Grid>
      {vendorType === 'Supplier'
        ? <>
          <Grid xs={12} item className="mobileCreateRfqCol">
            <Grid container className="mobileCreateRfq" >
              <Grid container style={{ background: '#f4f4f4', margin: '0px', padding: '0px 15px' }}>
                <Grid xs={3} item>
                  <p><b>Items</b></p>
                </Grid>

                <Grid xs={3} item>
                  <p><b>Quantity</b></p>
                </Grid>
                <Grid xs={3} item>
                  <p><b>Manufacturer</b></p>
                </Grid>
                <Grid xs={3} item>
                  <p><b>Part No</b></p>
                </Grid>
              </Grid>
              <Grid xs={12} contaier>
                {
                  filteredData.map((material, index) => {
                    return (
                      <Grid key={index} container style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px' }}>
                        <Grid xs={3} item><p>{material.item}</p></Grid>
                        <Grid xs={3} item><p>{material.qty} {material.unit}</p></Grid>
                        <Grid xs={3} item><p>{material.manufacturer}</p></Grid>
                        <Grid xs={3} item><p>{material.partNo}</p></Grid>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </>
        : <>
          <Grid container style={{ background: '#f4f4f4', margin: '0px', padding: '0px 15px' }}>
            <Grid xs={4} item>
              <p><b>Task</b></p>
            </Grid>
            <Grid xs={8} item>
              <p><b>Description</b></p>
            </Grid>
          </Grid>
          <Grid xs={12} contaier>
            {
              filteredData.map((task, index) => {
                return (
                  <Grid container key={index} style={{ backgroundColor: index % 2 === 0 ? '#fcfcfc' : '#F4F4F4', padding: '0 15px' }}>
                    <Grid xs={4} item><p>{task.name}</p></Grid>
                    <Grid xs={8} item><p>{task.description}</p></Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </>}
    </div>
  )
}
