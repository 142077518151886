import React from "react";
import { useSelector } from "react-redux";
import * as paths from "src/constants/route-paths";
import { history } from "src/util/history";
import Cookies from "js-cookie";

export default function RBACApplicationAuth({ moduleURL = '', component: Component }) {
  let projectCategories = useSelector(state => state.project.projectCategories) || []
  let projectType = useSelector(state => state.project.projectType)
  let user = useSelector(state => state.user.userData)

  if (!projectType) {
    projectType = Cookies.get("projectType");
  }

  const userPermissions = user.permissions || {};
  const globalUser = user.global_user || false;

  let financePermission = 0;
  let userAccessControl = 0;
  let updatePermission = false;
  let writePermission = false;
  let readPermission = false;
  let projectFinance = [], projectModules = [];
  projectCategories.map(item => {
    if ((globalUser && user.selected_account.id) || userPermissions[`project${item.type}Finance`]) {
      projectFinance.push(item.type)
    }
    if ((globalUser && user.selected_account.id) || userPermissions[`project${item.type}`]) {
      projectModules.push(item.type)
    }
  })
  const path = moduleURL.split("/")[1] || "";

  switch (path) {
    case paths.DASHBOARD:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.dashboard;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.USER_MANAGEMENT:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.user;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.CUSTOMER_MANAGEMENT:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.customer;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.SUPPLIERS:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.supplier;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.CONTRACTORS:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.contractor;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.RECEIVABLES:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.finance;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.CATALOGS:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.catalog;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.PROJECT_MANAGEMENT:
      let projectTypeObj = projectCategories.find(item => item.type === projectType);
      if (projectTypeObj) {
        userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions[`project${projectTypeObj.type}`] || 0;
      }
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.TEMPLATES:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.template;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.INVENTORY:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.inventory;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.ASSET_MANAGEMENT:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.asset;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.TIMESHEET:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.workforce;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.GLOBAL_SETTING:
      userAccessControl = globalUser && user.selected_account.id ? 2 : userPermissions.globalSetting;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    case paths.GLOBAL_ACCOUNT:
      userAccessControl = globalUser ? 2 : 0;
      if (userAccessControl === 0) {
        if (history.navigate) history.navigate('');
        return "";
      }
      break;
    default: userAccessControl = 0; break;
  }

  readPermission = userAccessControl === 1 || globalUser ? true : false;
  updatePermission = userAccessControl === 2 || globalUser ? true : false;
  writePermission = userAccessControl === 2 || globalUser ? true : false;
  
  return (
    <Component
      permissions={{
        financePermission: financePermission,
        userAccessControl,
        updatePermission,
        writePermission,
      }}
      globalUser={globalUser}
      readPermission={readPermission}
      userAccessControl={userAccessControl}
      writePermission={writePermission}
      projectFinance={projectFinance}
      rFinancePermission={globalUser && user.selected_account?.id ? true : userPermissions.finance > 0 ? true : false}
      wFinancePermission={globalUser && user.selected_account?.id ? true : userPermissions.finance > 1 ? true : false}
      rSupplierPermission={globalUser && user.selected_account?.id ? true : userPermissions.supplier > 0 ? true : false}
      wSupplierPermission={globalUser && user.selected_account?.id ? true : userPermissions.supplier > 1 ? true : false}
      rContractorPermission={globalUser && user.selected_account?.id ? true : userPermissions.contractor > 0 ? true : false}
      wContractorPermission={globalUser && user.selected_account?.id ? true : userPermissions.contractor > 1 ? true : false}
      wTemplatePermission={globalUser && user.selected_account?.id ? true : userPermissions.template > 1 ? true : false}
      aUserPermission={globalUser && user.selected_account?.id ? true : userPermissions.userAdmin > 1 ? true : false}
      projectModules={projectModules}
      projectModule={projectModules.length ? true : false}
    />
  )

}
