import { subTaskCalculation } from "src/util/calculations/calculate-sub-task";

export const formatTaskData = (data, name = '', skipId = true) => {
    let { subTaskArray = [] } = data;
    let total = { material: 0, subcontractor: 0, labor: 0 }
    let req = {
        name: name,
        description: data.description,
        subtasks: subTaskArray.map(subtask => {
            let { material = [], labor = [], subcontractor = [] } = subtask || {}
            material = formatMaterials(material).map(item => JSON.stringify(item))
            labor = formatLabors(labor).map(item => JSON.stringify(item))
            subcontractor = formatSubcontractors(subcontractor).map(item => JSON.stringify(item))
            let calculation = subTaskCalculation({
                subtask: { ...data, material, labor, subcontractor },
                profitMargin: 0,
                profitMarginType: 'PERCENTAGE',
            })
            total.material += parseFloat(calculation.material.cost || 0);
            total.subcontractor += parseFloat(calculation.subcontractor.cost || 0);
            total.labor += parseFloat(calculation.labor.cost || 0);
            return {
                ...(!skipId ? { id: subtask.id } : {}),
                description: subtask.description,
                material_cost: parseFloat(calculation.material.cost || 0),
                subcontractor_cost: parseFloat(calculation.subcontractor.cost || 0),
                labor_cost: parseFloat(calculation.labor.cost || 0),
                material: material,
                labor: labor,
                subcontractor: subcontractor
            }
        })
    }
    req.material_cost = total.material;
    req.subcontractor_cost = total.subcontractor;
    req.labor_cost = total.labor;

    return req;
}


export const formatMaterials = (materialsarray = []) => {
    const materialData = materialsarray.map((item) => ({
        item: item.name.value,
        id: item.id,
        categoryId: item.categoryId,
        manufacturer: item.manufacturer,
        partNo: item.partNo,
        cost: item.costPerItem.value,
        saleAmount: item.costPerItem.value,
        saleAmountLock: item.saleAmountLock.value,
        qty: item.qty.value,
        unit: item.unit.value || null,
        total: item.total.value,
        isInlineItem: item.isInlineItem || false,
    }));

    return materialData;
};

export const formatLabors = (laborarray = []) => {
    const laborData = laborarray.map((_labor) => {
        return {
            name: _labor.name.value,
            level: _labor.level.value,
            id: _labor.id.value,
            cost: _labor.costPerItem.value,
            qty: _labor.qty.value,
            calloutCost: _labor.calloutCost.value || 0,
            averageCost: _labor.costPerItem.value || _labor.labourCost.value || 0,
            total: _labor.total.value,
        }
    });
    return laborData;
};

export const formatSubcontractors = (subcontractorarray = []) => {
    const subcontractorData = subcontractorarray.map((_subcontractor) => ({
        name: _subcontractor.name.value,
        id: _subcontractor.id.value,
        Cost: _subcontractor.Cost.value,
        saleAmount: _subcontractor.Cost.value,
        saleAmountLock: _subcontractor.saleAmountLock.value,
        description: _subcontractor.description.value,
    }));
    return subcontractorData;
};