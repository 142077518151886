import {
    addGlobalSettingTemplate as addGlobalSettingTemplateAPI,
    getAllGlobalSettingTemplate as getAllGlobalSettingTemplateAPI,
    getGlobalSettingTemplate as getGlobalSettingTemplateAPI,
    editGlobalSettingTemplate as editGlobalSettingTemplateAPI,
    deleteGlobalSettingTemplate as deleteGlobalSettingTemplateAPI,
    // editProjectTypeSetting as editProjectTypeSettingAPI,
    getGlobalSettingTemplateTypes as getGlobalSettingTemplateTypesAPI,
    // UpdateProjectType as UpdateProjectTypeAPI,
} from '../api/global-setting-template.api'

export const addGlobalSettingTemplate = (settingData) => {
    return (dispatch, getState) => {
        return addGlobalSettingTemplateAPI(settingData)
    }
}


export const getGlobalSettingTemplate = (settingId) => {
    return (dispatch, getState) => {
        return getGlobalSettingTemplateAPI(settingId)
    }
}
export const getGlobalSettingTemplateTypes = (settingType, projectId) => {
    return (dispatch, getState) => {
        return getGlobalSettingTemplateTypesAPI(settingType, projectId)
    }
}
export const getAllGlobalSettingTemplate = () => {
    return (dispatch, getState) => {
        return getAllGlobalSettingTemplateAPI()
    }
}
export const editGlobalSettingTemplate = (settingData, settingId) => {
    return (dispatch, getState) => {
        return editGlobalSettingTemplateAPI(settingData, settingId)
    }
}

export const deleteGlobalSettingTemplate = (settingId) => {
    return (dispatch, getState) => {
        return deleteGlobalSettingTemplateAPI(settingId)
    }
}

/* export const editProjectTypeSetting = (settingData, settingId) => {
    return (dispatch, getState) => {
        return editProjectTypeSettingAPI(settingData, settingId)
    }
} */



/* export const getProjectCategory = (data) => {
    return (dispatch, getState) => {
        return getGlobalSettingTemplateAPI(data).then((response) => {
            dispatch(saveProjectCategory({ user: getState().user, projectCategory: idx(response, _response => _response) }))
        }).catch((e) => {
            console.error(e)
        })
    }
} */


/* export const getGlobalSettingTemplateReducer = (type) => {
    return (dispatch, getState) => {
        return getGlobalSettingTemplateAPI(type).then((response) => {
            if (type === 'FINANCE_TAX')
                dispatch(saveFinanceTax(idx(response, _response => _response)))
        })
    }
}

export const UpdateProjectType = (settingData, settingId) => {
    return (dispatch, getState) => {
        return UpdateProjectTypeAPI(settingData, settingId)
    }
} */
