import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import _ from "lodash";
import { getAsset, editAsset } from "../../actions/asset-management.action";
import AssetHistory from "./asset-history";
import "./scss/asset-management.scss";
import { withRouter } from "../../util/with-router";
import { ASSET_MANAGEMENT } from "../../constants/route-paths";
import Button from 'react-bootstrap/Button';
import DatePickerInput from "src/components/inputs/date-picker";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SelectInput from "src/components/inputs/select";
import CustomInput from "src/components/inputs/new-input";
import TextAreaInput from "src/components/inputs/textarea-input";

const assetType = [
  {
    key: "Leased",
    value: "LEASED",
  },
  {
    key: "Purchased",
    value: "PURCHASED",
  },
];

const assetCategory = [
  {
    key: "Vehicle",
    value: "VEHICLE",
  },
  {
    key: "Plant & Equipment",
    value: "PLANT_AND_EQUIPMENT",
  },
];

const allocationType = [
  {
    key: "Vehicle",
    value: "VEHICLE",
  },
  {
    key: "User",
    value: "USER",
  },
  {
    key: "Project",
    value: "PROJECT",
  },
  {
    key: "Service",
    value: "SERVICE",
  },
];

const assetStatus = {
  AVAILABLE: {
    value: "Available",
    color: "#3CB371",
  },
  INUSE: {
    value: "In-Use",
    color: "#1E90FF",
  },
  MAINTENANCE: {
    value: "Maintenance",
    color: "#FFD700",
  },
  DISCARD: {
    value: "Discard",
    color: "#FF6347",
  },
  DAMAGED: {
    value: "Damaged",
    color: "#e8d3e4",
  },
};

class EditAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: {
          name: "name",
          label: "Asset Name",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        category: {
          name: "category",
          label: "Catalog",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "",
          items: assetCategory,
          required: true,
        },
        assetNo: {
          name: "assetNo",
          label: "Asset Registration No/ID",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          required: true,
        },
        assetType: {
          name: "assetType",
          label: "Asset Type",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "",
          items: assetType,
          required: true,
        },
        purchasingDate: {
          name: "purchasingDate",
          label: "Purchasing Date",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: "",
          clearable: true,
        },
        expiryDate: {
          name: "expiryDate",
          label: "Insurance/Registration Date",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: "",
          clearable: true,
        },
        description: {
          name: "description",
          label: "Notes/Description",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
      },
      isDisabled: false,
      assetData: {},
    };
  }

  componentDidMount() {
    if (this.props.params.assetId) {
      this.getAssetData(this.props.params.assetId);
    }
  }

  getAssetData = async (assetId) => {
    const assetData = await this.props.getAsset(assetId);

    let fields = this.state.fields;
    Object.keys(fields).forEach((key) => {
      fields[key].value = assetData[key];
    });

    this.setState({ fields, isDisabled: true, assetData });
  };

  inputChangeHandler = (e, fieldName = "") => {
    let fields = this.state.fields;
    var name;
    var value;
    if (fieldName === "purchasingDate" || fieldName === "expiryDate") {
      name = fieldName;
      value = e;
    } else if (_.isObject(e)) {
      name = e.target.name;
      value = e.target.value;
    }
    fields[name].value = value;
    fields[name].touched = true;

    this.setState({ fields });
  };

  handleValidation = ({ name, value, required }) => {
    let error = false;
    let valid = true;

    if (required && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }

    return { error, valid };
  };

  enableEdit = () => {
    this.setState((prevState) => ({
      isDisabled: !prevState.isDisabled,
    }));
  };

  submitForm = async () => {
    const { fields, assetData } = this.state;
    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = {
        name: fields.name.value,
        assetNo: fields.assetNo.value,
        category: fields.category.value,
        assetType: fields.assetType.value,
        purchasingDate: fields.purchasingDate.value || "",
        expiryDate: fields.expiryDate.value || "",
        description: fields.description.value,
      };

      await this.props.editAsset(data, assetData.id).then(() => {
        this.handleClose();
      }).catch(e => console.error(e));
    }
  };

  handleClose = () => {
    this.props.history(`/${ASSET_MANAGEMENT}`);
  };

  renderStatusInformation = () => {
    const { assetData } = this.state;

    if (assetData.status === "INUSE") {
      const assetAllocation = assetData.assetAllocation || {};
      return (
        <>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Allocated To</label>
              <p>{allocationType.find((x) => x.value === assetAllocation.type).key}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Allocation Id</label>
              <p>{assetAllocation.allocationName}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Allocation Date</label>
              <p> {(assetAllocation.allocationDate && format(new Date(assetAllocation.allocationDate), "dd/MM/yyyy")) || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Return Date</label>
              <p>{(assetAllocation.returnDate && format(new Date(assetAllocation.returnDate), "dd/MM/yyyy")) || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Allocation Description</label>
              <p>{assetAllocation.description || "-"}</p>
            </div>
          </div>
        </>
      );
    } else if (assetData.status === "MAINTENANCE") {
      const assetMaintenance = assetData.assetMaintenance || {};
      return (
        <>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Maintenance Reason </label>
              <p>{assetMaintenance.reason}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Maintenance Description</label>
              <p>{assetMaintenance.description || "-"}</p>
            </div>
          </div>
        </>
      );
    }
  };

  render() {
    const { fields, isDisabled, assetData } = this.state;
    const statusData = (assetData.status && assetStatus[assetData.status]) || {};

    return (
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Edit Assets</h2>
            <div className="col-12 breadcrumb-block p-0">
              <Breadcrumb>
                <Breadcrumb.Item onClick={this.handleClose}>Assets</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Assets</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="w-auto ms-auto mt-auto">
            <AssetHistory assetId={assetData.id} />
          </div>
        </div>
        <div className="row">

          <div className="col-lg-4 mt-3">
            <SelectInput
              {...fields.category}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.category.name)
              }
            />
          </div>
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.name}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.name.name)
              }
            />
          </div>
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.assetNo}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.assetNo.name)
              }
            />
          </div>
          <div className="col-lg-4 mt-3">
            <SelectInput
              {...fields.assetType}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.assetType.name)
              }
            />
          </div>
          <div className="col-lg-4 mt-3">
            <DatePickerInput
              {...fields.purchasingDate}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.purchasingDate.name)
              }
            />
          </div>
          <div className="col-lg-4 mt-3">
            <DatePickerInput
              {...fields.expiryDate}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.expiryDate.name)
              }
            />
          </div>
          <div className="col-12 mt-3">
            <TextAreaInput
              {...fields.description}
              disabled={isDisabled}
              onChange={(e) =>
                this.inputChangeHandler(e, fields.description.name)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Current Status</label>
              <p>
                <span style={{ color: statusData.color }}>
                  {statusData.value}
                </span>
              </p>
            </div>
          </div>
          {this.renderStatusInformation()}

        </div>
        <div className="col-12 d-flex justify-content-end gap-3 mt-3">
          {isDisabled ? this.props.writePermission && (
            <Button
              className="secondarybtn"
              onClick={this.enableEdit}
            >Edit</Button>
          ) : this.props.writePermission && (
            <Button
              className="secondarybtn"
              onClick={this.submitForm}
            >Save</Button>
          )}

          <Button onClick={this.handleClose} className="secondarybtn cancelbtn">CANCEL</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAsset: bindActionCreators(getAsset, dispatch),
  editAsset: bindActionCreators(editAsset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAsset)
);
