import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import '../../project-management/scss/task.scss'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import idx from 'idx'
import { updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { numberFormat } from "src/util/currency-formatter";
import { subTaskCalculation } from "src/util/calculations/calculate-sub-task";
import Material from "./material";
import Labor from "./labor";
import Subcontractor from "./subcontractor";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

export default function SubTask({ isSubTask, subTaskArray = [], profitMargin, profitMarginType, isDisabled, handleSaveTemplateDialog, ...props }) {

    const dispatch = useDispatch()
    const [expand, setexpand] = useState(isSubTask ? 0 : -1);

    const inputChangeHandler = (e, index = "") => {
        let description;
        let descriptionLength;
        if (e.target.value) {
            description = e?.target?.value.trim();
            descriptionLength = description.length;
        }

        let _temp = _.cloneDeep(subTaskArray)

        if (_temp[index]) {
            _temp[index].descriptionError = !description;
            _temp[index].descriptionLength =
                description && descriptionLength < 2;
        } else {
            _temp[index] = {
                descriptionError: !description,
                descriptionLength: description && descriptionLength < 2,
            };
        }

        if (e.target.type === "checkbox") {
            _temp[index]["is_actual_materialcost"] = e.target.checked;
        } else {
            _temp[index][e.target.name] = e.target.value;
        }
        dispatch(updateSubTaskAction(_temp))
    };

    const removeSubtask = (index) => {
        let _temp = _.cloneDeep(subTaskArray)
        _temp.splice(index, 1)
        dispatch(updateSubTaskAction(_temp))
    }

    return (
        <>
            {subTaskArray.map((subtask, subtaskIndex) => {
                let _total = subTaskCalculation({
                    subtask: subtask,
                    profitMargin: profitMargin,
                    profitMarginType: profitMarginType,
                    isForm: true
                })

                return (
                    <div key={subtaskIndex} className="create-table-block px-0">
                        <Collapse in={expand == subtaskIndex}>
                            <div className="col-lg-12 mb-3">
                                <div className="form-group px-3">
                                    <div className="input-block">
                                        <label className="input-label">Description</label>
                                        <textarea
                                            disabled={isDisabled}
                                            className="form-control"
                                            placeholder="Description"
                                            defaultValue={subtask.description}
                                            name='description'
                                            onChange={(e) => inputChangeHandler(e, subtaskIndex)}
                                        />
                                    </div>
                                    {subtask.descriptionError && !isDisabled && <span className="text-danger error-text text">
                                        Please fill Item description with atleast 2 characters
                                    </span>}
                                </div>
                            </div>

                        </Collapse>
                        <div className="table-responsive">
                            <Table bordered className="table-create tbl-fixed tbl-fixed-width">
                                <thead>
                                    <tr>
                                        {expand != subtaskIndex && <th></th>}
                                        <th></th>
                                        <th>Material</th>
                                        <th>Resource</th>
                                        <th>Contractor</th>
                                        <th>Total</th>
                                        <th className="save-icon" onClick={() => handleSaveTemplateDialog('subtask', subtask)}><SaveIcon /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {expand != subtaskIndex && <td>
                                            <span className="tableinfo text-left">
                                                {subtask.description}
                                            </span>
                                            {subtask.descriptionError && !isDisabled && <span className="text-danger error-text text">
                                                Please fill Item description with atleast 2 characters
                                            </span>}
                                        </td>}
                                        <td></td>
                                        <td>
                                            <span className="tableinfo">
                                                {numberFormat(idx(_total, _ => _.material.sale) || 0)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {numberFormat(idx(_total, _ => _.labor.sale) || 0)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {numberFormat(idx(_total, _ => _.subcontractor.sale) || 0)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {numberFormat(idx(_total, _ => _.total) || 0)}
                                            </span>
                                        </td>
                                        <td>
                                            {expand != subtaskIndex && <AddIcon
                                                onClick={() => setexpand(subtaskIndex)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expand == subtaskIndex}
                                            />}
                                            {expand == subtaskIndex && <RemoveIcon
                                                onClick={() => setexpand(-1)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expand == subtaskIndex}
                                            />}

                                            {!isDisabled && !subtask.id && <DeleteIcon className="project-delete-icon"
                                                onClick={() => removeSubtask(subtaskIndex)}
                                                sx={{ fill: '#f66 !important' }}
                                            />}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <Collapse in={expand == subtaskIndex}>
                            <div className="col-12">
                                <div className="px-3">
                                    <div className="material-list-block mb-3">
                                        <Material
                                            material={subtask.material || []}
                                            subTaskArray={subTaskArray}
                                            subTaskArrayIndex={subtaskIndex}
                                            profitMargin={profitMargin}
                                            profitMarginType={profitMarginType}
                                            total={_total}
                                            isDisabled={isDisabled}
                                        />
                                    </div>
                                    <div className="material-list-block mb-3">
                                        <Labor
                                            labor={subtask.labor || []}
                                            laborOptions={props.laborOptions}
                                            laborsList={props.laborOptions}
                                            subTaskArray={subTaskArray}
                                            subTaskArrayIndex={subtaskIndex}
                                            total={_total}
                                            isDisabled={isDisabled}
                                        />
                                    </div>
                                    <div className="material-list-block mb-3">
                                        <Subcontractor
                                            subcontractor={subtask.subcontractor || []}
                                            subcontractorOptions={props.subcontractors || []}
                                            profitMargin={profitMargin}
                                            profitMarginType={profitMarginType}
                                            subTaskArray={subTaskArray}
                                            subTaskArrayIndex={subtaskIndex}
                                            total={_total}
                                            isDisabled={isDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                )
            })}
        </>

    );

}
