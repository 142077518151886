import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'date-fns'
import { getUserAllocations } from './../../actions/project-management.action'
import { getUserList } from './../../actions/user-management.action'
import AllocateUser from './allocate-user'
import EditSchedule from './edit-schedule'
import ApplyLeave from './apply-leave'
import UserTimesheet from './user-timesheet'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import './scss/user-management.scss'
import { Grid } from '@mui/material';
import CustomTable from 'src/components/table/table'
import Popover from 'react-bootstrap/Popover';

class UserAllocationList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            columns: [],
            allocations: [],
            userData: '',
            menuItems: [{
                name: 'View Timesheet',
                isDisabled: false
            }, {
                name: 'Apply For Leave',
                isDisabled: true
            }, {
                name: 'Edit Schedule',
                isDisabled: true
            }, {
                name: 'Allocate',
                isDisabled: true
            }],
            allocateUserDialog: false,
            editScheduleDialog: false,
            applyLeaveDialog: false,
            userTimesheetDialog: false
        }
    }

    componentDidMount() {
        this.getAllocations()
    }

    getAllocations = async () => {
        const userData = await this.props.getUserList()
        const userIds = (Array.isArray(userData) && userData.map(user => user.id)) || []
        const allocations = await this.props.getUserAllocations(userIds)

        const data = userData.map(user => {
            let project = '', fromDate = '', toDate = '', projectId = '', allocationId = ''
            const allocationData = allocations.find(a => a.user === user.id)

            if (allocationData) {
                allocationId = allocationData.id
                projectId = allocationData.project && allocationData.project.id
                project = allocationData.project && allocationData.project.name
                fromDate = (allocationData.fromDate && format(new Date(allocationData.fromDate), 'EEEE dd/MM/yyyy')) || '-'
                toDate = (allocationData.toDate && format(new Date(allocationData.toDate), 'EEEE dd/MM/yyyy')) || '-'
            }

            return ({
                id: user.id,
                projectId: projectId,
                allocationId: allocationId,
                firstName: ((user.firstName && user.firstName != null) ? user.firstName : ''),
                lastName: ((user.lastName && user.lastName != null) ? user.lastName : ''),
                role: user.roles && user.roles.name,
                project: project || '-',
                fromDate: fromDate || '-',
                toDate: toDate || '-',
            })
        })

        const columns = [
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            { title: 'Role', field: 'role' },
            { title: 'Project', field: 'project' },
            { title: 'From Date', field: 'fromDate' },
            { title: 'To Date', field: 'toDate' },
        ]
        this.handleMenuClose()
        this.setState({ allocations: data, columns: columns, allocateUserDialog: false, editScheduleDialog: false, applyLeaveDialog: false, userTimesheetDialog: false })
    }

    handleMenu = (event, rowData) => {
        let menuItems = this.state.menuItems

        if (rowData.projectId) {
            menuItems[1].isDisabled = false
            menuItems[2].isDisabled = false
        } else {
            menuItems[3].isDisabled = false
        }

        this.setState({ anchorEl: event.currentTarget, userData: rowData, menuItems });
    }

    handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                this.setState({ userTimesheetDialog: true })
                break
            case 1:
                this.setState({ applyLeaveDialog: true })
                break
            case 2:
                this.setState({ editScheduleDialog: true })
                break
            case 3:
                this.setState({ allocateUserDialog: true })
                break
            default:
                break
        }
    }

    handleClose = () => {
        this.handleMenuClose()
        this.setState({ allocateUserDialog: false, editScheduleDialog: false, applyLeaveDialog: false, userTimesheetDialog: false })
    }

    handleMenuClose = () => {
        let menuItems = this.state.menuItems
        for (var i = 1; i < menuItems.length; i++) {
            menuItems[i].isDisabled = true
        }

        this.setState({ anchorEl: null, menuItems, userData: '' })
    }

    render() {
        const { columns, allocations, anchorEl, menuItems, allocateUserDialog, userData, editScheduleDialog, applyLeaveDialog, userTimesheetDialog } = this.state

        return (
            <>
                <UserTimesheet
                    open={userTimesheetDialog}
                    userData={userData}
                    getAllocations={this.getAllocations}
                    handleClose={this.handleClose}
                />
                <AllocateUser
                    open={allocateUserDialog}
                    userData={userData}
                    getAllocations={this.getAllocations}
                    handleClose={this.handleClose}
                />
                <EditSchedule
                    open={editScheduleDialog}
                    userData={userData}
                    getAllocations={this.getAllocations}
                    handleClose={this.handleClose}
                />
                <ApplyLeave
                    open={applyLeaveDialog}
                    userData={userData}
                    getAllocations={this.getAllocations}
                    handleClose={this.handleClose}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleMenuClose}
                >
                    {Array.isArray(menuItems) && menuItems.map((menuItem, index) =>
                        (<MenuItem disabled={menuItem.isDisabled} onClick={() => this.handleMenuItemClick(index)}>{(menuItem.name)}</MenuItem>)
                    )}
                </Menu>
                <div className='userManagment'>
                    <Grid container sx={{ m: 0, mb: 1 }}>
                        <Grid item xs={12} sx={{ p: 0 }} textAlign={'start'}>
                            {'User Allocations'}
                        </Grid>
                    </Grid>
                    <div className='tableContainer'>
                        <CustomTable
                            columns={columns}
                            data={allocations}
                            isAction={true}
                            menu={(data) => (
                                <Popover id="popover-basic">
                                    <Popover.Body>
                                        <ul className="action-block-list">
                                            <li onClick={() => {
                                                this.setState({ userTimesheetDialog: true, userData: data })
                                            }}>View Timesheet</li>
                                            {data.projectId && <li onClick={() => {
                                                this.setState({ applyLeaveDialog: true, userData: data })
                                            }}>Apply For Leave</li>}
                                            {data.projectId && <li onClick={() => {
                                                this.setState({ editScheduleDialog: true, userData: data })
                                            }}>Edit Schedule</li>}
                                            {!data.projectId && <li onClick={() => {
                                                this.setState({ allocateUserDialog: true, userData: data })
                                            }}>Allocate</li>}
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            )}
                            fileName='User Allocations List'
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user.userData
})

const mapDispatchToProps = dispatch => ({
    getUserAllocations: bindActionCreators(getUserAllocations, dispatch),
    getUserList: bindActionCreators(getUserList, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAllocationList)
