import React from "react";
import { Typography, Collapse } from "@mui/material";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { numberFormat, toPercent } from "../../util/currency-formatter";
import Icons from "../../components/icons/icons";
import deleteIcon from "../../assets/Dustbin.png";
import subtaskLine from "../../assets/subtaskLine.png";
import idx from "idx";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

function tasks({
  stages,
  options,
  stagesCost,
  taskCost,
  projectType,
  financePermission,
  updatePermission,
  quoteType,
  optionsTotal,
  toggleId,
  variations,
  handleTaskClick,
  deleteDialogHandler,
  optionDialogHandler,
  handleOptionMenu,
}) {

  const getTotalPercentComplete = (tasks = []) => {
    let percentageComplete = 0;
    for (const iterator of tasks) {
      percentageComplete += Number(iterator.percentageComplete) || 0
    }
    if (percentageComplete) percentageComplete = percentageComplete / (tasks.length || 1)
    return percentageComplete || 0
  }

  return (
    <>
      {Array.isArray(stages) && stages?.map((stage, index) => {
        return (
          <div key={index} className="table-body">
            <Droppable
              key={index}
              droppableId={`droppable${stage.id}`}
              type={`${index}`}
            >
              {(provided, snapshot) => (
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                  {stage?.tasks?.map((stageTask, i) => (
                    <Draggable key={stageTask?.id} draggableId={stageTask?.id} index={i}>
                      {(provided, snapshot) => (
                        <div className="sub-task" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <ul key={stageTask.id} className="droppable tabel-list">
                            <li className="first-cell">
                              <span {...provided.dragHandleProps}>
                                <Icons icon="dragHandle" />
                              </span>
                              <Typography
                                onClick={() => handleTaskClick(false, stage.id, stageTask.id, false, "", true)}
                                className="text-left"
                              >
                                {stageTask?.name}
                              </Typography>
                            </li>
                            <ProfitInPercentage
                              stageTask={stageTask}
                              stagesCost={stagesCost}
                              taskCost={taskCost}
                              projectType={projectType}
                              financePermission={financePermission}
                            />
                            <li>
                              <ModeEditOutlineOutlinedIcon
                                onClick={() => handleTaskClick(false, stage.id, stageTask.id, false, "", true)}
                                style={{ marginRight: "15px" }}
                              />
                              {updatePermission && ((quoteType == "quote" && projectType == "TENDER") || quoteType == "noquote") && (
                                <DeleteOutlineOutlinedIcon
                                  onClick={() => deleteDialogHandler(stageTask.id, "Task")}
                                />
                              )}
                            </li>
                          </ul>

                          <ul className="collapsed-content tabel-lists">
                            <li
                              colSpan={7}
                              className={("toggle" + stageTask.id === toggleId ? true : false) ? "collapsed" : "uncollapsed"}
                            >
                              <Collapse
                                in={"toggle" + stageTask.id === toggleId ? true : false}
                                timeout="auto"
                              >
                                <Profit
                                  deleteDialogHandler={deleteDialogHandler}
                                  quoteType={quoteType}
                                  updatePermission={updatePermission}
                                  projectType={projectType}
                                  type={"Subtask"}
                                  stageTask={stageTask}
                                />
                              </Collapse>
                            </li>
                          </ul>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {financePermission && (
                    <ul className="stage-total-row tabel-list">
                      <li className="first-cell">
                        <Typography className="stageTotal" variant="body1">
                          {stage.name !== "DummyStage" ? "Stage Total:" : "Project Total:"}
                        </Typography>
                      </li>
                      <li className="text-center">
                        <Typography style={{ fontSize: "12px" }} fontStyle="italic" variant="subtitle1" color="textSecondary">
                          Profit
                        </Typography>
                      </li>
                      {projectType != "TENDER" && (
                        <li style={{ color: '#014671' }}>{getTotalPercentComplete(stage?.tasks).toFixed(1)}%</li>
                      )}
                      {projectType === "TENDER" && (<>
                        <li className="text-right">
                          <Typography variant="body1">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].material.sale) || 0)}
                          </Typography>
                          <Typography style={{ fontSize: "12px" }} variant="subtitle1" color="textSecondary">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].material.profit) || 0)}{' '}
                            ({toPercent(idx(stagesCost, (_) => _[stage.id].material.profit), idx(stagesCost, (_) => _[stage.id].material.cost))})
                          </Typography>
                        </li>
                        <li className="text-right">
                          <Typography variant="body1">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].labor.sale) || 0)}
                          </Typography>
                          <Typography style={{ fontSize: "12px" }} variant="subtitle1" color="textSecondary">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].labor.profit || 0))}{' '}
                            ({toPercent(idx(stagesCost, (_) => _[stage.id].labor.profit), idx(stagesCost, (_) => _[stage.id].labor.cost))})
                          </Typography>
                        </li>
                        <li className="text-right">
                          <Typography variant="body1">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].subcontractor.sale) || 0)}
                          </Typography>
                          <Typography style={{ fontSize: "12px" }} variant="subtitle1" color="textSecondary">
                            {numberFormat(idx(stagesCost, (_) => _[stage.id].subcontractor.profit) || 0)}{' '}
                            ({toPercent(idx(stagesCost, (_) => _[stage.id].subcontractor.profit), idx(stagesCost, (_) => _[stage.id].subcontractor.cost))})
                          </Typography>
                        </li>
                      </>)}
                      <li className="text-right">
                        <Typography variant="body1">
                          {numberFormat(idx(stagesCost, (_) => _[stage.id].total) || 0)}
                        </Typography>
                        <Typography style={{ fontSize: "12px" }} variant="subtitle1" color="textSecondary">
                          {numberFormat(idx(stagesCost, (_) => _[stage.id].total_profit) || 0)}{' '}
                          ({toPercent(idx(stagesCost, (_) => _[stage.id].total_profit), idx(stagesCost, (_) => _[stage.id].subtotal))})
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1"></Typography>
                      </li>
                    </ul>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}

      {projectType !== "TENDER" && Array.isArray(variations) && variations.map((variation, i) => {
        return (
          <div key={i} className="table-body">
            <Droppable
              key={i}
              droppableId={`droppable${variation.id}`}
              type={`${i}`}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Draggable
                    key={variation.id}
                    draggableId={variation.id}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="sub-task"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <ul
                          key={variation.id}
                          className="droppable tabel-list"
                        >
                          <li className="first-cell">
                            <span {...provided.dragHandleProps}>
                              <Icons icon="dragHandle" />
                            </span>
                            <Typography
                              onClick={() => handleTaskClick(true, "", "", "", "", "", "", variation.id, true, true)}
                              className="text-left"
                            >
                              {`${variation.variationNo} ${variation.name}`}
                            </Typography>
                          </li>
                          <ProfitInPercentage
                            stageTask={variation}
                            taskCost={optionsTotal.option || {}}
                            projectType={projectType}
                            financePermission={financePermission}
                          />

                          <li>
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => handleTaskClick(true, "", "", "", "", "", "", variation.id, true, true)}
                            />
                          </li>
                        </ul>

                        <ul className="collapsed-content tabel-lists">
                          <li
                            colSpan={7}
                            className={("toggle" + variation.id === toggleId ? true : false) ? "collapsed" : "uncollapsed"}
                          >
                            <Collapse
                              in={"toggle" + variation.id === toggleId ? true : false}
                              timeout="auto"
                            >
                              <Profit
                                deleteDialogHandler={deleteDialogHandler}
                                stageTask={variation}
                                type={"VariationSubtask"}
                                quoteType={quoteType}
                                updatePermission={updatePermission}
                                projectType={projectType}
                              />
                            </Collapse>
                          </li>
                        </ul>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}

      {(Array.isArray(variations) && variations.length && (
        <ul className="stage-total-row tabel-list">
          <li className="first-cell">
            <Typography className="stageTotal" variant="body1">
              Variation Total:
            </Typography>
          </li>
          <li className="text-center" style={{ alignSelf: "end", fontSize: "12px" }}>
            <Typography
              style={{ fontSize: "12px" }}
              fontStyle="italic"
              variant="subtitle1"
              color="textSecondary"
              className="text-center"
            >
              Profit
            </Typography>
          </li>
          <li style={{ color: '#014671' }}>{getTotalPercentComplete(variations).toFixed(1)}%</li>
          {financePermission && projectType === "TENDER" && (
            <li>
              <Typography variant="body1" className="option-top-text text-right">
                {numberFormat(idx(optionsTotal, _ => _.material.sale) || 0)}
              </Typography>
              <Typography
                style={{ fontSize: "12px" }}
                variant="subtitle1"
                color="textSecondary"
                className="text-right"
              >
                {numberFormat(idx(optionsTotal, _ => _.material.profit) || 0)}{' '}
                ({toPercent(idx(optionsTotal, _ => _.material.profit), idx(optionsTotal, _ => _.material.cost))})
              </Typography>
            </li>
          )}
          {financePermission && projectType === "TENDER" && (
            <li>
              <Typography variant="body1" className="option-top-text text-right">
                {numberFormat(idx(optionsTotal, _ => _.labor.sale) || 0)}
              </Typography>
              <Typography
                style={{ fontSize: "12px" }}
                variant="subtitle1"
                color="textSecondary"
                className="text-right"
              >
                {numberFormat(idx(optionsTotal, _ => _.labor.profit) || 0)}{' '}
                ({toPercent(idx(optionsTotal, _ => _.labor.profit), idx(optionsTotal, _ => _.labor.cost))})
              </Typography>
            </li>
          )}
          {financePermission && projectType === "TENDER" && (
            <li>
              <Typography variant="body1" className="option-top-text text-right">
                {numberFormat(idx(optionsTotal, _ => _.subcontractor.sale) || 0)}
              </Typography>
              <Typography
                style={{ fontSize: "12px" }}
                variant="subtitle1"
                color="textSecondary"
                className="text-right"
              >
                {numberFormat(idx(optionsTotal, _ => _.subcontractor.profit) || 0)}{' '}
                ({toPercent(idx(optionsTotal, _ => _.subcontractor.profit), idx(optionsTotal, _ => _.subcontractor.cost))})
              </Typography>
            </li>
          )}
          <li style={{ alignSelf: "end", fontSize: "12px" }}>
            {financePermission && (
              <Typography variant="body1" className="option-top-text text-right">
                {numberFormat(idx(optionsTotal, _ => _.total) || 0)}
              </Typography>
            )}
            <Typography
              style={{ fontSize: "12px" }}
              variant="subtitle1"
              color="textSecondary"
              className="text-right"
            >
              {numberFormat(idx(optionsTotal, _ => _.total_profit) || 0)}{' '}
              ({toPercent(idx(optionsTotal, _ => _.total_profit), idx(optionsTotal, _ => _.subtotal))})
            </Typography>
          </li>
          <li>
            <Typography variant="body1"></Typography>
          </li>
        </ul>
      )) || ""}

      {(options && options.map((option, optionIndex) => {
        return (
          <>
            <ul className="collapsed-content tabel-lists" key={option.id}>
              <li colSpan={7}>
                <ul className="tabel-list">
                  <li className="first-cell option_first_cell">
                    <Typography
                      onClick={() => optionDialogHandler(option.id, true, true)}
                    >
                      <span>{option.name}</span>
                      {option.revisionNumber !== 1 ? " - " + option.revisionName : ""}
                    </Typography>
                  </li>
                  <ProfitInPercentage
                    stageTask={option}
                    taskCost={optionsTotal.option || {}}
                    projectType={projectType}
                    financePermission={financePermission}
                  />

                  <li>
                    {quoteType !== "TENDER" && (
                      <span
                        onClick={(e) => handleOptionMenu(e, "OPTION", "", "", "", option.id)}
                        style={{ marginRight: "15px" }}
                        className="menuIcon"
                      >
                        <MoreHorizOutlinedIcon />
                      </span>
                    )}

                    {updatePermission &&
                      ((quoteType == "quote" && projectType == "TENDER") || quoteType == "noquote") && (
                        <DeleteOutlineOutlinedIcon
                          onClick={() => deleteDialogHandler(option.id, "OptionTask")}
                        />
                      )}
                  </li>
                </ul>
              </li>
            </ul>
            <ul
              className={
                ("toggle" + option.id == toggleId ? true : false)
                  ? "collapsed-content tabel-lists collapsedlist"
                  : "collapsed-content tabel-lists uncollapsed-list"
              }
            >
              <li
                className={("toggle" + option.id == toggleId ? true : false) ? "collapsed" : "uncollapsed"}
              >
                <Collapse
                  in={"toggle" + option.id == toggleId ? true : false}
                  timeout="auto"
                >
                  <Profit
                    deleteDialogHandler={deleteDialogHandler}
                    stageTask={option}
                    type={"OptionSubtask"}
                    quoteType={quoteType}
                    updatePermission={updatePermission}
                    projectType={projectType}
                  />
                </Collapse>
              </li>
            </ul>
          </>
        );
      })) || ""}

      {(Array.isArray(options) && options.length && (
        <ul className="stage-total-row tabel-list">
          <li className="first-cell">
            <Typography className="stageTotal" variant="body1">
              Options Total:
            </Typography>
          </li>
          <li className="text-center">
            <Typography
              style={{ fontSize: "12px" }}
              fontStyle="italic"
              variant="subtitle1"
              color="textSecondary"
            >
              Profit
            </Typography>
          </li>
          <li className="text-right">
            <Typography variant="body1" className="option-top-text">
              {numberFormat(idx(optionsTotal, _ => _.material.sale) || 0)}
            </Typography>
            <Typography
              style={{ fontSize: "12px" }}
              variant="subtitle1"
              color="textSecondary"
            >
              {numberFormat(idx(optionsTotal, _ => _.material.profit) || 0)}{' '}
              ({toPercent(idx(optionsTotal, _ => _.material.profit), idx(optionsTotal, _ => _.material.cost))})
            </Typography>
          </li>
          {/* Add the necessary columns */}
          <li className="text-right">
            <Typography variant="body1" className="option-top-text">
              {numberFormat(idx(optionsTotal, _ => _.labor.sale) || 0)}
            </Typography>
            <Typography
              style={{ fontSize: "12px" }}
              variant="subtitle1"
              color="textSecondary"
            >
              {numberFormat(idx(optionsTotal, _ => _.labor.profit) || 0)}{' '}
              ({toPercent(idx(optionsTotal, _ => _.labor.profit), idx(optionsTotal, _ => _.labor.cost))})
            </Typography>
          </li>
          <li className="text-right">
            <Typography variant="body1" className="option-top-text">
              {numberFormat(idx(optionsTotal, _ => _.subcontractor.sale) || 0)}
            </Typography>
            <Typography
              style={{ fontSize: "12px" }}
              variant="subtitle1"
              color="textSecondary"
            >
              {numberFormat(idx(optionsTotal, _ => _.subcontractor.profit) || 0)}{' '}
              ({toPercent(idx(optionsTotal, _ => _.subcontractor.profit), idx(optionsTotal, _ => _.subcontractor.cost))})
            </Typography>
          </li>
          <li className="text-right">
            <Typography variant="body1" className="option-top-text">
              {numberFormat(idx(optionsTotal, _ => _.total) || 0)}
            </Typography>
            <Typography
              style={{ fontSize: "12px" }}
              variant="subtitle1"
              color="textSecondary"
            >
              {numberFormat(idx(optionsTotal, _ => _.total_profit) || 0)}{' '}
              ({toPercent(idx(optionsTotal, _ => _.total_profit), idx(optionsTotal, _ => _.subtotal))})
            </Typography>
          </li>
          <li>
            <Typography variant="body1"></Typography>
          </li>
        </ul>
      )) || ""}
    </>
  );
}

export default tasks;

function ProfitInPercentage({
  projectType,
  financePermission,
  stageTask,
  taskCost,
}) {

  return (
    <>
      <li className="text-center">
        <Typography style={{ fontSize: "12px" }} fontStyle="italic" variant="subtitle1" color="textSecondary">
          Profit
        </Typography>
      </li>
      {projectType != "TENDER" && (
        <li>{(parseFloat(stageTask.percentageComplete) || 0).toFixed(1)}%</li>
      )}
      {financePermission && projectType === "TENDER" && (<>
        <li className="text-right">
          {`${numberFormat(
            idx(taskCost, (_) => _[stageTask.id].material.sale) || 0
          )}`}
          <Typography
            style={{ fontSize: "12px" }}
            variant="subtitle1"
            color="textSecondary"
          >
            {numberFormat(idx(taskCost, (_) => _[stageTask.id].material.profit) || 0)}{' '}
            ({toPercent(idx(taskCost, (_) => _[stageTask.id].material.profit), idx(taskCost, (_) => _[stageTask.id].material.cost))})
          </Typography>
        </li>
        <li className="text-right">
          {numberFormat(idx(taskCost, (_) => _[stageTask.id].labor.sale) || 0)}
          <Typography
            style={{ fontSize: "12px" }}
            variant="subtitle1"
            color="textSecondary"
          >
            {numberFormat(idx(taskCost, (_) => _[stageTask.id].labor.profit) || 0)}{' '}
            ({toPercent(idx(taskCost, (_) => _[stageTask.id].labor.profit), idx(taskCost, (_) => _[stageTask.id].labor.cost))})
          </Typography>
        </li>
        <li className="text-right">
          {`${numberFormat(
            idx(taskCost, (_) => _[stageTask.id].subcontractor.sale || 0)
          )}`}
          <Typography
            style={{ fontSize: "12px" }}
            variant="subtitle1"
            color="textSecondary"
          >
            {numberFormat(idx(taskCost, (_) => _[stageTask.id].subcontractor.profit) || 0)}{' '}
            ({toPercent(idx(taskCost, (_) => _[stageTask.id].subcontractor.profit), idx(taskCost, (_) => _[stageTask.id].subcontractor.cost))})
          </Typography>
        </li>
      </>)}
      <li className="text-right">
        {numberFormat(idx(taskCost, (_) => _[stageTask.id].total || 0))}
        <Typography
          style={{ fontSize: "12px" }}
          variant="subtitle1"
          color="textSecondary"
        >
          {numberFormat(idx(taskCost, (_) => _[stageTask.id].total_profit) || 0)}{' '}
          ({toPercent(idx(taskCost, (_) => _[stageTask.id].total_profit), idx(taskCost, (_) => _[stageTask.id].subtotal))})
        </Typography>
      </li>
    </>
  );
}

function Profit(
  stageTask,
  financePermission,
  projectType,
  updatePermission,
  quoteType,
  type,
  deleteDialogHandler
) {
  return (
    <>
      {stageTask?.subTasks?.map((subTask, subtaskindex) => {
        const descriptionText = subTask.description.slice(0, 20) + (subTask.description.length > 20 ? "..." : "");
        return (
          <ul key={subtaskindex} className="tabel-list">
            <li className="sub-content-first-cell">
              <img src={subtaskLine} alt="subtask" /> {descriptionText}
            </li>
            {financePermission && projectType === "TENDER" && (
              <li>
                ${parseFloat(idx(subTask, (_) => _.totalProfit) || 0).toFixed(1) || 0}
              </li>
            )}
            {projectType != "TENDER" && (
              <li>
                {parseFloat(idx(subTask, (_) => _.percentageComplete) || 0).toFixed(1)}%
              </li>
            )}
            {financePermission && projectType === "TENDER" && (<>
              <li>
                {numberFormat(
                  idx(subTask, (_) => _.materialTotal_SaleAmount) || 0
                )}
              </li>
              <li>
                {numberFormat(
                  idx(subTask, (_) => _.laborTotal_SaleAmount) || 0
                )}
              </li>
              <li>
                {numberFormat(
                  idx(subTask, (_) => _.subcontractorTotal_SaleAmount) || 0
                )}
              </li>
            </>)}
            <li>
              {numberFormat(
                parseFloat(
                  idx(subTask, (_) => _.materialTotal_SaleAmount) || 0
                ) +
                parseFloat(
                  idx(subTask, (_) => _.laborTotal_SaleAmount) || 0
                ) +
                parseFloat(
                  idx(subTask, (_) => _.subcontractorTotal_SaleAmount) || 0
                )
              )}
            </li>
            <li>
              {updatePermission && ((quoteType == "quote" && projectType == "TENDER") || quoteType == "noquote") && (
                <img
                  src={deleteIcon}
                  alt="edit"
                  onClick={() => deleteDialogHandler(subTask.id, type)}
                />
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
}
