import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import CancelIcon from '@material-ui/icons/Cancel'
import Edit from '@material-ui/icons/Edit'
import FilterListIcon from '@material-ui/icons/FilterList'
import Delete from '@material-ui/icons/Delete'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
// import PanToolIcon from '@mui/icons-material/PanTool'
import OpenWithIcon from '@mui/icons-material/OpenWith';

import './icons.scss'

export default function Icons(props) {
  let element
  switch (props.icon) {
    case 'add':
      element = <AddIcon />
      break
    case 'remove':
      element = <Remove />
      break
    case 'cancel':
      element = <CancelIcon onClick={props.onClick} />
      break
    case 'edit':
      element = <Edit />
      break
    case 'trash':
      element = <Delete />
      break
    case 'filter':
      element = <FilterListIcon />
      break
    case 'download':
      element = <GetAppRoundedIcon fontSize="small" />
      break
    case 'dragHandle':
      element = < OpenWithIcon className="pan-tool" />
      break
    default:
  }
  return (
    <div className='icon'>
      {element}
    </div>
  )
}
