import axios, { microServices } from './axios'
import { loaders } from './../constants'

// // ********************API url *******************
const SUBSCRIPTION_BASE = '/subscription'

// // ************API call Functions starts here **************

export const addSubscriptionAPI = async (data) => {
  return await axios('post', SUBSCRIPTION_BASE, data, { loader: loaders.OVERLAY, successMessage: 'Subscription Created Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const listSubscriptionAPI = async (params) => {
  return await axios('get', SUBSCRIPTION_BASE, {}, { loader: loaders.OVERLAY, server: microServices.DE, params }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const getSubscriptionAPI = async (id) => {
  return await axios('get', `${SUBSCRIPTION_BASE}/${id}`, {}, { loader: loaders.OVERLAY, server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const updateSubscriptionAPI = async (data, id) => {
  return await axios('put', `${SUBSCRIPTION_BASE}/${id}`, data, { loader: loaders.OVERLAY, successMessage: 'Subscription Updated Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}

export const deleteSubscriptionAPI = async (id) => {
  return await axios('delete', `${SUBSCRIPTION_BASE}/${id}`, {}, { successMessage: 'Subscription Deleted Successfully', server: microServices.DE }).then(data => {
    return data
  },
    error => { // request level error
      return new Promise((resolve, reject) => {
        reject(error)
      })
    })
}
