import React from "react";
import { PURCHASE_ORDER, SUPPLIERS } from "./../../../constants/route-paths";
import { withRouter } from "../../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ViewPurchaseOrderComponent from 'src/components/po/view'
import { Button } from "react-bootstrap";

function ViewPurchaseOrder(props) {

  const cancelHandler = () => {
    props.history(`/${SUPPLIERS}/${PURCHASE_ORDER}`);
  };

  return (
    <div className="purchase-order-block">
      <h2 className="content-heading text-uppercase">View Purchase Order</h2>
      <div className="col-12 breadcrumb-block p-0">
        <Breadcrumb>
          <Breadcrumb.Item onClick={cancelHandler}>Purchase Order</Breadcrumb.Item>
          <Breadcrumb.Item active>View Purchase Order</Breadcrumb.Item>
        </Breadcrumb>

      </div>

      <ViewPurchaseOrderComponent
        poId={props.params.poId}
      />

      <div className="col-12 d-flex mt-3 justify-content-end">
        <Button type="submit" onClick={cancelHandler} className="secondarybtn cancelbtn">CANCEL</Button>
      </div>
    </div>
  );
}

export default withRouter(ViewPurchaseOrder);
