import React from 'react'
import AddMaterial from '../catalogs/material/add-material'
import { Modal } from 'react-bootstrap'

export default function AddItemDialog(props) {
  const { dialogClose, open, writePermission } = props
  return (
    <Modal
      show={open}
      onHide={dialogClose}
      dialogClassName="modal-70w create-modal"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Add Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddMaterial {...props} writePermission={writePermission} />
      </Modal.Body>
    </Modal>
  )
}
