import React, { useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import List from '@material-ui/core/List'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TopBar from '../topbar'
import { useNavigate } from 'react-router-dom';
import { Typography } from '@material-ui/core'
import * as paths from 'src/constants/route-paths'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import { saveProjectType, removeProjectType } from 'src/actions/project-management.action'
import { showMobileDrawer as showMobileDrawerAPI } from 'src/actions/common.action'
import { getGlobalSettingReducer } from 'src/actions/global-setting.action'
import { logout } from 'src/actions/user-management.action'
import Cookies from 'js-cookie'
import idx from 'idx'
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { navLinks } from './links'
import { lgStyles, mdStyles } from './styles'
import { setCookie } from '../../util/auth'

function HomeLayout(props) {

    const lgStyle = lgStyles();
    const mdStyle = mdStyles();
    const [classes, setClasses] = useState(window?.innerWidth > 1024 ? lgStyle : mdStyle)
    const navigate = useNavigate();
    const [variant, setVariant] = useState(window.innerWidth > 1024 ? 'permanent' : 'persistent')
    const [anchorEl, setAnchorEl] = React.useState(null)
    const isMenuOpen = Boolean(anchorEl)
    const { name, role } = props.user
    const { user } = props


    const setWindowDimensions = () => {
        setClasses(window.innerWidth > 1024 ? lgStyle : mdStyle)
        setVariant(window.innerWidth > 1024 ? 'permanent' : 'persistent')
    }
    useEffect(() => {
        setWindowDimensions();
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const logout = () => {
        handleMenuClose()
        props.logout()
        props.showMobileDrawerAPI(false)
        navigate('/login')
    }

    const editProfile = () => {
        handleMenuClose()
        props.showMobileDrawerAPI(false)
        navigate('/profile')
    }

    const resetPassword = () => {
        handleMenuClose()
        props.showMobileDrawerAPI(false)
        navigate('/profile/reset-password')
    }

    const changeAccount = () => {
        handleMenuClose()
        navigate(`/${paths.GLOBAL_ACCOUNT}`)
    }

    const permissions = props.user.permissions
    const projectType = props.projectType

    if (props.user.selected_account.name) {
        let takingFirstLetters = (props.user.selected_account.name).match(/\b(\w)/g);
        let joiningFirstLetters = takingFirstLetters.join('');
        document.title = joiningFirstLetters + ' | Contractable'
    }

    const sidebarLinks = navLinks({ projectCategories: props.projectCategories, entitlements: props.entitlements, user, permissions })

    const changeUrl = async path => {
        const queryString = path.split('?')[1]
        const queryParams = new URLSearchParams(queryString)
        if (queryParams.has('project-type')) {
            await props.saveProjectType(queryParams.get('project-type'))
            setCookie({ name: 'projectType', value: queryParams.get('project-type') })
        } else {
            props.removeProjectType('')
            Cookies.remove('projectType')
        }
        props.showMobileDrawerAPI(false)
        navigate(path)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem key={'editProfile'} onClick={editProfile}>Profile</MenuItem>
            <MenuItem key={'resetPassword'} onClick={resetPassword}>Reset Password</MenuItem>
            <MenuItem key={'logout'} onClick={logout}>Logout</MenuItem>
        </Menu>
    )

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position='fixed' className={classes.appBar + ' Homelayout '}>
                <TopBar {...props} openHandler={() => { props.showMobileDrawerAPI(!props.showMobileDrawer) }} />
            </AppBar>
            <Drawer
                className={classes.drawer + ' mainDrawer sidebar-light'}
                variant={variant}
                open={props.showMobileDrawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                anchor='left'
            >
                {renderMenu}
                <div className='sideBarCloseBtn'>
                    <IconButton color='inherit' onClick={() => { props.showMobileDrawerAPI(!props.showMobileDrawer) }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className='sidebarLogo'>
                    <img src={require('../../assets/logo.svg').default} alt='Logo' />
                    <div className={`${classes.sectionDesktop} avatarLogoContainer mobileUser`}>
                        <IconButton
                            edge='end'
                            aria-label='account of current user'
                            aria-controls={menuId}
                            aria-haspopup='true'
                            onClick={handleProfileMenuOpen}
                            color='inherit'
                        >
                            <Avatar alt={name} src='/static/images/avatar/1.jpg' />
                        </IconButton>
                        <div className='personDetail'>
                            <p className='name'>{name}</p>
                            <p className='designation'>{role}</p>
                            {user.global_user && idx(user, _ => _.selected_account.name) && <p className='designation'>{idx(user, _ => _.selected_account.name)}</p>}
                        </div>
                    </div>
                </div>
                <List id="sidebar-menu-list">
                    {sidebarLinks.map((listItem, index) => {
                        if (listItem.permission > 0) {
                            return (
                                listItem.subListItems ?
                                    <Accordion key={listItem.name} className='menuList'>
                                        <AccordionSummary
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            className='w-100 p-0'
                                            expandIcon={<ChevronRightIcon />}
                                            onClick={() => {
                                                if (listItem.path) changeUrl(listItem.path)
                                            }}
                                            disabled={listItem.disabled}
                                            sx={{ height: 'auto' }}
                                        >
                                            <ListItemIcon className='ps-2'>{listItem.icon}</ListItemIcon>
                                            <Typography className={'ps-2'}>{listItem.name}</Typography>
                                        </AccordionSummary>
                                        {listItem.subListItems ?
                                            <AccordionDetails sx={{ p: 0, pl: 1 }}>
                                                <List style={{ padding: '0' }}>
                                                    {listItem.subListItems.map((subListItem, index) => {
                                                        if (subListItem.permission > 0) {
                                                            return (
                                                                <ListItem
                                                                    key={index}
                                                                    disabled={subListItem.disabled}
                                                                    className={(`${window.location.pathname}${window.location.search || ''}`.includes(subListItem.path) && subListItem.path !== '/') || (projectType === subListItem.projectType) ? 'activeList' : ''}
                                                                    onClick={() => changeUrl(subListItem.path, subListItem.notNestedRoute)}>
                                                                    <ListItemIcon>{subListItem.icon}</ListItemIcon>
                                                                    <ListItemText primary={subListItem.name} />
                                                                </ListItem>
                                                            )
                                                        }
                                                    })}
                                                </List>
                                            </AccordionDetails>
                                            : true}
                                    </Accordion>
                                    :
                                    <ListItem disabled={listItem.disabled} onClick={() => changeUrl(listItem.path)} button key={listItem.name} className={window.location.pathname.includes(listItem.path) && listItem.path !== '/' ? 'activeList' : ''}>
                                        <ListItemIcon>{listItem.icon}</ListItemIcon>
                                        <ListItemText primary={listItem.name} />
                                    </ListItem>
                            )
                        }
                    })}
                    <Accordion className='menuList'>
                        <AccordionSummary
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            expandIcon={<ChevronRightIcon />}
                            sx={{ height: 'auto' }}
                        >
                            <ListItemIcon style={{ alignItems: 'center' }}>
                                <Avatar alt={name} src='/static/images/avatar/1.jpg' />
                            </ListItemIcon>
                            <ListItemText style={{ paddingLeft: '8px', textAlign: 'start' }}>
                                <div>{name}</div>
                                <div>{role}</div>
                                {idx(user, _ => _.selected_account.name) && <div>{idx(user, _ => _.selected_account.name)} {user.global_user && <span className="link" onClick={changeAccount}>Change</span>} </div>}
                            </ListItemText>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, pl: 1 }}>
                            <List style={{ padding: '0' }}>
                                <ListItem
                                    className={!(`${window.location.pathname}${window.location.search || ''}`.includes('/profile/reset-password')) && (`${window.location.pathname}${window.location.search || ''}`.includes('/profile')) ? 'activeList' : ''}
                                    onClick={editProfile}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText primary={'Profile'} />
                                </ListItem>
                                <ListItem
                                    className={(`${window.location.pathname}${window.location.search || ''}`.includes('/profile/reset-password')) ? 'activeList' : ''}
                                    onClick={resetPassword}>
                                    <ListItemIcon><EditIcon /></ListItemIcon>
                                    <ListItemText primary={'Reset Password'} />
                                </ListItem>
                                <ListItem
                                    onClick={logout}>
                                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                                    <ListItemText primary={'Logout'} />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </List>
            </Drawer>
            <main id='layoutAlign' className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user.userData,
    entitlements: state.user.entitlements,
    projectType: state.project.projectType,
    showMobileDrawer: state.common.showMobileDrawer,
    projectCategories: state.project.projectCategories
})

const mapDispatchToProps = dispatch => ({
    saveProjectType: bindActionCreators(saveProjectType, dispatch),
    removeProjectType: bindActionCreators(removeProjectType, dispatch),
    showMobileDrawerAPI: bindActionCreators(showMobileDrawerAPI, dispatch),
    logout: bindActionCreators(logout, dispatch),
    getGlobalSettingReducer: bindActionCreators(getGlobalSettingReducer, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout)
