import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CATALOGS,
  RESOURCE_LEVEL,
  RESOURCES,
  ADD_RESOURCE,
  ADD_RESOURCE_LEVEL,
} from "./../../../constants/route-paths";
import {
  deleteLabor,
  getLaborList,
  editLabor,
  getLevels,
  updateLevel,
} from "./../../../actions/inventory.action";
import DeleteDialog from "./../../../components/delete-dialog";
import { Link } from "react-router-dom";
import { withRouter } from "../../../util/with-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomTable from "src/components/table/table";
import { addResourcesAction } from "src/slice/resources";
import { toUSD } from "src/util/currency-formatter";

function LaborList({ writePermission, resources = [], ...props }) {
  const [state, setState] = useState({
    open: false,
    levels: [],
    deleteDialogState: false,
    openAddLevelDialog: false,
    level_column: [],
    resource_column: []
  })
  const [activeResourceTab, setActiveResourceTab] = useState(sessionStorage.getItem('resourceActiveTab') || 'resource');

  useEffect(() => {
    if (!resources.length) getLaborList();
    getLevels();
    getColumns();
  }, [])


  const getColumns = () => {
    setState(prev => ({
      ...prev,
      resource_column: [
        { title: "S No", field: "sno" },
        {
          title: "Skill",
          field: "skill",
          render: (rowData) => (
            <Link to={`/${CATALOGS}/${RESOURCES}/edit/${rowData.id}`}>
              {rowData.skill}
            </Link >
          ),
        },
        { title: "Average Cost / Hour", field: "cost", render: (rowData) => toUSD(rowData.cost), class: 'text-right' },
        {
          title: "Status",
          field: "status",
          filtering: false,
          class: 'text-center',
          render: (rowData) => (
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                className="form-check-input switch-checkbox"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault2"
                disabled={writePermission ? false : true}
                checked={rowData.status === "ACTIVE" ? true : false}
                onChange={(e) => {
                  props.editLabor({ status: e.target.checked ? "ACTIVE" : "INACTIVE" }, rowData.id).then((data) => {
                    getLaborList();
                  }).catch(e => console.error(e));
                }}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault2"></label>
            </div>
          ),
        },
      ],
      level_column: [
        { title: "S No", field: "sno" },
        {
          title: "Name",
          field: "name",
          render: (rowData) => (
            <Link to={`/${CATALOGS}/${RESOURCE_LEVEL}/edit/${rowData.id}`}>
              {rowData.name}
            </Link>
          ),
        },
        { title: "Description", field: "description" },
        // {
        //   title: "Call out Cost",
        //   field: "calloutCost",
        //   render: (rowData) => numberFormat(rowData.calloutCost),
        // },
        // {
        //   title: "Travel Cost",
        //   field: "travelCost",
        //   render: (rowData) => numberFormat(rowData.travelCost),
        // },
        {
          title: "Status",
          field: "status",
          filtering: false,
          render: (rowData) => (
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-checkbox"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                disabled={writePermission ? false : true}
                checked={rowData.status}
                onChange={(e) => {
                  props.updateLevel({ status: e.target.checked }, rowData.id).then((data) => {
                    getLevels();
                  });
                }}
              />
              < label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
            </div>
          ),
        },
      ]
    }));
  };

  const getLevels = () => {
    const levels = [];
    props.getLevels().then((data) => {
      data.forEach((level, i) => {
        level.sno = i + 1;
        levels.push(level);
      });
      setState(prev => ({
        ...prev,
        levels
      }));
    });
  };

  const getLaborList = () => {
    props.getLaborList().then((data) => {
      let labors = (data || []).map((labor, i) => {
        labor.sno = i + 1;
        return labor;
      })
      props.addResourcesAction(labors);
    }).catch(e => console.error(e))
  };

  const deleteDialogHandler = (id = "") => {
    setState(prev => ({
      ...prev,
      deleteDialogState: !prev.deleteDialogState,
      deleteId: id,
    }));
  };

  const deleteHandler = (id) => {
    props.deleteLabor(id).then((res) => {
      setState(prev => ({
        ...prev,
        deleteDialogState: false,
      }));
      getLaborList();
    });
  };

  const addLabor = () => {
    props.history(`/${CATALOGS}/${ADD_RESOURCE}`);
  };

  const editLabor = (id) => {
    props.history(`/${CATALOGS}/${RESOURCES}/edit/${id}`);
  };

  const addLevel = () => {
    props.history(`/${CATALOGS}/${ADD_RESOURCE_LEVEL}`);
  };

  const editLevel = (id) => {
    props.history(`/${CATALOGS}/${RESOURCE_LEVEL}/edit/${id}`);
  };

  return (
    <>
      <DeleteDialog
        open={state.deleteDialogState}
        id={state.deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName="Item"
      />

      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Resources</h2>
          </div>
          {writePermission && (<div className="w-auto ms-auto mt-auto">
            <button onClick={addLabor} className="secondarybtn secondarybtn-outline btn-ch btn btn-primary me-2">Add Resource</button>
            <button onClick={addLevel} className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary">Add Cost/Sales Level</button>
          </div>)}
        </div>

        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey={activeResourceTab}
              activeKey={activeResourceTab}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={k => { sessionStorage.setItem('resourceActiveTab', k); setActiveResourceTab(k) }}
            >
              <Tab eventKey="resource" title={`Resources (${resources.length})`}>
                <CustomTable
                  columns={state.resource_column}
                  data={resources}
                  isEdit={writePermission}
                  editHandler={(data) => editLabor(data.id)}
                  fileName='Resources List'
                />
              </Tab>
              <Tab eventKey="levels" title={`Cost/Sales Levels (${state.levels.length})`}>
                <CustomTable
                  columns={state.level_column}
                  data={state.levels}
                  isEdit={writePermission}
                  editHandler={(data) => editLevel(data.id)}
                  fileName='Cost/Sales Level List'
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );

}

const mapStateToProps = (state) => ({
  resources: state.resources.list,
});

const mapDispatchToProps = (dispatch) => ({
  deleteLabor: bindActionCreators(deleteLabor, dispatch),
  getLaborList: bindActionCreators(getLaborList, dispatch),
  editLabor: bindActionCreators(editLabor, dispatch),
  getLevels: bindActionCreators(getLevels, dispatch),
  updateLevel: bindActionCreators(updateLevel, dispatch),
  addResourcesAction: bindActionCreators(addResourcesAction, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LaborList)
);
