import { addDashboardAction } from 'src/slice/dashboard'
import {
  getFinanceDetails as getFinanceDetailsAPI,
  getLaborCost as getLaborCostAPI,
  getProjectCategory as getProjectCategoryAPI,
  getDashboardDetails as getDashboardDetailsAPI,
  getOperationDetailsAPI
} from '../api/dashboard.api'

export const getOperationDetails = (params) => {
  return (dispatch, getState) => {
    getOperationDetailsAPI(params).then((response) => {
      dispatch(addDashboardAction({ operations: response.operations, admin: response.admin, insights: response.insights, filter: params }))
    }).catch(e => {
      console.error(e)
    })
  }
}
export const getFinanceDetails = (params) => {
  return (dispatch, getState) => {
    return getFinanceDetailsAPI(params)
  }
}

export const getLaborCost = (params) => {
  return (dispatch, getState) => {
    return getLaborCostAPI(params)
  }
}
export const getProjectCategory = (params) => {
  return (dispatch, getState) => {
    return getProjectCategoryAPI(params)
  }
}

export const getDashboardDetails = (params) => {
  return (dispatch, getState) => {
    return getDashboardDetailsAPI(params)
  }
}