import React from 'react'
import Modal from './../../../../components/modal/modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveInvoice, saveServiceInvoice } from './../../../../actions/invoice.action'
import { getProject } from './../../../../actions/project-management.action'
import { format } from 'date-fns'
import './invoices.scss'
import idx from 'idx'
import { numberFormat } from '../../../../util/currency-formatter'
import { errorSnackBar } from '../../../../actions/common.action'
import Table from 'react-bootstrap/Table';

function ViewInvoice(props) {

  const { open, dialogClose, invoiceData, filter, getData, customerPaymentTerms, projectQuoteType } = props

  let progressClaimDueDate;

  // const progressClaimNo = filter === 'INVOICES' ? invoiceData.claimNumber : invoiceData.claimNumber
  const invoiceId = (invoiceData.invoice) ? (invoiceData.invoice.id) : (invoiceData.id)
  const issueDate = invoiceData.invoiceDate ? format(new Date(invoiceData.invoiceDate), 'dd/MM/yyyy') : ''
  const dueDateInvoice = invoiceData.dueDate ? format(new Date(invoiceData.dueDate), 'dd/MM/yyyy') : ''
  let invoiceDate = invoiceData.invoiceDate ? new Date(invoiceData.invoiceDate) : new Date()
  let dueDate = invoiceData.dueDate ? new Date(invoiceData.dueDate) : progressClaimDueDate


  if (filter === 'PROGRESS_CLAIM') {
    if (customerPaymentTerms) {
      progressClaimDueDate = new Date(invoiceDate);
      progressClaimDueDate.setDate(progressClaimDueDate.getDate() + customerPaymentTerms);
    }
  }

  const { claimAmount = 0, contractPrice = 0, variation = 0 } = idx(invoiceData, _ => _.progressClaim) || invoiceData

  const retentionAmount = filter === 'INVOICES' ? invoiceData.retentionAmount : invoiceData.retentionAmount
  const claimedRetention = filter === 'INVOICES' ? invoiceData.retentionClaimAmount : invoiceData.retentionClaimAmount
  let lessRetention = retentionAmount || 0
  let claimedRetentionEdit = claimedRetention || 0

  const generateInvoice = async () => {

    const data = {
      progressClaimId: invoiceData.u_id,
      dueDate,
      invoiceDate,
      retentionAmount: lessRetention,
      retentionClaimAmount: claimedRetentionEdit,
    }
    if (filter === 'PROGRESS_CLAIM') {
      const result = await props.saveInvoice(data)
      props.handleDownloadDialog(result.u_id, 'INVOICE', 'PROGRESS_CLAIM')
      getData()
    } else if (filter === 'PROGRESSCLAIM') {
      props.handleDownloadDialog(invoiceData.u_id, 'PROGRESSCLAIM')
    } else {
      props.handleDownloadDialog(invoiceData.u_id, 'INVOICE')
    }
    dialogClose()
  }

  const editProgressClaim = async () => {
    props.handleGeneratePC(invoiceData.u_id);
  }


  return (
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth={true}
      maxWidth='sm'
      modalHeading={filter === 'PROGRESS_CLAIM' ? 'Generate Invoice' : 'View Invoice'}
      modalSaveTxt={filter === 'PROGRESS_CLAIM' ? 'Save' : 'Download'}
      modalCloseTxt='Back'
      modalEditTxt={filter === 'PROGRESSCLAIM' ? 'Edit' : ''}
      onClick={generateInvoice}
      onEdit={editProgressClaim}
      showActionBtn
      savebtnClass='createBtn'
      cxlbtnClass='cancelBtn'
      primary
    >
      <>
        <div className="row">
          {/* <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Progress Claim</label>
              <p className="mt-2"><b>{progressClaimNo}</b></p>
            </div>
          </div> */}
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Invoice Number</label>
              <p className="mt-2"><b>{invoiceId}</b></p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Invoice Date</label>
              <p className="mt-2">{issueDate}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Due Date</label>
              <p className="mt-2">{dueDateInvoice}</p>
            </div>
          </div>
          {projectQuoteType == 'quote' && <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Amount Retained</label>
              <p className="mt-2">{retentionAmount}</p>
            </div>
          </div>}
          {projectQuoteType == 'quote' && <div className="col-lg-4 mt-3">
            <div className="view-block">
              <label>Retention Claim</label>
              <p className="mt-2">{claimedRetentionEdit}</p>
            </div>
          </div>}
        </div>

        <div className="row">
          <div className="col-12">
            <div className="material-list-block mt-4">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch">
                  <thead>
                    <tr>
                      <th className="w-auto">Description</th>
                      <th className="w-auto text-right">Ex-GST</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Contract Price</td>
                      <td className="text-right">{contractPrice && numberFormat(contractPrice)}</td>
                    </tr>
                    {projectQuoteType == 'quote' && <tr>
                      <td>Claimed Retention</td>
                      <td className="text-right">{numberFormat(claimedRetentionEdit || 0)}</td>
                    </tr>}
                    {projectQuoteType == 'quote' && <tr>
                      <td>Less Retention</td>
                      <td className="text-right">$ {lessRetention > 0 ? '-' + lessRetention.toFixed(2) : lessRetention.toFixed(2)}</td>
                    </tr>}
                    <tr>
                      <td>This Claim Amount</td>
                      <td className="text-right">
                        {numberFormat((claimAmount + claimedRetentionEdit) - lessRetention)}
                      </td>
                    </tr>
                    <tr className="border-0">
                      <td>Contract Balance</td>
                      <td className="text-right">
                        {numberFormat(contractPrice + variation - claimAmount)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 total-table mt-3 table-responsive mb-3">
          <table className="table-create table-variation mb-0 table table-bordered">
            <tbody>
              <tr className="border-0">
                <td className="text-left">
                  Sub Total
                </td>
                <td>
                  <span className="tableinfo pb-0">{numberFormat(claimAmount - lessRetention)}</span>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  GST
                </td>
                <td>
                  <span className="tableinfo pb-0">{numberFormat((claimAmount - lessRetention) / 10)}</span>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <b>Total Inc-GST</b>
                </td>
                <td>
                  <span className="tableinfo pb-0">
                    <b>
                      {numberFormat((claimAmount - lessRetention) + ((claimAmount - lessRetention) / 10))}
                    </b>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </Modal >
  )
}

const mapStateToProps = state => ({
  user: state.user.userData
})

const mapDispatchToProps = dispatch => ({
  saveInvoice: bindActionCreators(saveInvoice, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  saveServiceInvoice: bindActionCreators(saveServiceInvoice, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice)
