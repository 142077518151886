export const levelFields = {
    name: {
        name: 'name',
        label: 'Name',
        type: 'text',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['required']
    },
    description: {
        name: 'description',
        label: 'Description',
        type: 'textarea',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['required']
    },
    calloutCost: {
        name: 'calloutCost',
        label: 'Call Out Charge Amount',
        type: 'amount',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['number']
    },
    calloutAverageCost: {
        name: 'calloutAverageCost',
        label: 'Average Cost',
        type: 'amount',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['number']
    },
    travelCost: {
        name: 'travelCost',
        label: 'Travel Charge Amount',
        type: 'amount',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['number']
    },
    travelAverageCost: {
        name: 'travelAverageCost',
        label: 'Average Cost',
        type: 'amount',
        touched: false,
        error: false,
        valid: true,
        value: '',
        validation: ['number']
    }
}