import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
import {
  getGlobalSetting,
  editGlobalSetting,
  getGlobalSettingReducer,
} from "../../actions/global-setting.action";
import { emailValidator } from "../../util/validators/email";
import { errorSnackBar } from "../../actions/common.action";
import "./scss/templates.scss";
import { withRouter } from "../../util/with-router";
import CustomInput from "src/components/inputs/new-input";
import Button from 'react-bootstrap/Button';
import _ from "lodash";

class FinanceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        finance_accountName: {
          name: "finance_accountName",
          label: "Account Name",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_bsb: {
          name: "finance_bsb",
          label: "BSB",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_accountNo: {
          name: "finance_accountNo",
          label: "Account No",
          type: "text",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_remittance: {
          name: "finance_remittance",
          label: "Remittance Email",
          type: "email",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required", "email"],
        },
        finance_invoiceNo: {
          name: "finance_invoiceNo",
          label: "Invoice Start Number",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_purchaseOrderNo: {
          name: "finance_purchaseOrderNo",
          label: "Purchase Order Start Number",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_customerNo: {
          name: "finance_customerNo",
          label: "Customer Start Number",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_vendorNo: {
          name: "finance_vendorNo",
          label: "Vendor Start Number",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
        finance_projectNo: {
          name: "finance_projectNo",
          label: "Project Start Number",
          type: "number",
          touched: false,
          error: false,
          valid: true,
          value: "",
          validation: ["required"],
        },
      },
    };
  }

  componentDidMount() {
    this.getFinanceSetting();
  }

  getFinanceSetting = async () => {
    let { fields } = this.state;
    await this.props.getGlobalSettingReducer("FINANCE_TAX");
    const financeData = idx(this.props.globalSetting, (_) => _.finance_tax) || {};

    financeData && Object.entries(financeData).forEach(([key, value]) => {
      if (key in fields) fields[key].value = value || "";
    });

    this.setState({ fields });
  };

  editFinanceSetting = async () => {
    const { fields } = this.state;
    let isFormValid = true, dataToSend = {};

    fields && Object.entries(fields).forEach(([key, value]) => {
      const { error, valid } = this.handleValidation(value);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
      dataToSend[key] = value.value;
    });

    if (!isFormValid) {
      this.setState({ fields });
      return;
    }

    const financeData = idx(this.props.globalSetting, (_) => _.finance_tax) || {};

    const difference = _.difference(_.keys(financeData), _.keys(dataToSend));
    const companySettingTabFinanceData = _.pick(financeData, difference);
    const mergedObj = _.merge(dataToSend, companySettingTabFinanceData);
    dataToSend = mergedObj;
    try {
      await this.props.editGlobalSetting(_.merge({}, dataToSend, companySettingTabFinanceData), "FINANCE_TAX");
      this.getFinanceSetting();
    } catch (error) {
      console.error(error);
    }
  };

  inputChangeHandler = (e, key) => {
    let fields = this.state.fields, isFormValid = true;
    let value = e.target.value;

    fields[key].value = value;
    fields[key].touched = true;

    const { error, valid } = this.handleValidation(fields[key]);

    fields[key].error = error;
    fields[key].valid = valid;

    for (let key of Object.keys(fields)) {
      isFormValid = isFormValid && !fields[key].error;
    }

    this.setState({ fields, isFormValid });
  };

  daysInputChangeHandler = (e, name = "") => {
    var value = e.target.checked || false;
    let fields = this.state.fields;
    fields.workingDays.days[name].value = value;
    fields.workingDays.touched = true;
    this.setState({ fields: fields });
  };

  handleValidation = ({ value = "", validation = [] }) => {
    let error = false, valid = true;

    if (validation.includes("skip")) return { error, valid };


    if (validation.includes("required") && (value === null || value === "" || value.length === 0)) return { error: "This Field is Required", valid: false };
    else if (validation.includes("positive") && (isNaN(value) || Number(value) <= 0)) return { error: "Enter Positive Number!", valid: false }
    else if (validation.includes("email")) {
      let status = emailValidator(value);
      if (!status) return { error: "Email is not valid. Please Enter a valid Email format", valid: false, };
    }

    return { error, valid }
  };

  render() {
    const { fields } = this.state;
    const writePermission = this.props.writePermission;

    return (
      <div className="purchase-order-block">
        <div className="row">
          <div className="col-12 mt-3">
            <h2 className="content-heading content-heading-lg text-uppercase my-auto me-auto">
              Bank Account Information
            </h2>
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_accountName}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_accountName.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_bsb}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_bsb.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_accountNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_accountNo.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_remittance}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_remittance.name)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h2 className="content-heading content-heading-lg text-uppercase my-auto me-auto">
              Starting Sequence of Number
            </h2>
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_invoiceNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_invoiceNo.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_purchaseOrderNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_purchaseOrderNo.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_customerNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_customerNo.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_vendorNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_vendorNo.name)}
            />
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <CustomInput
              {...fields.finance_projectNo}
              disabled={!writePermission}
              onChange={(e) => this.inputChangeHandler(e, fields.finance_projectNo.name)}
            />
          </div>
        </div>

        {writePermission && (<div className="col-12 d-flex mt-3">
          <Button onClick={this.editFinanceSetting} className="ms-auto me-3 secondarybtn">Save</Button>
        </div>)}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  globalSetting: state.globalSetting,
});

const mapDispatchToProps = (dispatch) => ({
  getGlobalSetting: bindActionCreators(getGlobalSetting, dispatch),
  editGlobalSetting: bindActionCreators(editGlobalSetting, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  getGlobalSettingReducer: bindActionCreators(
    getGlobalSettingReducer,
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FinanceSetting)
);
