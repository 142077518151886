import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format, parseISO } from "date-fns";
import {
  getCategories,
  getItems,
  getLevel,
} from "./../../actions/inventory.action";
import {
  getProject,
  getOptions,
  updateOption,
  downloadProjectVariation,
  deleteOption,
} from "./../../actions/project-management.action";
import { errorSnackBar } from "./../../actions/common.action";
import "./scss/project-management.scss";
import AddTask from "./add-task";
import _ from "lodash";
import Modal from "../../components/modal/modal";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FileUploader from "../../components/file-uploader";
import DeleteIcon from "@material-ui/icons/Delete";
import { numberFormat } from "../../util/currency-formatter";
import ProposalDialog from "./proposal-dialog";
import DownloadDialog from "../../components/download-dialog";
import DeleteDialog from "./../../components/delete-dialog";
import idx from "idx";
import { getGlobalSettingTemplateTypes } from "./../../actions/global-setting-template.action";
import { withRouter } from "../../util/with-router";
import { findTotal, optionCalculation } from "../../util/calculations";
import { clearTaskAction } from "../../slice/add-task";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import '../project-management/scss/task.scss'
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SelectInput from "src/components/inputs/select";
import DatePickerInput from "src/components/inputs/date-picker";
import { ProposalType } from "src/util/enums";
import { fileValidator } from "src/util/validators/file";

function ViewVariation({ writePermission, ...props }) {

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filterOptions, setfilterOptions] = useState([]);
  const [taskDialogState, setTaskDialogState] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState(false);
  const [proposalDialogState, setProposalDialogState] = useState(false);
  const [proposalType, setProposalType] = useState(false);
  const [filter, setFilter] = useState("ALL");
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [labors, setLabors] = useState([]);
  const [optionId, setOptionId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [calculation, setCalculation] = useState({})
  const [action, setAction] = useState("new");
  const [approveDialog, setApproveDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [approvalDate, setApprovalDate] = useState({
    value: null,
    valid: true,
    error: "",
    touched: false,
  });

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const { projectDetail = {} } = useSelector(state => state)
  let projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)

  const [menuItems, setMenuItems] = useState([
    {
      name: "Approve",
      isDisabled: true,
    },
    {
      name: "Reject",
      isDisabled: true,
    },
    {
      name: "Restore",
      isDisabled: true,
    },
    {
      name: "Delete",
      isDisabled: true,
    },
  ]);
  let [projectData, setProjectData] = useState({
    projectName: "",
    projectDescription: "",
    customerName: "",
  });
  let [downloadData, setdownloadData] = useState({
    open: false,
    id: "",
    template: "",
  });

  useEffect(() => {
    props.getProject(props.params.projectId).then((project) => {
      getLevels(project.level);
      let projectData = {
        projectName: project.name,
        projectDescription: project.description,
        customerName: idx(project, (_project) => _project.customer.businessName) || "",
        profitMarginValue: idx(project, (_project) => _project.profitMarginValue) || "",
      };
      setProjectData({ ...projectData });
    });
    props.getCategories().then((categories) => {
      setCategories(categories);
    });
    props.getItems().then((items) => {
      setItems(items);
    });

    getOptions();
    setfilterOptions([
      { key: "All Variations", value: "ALL" },
      { key: "Pending", value: "PENDING" },
      { key: "Approved", value: "APPROVED" },
      { key: "Draft", value: "DRAFT" },
    ]);
  }, []);

  const getOptions = () => {
    const projectId = props.params.projectId;
    if (projectId) {
      props.getOptions(projectId).then((res) => {
        let sno = 0;
        let tempArray = [];

        res.forEach((option) => {
          option.revisionNumber = idx(option, _ => _.taskRevision[0].RevisionNumber) || '';
          option.revisionId = idx(option, _ => _.taskRevision[0].RevisionId) || '';
          option.revisionName = idx(option, _ => _.taskRevision[0].revisionName) || '';
          tempArray.push(option);
        });

        const sortedtempArray = _.chain(tempArray)
          .groupBy("revisionId")
          .map((revisions, revisionId) => {
            const sortedRevisions = _.sortBy(revisions, [(o) => new Date(o.createdAt || null)]) || [];
            return { revisionId, revisions: sortedRevisions };
          })
          .sortBy((o) => o.revisions[0].createdAt)
          .map((data) => data.revisions)
          .value()
          .flat(1);

        const uniqueIdArr = _.uniqBy(sortedtempArray, "revisionId");
        let selectedVariations = [];

        sortedtempArray.forEach((option, optionIndex) => {
          let index = _.findIndex(uniqueIdArr, function (item) { return item.id === option.id; });
          if (index !== -1) {
            option.revision = true;
            option.selected = true;
            sno += 1;
          } else {
            option.selected = false;
          }
          option.sno = sno;
          if (option.proposal_selected && option.proposal_status === "APPROVED") {
            selectedVariations.push(option)
          }
          option.variationNo = generateVariationNumber(optionIndex + 1, 3);
        });

        let _calculations = optionCalculation({ options: selectedVariations });

        setCalculation(_calculations)

        setOptions(sortedtempArray);
        setFilteredOptions(sortedtempArray);

      });
    }
  };

  const generateVariationNumber = (n, width, z) => {
    z = z || "0";
    n = n + "";
    return n.length >= width
      ? `V-${n}`
      : `V-${new Array(width - n.length + 1).join(z) + n}`;
  };

  const changeFilter = (status) => {
    if (status && status !== "ALL") {
      let filterProposal = [];
      if (status === "DRAFT") {
        filterProposal = options.filter((p) => p.proposal_selected === false);
      } else {
        filterProposal = options.filter(
          (p) => p.proposal_selected === true && p.proposal_status === status
        );
      }

      setFilteredOptions(filterProposal);
    } else {
      setFilteredOptions(options);
    }
    setFilter(status);
  };

  const updateStatus = (optionIndex, status, optionId, proposalStatus) => {
    const formData = new FormData();
    if (proposalStatus === "DRAFT") {
      formData.set("proposal_status", "DRAFT");
    } else {
      formData.set("proposal_status", status ? "APPROVED" : "REJECTED");
    }
    approvalDate.value && formData.set("approvalDate", approvalDate.value);
    for (let i = 0; i < fileToUpload.length; i++) {
      formData.append("attachment", fileToUpload[i]);
    }
    props.updateOption(formData, optionId).then((res) => {
      getOptions();
    }).catch(e => console.log(e));
  };

  const optionDialogHandler = (
    optionId = "",
    isOption = false,
    editOption = false,
    action = "new"
  ) => {
    props.clearTaskAction();
    setOptionId(optionId);
    setTaskDialogState(!taskDialogState);
    setAction(action);
  };

  const getLevels = (levelId) => {
    props.getLevel(levelId).then((level) => {
      const labors = [];
      level.hourlyCost &&
        level.hourlyCost.forEach((cost) => {
          labors.push({
            ...cost,
            calloutCost: level.calloutCost,
            travelCost: level.travelCost,
          });
        });
      setLabors(labors);
    });
  };

  const handleMenu = (event, option) => {
    setOptionId(option.id);
  };

  const handleMenuItemClick = (index, id) => {
    setOptionId(id);
    switch (index) {
      case 0:
        setApprovalDate({ ...approvalDate, value: null });
        setFileToUpload([]);
        setApproveDialog(true);
        break;
      case 1:
        updateStatus("", false, id);
        handleMenuClose();
        break;
      case 2:
        updateStatus("", false, id, "DRAFT");
        handleMenuClose();
        break;
      case 3:
        deleteDialogHandler(id);
        handleMenuClose();
        break;
      default:
        break;
    }
  };

  const handleMenuClose = () => {
    let menu = menuItems;
    for (var i = 0; i < menu.length; i++) {
      menu[i].isDisabled = true;
    }
    setMenuItems(menu);
    setApproveDialog(false);
    setOptionId("");
  };

  const handleApproveClick = () => {
    if (!approvalDate.value) {
      setApprovalDate({
        ...approvalDate,
        error: "Select Approval Date!",
        valid: false,
        touched: true,
      });
      return;
    }
    updateStatus("", true, optionId);
    handleMenuClose();
  };

  const handleFileUpload = (e) => {
    let isValid = true;
    let fileToUpload = e.target.files;
    if (e.target.files.length > 5 || !e.target.files.length) {
      props.errorSnackBar("You Can not upload more than 5 files at a time!");
    } else {
      Array.isArray(fileToUpload) && fileToUpload.map(file => {
        const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
        const { validFileSize, validFileType, error } = fileValidation
        if (!validFileSize.valid && !error) {
          isValid = false
          props.errorSnackBar(validFileSize.error)
        }
        else if (!validFileType.valid && !error) {
          isValid = false
          props.errorSnackBar(validFileType.error)
        } else if (error) {
          isValid = false
          props.errorSnackBar(error.error)
        }
      })
    }

    if (!isValid) return

    setFileToUpload([...fileToUpload, ...e.target.files]);
    e.target.value = "";
  };

  const handleRemoveFile = (index) => {
    let fileToUploadSplice = fileToUpload;
    fileToUploadSplice.splice(index, 1);
    setFileToUpload([...fileToUploadSplice]);
  };

  const optionHandler = (value, index) => {
    let test = filteredOptions.filter((p, i) => {
      if (i === index) {
        p.selected = value;
      }
      return p;
    });
    setFilteredOptions(test);
  };

  const proposalDialogHandler = (type) => {
    if (proposalDialogState) {
      getOptions();
    }
    setProposalType(type)
    setProposalDialogState(!proposalDialogState);
  };
  const gettemplate = async () => {
    if (templateOptions.length === 0) {
      let _templateOptions = [];
      let type = "ProjectVariation";
      const templateData = await props.getGlobalSettingTemplateTypes(type);
      for (let key in templateData) {
        _templateOptions.push({
          value: templateData[key].id,
          key: templateData[key].name,
        });
      }
      setTemplateOptions(_templateOptions);
    }
  };

  const handleDownloadDialog = (id = "", template = "") => {
    downloadData.open = !downloadData.open;
    downloadData.id = id;
    downloadData.template = template;
    if (templateOptions.length === 0) {
      gettemplate();
    }
    setdownloadData({ ...downloadData });
  };

  const downloadFile = async (fileFormat = "pdf", tempId) => {
    if (Array.isArray(filteredOptions)) {
      if (fileFormat === "pdf" || fileFormat === "ALL") {
        await Promise.all(
          filteredOptions.map(async (item) => {
            if (item.selected)
              await props.downloadProjectVariation(item.id, fileFormat, tempId).catch(e => console.error(e));
            return;
          })
        );
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        await Promise.all(
          filteredOptions.map(async (item) => {
            if (item.selected)
              await props.downloadProjectVariation(item.id, fileFormat, tempId).catch(e => console.error(e));
            return;
          })
        );
      }
    }
    handleDownloadDialog();
  };

  const deleteOption = (id) => {
    props.deleteOption(id).then((res) => {
      setDeleteDialogState(false);
      getOptions();
    });
  };

  const deleteDialogHandler = (id = "") => {
    setDeleteDialogState(!deleteDialogState);
    setDeleteId(id);
  };

  const viewProjectList = () => navigate(`/projects?project-type=${projectType}`)
  const viewProject = () => navigate(`/projects/view/${projectId}`)

  return (
    <>
      {taskDialogState && <AddTask
        projectId={props.params.projectId}
        profitMargin={projectData.profitMarginValue}
        open={taskDialogState}
        dialogClose={() => optionDialogHandler()}
        categories={categories}
        items={items}
        isOption={true}
        isVariation={true}
        optionId={optionId}
        editOption={optionId ? true : false}
        action={action}
        labors={labors}
        getOptions={() => getOptions()}
        liststages={false}
        listvariation={false}
        userAccessControl={props.userAccessControl}
      />}
      <div className="purchase-order-block">
        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Variations</h2>
            <div className="col-12 breadcrumb-block p-0">
              <Breadcrumb>
                <Breadcrumb.Item onClick={viewProjectList}>PROJECTS</Breadcrumb.Item>
                <Breadcrumb.Item onClick={viewProject}>{idx(projectData, _ => _.projectName)}</Breadcrumb.Item>
                <Breadcrumb.Item active>Variations</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="col-md-6 ms-auto mt-auto mb-2 d-flex">
            <div className="col-md-6 ms-auto">
              <SelectInput
                onChange={(e) => {
                  changeFilter(e.target.value);
                }}
                label="Variations Filter"
                value={filter}
                items={filterOptions}
              />
            </div>
            {writePermission && (<button
              type="submit"
              className="secondarybtn secondarybtn-outline ms-2 btn-ch mt-auto btn btn-primary"
              onClick={() => optionDialogHandler()}
            >
              Add Variations
            </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="material-list-block mt-3">
              <div className="table-responsive">
                <Table bordered className="table-create table-material table-material-ch">
                  <thead>
                    <tr>
                      <th className="w-auto">Variation</th>
                      <th className="w-auto">Description</th>
                      <th>Total Cost($)</th>
                      <th>Status</th>
                      <th>Approval Date</th>
                      <th>Action</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOptions && filteredOptions.map((option, optionIndex) => {
                      let popover = (
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <ul className="action-block-list">
                              {Array.isArray(menuItems) && menuItems.map((menuItem, index) => {
                                let isDisabled = true;
                                if (option.proposal_status === "PENDING" && option.proposal_selected) {
                                  if (index == 0 || index == 1 || index == 3)
                                    isDisabled = false;
                                } else if (option.proposal_status === "APPROVED") {
                                  if (index == 1)
                                    isDisabled = false;
                                } else if (option.proposal_status === "REJECTED") {
                                  if (index == 0 || index == 2 || index == 3)
                                    isDisabled = false;
                                } else if (option.proposal_status === "PENDING" && option.proposal_selected === false) {
                                  if (index == 0 || index == 3)
                                    isDisabled = false;
                                }
                                if (isDisabled) return;
                                return (
                                  <li
                                    key={menuItem.name}
                                    disabled={!writePermission || isDisabled}
                                    onClick={() => handleMenuItemClick(index, option.id)}
                                  >
                                    {menuItem.name}
                                  </li>
                                )
                              })
                              }
                            </ul>
                          </Popover.Body>
                        </Popover>
                      );
                      return (
                        <tr>
                          <td onClick={() => optionDialogHandler(option.id, true, true)}>
                            <span>{`${option.variationNo} ${option.name}`}</span>
                            {option.revisionNumber !== 1 ? " - " + option.revisionName : ""}
                          </td>
                          <td>{option.description}</td>
                          <td>{numberFormat(findTotal(option))}</td>
                          <td>
                            {option.proposal_selected ? (
                              option.proposal_status &&
                                option.proposal_status === "PENDING" ? (
                                <span style={{ color: "blue" }}>Pending</span>
                              ) : option.proposal_status === "APPROVED" ? (
                                <span style={{ color: "green" }}>Approved</span>
                              ) : (
                                <span style={{ color: "red" }}>Rejected</span>
                              )
                            ) : (
                              <span>Draft</span>
                            )}
                          </td>
                          <td>
                            {option.approvalDate
                              ? format(parseISO(option.approvalDate), "dd/MM/yyyy")
                              : "--"}
                          </td>
                          <td>
                            <div className="action-dropdown">
                              <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                                <Button onClick={(event) => handleMenu(event, option)} variant="default"><MoreHorizIcon /></Button>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td>
                            <Form>
                              <div className="table-checkbox">
                                <Form.Check
                                  type={'checkbox'}
                                  value={option.selected}
                                  onChange={(e) =>
                                    optionHandler(e.target.checked, optionIndex)
                                  }
                                />
                              </div>
                            </Form>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex gap-3 justify-content-end mt-3">
            <Button type="submit" className="secondarybtn" onClick={() => proposalDialogHandler(ProposalType.SUMMARY)}>
              Summary Variation
            </Button>
            <Button type="submit" className="secondarybtn" onClick={() => proposalDialogHandler(ProposalType.DETAILED)}>
              Detailed Variation
            </Button>
          </div>

        </div>

        <div className="col-12 total-table mt-3 table-responsive">
          <table className="table-create table-variation mb-0 table table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>Material Cost</th>
                <th>Resource Cost</th>
                <th>Contractor Cost</th>
                <th>Profit Margin</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <span className="tableinfo">{numberFormat(idx(calculation, _ => _.material.cost) || 0)}</span>
                </td>
                <td>
                  <span className="tableinfo">{numberFormat(idx(calculation, _ => _.labor.cost) || 0)}</span>
                </td>
                <td>
                  <span className="tableinfo">{numberFormat(idx(calculation, _ => _.subcontractor.cost) || 0)}</span>
                </td>
                <td>
                  <span className="tableinfo">{numberFormat(idx(calculation, _ => _.total_profit) || 0)}</span>
                </td>
                <td>
                  <span className="tableinfo">{numberFormat(idx(calculation, _ => _.total) || 0)}</span>
                </td>
                <td>
                  <span className="empty-icon"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <DownloadDialog
          {...downloadData}
          downloadFile={downloadFile}
          handleDownloadDialog={handleDownloadDialog}
          templateOptions={templateOptions}
          downloadModuleName="Variation"
        />
        {proposalDialogState && <ProposalDialog
          type={proposalType}
          projectName={projectData.projectName}
          customerName={projectData.customerName}
          projectId={props.params.projectId}
          projectDescription={projectData.projectDescription}
          open={proposalDialogState}
          dialogClose={() => proposalDialogHandler()}
          options={filteredOptions}
          isVariation={true}
          isUpdate={false}
          handleDownloadDialog={handleDownloadDialog}
        />}
        <Modal
          open={approveDialog}
          onClose={handleMenuClose}
          onClick={handleApproveClick}
          modalHeading={"Approve Variation"}
          modalSaveTxt={"Approve"}
          modalCloseTxt="Cancel"
          showActionBtn={true}
          fullWidth={true}
          maxWidth={"sm"}
          cxlbtnclassName="cancelBtn"
          savebtnclassName="createBtn"
          primary
        >
          <div className="purchase-order-block">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <DatePickerInput
                  label={"Approval Date"}
                  {...approvalDate}
                  onChange={(e) =>
                    setApprovalDate({
                      ...approvalDate,
                      value: e,
                      error: "",
                      valid: true,
                      touched: true,
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 mt-3 fileupload-section">
                <div className="file-section-part position-relative h-auto px-0">
                  {/* <label className="label-header">Attachment</label> */}
                  <div className="file-part">
                    <FileUploader
                      title="Attach Documents"
                      fileHandler={(files) =>
                        handleFileUpload({
                          target: {
                            files,
                            type: "file",
                            name: "attachments",
                          },
                        })
                      }
                    />
                    <label htmlFor="#file-section"></label>
                  </div>
                  <input type="file" id="file-section"></input>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-3">
                <div className="form-group">
                  <div className="input-block">
                    <label className="input-label">Uploaded Documents</label>
                    <p className="m-0 doc-info text-left">
                      {Array.isArray(fileToUpload) && fileToUpload.map((file, index) => (
                        <span key={index}>
                          {file.name}
                          <span onClick={() => handleRemoveFile(index)}>
                            <DeleteIcon />
                          </span>
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </div>

            </div>
            {/* <Grid xs={6} item>
              <FileUploader
                fileHandler={(files) =>
                  handleFileUpload({
                    target: { files, type: "file", name: "attachments" },
                  })
                }
              />
              {Array.isArray(fileToUpload) &&
                fileToUpload.map((file, index) => (
                  <div className="attachedFiles" key={`${index}${file.name}`}>
                    <p style={{ margin: "0 10px 0 0" }}>{file.name}</p>
                    <p
                      style={{ margin: "0", float: "right", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <DeleteIcon />
                    </p>
                  </div>
                ))}
            </Grid> */}
          </div>
        </Modal>
        <DeleteDialog
          open={deleteDialogState}
          id={deleteId}
          deleteHandler={deleteOption}
          dialogClose={deleteDialogHandler}
          moduleName="Variation"
        />
      </div>
    </>

  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(
    getGlobalSettingTemplateTypes,
    dispatch
  ),
  getItems: bindActionCreators(getItems, dispatch),
  getLevel: bindActionCreators(getLevel, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getOptions: bindActionCreators(getOptions, dispatch),
  updateOption: bindActionCreators(updateOption, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  downloadProjectVariation: bindActionCreators(
    downloadProjectVariation,
    dispatch
  ),
  deleteOption: bindActionCreators(deleteOption, dispatch),
  clearTaskAction: bindActionCreators(clearTaskAction, dispatch),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewVariation)
);
