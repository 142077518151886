import idx from "idx";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../scss/project-management.scss";
import _ from "lodash";
import { getProjectData } from "../utils/get-project";
import { errorSnackBar } from "src/actions/common.action";
import { emailValidator } from "src/util/validators/email";
import "../scss/task.scss";
import {
    PROJECT_MANAGEMENT,
    CUSTOMER_MANAGEMENT,
    ADD_CUSTOMER,
} from "../../../constants/route-paths";
import { getUserList } from "../../../actions/user-management.action";
import {
    addProject,
    editProject,
    addStage,
    addContentTemplate,
} from "../../../actions/project-management.action";
import AddProjectStepTwo from "../add-project-step-two";
import DeleteIcon from "@material-ui/icons/Delete";
import { getLevels } from "../../../actions/inventory.action";
import Cookies from "js-cookie";
import ViewProject from "../view-project";
import ShowTemplates from "../templates";
import FileUploader from "../../../components/file-uploader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import AddIcon from '@mui/icons-material/Add';
import DatePickerInput from "src/components/inputs/date-picker";
import Button from 'react-bootstrap/Button';
import CustomInput, { AmountInput } from "src/components/inputs/new-input";
import SelectInput from "src/components/inputs/select";
import TextAreaInput from "src/components/inputs/textarea-input";
import { phoneValidator } from "src/util/validators/phone";
import { passwordValidator } from "src/util/validators/password";
import { cleaner } from "src/util/validators/cleaners";
import { AddProjectFields } from "./data/fields";
import { getCustomerList } from "src/actions/customer-management.action";
import { clearProjectAction } from "src/slice/project-detail";
import { fileValidator } from "src/util/validators/file";
import { setCookie } from "../../../util/auth";

export default function AddProject(props) {

    const [addProjectState, setAddProjectState] = useState({
        convertTender: false,
        currentStep: 0,
        siteAddress: [],
        siteAddressList: [],
        edit: false,
        projectNextStep: false,
        showTemplateModal: false,
    })
    const [formField, setFormField] = useState(_.cloneDeep(AddProjectFields))
    const permissions = props.permissions || {};
    let { projectId, step } = useParams();
    if (!projectId) projectId = props.projectId
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const entitlements = useSelector(state => state.user.entitlements)
    const projectDetail = useSelector(state => state.projectDetail)
    const project = useSelector(state => state.project)
    const customerData = useSelector(state => state.customerData)

    useEffect(() => {
        initLoad()
    }, [projectId])

    useEffect(() => {
        if (props.convertToProject && !formField.budget.disable) {
            setFormField(prev => ({
                ...prev,
                budget: { ...prev.budget, disable: props.convertToProject || false }
            }));
        }
    }, [props.convertToProject])

    useEffect(() => {
        if ((idx(project, _ => _.projectCategories) || []).length && !formField.category.items.length) {
            const projectcategories = [];
            (idx(project, _ => _.projectCategories) || []).map((category) =>
                projectcategories.push({ key: category.name, value: category.type })
            );
            let project_type = searchParams.get('project-type')
            let _category = projectcategories.find(item => item.value == project_type)
            setFormField(prev => ({
                ...prev,
                category: { ...prev.category, items: projectcategories, value: _category?.value || project_type }
            }));
        }
    }, [project, formField.category.items])

    useEffect(() => {
        if (projectId && idx(projectDetail, _ => _.project.res.id) && formField.projectName.value != idx(projectDetail, _ => _.project.res.name)) {
            _editProject()
        }
    }, [projectDetail])

    const initLoad = async () => {

        let projectStep = searchParams.get("project-step");

        if (projectStep == "VIEW") {
            if (addProjectState.currentStep != 1) {
                setCookie({ name: "projectId", value: projectId });
                setAddProjectState(prev => ({
                    ...prev,
                    currentStep: 1,
                    projectNextStep: true,
                }));
            }
        }
        if (projectStep == "TENDERVIEW") {
            if (addProjectState.currentStep != 1 && !props.tenderEditPage) {
                setCookie({ name: "projectId", value: projectId });
                setAddProjectState(prev => ({
                    ...prev,
                    currentStep: 1,
                }));
            }
            if (props.currentStep == 0 && addProjectState.currentStep !== 0 && props.tenderEditPage === true) {
                setCookie({ name: "projectId", value: projectId });
                setAddProjectState(prev => ({
                    ...prev,
                    currentStep: 0,
                }));
            }
        }

        if (!customerData.dropdown.length) dispatch(getCustomerList()).catch(e => console.error(e))
        let allowedProjectTypes = []
        if (entitlements.currentProject) allowedProjectTypes.push({ value: "noquote", key: "No Quote" })
        if (entitlements.quotesProject) allowedProjectTypes.push({ value: "quote", key: "Quote" })
        setFormField(prev => ({
            ...prev,
            projectQuoteType: { ...prev.projectQuoteType, items: allowedProjectTypes }
        }));
        dispatch(getLevels()).then((res) => {
            const levels = [];
            (res || []).map((level) => levels.push({ key: level.name, value: level.id }));
            setFormField(prev => ({
                ...prev,
                level: { ...prev.level, items: levels }
            }));
        });

        dispatch(getUserList()).then((users) => {
            const data = users.map((user) => {
                return {
                    value: user.id,
                    key: (idx(user, _ => _.firstName) || '') + " " + (idx(user, _ => _.lastName) || ''),
                };
            });
            setFormField(prev => ({
                ...prev,
                manager: { ...prev.manager, items: data }
            }));
        });
        const convertTender = props.convertTender || false;
        if (convertTender) {
            setFormField(prev => ({
                ...prev,
                manager: { ...prev.manager, validation: prev.manager.validation.splice(formField.projectQuoteType.value == "quote" ? 0 : 1, 1) },
                startDate: { ...prev.startDate, validation: prev.startDate.validation.splice(1, 1) },
                budget: { ...prev.budget, validation: prev.budget.validation.splice(1, 1) }
            }));
        }
        setAddProjectState(prev => ({
            ...prev,
            convertTender
        }));
        if (projectId) {
            getProjectData({ projectId, dispatch })
        } else {
            dispatch(clearProjectAction())
        }

        // let fields = _.cloneDeep(formField);
        const redirect = searchParams.get("redirect");
        // const projectType = searchParams.get("project-type");

        if (redirect && sessionStorage.getItem("fields")) {
            let fields = JSON.parse(sessionStorage.getItem("fields"));
            Cookies.remove("url");
            Cookies.remove("fields");
            setFormField(prev => ({
                ...prev,
                profitMarginValue: { ...prev.profitMarginValue, value: fields.profitMarginValue.value || prev.profitMarginValue.value },
                projectName: { ...prev.projectName, value: fields.projectName.value || prev.projectName.value },
                address: { ...prev.address, value: fields.address.value || prev.address.value },
                manager: { ...prev.manager, value: fields.manager.value || prev.manager.value },
                customer: { ...prev.customer, value: fields.customer.value || prev.customer.value },
                ...(fields.contact.value ? { contact: { ...prev.contact, value: fields.contact.value || prev.contact.value } } : {}),
                date: { ...prev.date, value: fields.date.value || prev.date.value },
                startDate: { ...prev.startDate, value: fields.startDate.value || prev.startDate.value },
                budget: { ...prev.budget, value: fields.budget.value || prev.budget.value },
                level: { ...prev.level, value: fields.level.value || prev.level.value },
                category: { ...prev.category, value: fields.category.value || prev.category.value },
                projectQuoteType: { ...prev.projectQuoteType, value: fields.projectQuoteType.value || prev.projectQuoteType.value },
                projectType: { ...prev.projectType, value: fields.projectType.value || prev.projectType.value },
                description: { ...prev.description, value: fields.description.value || prev.description.value },
                exclusions: { ...prev.exclusions, value: fields.exclusions.value || prev.exclusions.value },
            }));
        }
    }

    const _editProject = async () => {
        const project = idx(projectDetail, _ => _.project.res)
        const status = project.type || "TENDER";
        const calculations = idx(projectDetail, _ => _.project.calculations)

        let attachments = []
        project.attachment && project.attachment.forEach((attach) => {
            if (_.isString(attach)) {
                attachments.push(JSON.parse(attach));
            } else {
                attachments.push(attach);
            }
        });

        if (step === "1") {
            stepTwo(project);
        }
        let siteAddress;
        let siteAddressList = [];
        if (project.customer && project.customer.id) {
            const Customer = (idx(customerData, _ => _.customers) || []).find((customer) => customer.id === (project.customer.id)) || {};
            siteAddressList = Customer.siteAddress || [];
            siteAddress = getAddressDropdown(project.customer.id);
        }

        setFormField(prev => ({
            ...prev,
            address: {
                ...prev.address,
                value: siteAddress.find(item => item.key == cleaner(project.address || ""))?.value || cleaner(project.address || ""),
            },
            contact: {
                ...prev.contact,
                value: idx(project, _ => _.customerContact.id) || ""
            },
            manager: {
                ...prev.manager,
                value: project.managerId || "",
                validation: status === "PROJECT" ? prev.projectQuoteType.value == "quote" ? prev.manager.validation.splice(0, 1) : prev.manager.validation.splice(1, 1) : prev.manager.validation
            },
            startDate: {
                ...prev.startDate,
                value: project.startDate ? new Date(project.startDate) : '',
                validation: status === "PROJECT" ? prev.startDate.validation.splice(1, 1) : prev.startDate.validation
            },
            budget: {
                ...prev.budget,
                value: props.convertTender ? Number(calculations.total || 0).toFixed(2) : Number(project.budget || 0).toFixed(2) || "",
                validation: status === "PROJECT" ? prev.budget.validation.splice(1, 1) : prev.budget.validation
            },
            exclusions: { ...prev.exclusions, value: project.exclusions },
            description: { ...prev.description, value: project.description },
            projectQuoteType: { ...prev.projectQuoteType, value: project.quoteType || "" },
            projectName: { ...prev.projectName, value: project.name },
            level: { ...prev.level, value: project.level },
            category: { ...prev.category, value: project.projectType },
            customer: { ...prev.customer, value: idx(project, _ => _.customer.id) || "" },
            date: { ...prev.date, value: new Date(project.dateOfCreation) },
            projectType: { ...prev.projectType, value: project.type },
            profitMarginValue: { ...prev.profitMarginValue, value: project.profitMarginValue || "" },
            attachments: { ...prev.attachments, attachmentArr: attachments },
        }));

        setAddProjectState(prev => ({
            ...prev,
            edit: true,
            siteAddress,
            siteAddressList,
        }));

    }

    const cancelHandler = () => {
        const projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)
        const status = idx(projectDetail, _ => _.project.projectType) || ''
        navigate(`/${PROJECT_MANAGEMENT}?project-type=${projectType || props.projectType}&project-category=${status}`);
    };

    const isConvertToProject = () => {
        return props.convertToProject ? true : false
    }

    const inputChangeHandler = (e) => {
        if (e.target.type === "file") {
            const fileToUpload = e.target.files
            Array.isArray(fileToUpload) && fileToUpload.forEach(file => {
                const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
                const { validFileSize, validFileType, error } = fileValidation
                if (!validFileSize.valid && !error) dispatch(errorSnackBar(validFileSize.error))
                else if (!validFileType.valid && !error) dispatch(errorSnackBar(validFileType.error))
                else if (error) dispatch(errorSnackBar(error.error))
                else formField[e.target.name].value.push(file);
            })

        } else {
            formField[e.target.name].value = e.target.value;
        }
        formField[e.target.name].touched = true;
        const { error, valid } = handleValidation(e.target, formField[e.target.name].validation);
        formField[e.target.name].error = error;
        formField[e.target.name].valid = valid;
        let isFormValid = true;
        for (let key of Object.keys(formField)) {
            isFormValid = isFormValid && !formField[key].error;
        }
        if (formField.projectQuoteType.value == "quote") {
            formField.manager.validation.splice(0, 1);
        } else {
            formField.manager.validation.splice(1, 1);
        }
        formField['isFormValid'] = isFormValid
        setFormField(prev => ({
            ...prev,
            ...formField
        }));
    };

    const handleValidation = ({ name, type, value }, validation = []) => {
        let error = false;
        let valid = true;
        if (!validation.includes("skip") && validation.includes("required") && (value === null || !value)) {
            return { error: "This Field is Required", valid: false };
        }
        if (name === "email") {
            let status = emailValidator(value);
            if (!status) {
                return { error: "Please Enter Valid Email", valid: false };
            }
        }

        if (name === "phoneNumber") {
            let status = phoneValidator(value);
            if (!status) {
                return { error: "Please Enter Valid Mobile Number", valid: false };
            }
        }

        if (name === "password") {
            let status = passwordValidator(value)
            return status;
        }

        return { error, valid };
    };

    const inputChangeCustomer = async (e) => {
        inputChangeHandler(e);
        const Customer = (idx(customerData, _ => _.customers) || []).find((customer) => customer.id === e.target.value) || {};
        let siteAddressList = Customer.siteAddress || [];
        let siteAddress = getAddressDropdown(e.target.value);
        formField.level.value = Customer?.level_id || "";
        setFormField(prev => ({
            ...prev,
            ...formField
        }));
        setAddProjectState(prev => ({
            ...prev,
            siteAddress,
            siteAddressList,
        }));
    };

    const getContactDropdown = (value) => {
        const Customer = (idx(customerData, _ => _.customers) || []).find((customer) => customer.id === (value || formField.customer.value)) || {};
        const data = (Customer.contacts || []).map((contact) => {
            return {
                value: contact.id,
                key: contact.firstName + " " + contact.lastName,
            };
        });
        return data;
    }

    const getAddressDropdown = (value) => {
        const Customer = (idx(customerData, _ => _.customers) || []).find((customer) => customer.id === (value || formField.customer.value)) || {};
        const data = (Customer.siteAddress || []).map((site) => {
            return {
                value: site.id,
                key: site.name || site.f_address,
            };
        });
        return data;
    }

    const addNewCustomer = (id) => {
        let { pathname, search } = window.location;
        sessionStorage.setItem("url", `${pathname}${search}`);
        sessionStorage.setItem("fields", JSON.stringify(formField));
        navigate(`/${CUSTOMER_MANAGEMENT}/${id ? 'edit' : ADD_CUSTOMER}${id ? `/${id}` : ''}?redirect=true`);
    };

    const inputChangeDateHandler = (date, name) => {
        formField[name].touched = true;
        formField[name].value = date;
        formField[name].error = false;
        setFormField(prev => ({
            ...prev,
            ...formField
        }));
    };

    const renderFiles = () => {
        let files = []
        for (var x = 0; x < formField.attachments.value.length; x++) {
            const index = x;
            files.push(
                <span key={x}>
                    {formField.attachments.value[x].name}
                    {permissions.writePermission && (
                        <span onClick={() => removeFile(index, "new")}>
                            <DeleteIcon />
                        </span>
                    )}
                </span>
            );
        }
        return files;
    }

    const renderAttachments = () => {
        const attachments = formField.attachments.attachmentArr.map((doc, i) => (
            <div key={i} className="attachedFiles input-block-level">
                <p style={{ margin: "0 10px 0 0" }}>
                    <a href={doc.url} rel="noopener noreferrer" target="_blank">{doc.name}</a>
                </p>
                {permissions.writePermission && (
                    <p style={{ margin: "0", float: "right", cursor: "pointer" }} onClick={() => removeFile(i, "old")} >
                        <DeleteIcon />
                    </p>
                )}
            </div>
        ));
        return attachments
    }

    const removeFile = (index, type) => {
        if (type === "new") {
            const data = [];
            for (var x = 0; x < formField.attachments.value.length; x++) {
                if (x !== index) {
                    data.push(formField.attachments.value[x]);
                }
            }
            formField.attachments.value = data;
        }
        if (type === "old") {
            const data = formField.attachments.attachmentArr.filter(
                (doc, i) => i !== index
            );
            formField.attachments.attachmentArr = data;
        }
        setFormField(prev => ({
            ...prev,
            ...formField
        }));
    };

    const formatData = (fields) => {
        const { siteAddress, edit } = addProjectState;

        let address = siteAddress.find((sa) => sa.value === fields.address.value) || {};
        if (!address.key) address.key = fields.address.value;
        const formData = new FormData();
        formData.set("name", fields.projectName.value);
        if (address.key) formData.set("address", address.key);
        formData.set("level", fields.level.value);
        if (edit) {
            formData.set("category", fields.category.value);
        }
        formData.set("manager", fields.manager.value);
        formData.set("customer", fields.customer.value);
        formData.set("customerContact", fields.contact.value);
        formData.set("dateOfCreation", fields.date.value);
        formData.set("description", fields.description.value);
        formData.set("exclusions", fields.exclusions.value);
        formData.set("type", fields.projectType.value);
        const projectType = searchParams.get("project-type") || idx(projectDetail, _ => _.project.projectDataType)
        formData.set("projectType", projectType);
        formData.set("projectQuoteType", fields.projectQuoteType.value);
        formData.set("profit_margin_value", fields.profitMarginValue.value);

        formData.set("budget", fields.budget.value || 0)
        formData.set("startDate", fields.startDate.value || new Date())

        for (var i = 0; i < fields.attachments.attachmentArr.length; i++) {
            formData.append(
                "attachment[]",
                JSON.stringify(fields.attachments.attachmentArr[i])
            );
        }

        for (var x = 0; x < fields.attachments.value.length; x++) {
            formData.append("attachment", fields.attachments.value[x]);
        }
        return formData;
    };

    const submitForm = async () => {
        try {
            let action = searchParams.get("action");
            let fields = _.cloneDeep(formField);

            let isFormValid = true;
            for (let key in fields) {
                const { error, valid } = handleValidation(fields[key], fields[key].validation);
                if (typeof fields[key] == 'object') {
                    fields[key].touched = true;
                    fields[key].valid = valid;
                    fields[key].error = error;
                }
                isFormValid = isFormValid && valid;
                if (error) dispatch(errorSnackBar(`${key} ${error}`))
            }

            if (!isFormValid) {
                setFormField(prev => ({
                    ...prev,
                    ...fields
                }));
            } else {
                const formData = formatData(fields);

                if (!projectId || (projectId && action === "revise")) {
                    if (projectId && action === "revise") {
                        formData.set("isRevised", true);
                        formData.set("projectId", projectId);
                        formData.set("hasStages", idx(projectDetail, _ => _.project.hasStages) || false);
                    }

                    if (fields.projectQuoteType.value == "noquote") {
                        formData.set("hasStages", false);
                        formData.set("status", "NOT_STARTED");
                        formData.set("type", "PROJECT");
                    } else {
                        formData.set("status", "PENDING");
                        formData.set("type", "TENDER");
                    }

                    dispatch(addProject(formData)).then(async (res) => {

                        if (projectId && action === "revise") {
                            // await this.props.history(`${res.id}`);
                        } else {
                            stepTwo(res);
                            setAddProjectState(prev => ({
                                ...prev,
                                showTemplateModal: true,
                            }));
                        }
                        if ((res.stages || []).length == 0 && res.id && action != "revise") {
                            const newformData = new FormData();
                            newformData.set("name", "DummyStage");
                            newformData.set("isDummyStage", true);
                            await dispatch(addStage(newformData, res.id, true));
                        }
                        if (formField.projectQuoteType.value == "noquote") {
                            navigate(`/projects/view/${res.id}`);
                        } else if (formField.projectQuoteType.value == "quote") {
                            navigate(`/projects/edit/${res.id}?project-step=TENDERVIEW`);
                        }
                    }).catch(e => {
                        console.error(e)
                    });
                } else {
                    dispatch(editProject(formData, projectId)).then((res) => {
                        navigate(`/projects/view/${Cookies.get('projectId')}`);
                        stepTwo(res);
                        getProjectData({ projectId: res.id, dispatch })
                    }).catch(e => console.log(e));
                }
            }
        } catch (e) {
            console.error(e)
        }
    };

    const projectRedirect = async () => {
        try {
            if ((idx(projectDetail, _ => _.project.stagesData) || []).length == 0 && idx(projectDetail, _ => _.project.projectId) == projectId) {
                const newformData = new FormData();
                newformData.set("name", "DummyStage");
                newformData.set("isDummyStage", true);
                await dispatch(addStage(newformData, projectId, true));
            }
        } catch (e) {
            console.error(e)
        }
        if (formField.projectQuoteType.value == "noquote") {
            navigate(`/projects/view/${projectId}`);
        } else if (formField.projectQuoteType.value == "quote") {
            navigate(`/projects/edit/${projectId}?project-step=TENDERVIEW`);
        }
    };

    const stepTwo = (res) => {
        if (res.type === "PROJECT") {
            setAddProjectState(prev => ({
                ...prev,
                currentStep: 1,
                projectNextStep: true,
            }));
        } else {
            setCookie({ name: 'projectId', value: res.id });
            setAddProjectState(prev => ({
                ...prev,
                currentStep: 1,
            }));
        }
    };

    const convertToProject = () => {
        let fields = _.cloneDeep(formField);
        let isFormValid = true;
        for (let key in fields) {
            const { error, valid } = handleValidation(fields[key], fields[key].validation);
            if (typeof fields[key] == 'object') {
                fields[key].touched = true;
                fields[key].valid = valid;
                fields[key].error = error;
            }
            isFormValid = isFormValid && valid;
        }

        if (!isFormValid) {
            setFormField(prev => ({
                ...prev,
                ...fields
            }));
        } else {
            const formData = formatData(fields);
            formData.set("status", "IN_PROGRESS");
            formData.set("type", "PROJECT");
            dispatch(editProject(formData, projectId)).then((res) => {
                props.handleClose();
                const projectType = searchParams.get("project-type")
                navigate(`/projects?project-type=${projectType || props.projectType}`);
            }).catch(e => console.error(e));
        }
    };

    const chooseTemplate = async (template) => {
        const _projectId = projectId || Cookies.get("projectId");
        if (template === null) {
            setAddProjectState(prev => ({
                ...prev,
                showTemplateModal: false
            }));
            projectRedirect();
        } else {
            dispatch(addContentTemplate({
                templateId: template.id,
                projectId: _projectId,
            })).then((res) => {
                setAddProjectState(prev => ({
                    ...prev,
                    showTemplateModal: false,
                }));
            }).catch((error) => {
                setAddProjectState(prev => ({
                    ...prev,
                    showTemplateModal: true
                }));
            });
        }
    };

    return (
        <>
            <div className="purchase-order-block">

                {!addProjectState.convertTender && addProjectState.currentStep === 0 && (<>
                    <h2 className="content-heading text-uppercase">
                        {addProjectState.edit ? "Edit a Project" : "Add a Project"}
                    </h2>
                    <div className="col-12 breadcrumb-block p-0">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => cancelHandler()}>PROJECT </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {addProjectState.edit ? "Edit a Project" : "Add a Project"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </>)}

                {addProjectState.currentStep === 0 && (<>
                    <div className="row">
                        <div className="col-lg-4 mt-3">
                            <CustomInput
                                {...formField.projectName}
                                label={'Project Name'}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>
                        {addProjectState.edit && (<div className="col-lg-4 mt-3">
                            <SelectInput
                                {...formField.category}
                                label={'Project Categoory'}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>)}
                        <div className="col-lg-4 mt-3">
                            <SelectInput
                                {...formField.projectQuoteType}
                                label={'Project Type'}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>
                        <div className="col-lg-4 mt-3">
                            <SelectInput
                                {...formField.manager}
                                label={'Project Manager'}
                                disabled={!permissions.writePermission}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div className="d-flex">
                                <SelectInput
                                    {...formField.customer}
                                    items={idx(customerData, _ => _.dropdown)}
                                    label={'Customer'}
                                    disabled={!permissions.writePermission}
                                    onChange={(e) => inputChangeCustomer(e)}
                                />
                                {!projectId && permissions.writePermission && (<span className="download-icon addicon ms-2 mt-auto">
                                    <AddIcon onClick={() => addNewCustomer()} />
                                </span>)}
                            </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div className="d-flex">
                                <SelectInput
                                    {...formField.contact}
                                    items={getContactDropdown()}
                                    label={'Customer Contact'}
                                    disabled={!permissions.writePermission}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                                {!projectId && permissions.writePermission && (<span className="download-icon addicon ms-2 mt-auto">
                                    <AddIcon onClick={() => addNewCustomer(formField.customer.value)} />
                                </span>)}
                            </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div className="d-flex">
                                <SelectInput
                                    {...formField.address}
                                    items={getAddressDropdown()}
                                    label={'Address'}
                                    disabled={!permissions.writePermission}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                                {!projectId && permissions.writePermission && (<span className="download-icon addicon ms-2 mt-auto">
                                    <AddIcon onClick={() => addNewCustomer(formField.customer.value)} />
                                </span>)}
                            </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <SelectInput
                                {...formField.level}
                                label={'Cost/Sales Level'}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>

                        {!addProjectState.convertTender && (<div className="col-lg-4 mt-3">
                            <DatePickerInput
                                {...formField.date}
                                disabled={!permissions.writePermission}
                                onChange={(e) => inputChangeDateHandler(e, "date")}
                            />
                        </div>)}

                        {(idx(projectDetail, _ => _.project.projectType) === 'PROJECT' || addProjectState.convertTender) &&
                            <div className="col-lg-4 mt-3">
                                <AmountInput
                                    {...formField.budget}
                                    disabled={!permissions.writePermission || isConvertToProject()}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                            </div>
                        }

                        <div className="col-lg-4 mt-3 text-left">
                            <CustomInput
                                {...formField.profitMarginValue}
                                disabled={!permissions.writePermission}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                            <p className="help-text mt-1 mb-0"> Default profit margin added to Material and Sub-Contractors</p>
                        </div>
                        {/* <div className="col-lg-4 mt-3">
                                <SelectInput
                                    {...formField.projectType}
                                    label={'Project Type'}
                                    disabled={!permissions.writePermission}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mt-3">
                            <TextAreaInput
                                {...formField.description}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>
                        {formField.projectQuoteType.value !== "noquote" && (<div className="col-lg-6 col-md-6 mt-3">
                            <TextAreaInput
                                {...formField.exclusions}
                                disabled={!permissions.writePermission || isConvertToProject()}
                                onChange={(e) => inputChangeHandler(e)}
                            />
                        </div>)}
                    </div>
                    <div className="row">
                        {permissions.writePermission && (<div className="col-lg-6 col-md-6 mt-3 fileupload-section">
                            <div className="file-section-part position-relative h-auto px-0">
                                {/* <label className="label-header">Attachment</label> */}
                                <div className="file-part">
                                    <FileUploader
                                        title="Attach Project Documents"
                                        fileHandler={(files) =>
                                            inputChangeHandler({
                                                target: {
                                                    files,
                                                    type: "file",
                                                    name: "attachments",
                                                },
                                            })
                                        }
                                    />
                                    <label htmlFor="#file-section"></label>
                                </div>
                                <input type="file" id="file-section"></input>
                            </div>
                        </div>)}
                        <div className="col-lg-6 col-md-6 mt-3">
                            <div className="form-group">
                                <div className="input-block">
                                    <label className="input-label">Uploaded Documents</label>
                                    <p className="m-0 doc-info text-left input-block-level">
                                        {renderFiles()}
                                        {renderAttachments()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {!addProjectState.convertTender && (<div className="col-12 d-flex mt-3">
                            <Button onClick={() => submitForm()} className="ms-auto me-3 secondarybtn">
                                {(searchParams.get("action")) === "revise" ? "save" : "Next"}
                            </Button>
                            <Button onClick={() => cancelHandler()} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
                        </div>)}
                        {addProjectState.convertTender && (<div className="col-12 d-flex mt-3">
                            <Button onClick={convertToProject} className="ms-auto me-3 secondarybtn">
                                Convert
                            </Button>
                            <Button onClick={props.handleClose} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
                        </div>)}
                    </div>
                </>)}

                {addProjectState.currentStep === 1 && !addProjectState.projectNextStep ? (<>
                    {addProjectState.showTemplateModal ? (
                        <ShowTemplates
                            chooseTemplate={chooseTemplate}
                            open={true}
                        />
                    ) : (
                        <AddProjectStepTwo
                            {...props}
                            userAccessControl={permissions.userAccessControl}
                        />
                    )}
                </>) :
                    addProjectState.currentStep === 1 && addProjectState.projectNextStep ? (
                        <ViewProject
                            {...props}
                            userAccessControl={permissions.userAccessControl}
                        />
                    ) : ""
                }

            </div>

        </>
    );
}
