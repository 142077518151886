import React, { useEffect, useState } from 'react';
import './../modal/modal.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { getItemTemplates } from 'src/actions/template-management.action';
import CustomTable from '../table/table';
import Form from 'react-bootstrap/Form'
import { toUSD } from 'src/util/currency-formatter';

function ListItemTemplate({ open, dialogClose, ...props }) {
  const [state, setState] = useState({
    columns: [
      {
        title: "Name", field: "isSelected", class: "w-auto fw-bold",
        render: (rowData) => (
          <div className="table-checkbox table-checkbox-ch text-left">
            <Form.Check
              id={rowData.id}
              type={'checkbox'}
              label={rowData.description}
              value={rowData.isSelected}
              checked={Boolean(rowData.isSelected)}
              onChange={(e) => selectHandler(rowData.id, e.target.checked)}
            />
          </div>
        )
      },
      {
        title: "Material", field: "material_total_cost", class: 'fw-bold text-right',
        render: (rowData) => <>{toUSD(rowData.material_total_cost)}</>
      },
      {
        title: "Resource", field: "labor_total_cost", class: 'fw-bold text-right',
        render: (rowData) => <>{toUSD(rowData.labor_total_cost)}</>
      },
      {
        title: "Contractor", field: "subcontractor_total_cost", class: 'fw-bold text-right',
        render: (rowData) => <>{toUSD(rowData.subcontractor_total_cost)}</>
      },
      {
        title: "Total", field: "total", class: 'fw-bold text-right',
        render: (rowData) => <>{toUSD(parseFloat(rowData.material_total_cost || 0) + parseFloat(rowData.labor_total_cost || 0) + parseFloat(rowData.subcontractor_total_cost || 0))}</>
      },
      {
        title: "Quantity", field: "", class: 'fw-bold',
        render: (rowData) => (
          <div className="input-block input-block-sm mt-0">
            <input
              className="form-control mt-0" type="number" placeholder=""
              name='selectedQty'
              value={rowData.selectedQty}
              onChange={(e) => {
                if (e.target.value >= 0) changeHandler(rowData.id, e.target)
              }}
            />
          </div>
        )
      },
    ],
    itemTemplates: [],
    error: ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    dispatch(getItemTemplates()).then((res) => {
      let items = res.map(item => {
        return { ...item, selectedQty: '', isSelected: false }
      })
      setState(prev => ({
        ...prev,
        itemTemplates: items
      }));
    }).catch(e => console.error(e))
  }

  const selectHandler = (id, checked) => {
    setState(prev => ({
      ...prev,
      itemTemplates: prev.itemTemplates.map(item => {
        if (item.id == id) {
          item.isSelected = checked;
          item.selectedQty = checked ? (Number(item.selectedQty) > 0 ? item.selectedQty : 1) : ''
        }
        return item
      })
    }));
  }

  const changeHandler = (id, { name, value }) => {
    setState(prev => ({
      ...prev,
      itemTemplates: prev.itemTemplates.map(item => {
        if (item.id == id) item[name] = value;
        return item
      })
    }));
  }

  const closeDialog = () => {
    if (dialogClose) dialogClose()
  }

  const addHandler = () => {
    let { itemTemplates = [] } = state;
    let selected = itemTemplates.filter(item => item.isSelected && parseInt(item.selectedQty) > 0)
    if (selected.length < 1) {
      setState(prev => ({
        ...prev,
        error: 'Please select atleast one Item with quantity of minimum 1'
      }));
    } else {
      setState(prev => ({
        ...prev,
        error: ''
      }));
      props.addHandler(selected)
    }
  }

  return (
    <Modal
      show={open}
      onHide={closeDialog}
      dialogClassName="modal-70w create-modal"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Select Item Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="purchase-order-block mt-3">
          <CustomTable
            exportButton={false}
            columns={state.columns}
            data={state.itemTemplates}
          />
          {state.error && <div className="col-12 d-flex text-danger">
            {state.error}
          </div>}
          <div className="col-12 d-flex mt-3">
            <Button onClick={addHandler} className="ms-auto me-3 secondarybtn">Add</Button>
            <Button onClick={closeDialog} className="secondarybtn cancelbtn me-auto">CANCEL</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ListItemTemplate;
