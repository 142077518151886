import idx from "idx"

export const loadPermissions = (projectCategories = [], defaults = {}) => {
    return [
        {
            name: 'dashboard',
            display: 'Dashboard',
            read: { value: defaults.dashboard > 0 ?? false },
            write: { hidden: true },
            additional: [{ display: 'Projects', name: 'dashboardProjects', value: defaults.dashboardProjects === 2 ?? false }]
        },
        ...projectCategories.map(item => {
            return {
                name: `project${item.type}`,
                display: `Project ${item.type}`,
                read: { value: defaults[`project${item.type}`] > 0 ?? false },
                write: { value: defaults[`project${item.type}`] === 2 ?? false },
                additional: [{ display: `Project ${item.type} Finance`, name: `project${item.type}Finance`, value: defaults[`project${item.type}Finance`] === 2 ?? false }]
            }
        }),
        {
            name: 'customer',
            display: 'Customer Management',
            read: { value: defaults.customer > 0 ?? false },
            write: { value: defaults.customer === 2 ?? false },
        },
        {
            name: 'supplier',
            display: 'Suppliers Management',
            read: { value: defaults.supplier > 0 ?? false },
            write: { value: defaults.supplier === 2 ?? false },
        },
        {
            name: 'contractor',
            display: 'Contractors Management',
            read: { value: defaults.contractor > 0 ?? false },
            write: { value: defaults.contractor === 2 ?? false },
        },
        {
            name: 'finance',
            display: 'Finance',
            read: { value: defaults.finance > 0 ?? false },
            write: { value: defaults.finance === 2 ?? false },
        },
        {
            name: 'catalog',
            display: 'Resource & Material Catalogs',
            read: { value: defaults.catalog > 0 ?? false },
            write: { value: defaults.catalog === 2 ?? false },
        },
        {
            name: 'template',
            display: 'Template Management',
            read: { value: defaults.template > 0 ?? false },
            write: { value: defaults.template === 2 ?? false },
        },
        {
            name: 'inventory',
            display: 'Inventory',
            read: { value: defaults.inventory > 0 ?? false },
            write: { value: defaults.inventory === 2 ?? false },
        },
        {
            name: 'asset',
            display: 'Asset Management',
            read: { value: defaults.asset > 0 ?? false },
            write: { value: defaults.asset === 2 ?? false },
        },
        {
            name: 'workforce',
            display: 'Workforce Management',
            read: { value: defaults.workforce > 0 || true, disabled: true },
            write: { value: defaults.workforce === 2 ?? false },
            additional: [{ display: 'Timesheet Approver', name: 'timesheetApprover', value: defaults.timesheetApprover === 2 ?? false }]
        },
        {
            name: 'user',
            display: 'User Management',
            read: { value: defaults.user > 0 ?? false },
            write: { value: defaults.user === 2 ?? false },
            additional: [{ display: 'Administrator', name: 'userAdmin', value: defaults.userAdmin === 2 ?? false }]
        },
        {
            name: 'globalSetting',
            display: 'Global Settings Management',
            read: { value: defaults.globalSetting > 0 ?? false },
            write: { value: defaults.globalSetting === 2 ?? false },
        },
    ]
}

export const minifyPermissions = (permissions = []) => {
    let simplified = {}
    permissions.map(item => {
        let value = 0, additional = idx(item, _ => _.additional) || [];
        if (idx(item, _ => _.write.value)) {
            value = 2;
        } else if (idx(item, _ => _.read.value)) {
            value = 1;
        }
        additional.map(aItem => {
            simplified[aItem.name] = aItem.value ? 2 : 0;
        })
        simplified[item.name] = value;

    })
    return simplified;
}