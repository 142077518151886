import React from "react";
import Table from 'react-bootstrap/Table';
import '../../project-management/scss/task.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import idx from 'idx'
import { laborObjFields } from "src/constants/fields.constant";
import { updateItemAction, updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import CustomInput from "src/components/inputs/new-input";
import { useSelector } from "react-redux";
import { toUSD } from "src/util/currency-formatter";

const filter = createFilterOptions();

export default function Labor({ labor = [], newLabor = [], total = {}, subTaskArrayIndex, isDisabled, handleAddLabor, ...props }) {

    const projectDetail = useSelector(state => state.projectDetail) || {}
    const resources = useSelector(state => state.resources)
    const dispatch = useDispatch()

    const getLabor = (id) => {
        let allLabors = idx(resources, _ => _.list) || [];
        return allLabors.find((labor) => labor.id === id) || {};
    }

    const filterLabor = (id) => {
        let laborsList = idx(projectDetail, _ => _.labors) || [];
        let labor = laborsList.find((labor) => labor.id === id);
        if (!labor) {
            labor = getLabor(id)
            labor = _.cloneDeep(labor)
            labor['calloutCost'] = 0;
            labor['averageCost'] = labor.cost;
            labor['skillDescription'] = ''
        } else {
            if (!labor['averageCost']) {
                let _temp = getLabor(id)
                labor = _.cloneDeep(labor)
                labor['averageCost'] = _temp.cost
            }
        }
        return labor;
    };

    // const updateLabor = (value, index) => {
    //     const selectedLabor = filterLabor(value.value);
    //     const labor = _.cloneDeep(laborObjFields);
    //     labor.name.value = selectedLabor.skill;
    //     labor.id.value = selectedLabor.id;
    //     labor.skillDescription.value = selectedLabor.skillDescription;
    //     labor.costPerItem.value = selectedLabor.cost || 0;
    //     labor.calloutCost.value = parseFloat(selectedLabor.calloutCost || 0);
    //     labor.labourCost.value = parseFloat(selectedLabor.averageCost || 0);
    //     labor.travelCost.value = 0;
    //     labor.qty.value = 1;
    //     labor.total.value = parseFloat(labor.qty.value) * parseFloat(labor.costPerItem.value)

    //     const { subTaskArray } = props;
    //     let _temp = _.cloneDeep(subTaskArray)
    //     _temp[subTaskArrayIndex]["labor"][index] = labor;
    //     dispatch(updateSubTaskAction(_temp))
    // };

    const laborChangeHandler = (e, index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        const labor = _temp[subTaskArrayIndex]["labor"][index];

        if (e.target.name === "qty") {
            const quantity = parseFloat(e.target.value) || 0;
            const costPerItem = parseFloat(labor.costPerItem.value) || 0;
            const laborCost = parseFloat(labor.labor_cost) || 0;
            labor.qty.value = quantity;
            labor.total.value = quantity * costPerItem;
            const labor_Profit = costPerItem - laborCost;
            labor.labor_profit = labor_Profit * quantity;
            _temp.labor_profit = labor.labor_profit;
        }

        if (e.target.name === "costPerItem") {
            const quantity = parseFloat(e.target.value) || 0;
            const costPerItem = parseFloat(e.target.value) || 0;
            const laborCost = parseFloat(labor.labor_cost) || 0;
            labor.costPerItem.value = costPerItem;
            labor.total.value = (parseInt(labor.qty.value) || 0) * costPerItem;
            const labor_Profit = costPerItem - laborCost;
            labor.labor_profit = labor_Profit * quantity;
            _temp.labor_profit = labor.labor_profit;
        }

        const value = e.target.value.replace("-", "");
        _temp[subTaskArrayIndex]["labor"][index][e.target.name].value = value;
        _temp[subTaskArrayIndex]["labor"][index]["total"].value =
            (parseFloat(_temp[subTaskArrayIndex]["labor"][index]["qty"].value) || 0) *
            (parseFloat(_temp[subTaskArrayIndex]["labor"][index]["costPerItem"].value) || 0);
        dispatch(updateSubTaskAction(_temp))
    };

    const removeLabor = (index) => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        _temp[subTaskArrayIndex]["labor"].splice(index, 1);
        dispatch(updateSubTaskAction(_temp))
    };

    const addLabor = (value, tmpId) => {
        const selectedLabor = filterLabor(value.value);
        const labor = _.cloneDeep(laborObjFields);
        labor.name.value = selectedLabor.skill;
        labor.id.value = selectedLabor.id;
        labor.skillDescription.value = selectedLabor.skillDescription;
        labor.costPerItem.value = selectedLabor.cost || 0;
        labor.calloutCost.value = parseFloat(selectedLabor.calloutCost || 0);
        labor.labourCost.value = parseFloat(selectedLabor.averageCost || 0);
        labor.travelCost.value = 0;
        labor.labor_cost = selectedLabor.averageCost;
        labor.labor_profit = 0;
        labor.qty.value = 1;
        labor.total.value = parseFloat(labor.qty.value) * parseFloat(labor.costPerItem.value);

        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        let labors = getNewLabors()

        _temp[subTaskArrayIndex]["labor"].push(labor);
        _temp[subTaskArrayIndex]["newLabor"] = labors.filter(item => item.id != tmpId)

        dispatch(updateSubTaskAction(_temp));
    };

    const getOptions = () => {
        return (idx(resources, _ => _.active) || []).map((labor) => {
            return { key: labor.skill, value: labor.id }
        })
    }

    const getNewLabors = () => {
        const { subTaskArray } = props;
        let _temp = _.cloneDeep(subTaskArray)
        return _temp[subTaskArrayIndex]["newLabor"] || []
    }
    const handleRemoveLabor = (tmpId) => {
        let labors = getNewLabors()
        labors = labors.filter(item => item.id != tmpId)
        dispatch(updateItemAction({ labor: labors, index: subTaskArrayIndex }));
    }

    return (
        <>
            {[...labor, ...newLabor].length > 0 && <div className="mb-2">
                <div className="border border-bottom-0 d-flex px-2 py-1 gap-3">
                    <h2 className="py-1 tbl-heading">Resource List</h2>
                </div>
                <div className="material-list-block">
                    <div className="table-responsive">
                        <Table bordered className="table-create table-material tbl-fixed">
                            <thead>
                                <tr>
                                    <th className="">Resource(s)</th>
                                    <th className="cost-width">Cost($)</th>
                                    <th className="cost-width">Sale Amount($)</th>
                                    <th className="qty-width">Quantity</th>
                                    <th className="text-right total-width">Total ($)</th>
                                    <th className="text-right qty-width">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {labor.map((_labor, laborIndex) => {
                                    if (!_labor) return;
                                    return (
                                        <tr key={laborIndex}>
                                            <td>
                                                <p title={idx(_labor, _ => _.name.value)} className="text-truncate m-0 table-text-sm">
                                                    {idx(_labor, _ => _.name.value)}
                                                </p>
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={true}
                                                    value={idx(_labor, _ => _.labourCost.value) || _labor.labor_cost || 0}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={isDisabled}
                                                    {..._labor.costPerItem}
                                                    onChange={(e) => laborChangeHandler(e, laborIndex)}
                                                    min={1}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    className="input-block-sm"
                                                    disabled={isDisabled}
                                                    {..._labor.qty}
                                                    onChange={(e) => laborChangeHandler(e, laborIndex)}
                                                    min={1}
                                                />
                                            </td>
                                            <td className="text-right">
                                                {toUSD(idx(_labor, _ => _.total.value))}
                                            </td>
                                            <td className="text-right">
                                                {!isDisabled && <DeleteIcon
                                                    className="deleteicon"
                                                    onClick={() => removeLabor(laborIndex)}
                                                />}
                                            </td>
                                        </tr>
                                    )
                                })}

                                {newLabor.map((_labor) => (
                                    <tr key={_labor.id}>
                                        <td>
                                            <div className="input-block-sm autocomplete-block autocomplete-block-ch">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === "string") {
                                                            newValue = { inputValue: newValue, };
                                                        } else if (newValue && newValue.inputValue) {
                                                        } else if (newValue) {
                                                            addLabor(newValue, _labor.id);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => filter(options, params)}
                                                    selectOnFocus
                                                    handleHomeEndKeys
                                                    options={getOptions()}
                                                    getOptionLabel={(option) => typeof option === "string" ? option : option.key}
                                                    renderOption={(props, option) => <li {...props}>{option.key}</li>}
                                                    renderInput={(params) => (<TextField {...params} variant='standard' label='Resource' />)}
                                                    disablePortal={true}
                                                    id="combo-box-demo"
                                                />
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right table-text-sm"></td>
                                        <td className="text-right">
                                            {!isDisabled && <DeleteIcon
                                                className="deleteicon"
                                                onClick={() => handleRemoveLabor(_labor.id)}
                                            />}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right fs-6 py-1">
                                        <span>
                                            {toUSD(idx(total, _ => _.labor.sale))}
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>}
        </>
    );

}
