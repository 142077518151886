import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { INVENTORY } from "./../../constants/route-paths";
import { getItem } from "./../../actions/inventory.action";
import { getPOList } from "./../../actions/project-management.action";
import { numberFormat } from "../../util/currency-formatter";
import _ from "lodash";
import { withRouter } from "../../util/with-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';

class POStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: "",
      transactions: [],
      poList: [],
    };
  }

  componentDidMount() {
    const itemId = this.props.params.itemId;
    this.setState({ itemId });
    this.getPOList();
  }

  getPOList = () => {
    this.props.getPOList({}).then((poList) => {
      const POs = [];
      Array.isArray(poList) &&
        poList.forEach((po) => {
          const data = {};
          let totalAmount = 0;
          let totalQty = 0;
          Array.isArray(po.items) &&
            po.items.forEach((item) => {
              if (item.id === this.state.itemId) {
                totalAmount +=
                  parseFloat(item.qty || 0) * parseFloat(item.cost || 0);
                totalQty += parseFloat(item.qty || 0);
                data.poId = po.id;
                data.allocatedQty =
                  parseFloat(data.allocatedQty || 0) +
                  parseFloat(item.allocatedQty || 0);
                data.receivedQty =
                  parseFloat(data.receivedQty || 0) +
                  parseFloat(item.receivedQty || 0);
              }
            });

          if (!_.isEmpty(data)) {
            data.itemCost = parseFloat(totalAmount / totalQty);
            POs.push(data);
          }
        });
      this.setState({
        poList: POs,
      });
    });
  };

  cancelHandler = () => {
    this.props.history(`/${INVENTORY}`);
  };

  render() {
    const { poList } = this.state;
    return (
      <div className="purchase-order-block">

        <h2 className="content-heading text-uppercase">View PO Stock</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={this.cancelHandler}>INVENTORY </Breadcrumb.Item>
            <Breadcrumb.Item active> View PO Stock</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="material-list-block mt-3">
          <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch w-100-lg">
              <thead>
                <tr>
                  <th className="w-auto">PO</th>
                  <th className="w-auto">Submitted Quote</th>
                  <th>Received Qty</th>
                  <th>Allocated Qty</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                {poList.map((po, subtaskindex) => (
                  <tr key={subtaskindex}>
                    <td>{po.poId}</td>
                    <td>{numberFormat(po.itemCost)}</td>
                    <td>{po.receivedQty}</td>
                    <td>{po.allocatedQty}</td>
                    <td>{po.receivedQty - po.allocatedQty}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getItem: bindActionCreators(getItem, dispatch),
  getPOList: bindActionCreators(getPOList, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(POStock)
);
