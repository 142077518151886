import { createSlice } from '@reduxjs/toolkit'

// Slice
const initial = {
    list: [],
    active: []
}

const slice = createSlice({
    name: 'resources',
    initialState: {
        ...initial,
    },
    reducers: {
        addResources: (state, action) => {
            let { resources = [] } = action.payload;
            state.list = resources;
            state.active = resources.filter(item => item.status == 'ACTIVE')
        },
        clearResources: (state, action) => {
            state.list = [];
            state.active = [];
        }
    },
});

export default slice.reducer

// Actions
const { addResources, clearResources } = slice.actions

export const addResourcesAction = (data) => async dispatch => {
    try {

        dispatch(addResources({ resources: data }));
    } catch (e) {
        return console.error(e);
    }
}

export const clearResourcesAction = (data) => async dispatch => {
    dispatch(clearResources());
}