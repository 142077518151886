import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getvendorsPOList,
  downloadProjectPO,
  getProject,
} from "../../actions/project-management.action";
import {
  getvendorsPOList as getvendorsServicePOList,
  downloadServicePO,
} from "../../actions/service.action";
import { warningSnackBar } from "../../actions/common.action";
import PayInvoice from "./pay-invoice";
import { PROJECT_MANAGEMENT, VENDOR_PAYMENT } from "../../constants/route-paths";
import { format } from "date-fns";
import VendorPaymentFilter from "./vendor-payment-filter";
import { numberFormat } from "../../util/currency-formatter";
import DownloadDialog from "../../components/download-dialog";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { withRouter } from "../../util/with-router";
import { getGlobalSettingTemplateTypes } from "./../../actions/global-setting-template.action";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../project-management/scss/task.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Popover from 'react-bootstrap/Popover';
import idx from "idx";
import CustomTable from "src/components/table/table";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPayInvoice: false,
      columns: [],
      list: [],
      submitDialog: false,
      discardDialog: false,
      supplierCount: null,
      subContractorCount: null,
      vendorType: "ALL",
      invoiceData: {},
      workType: "",
      projectId: null,
      suppliers: [],
      templateOptions: [],
      subContractors: [],
      allInvoices: [],
      breadCumbText: null,
      showFilter: false,
      downloadData: {
        open: false,
        id: "",
        template: "",
      },
      projectData: null
    };
  }

  // prepairing data for tree structured material table
  prepareTableData = (list) => {
    const temp = {};
    list.forEach((po) => {
      if (temp[po.vendorId]) {
        const _po = { ...po, parentId: po.vendorId, vendorId: undefined };
        _po.vendorId = Math.floor(100 + Math.random() * 10000 + 1);
        temp[po.vendorId].push(_po);
      } else {
        temp[po.vendorId] = [po];
      }
    });
    let formattedList = [];
    for (let key in temp) {
      formattedList = formattedList.concat(temp[key]);
    }
    return formattedList;
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project");
    const poId = urlParams.get("poId");

    let workType = "project";
    let breadCumbText = null;
    if (projectId) {
      workType = "project";
      breadCumbText = "Project";
    }

    this.setState({ workType, projectId, poId, breadCumbText }, () =>
      this.fetchList()
    );

    if (!this.state.projectData && projectId) {
      this.props.getProject(projectId).then((projectData) => {
        this.setState({
          projectData: projectData
        });
      })
    }
  }

  getAssetList = async (selectedCategory = "ALL") => {
    return null;
  };

  handleMenu = (rowData) => {
    this.setState({
      invoiceId: rowData.invoiceId,
      invoiceData: {
        poId: rowData.poId,
        poNumber: rowData.poNumber,
        isSubContractor: rowData.vendorType === "sub-contractor",
      },
    });
  };

  handlePayInvoiceModal = (status, allProjects = false) => {
    if (!status) this.fetchList();
    this.setState((prevState) => ({
      showPayInvoice: status,
      invoiceData: { ...(allProjects ? {} : { ...prevState.invoiceData }) },
    }));
  };

  fetchList = async ({ pageNo, pageSize, vendorType, startDate, endDate } = {}) => {
    const params = { pageNo, pageSize, vendorType, startDate, endDate, };
    let { workType, projectId, poId } = this.state;
    if (projectId) params.projectId = projectId;
    if (poId) params.poId = poId;

    this.setState({ vendorType: vendorType || "ALL" });

    let list;
    let vendorTypesCount;
    if (workType === "service") ({ list, vendorTypesCount } = await this.props.getvendorsServicePOList(params));
    else ({ list, vendorTypesCount } = await this.props.getvendorsPOList(params));

    const suppliers = (Array.isArray(list) && list.filter(({ vendorType }) => vendorType === "Supplier")) || [];
    const subContractors = (Array.isArray(list) && list.filter(({ vendorType }) => vendorType === "sub-contractor")) || [];
    const allInvoices = (Array.isArray(list) && list) || [];
    const supplierCount = vendorTypesCount.suppliersCount || 0;
    const subContractorCount = vendorTypesCount.subContractorsCount || 0;
    const columns = [
      { title: "Vendor Name", field: "vendorName", render: (rowData) => rowData.vendorName },
      {
        title: "Vendor Type",
        field: "vendorType",
        // lookup: { "sub-contractor": "Sub-Contractor", Supplier: "Supplier" },
        render: (rowData) => rowData.vendorType == 'sub-contractor' ? "Sub-Contractor" : rowData.vendorType
      },
      {
        title: "Invoice No",
        field: "invoiceNo",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.viewInvoice(rowData)}
          >
            {rowData.invoiceNo}
          </span>
        ),
      },
      { title: "PO/WO Number", field: "poNumber", render: (rowData) => rowData.poNumber },
      { title: "Project/Job", field: "projectName", render: (rowData) => rowData.projectName },
      {
        title: "Invoice Date",
        field: "invoiceDate",
        render: (rowData) => (rowData.invoiceDate && format(new Date(rowData.invoiceDate), "dd/MM/yyyy")) || "",
      },
      {
        title: "Amount Paid",
        field: "amountPaid",
        render: (rowData) => `${numberFormat(rowData.amountPaid)}`,
        class: 'text-right',
      },
      {
        title: "Retained Amount",
        field: "retainedAmount",
        render: (rowData) => `${numberFormat(rowData.retainedAmount)}`,
        class: 'text-right',
      },
      {
        title: "Received By",
        field: "userName",
        render: (rowData) => rowData.userName
      },
    ];
    list.forEach((item, index) => {
      item["id"] = index;
      return item;
    });
    this.setState({
      supplierCount,
      subContractorCount,
      columns,
      list,
      suppliers,
      subContractors,
      allInvoices,
    });
  };

  changeWorkType = (workType) => {
    this.setState({ workType }, () => {
      if (this.props[`${workType}Module`] === false) {
        this.props.warningSnackBar(`This user does not have access for ${workType === "service" ? "Services" : "any Projects"}`);
        return false;
      }
      this.fetchList({});
    });
  };

  viewPO = () => {
    const { workType, invoiceId, projectId, poId } = this.state;
    this.props.history(`/${VENDOR_PAYMENT}/view/${invoiceId}${`?type=${workType}`}${projectId ? `&id=${projectId}` : ""}${poId ? `&poId=${poId}` : ""}`);
  };

  showFilterHandler = () => {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  };

  dateChangeFilter = (startDate, endDate) => {
    this.fetchList({ startDate, endDate });
  };

  changeFilter = (vendorType) => {
    const { suppliers, subContractors, allInvoices } = this.state;
    let list = [];

    if (vendorType === "Supplier") {
      list = suppliers;
    } else if (vendorType === "sub-contractor") {
      list = subContractors;
    } else if (vendorType === "ALL") {
      list = allInvoices;
    }

    list = this.prepareTableData(list);

    this.setState({ vendorType, list });
  };
  gettemplate = async (type) => {
    let templateOptions = this.state;
    let newdata = [];
    let projectId = this.props.params.projectId;
    const templateData = await this.props.getGlobalSettingTemplateTypes(
      type,
      projectId
    );
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    this.setState({ templateOptions });
  };
  handleDownloadDialog = (id = "", template = "") => {
    const { downloadData = {}, invoiceId } = this.state;
    downloadData.open = !downloadData.open;
    downloadData.id = id || invoiceId;
    // downloadData.template = "subcontractor_payment_schedule";
    this.gettemplate("subcontractor_payment_schedule");
    this.setState({ downloadData });
  };

  downloadFile = async (fileFormat = "pdf", templatevalue) => {
    try {
      const { downloadData = {}, workType } = this.state;

      if (fileFormat === "pdf" || fileFormat === "ALL") {
        if (workType === "service") {
          await this.props.downloadServicePO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
        } else {
          await this.props.downloadProjectPO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
        }
      }
      if (fileFormat === "docx" || fileFormat === "ALL") {
        if (workType === "service") {
          await this.props.downloadServicePO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
        } else {
          await this.props.downloadProjectPO(downloadData.id, fileFormat, templatevalue).catch(e => console.error(e));
        }
      }
      this.handleDownloadDialog();
    } catch (e) {
      console.error(e)
    }
  };

  viewInvoice(rowData) {
    this.setState(
      {
        invoiceId: rowData.invoiceId,
        invoiceData: {
          poNumber: rowData.poNumber,
          isSubContractor: rowData.vendorType === "sub-contractor",
        },
      },
      () => {
        this.viewPO();
      }
    );
  }

  ViewProjectList = () => {
    let projectType = idx(this.state, _ => _.projectData.projectType)
    this.props.history(`/${PROJECT_MANAGEMENT}?project-type=${projectType}`);
  }

  ViewProject = () => {
    let { projectId } = this.state;
    this.props.history(`/${PROJECT_MANAGEMENT}/view/${projectId}`);
  }

  render() {
    const {
      columns,
      supplierCount,
      subContractorCount,
      showPayInvoice,
      invoiceData,
      workType,
      projectId,
      templateOptions,
      breadCumbText,
      showFilter,
      downloadData,
      suppliers, subContractors, allInvoices
    } = this.state;
    const { projectModule, writePermission } = this.props;

    return (
      <>
        <DownloadDialog
          {...downloadData}
          downloadFile={this.downloadFile}
          handleDownloadDialog={this.handleDownloadDialog}
          templateOptions={templateOptions}
          downloadModuleName="Vendor Payment"
        />
        {showPayInvoice && (
          <PayInvoice
            {...invoiceData}
            workType={workType}
            handleDownloadDialog={this.handleDownloadDialog}
            handleClose={() => this.handlePayInvoiceModal(false)}
          />
        )}
        {
          <VendorPaymentFilter
            changeFilter={this.dateChangeFilter}
            changeWorkType={this.changeWorkType}
            workType={workType}
            dialogClose={this.showFilterHandler}
            open={showFilter}
          />
        }

        {projectModule === false ? (
          <p>
            You do not currently have access to any Projects. Please
            contact your Administrator if this is required.
          </p>
        ) : (
          <>
            <div className="purchase-order-block">
              <div className="row">
                <div className="w-auto">
                  <h2 className="content-heading text-uppercase">Vendor Invoices</h2>
                  <div className="col-12 breadcrumb-block p-0">
                    <Breadcrumb>
                      {breadCumbText ? (
                        <>
                          <Breadcrumb.Item onClick={this.ViewProjectList}>PROJECTS</Breadcrumb.Item>
                          <Breadcrumb.Item onClick={this.ViewProject}>{idx(this.state, _ => _.projectData.name)}</Breadcrumb.Item>
                          <Breadcrumb.Item active>Vendor Invoices</Breadcrumb.Item>
                        </>)
                        : ''
                        // <Breadcrumb.Item active>Vendor Invoices</Breadcrumb.Item>
                      }
                    </Breadcrumb>
                  </div>
                </div>
                {!projectId && (<div className="w-auto ms-auto mt-auto">
                  {writePermission && (
                    <button
                      className="secondarybtn secondarybtn-outline btn-ch ms-auto btn btn-primary"
                      onClick={() => this.handlePayInvoiceModal(true, true)}
                    >
                      Receive Invoice
                    </button>)}
                  <button
                    onClick={this.showFilterHandler}
                    aria-label="filter"
                    className="btn"
                  >
                    <FilterAltIcon />
                  </button>
                </div>)}
              </div>

              <div className="row">
                <div className="col-12">
                  <Tabs
                    defaultActiveKey="ALL"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="ALL" title={`All (${parseInt(supplierCount) + parseInt(subContractorCount) || 0})`}>
                      <CustomTable
                        columns={columns}
                        data={allInvoices}
                        isAction={true}
                        handleMenu={(data) => this.handleMenu(data)}
                        menu={(data) => (
                          <Popover id="popover-basic">
                            <Popover.Body>
                              <ul className="action-block-list">
                                {writePermission && <li onClick={() => this.handlePayInvoiceModal(true)}>Receive Invoice</li>}
                                <li onClick={() => this.viewPO()}>View Invoice</li>
                                {data.vendorType === "sub-contractor" && (<li onClick={() => this.handleDownloadDialog()}>Download Payment Schedule</li>)}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        )}
                        fileName='Vendor Invoices List'
                      />
                    </Tab>

                    <Tab eventKey="Supplier" title={`Suppliers (${supplierCount})`}>
                      <CustomTable
                        columns={columns}
                        data={suppliers}
                        isAction={true}
                        handleMenu={(data) => this.handleMenu(data)}
                        menu={(data) => (
                          <Popover id="popover-basic">
                            <Popover.Body>
                              <ul className="action-block-list">
                                {writePermission && <li onClick={() => this.handlePayInvoiceModal(true)}>
                                  Receive Invoice
                                </li>}
                                <li onClick={() => this.viewPO()}>
                                  View Invoice
                                </li>
                                {data.vendorType === "sub-contractor" && (<li onClick={() => this.handleDownloadDialog()}>
                                  Download Payment Schedule
                                </li>)}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        )}
                        fileName='Supplier Invoices List'
                      />
                    </Tab>

                    <Tab eventKey="sub-contractor" title={`Contractors (${subContractorCount})`}>
                      <CustomTable
                        columns={columns}
                        data={subContractors}
                        isAction={true}
                        handleMenu={(data) => this.handleMenu(data)}
                        menu={(data) => (
                          <Popover id="popover-basic">
                            <Popover.Body>
                              <ul className="action-block-list">
                                {writePermission && <li onClick={() => this.handlePayInvoiceModal(true)}>
                                  Receive Invoice
                                </li>}
                                <li onClick={() => this.viewPO()}>
                                  View Invoice
                                </li>
                                {data.vendorType === "sub-contractor" && (<li onClick={() => this.handleDownloadDialog()}>
                                  Download Payment Schedule
                                </li>)}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        )}
                        fileName='Contractor Invoices List'
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  projectModule: state.project.projectModule,
  projectDetail: state.projectDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getvendorsPOList: bindActionCreators(getvendorsPOList, dispatch),
  getvendorsServicePOList: bindActionCreators(
    getvendorsServicePOList,
    dispatch
  ),
  getGlobalSettingTemplateTypes: bindActionCreators(
    getGlobalSettingTemplateTypes,
    dispatch
  ),
  downloadProjectPO: bindActionCreators(downloadProjectPO, dispatch),
  downloadServicePO: bindActionCreators(downloadServicePO, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));
