import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/po.scss";
import { errorSnackBar, warningSnackBar } from "src/actions/common.action";
import { CONTRACTORS, WORK_ORDER } from "src/constants/route-paths";
import { deliveryOptions } from "src/constants/fields.constant";
import {
  getProjectList,
  savePurchaseOrder,
  editPurchaseOrder,
  getProject,
  getPoCount,
  getPurchaseOrder,
} from "src/actions/project-management.action";
import idx from "idx";
import _ from "lodash";
import { getVendorList } from "src/actions/vendor-management.action";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SelectInput from "src/components/inputs/select";
import { useNavigate } from "react-router";
import CustomInput from "src/components/inputs/new-input";
import { createWOFields } from "../data/wo";
import FileUploader from "src/components/file-uploader";
import DeleteIcon from '@mui/icons-material/Delete';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import { numberFormat } from "src/util/currency-formatter";
import Button from 'react-bootstrap/Button';
import { fileValidator } from '../../../util/validators/file'

function CreateWorkOrder(props) {

  const [fields, setFields] = useState(createWOFields)
  const [state, setState] = useState({
    stages: [],
    fileToUpload: [],
    hasStages: false,
    tasksList: [],
    taskItems: [],
    tasks: [],
    selectedItems: [],
    edit: false,
    vendorsList: [],
  })

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    await getVendorList();
    await getProjectList();
  }


  const stageHandler = (value, stageIndex, type, preFilled = false) => {
    let stages = _.cloneDeep(state.stages)
    if (type === 'checkbox') {
      stages[stageIndex].selected = value
      stages[stageIndex].tasks.forEach((task, taskIndex) => {
        task.selected = value
        task.subTasks.forEach((subTask, subTaskIndex) => { subTask.selected = value })
      })
    } else if (type === 'quote') {
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].quote = value
    } else if (preFilled) {
      stages[stageIndex].selected = true
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].quote = value
    }
    setState(prev => ({
      ...prev,
      stages: stages
    }));
  }

  const taskHandler = (value, stageIndex, taskIndex, type, preFilled = false) => {
    let stages = _.cloneDeep(state.stages)
    if (type === 'checkbox') {
      stages[stageIndex].tasks[taskIndex].selected = value
      stages[stageIndex].tasks[taskIndex].subTasks.forEach((subTask, subTaskIndex) => {
        subTask.selected = value
      })
    } else if (type === 'quote') {
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].quote = value
    } else if (preFilled) {
      stages[stageIndex].tasks[taskIndex].selected = true
      if (parseFloat(value) < 0) value = 0

      stages[stageIndex].tasks[taskIndex].quote = value
    }
    setState(prev => ({
      ...prev,
      stages: stages
    }));
  }

  const getProjectList = async () => {
    let list = await props.getProjectList(null, "PROJECT");

    let projects = [];
    list && list.forEach(({ name, id }) => projects.push({ key: name, value: id }));
    projects = _.orderBy(projects, [({ key }) => key.toLowerCase()], ["asc"]);

    setFields(prev => ({
      ...prev,
      project: { ...prev.project, items: projects }
    }));
  };

  const getVendorList = async () => {
    const vendorsList = await props.getVendorList("sub-contractor");
    const vendors = (Array.isArray(vendorsList) && vendorsList.map(({ id, businessName }) => ({
      key: businessName,
      value: id,
    }))) || [];
    setFields(prev => ({
      ...prev,
      vendor: { ...prev.vendor, items: vendors },
      deliveryOption: { ...prev.deliveryOption, items: deliveryOptions },
    }));
    setState(prev => ({
      ...prev,
      vendorsList
    }))
  };

  const changeHandler = (type, value) => {
    setFields(prev => ({
      ...prev,
      [type]: { ...prev[type], value: value, error: false, valid: true },
    }));
  };

  const projectChangeHandler = async (value) => {
    const project = await props.getProject(value);
    const stages = idx(project, (_) => _.stages);
    const hasStages = idx(project, (_) => _.hasStages);
    setFields(prev => ({
      ...prev,
      project: { ...prev.project, value: value },
      tasksList: { ...prev.tasksList, value: '', items: [] },
    }));
    setState(prev => ({
      ...prev,
      tasksList: [],
      taskItems: [],
      tasks: [],
      selectedItems: [],
    }))

    getStages(stages, hasStages);
  };

  const getStages = async (stages, hasStages) => {
    let tasksList = [], _tasks = [];
    Array.isArray(stages) && stages.forEach(({ tasks, id, name, description }) => {
      _tasks.push(...tasks);
      Array.isArray(tasks) && tasks.forEach(({ id, name, subTasks, description, material }) => {
        tasksList.push({
          key: name,
          value: id,
          taskDescription: description,
          taskType: "TASK",
        });
      });
    });
    setState(prev => ({
      ...prev,
      tasksList, stages, tasks: _tasks, taskItems: []
    }));
    setFields(prev => ({
      ...prev,
      tasksList: { ...prev.tasksList, items: tasksList, all_items: _tasks },
    }));
  };

  const vendorChangeHandler = (value) => {
    let { vendorsList = [] } = state;
    let vendor = vendorsList.find(item => item.id == value) || {}

    setFields(prev => ({
      ...prev,
      vendor: { ...prev.vendor, value: value, error: false, valid: true },
      street_address: { ...prev.street_address, value: vendor.street_address || '', error: false, valid: true },
      suburb: { ...prev.suburb, value: vendor.suburb || '', error: false, valid: true },
      state: { ...prev.state, value: vendor.state || '', error: false, valid: true },
      postcode: { ...prev.postcode, value: vendor.postcode || '', error: false, valid: true },
      country: { ...prev.country, value: vendor.country || '', error: false, valid: true },
    }));
  };

  const handleValidation = ({ name, value, validation = [] }) => {
    let error = false;
    let valid = true;
    if (validation.includes("required") && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }
    if (validation.includes("optional") && _.isEmpty(value)) {
      return { error: "Optional", valid: true };
    }
    return { error, valid };
  };

  const handleFileUpload = (e) => {
    let { fileToUpload } = state;
    const fileUpload = e.target.files;
    let isValid = true
    if (e.target.files.length > 5) {
      props.errorSnackBar('You can not upload more than 5 files at a time')
    } else {
      Array.isArray(fileUpload) && fileUpload.map(file => {
        const fileValidation = fileValidator({ file, acceptedFileSize: 10 })
        const { validFileSize, validFileType, error } = fileValidation
        if (!validFileSize.valid && !error) {
          isValid = false
          props.errorSnackBar(validFileSize.error)
        }
        else if (!validFileType.valid && !error) {
          isValid = false
          props.errorSnackBar(validFileType.error)
        } else if (error) {
          isValid = false
          props.errorSnackBar(error.error)
        }
      })
    }

    if (!isValid) return

    setState(prev => ({
      ...prev,
      fileToUpload: [...fileToUpload, ...(e.target.files)]
    }));
    e.target.value = ''
  }

  const handleRemoveFile = (index) => {
    let { fileToUpload } = state
    fileToUpload.splice(index, 1)
    setState(prev => ({
      ...prev,
      fileToUpload: fileToUpload
    }));
  }

  const formatItemObj = (data, type) => {
    return {
      taskName: data.name,
      taskDescription: data.description,
      taskId: data.id,
      total: parseFloat(data.quote) || 0,
      taskType: type
    }
  }

  const submitForm = async () => {
    let isFormValid = true;
    let _fields = _.cloneDeep(fields)

    for (let key in _fields) {
      const { error, valid } = handleValidation(_fields[key]);
      _fields[key].touched = true;
      _fields[key].valid = valid;
      _fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!_fields.project.value) {
      props.warningSnackBar("Please Select Project");
    }

    if (!_fields.vendor.value) {
      props.warningSnackBar("Please Select Vendor");
    }
    if (!isFormValid) {
      setFields(_fields)
    } else {
      const { stages, fileToUpload } = state
      const selectedTasks = []
      stages.forEach(stage => {
        if (stage.selected) {
          selectedTasks.push(formatItemObj(stage, 'STAGE'))
        }
        stage.tasks.forEach(task => {
          if (task.selected) {
            selectedTasks.push(formatItemObj(task, 'TASK'))
          }
        })
      })
      const data = new FormData()
      data.set('projectId', fields.project.value)
      data.set('vendorId', fields.vendor.value)
      data.set('quoteNo', fields.quote_no.value)
      data.set('street_address', fields.street_address.value)
      data.set('suburb', fields.suburb.value)
      data.set('state', fields.state.value)
      data.set('postcode', fields.postcode.value)
      data.set('country', fields.country.value)

      for (let i = 0; i < selectedTasks.length; i++) {
        data.append(`items[]`, JSON.stringify(selectedTasks[i]))
      }
      for (let i = 0; i < fileToUpload.length; i++) {
        data.append(`attachment`, fileToUpload[i])
      }
      await props.savePurchaseOrder(data, '', 'Work Order Created Successfully');
      cancelHandler()
    }
  }

  const cancelHandler = () => navigate(`/${CONTRACTORS}/${WORK_ORDER}`);

  return (
    <>
      <div className="purchase-order-block">
        <h2 className="content-heading text-uppercase">{state.edit ? "Edit WO" : "Create WO"}</h2>
        <div className="col-12 breadcrumb-block p-0">
          <Breadcrumb>
            <Breadcrumb.Item onClick={cancelHandler}>WO</Breadcrumb.Item>
            <Breadcrumb.Item active>{state.edit ? "Edit WO" : "Create WO"}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 mt-3">
            <SelectInput
              {...fields.project}
              label={'Select Project'}
              onChange={(e) => projectChangeHandler(e.target.value)}
            />
          </div>
          <div className="col-lg-4 col-md-4 mt-3">
            <SelectInput
              {...fields.vendor}
              label={'Select Vendor'}
              onChange={(e) => vendorChangeHandler(e.target.value)}
            />
          </div>
          <div className="col-lg-4 col-md-4 mt-3">
            <CustomInput
              {...fields.quote_no}
              onChange={(e) => changeHandler(fields.quote_no.name, e.target.value)}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <CustomInput
              {...fields.street_address}
              onChange={(e) => changeHandler(fields.street_address.name, e.target.value)}
            />
          </div>
          <div className="col-4 mt-3">
            <CustomInput
              {...fields.suburb}
              onChange={(e) => changeHandler(fields.suburb.name, e.target.value)}
            />
          </div>
          <div className="col-4 mt-3">
            <CustomInput
              {...fields.state}
              onChange={(e) => changeHandler(fields.state.name, e.target.value)}
            />
          </div>
          <div className="col-4 mt-3">
            <CustomInput
              {...fields.postcode}
              onChange={(e) => changeHandler(fields.postcode.name, e.target.value)}
            />
          </div>
          <div className="col-4 mt-3">
            <CustomInput
              {...fields.country}
              onChange={(e) => changeHandler(fields.country.name, e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 fileupload-section">
            <div className="file-section-part position-relative h-auto px-0">
              {/* <label className="label-header">Attachment</label> */}
              <div className="file-part">
                <FileUploader
                  fileHandler={(files) =>
                    handleFileUpload({ target: { files, type: 'file', value: '' } })
                  }
                />
                <label htmlFor="#file-section"></label>
              </div>
              <input type="file" id="file-section"></input>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group text-left">
              <div className="input-block">
                <label className="input-label">Uploaded Documents</label>
                <p className="m-0 doc-info">
                  {Array.isArray(state.fileToUpload) && state.fileToUpload.map((file, index) => (
                    <span key={index}>
                      <span>{file.name}</span>
                      <span onClick={() => handleRemoveFile(index)}><DeleteIcon /></span>
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="material-list-block mt-3">
          <div className="table-responsive">
            <Table bordered className="table-create table-material table-material-ch">
              <thead>
                <tr>
                  <th className="w-auto">Select Task</th>
                  <th className="w-auto">Description</th>
                  <th>Contract Amount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {state.stages.map((stage, stageIndex) => {
                  return (
                    <Fragment key={stageIndex}>
                      {(state.hasStages &&
                        <tr>
                          <td>
                            {/* <Form> */}
                            <Form.Check
                              type={'checkbox'}
                              id={stage.id}
                              value={stage.selected}
                              label={`Stage${stageIndex + 1}: ${stage.name}`}
                              onChange={(e) => this.stageHandler(e.target.checked, stageIndex, 'checkbox')}
                            />
                            {/* </Form> */}
                          </td>
                          <td>{stage.description}</td>
                          <td></td>
                          <td>
                            <CustomInput
                              className="input-block-sm w-auto"
                              value={stage.quote}
                              valid={!stage.error}
                              lable='Amount'
                              name='quote'
                              error={stage.error}
                              onChange={(e) => stageHandler(e.target.value, stageIndex, 'quote')}
                            />
                          </td>
                        </tr>
                      ) || ''}
                      {stage.tasks.map((task, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form>
                                <Form.Check
                                  type={'checkbox'}
                                  id={task.id}
                                  value={task.selected}
                                  onChange={(e) => taskHandler(e.target.checked, stageIndex, index, 'checkbox')}
                                  label={`Task${index + 1}: ${task.name}`}
                                />
                              </Form>

                            </td>
                            <td>{task.description}</td>
                            <td>{numberFormat(idx(task, _ => _.subcontractorTotal_SaleAmount) || 0)}</td>
                            <td>
                              <CustomInput
                                className="input-block-sm w-auto"
                                value={task.quote}
                                lable='Amount'
                                type='number'
                                name='quote'
                                valid={!task.error}
                                error={task.error}
                                onChange={(e) => taskHandler(e.target.value, stageIndex, index, 'quote')}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="col-12 d-flex mt-3">
          <Button type="submit" className="ms-auto me-3 secondarybtn" onClick={submitForm}>DONE</Button>
          <Button type="submit" className="secondarybtn cancelbtn me-auto" onClick={cancelHandler}>CANCEL</Button>
        </div>

      </div>
    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  errorSnackBar: bindActionCreators(errorSnackBar, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  savePurchaseOrder: bindActionCreators(savePurchaseOrder, dispatch),
  editPurchaseOrder: bindActionCreators(editPurchaseOrder, dispatch),
  getVendorList: bindActionCreators(getVendorList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getPoCount: bindActionCreators(getPoCount, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder);
