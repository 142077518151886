import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProposalOptions, downloadProjectQuote } from './../../actions/project-management.action'
import Modal from './../../components/modal/modal'
import 'dompurify'
import Cookies from 'js-cookie'
import DownloadDialog from '../../components/download-dialog'
import { optionCalculation, stageCalculation } from '../../util/calculations'
import { getGlobalSettingTemplateTypes } from './../../actions/global-setting-template.action'
import { ProposalType } from 'src/util/enums'
import SummaryProposal from 'src/components/proposal/summary';
import DetailedProposal from 'src/components/proposal/detailed'

function ProposalDialog(props) {
  const {
    open,
    dialogClose,
    projectName,
    customerName,
    stages,
    projectDescription,
    hasStages,
    options,
    isVariation,
    isUpdate = true,
    type = ProposalType.SUMMARY
  } = props

  const [subTaskCost, setSubTaskCost] = useState([])
  const [taskCost, setTaskCost] = useState([])
  const [stagesCost, setStagesCost] = useState([])
  const [optionsCost, setOptionsCost] = useState([])
  const [stagesData, setStagesData] = useState([])
  const [optionsData, setOptionsData] = useState([])
  const [totalCost, setTotalCost] = useState(0)
  let [templateOptions, setTemplateOptions] = useState([])
  const [downloadData, setDownloadData] = useState({
    open: false,
    id: ''
  })

  useEffect(() => {
    let calculations = stageCalculation({ stages: stages });
    let totalCost = calculations.total
    let _options = options.filter(_ => _.selected && _.isVariation) || []
    let selectedOptionsCalculations = optionCalculation({ options: _options })
    let optionsCalculations = optionCalculation({ options: options })

    totalCost += selectedOptionsCalculations.total

    setOptionsData(options)
    setTotalCost(totalCost)
    setTaskCost(calculations.task)
    setStagesCost(calculations.stage)
    setSubTaskCost({ ...calculations.subtask, ...optionsCalculations.subtask })
    setOptionsCost(optionsCalculations.option)
    setStagesData(stages)

  }, [])

  const saveProposal = () => {
    const projectId = Cookies.get('projectId')
    const selOptions = options.filter(option => option.selected === true)
    const optionArr = []
    selOptions.forEach(option => {
      optionArr.push(option.id)
    })
    const data = {
      projectId,
      options: optionArr,
      isVariation: props.isVariation
    }
    if (isVariation && isUpdate) {
      props.updateProposalOptions(data).then(resp => {
        props.handleDownloadDialog()
      })
    } else {
      handleDownloadDialog(projectId)
      // generatePdf()
    }
  }

  // const generatePdf = () => {
  //   let source = document.getElementById('proposal')
  //   window.html2canvas = html2canvas;
  //   let pdf = new jsPDF('p', 'pt', 'a2');
  //   pdf.html(source, {
  //     x: 30,
  //     y: -300,
  //     callback: function () {
  //       pdf.save('test.pdf');
  //     }
  //   })
  // }

  const gettemplate = async () => {
    if (templateOptions.length == 0) {
      templateOptions = []
      let type = (props.isVariation ? 'ProjectVariation' : 'ProjectQuote')
      const templateData = await props.getGlobalSettingTemplateTypes(type)
      for (let key in templateData) {
        templateOptions.push({ value: templateData[key].id, key: templateData[key].name })
      }
      setTemplateOptions(templateOptions)
    }
  }

  const handleDownloadDialog = async (id = '', template = '') => {

    const newDownloadData = {}
    newDownloadData.open = !downloadData.open
    newDownloadData.id = id
    newDownloadData.template = template
    if (templateOptions.length == 0) {
      gettemplate();
    }
    setDownloadData(newDownloadData)
  }

  const downloadFile = async (fileFormat = 'pdf', tempId) => {
    if (fileFormat === 'pdf' || fileFormat === 'ALL') {
      await props.downloadProjectQuote(downloadData.id, fileFormat, tempId).catch(e => console.error(e))
    }
    if (fileFormat === 'docx' || fileFormat === 'ALL') {
      await props.downloadProjectQuote(downloadData.id, fileFormat, tempId).catch(e => console.error(e))
    }
    handleDownloadDialog()
  }

  return (<>
    <DownloadDialog
      {...downloadData}
      downloadFile={downloadFile}
      handleDownloadDialog={handleDownloadDialog}
      templateOptions={templateOptions}
      downloadModuleName='Variation'
    />
    <Modal
      open={open}
      onClose={dialogClose}
      fullWidth
      maxWidth='md'
      modalHeading={`Proposal Name ${projectName}`}
      modalSaveTxt={isUpdate ? `Confirm and Download` : 'Download'}
      onClick={saveProposal}
      showActionBtn
      savebtnClass='createBtn'
      primary
    >

      <div id='proposal' >
        <div className="row">
          <div className="col-lg-6">
            <div className="view-block">
              <label>Customer Name</label>
              <p>{customerName}</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="view-block">
              <label>Description</label>
              <p>{projectDescription}</p>
            </div>
          </div>
        </div>

        {type == ProposalType.SUMMARY && (
          <SummaryProposal
            optionsData={optionsData}
            stagesData={stagesData}
            hasStages={hasStages}
            taskCost={taskCost}
            optionsCost={optionsCost}
            stagesCost={stagesCost}
            totalCost={totalCost}
            isVariation={isVariation}
          />
        )}

        {type == ProposalType.DETAILED && (
          <DetailedProposal
            optionsData={optionsData}
            stagesData={stagesData}
            hasStages={hasStages}
            taskCost={taskCost}
            optionsCost={optionsCost}
            stagesCost={stagesCost}
            subTaskCost={subTaskCost}
            totalCost={totalCost}
            isVariation={isVariation}
          />
        )}

      </div>
    </Modal>
  </>)
}
const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  updateProposalOptions: bindActionCreators(updateProposalOptions, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(getGlobalSettingTemplateTypes, dispatch),
  downloadProjectQuote: bindActionCreators(downloadProjectQuote, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDialog)
