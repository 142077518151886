const d = new Date();
let taskname = d.toDateString();
export const taskFields = {
  taskName: {
    name: 'taskName',
    label: 'Task Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: taskname,
    validation: ['required']
  },
  profitMarginType: {
    name: 'profitMarginType',
    label: 'Profit Margin Type',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: 'PERCENTAGE',
    items: [
      { key: 'Percentage', value: 'PERCENTAGE' }],
    validation: ['required']
  },
  profitMargin: {
    name: 'profitMargin',
    label: 'Profit Margin %',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: []
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: []
  },
  notes: {
    name: 'notes',
    label: 'Internal notes',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['skip']
  },
  attachments: {
    name: 'attachments',
    label: 'Attachments',
    type: 'file',
    touched: false,
    error: false,
    valid: true,
    multiple: true,
    value: [],
    attachmentArr: [],
    validation: []
  },
  is_actual_materialcost: {
    name: 'is_actual_materialcost',
    label: 'Use Material Cost as Actual Cost',
    type: 'checkbox',
    touched: false,
    error: false,
    valid: true,
    validation: ['skip']
  },
  date: {
    name: 'date',
    label: '',
    type: 'datepicker',
    touched: false,
    error: false,
    value: new Date()
  },
  user: {
    name: 'user',
    type: 'select',
    label: 'Select User',
    value: '',
    validation: ['skip']
  }
}
export const subTaskFields = {
  taskName: {
    name: 'taskName',
    label: 'Task Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: taskname,
    validation: ['required']
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: []
  },
  notes: {
    name: 'notes',
    label: 'Internal notes',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['skip']
  },
  is_actual_materialcost: {
    name: 'is_actual_materialcost',
    label: 'Use Material Cost as Actual Cost',
    type: 'checkbox',
    touched: false,
    error: false,
    valid: true,
    validation: ['skip']
  }
}
export const itemFields = {
  name: {
    value: ''
  },
  id: '',
  categoryId: '',
  taskId: {
    name: 'taskId',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: '',
    items: []
  },
  manufacturer: '',
  partNo: '',
  costPerItem: {
    name: 'costPerItem',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  saleAmount: {
    name: 'saleAmount',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  qty: {
    name: 'qty',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  unit: {
    name: 'unit',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: 'unit',
    items: []
  },
  saleAmountLock: {
    name: 'saleAmountLock',
    type: 'boolean',
    touched: false,
    error: false,
    valid: true,
    value: true
  },
  total: {
    value: 0
  }
}

export const laborObjFields = {
  name: {
    name: 'name',
    label: '',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  id: {
    value: ''
  },
  skillDescription: {
    value: ''
  },
  calloutCost: {
    name: 'calloutCost',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  travelCost: {
    name: 'travelCost',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  level: {
    name: 'level',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: '',
    items: []
  },
  labourCost: {
    name: 'labourCost',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  costPerItem: {
    name: 'costPerItem',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  qty: {
    name: 'qty',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  total: {
    value: 0
  }
}
export const subcontractorObjFields = {
  name: {
    name: 'name',
    label: 'Contractor Name',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: '',
    items: []
  },
  id: {
    value: ''
  },
  Cost: {
    name: 'Cost',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  subContractorForecastCost: {
    name: 'subContractorForecastCost',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  saleAmount: {
    name: 'saleAmount',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: 0
  },
  saleAmountLock: {
    name: 'saleAmountLock',
    type: 'boolean',
    touched: false,
    error: false,
    valid: true,
    value: true
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: []
  },
  total: {
    value: 0
  }
}
export const categoriesFields = {
  name: 'categories',
  label: 'Catalog',
  type: 'select',
  items: [],
  value: ''
}

export const categoryItemFields = {
  name: 'items',
  type: 'select',
  label: 'Add Item',
  items: [],
  value: ''
}

export const viewDataFields = {
  name: '',
  description: '',
  profitMargin: 0,
  profitMarginType: '',
  totalCost: 0,
  noOfItems: 0,
  noOfResources: 0,
  material: [],
  labor: []
}

export const stageFields = {
  stageName: {
    name: 'stageName',
    label: 'Stage Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  profitMarginType: {
    name: 'profitMarginType',
    label: 'Profit Margin Type',
    type: 'select',
    touched: false,
    error: false,
    valid: true,
    value: 'PERCENTAGE',
    items: [{ key: 'Fixed', value: 'FIXED' },
    { key: 'Percentage', value: 'PERCENTAGE' }],
    validation: ['required']
  },
  profitMargin: {
    name: 'profitMargin',
    label: 'Profit Margin',
    type: 'number',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: []
  },
  // variableAmount: {
  //   name: 'variableAmount',
  //   label: 'Sub-Contractor Cost',
  //   type: 'text',
  //   touched: false,
  //   error: false,
  //   valid: true,
  //   value: '',
  //   validation: ['required']
  // },
  // variableAmountDescription: {
  //   name: 'variableAmountDescription',
  //   label: 'Sub-Contractor Cost Description',
  //   type: 'textarea',
  //   touched: false,
  //   error: false,
  //   valid: true,
  //   value: '',
  //   validation: ['required']
  // },
  attachments: {
    name: 'attachments',
    label: 'Attachments',
    type: 'file',
    touched: false,
    error: false,
    valid: true,
    multiple: true,
    value: [],
    attachmentArr: [],
    validation: []
  }
}

export const viewDataStageFields = {
  name: '',
  description: '',
  totalCost: '',
  ProfitMargin: '',
  totalTasks: ''
}

export const levelFields = {
  name: {
    name: 'name',
    label: 'Level Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  description: {
    name: 'description',
    label: 'Level Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  calloutCost: {
    name: 'calloutCost',
    label: 'Call Out Charge Amount',
    type: 'amount',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['number']
  },
  calloutAverageCost: {
    name: 'calloutAverageCost',
    label: 'Average Cost',
    type: 'amount',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['number']
  },
  travelCost: {
    name: 'travelCost',
    label: 'Travel Charge Amount',
    type: 'amount',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['number']
  },
  travelAverageCost: {
    name: 'travelAverageCost',
    label: 'Average Cost',
    type: 'amount',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['number']
  }
}

export const addTemplateFields = {
  name: {
    name: 'name',
    label: 'Template Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  }
}

export const assignTaskFields = {
  status: {
    error: false,
    valid: true,
    value: 'OPEN'
  },
  percentageComplete: {
    error: false,
    valid: true,
    value: ''
  },
  reminder: {
    error: false,
    valid: true,
    value: ''
  },
  attachments: {
    error: false,
    valid: true,
    value: [],
    attachmentArr: []
  }
}

export const deliveryOptions = [
  { key: 'Site', value: 'SITE' },
  { key: 'Pickup', value: 'PICKUP' },
  { key: 'Office', value: 'OFFICE' }
]

export const projectCategoryFields = {
  name: {
    name: 'name',
    label: 'Name',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  },
  type: {
    name: 'type',
    label: 'Type',
    type: 'text',
    touched: false,
    error: false,
    valid: true,
    value: '',
    validation: ['required']
  }
}