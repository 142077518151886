import {
  addService as addServiceAPI,
  addTask as addTaskAPI,
  addOption as addOptionAPI,
  getTask as getTaskAPI,
  addSubTask as addSubTaskAPI,
  updateSubTask as updateSubTaskAPI,
  updateTask as updateTaskAPI,
  getTasks as getTasksAPI,
  getServicesList as getServicesListAPI,
  getService as getServiceAPI,
  editService as editServiceAPI,
  deleteOption as deleteOptionAPI,
  deleteService as deleteServiceAPI,
  getOptions as getOptionsAPI,
  getOption as getOptionAPI,
  updateOption as updateOptionAPI,
  saveTemplate as saveTemplateAPI,
  saveContentFromTemplate,
  fetchTemplates as fetchTemplatesAPI,
  updateProposalOptions as updateProposalOptionsAPI,
  saveRFQ as saveRFQAPI,
  getrfqList as getrfqListAPI,
  editRFQ as editRFQAPI,
  uploadFileRfq as uploadFileRfqAPI,
  getRfq as getRfqAPI,
  createQuotation as createQuotationAPI,
  savePurchaseOrder as savePurchaseOrderAPI,
  editPurchaseOrder as editPurchaseOrderAPI,
  deletePurchaseOrder as deletePurchaseOrderAPI,
  getPurchaseOrder as getPurchaseOrderAPI,
  getQuotationList as getQuotationListAPI,
  deleteTask as deleteTaskAPI,
  deleteSubTask as deleteSubTaskAPI,
  getSubTask as getSubTaskAPI,
  getPOList as getPOListAPI,
  updatePOStock as updatePOStockAPI,
  addProgressClaim as addProgressClaimAPI,
  getProgressClaimList as getProgressClaimListAPI,
  getvendorsPOList as getvendorsPOListAPI,
  addProgressClaimRetention as addProgressClaimRetentionAPI,
  getPoCount as getPoCountAPI,
  getPoVendors as getPoVendorsAPI,
  setVendorPayment as setVendorPaymentAPI,
  getvendorsPOInvoiceCount as getvendorsPOInvoiceCountAPI,
  getPOInvoices as getPOInvoicesAPI,
  getPOInvoice as getPOInvoiceAPI,
  duplicateServiceTask as duplicateServiceTaskAPI,
  duplicateService as duplicateServiceAPI,
  receiveDEStock as receiveDEStockAPI,
  receiveReserveStock as receiveReserveStockAPI,
  editQuotation as editQuotationAPI,
  downloadServiceQuote as downloadServiceQuoteAPI,
  downloadServicePO as downloadServicePOAPI,
  downloadServiceRFQ as downloadServiceRFQAPI,
  downloadServiceVariation as downloadServiceVariationAPI,
} from '../api/service.api'

export const addService = (data) => {
  return (dispatch, getState) => {
    return addServiceAPI(data)
  }
}

export const addTask = (data, taskId) => {
  return (dispatch, getState) => {
    return addTaskAPI(data, taskId)
  }
}
export const deleteService = (id,type) => {
  return (dispatch, getState) => {
    return deleteServiceAPI(id,type)
  }
}
export const addOption = (data) => {
  return (dispatch, getState) => {
    return addOptionAPI(data)
  }
}

export const getTask = (taskId) => {
  return (dispatch, getState) => {
    return getTaskAPI(taskId)
  }
}

export const updateTask = (data, taskId) => {
  return (dispatch, getState) => {
    return updateTaskAPI(data, taskId)
  }
}

export const addSubTask = (data, taskId) => {
  return (dispatch, getState) => {
    return addSubTaskAPI(data, taskId)
  }
}

export const updateSubTask = (data, SubTaskId) => {
  return (dispatch, getState) => {
    return updateSubTaskAPI(data, SubTaskId)
  }
}

export const getOption = (optionId) => {
  return (dispatch, getState) => {
    return getOptionAPI(optionId)
  }
}

export const updateOption = (data, optionId) => {
  return (dispatch, getState) => {
    return updateOptionAPI(data, optionId)
  }
}

export const getTasks = (serviceId) => {
  return (dispatch, getState) => {
    return getTasksAPI(serviceId)
  }
}

export const getServicesList = (customerId, type, serviceDescription, managerId) => {
  return (dispatch, getState) => {
    return getServicesListAPI(customerId, type, serviceDescription, managerId)
  }
}

export const getService = (serviceId) => {
  return (dispatch, getState) => {
    return getServiceAPI(serviceId)
  }
}

export const editService = (data, serviceId) => {
  return (dispatch, getState) => {
    return editServiceAPI(data, serviceId)
  }
}

export const getOptions = (serviceId) => {
  return (dispatch, getState) => {
    return getOptionsAPI(serviceId)
  }
}

export const saveTemplate = (data) => {
  return (dispatch) => {
    return saveTemplateAPI(data)
  }
}

export const addContentTemplate = (data) => {
  return (dispatch) => {
    return saveContentFromTemplate(data)
  }
}

export const fetchTemplates = () => {
  return () => {
    return fetchTemplatesAPI()
  }
}

export const updateProposalOptions = (data) => {
  return (dispatch, getState) => {
    return updateProposalOptionsAPI(data)
  }
}

export const saveRFQ = (data, serviceId, message) => {
  return (dispatch, getState) => {
    return saveRFQAPI(data, serviceId, message)
  }
}

export const getrfqList = (serviceId) => {
  return (dispatch, getState) => {
    return getrfqListAPI(serviceId)
  }
}

export const editRFQ = (data, id, message) => {
  return (dispatch, getState) => {
    return editRFQAPI(data, id, message)
  }
}

export const savePurchaseOrder = (poItems, rfqId, message) => {
  return (dispatch, getState) => {
    return savePurchaseOrderAPI(poItems, rfqId, message)
  }
}

export const editPurchaseOrder = (poItems, poId, message) => {
  return (dispatch, getState) => {
    return editPurchaseOrderAPI(poItems, poId, message)
  }
}

export const deletePurchaseOrder = (poId, message) => {
  return (dispatch, getState) => {
    return deletePurchaseOrderAPI(poId, message)
  }
}

export const getPurchaseOrder = (query) => {
  return (dispatch, getState) => {
    return getPurchaseOrderAPI(query)
  }
}

export const uploadFileRfq = (formData, id, message) => {
  return (dispatch, getState) => {
    return uploadFileRfqAPI(formData, id, message)
  }
}

export const getRfq = (rfqId) => {
  return (dispatch, getState) => {
    return getRfqAPI(rfqId)
  }
}

export const createQuotation = (data, id) => {
  return (dispatch, getState) => {
    return createQuotationAPI(data, id)
  }
}

export const getQuotationList = (query) => {
  return (dispatch, getState) => {
    return getQuotationListAPI(query)
  }
}

export const deleteTask = (taskId) => {
  return (dispatch, getState) => {
    return deleteTaskAPI(taskId)
  }
}

export const deleteOption = (optionId) => {
  return (dispatch, getState) => {
    return deleteOptionAPI(optionId)
  }
}

export const deleteSubTask = (subTaskId) => {
  return (dispatch, getState) => {
    return deleteSubTaskAPI(subTaskId)
  }
}

export const getSubTask = (subTaskId) => {
  return (dispatch) => {
    return getSubTaskAPI(subTaskId)
  }
}

export const getPOList = ({ vendorId = '', serviceId = '', vendorType = '', itemId = null }) => {
  return (dispatch, getState) => {
    return getPOListAPI({ vendorId, serviceId, vendorType, itemId })
  }
}

export const updatePOStock = (data, id) => {
  return (dispatch, getState) => {
    return updatePOStockAPI(data, id)
  }
}

export const addProgressClaim = (data) => {
  return (dispatch, getState) => {
    return addProgressClaimAPI(data)
  }
}

export const getProgressClaimList = (serviceId) => {
  return (dispatch, getState) => {
    return getProgressClaimListAPI(serviceId)
  }
}

export const getvendorsPOList = (params) => {
  return (dispatch, getState) => {
    return getvendorsPOListAPI(params)
  }
}

export const addProgressClaimRetention = (data) => {
  return (dispatch, getState) => {
    return addProgressClaimRetentionAPI(data)
  }
}

export const getPoCount = (serviceId) => {
  return (dispatch, getState) => {
    return getPoCountAPI(serviceId)
  }
}

export const getPoVendors = (serviceId, type) => {
  return (dispatch, getState) => {
    return getPoVendorsAPI(serviceId, type)
  }
}

export const setVendorPayment = (data, poNumber) => {
  return (dispatch, getState) => {
    return setVendorPaymentAPI(data, poNumber)
  }
}
// total vendor payment invoices for a project
export const getvendorsPOInvoiceCount = (projectId) => {
  return (dispatch, getState) => {
    return getvendorsPOInvoiceCountAPI(projectId)
  }
}

export const getPOInvoices = (poId) => {
  return (dispatch, getState) => {
    return getPOInvoicesAPI(poId)
  }
}

export const getPOInvoice = (poId) => {
  return (dispatch, getState) => {
    return getPOInvoiceAPI(poId)
  }
}

export const duplicateServiceTask = (taskType, taskId, subTaskId) => {
  return (dispatch, getState) => {
    return duplicateServiceTaskAPI(taskType, taskId, subTaskId)
  }
}

export const duplicateService = (data) => {
  return (dispatch, getState) => {
    return duplicateServiceAPI(data)
  }
}

export const receiveDEStock = (data) => {
  return (dispatch, getState) => {
    return receiveDEStockAPI(data)
  }
}

export const receiveReserveStock = (data) => {
  return (dispatch, getState) => {
    return receiveReserveStockAPI(data)
  }
}

export const editQuotation = (data, id) => {
  return (dispatch, getState) => {
    return editQuotationAPI(data, id)
  }
}

export const downloadServiceQuote = (id, format) => {
  return (dispatch, getState) => {
    return downloadServiceQuoteAPI(id, format)
  }
}

export const downloadServicePO = (id, format, template) => {
  return (dispatch, getState) => {
    return downloadServicePOAPI(id, format, template)
  }
}

export const downloadServiceRFQ = (id, format, vendorId) => {
  return (dispatch, getState) => {
    return downloadServiceRFQAPI(id, format, vendorId)
  }
}

export const downloadServiceVariation = (id, format) => {
  return (dispatch, getState) => {
    return downloadServiceVariationAPI(id, format)
  }
}
