import React from 'react'
import './App.scss'
import GateKeeper from './gateKeeper'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className='App'>
      <GateKeeper />
    </div>
  )
}

export default App
