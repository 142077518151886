import { subcontractorObjFields } from "../../constants/fields.constant";
import { cloneDeep, isString } from "lodash";

export const convertToSubcontractorForm = (subcontractors = [], options = []) => {
    const _subcontractors = subcontractors.map((iterator) => {
        if (isString(iterator)) {
            iterator = JSON.parse(iterator);
        }
        const subcontractorObj = cloneDeep(subcontractorObjFields);
        let _subcontractor = options.find(item => item.value == iterator.id)

        subcontractorObj.name.value = _subcontractor?.key || '';// iterator.id;
        subcontractorObj.id.value = iterator.id;
        subcontractorObj.Cost.value = iterator.Cost;
        subcontractorObj.saleAmount.value = iterator['saleAmount'] || 0;
        subcontractorObj.saleAmountLock.value = iterator.saleAmountLock;
        subcontractorObj.description.value = iterator.description;
        return subcontractorObj;

    }) || [];

    return _subcontractors;
}