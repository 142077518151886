import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./scss/po.scss";
import {
  PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  VENDOR_PAYMENT,
  SUPPLIERS,
} from "./../../../constants/route-paths";
import {
  getPOList,
  deletePurchaseOrder,
  getProjectList,
  downloadProjectPO,
} from "./../../../actions/project-management.action";
import idx from "idx";
import { format } from "date-fns";
import POFilter from "./po-list-filter";
import PayInvoice from "../../vendor-payment/pay-invoice";
import _ from "lodash";
import DownloadDialog from "../../../components/download-dialog";
import DeleteDialog from "../../../components/delete-dialog";
import { warningSnackBar } from "./../../../actions/common.action";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getGlobalSettingTemplateTypes } from "./../../../actions/global-setting-template.action";
import { withRouter } from "../../../util/with-router";
import Popover from 'react-bootstrap/Popover';
import CustomTable from "src/components/table/table";
import { poListColumns } from "../data/po";
import { TemplateType } from "src/util/enums";

function SuppliersPurchaseOrderList({ writePermission, projectModule, ...props }) {

  const [state, setState] = useState({
    poList: [],
    projectsList: [{ key: "All Projects", value: "all" }],
    selectedProject: "all",
    vendorType: "all",
    workType: "project",
    templateOptions: [],
    showPOFilter: false,
    downloadData: {
      open: false,
      id: "",
    },
    openDeleteDialog: false,
  })

  useEffect(() => {
    getPOList();
    getProjectList();
  }, [])

  const getProjectList = async () => {
    let projectsList = [];
    let projects = await props.getProjectList(null, "PROJECT");
    projects.forEach((project) => projectsList.push({ key: project.name, value: project.id }));

    projectsList = _.orderBy(
      projectsList,
      [({ key }) => key.toLowerCase()],
      ["asc"]
    );

    projectsList.unshift({ key: "All Projects", value: "all" });
    setState(prev => ({
      ...prev,
      projectsList
    }))
  };

  const getPOList = async (projectId = "", isDePo = null) => {
    let poList = await props.getPOList({ projectId, vendorType: 'Supplier', isDePo });

    const POs = [];
    let fields = ['street_address', 'suburb', 'state', 'postcode', 'country']
    Array.isArray(poList) && poList.forEach((po) => {
      const vendorName = idx(po, (_) => _.vendor.business_name) || "";
      const type = idx(po, (_) => _.vendor.type) || "";
      let po_address = [];
      for (const iterator of fields) {
        if (idx(po, _ => _[iterator])) po_address.push(idx(po, _ => _[iterator]))
      }
      const status = idx(po, (_) => _.status) || "";
      const project = idx(po, (_) => _.project.name) || "";
      const issueDate = po.createdAt ? format(new Date(po.createdAt), "dd/MM/yyyy") : "";
      const total = parseFloat(po.totalCost || 0);
      POs.push({
        total,
        vendorName,
        type,
        address: po_address.join(', '),
        project,
        issueDate,
        id: po.id,
        poId: po.u_id,
        status,
        projectId: po.projectId
      });
    });
    setState(prev => ({
      ...prev,
      poList: POs
    }))
  };

  const createPo = () => {
    props.history(`/${SUPPLIERS}/${CREATE_PURCHASE_ORDER}`);
  };

  const editPo = () => {
    const { workType, poId } = state;
    props.history(`/${SUPPLIERS}/${PURCHASE_ORDER}/edit/${poId}/${workType}`);
  };

  const viewPO = () => {
    const { workType, poId } = state;
    props.history(`/${SUPPLIERS}/${PURCHASE_ORDER}/view/${poId}/${workType}`);
  };

  const deleteDialogState = () => {
    setState(prev => ({
      ...prev,
      openDeleteDialog: !prev.openDeleteDialog,
    }))
  };

  const deleteHandler = async () => {
    const { poId } = state;
    await props.deletePurchaseOrder(poId, "Purchase Order Deleted successfully");
    deleteDialogState();
    getPOList();
  };

  const poFilterHandler = () => {
    setState(prev => ({
      ...prev,
      showPOFilter: !prev.showPOFilter,
    }))
  };

  const changeFilter = (projectId, isDePo = null) => {
    if (projectId === "all") { projectId = ""; }
    poFilterHandler();
    getPOList(projectId, isDePo);
  };


  const handleMenu = (rowData) => {
    setState(prev => ({
      ...prev,
      poId: rowData.poId,
      invoiceData: {
        poNumber: rowData.poId,
        poNo: rowData.id,
        status: rowData.status,
      },
    }))
  };

  const handlePayInvoiceModal = (status, allProjects = false) => {
    setState(prev => ({
      ...prev,
      showPayInvoice: status,
      invoiceData: { ...(allProjects ? {} : { ...prev.invoiceData }) },
    }))
  };

  const viewInvoices = () => {
    const { poId, workType } = state;
    props.history(`/${SUPPLIERS}/${VENDOR_PAYMENT}?poId=${poId}&type=${workType}`);
  };

  const gettemplate = async (projectId) => {
    let newdata = [];
    const templateData = await props.getGlobalSettingTemplateTypes(TemplateType.PURCHASE_ORDER, projectId);
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    setState(prev => ({
      ...prev,
      templateOptions: newdata
    }))
  };

  const handleDownloadDialog = (id = "") => {
    const { downloadData } = state;
    const newDownloadData = {};
    newDownloadData.open = !downloadData.open;
    newDownloadData.id = id;
    if (id) gettemplate(id);
    setState(prev => ({
      ...prev,
      downloadData: newDownloadData
    }))
  };

  const downloadFile = async (fileFormat = "pdf", tempId) => {
    const { downloadData } = state;
    if (fileFormat === "pdf" || fileFormat === "ALL") {
      await props.downloadProjectPO(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    if (fileFormat === "docx" || fileFormat === "ALL") {
      await props.downloadProjectPO(downloadData.id, fileFormat, tempId).catch(e => console.error(e));
    }
    handleDownloadDialog();
  };

  return (
    <>
      <DeleteDialog
        open={state.openDeleteDialog}
        id={state.poId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogState}
        moduleName="Purchase Order"
      />
      <DownloadDialog
        {...(state.downloadData || {})}
        downloadFile={downloadFile}
        templateOptions={state.templateOptions}
        handleDownloadDialog={handleDownloadDialog}
        downloadModuleName="Purchase Order"
      />
      {state.showPayInvoice && (
        <PayInvoice
          {...(state.invoiceData || {})}
          workType={"project"}
          handleClose={() => handlePayInvoiceModal(false)}
        />
      )}
      {state.showPOFilter && <POFilter
        projectsList={state.projectsList}
        changeFilter={changeFilter}
        workType={state.workType}
        open={state.showPOFilter}
        dialogClose={poFilterHandler}
      />}

      {!projectModule ? (
        <p>
          You do not currently have access to any Projects Finance. Please
          contact your Administrator if this is required.
        </p>
      ) : (
        <>
          <div className="purchase-order-block">
            <div className="row">
              <div className="w-auto">
                <h2 className="content-heading text-uppercase">Purchase Order</h2>

              </div>
              {writePermission && (<div className="w-auto ms-auto mt-auto">
                <button onClick={createPo} className="secondarybtn secondarybtn-outline btn-ch ms-auto me-2 btn btn-primary">Create PO</button>
                <span onClick={poFilterHandler} className="filter-icon"><FilterAltIcon /></span>
              </div>)}
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <CustomTable
                  columns={poListColumns}
                  data={state.poList}
                  isAction={true}
                  handleMenu={(data) => handleMenu(data)}
                  menu={(data) => (
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <ul className="action-block-list">
                          {data && (
                            <li onClick={() => viewPO()}>View PO</li>
                          )}
                          {writePermission && data && data.status !== "Completed" && (
                            <li onClick={editPo}>
                              Edit PO
                            </li>
                          )}
                          {data && (
                            <li onClick={() => handleDownloadDialog(data.poId)}>
                              Download PO
                            </li>
                          )}
                          {writePermission && <li onClick={() => handlePayInvoiceModal(true)}>
                            Receive Invoice
                          </li>}
                          {writePermission && <li onClick={() => viewInvoices()}>
                            View Invoices
                          </li>}
                          {writePermission && data && data.status === "Pending" && (
                            <li onClick={deleteDialogState}>
                              Delete
                            </li>
                          )}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  )}
                  fileName='PO List'
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPOList: bindActionCreators(getPOList, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(getGlobalSettingTemplateTypes, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  deletePurchaseOrder: bindActionCreators(deletePurchaseOrder, dispatch),
  downloadProjectPO: bindActionCreators(downloadProjectPO, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SuppliersPurchaseOrderList)
);
