import React, { useState, useEffect } from 'react'
import Modal from 'src/components/modal/modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DeleteDialog from 'src/components/delete-dialog'
import { addCategory, getCategories, updateCategory, deleteCategory } from 'src/actions/inventory.action'
import { getVendorList } from 'src/actions/vendor-management.action'
import CustomTable from 'src/components/table/table'
import SelectInput from 'src/components/inputs/select'
import CustomInput from 'src/components/inputs/new-input'
import TextAreaInput from 'src/components/inputs/textarea-input'

function AddCategory(props) {
  const { dialogClose, open, showList } = props
  const writePermission = props.permissions.catalog === 2 ? true : false
  const [name, setName] = useState('')
  const [VendorList, setVendorList] = useState([])
  const [VendorEditList, setVendorEditList] = useState([])
  const [description, setDescription] = useState('')
  const [nameError, setNameError] = useState(false)
  const [id, setCategoryId] = useState('')
  const [descriptionError, setDescriptionError] = useState(false)
  const [vendorError, setVendorError] = useState(false)
  const [list, setShowList] = useState((showList || true))
  const [CategoryList, setCategoryList] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [openDeleteHander, setOpenDeleteHander] = useState(false)
  const [vendorId, setVendorId] = useState(null)

  const changeHandler = e => {
    if (e.target.name === 'name') {
      setName(e.target.value)
      setNameError(false)
      if (e.target.value.trim() === '') {
        setNameError('This field is Required')
      }
    } else if (e.target.name === 'description') {
      setDescription(e.target.value)
      setDescriptionError(false)
      if (e.target.value.trim() === '') {
        setDescriptionError('This field is Required')
      }
    }
    if (e.target.name === 'vendorId') {
      setVendorId(e.target.value)
      setVendorError(false)
      /* if (e.target.value.trim() === '') {
        setVendorError('This field is Required')
      } */
    }
  }
  useEffect(() => {
    fetchCategoryList()
    fetchVendorList()
    fetchVendorEditList()
  }, [])

  const fetchCategoryList = () => {
    props.getCategories().then((data) => {
      const Categories = []
      const all = JSON.parse(JSON.stringify(data))
      all.forEach((Category, i) => {
        Category.sno = i + 1
        Category.businessName = Category.vendor?.businessName
        Categories.push(Category)
      })
      setCategoryList(Categories)
    })
  }
  const fetchVendorList = async () => {
    const Vendors = []
    props.getVendorList().then((data) => {
      const all = JSON.parse(JSON.stringify(data))
      all && all.forEach(({ businessName, id }) => Vendors.push({ key: businessName, value: id }))
    })

    setVendorList(Vendors)
  }

  const fetchVendorEditList = async () => {
    const VendorsEdit = []
    props.getVendorList('both').then((data) => {
      const all = JSON.parse(JSON.stringify(data))
      all.forEach(vendor => {
        var vendorname = (vendor.isActive === false) ? vendor.businessName + " (Inactive)" : vendor.businessName;
        VendorsEdit.push({ key: vendorname, value: vendor.id })
      })
    })
    setVendorEditList(VendorsEdit)
  }
  const editCategory = (rowData) => {
    setShowList(false)
    setName(rowData.name || '')
    setDescription(rowData.description || '')
    setCategoryId(rowData.id || '')
    setVendorId(rowData.vendor_id || '')
  }

  const deleteHandler = (id) => {
    props.deleteCategory(id).then(res => {
      setOpenDeleteHander(false)
      fetchCategoryList()
    })
  }

  const reset = () => {
    setCategoryList()
    setName('')
    setDescription('')
    setCategoryId('')
    setShowList(true)
    setVendorId('')

  }

  const deleteDialogHandler = (id = '') => {
    setOpenDeleteHander(!openDeleteHander)
    setDeleteId(id)
  }

  const submitHandler = () => {
    let valid = true
    if (name === '') {
      setNameError('This field is Required')
      valid = false
    }
    if (description === '') {
      setDescriptionError('This field is Required')
      valid = false
    }
    /* if (vendorId === '') {
      setVendorError('This field is Required')
      valid = false
    } */
    if (valid) {
      if (id === '') {
        props.addCategory({ name, description, vendorId }).then(res => {
          fetchCategoryList()
          reset()
        }).catch(e => console.error(e))
      } else {
        props.updateCategory({ name, description, vendorId }, id).then(res => {
          fetchCategoryList()
          reset()
        }).catch(e => console.error(e))
      }
    }
  }

  const handleClose = () => {
    setShowList(true)
    setName('')
    setDescription('')
    setCategoryId('')
    setVendorId('')
    dialogClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      modalHeading={list ? 'Catalog List' : id === '' ? 'Create Catalog' : 'Update Catalog'}
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      fullWidth={true}
      maxWidth={list ? 'md' : 'sm'}
      onClick={submitHandler}
      showActionBtn={!list}
      cxlbtnClass='cancelBtn'
      savebtnClass='createBtn'
      additionalbtnTxt='List'
      additionalbtn={() => setShowList(true)}
      primary
    >
      <DeleteDialog
        open={openDeleteHander}
        id={deleteId}
        deleteHandler={deleteHandler}
        dialogClose={deleteDialogHandler}
        moduleName='Category'
      />
      {!list && <>
        <div className="purchase-order-block">
          <div className="row mb-4">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 mt-3">
                  <CustomInput
                    name='name'
                    label='Catalog'
                    value={name}
                    error={nameError}
                    onChange={(e) => changeHandler(e)}
                  />
                </div>

                <div className="col-12 mt-3">
                  <SelectInput
                    name='vendorId'
                    label='Select Vendor'
                    items={id === '' ? VendorList : VendorEditList}
                    error={vendorError}
                    valid={!vendorError}
                    value={vendorId}
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="mt-3">
                <TextAreaInput
                  name='description'
                  label='Description'
                  error={descriptionError}
                  valid={!descriptionError}
                  value={description}
                  onChange={(e) => changeHandler(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      }
      {list && <div className="purchase-order-block">
        <CustomTable
          columns={[
            { title: 'S No', field: 'sno' },
            { title: 'Catalog', field: 'name' },
            { title: 'Vendor', field: 'businessName' },
            { title: 'Description', field: 'description' }
          ]}
          data={CategoryList}
          isEdit={writePermission}
          editHandler={editCategory}
          isDelete={writePermission}
          deleteHandler={(data) => deleteDialogHandler(data.id)}
          addButton={writePermission}
          addHandler={() => editCategory({})}
          fileName='Catalog List'
        />
      </div>}
    </Modal>
  )
}
const mapStateToProps = state => ({
  permissions: state.user.userData.permissions
})
const mapDispatchToProps = dispatch => ({
  getVendorList: bindActionCreators(getVendorList, dispatch),
  addCategory: bindActionCreators(addCategory, dispatch),
  updateCategory: bindActionCreators(updateCategory, dispatch),
  deleteCategory: bindActionCreators(deleteCategory, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(AddCategory)
