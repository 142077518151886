import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import '../../project-management/scss/task.scss';
import idx from 'idx'
import { updateItemAction, updateSubTaskAction } from "src/slice/add-task";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { toPercent, toUSD } from "src/util/currency-formatter";
import { subTaskCalculation } from "src/util/calculations/calculate-sub-task";
import Material from "./material";
import Labor from "./labor";
import Subcontractor from "./subcontractor";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import TextAreaInput from "src/components/inputs/textarea-input";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import AddIcon from '@mui/icons-material/Add';

export default function SubTask({ subTaskArray = [], profitMargin, profitMarginType, isDisabled, handleSaveTemplateDialog, ...props }) {

    const dispatch = useDispatch()
    const [expand, setexpand] = useState(-1);

    const inputChangeHandler = (e, index = "") => {
        let description;
        let descriptionLength;
        if (e.target.value) {
            description = e?.target?.value.trim();
            descriptionLength = description.length;
        }

        let _temp = _.cloneDeep(subTaskArray)

        if (_temp[index]) {
            _temp[index].descriptionError = !description;
            _temp[index].descriptionLength =
                description && descriptionLength < 2;
        } else {
            _temp[index] = {
                descriptionError: !description,
                descriptionLength: description && descriptionLength < 2,
            };
        }

        if (e.target.type === "checkbox") {
            _temp[index]["is_actual_materialcost"] = e.target.checked;
        } else {
            _temp[index][e.target.name] = e.target.value;
        }
        dispatch(updateSubTaskAction(_temp))
    };

    const removeSubtask = (index) => {
        let _temp = _.cloneDeep(subTaskArray)
        _temp.splice(index, 1)
        dispatch(updateSubTaskAction(_temp))
    }

    const handleAddMaterial = (index) => {
        let _temp = _.cloneDeep(subTaskArray)
        _temp[index]['newMaterial'] ? _temp[index]['newMaterial'].push({ id: uuidv4() }) : _temp[index]['newMaterial'] = [{ id: uuidv4() }]
        dispatch(updateItemAction({ material: _temp[index]['newMaterial'], index }))
        setexpand(index)
    }
    const handleAddLabor = (index) => {
        let _temp = _.cloneDeep(subTaskArray)
        _temp[index]['newLabor'] ? _temp[index]['newLabor'].push({ id: uuidv4() }) : _temp[index]['newLabor'] = [{ id: uuidv4() }]
        dispatch(updateItemAction({ labor: _temp[index]['newLabor'], index }))
        setexpand(index)
    }
    const handleAddContractor = (index) => {
        let _temp = _.cloneDeep(subTaskArray)
        _temp[index]['newContractor'] ? _temp[index]['newContractor'].push({ id: uuidv4() }) : _temp[index]['newContractor'] = [{ id: uuidv4() }]
        dispatch(updateItemAction({ contractor: _temp[index]['newContractor'], index }))
        setexpand(index)
    }

    return (
        <>
            {subTaskArray.map((subtask, subtaskIndex) => {
                let _total = subTaskCalculation({
                    subtask: subtask,
                    profitMargin: profitMargin,
                    profitMarginType: profitMarginType,
                    isForm: true
                })

                return (
                    <div key={subtaskIndex} className="create-table-block px-0 border-primary-app shadow-sm py-2 mb-2">

                        <div className="table-responsive">
                            <Table bordered className="table-create tbl-fixed tbl-fixed-width mb-0">
                                <tbody>
                                    <tr className={'border-top-0'}>
                                        <th rowSpan={2} className="desc-width">
                                            <TextAreaInput
                                                className="m-0"
                                                label={'Description'}
                                                value={subtask.description}
                                                name='description'
                                                onChange={(e) => inputChangeHandler(e, subtaskIndex)}
                                                required={true}
                                                isHTClassRequired={false}
                                                disabled={isDisabled}
                                                rows={subtask.descriptionError ? 2 : 3}
                                                error={subtask.descriptionError ? 'Please fill Item description with atleast 2 characters' : ''}
                                            />
                                        </th>
                                        <th>Material</th>
                                        <th>Resource</th>
                                        <th>Contractor</th>
                                        <th>Total</th>
                                        <th className="qty-width ps-2 pe-3 py-0">
                                            {expand != subtaskIndex && <span className="d-flex justify-content-end" title={"Expand"}>
                                                <ExpandMoreOutlined fontSize="large" onClick={() => setexpand(subtaskIndex)} />
                                            </span>}
                                            {expand == subtaskIndex && <span className="d-flex justify-content-end" title={"Minimize"}>
                                                <ExpandLessOutlined fontSize="large" onClick={() => setexpand(-1)} />
                                            </span>}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="tableinfo">
                                                {toUSD(idx(_total, _ => _.material.sale))}
                                            </span>
                                            <span>
                                                {toUSD(idx(_total, _ => _.material.profit))}&nbsp;
                                                ({toPercent(idx(_total, _ => _.material.profit), idx(_total, _ => _.material.cost))})
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {toUSD(idx(_total, _ => _.labor.sale))}
                                            </span>
                                            <span>
                                                {toUSD((idx(_total, _ => _.labor.profit)))}&nbsp;
                                                ({toPercent(idx(_total, _ => _.labor.profit), idx(_total, _ => _.labor.cost))})
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {toUSD(idx(_total, _ => _.subcontractor.sale))}
                                            </span>
                                            <span>
                                                {toUSD(idx(_total, _ => _.subcontractor.profit))}&nbsp;
                                                ({toPercent(idx(_total, _ => _.subcontractor.profit), idx(_total, _ => _.subcontractor.cost))})
                                            </span>
                                        </td>
                                        <td>
                                            <span className="tableinfo">
                                                {toUSD(idx(_total, _ => _.total))}
                                            </span>
                                            <span>
                                                {toUSD(idx(_total, _ => _.total_profit))}&nbsp;
                                                ({toPercent(idx(_total, _ => _.total_profit), idx(_total, _ => _.subtotal))})
                                            </span>
                                        </td>
                                        <td className="tbl-action ps-2 pe-3">
                                            <div className="d-flex flex-row gap-2 justify-content-end">
                                                {props.wTemplatePermission && <div title="Save as Item Template" onClick={() => handleSaveTemplateDialog('subtask', subtask)}>
                                                    <SaveIcon className="fill-green fs-4" />
                                                </div>}
                                                {!subtask.id && <div title="Save as Item Template" onClick={() => removeSubtask(subtaskIndex)}>
                                                    <DeleteIcon className="fill-red fs-4" />
                                                </div>}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="w-100 px-3 py-1">
                            <div className="d-flex justify-content-end gap-4">
                                <div className="d-flex gap-3">
                                    {!isDisabled && <span className="tbl-action-btn" onClick={() => !isDisabled && handleAddMaterial(subtaskIndex)} >
                                        <AddIcon /> Add Material
                                    </span>}
                                    {!isDisabled && <span className="tbl-action-btn" onClick={() => !isDisabled && handleAddLabor(subtaskIndex)} >
                                        <AddIcon /> Add Resource
                                    </span>}
                                    {!isDisabled && <span className="tbl-action-btn" onClick={() => !isDisabled && handleAddContractor(subtaskIndex)} >
                                        <AddIcon /> Add Contractor
                                    </span>}
                                </div>
                            </div>
                        </div>
                        <Collapse in={expand == subtaskIndex}>
                            <div className="col-12">
                                <div className="px-1">
                                    <Material
                                        material={subtask.material || []}
                                        newMaterial={subtask['newMaterial'] || []}
                                        subTaskArray={subTaskArray}
                                        subTaskArrayIndex={subtaskIndex}
                                        profitMargin={profitMargin}
                                        profitMarginType={profitMarginType}
                                        total={_total}
                                        isDisabled={isDisabled}
                                        handleAddMaterial={handleAddMaterial}
                                        minimizeHandler={() => setexpand(-1)}
                                    />
                                </div>
                                <div className="px-1">
                                    <Labor
                                        labor={subtask.labor || []}
                                        newLabor={subtask['newLabor'] || []}
                                        subTaskArray={subTaskArray}
                                        subTaskArrayIndex={subtaskIndex}
                                        total={_total}
                                        isDisabled={isDisabled}
                                        handleAddLabor={handleAddLabor}
                                        minimizeHandler={() => setexpand(-1)}
                                    />
                                </div>
                                <div className="px-1">
                                    <Subcontractor
                                        subcontractor={subtask.subcontractor || []}
                                        newContractor={subtask['newContractor'] || []}
                                        subcontractorOptions={props.subcontractors || []}
                                        profitMargin={profitMargin}
                                        profitMarginType={profitMarginType}
                                        subTaskArray={subTaskArray}
                                        subTaskArrayIndex={subtaskIndex}
                                        total={_total}
                                        isDisabled={isDisabled}
                                        handleAddContractor={handleAddContractor}
                                        minimizeHandler={() => setexpand(-1)}
                                    />
                                </div>
                            </div>
                        </Collapse>

                    </div>
                )
            })}
        </>

    );

}
