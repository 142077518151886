import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { addProgressClaimRetention } from '../../../../actions/project-management.action'
import Modal from '../../../../components/modal/modal'
import Input from '../../../../components/inputs/input-simple'
import { InputAdornment, Grid } from '@mui/material';
import Button from 'react-bootstrap/Button';
import './budget.scss'
import CustomTable from 'src/components/table/table'

class EditTaskBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      claims: [],
      columns: [],
      addRetentionDialog: false,
      retentionField: {
        name: 'retentionInput',
        label: '',
        type: 'number',
        touched: true,
        error: false,
        valid: true,
        value: '',
        inputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      },
      progressClaimId: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.open, this.props.open) && this.props.open) {
      this.getAllData()
    }
    if (!_.isEqual(prevProps.progressClaims, this.props.progressClaims)) {
      this.getAllData()
    }
  }

  getAllData = async () => {
    const { progressClaims, projectId } = this.props
    const writePermission = this.props.userAccessControl === 2 ? true : false

    const columns = [
      {
        title: 'Progress Claim Id',
        field: 'id',
        class: 'w-auto',
        render: rowData => rowData.id,
      },
      {
        title: 'Total Amount',
        field: 'total',
        class: 'text-right',
        render: rowData => rowData.total,
      },
      {
        title: 'Progress Claim Amount',
        field: 'claimAmount',
        class: 'text-right',
        render: rowData => rowData.claimAmount,
      },
      {
        title: 'Review/Retention Amount',
        field: 'claimAmount',
        class: 'text-center',
        render: rowData => {
          if (typeof rowData.fullApproved === "boolean") {
            if (rowData.fullApproved) {
              return <span style={{ color: 'lightgreen' }}>$0</span>
            } else {
              return <span style={{ color: 'lightsalmon' }}>${rowData.retentionAmount || 0}</span>
            }
          } else {
            return (writePermission &&
              <div className="d-flex">
                <Button onClick={() => this.fullApprovedClick(rowData.id)} className="ms-auto me-2 secondarybtn btn-sm">Full Approve</Button>
                <Button onClick={() => this.setState({ addRetentionDialog: true, progressClaimId: rowData.id })} className="secondarybtn me-auto btn-sm">Add Retention</Button>
              </div>
            )
          }
        }
      },
    ]

    const claims = Array.isArray(progressClaims) && progressClaims.map(claim => ({
      id: claim.id,
      total: claim.totalValue.toFixed(2),
      claimAmount: claim.claimAmount.toFixed(2),
      fullApproved: claim.fullApproved,
      retentionAmount: claim.retentionAmount,
    }))

    this.setState({ columns, claims, progressClaims, projectId })
  }

  handleClose = () => {
    this.props.dialogClose()
  }

  handleRetentionDialog = () => {
    const { retentionField } = this.state
    this.setState({ addRetentionDialog: false, retentionField: { ...retentionField, value: '', valid: true, error: false }, progressClaimId: '' })
  }

  submitForm = async () => {
    const { retentionField, progressClaims, progressClaimId, projectId } = this.state

    const index = progressClaims.findIndex(c => c.id === progressClaimId)
    if (index !== -1) {
      const claim = progressClaims[index]
      if (!retentionField.value || claim.claimAmount <= retentionField.value) {
        retentionField.touched = true
        retentionField.valid = false
        retentionField.error = 'Less than Claim Amount!'
        this.setState({ retentionField })
      } else {
        await this.props.addProgressClaimRetention({
          progressClaimId: claim.u_id,
          retentionAmount: parseFloat(retentionField.value),
          type: 'RETENTION'
        })
        await this.props.getProgressClaimList(projectId)
        this.handleRetentionDialog()
        this.handleClose()
      }
    }
  }

  fullApprovedClick = async (value) => {
    const { progressClaims, projectId } = this.state

    const index = progressClaims.findIndex(c => c.id === value)
    if (index !== -1) {
      const claim = progressClaims[index]
      await this.props.addProgressClaimRetention({
        progressClaimId: claim.u_id,
        retentionAmount: parseFloat(0),
        type: 'FULL_APPROVED'
      })
      await this.props.getProgressClaimList(projectId)
      this.handleRetentionDialog()
      this.handleClose()
    }
  }

  handleRetentionAmount = (e) => {
    let { retentionField, progressClaims, progressClaimId } = this.state

    const amount = parseFloat(e.target.value || 0)

    const index = progressClaims.findIndex(c => c.id === progressClaimId)
    if (index !== -1) {
      const claim = progressClaims[index]
      if (!(amount) || claim.claimAmount <= amount) {
        retentionField.touched = true
        retentionField.valid = false
        retentionField.error = 'Less than Claim Amount!'
        retentionField.value = amount
      } else {
        retentionField.touched = true
        retentionField.valid = true
        retentionField.error = ''
        retentionField.value = amount
      }
      this.setState({ retentionField })
    }
  }

  render() {
    const { claims, columns, addRetentionDialog, retentionField } = this.state
    const { open } = this.props

    return (<>
      <Modal
        open={addRetentionDialog}
        onClose={this.handleRetentionDialog}
        onClick={this.submitForm}
        modalHeading={'Add Retention'}
        modalSaveTxt={'Add'}
        modalCloseTxt='Cancel'
        showActionBtn={true}
        fullWidth={true}
        maxWidth={'sm'}
        cxlbtnClass='cancelBtn'
        primary
      >
        <Grid container className='subtaskrow-1'>
          <Grid sm={4} xs={12} className='label' item>
            <p style={{ margin: "10px 0px" }}>{'Retention Amount:'}</p>
          </Grid>
          <Grid sm={7} xs={12} item>
            <Input
              {...retentionField}
              onChange={(e) => this.handleRetentionAmount(e)} />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={open}
        onClose={this.handleClose}
        modalHeading={'Progress Claim Retention'}
        modalCloseTxt='Done'
        showActionBtn={true}
        fullWidth={true}
        maxWidth={'md'}
        cxlbtnClass='cancelBtn'
        primary
      >
        <div className="purchase-order-block">
          <CustomTable
            columns={columns}
            data={claims}
            fileName='Progress Claim Retention'
          />
        </div>
      </Modal>
    </>)
  }
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  addProgressClaimRetention: bindActionCreators(addProgressClaimRetention, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskBudget);