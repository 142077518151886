import React from "react";
import Modal from "./../../components/modal/modal";
import IconButton from "@material-ui/core/IconButton";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import ViewSingleRFQ from "../../components/view-rfq";
import {
  Grid,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  TableHead,
} from "@material-ui/core";
import "./scss/project-management.scss";
import { PROJECT_MANAGEMENT } from "./../../constants/route-paths";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  getRfq,
  createQuotation,
  getQuotationList,
  getProject,
  savePOData,
  getPurchaseOrder,
  editQuotation,
  downloadProjectRFQ,
} from "./../../actions/project-management.action";
import CreateRFQDialog from "./create-rfq";
import SubmitQuote from "./submit-quote";
import { warningSnackBar } from "./../../actions/common.action";
import Cookies from "js-cookie";
import { format } from "date-fns";
import Breadcumb from "./../../components/breadcumb";
import EditIcon from "@material-ui/icons/Edit";
import { numberFormat } from "../../util/currency-formatter";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import DownloadDialog from "../../components/download-dialog";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getGlobalSettingTemplateTypes } from "./../../actions/global-setting-template.action";
import { withRouter } from "../../util/with-router";

class ViewRFQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfqsList: [],
      projectData: [],
      options: [],
      projectDetails: {},
      templateOptions: [],
      rfqDialogState: false,
      step: 1,
      quotationData: {
        rfqId: null,
        items: [],
        submitQuote: false,
        reviewQuote: false,
        vendorType: null,
        vendorId: null,
      },
      materialItems: [], // Items on which quotaion is added or they are selected or rejected. This field is being set from submit-quote file
      viewPurchaseOrder: false,
      downloadData: {
        open: false,
        id: "",
        template: "",
        vendorId: "",
      },
    };
  }

  componentDidMount() {
    this.getQuotationList();
  }

  getQuotationList = () => {
    this.setState({ projectId: Cookies.get("projectId") });
    this.props.getProject(Cookies.get("projectId")).then((project) => {
      const projectDetails = {
        hasStages: project.hasStages,
        name: project.name,
        dateOfCreation: project.dateOfCreation,
        customer: project.customer,
        description: project.description,
        type: project.type,
        stages: project.stages || [],
      };
      Array.isArray(project.stages) &&
        project.stages.forEach((stage) => {
          project.hasStages && (stage.selected = true);
          Array.isArray(stage.tasks) &&
            stage.tasks.forEach((task) => {
              task.selected = true;
              Array.isArray(task.subTasks) &&
                task.subTasks.forEach((subTask) => {
                  subTask.selected = true;
                });
            });
        });
      this.setState({ projectDetails, projectData: project.stages || [] });
    });
    const query = `?projectId=${Cookies.get("projectId")}`;
    this.props.getQuotationList(query).then((quotationsList) => {
      Array.isArray(quotationsList) &&
        quotationsList.forEach((quotation, quoIndex) => {
          Array.isArray(quotation.items) &&
            quotation.items.forEach((item, itemIndex) => {
              if (_.isString(item) && !_.isError(_.attempt(JSON.parse, item))) {
                item = JSON.parse(item);
              }
              quotationsList[quoIndex].items[itemIndex] = item;
            });
          Array.isArray(quotation.optionItems) &&
            quotation.optionItems.forEach((option, optionIndex) => {
              if (
                _.isString(option) &&
                !_.isError(_.attempt(JSON.parse, option))
              ) {
                option = JSON.parse(option);
              }
              quotationsList[quoIndex].optionItems[optionIndex] = option;
            });
          Array.isArray(quotation.attachment) &&
            quotation.attachment.forEach((attachment, attachIndex) => {
              if (
                _.isString(attachment) &&
                !_.isError(_.attempt(JSON.parse, attachment))
              ) {
                attachment = JSON.parse(attachment);
              }
              quotationsList[quoIndex].attachment[attachIndex] = attachment;
            });
          Array.isArray(quotation.vendorList) &&
            quotation.vendorList.forEach((vendor, vIndex) => {
              if (
                _.isString(vendor) &&
                !_.isError(_.attempt(JSON.parse, vendor))
              ) {
                vendor = JSON.parse(vendor);
              }
              quotationsList[quoIndex].vendorList[vIndex] = vendor;
            });
        });
      const formatedRfq = [];
      Array.isArray(quotationsList) &&
        quotationsList.forEach((rfq) => {
          const vendorData = [];
          Array.isArray(rfq.vendorList) &&
            rfq.vendorList.forEach((vendor) => {
              let total = 0;
              let data = {};
              const isVendorQuoteSubmitted = rfq.quotation.find(
                (_quotation) => _quotation.vendorId === vendor.vendorId
              );
              if (isVendorQuoteSubmitted) {
                Array.isArray(isVendorQuoteSubmitted.items) &&
                  isVendorQuoteSubmitted.items.forEach((_item) => {
                    total = parseFloat(total) + parseFloat(_item.quote);
                  });
                let quotationAttachments = [];
                Array.isArray(isVendorQuoteSubmitted.quotationAttachments) &&
                  isVendorQuoteSubmitted.quotationAttachments.map((qa) => {
                    if (
                      _.isString(qa) &&
                      !_.isError(_.attempt(JSON.parse, qa))
                    ) {
                      qa = JSON.parse(qa);
                    }
                    quotationAttachments.push(qa);
                  });
                data = {
                  ...vendor,
                  items: isVendorQuoteSubmitted.items,
                  isPoGenerated: isVendorQuoteSubmitted.poGenerated,
                  quotationId: isVendorQuoteSubmitted.id,
                  quotationAttachments,
                  isSubmitted: true,
                  total: total,
                  quoteDate: isVendorQuoteSubmitted.createdAt,
                  rfqDate: rfq.createdAt,
                };
              } else {
                data = {
                  ...vendor,
                  items: rfq.items,
                  isSubmitted: false,
                  rfqDate: rfq.createdAt,
                };
              }
              vendorData.push(data);
            });
          formatedRfq.push({
            vendorData,
            rfqId: rfq.id,
            attachment: rfq.attachment,
            description: rfq.description,
            vendorType: rfq.vendorType,
            draft: rfq.draft,
            items: rfq.items,
            optionItems: rfq.optionItems,
            quotationId: rfq.id,
          });
        });
      this.setState({ rfqsList: formatedRfq });
    });
  };

  editRFQHandler = (rfq) => {
    this.setState({
      projectData: rfq.items,
      options: rfq.optionItems,
      vendorType: rfq.vendorType,
      rfqId: rfq.rfqId,
      description: rfq.description,
      attachmentArr: rfq.attachment,
    });
    this.rfqDialogHandler();
  };

  rfqDialogHandler = (res = {}) => {
    this.setState(
      (prevState) => ({
        rfqDialogState: !prevState.rfqDialogState,
      }),
      () => this.getQuotationList()
    );
  };

  viewRFQ = async (id) => {
    const rfq = await this.props.getRfq(id);
    this.setState({ rfqData: rfq, step: 3 });
  };

  submitQuote = (rfq, vendor) => {
    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: true,
      reviewQuote: false,
      editQuote: false,
      vendorType: rfq.vendorType,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      attachment: rfq.attachment,
      description: rfq.description,
      quotationId: rfq.quotationId,
    };
    this.setState({ step: 2, quotationData });
  };

  editQuote = (rfq, vendor) => {
    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: false,
      reviewQuote: false,
      editQuote: true,
      vendorType: rfq.vendorType,
      attachment: vendor.quotationAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    };
    this.setState({ step: 2, quotationData });
  };

  reviewQuote = (rfq, vendor) => {
    const quotationData = {
      rfqId: rfq.rfqId,
      items: vendor.items,
      submitQuote: false,
      reviewQuote: true,
      editQuote: false,
      vendorType: rfq.vendorType,
      attachment: vendor.quotationAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    };
    this.setState({ step: 2, quotationData });
  };

  dialogClose = () => {
    const { step } = this.state;
    if (step === 2 || step === 3) {
      this.setState({ step: 1, viewPurchaseOrder: false });
      return;
    }
    this.props.dialogClose();
  };

  // This function takes value from submit-quote file and set items which will be used for quote or PO creation
  setMaterialItems = (materialItems) => {
    this.setState({ materialItems });
  };

  // crete new Quotation using materialItems
  createQuote = () => {
    const { quotationData = {} } = this.state;
    let valid = true;
    const formatedItems =
      (this.state.materialItems &&
        this.state.materialItems.map((item) => {
          if (!item.quote.value) {
            valid = false;
          }
          return {
            ...item,
            quote: parseFloat(item.quote.value),
          };
        })) ||
      [];

    if (!valid) {
      this.props.warningSnackBar("Enter Quote Value!");
      return;
    }

    const formData = new FormData();
    Array.isArray(quotationData.attachment) &&
      quotationData.attachment.map((file) => {
        formData.append("attachment[]", JSON.stringify(file));
      });
    Array.isArray(quotationData.attachmentArr) &&
      quotationData.attachmentArr.map((newFile) => {
        formData.append("attachment", newFile);
      });

    Array.isArray(formatedItems) &&
      formatedItems.map((item) => {
        formData.append("items[]", JSON.stringify(item));
      });
    formData.set("vendorId", quotationData.vendorId);

    this.props.createQuotation(formData, quotationData.rfqId).then((res) => {
      this.getQuotationList();
      this.setState({ step: 1 });
    });
  };

  editQuotation = () => {
    const { quotationData = {} } = this.state;
    let valid = true;
    const formatedItems =
      (this.state.materialItems &&
        this.state.materialItems.map((item) => {
          if (!item.quote.value) {
            valid = false;
          }
          return {
            ...item,
            quote: parseFloat(item.quote.value),
          };
        })) ||
      [];

    if (!valid) {
      this.props.warningSnackBar("Enter Quote Value!");
      return;
    }

    this.props
      .editQuotation({ items: formatedItems }, quotationData.quotationId)
      .then((res) => {
        this.getQuotationList();
        this.setState({ step: 1 });
      });
  };

  generatePO = async () => {
    const { materialItems, quotationData } = this.state;
    let poItems =
      Array.isArray(materialItems) &&
      materialItems
        .filter((item) => {
          if (item && item.status && item.status.value) {
            return item;
          }
        })
        .map(({ status, ...poItem }) => poItem);

    if (Array.isArray(poItems) && poItems.length < 1) {
      this.props.warningSnackBar("Approve an Item to generate Purchase Order!");
      return;
    }

    const { rfqId, vendorType, vendorId, quotationId } = quotationData;
    const data = {
      rfqId,
      vendorType,
      items: poItems,
      vendorId,
      quotationId,
    };

    const projectId = Cookies.get("projectId");

    await this.props.savePOData(data);
    if (quotationData.vendorType === "Supplier") {
      this.props.history(
        `/projects/purchase-order/${projectId}/create-purchase-order`
      );
    } else if (quotationData.vendorType === "sub-contractor") {
      this.props.history(
        `/projects/purchase-order/${projectId}/create-work-order`
      );
    } else {
      this.props.warningSnackBar(
        "Some Error Occured, Please Refresh the page and try again"
      );
    }
  };

  viewPurchaseOrder = async (rfq, vendor) => {
    const query = `?id=${vendor.quotationId}`;
    const { items, id, attachment } = await this.props.getPurchaseOrder(query);
    let poItems = (Array.isArray(items) && items) || [];
    let poAttachments = [];
    Array.isArray(attachment) &&
      attachment.map((attach) => poAttachments.push(JSON.parse(attach)));

    const quotationData = {
      rfqId: rfq.rfqId,
      items: poItems,
      submitQuote: false,
      reviewQuote: true,
      vendorType: rfq.vendorType,
      attachment: poAttachments,
      description: rfq.description,
      vendorId: vendor.vendorId,
      vendorName: vendor.name,
      quotationId: vendor.quotationId,
    };
    this.setState({
      step: 2,
      quotationData,
      viewPurchaseOrder: true,
      purchaseOrderId: id,
    });
  };

  rfqFileAttached = (quotationData) => {
    this.setState({ quotationData });
  };

  viewProject = () => {
    this.props.history(
      `/${PROJECT_MANAGEMENT}/view/${Cookies.get("projectId")}`
    );
  };
  gettemplate = async () => {
    let { templateOptions, vendorType } = this.state;
    let newdata = [];
    let type = "";
    if (vendorType === "Supplier") {
      type = "ProjectRFQSupplier";
    } else {
      type = "ProjectRFQSubcontractor";
    }
    const templateData = await this.props.getGlobalSettingTemplateTypes(type);
    for (let key in templateData) {
      newdata.push({
        value: templateData[key].id,
        key: templateData[key].name,
      });
    }
    templateOptions = newdata;
    this.setState({ templateOptions });
  };
  handleDownloadDialog = (id = "", template = "", vendorId = "") => {
    const { downloadData = {} } = this.state;
    downloadData.open = !downloadData.open;
    downloadData.id = id;
    downloadData.template = template;
    downloadData.vendorId = vendorId;
    this.gettemplate();
    this.setState({ downloadData });
  };

  downloadFile = async (fileFormat = "pdf") => {
    const { downloadData = {} } = this.state;

    if (fileFormat === "pdf" || fileFormat === "ALL") {
      await this.props.downloadProjectRFQ(downloadData.id, fileFormat, downloadData.vendorId).catch(e => console.error(e));
    }
    if (fileFormat === "docx" || fileFormat === "ALL") {
      await this.props.downloadProjectRFQ(downloadData.id, fileFormat, downloadData.vendorId).catch(e => console.error(e));
    }
    this.handleDownloadDialog();
  };

  render() {
    const { open, isModal } = this.props;
    const {
      rfqsList,
      rfqDialogState,
      projectDetails,
      projectData,
      step,
      quotationData = {},
      vendorType,
      attachmentArr,
      description,
      viewPurchaseOrder,
      purchaseOrderId,
      options,
      rfqId = "",
      rfqData,
      templateOptions,
      projectId,
      downloadData,
    } = this.state;
    const writePermission = this.props.userAccessControl === 2 ? true : false;

    const renderData = (
      <>
        <CreateRFQDialog
          open={rfqDialogState}
          projectName={projectDetails.name}
          dialogClose={this.rfqDialogHandler}
          projectData={projectData}
          vendorType={vendorType}
          customerName={
            projectDetails.customer &&
            projectDetails.customer[0] &&
            projectDetails.customer[0].businessName
          }
          selectedRFQ={[]}
          rfqId={rfqId}
          stages={projectDetails.stages || []}
          options={options}
          attachmentArr={attachmentArr}
          description={description}
          projectDueDate={projectDetails.dateOfCreation}
          hasStages={projectDetails.hasStages}
          isProjectRFQ={true}
          userAccessControl={this.props.userAccessControl}
        //totalAmount={totalMaterialCost + totalLaborCost + totalProfitMargin + totalVariableAmount}
        />
        {step === 1 && (
          <div className="viewRfqContainer">
            {rfqsList.map((rfq, index) => (
              <>
                <div key={rfq.id} className="rfqNameLabel">
                  <p>
                    {index + 1}.RFQ: {rfq.description}{" "}
                  </p>
                  {rfq.draft ? (
                    <p
                      className="openDraft"
                      onClick={() => this.editRFQHandler(rfq)}
                    >
                      <IconButton
                        aria-label="add"
                        disabled="true"
                        className="AddNewCustomerIcon"
                      >
                        <InsertDriveFileRoundedIcon />
                      </IconButton>
                      Open Draft
                    </p>
                  ) : (
                    <p
                      className="openDraft"
                      onClick={() => this.viewRFQ(rfq.rfqId)}
                    >
                      <IconButton
                        aria-label="add"
                        disabled="true"
                        className="AddNewCustomerIcon"
                      >
                        <VisibilityRoundedIcon />
                      </IconButton>
                      View RFQ
                    </p>
                  )}
                </div>
                {!rfq.draft && (
                  <div key={rfq.id} className="rfqList" style={{}}>
                    <TableContainer>
                      <Table>
                        <TableHead className="table-head">
                          <TableRow
                            style={{
                              background: "#F4F4F4",
                              borderRadius: "4px",
                              padding: "0 15px",
                            }}
                          >
                            <TableCell>
                              <p>
                                <b>Vendor Name</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Status</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Total</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Accepted</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Orders</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Send Date</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Receive Date</b>
                              </p>
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {rfq.vendorData &&
                            rfq.vendorData.map((vendor, index) => {
                              return (
                                <TableRow
                                  key={vendor.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#fcfcfc" : "#F4F4F4",
                                    padding: "0 15px",
                                    alignItems: "center",
                                  }}
                                >
                                  <TableCell xs={2} item>
                                    <p>{vendor.name}</p>
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    {vendor.isSubmitted ? (
                                      <p style={{ color: "#3FC620" }}>
                                        Submitted
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.editQuote(
                                              { ...rfq },
                                              { ...vendor }
                                            )
                                          }
                                        >
                                          {" "}
                                          <EditIcon />
                                        </span>
                                      </p>
                                    ) : (
                                      <p
                                        onClick={() =>
                                          this.submitQuote(
                                            { ...rfq },
                                            { ...vendor }
                                          )
                                        }
                                        className="submitQuote"
                                      >
                                        Submit Quote
                                      </p>
                                    )}
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    {vendor.isSubmitted ? (
                                      <p>
                                        <b>{numberFormat(vendor.total)}</b>
                                      </p>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    {vendor.isSubmitted ? (
                                      <p>{numberFormat(vendor.total)}</p>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    {vendor.isSubmitted ? (
                                      vendor.isPoGenerated ? (
                                        rfq.vendorType === "Supplier" ? (
                                          <p
                                            onClick={() =>
                                              this.viewPurchaseOrder(
                                                { ...rfq },
                                                { ...vendor }
                                              )
                                            }
                                            className="viewPO"
                                          >
                                            View Purchase Order
                                          </p>
                                        ) : (
                                          <p
                                            onClick={() =>
                                              this.viewPurchaseOrder(
                                                { ...rfq },
                                                { ...vendor }
                                              )
                                            }
                                            className="viewPO"
                                          >
                                            View Work Order
                                          </p>
                                        )
                                      ) : (
                                        <p
                                          onClick={() =>
                                            this.reviewQuote(
                                              { ...rfq },
                                              { ...vendor }
                                            )
                                          }
                                          className="reviewQuote"
                                        >
                                          Review
                                        </p>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    <p>
                                      {vendor.rfqDate &&
                                        format(
                                          new Date(vendor.rfqDate),
                                          "dd/MM/yyyy"
                                        )}
                                    </p>
                                  </TableCell>
                                  <TableCell xs={2} item>
                                    <p>
                                      {vendor.isSubmitted &&
                                        vendor.quoteDate &&
                                        format(
                                          new Date(vendor.quoteDate),
                                          "dd/MM/yyyy"
                                        )}
                                    </p>
                                  </TableCell>
                                  <TableCell xs={1} item>
                                    <p
                                      className="openDraft"
                                      onClick={() =>
                                        this.handleDownloadDialog(
                                          rfq.rfqId,
                                          "",
                                          vendor.vendorId
                                        )
                                      }
                                    >
                                      <IconButton
                                        aria-label="add"
                                        disabled="true"
                                        className="AddNewCustomerIcon"
                                      >
                                        <GetAppRoundedIcon fontSize="small" />
                                      </IconButton>
                                      Download
                                    </p>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </>
            ))}
          </div>
        )}
        {step === 2 && (
          <SubmitQuote
            quotationData={quotationData}
            setMaterialItems={this.setMaterialItems}
            projectDetails={projectDetails}
            viewPurchaseOrder={viewPurchaseOrder}
            purchaseOrderId={purchaseOrderId}
            rfqFileAttached={this.rfqFileAttached}
            warningSnackBar={this.props.warningSnackBar}
            userAccessControl={this.props.userAccessControl}
          />
        )}
        {step === 3 && <ViewSingleRFQ rfqData={rfqData} />}
      </>
    );

    return (
      <>
        <DownloadDialog
          {...downloadData}
          downloadFile={this.downloadFile}
          handleDownloadDialog={this.handleDownloadDialog}
          templateOptions={templateOptions}
          downloadModuleName="RFQ"
        />
        {isModal ? (
          <Modal
            open={open}
            onClose={this.dialogClose}
            fullWidth={true}
            maxWidth="lg"
            modalHeading={`Proposal Name - ${projectDetails.name}`}
            modalSaveTxt={
              step === 2 && quotationData.editQuote
                ? "Edit Quote"
                : step === 2 &&
                !viewPurchaseOrder &&
                (quotationData.submitQuote
                  ? "Save"
                  : (projectDetails.type !== "TENDER" &&
                    (quotationData.vendorType === "Supplier"
                      ? "Generate PO"
                      : "Generate Work Order")) ||
                  "")
            }
            modalCloseTxt={step === 2 && !viewPurchaseOrder && "Cancel"}
            onClick={
              quotationData.editQuote
                ? this.editQuotation
                : quotationData.submitQuote
                  ? this.createQuote
                  : this.generatePO
            }
            showActionBtn
            savebtnClass="createBtn"
            primary
          >
            {renderData}
          </Modal>
        ) : (
          <div className="viewRFQScreen">
            <Grid container sx={{ m: 0, mb: 1 }}>
              <Grid item xs={12} sx={{ p: 0 }} textAlign={"start"}>
                <ArrowCircleLeftOutlinedIcon
                  className="arrow-circle-icon"
                  onClick={() => {
                    this.setState({ step: 1, viewPurchaseOrder: false });
                  }}
                />
                {step === 1 ? (
                  <Breadcumb projectId={projectId}>RFQ</Breadcumb>
                ) : (
                  <Breadcumb projectId={projectId}>
                    <span
                      className="link"
                      onClick={() => {
                        this.setState({ step: 1, viewPurchaseOrder: false });
                      }}
                    >
                      RFQ{" "}
                    </span>
                    <span className="arrow"> {"/"} </span>
                    {quotationData.vendorType === "Supplier"
                      ? "View Purchase Order"
                      : "View Work Order"}
                  </Breadcumb>
                )}
              </Grid>
            </Grid>
            <Paper className="paperContainer rfqContainer">
              <>
                {renderData}
                {step === 2 && !viewPurchaseOrder && writePermission && (
                  <Grid
                    container
                    style={{ justifyContent: "flex-end", marginTop: "13px" }}
                  >
                    <p
                      className="cancel-btn"
                      onClick={() => {
                        this.setState({ step: 1 });
                      }}
                    >
                      Cancel
                    </p>
                    {quotationData.editQuote ? (
                      <p
                        className="cancel-btn"
                        onClick={() => this.editQuotation()}
                      >
                        {"Edit Quote"}
                      </p>
                    ) : (
                      <p
                        className="cancel-btn"
                        onClick={() =>
                          quotationData.submitQuote
                            ? this.createQuote()
                            : this.generatePO()
                        }
                      >
                        {quotationData.submitQuote
                          ? "Save"
                          : quotationData.vendorType === "Supplier"
                            ? "Generate PO"
                            : "Generate Work Order"}
                      </p>
                    )}
                  </Grid>
                )}
              </>
            </Paper>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getRfq: bindActionCreators(getRfq, dispatch),
  getGlobalSettingTemplateTypes: bindActionCreators(
    getGlobalSettingTemplateTypes,
    dispatch
  ),
  createQuotation: bindActionCreators(createQuotation, dispatch),
  getQuotationList: bindActionCreators(getQuotationList, dispatch),
  getProject: bindActionCreators(getProject, dispatch),
  getPurchaseOrder: bindActionCreators(getPurchaseOrder, dispatch),
  savePOData: bindActionCreators(savePOData, dispatch),
  warningSnackBar: bindActionCreators(warningSnackBar, dispatch),
  editQuotation: bindActionCreators(editQuotation, dispatch),
  downloadProjectRFQ: bindActionCreators(downloadProjectRFQ, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewRFQ)
);
