import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  getAsset,
  getAssetList,
  allocateAsset,
} from "../../actions/asset-management.action";
import { getUserList } from "../../actions/user-management.action";
import { getProjectList } from "../../actions/project-management.action";
import { getServicesList } from "../../actions/service.action";
import "./scss/asset-management.scss";
import { withRouter } from "../../util/with-router";
import { ASSET_MANAGEMENT } from "../../constants/route-paths";
import DatePickerInput from "src/components/inputs/date-picker";
import Button from 'react-bootstrap/Button';
import SelectInput from "src/components/inputs/select";
import TextAreaInput from "src/components/inputs/textarea-input";

const allocationType = [
  {
    key: "Vehicle",
    value: "VEHICLE",
  },
  {
    key: "User",
    value: "USER",
  },
  {
    key: "Project",
    value: "PROJECT",
  },
  // {
  //   key: "Service",
  //   value: "SERVICE",
  // },
];

class AllocateAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        type: {
          name: "type",
          label: "Allocate To:",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "VEHICLE",
          items: allocationType,
          required: true,
        },
        typeId: {
          name: "typeId",
          label: "Id:",
          type: "select",
          touched: false,
          error: false,
          valid: true,
          value: "",
          items: [],
          required: true,
        },
        allocationDate: {
          name: "allocationDate",
          label: "Allocation Date:",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: new Date(),
        },
        returnDate: {
          name: "returnDate",
          label: "Return Date:",
          type: "datepicker",
          touched: false,
          error: false,
          valid: true,
          value: "",
          minDate: new Date(),
        },
        description: {
          name: "description",
          label: "Description:",
          type: "textarea",
          touched: false,
          error: false,
          valid: true,
          value: "",
        },
      },
      assetId: "",
      assetData: {},
    };
  }

  async componentDidMount() {
    if (this.props.params.assetId) {
      const assetId = this.props.params.assetId;
      const { fields } = this.state;
      const typeId = fields.typeId;

      const assetData = await this.props.getAsset(assetId);
      const assetOptions = await this.getAssetList();

      this.setState({
        assetData,
        assetId,
        fields: { ...fields, typeId: { ...typeId, items: assetOptions } },
      });
    }
  }

  getAssetList = async () => {
    const assetId = this.props.params.assetId;

    const assets = await this.props.getAssetList("VEHICLE");
    let assetOptions = assets
      .filter((asset) => asset.id !== assetId && asset.status !== "DISCARD")
      .map((asset) => ({
        key: `${asset.name} (${asset.assetNo})`,
        value: asset.id,
      }));

    assetOptions = _.orderBy(
      assetOptions,
      [({ key }) => key.toLowerCase()],
      ["asc"]
    );
    return assetOptions;
  };

  getUserList = async () => {
    const users = await this.props.getUserList();
    let assetOptions = users.map((user) => ({
      key:
        (user.firstName && user.firstName != null ? user.firstName : "") +
        " " +
        (user.lastName && user.lastName != null ? user.lastName : ""),
      value: user.id,
    }));

    assetOptions = _.orderBy(
      assetOptions,
      [({ key }) => key.toLowerCase()],
      ["asc"]
    );
    return assetOptions;
  };

  getProjectList = async () => {
    const projects = await this.props.getProjectList();
    let assetOptions = projects
      .filter((asset) => asset.type === "PROJECT")
      .map((project) => ({
        key: project.name,
        value: project.id,
      }));

    assetOptions = _.orderBy(
      assetOptions,
      [({ key }) => key.toLowerCase()],
      ["asc"]
    );
    return assetOptions;
  };

  getServicesList = async () => {
    const services = await this.props.getServicesList();
    let assetOptions = services
      .filter((asset) => asset.type === "SERVICE" || asset.type === "UNQUOTE")
      .map((service) => ({
        key: service.name,
        value: service.id,
      }));

    assetOptions = _.orderBy(
      assetOptions,
      [({ key }) => key.toLowerCase()],
      ["asc"]
    );
    return assetOptions;
  };

  inputChangeHandler = async (e, fieldName = "") => {
    let fields = this.state.fields,
      typeName = "";
    if (fieldName === "returnDate" || fieldName === "allocationDate") {
      if (fieldName === "allocationDate") {
        fields["returnDate"].value = null;
        fields["returnDate"].minDate = new Date(e);
      }
      fields[fieldName].value = e;
      fields[fieldName].touched = true;
    } else if (fieldName === "type") {
      fields[fieldName].value = e.target.value;
      fields[fieldName].touched = true;
      if (e.target.value === "VEHICLE") {
        fields["typeId"].items = await this.getAssetList();
      } else if (e.target.value === "USER") {
        fields["typeId"].items = await this.getUserList();
      } else if (e.target.value === "PROJECT") {
        fields["typeId"].items = await this.getProjectList();
      } else if (e.target.value === "SERVICE") {
        fields["typeId"].items = await this.getServicesList();
      }
    } else if (fieldName === "typeId") {
      fields[fieldName].value = e.target.value;
      fields[fieldName].touched = true;
      typeName =
        (e.nativeEvent.target && e.nativeEvent.target.textContent) || "";
    } else if (_.isObject(e) && _.isObject(e.target)) {
      fields[e.target.name].value = e.target.value;
      fields[e.target.name].touched = true;
    }

    this.setState({ fields, ...(typeName && { typeName: typeName }) });
  };

  handleValidation = ({ name, value, required }) => {
    let error = false;
    let valid = true;

    if (required && _.isEmpty(value)) {
      return { error: "This Field is Required", valid: false };
    }

    return { error, valid };
  };

  submitForm = async () => {
    const { user } = this.props;
    const { fields, assetId, typeName } = this.state;
    let isFormValid = true;
    for (let key in fields) {
      const { error, valid } = this.handleValidation(fields[key]);
      fields[key].touched = true;
      fields[key].valid = valid;
      fields[key].error = error;
      isFormValid = isFormValid && valid;
    }

    if (!isFormValid) {
      this.setState({ fields });
    } else {
      const data = {
        type: fields.type.value,
        typeId: fields.typeId.value,
        allocationDate: fields.allocationDate.value,
        returnDate: fields.returnDate.value,
        description: fields.description.value,
        assetId: assetId,
        typeName: typeName,
        allocatedBy: user.id,
      };
      await this.props.allocateAsset(data);
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history(`/${ASSET_MANAGEMENT}`);
  };

  render() {
    const { fields, assetData } = this.state;

    return (
      <div className="purchase-order-block">

        <div className="row">
          <div className="w-auto">
            <h2 className="content-heading text-uppercase">Allocate Assets</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mt-3">
            <div className="view-block">
              <label>Asset Name</label>
              <p>{assetData.name}</p>
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="view-block">
              <label>Asset Id</label>
              <p>{assetData.assetNo}</p>
            </div>
          </div>

          <div className="col-lg-6 mt-3">
            <SelectInput
              {...fields.type}
              onChange={(e) => this.inputChangeHandler(e, fields.type.name)}
            />
          </div>
          <div className="col-lg-6 mt-3">
            <SelectInput
              {...fields.typeId}
              onChange={(e) => this.inputChangeHandler(e, fields.typeId.name)}
            />
          </div>
          <div className="col-lg-6 mt-3">
            <DatePickerInput
              {...fields.allocationDate}
              onChange={(e) => this.inputChangeHandler(e, fields.allocationDate.name)}
            />
          </div>
          <div className="col-lg-6 mt-3">
            <DatePickerInput
              {...fields.returnDate}
              onChange={(e) => this.inputChangeHandler(e, fields.returnDate.name)}
            />
          </div>
          <div className="col-12 mt-3">
            <TextAreaInput
              {...fields.description}
              label={'Description'}
              onChange={(e) => this.inputChangeHandler(e, fields.description.name)}
            />
          </div>
        </div>

        <div className="col-12 d-flex justify-content-end gap-3 mt-3">
          {this.props.writePermission && (<Button onClick={this.submitForm} className="secondarybtn">Allocate</Button>)}
          <Button onClick={this.handleClose} className="secondarybtn cancelbtn">CANCEL</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getAsset: bindActionCreators(getAsset, dispatch),
  getAssetList: bindActionCreators(getAssetList, dispatch),
  getUserList: bindActionCreators(getUserList, dispatch),
  getProjectList: bindActionCreators(getProjectList, dispatch),
  allocateAsset: bindActionCreators(allocateAsset, dispatch),
  getServicesList: bindActionCreators(getServicesList, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllocateAsset)
);
