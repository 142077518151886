import { getVendorList } from "src/actions/vendor-management.action";
import { addSubcontractorsAction } from "src/slice/project-detail";

export const getSubcontractorsData = async ({ type, dispatch }) => {
    dispatch(getVendorList(type)).then((vendors) => {
        const subcontractors = [];
        vendors.forEach((vendor) => {
            subcontractors.push({ key: vendor.businessName, value: vendor.id });
        });

        dispatch(addSubcontractorsAction(subcontractors))
    });
};