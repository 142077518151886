export const numberFormat = (value, skipSymbol = false) => {

  value = (parseFloat(value) || 0).toFixed(2)

  let formatedValue = new Intl.NumberFormat('en-US', {
  }).format(value)

  if (!formatedValue.includes('.')) {
    formatedValue = formatedValue + '.00'
  } else {

    const valueArr = formatedValue.split('.')
    if (valueArr[1].length === 1) {
      formatedValue = formatedValue + '0'
    }
  }

  return skipSymbol ? formatedValue : '$' + formatedValue
}

export const toUSD = (value) => {

  value = (parseFloat(value) || 0).toFixed(2)

  let formatedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)


  return formatedValue
}

export const toAUD = (value) => {

  value = (parseFloat(value) || 0).toFixed(2)

  let formatedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  }).format(value)


  return formatedValue
}

export const toPercent = (valueA = 0, valueB = 0) => {
  let value = 0
  if (parseFloat(valueB)) {
    value = parseFloat(valueA) / parseFloat(valueB) * 100
  } else if (parseFloat(valueA)) {
    value = 100
  }
  value = value / 100;
  return Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(value || 0);
}

export const showPercent = (value = 0) => {
  value = value / 100;
  return Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(value || 0);
}