export const allowedWordTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const allowedPdfTypes = ['application/pdf'];

export const allowedImgTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif'
];

export const allowedExcelTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
];

export const fileValidator = ({ file, acceptedFileSize = 10, allowedMimeTypes = [...allowedExcelTypes, ...allowedImgTypes, ...allowedPdfTypes, ...allowedWordTypes] }) => {
    try {
        const fileType = allowedMimeTypes.includes(file.type);
        const fileSize = (file.size / (1024 * 1024)) >= acceptedFileSize;
        return {
            validFileType: fileType ? { valid: true, error: false } : { valid: false, error: `File format (.${file.name.split('.').at(-1)}) is Invalid. ${file.name}` },
            validFileSize: !fileSize ? { valid: true, error: false } : { valid: false, error: `File size exceeds the maximum limit ${acceptedFileSize}MB. ${file.name}` },
        }
    } catch (error) {
        return { error: { valid: false, error: error.message } }
    }
}