
export const Subscriptions = [
    {
        name: 'Basic',
        key: 'Basic',
        value: 'BASIC',
        metadata: {
            project: {
                limit: [
                    { label: 'Total Projects', key: 'total_projects', value: 1 }
                ],
                availability: [
                    { label: 'Current Projects', key: 'current', value: false },
                    { label: 'Quotes', key: 'quotes', value: true }
                ]
            },
            users: {
                limit: [
                    { label: 'Total Users', key: 'total_users', value: 1 }
                ]
            },
            workforce: {
                availability: [
                    { label: 'Timesheet Menu', key: 'timesheet_side_menu', value: false },
                    { label: 'Schedule Menu', key: 'schedule_side_menu', value: false }
                ]
            },
            inventory: {
                availability: [
                    { label: 'Inventory Menu', key: 'inventory_side_menu', value: true },
                    { label: 'Receive Stock', key: 'receive_stock', value: false },
                ]
            },
            asset: {
                availability: [
                    { label: 'Asset Management Menu', key: 'asset_side_menu', value: false }
                ]
            },
        }
    },
    {
        name: 'Pro',
        key: 'Pro',
        value: 'PRO',
        metadata: {
            project: {
                limit: [
                    { label: 'Total Projects', key: 'total_projects', value: 5 }
                ],
                availability: [
                    { label: 'Current Projects', key: 'current', value: true },
                    { label: 'Quotes', key: 'quotes', value: true }
                ]
            },
            users: {
                limit: [
                    { label: 'Total Users', key: 'total_users', value: 3 }
                ]
            },
            workforce: {
                availability: [
                    { label: 'Timesheet Menu', key: 'timesheet_side_menu', value: true },
                    { label: 'Schedule Menu', key: 'schedule_side_menu', value: true }
                ]
            },
            inventory: {
                availability: [
                    { label: 'Inventory Menu', key: 'inventory_side_menu', value: true },
                    { label: 'Receive Stock', key: 'receive_stock', value: true },
                ]
            },
            asset: {
                availability: [
                    { label: 'Asset Management Menu', key: 'asset_side_menu', value: true }
                ]
            },
        }
    },
    {
        name: 'Custom',
        key: 'Custom',
        value: 'CUSTOM',
        metadata: {
            project: {
                limit: [
                    { label: 'Total Projects', key: 'total_projects', value: 10 }
                ],
                availability: [
                    { label: 'Current Projects', key: 'current', value: true },
                    { label: 'Quotes', key: 'quotes', value: true }
                ]
            },
            users: {
                limit: [
                    { label: 'Total Users', key: 'total_users', value: 5 }
                ]
            },
            workforce: {
                availability: [
                    { label: 'Timesheet Menu', key: 'timesheet_side_menu', value: true },
                    { label: 'Schedule Menu', key: 'schedule_side_menu', value: true }
                ]
            },
            inventory: {
                availability: [
                    { label: 'Inventory Menu', key: 'inventory_side_menu', value: true },
                    { label: 'Receive Stock', key: 'receive_stock', value: true },
                ]
            },
            asset: {
                availability: [
                    { label: 'Asset Management Menu', key: 'asset_side_menu', value: true }
                ]
            },
        }
    },
]

export const minifySubscription = (subscription_metadata = {}, isGlobalUser = false) => {
    let entitlements = {
        currentProject: isGlobalUser,
        quotesProject: isGlobalUser,
        timesheetMenu: isGlobalUser,
        scheduleMenu: isGlobalUser,
        inventoryMenu: isGlobalUser,
        assetMenu: isGlobalUser,
        receiveStock: isGlobalUser,
        totalUsers: 0,
        totalProjects: 0
    };
    for (const key in subscription_metadata) {
        for (let itr of (subscription_metadata[key].availability || [])) {
            switch (itr.key) {
                case 'current': entitlements.currentProject ||= itr.value; break;
                case 'quotes': entitlements.quotesProject ||= itr.value; break;
                case 'timesheet_side_menu': entitlements.timesheetMenu ||= itr.value; break;
                case 'schedule_side_menu': entitlements.scheduleMenu ||= itr.value; break;
                case 'inventory_side_menu': entitlements.inventoryMenu ||= itr.value; break;
                case 'receive_stock': entitlements.receiveStock ||= itr.value; break;
                case 'asset_side_menu': entitlements.assetMenu ||= itr.value; break;
                default: break;
            }
        }
        for (let itr of (subscription_metadata[key].limit || [])) {
            switch (itr.key) {
                case 'total_users': entitlements.totalUsers = Number(itr.value); break;
                case 'total_projects': entitlements.totalProjects = Number(itr.value); break;
                default: break;
            }
        }
    }

    return entitlements;
}