import React, { Fragment } from 'react'
import { toUSD } from '../../util/currency-formatter'
import idx from 'idx'

function DetailedProposal({ optionsData, stagesData, hasStages, stagesCost, taskCost, subTaskCost, optionsCost, totalCost, isVariation }) {

    const viewVariationsData = () => {
        return (
            <>
                <tr>
                    <td className="ps-2 text-left" colSpan={2}>Options: </td>
                </tr>
                {optionsData && optionsData.map((option) => {
                    const subTaskJSX = option.subTasks.map((subTask) => (
                        <tr key={subTask.id}>
                            <td className="ps-5 text-left">{subTask.description}</td>
                            <td>
                                <span className="text-right">{toUSD(idx(subTaskCost, _ => _[subTask.id].total))}</span>
                            </td>
                        </tr>))
                    return (<>
                        <tr key={option.id}>
                            <td className="text-left">
                                {option.name}{option.revisionNumber !== 1 ? ' - ' + option.revisionName : ''}
                            </td>
                            <td>
                                {/* <span className="text-right">{toUSD(idx(optionsCost, _ => _[option.id].total))}</span> */}
                            </td>
                        </tr>
                        {subTaskJSX}
                    </>)
                })}
            </>
        )
    }


    return (
        <div className="col-12 my-3 table-responsive">
            <table className="table-create mb-0 table table-bordered table-row-color">
                <thead>
                    <tr>
                        <th className='w-auto text-left'>Description</th>
                        <th className='text-right'>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {stagesData && stagesData.map((stage, index) => {
                        return (
                            <Fragment key={index}>
                                {stage.selected && hasStages &&
                                    <tr key={stage.id}>
                                        <td className="text-left">
                                            Stage {index + 1}: <b>{stage.name} </b>
                                        </td>
                                        <td>
                                            <span className="text-right">
                                                {toUSD(idx(stagesCost, _ => _[stage.id].total))}
                                            </span>
                                        </td>
                                    </tr>
                                }
                                {stage.tasks.map((stageTask, i) => {
                                    const subTaskJSX = stageTask.subTasks.map((subTask) => (
                                        subTask.selected ? <tr key={subTask.id}>
                                            <td className="ps-5 text-left">{subTask.description}</td>
                                            <td>
                                                <span className="text-right">{toUSD(idx(subTaskCost, _ => _[subTask.id].total))}</span>
                                            </td>
                                        </tr> : ''))
                                    if (stageTask.selected) {
                                        return (
                                            <>
                                                <tr key={stageTask.id}>
                                                    <td className="text-left">
                                                        <b>{stageTask.name}</b>
                                                    </td>
                                                    <td> </td>
                                                </tr>
                                                {subTaskJSX}
                                            </>
                                        )
                                    }
                                })}
                            </Fragment>
                        )
                    })}
                    {isVariation && viewVariationsData()}
                    <tr>
                        <td className='text-right'>
                            <span className="tableinfo">Total</span>
                        </td>
                        <td>
                            <span className="tableinfo text-right">{toUSD(totalCost)}</span>
                        </td>
                    </tr>
                    {(!isVariation && Array.isArray(optionsData) && optionsData.length > 0 && viewVariationsData()) || ''}
                </tbody>
            </table>
        </div>
    )
}

export default DetailedProposal
